import { go } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { changeCvObjectToImgSrc } from './draw_product_faces.js';
import { getBaseProductInMaker } from './getSth.js';
import { initAfterPlacingProductFaces, renderNewFacesByCurrentBpcfsInMakerA } from './mp_maker.js';
import { closeAllFacesModeA } from './select_faces.js';
import { selectFaceByDesigns } from './util.js';
import { renderSpecialColor } from './text.js';
import { NewMakerCvObjectCvImageF } from '../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { getPrevCurrentSizeFaces } from './change_bp.js';
import { NewMakerFcanvasFuncF } from '../../NewMaker/Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { alertWhenIsLocOptimizationResetByBpChange } from './LocOptimization.js';
import { NewMakerGuideConfirmF } from '../../NewMaker/Guide/Confirm/F/Function/module/NewMakerGuideConfirmF.js';

export const makePrevProductMatcherHtml = (base_product) => {
  return legacyHtml`
    <div class="prev_product_matcher">
      <div class="new_bp_name">${base_product['name' + _collabo + _en]}</div>
      <div class="bottom">
        <div class="header">${T('Drag and drop your images.')}</div>
        <div class="tmp_designs"></div>
        <div class="options">
          <button type="button" class="cancel">${T('Cancel')}</button>
          <button type="button" class="done">${T('Done')}</button>
        </div>
      </div>
    </div>
  `;
};

async function closePrevProductMatcherA() {
  await initAfterPlacingProductFaces();
  unsetPrevProductColor();
  $.html($1('#maker_frame .prev_product_matcher .tmp_designs'), '');
  $.remove_class($1('html'), 'prev_product_matcher');
  return closeAllFacesModeA();
}

export function unsetPrevProductColor() {
  box.set('maker->prev_product_color', null);
  box.set('maker->prev_cv_groups', null);
  box.set('maker->prev_compact_cv_groups', null);
}

function cancelPrevProductMatcher() {
  const prev_product_color = box.sel('maker->prev_product_color');
  if (!prev_product_color) throw Error('no prev_product_color');
  box.set('maker->product_color', prev_product_color);
  $.don_loader_start();

  G.mp.maker.extend_state_bp(box.sel('maker->product_color->_->base_product->id'));
  $.css($1('.canvass'), { opacity: 0 });
  return _p.go(renderNewFacesByCurrentBpcfsInMakerA(), closePrevProductMatcherA, function () {
    $.don_loader_end();
    return anime({
      targets: $1('.canvass'),
      opacity: 1,
      easing: 'easeOutQuad',
      duration: 300,
    }).finished;
  });
}

export async function completePrevProductMatcher() {
  G.mp.maker.extend_state_bp(box.sel('maker->product_color->_->base_product->id'));
  selectFaceByDesigns();
  G.mp.maker.state.start();
  G.mp.maker.state.push();

  const prev_product_color = box.sel('maker->prev_product_color');
  await alertWhenIsLocOptimizationResetByBpChange({ pf2_values: prev_product_color.product_faces2?.value });
  await closePrevProductMatcherA();
  if (NewMakerProductStyleS.isNoRequestBgRemoval(getBaseProductInMaker())) {
    NewMakerCvObjectCvImageF.makeRequestBgRemovalFalse();
  }
  NewMakerGuideConfirmF.open(getBaseProductInMaker());
}

async function prev_product_matcher_done(is_done) {
  if (is_done) {
    await completePrevProductMatcher();
  } else {
    cancelPrevProductMatcher();
  }
  G.mp.maker.ban_frame_hide();
}

export function render_prev_product_matcherA() {
  const prev_cv_groups = box().maker.prev_cv_groups;
  const new_bp_name = box().maker.product_color._.base_product['name' + _en];
  return _p.go(
    prev_cv_groups,
    _p.cmap(function (cv_group, i) {
      if (!cv_group) return;
      return _p.go(changeCvObjectToImgSrc(cv_group, 100, undefined, true), function (src) {
        return $.el(
          '<div class="design" idx="' + i + '" draggable="true">' + '<img src="' + src + '">' + '</div>',
        );
      });
    }),
    _p.compact,
    _p.each((tmp_design) => $.append_to(tmp_design, $1('#maker_frame .tmp_designs'))),
    function () {
      $.add_class($1('html'), 'prev_product_matcher');
      $.text($1('#maker_frame .prev_product_matcher .new_bp_name'), new_bp_name);
    },
  );
}

$.frame.defn_frame({
  frame_name: 'mp.maker.prev_product_matcher',
  page_name: 'mp.maker.prev_product_matcher',
  prev_frame_show: true,
  animation: false,
  closing: function (el_frame, is_done) {
    prev_product_matcher_done(is_done);
  },
});

$.frame.defn_page({
  page_name: 'mp.maker.prev_product_matcher',
  tabs: [
    {
      tab_name: 'mp.maker.prev_product_matcher',
      template: _p.noop,
    },
  ],
});

export const makePrevProductMatcherEvent = function (maker_el) {
  return go(
    maker_el,
    $.on(
      'click',
      '.prev_product_matcher button.cancel',
      errorHandlingEventWrapper(function () {
        if (!UtilF.isLegacyMobile()) return prev_product_matcher_done();
        $.frame.close();
      }),
    ),
    $.on(
      'click',
      '.prev_product_matcher button.done',
      errorHandlingEventWrapper(function () {
        if (!UtilF.isLegacyMobile()) return prev_product_matcher_done(true);
        // G.mp.maker.all_reset_data_cm();
        // G.mp.maker.reset_box_data();
        $.frame.close(true);
      }),
    ),
    $.on('dragenter', '.front_blank.for_select_faces .text', function (e) {
      e.stopPropagation();
    }),
    $.on('dragleave', '.front_blank.for_select_faces .text', function (e) {
      e.stopPropagation();
    }),
    $.on('drop', '.front_blank.for_select_faces .text', function (e) {
      e.stopPropagation();
    }),
    $.on(
      'dragstart',
      '.tmp_designs .design',
      errorHandlingEventWrapper(function (e) {
        $.selectable(e);
        e.dataTransfer.effectAllowed = 'copy';
        e.dataTransfer.setData('design_idx', $.attr(e.currentTarget, 'idx'));
      }),
    ),
    $.on('dragover', '.front_blank .square', function (e) {
      e.preventDefault();
    }),
    $.on('dragenter', '.front_blank .square', function (e) {
      if (!$1('html.allFacesMode')) return;
      e.preventDefault();
      $.add_class(e.currentTarget, 'dragenter');
    }),
    $.on('dragleave', '.front_blank .square', function (e) {
      e.preventDefault();
      $.remove_class(e.currentTarget, 'dragenter');
    }),
    $.on(
      'drop',
      '.front_blank .square',
      errorHandlingEventWrapper(async function (e) {
        e.preventDefault();
        $.remove_class(e.currentTarget, 'dragenter');
        const prev_design_idx = e.dataTransfer.getData('design_idx');
        const prev_cv_group = box.sel('maker->prev_cv_groups')[prev_design_idx];
        // const prev_cv_compact_cv_groups = box.sel('maker->prev_compact_cv_groups');
        const canvas_idx = $.attr(e.currentTarget, 'idx');

        if (!G.mp.maker.can_embro() && G.mp.maker.is_embro(prev_cv_group)) {
          return $.alert(T('Embroidery patches are not available for this product'));
        }
        if (!G.mp.maker.can_flex() && G.mp.maker.only_flex(prev_cv_group)) {
          return $.alert(T('The font colors or image colors are not available for this product.'));
        }
        // const idx = prev_cv_compact_cv_groups.indexOf(prev_cv_group);
        const { prev_size_faces, current_size_faces } = getPrevCurrentSizeFaces();
        await prevProductMatching2(
          prev_cv_group,
          prev_size_faces[prev_design_idx],
          current_size_faces[canvas_idx],
          canvas_idx,
        );
        // await fitPfInFacanvasInMaker(
        //   box.sel('maker->prev_product_color').product_faces2.value[idx],
        //   getRealFcanvass()[canvas_idx],
        // );

        $.remove($1('.prev_product_matcher .tmp_designs .design[idx="' + prev_design_idx + '"]'));
        if (!$1('.prev_product_matcher .tmp_designs .design')) $.hide($1('.prev_product_matcher .bottom >p'));
      }),
    ),
  );
};

export function prevProductMatching2(
  prev_compact_cv_group,
  prev_print_area,
  current_print_area,
  idx,
  is_scale,
) {
  const canvas = box.sel('maker->canvass')[idx];
  const cv_group = prev_compact_cv_group;
  const prev_print_area_print = prev_print_area.print.px;
  const current_print_area_print = current_print_area.print.px;
  const prev_px_per_1cm = prev_print_area.px_per_1cm;
  const current_px_per_1cm = current_print_area.px_per_1cm;
  const scale_px = current_px_per_1cm / prev_px_per_1cm;
  if (Math.abs(current_px_per_1cm - prev_px_per_1cm) > 10) is_scale = true;

  return new Promise(function (resolve) {
    cv_group.clone(function (cv_group2) {
      const prev_print_area_top =
        prev_print_area_print.top + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
      const current_print_area_top =
        current_print_area_print.top + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
      canvas.add(cv_group2);
      if (is_scale) {
        if (current_print_area_print.width > current_print_area_print.height) {
          if (cv_group2.width > cv_group2.height) {
            cv_group2.scaleToWidth(current_print_area_print.width - 10);
          } else {
            cv_group2.scaleToHeight(current_print_area_print.height - 10);
          }
        } else {
          if (cv_group2.width > cv_group2.height) {
            cv_group2.scaleToWidth(current_print_area_print.width - 10);
          } else {
            cv_group2.scaleToHeight(current_print_area_print.height - 10);
          }
        }
        cv_group2.left = Math.round(
          current_print_area_print.left +
            current_print_area_print.width / 2 -
            (cv_group2.width * cv_group2.scaleX) / 2,
        );
        cv_group2.top = Math.round(
          current_print_area_top +
            current_print_area_print.height / 2 -
            (cv_group2.height * cv_group2.scaleY) / 2,
        );
      }
      _p.go(G.mp.maker.ungrouping_only(canvas, cv_group2), function (cv_objects) {
        return _p.go(renderSpecialColor(cv_objects), function () {
          if (!is_scale)
            _each(cv_objects, function (cv_obj) {
              const prev_top_cm = (cv_obj.top - prev_print_area_top) / prev_px_per_1cm;
              const current_top_px = prev_top_cm * current_px_per_1cm + current_print_area_top;
              cv_obj.top = current_top_px;
              const prev_left_cm =
                (cv_obj.left - (prev_print_area_print.left + prev_print_area_print.width / 2)) /
                prev_px_per_1cm;
              const current_left_px =
                prev_left_cm * current_px_per_1cm +
                current_print_area_print.left +
                current_print_area_print.width / 2;
              cv_obj.left = current_left_px;
              cv_obj.scale(cv_obj.scaleX * scale_px);
            });
          if (_p.some(cv_objects, G.mp.maker.is_embro))
            _go(
              cv_objects,
              _p.filter(function (obj) {
                return obj._data.is_embro;
              }),
              _p.each(function (obj) {
                if (obj._data.is_embro) {
                  obj.width = NewMakerFcanvasFuncF.cmToPxByCurrentBpfBps({
                    cm: obj._data.image_width_cm,
                    base_product_face_id: canvas.bpf_id,
                  });
                  obj.height = NewMakerFcanvasFuncF.cmToPxByCurrentBpfBps({
                    cm: obj._data.image_height_cm,
                    base_product_face_id: canvas.bpf_id,
                  });
                  obj.scale(1);
                }
              }),
            );
          canvas.renderAll();
          resolve();
        });
      });
    });
  });
}
