import { compact, each, extend, go, flatten, map } from 'fxjs/es';
import { makeOutlineCanvas2, sourceOverCanvas } from '../../../../../Canvas/S/util.js';
import { CanvasS } from '../../../../../Canvas/S/Function/module/CanvasS.js';

const getFlattenCvDesigns = (cv_objs) => {
  return go(
    cv_objs,
    map((cv_obj) =>
      cv_obj.objects?.length || cv_obj._objects?.length
        ? getFlattenCvDesigns(cv_obj.objects || cv_obj._objects)
        : cv_obj,
    ),
    flatten,
  );
};

export const bpcColorCode2Render = {
  setShadeMaterialColorCode: (product_face, color_code) => {
    const shade_material = product_face.cv_preview?._data?.shade_material;
    if (!shade_material) return;
    shade_material.color_code = color_code;
  },
  makeColorRenderedCanvas: (src_image, color_code) => {
    if (color_code === '#000000') {
      color_code = '#171717';
    }
    if (color_code === '#ffffff') {
      color_code = '#f9f9f9';
    }
    const design_src = CanvasS.makeCanvasTexturedInS(src_image, color_code);
    return design_src;
  },
  makeRenderedCanvas: async (src_image, { color_code }) => {
    const design_src = bpcColorCode2Render.makeColorRenderedCanvas(src_image, color_code);
    const shade_canvas = await bpcColorCode2Render.getShadeCanvas({
      canvas: design_src,
      color_code,
    });
    return sourceOverCanvas(design_src, shade_canvas);
  },
  getShadeCanvas: async ({ canvas }) => {
    // const lighten_hex = go(
    //   color_code,
    //   hexToRGB,
    //   ({ r, g, b }) => {
    //     const a = Math.round(r + g + b / 3);
    //     return go(
    //       [a, a, a],
    //       map((n) => n + 40),
    //       map((n) => Math.min(255, n)),
    //     );
    //   },
    //   (rgb_arr) => rgbToHex(...rgb_arr),
    // );
    // const darken_hex = go(
    //   color_code,
    //   hexToRGB,
    //   ({ r, g, b }) => {
    //     const a = Math.round(r + g + b / 3);
    //     return go(
    //       [a, a, a],
    //       map((n) => n - 70),
    //       map((n) => Math.max(0, n)),
    //     );
    //   },
    //   (rgb_arr) => rgbToHex(...rgb_arr),
    // );
    // const shade = {
    //   texture_url: null,
    //   light_location: { top: -1, left: -1 },
    //   lighten_url: null,
    //   deep_step,
    //   lighten_hex,
    //   darken_hex,
    // };
    // const shadow = await CanvasS.makeShadowAndGeometryCanvasInS(canvas, { shade });
    // return shadow;
    const deep_px = -2;
    const shade_strength = 50;
    return sourceOverCanvas(canvas, makeOutlineCanvas2(canvas, deep_px, shade_strength));
  },
  setColorCode2: function (cv_obj, bpc) {
    const _datas = go(
      [cv_obj._data?.cv_text_image_attrs?._data, cv_obj._data?.cv_image_attrs?._data, cv_obj._data],
      compact,
    );
    go(
      _datas,
      each((_data) => {
        if (_data.cv_type === 'cv_text_image') {
          extend(_data, {
            only_flex: false,
            press_color_id: `bpc_${bpc.id}`,
            press_color_code: bpc.color_code2,
            press_color_name: bpc.name,
            press_color_name_en: bpc.name_en,
            press_color_name_jp: bpc.name_jp,
          });
        }
        _data.color_code2 = bpc.color_code2;
      }),
    );
  },
  changeColorData: (product_face, bpc) => {
    const shade_material = product_face.cv_preview?._data?.shade_material;
    if (!shade_material) return;
    go(
      product_face.designs,
      getFlattenCvDesigns,
      each((cv_obj) => bpcColorCode2Render.setColorCode2(cv_obj, bpc)),
    );
    shade_material.color_code = bpc.color_code2;
  },
};
