import { each, filter, find, go, mapObject, pick, reject, map, flatten } from 'fxjs/es';
import { minusStrokeWidth } from './stroke.js';

// export const makeFindCvObjByType = cv_type => cv_objs => find(obj => obj._data.cv_type === cv_type, cv_objs);
export const getCvObj = (cv_objs, cv_type) => find((obj) => obj._data.cv_type === cv_type, cv_objs);
export const makeFilterCvObj = (cv_objs, cv_type) => filter((obj) => obj._data.cv_type === cv_type, cv_objs);

export const getCvDesigns = (cv_objs) => reject((obj) => obj._data.is_not_design, cv_objs);
export const isCvDesignLikeImages = (cv_obj) =>
  cv_obj._data.cv_type === 'cv_image' || cv_obj._data.cv_type === 'cv_pattern';

export const getFcanvasFlattenCvObjs = (fcanvas) => {
  return go(fcanvas._objects, getCvDesigns, getFlattenCvDesigns);
};

export const getFlattenCvDesigns = (cv_objs) => {
  return go(
    cv_objs,
    map((cv_obj) =>
      cv_obj.objects?.length || cv_obj._objects?.length
        ? getFlattenCvDesigns(cv_obj.objects || cv_obj._objects)
        : cv_obj,
    ),
    flatten,
  );
};

export const getNotDesigns = (cv_objs) => filter((obj) => obj._data.is_not_design, cv_objs);

export const makeCvObjToJSON = (cv_obj) =>
  cv_obj.toJSON ? cv_obj.toJSON() : JSON.parse(JSON.stringify(cv_obj));

export const getCurrentFcanvas = () => box.sel('maker->editing_canvas');

export function makeCvObjsVisibleFalse(cv_objs) {
  go(
    cv_objs,
    filter((cv_obj) => cv_obj.visible),
    each((cv_obj) => (cv_obj._data._visible = true)),
    each((cv_obj) => (cv_obj.visible = false)),
  );
}

export function makeCvObjsVisibleTrue(cv_objs) {
  go(
    cv_objs,
    filter((cv_obj) => cv_obj._data._visible),
    each((cv_obj) => cv_obj.set('visible', true)),
    each((cv_obj) => {
      delete cv_obj._data._visible;
    }),
  );
}

export function getActiveCvObj(fcanvas) {
  if (!fcanvas.getActiveGroup) return;
  return fcanvas.getActiveGroup() || fcanvas.getActiveObject();
}
export function getRealLocation(fcanvas, cv_obj) {
  const ratio = fcanvas.lowerCanvasEl.width / G.mp.maker.CANVAS_WIDTH;
  return go(
    cv_obj,
    pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY', 'strokeWidth']),
    (cv_print_area) => {
      cv_print_area.width *= cv_print_area.scaleX;
      cv_print_area.height *= cv_print_area.scaleY;
      return cv_print_area;
    },
    minusStrokeWidth,
    mapObject((v) => v * ratio),
  );
}
export function getCvObjLocation(cv_obj) {
  return go(
    cv_obj,
    pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY', 'strokeWidth']),
    (cv_print_area) => {
      cv_print_area.width *= cv_print_area.scaleX;
      cv_print_area.height *= cv_print_area.scaleY;
      return cv_print_area;
    },
    minusStrokeWidth,
    mapObject((v) => Math.round(v)),
  );
}

export function getCvObjScaledSize(cv_obj) {
  return go(cv_obj, pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY']), (cv_print_area) => {
    cv_print_area.width *= cv_print_area.scaleX;
    cv_print_area.height *= cv_print_area.scaleY;
    return cv_print_area;
  });
}
