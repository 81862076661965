import { NewMakerPropertyBaseProductConstantS } from '../../S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { go, each, filter, find } from 'fxjs/es';
import { changeBpByBpId } from '../../../../../Maker/F/change_bp.js';
import { getFontColors, renderCvTextColor } from '../../../../../Maker/F/CvTextImage/fs.js';
import { getCvDesigns, getFlattenCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { marpplizerProxy } from '../../../../../Maker/F/Marpplizer/marpplizer.js';
import { getRealFcanvass } from '../../../../../Maker/F/getSth.js';
import { OMPDosuF } from '../../../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { $qs } from 'fxdom/es';
import { OMPDosuConstantS } from '../../../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';

async function applyBasicColorToCvTextImage({ cv_obj, font_color }) {
  const cv_type = cv_obj._data.cv_type;

  // ** 도수 색상 컬러 필터가 적용되는 대상
  if (['cv_text_image', 'cv_text_image_pattern'].includes(cv_type)) {
    const color_filter = new fabric.Image.filters.Tint({
      color: font_color.code,
      opacity: 1.0,
    });
    cv_obj.filters = [color_filter];
    renderCvTextColor(
      font_color,
      cv_type === 'cv_text_image_pattern' ? cv_obj._data.cv_text_image_attrs : cv_obj,
    );
    if (cv_obj._data.cv_text_image_attrs) {
      delete cv_obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
    } else {
      delete cv_obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
    }
  }

  return cv_obj.applyFilters
    ? new Promise((res) => {
        cv_obj.applyFilters(() => {
          cv_obj.filters = [];
          res();
        });
      })
    : null;
}
async function updateCvObjToDefaultColorCodesInBasic(fcanvas) {
  const font_colors = await getFontColors();
  const defalut_font_color =
    go(
      font_colors,
      find((f) => f.name === '노랑'),
    ) || font_colors[0];
  let is_show_message = false;
  await go(
    fcanvas._objects,
    getCvDesigns,
    getFlattenCvDesigns,
    filter(
      (cv_obj) =>
        cv_obj._data.cv_type === 'cv_text_image' || cv_obj._data.cv_type === 'cv_text_image_pattern',
    ),
    each(async (cv_text_image) => {
      const selected_font_color = go(
        font_colors,
        find((font_color) => {
          return (
            font_color.code.toUpperCase() ===
            (
              cv_text_image._data.press_color_code ||
              cv_text_image._data?.cv_text_image_attrs?._data?.press_color_code
            )?.toUpperCase()
          );
        }),
      );
      if (!selected_font_color) {
        is_show_message = true;
      }
      const font_color = selected_font_color || defalut_font_color;
      await applyBasicColorToCvTextImage({ cv_obj: cv_text_image, font_color });
    }),
  );
  if (is_show_message) {
    OMPDosuF.showToastMsg({
      text: TT('biz::dosu::warning_01'),
      target_el: $qs('#maker'),
    });
  }
  fcanvas.renderAll();
  G.mp.maker.reset_box_data();
  await marpplizerProxy();
  await G.mp.maker.reset_layer();
}
export const linkToBpId = {
  goTo: async () => {
    const link_to_bp_id = box.sel(
      `maker->product_color->_->base_product->maker_features->${NewMakerPropertyBaseProductConstantS.LINK_TO_BP_ID}`,
    );
    if (!link_to_bp_id) return;
    $.don_loader_start();
    const original_id = box.sel(`maker->product_color->_->base_product->_->biz_product->original_id`);
    if (!original_id) return;
    if (original_id) {
      await go(
        changeBpByBpId(original_id, true),
        async () => {
          await go(getRealFcanvass(), each(updateCvObjToDefaultColorCodesInBasic));
          await G.mp.maker.state.reset();
        },
        $.don_loader_end,
      );
    }
    return true;
  },
};
