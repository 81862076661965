import { find, go } from 'fxjs/es';
import { BpOptionS } from '../../../S/Function/module/BpOptionS.js';

export const prices = [
  { area: 4, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 6, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 8, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 9, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 10, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 12, price: 3200, price_en: 2.91, price_jp: 313 },
  { area: 14, price: 3600, price_en: 3.28, price_jp: 352 },
  { area: 15, price: 3800, price_en: 3.46, price_jp: 372 },
  { area: 16, price: 4000, price_en: 3.64, price_jp: 391 },
  { area: 18, price: 4500, price_en: 4.09, price_jp: 440 },
  { area: 20, price: 4900, price_en: 4.46, price_jp: 479 },
  { area: 21, price: 5100, price_en: 4.64, price_jp: 499 },
  { area: 22, price: 5400, price_en: 4.91, price_jp: 528 },
  { area: 24, price: 5800, price_en: 5.28, price_jp: 568 },
  { area: 25, price: 6000, price_en: 5.46, price_jp: 587 },
  { area: 26, price: 6200, price_en: 5.64, price_jp: 607 },
  { area: 27, price: 6500, price_en: 5.91, price_jp: 636 },
  { area: 28, price: 6700, price_en: 6.1, price_jp: 656 },
  { area: 30, price: 7100, price_en: 6.46, price_jp: 695 },
  { area: 32, price: 7600, price_en: 6.92, price_jp: 744 },
  { area: 33, price: 7800, price_en: 7.1, price_jp: 763 },
  { area: 34, price: 8000, price_en: 7.28, price_jp: 783 },
  { area: 35, price: 8200, price_en: 7.46, price_jp: 802 },
  { area: 36, price: 8400, price_en: 7.64, price_jp: 822 },
  { area: 38, price: 8900, price_en: 8.1, price_jp: 871 },
  { area: 39, price: 9100, price_en: 8.28, price_jp: 890 },
  { area: 40, price: 9300, price_en: 8.46, price_jp: 910 },
  { area: 42, price: 9800, price_en: 8.92, price_jp: 959 },
  { area: 44, price: 10200, price_en: 9.28, price_jp: 998 },
  { area: 45, price: 10400, price_en: 9.46, price_jp: 1018 },
  { area: 48, price: 11100, price_en: 10.1, price_jp: 1086 },
  { area: 49, price: 11300, price_en: 10.28, price_jp: 1106 },
  { area: 50, price: 11500, price_en: 10.46, price_jp: 1125 },
  { area: 51, price: 11700, price_en: 10.65, price_jp: 1145 },
  { area: 52, price: 12000, price_en: 10.92, price_jp: 1174 },
  { area: 54, price: 12400, price_en: 11.28, price_jp: 1213 },
  { area: 55, price: 12600, price_en: 11.46, price_jp: 1233 },
  { area: 56, price: 12800, price_en: 11.65, price_jp: 1252 },
  { area: 57, price: 13100, price_en: 11.92, price_jp: 1282 },
  { area: 60, price: 13700, price_en: 12.47, price_jp: 1341 },
  { area: 63, price: 14400, price_en: 13.1, price_jp: 1409 },
  { area: 64, price: 14600, price_en: 13.28, price_jp: 1429 },
  { area: 65, price: 14800, price_en: 13.47, price_jp: 1448 },
  { area: 66, price: 15000, price_en: 13.65, price_jp: 1468 },
  { area: 68, price: 15500, price_en: 14.1, price_jp: 1517 },
  { area: 70, price: 15900, price_en: 14.47, price_jp: 1556 },
  { area: 72, price: 16400, price_en: 14.92, price_jp: 1605 },
  { area: 75, price: 17000, price_en: 15.47, price_jp: 1663 },
  { area: 76, price: 17200, price_en: 15.65, price_jp: 1683 },
  { area: 77, price: 17500, price_en: 15.92, price_jp: 1712 },
  { area: 78, price: 17700, price_en: 16.11, price_jp: 1732 },
  { area: 80, price: 18100, price_en: 16.47, price_jp: 1771 },
  { area: 81, price: 18300, price_en: 16.65, price_jp: 1791 },
  { area: 84, price: 19000, price_en: 17.29, price_jp: 1859 },
  { area: 85, price: 19200, price_en: 17.47, price_jp: 1879 },
  { area: 88, price: 19900, price_en: 18.11, price_jp: 1947 },
  { area: 90, price: 20300, price_en: 18.47, price_jp: 1986 },
  { area: 91, price: 20500, price_en: 18.65, price_jp: 2006 },
  { area: 95, price: 21400, price_en: 19.47, price_jp: 2094 },
  { area: 96, price: 21600, price_en: 19.65, price_jp: 2114 },
  { area: 98, price: 22100, price_en: 20.11, price_jp: 2162 },
  { area: 99, price: 22300, price_en: 20.29, price_jp: 2182 },
  { area: 100, price: 22500, price_en: 20.47, price_jp: 2202 },
  { area: 102, price: 23000, price_en: 20.93, price_jp: 2250 },
  { area: 104, price: 23400, price_en: 21.29, price_jp: 2290 },
  { area: 105, price: 23600, price_en: 21.47, price_jp: 2309 },
  { area: 108, price: 24300, price_en: 22.11, price_jp: 2378 },
  { area: 110, price: 24700, price_en: 22.47, price_jp: 2417 },
  { area: 112, price: 25200, price_en: 22.93, price_jp: 2466 },
  { area: 114, price: 25600, price_en: 23.29, price_jp: 2505 },
  { area: 117, price: 26300, price_en: 23.93, price_jp: 2573 },
  { area: 119, price: 26700, price_en: 24.29, price_jp: 2613 },
  { area: 120, price: 26900, price_en: 24.48, price_jp: 2632 },
  { area: 121, price: 27100, price_en: 24.66, price_jp: 2652 },
  { area: 126, price: 28200, price_en: 25.66, price_jp: 2759 },
  { area: 128, price: 28700, price_en: 26.11, price_jp: 2808 },
  { area: 130, price: 29100, price_en: 26.48, price_jp: 2847 },
  { area: 132, price: 29600, price_en: 26.93, price_jp: 2896 },
  { area: 133, price: 29800, price_en: 27.12, price_jp: 2916 },
  { area: 135, price: 30200, price_en: 27.48, price_jp: 2955 },
  { area: 136, price: 30400, price_en: 27.66, price_jp: 2975 },
  { area: 140, price: 31300, price_en: 28.48, price_jp: 3063 },
  { area: 143, price: 32000, price_en: 29.12, price_jp: 3131 },
  { area: 144, price: 32200, price_en: 29.3, price_jp: 3151 },
  { area: 150, price: 33500, price_en: 30.48, price_jp: 3278 },
  { area: 152, price: 34000, price_en: 30.94, price_jp: 3327 },
  { area: 153, price: 34200, price_en: 31.12, price_jp: 3346 },
  { area: 154, price: 34400, price_en: 31.3, price_jp: 3366 },
  { area: 156, price: 34800, price_en: 31.67, price_jp: 3405 },
  { area: 160, price: 35700, price_en: 32.48, price_jp: 3493 },
  { area: 162, price: 36200, price_en: 32.94, price_jp: 3542 },
  { area: 165, price: 36800, price_en: 33.48, price_jp: 3601 },
  { area: 168, price: 37500, price_en: 34.12, price_jp: 3669 },
  { area: 169, price: 37700, price_en: 34.3, price_jp: 3689 },
  { area: 170, price: 37900, price_en: 34.49, price_jp: 3708 },
  { area: 171, price: 38100, price_en: 34.67, price_jp: 3728 },
  { area: 176, price: 39200, price_en: 35.67, price_jp: 3836 },
  { area: 180, price: 40100, price_en: 36.49, price_jp: 3924 },
  { area: 182, price: 40600, price_en: 36.94, price_jp: 3973 },
  { area: 187, price: 41700, price_en: 37.94, price_jp: 4080 },
  { area: 190, price: 42300, price_en: 38.49, price_jp: 4139 },
  { area: 192, price: 42800, price_en: 38.94, price_jp: 4188 },
  { area: 195, price: 43400, price_en: 39.49, price_jp: 4247 },
  { area: 196, price: 43600, price_en: 39.67, price_jp: 4266 },
  { area: 198, price: 44100, price_en: 40.13, price_jp: 4315 },
  { area: 200, price: 44500, price_en: 40.49, price_jp: 4354 },
  { area: 204, price: 45400, price_en: 41.31, price_jp: 4442 },
  { area: 208, price: 46300, price_en: 42.13, price_jp: 4530 },
  { area: 209, price: 46500, price_en: 42.31, price_jp: 4550 },
  { area: 210, price: 46700, price_en: 42.49, price_jp: 4569 },
  { area: 216, price: 48000, price_en: 43.68, price_jp: 4697 },
  { area: 220, price: 48900, price_en: 44.49, price_jp: 4785 },
  { area: 221, price: 49100, price_en: 44.68, price_jp: 4804 },
  { area: 224, price: 49800, price_en: 45.31, price_jp: 4873 },
  { area: 225, price: 50000, price_en: 45.5, price_jp: 4892 },
  { area: 228, price: 50700, price_en: 46.13, price_jp: 4961 },
  { area: 234, price: 52000, price_en: 47.32, price_jp: 5088 },
  { area: 238, price: 52900, price_en: 48.13, price_jp: 5176 },
  { area: 240, price: 53300, price_en: 48.5, price_jp: 5215 },
  { area: 247, price: 54900, price_en: 49.95, price_jp: 5372 },
  { area: 252, price: 56000, price_en: 50.96, price_jp: 5479 },
  { area: 255, price: 56600, price_en: 51.5, price_jp: 5538 },
  { area: 256, price: 56800, price_en: 51.68, price_jp: 5558 },
  { area: 260, price: 57700, price_en: 52.5, price_jp: 5646 },
  { area: 266, price: 59000, price_en: 53.69, price_jp: 5773 },
  { area: 270, price: 59900, price_en: 54.5, price_jp: 5861 },
  { area: 272, price: 60400, price_en: 54.96, price_jp: 5910 },
  { area: 280, price: 62100, price_en: 56.51, price_jp: 6076 },
  { area: 285, price: 63200, price_en: 57.51, price_jp: 6184 },
  { area: 288, price: 63900, price_en: 58.14, price_jp: 6252 },
  { area: 289, price: 64100, price_en: 58.33, price_jp: 6272 },
  { area: 300, price: 66500, price_en: 60.51, price_jp: 6507 },
  { area: 304, price: 67400, price_en: 61.33, price_jp: 6595 },
  { area: 306, price: 67800, price_en: 61.69, price_jp: 6634 },
  { area: 320, price: 70900, price_en: 64.51, price_jp: 6937 },
  { area: 323, price: 71600, price_en: 65.15, price_jp: 7006 },
  { area: 324, price: 71800, price_en: 65.33, price_jp: 7025 },
  { area: 340, price: 75300, price_en: 68.52, price_jp: 7368 },
  { area: 342, price: 75800, price_en: 68.97, price_jp: 7417 },
  { area: 360, price: 79700, price_en: 72.52, price_jp: 7798 },
  { area: 361, price: 79900, price_en: 72.7, price_jp: 7818 },
  { area: 380, price: 84100, price_en: 76.52, price_jp: 8229 },
  { area: 400, price: 88500, price_en: 80.53, price_jp: 8659 },
];

const prices2 = [
  { area: 4, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 6, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 8, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 9, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 10, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 12, price: 2600, price_en: 2.37, price_jp: 254 },
  { area: 14, price: 2900, price_en: 2.64, price_jp: 284 },
  { area: 15, price: 3000, price_en: 2.73, price_jp: 294 },
  { area: 16, price: 3200, price_en: 2.91, price_jp: 313 },
  { area: 18, price: 3600, price_en: 3.28, price_jp: 352 },
  { area: 20, price: 3900, price_en: 3.55, price_jp: 382 },
  { area: 21, price: 4000, price_en: 3.64, price_jp: 391 },
  { area: 22, price: 4300, price_en: 3.91, price_jp: 421 },
  { area: 24, price: 4600, price_en: 4.19, price_jp: 450 },
  { area: 25, price: 4700, price_en: 4.28, price_jp: 460 },
  { area: 26, price: 4900, price_en: 4.46, price_jp: 479 },
  { area: 27, price: 5100, price_en: 4.64, price_jp: 499 },
  { area: 28, price: 5300, price_en: 4.82, price_jp: 519 },
  { area: 30, price: 5600, price_en: 5.1, price_jp: 548 },
  { area: 32, price: 6000, price_en: 5.46, price_jp: 587 },
  { area: 33, price: 6200, price_en: 5.64, price_jp: 607 },
  { area: 34, price: 6300, price_en: 5.73, price_jp: 616 },
  { area: 35, price: 6500, price_en: 5.91, price_jp: 636 },
  { area: 36, price: 6600, price_en: 6.01, price_jp: 646 },
  { area: 38, price: 6900, price_en: 6.28, price_jp: 675 },
  { area: 39, price: 7100, price_en: 6.46, price_jp: 695 },
  { area: 40, price: 7300, price_en: 6.64, price_jp: 714 },
  { area: 42, price: 7600, price_en: 6.92, price_jp: 744 },
  { area: 44, price: 8000, price_en: 7.28, price_jp: 783 },
  { area: 45, price: 8100, price_en: 7.37, price_jp: 793 },
  { area: 48, price: 8700, price_en: 7.92, price_jp: 851 },
  { area: 49, price: 8800, price_en: 8.01, price_jp: 861 },
  { area: 50, price: 9000, price_en: 8.19, price_jp: 881 },
  { area: 51, price: 9100, price_en: 8.28, price_jp: 890 },
  { area: 52, price: 9400, price_en: 8.55, price_jp: 920 },
  { area: 54, price: 9700, price_en: 8.83, price_jp: 949 },
  { area: 55, price: 9800, price_en: 8.92, price_jp: 959 },
  { area: 56, price: 10000, price_en: 9.1, price_jp: 978 },
  { area: 57, price: 10200, price_en: 9.28, price_jp: 998 },
  { area: 60, price: 10700, price_en: 9.74, price_jp: 1047 },
  { area: 63, price: 11200, price_en: 10.19, price_jp: 1096 },
  { area: 64, price: 11200, price_en: 10.19, price_jp: 1096 },
  { area: 65, price: 11400, price_en: 10.37, price_jp: 1115 },
  { area: 66, price: 11600, price_en: 10.56, price_jp: 1135 },
  { area: 68, price: 11900, price_en: 10.83, price_jp: 1164 },
  { area: 70, price: 12200, price_en: 11.1, price_jp: 1194 },
  { area: 72, price: 12500, price_en: 11.37, price_jp: 1223 },
  { area: 75, price: 12500, price_en: 11.37, price_jp: 1223 },
  { area: 76, price: 12600, price_en: 11.46, price_jp: 1233 },
  { area: 77, price: 12600, price_en: 11.46, price_jp: 1233 },
  { area: 78, price: 12700, price_en: 11.56, price_jp: 1243 },
  { area: 80, price: 13000, price_en: 11.83, price_jp: 1272 },
  { area: 81, price: 13100, price_en: 11.92, price_jp: 1282 },
  { area: 84, price: 13500, price_en: 12.28, price_jp: 1321 },
  { area: 85, price: 13600, price_en: 12.37, price_jp: 1331 },
  { area: 88, price: 13900, price_en: 12.65, price_jp: 1360 },
  { area: 90, price: 14000, price_en: 12.74, price_jp: 1370 },
  { area: 91, price: 14100, price_en: 12.83, price_jp: 1380 },
  { area: 95, price: 14600, price_en: 13.28, price_jp: 1429 },
  { area: 96, price: 14800, price_en: 13.47, price_jp: 1448 },
  { area: 98, price: 15000, price_en: 13.65, price_jp: 1468 },
  { area: 99, price: 15300, price_en: 13.92, price_jp: 1497 },
  { area: 100, price: 15400, price_en: 14.01, price_jp: 1507 },
  { area: 102, price: 15800, price_en: 14.38, price_jp: 1546 },
  { area: 104, price: 16000, price_en: 14.56, price_jp: 1566 },
  { area: 105, price: 16200, price_en: 14.74, price_jp: 1585 },
  { area: 108, price: 16600, price_en: 15.1, price_jp: 1624 },
  { area: 110, price: 17000, price_en: 15.47, price_jp: 1663 },
  { area: 112, price: 17100, price_en: 15.56, price_jp: 1673 },
  { area: 114, price: 17400, price_en: 15.83, price_jp: 1703 },
  { area: 117, price: 17900, price_en: 16.29, price_jp: 1751 },
  { area: 119, price: 18200, price_en: 16.56, price_jp: 1781 },
  { area: 120, price: 18300, price_en: 16.65, price_jp: 1791 },
  { area: 121, price: 18400, price_en: 16.74, price_jp: 1800 },
  { area: 126, price: 19200, price_en: 17.47, price_jp: 1879 },
  { area: 128, price: 19200, price_en: 17.47, price_jp: 1879 },
  { area: 130, price: 19500, price_en: 17.74, price_jp: 1908 },
  { area: 132, price: 19800, price_en: 18.02, price_jp: 1937 },
  { area: 133, price: 20000, price_en: 18.2, price_jp: 1957 },
  { area: 135, price: 20200, price_en: 18.38, price_jp: 1977 },
  { area: 136, price: 20400, price_en: 18.56, price_jp: 1996 },
  { area: 140, price: 20700, price_en: 18.84, price_jp: 2025 },
  { area: 143, price: 20800, price_en: 18.93, price_jp: 2035 },
  { area: 144, price: 20900, price_en: 19.02, price_jp: 2045 },
  { area: 150, price: 21800, price_en: 19.84, price_jp: 2133 },
  { area: 152, price: 22100, price_en: 20.11, price_jp: 2162 },
  { area: 153, price: 22200, price_en: 20.2, price_jp: 2172 },
  { area: 154, price: 22400, price_en: 20.38, price_jp: 2192 },
  { area: 156, price: 22600, price_en: 20.56, price_jp: 2211 },
  { area: 160, price: 23200, price_en: 21.11, price_jp: 2270 },
  { area: 162, price: 23500, price_en: 21.38, price_jp: 2299 },
  { area: 165, price: 23600, price_en: 21.47, price_jp: 2309 },
  { area: 168, price: 24000, price_en: 21.84, price_jp: 2348 },
  { area: 169, price: 24100, price_en: 21.93, price_jp: 2358 },
  { area: 170, price: 24300, price_en: 22.11, price_jp: 2378 },
  { area: 171, price: 24400, price_en: 22.2, price_jp: 2387 },
  { area: 176, price: 24700, price_en: 22.47, price_jp: 2417 },
  { area: 180, price: 24900, price_en: 22.66, price_jp: 2436 },
  { area: 182, price: 25200, price_en: 22.93, price_jp: 2466 },
  { area: 187, price: 25400, price_en: 23.11, price_jp: 2485 },
  { area: 190, price: 25800, price_en: 23.48, price_jp: 2524 },
  { area: 192, price: 26100, price_en: 23.75, price_jp: 2554 },
  { area: 195, price: 26500, price_en: 24.11, price_jp: 2593 },
  { area: 196, price: 26600, price_en: 24.2, price_jp: 2603 },
  { area: 198, price: 26900, price_en: 24.48, price_jp: 2632 },
  { area: 200, price: 27100, price_en: 24.66, price_jp: 2652 },
  { area: 204, price: 27700, price_en: 25.2, price_jp: 2710 },
  { area: 208, price: 27800, price_en: 25.3, price_jp: 2720 },
  { area: 209, price: 27900, price_en: 25.39, price_jp: 2730 },
  { area: 210, price: 28000, price_en: 25.48, price_jp: 2740 },
  { area: 216, price: 28800, price_en: 26.21, price_jp: 2818 },
  { area: 220, price: 29300, price_en: 26.66, price_jp: 2867 },
  { area: 221, price: 29500, price_en: 26.84, price_jp: 2886 },
  { area: 224, price: 29900, price_en: 27.21, price_jp: 2926 },
  { area: 225, price: 30000, price_en: 27.3, price_jp: 2935 },
  { area: 228, price: 30400, price_en: 27.66, price_jp: 2975 },
  { area: 234, price: 31200, price_en: 28.39, price_jp: 3053 },
  { area: 238, price: 31700, price_en: 28.84, price_jp: 3102 },
  { area: 240, price: 32000, price_en: 29.12, price_jp: 3131 },
  { area: 247, price: 32900, price_en: 29.94, price_jp: 3219 },
  { area: 252, price: 33600, price_en: 30.57, price_jp: 3288 },
  { area: 255, price: 33700, price_en: 30.66, price_jp: 3297 },
  { area: 256, price: 33800, price_en: 30.76, price_jp: 3307 },
  { area: 260, price: 33800, price_en: 30.76, price_jp: 3307 },
  { area: 266, price: 34000, price_en: 30.94, price_jp: 3327 },
  { area: 270, price: 34300, price_en: 31.21, price_jp: 3356 },
  { area: 272, price: 35000, price_en: 31.85, price_jp: 3425 },
  { area: 280, price: 35700, price_en: 32.48, price_jp: 3493 },
  { area: 285, price: 36000, price_en: 32.76, price_jp: 3523 },
  { area: 288, price: 36200, price_en: 32.94, price_jp: 3542 },
  { area: 289, price: 36200, price_en: 32.94, price_jp: 3542 },
  { area: 300, price: 36600, price_en: 33.3, price_jp: 3581 },
  { area: 304, price: 36900, price_en: 33.58, price_jp: 3611 },
  { area: 306, price: 37000, price_en: 33.67, price_jp: 3620 },
  { area: 320, price: 37200, price_en: 33.85, price_jp: 3640 },
  { area: 323, price: 37900, price_en: 34.49, price_jp: 3708 },
  { area: 324, price: 38100, price_en: 34.67, price_jp: 3728 },
  { area: 340, price: 38400, price_en: 34.94, price_jp: 3757 },
  { area: 342, price: 38700, price_en: 35.21, price_jp: 3787 },
  { area: 360, price: 39100, price_en: 35.58, price_jp: 3826 },
  { area: 361, price: 40000, price_en: 36.4, price_jp: 3914 },
  { area: 380, price: 41200, price_en: 37.49, price_jp: 4031 },
  { area: 400, price: 41600, price_en: 37.85, price_jp: 4070 },
];
export function makePrice(fitted_size, basic_price) {
  const is_ness = BpOptionS.needNessPrice();
  const its_area = Math.ceil(fitted_size.width * 0.1) * Math.ceil(fitted_size.height * 0.1);
  const price = find(({ area }) => area >= its_area)(is_ness ? prices2 : prices);
  return {
    price: price.price - basic_price.price,
    price_en: price.price_en - basic_price.price_en,
    price_jp: price.price_jp - basic_price.price_jp,
  };
}

export function setBpOptionByFittedSize({
  base_products_bp_option_groups,
  selected_option_group,
  fitted_size,
  product,
}) {
  const size_bp_option = BpOptionS.selectFittedSizeBpOption({
    base_products_bp_option_groups,
    selected_option_group,
  });
  console.log('size_bp_option', size_bp_option);
  console.log('fitted_size', fitted_size);

  if (!fitted_size) return;
  if (!size_bp_option) return;
  const basic_price = {
    price: product._.base_product.price,
    price_en: product._.base_product.price_en,
    price_jp: product._.base_product.price_jp,
  };
  const price = makePrice(fitted_size, basic_price);
  size_bp_option.price = price.price;
  size_bp_option.price_en = price.price_en;
  size_bp_option.price_jp = price.price_jp;
  const bottom_stand_size = 3;
  const height = fitted_size.height + bottom_stand_size;
  size_bp_option.name = `${fitted_size.width} x ${height} mm`;
  size_bp_option.name_en = `${(fitted_size.width / 2.54).toFixed(2)} x ${(height / 2.54).toFixed(2)} inch`;
  size_bp_option.name_jp = size_bp_option.name;
  return price;
}

export function setPriceByFittedSizeWhenStart({
  base_products_bp_option_groups,
  selected_option_group,
  product,
}) {
  if (!product.product_faces2) return;
  const cv_obj = go(
    product.product_faces2.value[0].designs,
    find((cv_obj) => cv_obj.cid == 'c2323'),
  );
  if (!cv_obj) return;
  const { fitted_size } = cv_obj._data;
  setBpOptionByFittedSize({ product, base_products_bp_option_groups, selected_option_group, fitted_size });
}
