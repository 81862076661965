import { $delegate, $el, $qs } from 'fxdom/es';
import { go, html } from 'fxjs/es';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { BpOptionS } from '../../../BpOption/S/Function/module/BpOptionS.js';
import { BpOptionTmplS } from '../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import { discontinuedSizeIds, excludingDiscontinued, soldOutSizeIds } from '../../../ProductColor/S/fs.js';
import { getIsBizProduct, setPriceKeyringAndAcryl } from '../../S/product.js';
import { removeCvBlueLineInMaker } from '../cv_blue_line.js';
import {
  getBaseProductColorsInMaker,
  getBaseProductSizesInMaker,
  getCurrentBpcId,
  getCurrentBpsId,
  getProductColorInMaker,
} from '../getSth.js';
import { eventProductBpcA, getBpcTitle, getCurrentBpcName } from '../Product/bpc_fs.js';
import { makeProductBpcPcHtml } from '../Product/bpc_tmpl.js';
import { eventProductBpsA } from '../Product/bps_fs.js';
import { makeBpsSizeItemHtml } from '../Product/bps_tmpl.js';
import { open_chooser } from '../Product/product_fs.js';
import { product_editor_product_tmpl } from '../Product/product_tmpl.js';
import { fcanvasChanging } from '../util.js';
import { bizOptionMenus } from './biz.js';
import { clickHandlerForReviewSummaryCount } from './fs.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { BpOptionUploadTypeS } from '../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';

function makeArgsForBpsSizeItemHtml() {
  return [
    excludingDiscontinued(
      getBaseProductSizesInMaker(),
      discontinuedSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker()),
    ),
    getCurrentBpsId(),
    soldOutSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker()),
  ];
}

const event = (el) =>
  go(
    el,
    $delegate(
      'click',
      '.product_editor_ev .color_item:not(.selected)',
      fcanvasChanging(async function (e) {
        $.don_loader_start();
        removeCvBlueLineInMaker();
        await eventProductBpcA(e.currentTarget);
        $1('#marpplizer .product_editor .size.section').outerHTML = makeBpsSizeItemHtml(
          ...makeArgsForBpsSizeItemHtml(),
        );
        $.don_loader_end();
      }),
    ),
    $delegate('click', '.open_chooser', open_chooser),
    $delegate('click', '.review_summary .counts', clickHandlerForReviewSummaryCount),
    $delegate(
      'click',
      '.product_editor_ev .size.section li:not(.selected)',
      fcanvasChanging(eventProductBpsA),
    ),
  );

export const marpplizer_home = async function () {
  const product_color = box().maker.product_color;

  const { is_pure_biz_product } = getIsBizProduct(product_color);
  const biz_option_values = bizOptionMenus.get.bizOptionValuesFromBox();
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(
    product_color._.base_product,
  )?.length;
  const bp_discount_ranges = BpOptionS.getBpDiscountRanges(product_color);
  const base_products_bp_option_groups = product_color._.base_product._.base_products_bp_option_groups;
  const id = 'marpplizer_home';
  setPriceKeyringAndAcryl(product_color);
  BpOptionF.makeSelectDisabled();
  const html_body = html`
    <div class="main_head">Product</div>
    ${product_editor_product_tmpl(getProductColorInMaker())}
    ${has_bp_option_groups
      ? ``
      : makeProductBpcPcHtml(
          getBaseProductColorsInMaker(),
          getCurrentBpcName(),
          getBpcTitle(),
          getCurrentBpcId(),
        )}
    ${has_bp_option_groups ? `` : makeBpsSizeItemHtml(...makeArgsForBpsSizeItemHtml())}
    ${has_bp_option_groups
      ? html`
          <div class="section maker-product-info__bp-option-groups">
            ${BpOptionTmplS.bpAllOptions({
              product: product_color,
            })}
          </div>
        `
      : ``}
    ${BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(base_products_bp_option_groups)
      ? html`
          <div class="section maker-product-info__upload-type">
            ${BpOptionUploadTypeS.UPLOAD_TYPE.uploadHtml(
              (product_color._.svg_product_materials &&
                product_color._.svg_product_materials[0]?.original_svg_file_name) ||
                product_color._.svg_product?._?.svg_product_pages[0]?._?.svg_image?.original_name,
            )}
          </div>
        `
      : ``}

    <!-- 도수 색상 카드 -->
    ${BpOptionF.bizDosuCard.get.html()}

    <!-- 비즈 전용 옵션 메뉴 -->
    ${BpOptionTmplS.biz.get.html.adapter({
      is_render: is_pure_biz_product,
      biz_option_values,
      bp_discount_ranges,
    })}
    ${T.lang == 'kr' && G.collabo_type == ''
      ? html`
          <div class="delivery-price section">
            <div class="head">${T('배송비')}</div>
            <div class="body">
              ${product_color._.base_product?.maker_features?.[
                NewMakerPropertyBaseProductConstantS.DIFFERENT_DELIVERY_FEE
              ]?.message || '3,000원'}
            </div>
          </div>
        `
      : ''}
  `;
  /* (도서 산간 지역의 경우, 추가 비용 3,000원이 발생합니다.)*/
  return {
    name: 'home',
    app: 'base1',
    tooltips: [
      {
        content: go(
          html` <div style="text-align: center;">${TT('biz::opt::title::tippy_content')}</div> `,
          $el,
        ),
        target_unique_class: '.group_discount_guide',
      },
    ],
    html: html`
      <div class="activity" id="${id}">
        <div class="body product_editor product_editor_ev marpplizer_contents_wrapper">${html_body}</div>
      </div>
    `,
    render: function () {
      $qs('#marpplizer_home >.body').innerHTML = html_body;
      BpOptionF.makeSelectDisabled();
      return $qs('#marpplizer_home');
    },
    event,
  };
};
