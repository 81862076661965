import { go, tap } from 'fxjs/es';
import { $addClass, $delegate, $find, $hasClass, $qs, $removeClass } from 'fxdom/es';

export const init = (el, headroom) => {
  // todo :: 임시로 에러 안나게 처리
  if (!el) return;

  const wrapper$ = el;
  const drawer$ = $qs('.drawer__inner');
  const bottom_tab_bar$ = $qs('.bottom-tab-bar');
  const slide_in_classname = 'drawer__inner--slide-in';
  const slide_out_classname = 'drawer__inner--slide-out';

  go(
    wrapper$,
    $delegate('click', 'a', () => {
      go(drawer$, tap($addClass('drawer__inner--keep-open')), $removeClass(slide_in_classname));
      if (bottom_tab_bar$) $removeClass('bottom-tab-bar--hide', bottom_tab_bar$);
    }),
    $delegate('click', '.drawer-login__button', (e) => {
      e.originalEvent.preventDefault();
      location.href = `/${T.lang}/login?url=${location.pathname}${location.search}`;
    }),
    $delegate('click', '.drawer__close', () => {
      go(
        drawer$,
        tap($addClass(slide_in_classname)),
        tap($removeClass('drawer__inner--keep-open')),
        tap($addClass(slide_out_classname)),
      );
      if (bottom_tab_bar$) $removeClass('bottom-tab-bar--hide', bottom_tab_bar$);
    }),
    // $delegate('click', '.drawer-banner__button--apply', (e) => {
    //   if (!MShopUtilF.isApp()) return;
    //   e.originalEvent.preventDefault();
    //   MShopUtilF.postMessage({
    //     navigate: {
    //       name: 'SignUpSeller',
    //       params: {
    //         uri: `/${T.lang}/@/hello`,
    //       },
    //     },
    //   });
    // }),
    tap($find('.drawer__inner'), (el$) =>
      el$.addEventListener('animationend', ({ currentTarget }) => {
        if ($hasClass(slide_out_classname, currentTarget)) {
          go(currentTarget, $removeClass(`${slide_in_classname} ${slide_out_classname}`));
          if (headroom) {
            headroom.unfreeze();
          } else {
            $removeClass('creator-mask--is-open', $qs('.creator-mask'));
          }
          $.body_fixed(false);
        }
      }),
    ),
  );
};
