import { compact, flatMap, go, join, map, reject, sort, filter } from 'fxjs/es';
import { $data, $find, $findAll } from 'fxdom/es';

export const setSelectedOptionGroupBpOptions = (selected_option_group) => {
  selected_option_group._ = selected_option_group._ || {};
  selected_option_group._.bp_options = go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    flatMap((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group._.bp_options),
    filter((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
  return selected_option_group;
};
export const makeSelectedOptionGroup = (bp_option_groups_el) => {
  const bp_option_ids = go(
    bp_option_groups_el,
    $findAll('.bp_option_group'),
    map($find('select')),
    map((select_el) => parseInt(select_el.value)),
    sort,
  );

  const title_option_els = go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_name="true"] select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => $find(`option[value="${select_el.value}"]`)(select_el)),
  );
  const option_names = go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_name="true"] select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => {
      const option_group = $data(select_el);
      const option = $data($find(`option[value="${select_el.value}"]`)(select_el));
      return {
        option_group_name: option_group.name,
        option_group_name_en: option_group.name_en,
        option_group_name_jp: option_group.name_jp,
        option_name: option.name,
        option_name_en: option.name_en,
        option_name_jp: option.name_jp,
      };
    }),
  );

  return go(
    {
      bp_option_ids,
      title:
        go(
          title_option_els,
          map((el) => el?.dataset?.name),
          join('-'),
        ) || '',
      title_en:
        go(
          title_option_els,
          map((el) => el?.dataset?.name_en),
          join('-'),
        ) || '',
      title_jp:
        go(
          title_option_els,
          map((el) => el?.dataset?.name_jp),
          join('-'),
        ) || '',
      option_names,
    },
    setSelectedOptionGroupBpOptions,
  );
};
