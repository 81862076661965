import { html } from 'fxjs/es';
import { emptyCheckBoxIcon, fullCheckBoxIcon } from './icon.js';

export const checkbox = ({ name, active, title, color } = {}) => {
  return html`
    <label class="mshop-core__checkbox">
      <input type="checkbox" ${active ? 'checked' : ''} name="${name || ''}" />
      <span class="mshop-core__checkbox__box-wrapper">
        ${emptyCheckBoxIcon('mshop-core__checkbox__empty', color)}
        ${fullCheckBoxIcon('mshop-core__checkbox__full')}
      </span>
      <span class="mshop-core__checkbox__title">${title}</span>
    </label>
  `;
};
