import { $delegate } from 'fxdom/es';
import { pipe } from 'fxjs/es';
import { MarppleCustomDetailF } from '../../../Marpple/CustomDetail/F/Function/module/MarppleCustomDetailF.js';
import { resetUserCounts } from '../../../MPay/Cart/F/fs.js';
import { NewMakerCvObjectCvBackgroundF } from '../../../NewMaker/CvObject/CvBackground/F/Function/module/NewMakerCvObjectCvBackgroundF.js';
import { NewMakerCvObjectCvImageF } from '../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { addLineSticker } from '../image.js';
import { open_chooser } from '../Product/product_fs.js';
import { go_sharing } from '../share.js';
import { addCvTextImageInMakerA } from '../text.js';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { openFrameLocOptimization } from '../LocOptimization.js';

export const makeDecorationPcEvent = pipe(
  $delegate('click', '.decoration_menu_for_pc .open_chooser', errorHandlingEventWrapper(open_chooser)),
  $delegate('click', '.decoration_menu_for_pc .open_cv_background_marpplizer', () =>
    errorHandlingEventWrapper(NewMakerCvObjectCvBackgroundF.openCvBackgroundMarpplizer()),
  ),
  $delegate('click', '.decoration_menu_for_pc .share_j', errorHandlingEventWrapper(go_sharing)),
  $.on3(
    'click',
    '.decoration_menu_for_pc .add_text',
    errorHandlingEventWrapper(() => addCvTextImageInMakerA()),
  ),
  $delegate(
    'click',
    '.decoration_menu_for_pc .loc_optimization_btn',
    errorHandlingEventWrapper(openFrameLocOptimization),
  ),
  $delegate(
    'click',
    '.decoration_menu_for_pc .add_sticker2',
    errorHandlingEventWrapper(function () {
      $.frame.open(
        {
          is_modal: !UtilF.isLegacyMobile(),
          frame_name: 'maker.sticker2',
          page_name: 'maker_sticker_page',
          appending: __($.attr('is_fixed_head', 'true')),
          closing: function (don_frame, image) {
            if (!image) return;
            return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
          },
        },
        {
          page_name: 'maker_sticker_page',
          hide_frame_button_type: 'X',
          tabs: [
            {
              tab_name: 'maker_sticker_tab',
              selected: true,
            },
            {
              tab_name: 'maker_embro_tab',
            },
          ],
        },
      );
    }),
  ),
  $delegate(
    'click',
    '.decoration_menu_for_pc .add_sticker_custom',
    errorHandlingEventWrapper(MarppleCustomDetailF.addCustomSticker),
  ),
  $delegate('click', '.decoration_menu_for_pc .add_sticker_line', errorHandlingEventWrapper(addLineSticker)),
  $delegate(
    'click',
    '.decoration_menu_for_pc .my_lib',
    errorHandlingEventWrapper(function (e) {
      if (!$.has_class($1('#maker_frame'), 'decoration_mode_a') && UtilF.isLegacyMobile()) return;

      $.frame.open(
        {
          is_modal: !UtilF.isLegacyMobile(),
          frame_name: 'maker.my_lib',
          page_name: 'maker_my_lib_page',
          loader: true,
          appending: __($.attr('is_fixed_head', 'true')),
          closing: function (don_frame, image) {
            if (!image) return;
            return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
          },
        },
        {
          page_name: 'maker_my_lib_page',
          hide_frame_button_type: 'X',
        },
      );
    }),
  ),
  $delegate(
    'click',
    '.decoration_menu_for_pc .worker_folder',
    errorHandlingEventWrapper(function (e) {
      if (!$.has_class($1('#maker_frame'), 'decoration_mode_a') && UtilF.isLegacyMobile()) return;
      const image_up_desc = $1('.image_upload_desc');
      $.attr(image_up_desc, 'is_enter', 'false');
      $.hide(image_up_desc);

      $.frame.open(
        {
          is_modal: !UtilF.isLegacyMobile(),
          frame_name: 'maker.worker_folder_frame',
          page_name: 'maker.worker_folder_page',
          appending: __($.attr('is_fixed_head', 'true')),
          closing: function (don_frame, image) {
            if (!image) return;
            return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
          },
        },
        {
          page_name: 'maker.worker_folder_page',
          hide_frame_button_type: 'X',
          tabs: [
            {
              tab_name: 'maker.worker_folder_page_tab',
              selected: true,
            },
          ],
        },
      );
    }),
  ),
  $.on(
    'mouseenter',
    '.decoration_menu_for_pc .worker_folder, .decoration_menu_for_pc .my_lib, .decoration_menu_for_pc .add_sticker2, .decoration_menu_for_pc .add_text',
    errorHandlingEventWrapper(function (e) {
      const image_up_desc = $1('.image_upload_desc');
      $.attr(image_up_desc, 'is_enter', 'false');
      $.hide(image_up_desc);
    }),
  ),
  $.on(
    'mouseenter',
    '.decoration_menu_for_pc .add_img',
    errorHandlingEventWrapper(function (e) {
      const image_up_desc = $1('.image_upload_desc');
      const menu = $.closest(e.currentTarget, '.decoration_menu_for_pc');
      $.css(image_up_desc, {
        top: $.position(menu).top + $.position(e.currentTarget).top - 10,
        left: $.position(menu).left - $.innerWidth(image_up_desc) - 20,
      });

      $.attr(image_up_desc, 'is_enter', true);
      $.show(image_up_desc);
    }),
  ),
  $.on(
    'mouseleave',
    '.decoration_menu_for_pc .add_img',
    errorHandlingEventWrapper(function (e) {
      const image_up_desc = $1('.image_upload_desc');
      $.attr(image_up_desc, 'is_enter', 'false');
      setTimeout(function () {
        if ($.attr(image_up_desc, 'is_enter') == true) return;
        $.hide(image_up_desc);
      }, 2000);
    }),
  ),
  $delegate(
    'click',
    '.decoration_menu_for_pc .add_img .icon',
    errorHandlingEventWrapper(function (e) {
      $.trigger($.find1($.closest(e.currentTarget, '.add_img'), 'input'), 'click');
    }),
  ),
  $delegate('dragover', '.decoration_menu_for_pc .add_img .icon', (e) => {
    e.originalEvent.preventDefault();
    $.addClass(e.currentTarget, 'drop');
    $.addClass($.closest(e.currentTarget, '.circle_icon'), 'drop');
  }),
  $delegate('dragleave', '.decoration_menu_for_pc .add_img .icon', (e) => {
    e.originalEvent.preventDefault();
    $.removeClass(e.currentTarget, 'drop');
    $.removeClass($.closest(e.currentTarget, '.circle_icon'), 'drop');
  }),
  $delegate('drop', '.decoration_menu_for_pc .add_img .icon', (e) => {
    e.originalEvent.preventDefault();
    const file$ = $.find1($.closest(e.currentTarget, '.add_img'), 'input');
    file$.files = e.dataTransfer.files;
    $.trigger(file$, 'change');
    $.removeClass(e.currentTarget, 'drop');
    $.removeClass($.closest(e.currentTarget, '.circle_icon'), 'drop');
  }),

  $delegate(
    'change',
    '.decoration_menu_for_pc .add_img input',
    errorHandlingEventWrapper(async function (e) {
      $.don_loader_start();
      const image = await NewMakerUtilF.uploadFile(e.currentTarget);
      if (!image) {
        $.don_loader_end();
        return;
      }
      await NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
      if ($1('html#dream_factory')) return;
      resetUserCounts();
      $.don_loader_end();
    }),
  ),
);
