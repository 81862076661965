import { legacyHtml } from '../../../Util/S/Function/util.js';

export const starter = () =>
  legacyHtml`
    <div class="marstagram_editor section">
      <div class="title">${T('image_editor::edit')}</div>
      <div class="buttons">
        <button type="button" class="filter">
          <img
            src="https://s3.marpple.co/files/u_1187078/2021/1/original/a8a8194f40c30f5038939d6e2ba0b9437a397fb31.png"
          /><span>${T('image_editor::filter')}</span>
        </button>
        <button type="button" class="adjustment">
          <img
            src="https://s3.marpple.co/files/u_1187078/2021/1/original/e8f969d2c84e09cc50b4cb9b5a17c34e3e2295761.png"
          /><span>${T('image_editor::adjustment')}</span>
        </button>
        <button type="button" class="crop">
          <img
            src="https://s3.marpple.co/files/u_1187078/2021/1/original/3b0c2c4856f5483327ad519f0930e30e9ffe20871.png"
          /><span>${T('image_editor::transform')}</span>
        </button>
      </div>
    </div>
  `;
