import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SimpleModalMuiF } from '../../../../SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { go, html } from 'fxjs/es';
import { $delegate } from 'fxdom/es';

function getPopupImage(is_keyring) {
  if (G.collabo_type === 'creator') {
    return T.lang === 'kr'
      ? is_keyring
        ? '//s3.marpple.co/files/u_193535/2023/12/original/fc3a70f98a601bf30114be973131e4029a943bbf1.jpg'
        : '//s3.marpple.co/files/u_193535/2023/12/original/7a627b26882e736a4ffd9c51ab0f260267c19fd02.jpg'
      : T.lang === 'en'
      ? is_keyring
        ? '//s3.marpple.co/files/u_193535/2023/12/original/7b363101490cee4c83ba50f0e3906e0fbc8bad904.png'
        : '//s3.marpple.co/files/u_193535/2023/12/original/d03b6a388a0de1c9ab01d95941f574097100f8661.png'
      : is_keyring
      ? '//s3.marpple.co/files/u_193535/2023/12/original/22e286c288715d2237606b18723a5ff4c5db80535.png'
      : '//s3.marpple.co/files/u_193535/2023/12/original/8ea2990dce591517add9304da6b3b3f50c7766ef2.png';
  } else {
    return T.lang === 'kr'
      ? is_keyring
        ? '//s3.marpple.co/files/u_193535/2023/11/original/72614ce8d5d183645c57cedf0143a9b7f8ae90523.jpeg'
        : '//s3.marpple.co/files/u_193535/2023/12/original/b1af637110b421aa944bc5f62cf1cfe3a2a1726c3.png'
      : T.lang === 'en'
      ? is_keyring
        ? '//s3.marpple.co/files/u_193535/2023/11/original/b265a7e2746330554c469caf2388f7b4605600431.jpeg'
        : '//s3.marpple.co/files/u_193535/2023/12/original/44e6590c85eee599f49f63bbf70267676145740d1.png'
      : is_keyring
      ? '//s3.marpple.co/files/u_193535/2023/12/original/005aec926235023fa2f908f7c0893341f64d5a222.png'
      : '//s3.marpple.co/files/u_193535/2023/11/original/95019cc4091ae45614b79c7105c51067545518512.jpeg';
  }
}

export const openMobilePopup = () => {
  const is_keyring = box.sel('maker->product_color->_->base_product->maker_type') === 'KEYRING';
  MuiF.openFrame(SimpleModalMuiF.frame, async (frame, page, [tab]) => {
    frame.appended = (frame_el) => {
      go(
        frame_el,
        $delegate('click', '.maker-upload-type-mobile-popup-head__close', () => {
          $.frame.close();
        }),
      );
      $.don_loader_end();
    };
    tab.makeData = () => {
      return {
        header: html`
          <div class="maker-upload-type-mobile-popup-head">
            <div class="maker-upload-type-mobile-popup-head__title">
              ${G.collabo_type === 'creator'
                ? T('maker_upload_type::내 디자인 파일로 만드는 방법')
                : T('maker_upload_type::커스텀하는 방법')}
            </div>
            <button type="button" class="maker-upload-type-mobile-popup-head__close">
              <img
                src="//s3.marpple.co/files/u_193535/2023/11/original/a00a537103616ad2876b9f58b019f12c4cf2faea1.svg"
                alt=""
              />
            </button>
          </div>
        `,
        body: html`
          <div class="maker-upload-type-mobile-popup" style="width:100%; height:100%;">
            <img class="maker-upload-type-mobile-popup__image" src="${getPopupImage(is_keyring)}" />
          </div>
        `,
        footer: html`<div></div>`,
        header_height: 50,
      };
    };
  });
};
