import { html, isEmpty, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppKeywordAutoCompleteS } from '../Function/module/MShopAppKeywordAutoCompleteS.js';

export const autoCompleteShellTmpl = () => {
  return html`<div class="auto-complete">${emptyAutoCompleteListTmpl()}</div>`;
};

export const emptyAutoCompleteListTmpl = () => {
  return html`<ul class="auto-complete__list hide"></ul> `;
};

/**
 * @typedef {Object} AutoComplete
 * @property {string} name
 */
/**
 *
 * @param {AutoComplete[]} auto_complete_list
 * @param {string} input_value
 */
export const autoCompleteListTmpl = (auto_complete_list, input_value) => {
  const name_pattern = MShopAppKeywordAutoCompleteS.getPatternWithSpaces(input_value);
  return html`
    <ul class="auto-complete__list ${isEmpty(auto_complete_list) ? 'hide' : ''}">
      ${auto_complete_list?.length
        ? strMap(({ name }) => {
            const matched = name_pattern.exec(name);
            if (!matched) {
              return '';
            }
            const [matched_name] = matched;
            const start_idx = matched.index;
            const end_idx = start_idx + matched_name.length;

            return html`<li class="auto-complete__item" data-name="${UtilS.escape(name)}">
              <span>${name.slice(0, start_idx)}</span
              ><span class="auto-complete__item--matched">${UtilS.escape(matched_name)}</span
              ><span>${name.slice(end_idx)}</span>
            </li>`;
          }, auto_complete_list)
        : ''}
    </ul>
  `;
};
