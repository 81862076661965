import { $closest, $delegate, $hasClass, $on, $qs, $removeClass, $toggleClass } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';

export const delegateSortSelector = (tab_el) => {
  // close selector
  if (!MShopUtilF.isApp()) {
    $on('click', (e) => {
      if ($hasClass('app-product-sub__sort-btn', e.target)) {
        return;
      }
      const $active_sort_selector = $qs('.app-product-sub__sort-selector--show');
      if (!$active_sort_selector) {
        return;
      }
      $removeClass('app-product-sub__sort-selector--show', $active_sort_selector);
    })(document);
  }

  return go(
    tab_el,
    $delegate('click', '.app-product-sub__sort-btn', ({ currentTarget }) => {
      const $sort = $closest('.app-product-sub__sort', currentTarget);
      const $sort_selector = $qs('.app-product-sub__sort-selector', $sort);
      $toggleClass('app-product-sub__sort-selector--show', $sort_selector);
    }),
    $delegate('click', '.app-product-sub__sort-item', ({ currentTarget }) => {
      const $sort_selector = $closest('.app-product-sub__sort-selector', currentTarget);
      $toggleClass('app-product-sub__sort-selector--show', $sort_selector);
      const { sort_key } = currentTarget.dataset;

      const params = new URLSearchParams(location.search);
      params.set('sort', sort_key);
      location.href = `${location.pathname}?${params.toString()}`;
    }),
  );
};
