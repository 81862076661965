import { defaults, each, extend, filter, find, go, join, mapC, pick, reject, sel, take, tap } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { UtilS } from '../../Util/S/Function/module/UtilS.js';
import { trimCanvasSize } from './canvas_trim.js';
import { isNeedTextVertically } from './categorize.js';
import { checkAllFcanvasDesignSize } from './check_image.js';
import { createCvPattern } from './CvPattern/fs.js';
import { getShadeFcanvas } from './CvPreview/Shade/render.js';
import { hasNotBold } from './CvTextImage/fs.js';
import { getBaseProductInMaker, getCurrentBpcInMaker, getCvTexts, getRealFcanvass } from './getSth.js';
import { getFheight, getFwidth, isTemporalGroupInMaker } from './util.js';
import { $qs } from 'fxdom/es';
import { minusStrokeWidth } from './Fcanvas/stroke.js';
import { NewMakerCheckerS } from '../../NewMaker/Checker/S/Function/module/NewMakerCheckerS.js';
import { getCurrentFcanvas, getCvDesigns } from './Fcanvas/cv_object.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerCvObjAlignmentF } from '../../NewMaker/CvObjAlignment/F/Function/module/NewMakerCvObjAlignmentF.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import * as configSentryF from '../../Config/Sentry/F/Function/sentry.js';
import { BpOptionF } from '../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from '../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { OMPDosuConstantS } from '../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerPropertyBpfF } from '../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';

const props_to_make_clean_cv_text = [
  'flipX',
  'flipY',
  'evented',
  'selectable',
  'visible',
  'is_overflow',
  'is_oversized',
  'is_smallsized',
];
const data_for_location = ['left', 'top'];
const data_for_text_info = [
  'left',
  'top',
  'text',
  'angle',
  'scaleX',
  'styles',
  'fontWeight',
  'fontStyle',
  'underline',
  'linethrough',
  'textAlign',
  'fontFamily',
  'charSpacing',
  'lineHeight',
  'globalCompositeOperation',
];

const data_for_text_info_data = [
  'width_cm',
  'height_cm',
  'rotate',
  'nscreened',
  'only_flex',
  'real_height_cm',
  'real_width_cm',
  'press_color_code',
  'press_color_id',
  [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR],
  'press_color_name',
  'press_color_name_en',
  'press_color_name_jp',
  'clone_parent_cid',
  '_debug',
];

G.mp.maker = G.mp.maker || {};

function _addCvTextImage(is_not_active) {
  const fcanvas = G.mp.maker.editing_canvas();
  const _default_text = {
    _data: {},
  };

  if (T.lang === 'kr' && sel('preview.shade', fcanvas)) {
    _default_text.fontFamily = 'BareunBatang';
  }
  // 처음 텍스트 생성시 (press_color_code 지정되어 있지 않음)
  let _default__data = null;

  // $도수 - 가드(면 도수 )
  /* 도수 제한 면
   *  - 각인 면인 경우 각인 색상으로 강제 변환
   *  - 각인 아닐 때,
   *    - 기존 디자인 사용 컬러로 강제 변환
   *    - 기존 디자인 없으면 press_colors 첫번째 색상 */
  const mp_maker_el = $qs('#maker_frame');
  if (BpOptionF.biz.get.currentFace.hasDosuCountOptions()) {
    const { is_engrave, engrave_hex_code } = BpOptionF.makerHelper.get.isEngraveForCurrentEditingCanvas();
    if (is_engrave) {
      /* 도수 각인 */
      _default__data = {
        only_flex: false,
        press_color_id: undefined,
        press_color_code: engrave_hex_code,
        press_color_name: engrave_hex_code,
        press_color_name_en: engrave_hex_code,
        press_color_name_jp: engrave_hex_code,
        [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]: engrave_hex_code,
      };
    } else {
      /* 도수 */
      const used_dosu_last_color_code = OMPDosuF.cvObj.get.usedDosuLastColorCodeFromCurrentFace();
      let apply_color;

      if (used_dosu_last_color_code) {
        // 디자인에 사용된 색상이 있는 경우 - 사용된 색상의 첫째 색상 적용
        const first_color_from_design =
          BpOptionF.biz.get.currentFace.dosuColorOption(used_dosu_last_color_code);
        if (first_color_from_design) {
          apply_color = BpOptionF.biz.convert.dosuColorOptionToTextPressColor(first_color_from_design);
          const { code, id, name, name_jp, name_en, only_flex } = apply_color;
          _default__data = {
            press_color_id: id,
            press_color_code: code,
            press_color_name: name,
            press_color_name_en: name_en,
            press_color_name_jp: name_jp,
            [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]: code,
            only_flex,
          };
        } else {
          _default__data = BpOptionF.biz.convert.dosuColorOptionToTextFontData({
            color_code: used_dosu_last_color_code,
          });
        }
      } else {
        // 디자인 없는 경우, 첫번째 컬러 사용
        apply_color = go(
          BpOptionF.biz.get.currentFace.defaultDosuColorOption(G.mp.maker.editing_canvas()?.bpf_id),
          (bp_option) => {
            if (bp_option) return BpOptionF.biz.convert.dosuColorOptionToTextPressColor(bp_option);
            return box().press_colors[0];
          },
        ); // press_colors 가 dosu_color 칩들로
        const { code, id, name, name_jp, name_en, only_flex } = apply_color;
        _default__data = {
          press_color_id: id,
          press_color_code: code,
          press_color_name: name,
          press_color_name_en: name_en,
          press_color_name_jp: name_jp,
          [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]: code,
          only_flex,
        };
      }
    }
  } else if (sel('preview.shade.is_black_and_white_contrast', fcanvas)) {
    /* 흑백 (키캡) */
    _default__data = {
      press_color_code: '#ffffff',
      press_color_id: 34,
      press_color_name: '흰색',
      press_color_name_en: 'White',
      press_color_name_jp: 'ホワイト',
    };
  } else if (mp_maker_el?.dataset?.is_embroidery_app === 'true') {
    /* 자수 */
    _default__data = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.font_color;
  } else if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(getCurrentFcanvas())) {
    const bpc = getCurrentBpcInMaker();
    _default__data = {
      only_flex: false,
      press_color_id: `bpc_${bpc.id}`,
      press_color_code: bpc.color_code2,
      press_color_name: bpc.name,
      press_color_name_en: bpc.name_en,
      press_color_name_jp: bpc.name_jp,
    };
  } else {
    _default__data = {
      only_flex: false,
      press_color_id: 36,
      press_color_code: '#ffd200',
      press_color_name: '노랑',
      press_color_name_en: 'Yellow',
      press_color_name_jp: 'イエロー',
    };
  }
  defaults(_default_text._data, _default__data);
  const print = G.mp.maker.cv_print_area(fcanvas);
  return _p.go(
    createCvTextImageFromCvText(G.mp.maker.TEXT.text, null, _default_text),
    function (cv_text_image) {
      const cv_text_width = G.mp.maker.fabric_get_width(cv_text_image) / G.mp.maker.NSCREEN_ZOOM;
      const cv_text_height = G.mp.maker.fabric_get_height(cv_text_image) / G.mp.maker.NSCREEN_ZOOM;
      const max_width =
        print.width * print.scaleX - (NewMakerProductStyleS.isWatchBand(getBaseProductInMaker()) ? 32 : 16);
      if (cv_text_width >= max_width) {
        cv_text_image.scaleX = (cv_text_image.scaleX * max_width) / cv_text_width;
        cv_text_image.scaleY = cv_text_image.scaleX;
      }
      if (print.height < 65) {
        cv_text_image.scaleX = (cv_text_image.scaleX * (print.height - 2)) / cv_text_height;
        cv_text_image.scaleY = cv_text_image.scaleX;
      }
      const mp_maker_el = $qs('#maker_frame');
      if (mp_maker_el?.dataset?.is_carved_phonecase_product === 'true') {
        cv_text_image.scaleX = (minusStrokeWidth(print).width * 0.4) / cv_text_image.width;
        cv_text_image.scaleY = cv_text_image.scaleX;
        cv_text_image.left = Math.floor(cv_text_image.left);
        cv_text_image.top = Math.floor(cv_text_image.top);
        const center_point = print.getCenterPoint();
        G.mp.maker.center(cv_text_image, center_point.x, center_point.y);
        NewMakerCvObjAlignmentF.makeAlignmentCenter(cv_text_image, fcanvas);
      } else if (mp_maker_el?.dataset?.is_embroidery_app === 'true') {
        const config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
          NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
          box.sel('maker->product_color->base_product_id'),
        );
        const scale = (fcanvas._px_per_1cm * config.height_cm_in_line) / cv_text_image.height;
        cv_text_image.scaleX = scale;
        cv_text_image.scaleY = scale;
        NewMakerCvObjAlignmentF.makeAlignmentCenter2(cv_text_image, fcanvas);
      } else {
        cv_text_image.left = Math.floor(cv_text_image.left);
        cv_text_image.top = Math.floor(cv_text_image.top);
        const center_point = print.getCenterPoint();
        G.mp.maker.center(cv_text_image, center_point.x, center_point.y);
        NewMakerCvObjAlignmentF.makeAlignmentCenter(cv_text_image, fcanvas);
      }

      if (isNeedTextVertically()) {
        const point = new fabric.Point(cv_text_image.getCenterPoint().x, cv_text_image.getCenterPoint().y);
        cv_text_image.set('angle', 90).setCoords();
        cv_text_image.scale(0.1);
        cv_text_image.setPositionByOrigin(point, 'center', 'center');
      }
      // else if (NewMakerProductStyleS.isSlipper(getBaseProductInMaker())) {
      //   G.mp.maker.center(cv_text_image, 227, 504 + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2);
      // } else if (NewMakerProductStyleS.isSmallBand(getBaseProductInMaker())) {
      //   G.mp.maker.center(cv_text_image, 431, 234 + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2);
      // } else if (NewMakerProductStyleS.isBigBand(getBaseProductInMaker())) {
      //   G.mp.maker.center(cv_text_image, 426, 234 + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2);
      // }
      go(
        [
          cv_text_image,
          filter(function (cv_object) {
            return cv_object._data.cv_type == 'cv_text_image';
          }, G.mp.maker.designs()),
        ],
        function p([pst, cv_text_images]) {
          if (
            !find(function (ct) {
              return ct.top == pst.top && ct.left == pst.left;
            }, cv_text_images)
          )
            return pst;
          pst.top += 16;
          pst.left += 16;
          return p([pst, cv_text_images]);
        },
      );

      G.mp.maker.nscreen_scale(cv_text_image);

      setCvTextImageNewScale(cv_text_image);
      $.add_class($1('#maker_frame'), 'delay_transition');
      fcanvas.add(cv_text_image);
      if (!is_not_active) fcanvas.setActiveObject(cv_text_image);
      setTimeout(function () {
        $.remove_class($1('#maker_frame'), 'delay_transition');
      }, 200);
    },
  );
}

export const is_load_font_important = { value: false };
let is_load_font_error = false;
const loadFont = function ({ font_family, option = {} }) {
  return new FontFaceObserver(font_family, option).load(undefined, 70000).catch(function (e) {
    console.error(e);
    if ($1('html#dream_factory') && (is_load_font_important.value || G.mp.maker.is_auto_print)) {
      is_load_font_error = true;
    }
  });
};
export const loadDefaultFonts = async (fonts = []) => {
  const base_fonts = [
    { font_family: 'Noto Sans KR' },
    { font_family: 'Noto Sans KR', option: { weight: 'bold' } },
    { font_family: 'Taviraj' },
    { font_family: 'Taviraj', option: { style: 'italic' } },
    { font_family: 'Taviraj', option: { weight: 'bold' } },
    { font_family: 'Taviraj', option: { weight: 'bold', style: 'italic' } },
    { font_family: 'Noto Sans TC' },
    { font_family: 'Noto Sans TC', option: { weight: 'bold' } },
    { font_family: 'Roboto' },
    { font_family: 'Roboto', option: { style: 'italic' } },
    { font_family: 'Roboto', option: { weight: 'bold' } },
    { font_family: 'Roboto', option: { weight: 'bold', style: 'italic' } },
    T.lang === 'kr' ? { font_family: 'BMHANNA' } : { font_family: 'Bree Serif' },
  ];
  await go(
    base_fonts.concat(fonts),
    filter((font) => font?.font_family),
    mapC(loadFont),
  );
};

async function _createCvTextA(text, data, _default_text = {}) {
  text = UtilS.un_maker_text_escape(text);
  NewMakerUtilF.systemFontAffectCanvasFont();
  const font_family__ = _p.v(data, 'styles.0.0.fontFamily');
  if (font_family__) {
    data.fontFamily = font_family__;
  }
  defaults(_default_text, G.mp.maker.TEXT);
  if (!!$1('#maker_frame') && _p.v(data, 'fontWeight') === 'bold' && (await hasNotBold(data))) {
    data.fontWeight = 'normal';
    $.alert(T('maker::This font does not support bold. The font will be changed to the default thickness.'));
  }
  await loadDefaultFonts([
    { font_family: _default_text.fontFamily },
    { font_family: data?.fontFamily },
    data?.fontWeight === 'bold' ? { font_family: data?.fontFamily, option: { weight: 'bold' } } : null,
  ]);
  if (is_load_font_error) {
    alert(
      '폰트 계산이 잘못 됐을 가능성이 있습니다. 새로 고침해 주세요. 이 알림이 뜬다면 개발팀에 문의주세요.',
    );
    is_load_font_error = false;
  }
  const cv_text = new fabric.IText(text, _p.defaults({}, data, _default_text));
  cv_text._data.cv_type = 'cv_text';

  if (UtilF.isLegacyMobile()) cv_text.editable = false;
  renderSpecialColor(cv_text);
  return cv_text;
}

function _createCvTextFromCvTextImageA(cv_text_image) {
  const cv_text_attrs = cv_text_image._data.text_info;
  cv_text_attrs._data = _p.pick(cv_text_image._data, data_for_text_info_data);
  return _p.go(_createCvTextA(cv_text_attrs.text, _p.omit(cv_text_attrs, 'scaleX')), function (cv_text) {
    return cv_text.scale((cv_text_image.scaleX / cv_text_image._data.old_scale) * cv_text_attrs.scaleX);
  });
}

function _getPaddingFromCvText(cv_text_cloned) {
  const padding = 50;
  cv_text_cloned.padding = padding;
  cv_text_cloned.angle = 0;
  const img = new Image();
  img.src = cv_text_cloned.toDataURL();
  return new Promise(function (resolve) {
    img.onload = function () {
      const canvas_width = img.width;
      const canvas_height = img.height;

      const canvas1 = document.createElement('canvas');
      canvas1.width = canvas_width;
      canvas1.height = canvas_height;
      const ctx1 = canvas1.getContext('2d');
      ctx1.drawImage(img, 0, 0, canvas_width, canvas_height);
      return _p.go(
        trimCanvasSize(canvas1),
        function ({ top, left, width, height }) {
          const x = left - padding;
          const y = -(top - padding);
          const r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          let deg = Math.atan(y / x) * (180 / Math.PI);
          if (x > 0 && y < 0) {
            /*
             * "텍스트" BMHANNA
             * */
            deg = 360 + deg;
          } else if (x > 0 && y > 0) {
            /*
             * "jabcde" Anton
             * */
          } else if (x < 0 && y < 0) {
            /*
             * "jabcde" Grand Hotel
             * */
            deg = 180 + deg;
          } else if (x < 0 && y > 0) {
            /*
             * "jabcde" Fjalla One
             * */
            deg = 180 + deg;
          } else if (x === 0 && y === 0) {
            deg = 0;
          } else if (x === 0 && y > 0) {
            /**/
          } else if (x === 0 && y < 0) {
            deg = 360 + deg;
          } else if (x > 0 && y === 0) {
            /**/
          } else if (x < 0 && y === 0) {
            deg = 180;
          }

          return {
            left: x,
            top: y,
            r,
            width,
            height,
            deg,
          };
        },
        resolve,
      );
    };
  });
}

function _makeToDataUrlFromCvText(cv_text_cloned, zoom, cv_text_image_info) {
  if (cv_text_image_info.width > cv_text_image_info.height) {
    let width;
    if (G.mp.maker.is_auto_print || G.mp.maker.sharp_resize_print || G.mp.maker.is_text_printable) {
      width = cv_text_image_info.width * 1.2;
    } else if (zoom === 1) {
      width = 800;
    } else {
      width = cv_text_image_info.width * 1.2;
    }
    cv_text_cloned.scale(Math.max(width, 10) / cv_text_cloned.width);
  } else {
    let height;
    if (G.mp.maker.is_auto_print || G.mp.maker.sharp_resize_print) {
      height = cv_text_image_info.height * 1.2;
    } else if (zoom === 1) {
      height = 800;
    } else {
      height = cv_text_image_info.height * 1.2;
    }
    cv_text_cloned.scale(Math.max(height, 10) / cv_text_cloned.height);
  }

  cv_text_cloned.set({
    is_overflow: false,
    is_oversized: false,
    visible: true,
    angle: 0,
    flipX: false,
    flipY: false,
  });

  let url;
  if ($1('html#dream_factory') && zoom === 0.25) {
    url = cv_text_cloned.toDataURL();
  } else if (G.mp.maker.is_auto_print || G.mp.maker.sharp_resize_print || G.mp.maker.is_text_printable) {
    const scale = cv_text_cloned.scaleX;
    cv_text_cloned.scale(1);
    const normal_trimmed_size = cv_text_cloned.trimmedSize(true);
    const trimmed_size = cv_text_cloned.trimmedSize();
    const expected_normal_width =
      getFwidth(cv_text_cloned) +
      (trimmed_size.width +
        normal_trimmed_size.left +
        normal_trimmed_size.right -
        getFwidth(cv_text_cloned)) *
        2;
    const expected_normal_height =
      getFheight(cv_text_cloned) +
      (trimmed_size.height +
        normal_trimmed_size.top +
        normal_trimmed_size.bottom -
        getFheight(cv_text_cloned)) *
        2;
    const ratio = Math.max(
      expected_normal_width / getFwidth(cv_text_cloned),
      expected_normal_height / getFheight(cv_text_cloned),
    );
    cv_text_cloned.scale(Math.min(12000 / cv_text_cloned.width, scale));
    url = cv_text_cloned.toDataURL3(Math.max(ratio, 1));
  } else {
    url = cv_text_cloned.toDataURL3();
  }
  return url;
}

function _createCvTextImageFromCvTextGettingLocationInfoA(cv_text, zoom) {
  return _p.go(_getPaddingFromCvText(cv_text.clone()), function (pad) {
    const angle2 = 360 - cv_text.angle + pad.deg;
    const rad = angle2 * (Math.PI / 180);

    const location_info = {
      top: cv_text.top - Math.sin(rad) * pad.r,
      left: cv_text.left + Math.cos(rad) * pad.r,
      angle: cv_text.angle,
      width: pad.width,
      height: pad.height,
    };
    const cv_text_data = _p.pick(cv_text, props_to_make_clean_cv_text);
    return _p.go(
      _createCvTextImageFromCvTextWithLocationInfoA(cv_text, location_info, 1),
      function (cv_text_image) {
        _p.extend(cv_text_image, cv_text_data);
        cv_text_image._data.pad = pad;
        return cv_text_image;
      },
    );
  });
}

function _addCvTextImageFromOldText(active) {
  const canvas = active.canvas;
  const old_text_data = active._data.old_text_data;
  const data = {
    text: old_text_data.text,
    textAlign: old_text_data.textAlign,
    fontFamily: old_text_data.fontFamily || 'BMHANNA',
    top: active.top,
    left: active.left,
    angle: active.angle,
    width: active.width,
    scaleX: active.scaleX,
    scaleY: active.scaleY,
    _data: _p.clone(_p.omit(active._data, 'old_text_data')),
  };
  return _p.go(createCvTextImageFromCvText(old_text_data.text, data), function (cv_text_image) {
    const scale = G.mp.maker.fabric_get_width(active) / cv_text_image.width;
    cv_text_image.scale(scale);
    const idx = canvas._objects.indexOf(active);
    canvas.insertAt(cv_text_image, idx);
    canvas.remove(active);
    canvas.setActiveObject(cv_text_image);
    G.mp.maker.reset_data_cm(cv_text_image);
    canvas.renderAll();
    G.mp.maker.reset_data_cm(cv_text_image);
    return cv_text_image;
  });
}

function _isCvTextImageScaleChanged(cv_target) {
  return (
    cv_target && cv_target._data.cv_type === 'cv_text_image' && cv_target.scaleX !== cv_target._data.new_scale
  );
}

/*보조-create_cv_text_image*/
/*cv_text 정보와 위치가 어딘지 아는 상황에서 cv_text_image 만들기*/
// function create_cv_text_image_from_cv_text_and_zoom_and_location_info(cv_text, location_info, zoom){
function _createCvTextImageFromCvTextWithLocationInfoA(cv_text, cv_text_image_info, zoom) {
  const cv_text_info = _p.pick(cv_text, data_for_text_info);
  cv_text_info.text = UtilS.maker_text_escape(cv_text_info.text);
  const cv_text_data_info = JSON.parse(JSON.stringify(_p.pick(cv_text._data, data_for_text_info_data)));
  return new Promise(function (resolve) {
    cv_text.visible = false;
    cv_text.scale(1);
    const cv_text_cloned = cv_text.clone();
    fabric.Image.fromURL(
      _makeToDataUrlFromCvText(cv_text_cloned, zoom, cv_text_image_info),
      function (cv_text_image) {
        cv_text_image.set(
          _p.defaults(_p.pick(cv_text_image_info, ['left', 'top', 'angle']), G.mp.maker.IMAGE),
        );

        if (cv_text_image_info.width > cv_text_image_info.height) {
          const prev_height = cv_text_image_info.height;
          cv_text_image.scale(cv_text_image_info.width / cv_text_image.width);
          const current_height = getFheight(cv_text_image);
          const diff_height = current_height - prev_height;
          if (Math.abs(diff_height) >= 2) {
            cv_text_image.top = cv_text_image.top + diff_height / 2;
          }
        } else {
          const prev_width = cv_text_image_info.width;
          cv_text_image.scale(cv_text_image_info.height / cv_text_image.height);
          const current_width = getFwidth(cv_text_image);
          const diff_width = current_width - prev_width;
          if (Math.abs(diff_width) >= 2) {
            cv_text_image.left = cv_text_image.left + diff_width / 2;
          }
        }
        cv_text_image.setCoords();
        cv_text_image._data = _p.extend(cv_text_data_info, {
          text_info: cv_text_info,
          cv_type: 'cv_text_image',
          old_scale: cv_text_image.scaleX,
          new_scale: cv_text_image.scaleX,
        });
        resolve(cv_text_image);
      },
    );
  });
}

function _locateCvTextByCvTextImage(cv_text, cv_text_image) {
  return go(cv_text_image._data.pad, function (pad) {
    cv_text.set(_calcTopLeftAngleFromCvTextImageWithPad(cv_text_image, pad));
    cv_text.set({ angle: cv_text_image.angle });
    cv_text.set(_p.pick(cv_text_image, props_to_make_clean_cv_text));
    return cv_text;
  });
}

/*보조-create_cv_text*/
/*cv_text_image 정보로 cv_text생성 및 위치 셋팅 그리고 바꿔치기*/
// function create_cv_text_and_locating_and_select_no_event(cv_text_image) {
function _changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA(cv_text_image) {
  const cid = cv_text_image.cid;
  if (cv_text_image._data.cv_type === 'cv_text') return cv_text_image;
  return _p.go(_createCvTextFromCvTextImageA(cv_text_image), function (cv_text) {
    return _p.go(_locateCvTextByCvTextImage(cv_text, cv_text_image), function (cv_text) {
      const canvas = cv_text_image.canvas;
      const idx = canvas._objects.indexOf(cv_text_image);
      G.mp.maker.cleared_pass = true;
      G.mp.maker.selected_pass = true;
      cv_text.cid = cid || cv_text.cid;
      canvas.remove(cv_text_image).insertAt(cv_text, idx).setActiveObject(cv_text);
      G.mp.maker.cleared_pass = false;
      G.mp.maker.selected_pass = false;
      return cv_text;
    });
  });
}
export const changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA =
  _changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA;

function _calcTopLeftAngleFromCvTextImageWithPad(cv_text_image, pad) {
  const r = pad.r;
  let deg = 360 - cv_text_image.angle;
  if (pad.left > 0 && pad.top < 0) deg = 180 - (360 - pad.deg) + deg;
  if (pad.left > 0 && pad.top > 0) deg = 180 + pad.deg + deg;
  else if (pad.left < 0 && pad.top < 0) deg = 180 + pad.deg + deg;
  else if (pad.left < 0 && pad.top > 0) deg = 360 - (180 - pad.deg) + deg;
  else if (pad.left === 0 && pad.top > 0) deg = 360 - deg;
  else if (pad.left === 0 && pad.top < 0) deg = deg + (360 - pad.deg);
  else if (pad.left > 0 && pad.top === 0) deg = deg + 180;

  // var angle2 = (360 - angle) + pad.deg;
  const rad = deg * (Math.PI / 180);
  const x = Math.cos(rad) * r;
  const y = Math.sin(rad) * r;
  return {
    left: cv_text_image.left + x,
    top: cv_text_image.top - y,
  };
}

/* ungrouping 할때,*/
/* 패딩 값을 cv_text_image 계산해서 변경. */
export const setCvTextImagePaddingA = function setCvTextImagePadding(cv_text_image) {
  return _p.go(
    _createCvTextFromCvTextImageA(cv_text_image),
    tap((cv_text) => {
      extend(cv_text, _p.pick(cv_text_image, ['flipX', 'flipY']));
    }),
    (cv_text) => _getPaddingFromCvText(cv_text.clone()),
    function (pad) {
      cv_text_image._data.pad = pad;
      return cv_text_image;
    },
  );
};

/* modified,*/
/* 스케일이 바꼈을때, 패딩을 구해서 넣고, 새로운 스케일 값으로 변*/
export const setCvTextImageNewScale = function setCvTextImageNewScale(cv_target, force) {
  function f(cv_target) {
    if (force || _isCvTextImageScaleChanged(cv_target)) {
      setCvTextImagePaddingA(cv_target);
      cv_target._data.new_scale = cv_target.scaleX;
    }
  }
  isTemporalGroupInMaker(cv_target) ? each(f, cv_target._objects) : f(cv_target);
};

export const createCvTextImageSrc = function (cv_text_image, zoom, cv_text_image_info) {
  return go(_createCvTextFromCvTextImageA(cv_text_image), (cv_text) =>
    _makeToDataUrlFromCvText(cv_text, zoom, cv_text_image_info),
  );
};

/* 텍스트 blur 될때, selected 울릴때, all_reset_data_cm */
/* cv_text로 cv_text_image 만들고, 로케이션 정보 구해서 기존의 자리에 cv_text_image로 바꿔준다. */
const _changeCvTextToCvTextImageA = async function (cv_text, canvas) {
  cv_text = cv_text || G.mp.maker.active();
  canvas = canvas || cv_text.canvas;
  if (!cv_text) return;
  if (cv_text._data.cv_type === 'cv_text_image') return cv_text;
  if (cv_text._data.cv_type !== 'cv_text') return;
  cv_text.text = go(
    cv_text.text.split(''),
    reject((char) => char.charCodeAt(0) === 65039),
    join(''),
    (text) => {
      if (!text) return '';
      return text;
    },
  );
  if (cv_text.text.trim().length === 0) {
    canvas.remove(cv_text);
    await G.mp.maker.cleared();
    await G.mp.maker.reset_layer();
    return;
  }
  if (cv_text._changing) return cv_text._changing;
  const cid = cv_text.cid;
  return (cv_text._changing = _p.go(
    _createCvTextImageFromCvTextGettingLocationInfoA(cv_text),
    function (cv_text_image) {
      const idx = canvas._objects.indexOf(cv_text);
      cv_text_image.cid = cid || cv_text_image.cid;
      canvas.remove(cv_text).insertAt(cv_text_image, idx);
      return cv_text_image;
    },
    function (cv_text_image) {
      G.mp.maker.reset_data_cm(cv_text_image);
      checkAllFcanvasDesignSize(cv_text_image);
      return cv_text_image;
    },
  ));
};

const _applyChangeCvTextToCvTextImageAllCvTextsA = function () {
  return go(
    getRealFcanvass(),
    each((f_canvas) =>
      go(
        f_canvas,
        getCvTexts,
        each((cv_text) => _changeCvTextToCvTextImageA(cv_text, f_canvas)),
      ),
    ),
  );
};

export const makeDataCmAndChangeCvTextResetBoxDataA = async function () {
  await _applyChangeCvTextToCvTextImageAllCvTextsA();
  G.mp.maker.reset_box_data();
};

export const editOldCvTextFontStyle = function () {
  $.confirm(
    '현재 스타일이 변경 될 수 있습니다.',
    __(function (bool) {
      if (!bool) return;
      return _addCvTextImageFromOldText(G.mp.maker.active());
    }),
  );
};
export const editOldCvTextText = function () {
  $.confirm(
    '현재 스타일이 변경 될 수 있습니다.',
    __(function (bool) {
      if (!bool) return;
      return _p.go(G.mp.maker.active(), _addCvTextImageFromOldText, changeCvTextImageToCvTextForTextingA);
    }),
  );
};

export function renderSpecialColor(cv_texts) {
  return G.mp.maker.cv_objects_deep_each(cv_texts, function (cv_text) {
    if (!cv_text._data || cv_text._data.cv_type !== 'cv_text') return;
    const color_id = cv_text._data.press_color_id;
    let color_code = cv_text._data.press_color_code;
    if (_p.contains([52, 48, 49], color_id)) {
      const can = document.createElement('canvas');
      const ctx = can.getContext('2d');
      const width = 70;
      if (color_id === 52) {
        const gradient = ctx.createLinearGradient(-width, -width, width, width * 2);
        color_code = gradient;
        gradient.addColorStop(0, 'rgb(230, 230, 172)');
        gradient.addColorStop(0.06565656566, 'rgb(242, 193, 177)');
        gradient.addColorStop(0.13131313131313133, 'rgb(74, 248, 229)');
        gradient.addColorStop(0.1691919191919192, 'rgb(201, 171, 211)');
        gradient.addColorStop(0.20707070707070707, 'rgb(187, 170, 229)');
        gradient.addColorStop(0.26262626262626265, 'rgb(216, 236, 226)');
        gradient.addColorStop(0.3181818181818182, 'rgb(255, 184, 204)');
        gradient.addColorStop(0.4191919191919192, 'rgb(216, 236, 226)');
        gradient.addColorStop(0.5202020202020202, 'rgb(255, 221, 166)');
        gradient.addColorStop(0.6212121212121212, 'rgb(201, 171, 211)');
        gradient.addColorStop(0.7777777777777778, 'rgb(242, 193, 177)');
        gradient.addColorStop(0.9292929292929293, 'rgb(216, 236, 226)');
        gradient.addColorStop(1, 'rgb(216, 236, 226)');
      } else if (color_id === 48) {
        const gradient = ctx.createLinearGradient(-width, 0, width, 0);
        color_code = gradient;
        const a = 'rgb(174, 137, 19)';
        const b = 'rgb(255, 238, 181)';
        const c = 'rgb(179, 137, 0)';
        color_code.addColorStop(0, a);
        color_code.addColorStop(0.1, b);
        color_code.addColorStop(0.6, a);
        color_code.addColorStop(0.7, c);
        color_code.addColorStop(0.8, a);
        color_code.addColorStop(0.9, b);
        color_code.addColorStop(1, a);
      } else if (color_id === 49) {
        const gradient = ctx.createLinearGradient(-width, -width, width, width * 2);
        color_code = gradient;
        const a = 'rgb(145, 145, 145)';
        const b = 'rgb(219, 219, 219)';
        const c = 'rgb(125, 125, 125)';
        color_code.addColorStop(0, b);
        color_code.addColorStop(0.1, a);
        color_code.addColorStop(0.6, b);
        color_code.addColorStop(0.7, c);
        color_code.addColorStop(0.8, b);
        color_code.addColorStop(0.9, a);
        color_code.addColorStop(1, b);
      }
    }

    const fcanvas = getShadeFcanvas();
    const hex = sel('preview.shade.hex', fcanvas);
    if (fcanvas && hex) {
      color_code = hex;
    }
    cv_text.set('fill', color_code);
    return cv_text;
  });
}

export const createCvTextImageA = function (cv_text_image, zoom = 1, no_pad) {
  const cid = cv_text_image.cid;
  return _p.go(
    _createCvTextFromCvTextImageA(cv_text_image),
    (cv_text) => _p.mr(cv_text, no_pad ? null : _getPaddingFromCvText(cv_text.clone())),
    function (cv_text, pad) {
      return _p.go(
        _createCvTextImageFromCvTextWithLocationInfoA(
          cv_text,
          {
            width: G.mp.maker.fabric_get_width(cv_text_image),
            height: G.mp.maker.fabric_get_height(cv_text_image),
            top: cv_text_image.top,
            left: cv_text_image.left,
            angle: cv_text_image.angle,
          },
          zoom,
        ),
        _p.tap(function (new_cv_text_image) {
          if (!no_pad) new_cv_text_image._data.pad = pad;
          _p.extend(new_cv_text_image, _p.pick(cv_text_image, props_to_make_clean_cv_text));
          new_cv_text_image.cid = cid || new_cv_text_image.cid;
        }),
      );
    },
    _p.catch(function (e) {
      cv_text_image._data.error = true;
      // if ($1('html.maker_page')) $.alert(T('maker::Something went wrong. Please check all the designs.'));
      configSentryF.error(e, {
        option: {
          user_id: box.sel('is_user'),
          href: window.location.href,
          cv_text_image,
        },
      });
      return cv_text_image;
    }),
  );
};

export const changeCvTextToCvTextImageA = _changeCvTextToCvTextImageA;

/* 텍스트 작성을 하려고 할때,*/
/* 텍스트를 작성하기 위해서 cv_text_image를 cv_text로 바꾸면서 타이핑 시작*/
export const changeCvTextImageToCvTextForTextingA = function (cv_text_image) {
  const cid = cv_text_image.cid;
  return _p.go(
    (cv_text_image._changing = _changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA(cv_text_image)),
    function (cv_text) {
      cv_text.cid = cid || cv_text.cid;
      return new Promise(function (resolve, reject) {
        if (G.mp.maker.is_apple_device) {
          cv_text.editable = true;
          cv_text.enterEditing().selectLast();
          return resolve(cv_text);
        } else {
          setTimeout(
            errorHandlingEventWrapper(function () {
              cv_text.editable = true;
              cv_text.enterEditing().selectLast();
              setTimeout(function () {
                if (!cv_text.canvas) return resolve();
                cv_text.canvas.renderAll();

                resolve(cv_text);
              }, 100);
            }),
            0,
          );
        }
      });
    },
  );
};
function checkTextareaLength(text_value, { line_length = 0, char_length_in_line = 0 }) {
  const lines_before = text_value.split('\n');
  if (char_length_in_line > 0) {
    // 한 줄 이상이면, 각 줄이 6자 이상이면 한 줄 내림
    for (let i = 0; i < lines_before.length; i++) {
      if (lines_before[i].length > char_length_in_line) {
        lines_before[i] =
          lines_before[i].substring(0, char_length_in_line) +
          '\n' +
          lines_before[i].substring(char_length_in_line);
      }
    }
    text_value = lines_before.join('\n');
  }

  const lines_after = text_value.split('\n');
  if (line_length > 0 && lines_after.length > line_length) {
    // 두 줄 이상이면 입력을 막음
    text_value = lines_after.slice(0, line_length).join('\n');
  }
  return text_value;
}

export const whenBlurFiredInText = async function (active) {
  if (active._data.cv_type !== 'cv_text') return;
  // alert_emoji(active, G.mp.maker.editing_canvas());
  G.mp.maker.cleared_pass = true;
  const cid = active.cid;
  const mp_maker = $qs('#maker_frame');
  const is_carved_phonecase_product = mp_maker.dataset.is_carved_phonecase_product === 'true';
  const is_embroidery_app = mp_maker.dataset.is_embroidery_app === 'true';
  const _text = is_embroidery_app
    ? NewMakerCheckerS.getOnlyPossibleUpperCaseEnAndKrText(active.text)
    : NewMakerCheckerS.getOnlyPossibleText(active.text);
  if (_text.length !== active.text.length)
    $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
  active.text = _text;
  if (is_carved_phonecase_product) {
    const text_length = NewMakerCheckerS.hasOnlyEngAndNum(active.text) ? 7 : 6;
    active.text = active.text.trim();
    const s_length = go(
      active.text.replace(/\s/g, '꒰').split(''),
      filter((v) => v === '꒰'),
      (v) => v.length,
    );
    const text__ = go(
      active.text.replace(/\s/g, '꒰').split(''),
      reject((v) => v === '꒰'),
      take(text_length),
      (v) => v.length,
    );
    active.text = go(active.text.replace(/\n/g, ''), (text) => text.slice(0, text__ + s_length));
  }
  if (is_embroidery_app) {
    const config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
      NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
      box.sel('maker->product_color->base_product_id'),
    );
    active.text = checkTextareaLength(active.text, config);
  }

  return _p.go(_changeCvTextToCvTextImageA(active), async function (cv_text_image) {
    if (cv_text_image) {
      cv_text_image.cid = cid || cv_text_image.cid;
      const f_canvas = cv_text_image.canvas;
      const is_other_design_selected = _p.v(G.mp.maker.active(), '_data.cv_type');
      if (!is_other_design_selected) f_canvas.setActiveObject(cv_text_image);
      if (is_carved_phonecase_product) {
        G.mp.maker.align_print_area_center(cv_text_image, f_canvas);
        f_canvas.renderAll();
      }
      if (is_embroidery_app) {
        NewMakerCvObjAlignmentF.makeAlignmentCenter(cv_text_image, f_canvas);
      }
      G.mp.maker.reset_data_cm(cv_text_image);
      await G.mp.maker.reset_layer();
    }
    G.mp.maker.cleared_pass = false;
    if (UtilF.isLegacyMobile()) {
      if (G.mp.maker.is_android_chrome) {
        setTimeout(function () {
          $.remove_class($1('html'), 'btm_menus_hide');
        }, 100);
      } else {
        $.remove_class($1('html'), 'btm_menus_hide');
      }
      $.css($1('.cv_text_hidden_text_area'), {
        opacity: 0,
      });
      G.mp.maker.ban_frame_hide();
      return _p.go(G.mp.maker.zoom.recover(), function () {
        if (!cv_text_image) G.mp.maker.cleared();
        $.remove_class($1('html'), 'maker_showing_keyboard');

        $1('.m_keyboard_frame') && go($1('.m_keyboard_frame').frame_opt.opened, () => $.frame.close());
      });
    }
  });
};

/*cv_text_image를 화면에 추가할때,*/
export const addCvTextImageInMakerA = async function (is_not_active) {
  $.don_loader_start();
  const mp_maker_el = $qs('#maker_frame');
  if (mp_maker_el.dataset.is_carved_phonecase_product === 'true') {
    if (getCvDesigns(getCurrentFcanvas()._objects).length > 0) {
      $.don_loader_end();
      return;
    }
  }
  if (mp_maker_el.dataset.is_embroidery_app === 'true') {
    const config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
      NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
      box.sel('maker->product_color->base_product_id'),
    );
    if (getCvDesigns(getCurrentFcanvas()._objects).length >= config.obj_length) {
      $.don_loader_end();
      return;
    }
  }
  await _addCvTextImage(is_not_active);
  G.mp.maker.modified();
  $.don_loader_end();
};

/* 저장되어 있던 초기 값이 cv_text 일때, cv_text 정보로 cv_text_image 만들때*/
/* cv_text 정보로, cv_text_image를 만들어준다.*/
export function createCvTextImageFromCvText(text, cv_text_attr, _default_text) {
  return go(
    _createCvTextA(text, cv_text_attr, _default_text),
    _createCvTextImageFromCvTextGettingLocationInfoA,
  );
}

export async function full_cv_text_image(func, cv_text_image, is_not_select) {
  const selected_cv_text_image = cv_text_image || G.mp.maker.active();
  const f_canvas = selected_cv_text_image.canvas;
  const idx = f_canvas._objects.indexOf(selected_cv_text_image);
  const cid = selected_cv_text_image.cid;
  function _createCvTextImage(selected_cv_text_image) {
    return go(
      selected_cv_text_image,
      _createCvTextFromCvTextImageA,
      (cv_text) => {
        cv_text.set({ angle: selected_cv_text_image.angle });
        cv_text.set(_p.pick(selected_cv_text_image, props_to_make_clean_cv_text));
        return cv_text;
      },
      _p.tap(func),
      _p.tap(async function (cv_text) {
        if (cv_text.fontWeight === 'bold') {
          if (hasNotBold(cv_text)) {
            cv_text.fontWeight = 'normal';
            $.alert(T("maker::The font doesn't support Bold."));
            $.attr($1('.select_font_and_style.section >.style'), {
              font_weight: 'normal',
            });
          } else {
            await loadFont({
              font_family: cv_text.fontFamily,
              option: { weight: 'bold' },
            });
          }
          await loadFont({
            font_family: cv_text.fontFamily,
          });
        }
        f_canvas.renderAll();
      }),
      (cv_text) => _createCvTextImageFromCvTextGettingLocationInfoA(cv_text),
      _p.tap(function (cv_text_image) {
        cv_text_image.cid = cid || cv_text_image.cid;
      }),
    );
  }

  return (selected_cv_text_image._changing = _p.go(
    selected_cv_text_image._data.cv_type === 'cv_text_image'
      ? selected_cv_text_image
      : selected_cv_text_image._data.cv_text_image_attrs,
    _createCvTextImage,
    function (cv_text_image) {
      cv_text_image.set(pick(data_for_location, selected_cv_text_image));
      if (selected_cv_text_image._data.cv_type === 'cv_text_image') {
        return cv_text_image;
      } else {
        // cv_text_image.set(pick(data_for_location, selected_cv_text_image._data.cv_text_image_attrs));
        selected_cv_text_image._data.cv_text_image_attrs = cv_text_image.toObject();
        return go(createCvPattern(selected_cv_text_image), (cv_text_image_pattern) => {
          cv_text_image_pattern._data.nscreened = true;
          return cv_text_image_pattern;
        });
      }
    },
    async function (cv_text_image) {
      G.mp.maker.cleared_pass = true;
      G.mp.maker.selected_pass = true;
      f_canvas.remove(selected_cv_text_image).insertAt(cv_text_image, idx);
      if (!is_not_select) f_canvas.setActiveObject(cv_text_image);
      G.mp.maker.cleared_pass = false;
      G.mp.maker.selected_pass = false;
      if ($qs('#maker_frame').dataset.is_carved_phonecase_product === 'true') {
        G.mp.maker.align_print_area_center(cv_text_image, f_canvas);
        f_canvas.renderAll();
      }
      if ($qs('#maker_frame').dataset.is_embroidery_app === 'true') {
        NewMakerCvObjAlignmentF.makeAlignmentCenter(cv_text_image, f_canvas);
        f_canvas.renderAll();
      }
      G.mp.maker.debounced_modified();
      return cv_text_image;
    },
  ));
}
