import axios from 'axios';
import { $findAll, $on } from 'fxdom/es';
import { each, equals, go, takeL, tap } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorUploadImageF } from '../../../UploadImage/F/Function/module/SVGEditorUploadImageF.js';
import { SVGEditorMobileImageUploadMuiS } from '../../S/Mui/module/SVGEditorMobileImageUploadMuiS.js';
import { SVGEditorMobileImageUploadF } from '../Function/module/SVGEditorMobileImageUploadF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...SVGEditorMobileImageUploadMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    go(
      tab_el$,

      tap(
        $findAll('.blank_plate'),
        each(
          $on('click', ({ target, currentTarget: current_target }) => {
            if (equals(target)(current_target)) {
              MuiF.closeFrame();
            }
          }),
        ),
      ),

      tap(
        $findAll('.buttons .button.add_img'),
        each(
          $on('click', () =>
            go(
              $findAll('.buttons input[type="file"]')(tab_el$),
              takeL(1),
              each((el) => el.click()),
            ),
          ),
        ),
      ),

      tap(
        $findAll('.buttons input[type="file"]'),
        each(
          $on('change', async ({ currentTarget: input_el }) => {
            $.don_loader_start();
            try {
              const svg_el = await SVGEditorUploadImageF.uploadImageFromInput(input_el);
              if (svg_el) {
                await tab_el$.__mp_postProcess(svg_el);
                return MuiF.closeFrame();
              }
            } catch (error) {
              console.error(error);
              return $.alert(
                error?.__mp_alert_message ?? T('maker_error_alert::이미지를 그리는 데 실패했습니다.'),
              );
            } finally {
              $.don_loader_end();
            }
          }),
        ),
      ),

      tap(
        $findAll('.button.my_lib'),
        each(
          $on('click', async () => {
            await MuiF.closeFrame();
            const image = await new Promise((resolve) =>
              $.frame.open(
                {
                  frame_name: 'maker.my_lib',
                  page_name: 'maker_my_lib_page',
                  animation: true,
                  loader: true,
                  prev_frame_show: true,
                  appending: __($.attr('is_fixed_head', 'true'), (tab_el) => {
                    $on(
                      'touchmove',
                      (event) => {
                        event.stopPropagation();
                      },
                      { passive: false },
                    )(tab_el);
                  }),
                  closing: function (don_frame, image) {
                    if (!image) {
                      return resolve(null);
                    }
                    $.don_loader_start();
                    resolve(image);
                  },
                },
                {
                  page_name: 'maker_my_lib_page',
                },
              ),
            );
            if (!image) {
              return;
            }

            try {
              const el = await (async () => {
                if (image.type === 'svg') {
                  const url = await SVGEditorUploadImageF.makeImageIdToSvgUrl(image.id);
                  const res = await axios.get(url);
                  const svg_str = res.data;
                  return SVGEditorMobileImageUploadF.convertSVGStrToEl(svg_str);
                }

                return SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(image.url));
              })();
              await tab_el$.__mp_postProcess(el);
            } catch (error) {
              console.error(error);
              return $.alert(
                error?.__mp_alert_message ?? T('maker_error_alert::이미지를 그리는 데 실패했습니다.'),
              );
            } finally {
              $.don_loader_end();
            }
          }),
        ),
      ),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
