import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { go, map } from 'fxjs/es';
import { BpOptionKeyRingConstantS } from '../../KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { BpOptionAcrylicFigureConstantS } from '../../AcrylicFigure/S/Constant/module/BpOptionAcrylicFigureConstantS.js';

export const MASKING_TAPE_EDITOR = 'MASKING_TAPE';

export const BP_OPTION_MAKER_TYPES = [
  MASKING_TAPE_EDITOR,
  'VECTOR',
  VectorEditorConstantS.KEYRING_EDITOR,
  VectorEditorConstantS.STICKER_EDITOR,
  VectorEditorConstantS.PHOTO_CARD_EDITOR,
  VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR,
  VectorEditorConstantS.EX_MAKER_EDITOR,
];

export const MASKING_TAPE_CONFIRMS = go(
  [5201, 5202, 5204, 5203, 5208, 5200, 5205, 5206, 5207],
  map((base_product_id) => {
    return {
      base_product_id,
      m: '//s3.marpple.co/files/u_193535/2022/3/original/77fd15c959b40a078d45df5ffbd8e0a2817ea8773.jpg',
      // m2: '//s3.marpple.co/files/u_193535/2022/2/original/7a2043d686bec0001c4e08a58be9d55d19c0229c3.jpg',
      m_en: '//s3.marpple.co/files/u_193535/2022/3/original/a6111803ea4433d07c382a934ad9af3a834452cc1.jpg',
      // m2_en: '//s3.marpple.co/files/u_193535/2022/2/original/4939f439cfe4cb13dfc01a574fd1e1a32a5a1d051.jpg',
      m_jp: '//s3.marpple.co/files/u_193535/2022/3/original/93c73575ee4e6ddbe3950691dcd6815b05a722e82.jpg',
      // m2_jp: '//s3.marpple.co/files/u_193535/2022/2/original/7765c69b0e0f73164921bd7c04e4f6a5d31340161.jpg',
      pc: '//s3.marpple.co/files/u_193535/2022/3/original/2fc98614c1d0698837e1f265c793967abf6c64376.jpg',
      // pc2: '//s3.marpple.co/files/u_193535/2022/2/original/9edb8a18a130344b44d7b075cf3376adc5df96814.jpg',
      pc_en: '//s3.marpple.co/files/u_193535/2022/3/original/c950d28182c1ccf6e039aa5e539886fbcabbc53e4.jpg',
      // pc2_en: '//s3.marpple.co/files/u_193535/2022/2/original/22888d265761be64fbcd783db1943a69e680df022.jpg',
      pc_jp: '//s3.marpple.co/files/u_193535/2022/3/original/9046b22706ac04eb9013b29783b1bcaf9ee668b85.jpg',
      // pc2_jp: '//s3.marpple.co/files/u_193535/2022/2/original/d30967b4615c2ea1ac7417eec6d426e661552fd02.jpg',
    };
  }),
);

export const SELECT_BOX_STYLE_BP_OPTION_GROUP_IDS = [199];
export const FREE_SIZE_BP_OPTION_GROUP_IDS = [199];
export const FITTED_SIZE_PRICE_STYLE_BP_OPTION_GROUP_IDS = [
  BpOptionKeyRingConstantS.KEYRING_FREE_SIZE_BP_OPTION_GROUP_ID,
  BpOptionAcrylicFigureConstantS.BP_OPTION_GROUP_ID,
];

export const BP_OPTION_GROUP_DEV_NAME = {
  DOSU_COLORS: 'dosu_colors',
  DOSU_COUNTS: 'dosu_counts',
};

export const BIZ_OPTIONS_DEV_NAMES = {
  DOSU_COLORS: 'dosu_colors',
  DOSU_COUNTS: 'dosu_counts',
};

export const BIZ_RELATED_OPTION_GROUP_DEV_NAMES = Object.values(BIZ_OPTIONS_DEV_NAMES);

export const BIZ_OPTION_MENU_KEY = {
  QTY: 'quantity',
  FILE_REF: 'file-reference',
  REQ_MEMO: 'request-memo',
};

export const BIZ_OPTION_MENU_TITLE = 'biz-option';

export const BIZ_PF2_KEY = 'biz';
export const BIZ_PF2_DATA_KEYS = {
  FILE_REF_URL: 'file_reference_url',
  FILE_REF_NAME: 'file_reference_name',
  REQ_MEMO: 'request_memo',
  QTY: 'quantity',
  MOQ: 'moq',
};
