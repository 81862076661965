import i18next from 'i18next';
import i18n from './i18n/index.js';

i18next.init({
  debug:
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && process.env.I18N_DEBUG === 'true',
  resources: i18n,
  nsSeparator: '::',
  keySeparator: ' ##### ',
  fallbackLng: 'kr',
});
