import { $addClass, $attr, $find, $hasClass, $on, $qs, $removeAttr, $removeClass } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopAppKeywordDrawerF } from '../../../../MShop/App/Keyword/Drawer/F/Function/module/MShopAppKeywordDrawerF.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';

export const init = () => {
  if (MShopUtilF.isMobile()) {
    return;
  }
  if ($qs('.bottom-tab-bar__anchor--search'))
    go($qs('.bottom-tab-bar__anchor--search'), delegateBottomBarSearchBtn);
  if ($qs('#search-drawer')) go($qs('#search-drawer'), MShopAppKeywordDrawerF.drawer);
};
const delegateBottomBarSearchBtn = ($search_btn) => {
  // todo :: 임시 에러처리
  if ($search_btn) return;
  /*
  const [, lastFocusEl] = ComponentsHeaderF.findFocusElement(bottomTabBar$);

  if (!is_mobile && bottomTabBar$) {
    window.addEventListener('scroll', (e) => {
      const bottomScrollY = window.scrollY + window.innerHeight;
      const footerTop = $qs('.creator-footer').offsetTop;

      if (bottomScrollY >= footerTop) {
        $setCss({ transform: `translateY(${-Math.abs(bottomScrollY - footerTop)}px)` }, bottomTabBar$);
      } else {
        $setCss({ transform: '' }, bottomTabBar$);
      }
    });
    $trigger('scroll', window);
  }

  if (bottomTabBar$) {
    lastFocusEl.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which;

      if (keyCode == 9 && !e.shiftKey) {
        e.preventDefault();
        const [firstFocusEl] = ComponentsHeaderF.findFocusElement($qs('.app-home'));
        firstFocusEl.focus();
      }
    });
  }*/

  // 검색 drawer 불러오기
  const $search_drawer = $qs('#search-drawer');
  if (!$search_drawer) return;
  const $drawer_inner = $find('.drawer__inner', $search_drawer);
  const $creator_mask = $qs('.creator-mask');

  $on('click', async (e) => {
    e.preventDefault();
    const already_opened = $hasClass('drawer__inner--slide-in', $drawer_inner);
    if (already_opened) {
      MShopAppKeywordDrawerF.closeSearchDrawer();
    } else {
      await MShopAppKeywordDrawerF.openSearchDrawer();
    }
  })($search_btn);

  // 외부 클릭 시 사라지게
  if (!MShopUtilF.isApp()) {
    $on('click', function (e) {
      const is_bottom_bar_clicked = $hasClass('bottom-tab-bar', e.target);
      if ($drawer_inner && $hasClass('drawer__inner--slide-in', $drawer_inner)) {
        if (
          !$hasClass('drawer', e.target) &&
          !$hasClass('bottom-tab-bar__anchor--search', e.target) &&
          !$qs('#search-drawer').contains(e.target) &&
          !is_bottom_bar_clicked
        ) {
          $addClass('drawer__inner--slide-out', $drawer_inner);
          $creator_mask.style.right = '0';
          $creator_mask.style.left = '0';

          // drawer 열기 전 anchor로 돌아가기
          const $search_anchor = $qs('.bottom-tab-bar__anchor--search');
          const prev_anchor = $attr('prev_anchor', $search_anchor);
          $removeClass('bottom-tab-bar__anchor--active', $search_anchor);
          $addClass('bottom-tab-bar__anchor--active', $qs(`.${prev_anchor}`));
          $removeAttr('prev_anchor', $search_anchor);
        }
      }
    })(document);
  }

  const slide_in_classname = 'drawer__inner--slide-in';
  const slide_out_classname = 'drawer__inner--slide-out';

  $drawer_inner.addEventListener('animationend', ({ currentTarget }) => {
    if ($hasClass(slide_out_classname, currentTarget)) {
      go(currentTarget, $removeClass(`${slide_in_classname} ${slide_out_classname}`));
      $removeClass('creator-mask--is-open', $qs('.creator-mask'));
      $.body_fixed(false);
    }
  });
};
