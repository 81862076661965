import { filter, map, find, go } from 'fxjs/es';
import { $find } from 'fxdom/es';

export function getSelectedBpOption({ bp_option_groups_el, name }) {
  return go(bp_option_groups_el, $find(`.bp_option_group[data-name="${name}"] select`), (select_el) =>
    $find(`option[value="${select_el.value}"]`)(select_el),
  );
}

export function getSelectedBpOptionDataByBogId({ base_products_bp_option_groups, bp_option_ids, bog_id }) {
  return go(
    base_products_bp_option_groups,
    find((bpbog) => bpbog._.bp_option_group.id === bog_id),
    (bpbog) => bpbog?._?.bp_option_group?._?.bp_options,
    find((bo) => bp_option_ids.includes(bo.id)),
  );
}

export const getBpOptionGroupsOfBpfId = (bpf_id, base_product) => {
  return go(
    base_product._.base_products_bp_option_groups,
    filter((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id == bpf_id),
    map((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group),
  );
};
