import { $attr, $delegate } from 'fxdom/es';
import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

let is_first = true;

const _downloadImage = () => {
  return new Promise(function (res) {
    const image = new Image();
    image.src =
      '//s3.marpple.co/files/u_193535/2020/3/original/1751088b459d2ae1e512e6f26328f29304f099a24c83b9b.jpg';
    image.onload = function () {
      res(image);
    };
  });
};

export const makeEnterMarppleShopHtml = () => legacyHtml`
  <div class="btn_tip enter_marpple_shop" is_pc="${!UtilF.isLegacyMobile()}">
    <div class="icon">
      <img
        src="//s3.marpple.co/files/u_193535/2023/7/original/6c02eb7a1e276dc9fc30358fb3c42c1035fd4be21.svg"
        alt=""
      />
    </div>
    <div class="txt1">상품 판매하기</div>
  </div>
`;

export const makeEnterMarppleShopEvent = $delegate(
  'click',
  '.enter_marpple_shop',
  errorHandlingEventWrapper(function () {
    openEnterMarppleShop1(true);
  }),
);

export const openEnterMarppleShop1 = (is_open) => {
  if (is_first) {
    is_first = false;
    $.frame.defn_frame({
      frame_name: 'enter_marpple_shop1',
      page_name: 'enter_marpple_shop1_page',
      is_modal: !UtilF.isLegacyMobile(),
      hide_frame_button_type: 'X',
      always_remove: true,
      prev_frame_show: UtilF.isLegacyMobile(),
      height: UtilF.isLegacyMobile() && $.height(window),
      appended: _downloadImage,
      showed: (el) => {
        UtilF.isLegacyMobile() && $.on_prevent_default(el);
      },
      hided: (el) => UtilF.isLegacyMobile() && $.off_prevent_default(el),
    });

    $.frame.defn_page({
      page_name: 'enter_marpple_shop1_page',
      tabs: [
        {
          tab_name: 'enter_marpple_shop1_tab',
          template: () => legacyHtml`
            <div id="main">
              <div class="header"></div>
              <div class="body">
                <div class="bold paragraph">지금 만든 상품을 마플샵에서 판매해보세요!</div>
                ${
                  UtilF.isLegacyMobile()
                    ? legacyHtml`
                      <div class="paragraph">
                        마플샵에서는 나만의 굿즈샵을<br />무료로 오픈할 수 있습니다.<br />내가 디자인한 상품을
                        재고관리, 배송, 운영<br />걱정 없이 판매해보세요.
                      </div>
                    `
                    : legacyHtml`
                      <div class="paragraph">
                        마플샵에서는 나만의 굿즈샵을 무료로 오픈할 수 있습니다.<br />내가 디자인한 상품을
                        재고관리, 배송, 운영 걱정 없이 판매해보세요.
                      </div>
                    `
                }
                <div class="bold paragraph">
                  더 많은 정보는 <a href="https://marpple.shop" class="is_open">marpple.shop</a>에서!&nbsp;😁
                </div>
              </div>
            </div>
          `,
          appended: $.on('click', 'a', function (e) {
            e.preventDefault();
            window.open($attr('href', e.currentTarget));
          }),
        },
      ],
    });
  }
  window.localStorage.setItem('did_enter_marpple_shop', 'true');
  $.frame.open({ frame_name: 'enter_marpple_shop1' });
};
