import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { OMPDosuF } from './module/OMPDosuF.js';
import { OMPDosuConstantS } from '../../S/Constant/module/OMPDosuConstantS.js';
import { filter, go, html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { $el, $prepend, $qs } from 'fxdom/es';
import { getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getBpfOfFcanvas } from '../../../../Maker/F/getSth.js';
import { NewMakerPropertyBpfConstantS } from '../../../../NewMaker/Property/Bpf/S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { makeMakerColorPickerColorName } from '../../../../Maker/F/CvTextImage/tmpl.js';

export function dosuColorPickerHtml(cv_image) {
  const dosu_color_options = BpOptionF.biz.get.currentFace.dosuColorOptions();

  if (UtilArrayS.isEmNil(dosu_color_options)) return '';
  const used_dosu_color_hex_code_for_image = OMPDosuF.cvObj.get.dosuColor(cv_image);

  let selected_dosu_color_option;

  if (used_dosu_color_hex_code_for_image != null) {
    selected_dosu_color_option = BpOptionF.biz.get.currentFace.dosuColorOption(
      used_dosu_color_hex_code_for_image,
    ) || {
      name: '',
      name_en: '',
      name_jp: '',
    };
  }

  /* 현재 active 이미지에서 사용된 도수 컬러 가져오기 (이미지 이미지 업로드할 때 컬러 필터 처리 후 상태)
   *  1. 만약 적용된 도수 컬러가 없다면, 오리지널
   *     - original 이 허용 안되는 경우 -> 에러 상황
   *  2. 적용된 도수 컬러가 옵션 중에 있다면, 해당 옵션 "selected" 로 표시
   *  3. 컬러 옵션 중에 없다면 피커의미
   *     - 피커를 selected 로 표시하고 picker 색상을 해당 filter hex color code 값으로 set
   * */
  return html`
    <div class="section dosu image">
      <div class="dosu-colors">
        <div class="head color-text">
          ${`${T('Image Color')}`}<span class="color-name"
            >${selected_dosu_color_option
              ? makeMakerColorPickerColorName(selected_dosu_color_option['name' + G._en])
              : ``}</span
          >
        </div>
        <!--original_tag 원본 색상 삭제함. 깃 히스토리로 찾을수 있음-->
        <div class="color-pickers">
          <!-- 면 별 도수 색상 컬러 칩 -->
          ${go(
            dosu_color_options,
            filter((o) => o.is_public),
            strMap((o) => {
              const { hex_code, spot_color_name } = BpOptionF.biz.get.parseColorFromDosuColorOption(o);
              return html`
                <div
                  class="color-picker ${hex_code === selected_dosu_color_option?.dev_name?.toUpperCase()
                    ? OMPDosuConstantS.COLOR_PICKER_CN.selected
                    : ''}"
                  color-code="${hex_code}"
                  is-spot-color="${!!spot_color_name}"
                  spot-color-name="${spot_color_name ?? ''}"
                  bp_option_id="${o.id}"
                  data-fx-json="${$dataStr(o)}"
                >
                  <span
                    class="${hex_code === '#FFFFFF' ? 'too_bright' : ''}"
                    style="background:${hex_code};"
                  ></span>
                </div>
              `;
            }),
          )}
        </div>
      </div>
    </div>
  `;
}

export function dosuImageColorPickerHtml(cv_obj) {
  const fcanvas = G.mp.maker.editing_canvas();
  const bpf = getBpfOfFcanvas(fcanvas);
  if (!bpf?.preview?.[NewMakerPropertyBpfConstantS.ALLOW_USER_IMAGE_COLOR]) return ``;
  const image_dosu_color_code = OMPDosuF.cvObj.get.dosuColor(cv_obj);
  const original_colors = OMPDosuF.cvObj.get.originalColors(cv_obj);
  const press_color_codes = OMPDosuF.cvObj.get.originalColorCodes(getCvDesigns(fcanvas._objects));
  if (!press_color_codes?.length) return ``;
  return html`
    <div class="section dosu image">
      <div class="dosu-colors">
        <div class="head color-text">${TT('maker::image::title')}</div>
        <div class="color-pickers">
          ${original_colors?.[0] && original_colors?.[1]
            ? html`
                <div
                  class="color-picker ${!image_dosu_color_code
                    ? OMPDosuConstantS.COLOR_PICKER_CN.selected
                    : ''}"
                  color-code="original"
                >
                  ${`<span style="background: linear-gradient(135deg, ${original_colors[0]} 50%, ${
                    original_colors[0] === '#ffffff' || original_colors[1] === '#fefefe'
                      ? '#f8f8f8'
                      : original_colors[1]
                  } 50%)" class="too_bright"></span>`}
                </div>
              `
            : ''}
          ${go(
            press_color_codes,
            strMap((press_color_code) => {
              return html`
                <div
                  class="color-picker ${image_dosu_color_code === press_color_code
                    ? OMPDosuConstantS.COLOR_PICKER_CN.selected
                    : ''}"
                  color-code="${press_color_code}"
                >
                  <span class="too_bright" style="background:${press_color_code};"></span>
                </div>
              `;
            }),
          )}
        </div>
      </div>
    </div>
  `;
}

export const showToastMsg = ({ text, target_el = document, timeout = 2000, dimming = 500 }) => {
  const WARNING_CLASS_NAME = 'dosu_warning';

  const warning_el = $el(html`
    <div class="${WARNING_CLASS_NAME}">
      <span>${text}</span>
    </div>
  `);

  const exist_toast_el = $qs(`.${WARNING_CLASS_NAME}`);

  if (target_el) {
    if (exist_toast_el) {
      exist_toast_el.remove();
    }

    $prepend(warning_el, target_el);

    warning_el.style.opacity = '1';
    warning_el.style.transition = `opacity ${dimming / 1000}s linear`;

    setTimeout(() => {
      warning_el && (warning_el.style.opacity = '0');
      setTimeout(() => {
        warning_el && warning_el.remove();
      }, dimming);
    }, timeout);
  }
};
