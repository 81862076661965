import { $attr, $qs } from 'fxdom/es';
import { not } from 'fxjs/es';
import { startFrame, startMuiFrame } from '../../base_start_frame.js';

const loadFrameModule = (path) =>
  import(
    /*
      webpackMode: "lazy",
      webpackInclude: /F\/root_frame/,
      webpackChunkName: "[request]"
    */
    `../../../../../../modules/Creator/${path}.js`
  );

(async function () {
  const $don_frame = $qs('.don_frame');
  if (not($don_frame)) return;
  if ($attr('is-mui', $don_frame) === 'true') {
    await startMuiFrame($don_frame);
  } else {
    await startFrame(loadFrameModule, $don_frame);
  }
})();
