import { $addClass, $find, $findAll, $hasClass, $qs, $removeAttr, $removeClass, $setHTML } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopAppKeywordAutoCompleteTmplS } from '../../S/Tmpl/module/MShopAppKeywordAutoCompleteTmplS.js';
import { MShopAppKeywordAutoCompleteF } from './module/MShopAppKeywordAutoCompleteF.js';

export const setAutoCompleteHtml = (
  auto_complete_list,
  input_value = '',
  tab_el = window?.document,
  wrap_sel = '.auto-complete',
) => {
  const $auto_complete_wrap = $qs(wrap_sel, tab_el);
  if (!$auto_complete_wrap) {
    throw new Error('sel is not valid.');
  }
  const tmpl = MShopAppKeywordAutoCompleteTmplS.autoCompleteListTmpl(auto_complete_list, input_value);
  $setHTML(tmpl, $auto_complete_wrap);
};

/**
 * 자동완성 리스트를 비움 (숨기는 것이 아니라 초기화)
 */
export const flushAutoCompleteHtml = (tab_el = document) => {
  const $auto_complete_wrap = $qs('.auto-complete', tab_el);
  if (!$auto_complete_wrap) {
    return;
  }
  const tmpl = MShopAppKeywordAutoCompleteTmplS.autoCompleteListTmpl([], '');
  $setHTML(tmpl, $auto_complete_wrap);
};

/**
 * `hide` 클래스를 추가하여 자동완성 리스트를 숨김
 */
export const hideAutoCompleteList = (tab_el = document) => {
  const $auto_complete_list = $qs('.auto-complete__list', tab_el);
  if (!$auto_complete_list) {
    return;
  }

  go(
    $auto_complete_list,
    $addClass('hide'),
    $findAll('.auto-complete__item'),
    MShopAppKeywordAutoCompleteF.findFocusedItem,
    ($focused) => $focused && $removeAttr('selected', $focused),
  );
};

/**
 * `hide` 클래스를 제거해서 자동완성 리스트를 보이게 함
 */
export const showAutoCompleteList = ($keyword_container) => {
  if (!$keyword_container) {
    return;
  }

  const $auto_complete_list = $find('.auto-complete__list', $keyword_container);
  const $auto_complete_items = $findAll('.auto-complete__item', $keyword_container);

  if ($auto_complete_list && $auto_complete_items?.length) {
    $removeClass('hide', $auto_complete_list);
  }
};

export const isAutoCompleteListVisible = ($keyword_container) => {
  if (!$keyword_container) {
    return false;
  }

  const $auto_complete_list = $find('.auto-complete__list', $keyword_container);
  if (!$auto_complete_list) {
    return false;
  }

  return !$hasClass('hide', $auto_complete_list);
};
