import { OMPDosuConstantS } from '../../S/Constant/module/OMPDosuConstantS.js';
import { OMPDosuF } from './module/OMPDosuF.js';
import { go, map, flatMap, unique, filter, compact, mapC, eachC, extend, object, head } from 'fxjs/es';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { getProductFaces2InMaker } from '../../../../Maker/F/getSth.js';
import { OMPDosuS } from '../../S/Function/module/OMPDosuS.js';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { $qs } from 'fxdom/es';
import { getFlattenCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';

export const cvObj = {
  // @description 메이커에 이미지 추가시 도수 관련 동작 로직
  initialize: async (cv_image, can_use_other_color) => {
    const result = {
      is_original_color_allowed: false, // 원본 색상칩 허용 여부
      is_color_picker_allowed: false, // 자유 컬러 피키 허용 여부
      initial_forced_dosu_color: null, // 초기 강제 색상 변환색
    };

    // ***** [각인 제품] *****
    // - 각인 색상으로 일괄 색상 필터 적용
    const { is_engrave, engrave_hex_code } = BpOptionF.makerHelper.get.isEngraveForCurrentEditingCanvas();
    if (is_engrave) {
      // 디자인 색상을 모두 각인 색상 engrave_hex_code 로 적용
      await cvObj.update.dosuColorToCvObj({
        cv_obj: cv_image,
        color_code: engrave_hex_code,
      });
      result.initial_forced_dosu_color = engrave_hex_code;

      cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_INIT] = {
        ...cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_INIT],
        ...result,
      };
      return;
    }

    // ***** [로직 가드] *****

    // 제한 도수 컬러
    const is_face_dosu_color_limited = BpOptionF.biz.get.currentFace.hasDosuColorOptions();
    // 제한 도수 수량
    const is_face_dosu_count_limited = BpOptionF.biz.get.currentFace.hasDosuCountOptions();

    // [Guard] 도수 관련 제한사항이 있을 때에만 동작
    if (is_face_dosu_color_limited === false && is_face_dosu_count_limited === false) return;

    const original_hex_color_codes = await cvObj.update.originalDosuColors({ cv_obj: cv_image });

    // ***** [도수 제한만 있는 제품] *****
    if (is_face_dosu_color_limited === false && is_face_dosu_count_limited === true) {
      result.is_color_picker_allowed = true;

      // 도수 색상 평가
      const is_valid_cosu_colors = OMPDosuF.validation.insert.dosuColors(cv_image);
      if (is_valid_cosu_colors === false) {
        // 항상 통과 (due to 색상 제한 없음)
        $.alert('Invalid colors');
        return;
      }
      const used_dosu_colors_from_designs = cvObj.get.usedDosuColorFromCurrentFace();
      // 도수 수량 평가
      const is_valid_dosu_counts = OMPDosuF.validation.insert.dosuCounts(cv_image);
      if (is_valid_dosu_counts) {
        // 통과 - 원본 색상 사용 가능
        result.is_original_color_allowed = true;

        if (BpOptionF.biz.get.currentFace.isSingleDosuColorOption()) {
          // 1도 제한 면일때 - 기존 디자인 적용 컬러 or 아니면 원본 그대로
          if (UtilArrayS.isArrayOk(used_dosu_colors_from_designs)) {
            result.initial_forced_dosu_color = used_dosu_colors_from_designs[0];
          }
        }
      } else {
        // 실패 - 원본 색상 사용 불가 -> 강제 색상 변환!
        result.is_original_color_allowed = false;

        // 기존 디자인 적용 컬러 or 자신 원본 색상중 가장 높은 빈도 색상 적용
        result.initial_forced_dosu_color = UtilArrayS.isArrayOk(used_dosu_colors_from_designs)
          ? used_dosu_colors_from_designs[0]
          : original_hex_color_codes[0];

        OMPDosuF.showToastMsg({
          text: TT('biz::dosu::warning_00'),
          target_el: $qs('#maker'),
        });
      }
    }

    // ***** [도수 제한 + 색상 제한 있는 제품] *****
    if (is_face_dosu_color_limited === true && is_face_dosu_count_limited === true) {
      if (can_use_other_color) {
        result.is_original_color_allowed = true;
        result.is_color_picker_allowed = true;
        result.initial_forced_dosu_color = false;
      } else {
        // 도수 색상 평가
        const is_valid_dosu_colors = OMPDosuF.validation.insert.dosuColors(cv_image);

        // 도수 수량 평가
        const is_valid_dosu_counts = OMPDosuF.validation.insert.dosuCounts(cv_image);

        const used_dosu_colors_from_designs = cvObj.get.usedDosuColorFromCurrentFace();
        // 한개 라도 실패
        if (!is_valid_dosu_colors || !is_valid_dosu_counts) {
          // 원본 색상 사용 불가 -> 강제 색상 변환!
          result.is_original_color_allowed = false;

          // 기존 디자인 적용 컬러 or 제공된 첫번째 색상 칩
          result.initial_forced_dosu_color = UtilArrayS.isArrayOk(used_dosu_colors_from_designs)
            ? used_dosu_colors_from_designs[0]
            : go(
                BpOptionF.biz.get.currentFace.defaultDosuColorOption(G.mp.maker.editing_canvas()?.bpf_id),
                (bp_option) => {
                  if (bp_option) return BpOptionF.biz.convert.dosuColorOptionToTextPressColor(bp_option).code;
                  return BpOptionF.biz.get.currentFace.dosuColorOptionHexCodes().color_codes[0];
                },
              );

          OMPDosuF.showToastMsg({
            text: TT('biz::dosu::warning_00'),
            target_el: $qs('#maker'),
          });
        } else {
          // 통과 - 원본 색상 사용 가능
          result.is_original_color_allowed = true;

          if (BpOptionF.biz.get.currentFace.isSingleDosuColorOption()) {
            // 1도 제한 면일때 - 기존 디자인 적용 컬러 or 아니면 원본 그대로
            if (UtilArrayS.isArrayOk(used_dosu_colors_from_designs)) {
              result.initial_forced_dosu_color = used_dosu_colors_from_designs[0];
            }
          }
        }
      }
    }

    // 강제 색변환 컬러 -> 색상 필터 적용
    if (result.initial_forced_dosu_color) {
      await cvObj.update.dosuColorToCvObj({
        cv_obj: cv_image,
        color_code: result.initial_forced_dosu_color,
      });
    }

    cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_INIT] = {
      ...cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_INIT],
      ...result,
    };
  },
  get: {
    usedDosuColorFromCurrentFace: () => {
      return go(
        BpOptionF.makerHelper.get.currentFaceDesigns(),
        flatMap((design) => OMPDosuS.cvDesign.get.dosuColorIfNotOriginalColors(design)),
        compact,
        map((hex_code) => hex_code.toUpperCase()),
        unique,
      );
    },
    usedDosuLastColorCodeFromCurrentFace: () => {
      return go(
        BpOptionF.makerHelper.get.currentFaceDesigns()?.reverse(),
        flatMap((design) => OMPDosuS.cvDesign.get.dosuColorIfNotOriginalColors(design)),
        compact,
        map((hex_code) => hex_code.toUpperCase()),
        head,
      );
    },
    usedDosuColorCodes: (cv_objs) => {
      return go(
        cv_objs,
        filter((cv_obj) => cv_obj.visible),
        getFlattenCvDesigns,
        flatMap((design) => OMPDosuS.cvDesign.get.dosuColorIfNotOriginalColors(design)),
        compact,
        map((hex_code) => hex_code.toUpperCase()),
        unique,
      );
    },
    originalColorCodes: (cv_objs) => {
      return go(
        cv_objs,
        filter((cv_obj) => cv_obj.visible),
        getFlattenCvDesigns,
        flatMap((design) => design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS]),
        compact,
        map((hex_code) => hex_code.toUpperCase()),
        unique,
      );
    },
    usedDosuColorsByFaceId: () => {
      return go(
        getProductFaces2InMaker(), // pfs
        map((pf) => [pf.bpf_id, OMPDosuS.cvDesign.get.usedDosuColorFromPf(pf)]),
        object,
      );
    },
    // 모든 면의 디자인에서 사용된 도수 색상들 배열 리턴
    usedDosuColorsFromAllFacesDesigns: () => {
      return go(
        BpOptionF.makerHelper.get.allFacesDesigns(),
        flatMap((design) => OMPDosuS.cvDesign.get.dosuColorIfNotOriginalColors(design)),
        compact,
        map((hex_code) => hex_code.toUpperCase()),
        unique,
      );
    },

    dosuColorFromDesign: (design) => {
      return (
        design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] ??
        design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS] ??
        []
      );
    },
    dosuColor: (cv_image) => {
      cv_image = cv_image ?? G.mp.maker.active();
      if (cv_image == null) throw new Error(`Cannot get dosu color from design (not exist design)`);

      return cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
    },
    originalColors: (cv_obj) => {
      cv_obj = cv_obj ?? G.mp.maker.active();
      if (cv_obj == null) throw new Error(`Cannot get original colors from design (not exist design)`);

      return cv_obj._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS];
    },
    isAllowOriginalColor: (cv_image) => {
      /* 올린 이미지의 오리지널 컬러가 허용 여부 판단 로직
       *  1. cv_image 타입 이어야함
       *  2. 도수 컬러 옵션이 있을 때,
       *     - 현재 디자인의 original color 배열이 도수 컬러 옵션의 색상으로만 구성이 되어 있어야 함.
       *  3. 도수 수량 옵션이 있을 때,
       *     - 현재 디자인의 original color 배열의 수가 도수 수량 옵션의 최대치보다 작거나 같아야 함.
       *  그 외 모두 false
       * */
      cv_image = cv_image ?? G.mp.maker.active();
      if (cv_image == null) throw new Error(`Cannot get design ${cv_image}`);

      if (cv_image._data.cv_type !== 'cv_image') return false;

      // cache 데이터 사용
      if (cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_INIT]?.is_original_color_allowed != null) {
        return cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_INIT]?.is_original_color_allowed;
      }

      const is_dosu_color_allowed = OMPDosuF.validation.get.forImage.dosuColorAllowed();
      const is_dosu_count_allowed = OMPDosuF.validation.get.forImage.dosuCountAllowed();

      return is_dosu_color_allowed && is_dosu_count_allowed;
    },
  },
  insert: {
    dosuColor: ({ cv_image, color_code }) => {
      if (cv_image == null || cv_image?._data == null) return;
      cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] = color_code.toUpperCase();
    },
    originalDosuColors: ({ cv_image, colors }) => {
      if (cv_image == null || cv_image?._data == null) return;
      cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS] = colors; // String[]
    },
  },
  update: {
    originalDosuColors: async ({ cv_obj }) => {
      if (cv_obj == null) return;
      const { color_codes, quantized_color_codes } = BpOptionF.biz.get.currentFace.dosuColorOptionHexCodes();

      await go(
        BpOptionS.makerHelper.get.unGroupDesigns(cv_obj),
        eachC(async (obj) => {
          if (obj._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS]) return;
          const original_image_url = obj._data.image_url;
          go(
            await OMPDosuF.analysis.fromImage({
              image_src: original_image_url,
              options: {
                is_hex_code: true,
                chunk: OMPDosuConstantS.IMAGE_ANALYSIS.EXTRACT_COLORS_MAX_COUNT,
              },
            }),
            map((d) => {
              // 양자화된 컬러 코드와 매칭되는 옵션의 도수 색상이 존재하는 경우 -> 옵션 도수 색상 컬러코드로 매핑해서 저장
              const quantized_color_hex_code = d.color;
              const quantized_color_code_index = quantized_color_codes.indexOf(quantized_color_hex_code);
              return quantized_color_code_index >= 0
                ? color_codes[quantized_color_code_index].toUpperCase()
                : quantized_color_hex_code.toUpperCase();
            }),
            (original_hex_color_codes) => {
              cvObj.insert.originalDosuColors({ cv_image: cv_obj, colors: original_hex_color_codes });
              if (original_hex_color_codes.length === 1) {
                cvObj.insert.dosuColor({ cv_image: cv_obj, color_code: original_hex_color_codes[0] });
              }
              return original_hex_color_codes;
            },
          );
        }),
      );
    },
    dosuColorsToAllCvObjs: async ({ cv_objs, color_code }) => {
      await go(
        cv_objs,
        mapC((obj) => applyDosuColorToCvObj({ cv_obj: obj, color_code })),
      );
      G.mp.maker.editing_canvas() && G.mp.maker.editing_canvas().renderAll();
    },
    dosuColorToCvObj: async ({ cv_obj, color_code }) => {
      await go(
        BpOptionS.makerHelper.get.unGroupDesigns(cv_obj),
        mapC((obj) => applyDosuColorToCvObj({ cv_obj: obj, color_code })),
      );

      cv_obj.canvas && cv_obj.canvas.renderAll();
    },
  },
  delete: {
    dosuColor: async ({ cv_obj }) => {
      if (cv_obj == null) return;
      await go(
        BpOptionS.makerHelper.get.unGroupDesigns(cv_obj),
        mapC((obj) => {
          obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] = null;

          return new Promise(
            (res) => {
              obj.applyFilters(() => {
                res();
              });
            },
            [],
            obj._originalElement,
          );
        }),
      );

      cv_obj.canvas && cv_obj.canvas.renderAll();
    },
  },
};

async function applyDosuColorToCvObj({ cv_obj, color_code }) {
  color_code = color_code ?? cvObj.get.dosuColor(cv_obj);
  if (color_code == null || !color_code.startsWith('#')) return;

  cvObj.insert.dosuColor({ cv_image: cv_obj, color_code });

  const cv_type = cv_obj._data.cv_type;

  // ** 도수 색상 컬러 필터가 적용되는 대상
  if (['cv_image', 'cv_text_image', 'cv_pattern', 'cv_text_image_pattern'].includes(cv_type)) {
    const color_filter = new fabric.Image.filters.Tint({
      color: color_code,
      opacity: 1.0,
    });
    cv_obj.filters = [color_filter];

    if (cv_type === 'cv_pattern') {
      cvObj.insert.dosuColor({ cv_image: cv_obj._data.cv_image_attrs, color_code });
    }
  }

  // ** 도수 색상이 텍스트 color 에 적용되어야 하는 대상
  if (['cv_text', 'cv_text_image', 'cv_text_image_pattern'].includes(cv_type)) {
    const font_data = BpOptionF.biz.convert.dosuColorOptionToTextFontData({ color_code });
    extend(
      cv_type === 'cv_text_image_pattern' ? cv_obj._data.cv_text_image_attrs._data : cv_obj._data,
      font_data,
    );
  }
  return cv_obj.applyFilters
    ? new Promise((res) => {
        cv_obj.applyFilters(() => {
          cv_obj.filters = [];
          res();
        });
      })
    : null;
}
