export const EMPTY_STORE_IMG =
  '//s3.marpple.co/files/u_218933/2020/6/original/300709261dd47459652b2130f12112c1bfdb495bfd32a5e.png';

export const EMPTY_USER_IMG =
  '//s3.marpple.co/files/u_2308668/2023/9/original/79163ad634e03fb450b0f2ad65f860a4546f11f01.png';

export const ARTICLE_TYPES = {
  photo: 'photo',
  text: 'text',
  gallery_photo: 'gallery_photo',
  community_text: 'community_text',
  community_photo: 'community_photo',
  review_photo: 'review_photo',
};

export const STORE_SELLER_COMMUNITY_TYPES = [ARTICLE_TYPES.photo, ARTICLE_TYPES.gallery_photo];
export const STORE_COMMUNITY_TYPES = [ARTICLE_TYPES.community_text, ARTICLE_TYPES.community_photo];
export const ARTICLE_PHOTO_TYPES = [
  ARTICLE_TYPES.photo,
  ARTICLE_TYPES.gallery_photo,
  ARTICLE_TYPES.community_photo,
  ARTICLE_TYPES.review_photo,
];

export const ARTICLE_TEXT_TYPES = [ARTICLE_TYPES.text, ARTICLE_TYPES.community_text];
