import { each, extend, find } from 'fxjs';
import { requiringBorder } from '../../../../../modules/Maker/F/Product/bpc_tmpl.js';
import { discontinuedSizeIds, soldOutSizeIds } from '../../../../../modules/ProductColor/S/fs.js';
import { isNeedPreview } from '../../../../../modules/Maker/S/categorize.js';
import { $addClass, $qs, $removeClass } from 'fxdom/es';
import { delay, go, html, strMap, reject } from 'fxjs/es';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function (LF) {
  G.mp.maker.picker = {};

  $.frame.defn_frame({
    frame_name: 'maker.picker.bpcs',
    page_name: 'maker.picker.bpcs',
    el_class: 'picker bpcs maker_dialog no_header prevent_bg_event',
    animation: true,
    prev_frame_show: true,
    always_remove: true,
    height: !UtilF.isLegacyMobile() ? '' : 245 + G.mp.frame.v_height,
    header_height: G.mp.frame.v_height,
    hide_frame_button_type: !UtilF.isLegacyMobile() ? '' : 'V',
    showed: !UtilF.isLegacyMobile() ? _p.noop : $.on_prevent_default,
    hiding: !UtilF.isLegacyMobile() ? _p.noop : $.off_prevent_default,
    appended: UtilF.isLegacyMobile() ? $.frame_close_bg : _p.noop,
    // appended: $.frame_close_bg,
    is_modal: !UtilF.isLegacyMobile(),
  });

  $.frame.defn_page({
    page_name: 'maker.picker.bpcs',
    hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : '',
    tabs: [
      {
        tab_name: 'maker.picker.bpcs',
        inner_scroll_target: '.colors_container',
        data_func: function () {
          return {
            selector: 'maker->product_color->_->base_product->_->base_product_colors',
            base_product_colors: box.sel('maker->product_color->_->base_product->_->base_product_colors'),
            base_product: box.sel('maker->product_color->_->base_product'),
            selected_bpc_id: box.sel('maker->product_color->base_product_color_id'),
          };
        },
        template: function (data) {
          go(
            data.base_product_colors,
            each((color) => {
              color['need_ccc'] = requiringBorder([color.color_code, color.color_code2]);
            }),
          );
          data.is_transparent = isNeedPreview(data.base_product);

          data.done_type = data.done_type || 'Done';
          return _p.go(
            data,
            UtilF.isLegacyMobile()
              ? _p.t(
                  'data',
                  '\
          .color[is_transparent={{data.is_transparent}}]\
            .options\
              .preview_title {{T("maker::Preview")}}\
              button[type="button" done_type={{data.done_type}}].done {{T("color::" + data.done_type)}}\
            .body.iscroll_wrapper[_sel="{{data.selector}}"]\
              .wrapper.iscroll_ul_wrapper\
                {{G.mp.maker.picker.bpc_items_tmpl(data.selected_bpc_id, data.base_product_colors)}}',
                )
              : _p.t(
                  'data',
                  '\
          .color_body\
            .title {{data.is_transparent ? T("maker::Preview") : T("Add Color")}}\
            .colors_container[_sel="{{data.selector}}"] \
              {{G.mp.maker.picker.bpc_items_tmpl2(data.selected_bpc_id, data.base_product_colors)}}\
            button[type="button" done_type={{data.done_type}}].done {{T("color::Add")}}',
                ),
          );
        },
        appended: _pipe(
          $.on('click', '.done[done_type="Done"]', function (e) {
            const don_page = $.closest(e.delegateTarget, '.don_page');
            _p.go(
              null && don_page.page_opt.hiding(box.sel($.find1(don_page, '.color_item.selected'))),
              function () {
                $.frame.close();
              },
            );
          }),
          $.on('click', '.done[done_type="Add"]', function (e) {
            const don_page = $.closest(e.delegateTarget, '.don_page');
            _p.go(don_page, $.find1('.color_item.selected'), box.sel, don_page.page_opt.adding, function () {
              $.frame.close();
            });
          }),
          $.on3('click', '.color_item', (e) =>
            _p.go(e, $.selectable, box.sel, async function (bpc) {
              $.don_loader_start();
              await delay(10, () => {});
              if (UtilF.isLegacyMobile()) {
                const scroll_wrapper_el = $.closest(e.currentTarget, '.iscroll_wrapper');
                $.iscroll_to(scroll_wrapper_el, e.currentTarget);
              }
              const el_don_page = $.closest(e.delegateTarget, '.don_page');

              await el_don_page.page_opt.selecting(bpc);
              $.don_loader_end();
            }),
          ),
        ),
        ending: _p.identity,
      },
    ],
    adding: _p.identity,
    selecting: _p.identity,
    showing: __($.find1('.body'), $.css({ opacity: 0, transition: 'opacity 0.2s' })),
    showed: __(_p.tap($.iscroll_on), $.find1('.body'), $.css({ opacity: 1 })),
    hiding: $.iscroll_off,
  });

  G.mp.maker.picker.bpc_items_tmpl = _p.sum(
    _p.t(
      'select_bpc_id, bpc',
      '\
    .item.color_item.i_item[_id="{{bpc.id}}" _sel="./(#{{bpc.id}})" class="{{bpc.id == select_bpc_id ? "selected" : ""}}" color_code="{{bpc.color_code}}" need_ccc="{{bpc.need_ccc}}"]\
      .code[style="background: {{bpc.color_code2 ? \'linear-gradient(135deg, \' + bpc.color_code + \' 50%, \' + bpc.color_code2 + \' 50%)\' : bpc.color_code}}"]\
      .name {{bpc["name"+_en]}}\
      ',
    ),
  );

  G.mp.maker.picker.bpc_items_tmpl2 = _p.sum(
    _p.t(
      'select_bpc_id, bpc',
      '\
    .color_item[_id="{{bpc.id}}" _sel="./(#{{bpc.id}})" class="{{bpc.id == select_bpc_id ? "selected" : ""}}" color_code="{{bpc.color_code}}" need_ccc="{{bpc.need_ccc}}"]\
      .code_wrap\
        .code[style="background: {{bpc.color_code2 ? \'linear-gradient(135deg, \' + bpc.color_code + \' 50%, \' + bpc.color_code2 + \' 50%)\' : bpc.color_code}}"]\
      .name {{bpc["name"+_en]}}\
      ',
    ),
  );
  $.frame.defn_frame({
    frame_name: 'maker.picker.bpss',
    page_name: 'maker.picker.bpss',
    el_class: 'picker bpss maker_dialog no_header prevent_bg_event',
    animation: true,
    prev_frame_show: true,
    always_remove: true,
    height: 183 + G.mp.frame.v_height,
    header_height: G.mp.frame.v_height,
    hide_frame_button_type: 'V',
    appended: $.frame_close_bg,
    showed: $.on_prevent_default,
    hiding: $.off_prevent_default,
  });

  $.frame.defn_page({
    page_name: 'maker.picker.bpss',
    tabs: [
      {
        tab_name: 'maker.picker.bpss',
        template: _p.pipe(
          function () {
            const pc = box.sel('maker->product_color');
            const bpcs = _p.v(pc, '_.base_product._.base_product_colors');
            const sold_out_size_ids = soldOutSizeIds(pc, bpcs);
            const discontinued_size_ids = discontinuedSizeIds(pc, bpcs);
            return _p.mr(
              box.sel('maker->product_color->_->base_product->_->base_product_sizes'),
              sold_out_size_ids,
              discontinued_size_ids,
            );
          },
          _p.t(
            'bpss, sold_out_size_ids, discontinued_size_ids',
            '\
          .size\
            .options\
              button[type="button" style="display: none;"].size_table 사이즈표\
              button[type="button"].done {{T("Done")}}\
            .body.iscroll_wrapper[_sel="maker->product_color->_->base_product->_->base_product_sizes"]\
              .wrapper.iscroll_ul_wrapper\
                {{G.mp.maker.picker.bps_items_tmpl(bpss, sold_out_size_ids, discontinued_size_ids)}}',
          ),
        ),
        appended: _pipe(
          $.on('click', '.done', function () {
            $.frame.close();
          }),
          $.on3('click', '.item', async function (e) {
            $.don_loader_start();
            await delay(10, () => {});
            const el_don_page = $.closest(e.currentTarget, '.don_page');
            return _p.go($.selectable2(e.currentTarget), box.sel, async function (bps) {
              $.iscroll_to($.closest(e.currentTarget, '.iscroll_wrapper'), e.currentTarget);
              const maker_frame = $qs('#maker_frame');

              if (maker_frame) $addClass('pc_canvas_change_ing')(maker_frame);
              await el_don_page.page_opt.selecting(bps);
              if (maker_frame) $removeClass('pc_canvas_change_ing')(maker_frame);
              $.don_loader_end();
            });
          }),
        ),
      },
    ],
    showing: __($.find1('.body'), $.css({ opacity: 0, transition: 'opacity 0.2s' })),
    showed: __(_p.tap($.iscroll_on), $.find1('.body'), $.css({ opacity: 1 })),
    hiding: $.iscroll_off,
  });

  G.mp.maker.picker.bps_items_tmpl = function (bpss, sold_out_size_ids, discontinued_size_ids) {
    const avaiableSizes = go(
      bpss,
      reject(({ id }) => discontinued_size_ids.includes(id)),
    );

    return go(
      avaiableSizes,
      strMap(
        (bps) => html`
          <div
            class="sold-out-select-box-view-v1 item i_item ${bps.id ==
            box.sel('maker->product_color->base_product_size_id')
              ? 'selected'
              : ''} ${_.contains(sold_out_size_ids, bps.id) ? 'sold_out' : ''}"
            _id="${bps.id}"
            _sel="./(#${bps.id})"
          >
            <span class="sold-out-select-box-view-v1__name">${bps['name' + _en]}</span>
            <span class="sold-out-select-box-view-v1__no_stock">${T('cart_modal::일시품절')}</span>
          </div>
        `,
      ),
    );
  };
})({});
