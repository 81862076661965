import { isCanOverflow } from './getSth.js';
import { getCurrentFcanvas } from './Fcanvas/cv_object.js';

function check_cv_image_40_cm_base(obj) {
  if (collabo_type !== 'creator' && $.attr($1('html'), 'mp_worker_policy')) return false;
  if (isCanOverflow()) return false;
  return _p.max([obj._data.real_width_cm, obj._data.real_height_cm]) >= 40;
}

function check_cv_image_5_mm_base(obj) {
  return false;
  // if ($.attr($1('html'), 'mp_worker_policy')) return false;
  // return _.min([G.mp.maker.fabric_get_width(obj), G.mp.maker.fabric_get_height(obj)]) < 6;
}

export const checkDesignSize = function (cv_object, idx) {
  if (cv_object.type !== 'group') cv_object.is_oversized = check_cv_image_40_cm_base(cv_object);
  if (cv_object.type !== 'group') cv_object.is_smallsized = check_cv_image_5_mm_base(cv_object);
  idx = idx || G.mp.maker.editing_canvas_idx();
  f(cv_object, cv_object.scaleX, cv_object.scaleY);
  function f(cv_obj, scaleX, scaleY) {
    const objects = cv_obj._objects || cv_obj.objects;
    if (objects) {
      _p.each(objects, function (o) {
        const new_scaleX = scaleX * o.scaleX;
        const new_scaleY = scaleY * o.scaleY;
        const rect = {
          width: o.width * new_scaleX,
          height: o.height * new_scaleY,
        };
        const width_cm = G.mp.maker.to_cm('width', rect, idx);
        const height_cm = G.mp.maker.to_cm('height', rect, idx);
        o._data.real_width_cm = width_cm;
        o._data.real_height_cm = height_cm;
        o.is_oversized = check_cv_image_40_cm_base(o);
        o.is_smallsized = check_cv_image_5_mm_base(o);
        f(o, new_scaleX, new_scaleY);
      });
    }
  }
  if (_p.v(cv_object, '_data.cv_type') === 'cv_group') {
    cv_object.is_oversized = !!G.mp.maker.cv_objects_deep_find(cv_object, function (v) {
      return v.is_oversized;
    });
    cv_object.is_smallsized = !!G.mp.maker.cv_objects_deep_find(cv_object, function (v) {
      return v.is_smallsized;
    });
  }
};

export const checkCurrentFcanvasDesignSize = function () {
  const fcanvas = getCurrentFcanvas();
  _p.each(G.mp.maker.designs(fcanvas), function (vv) {
    checkDesignSize(vv);
  });
  fcanvas.renderAll();
};

export const checkAllFcanvasDesignSize = function () {
  const canvass = box.sel('maker->canvass');
  _p.each(canvass, function (v, i) {
    _p.each(G.mp.maker.designs(v), function (vv) {
      checkDesignSize(vv, i);
    });
  });
};
