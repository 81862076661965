export const ga = () => {};

export const signUpMpCreator = ({ email } = {}) => {
  try {
    window.email_address = email || '';
    window.phone_number = '';

    if (typeof gtag == 'function') {
      gtag('event', 'sign_up');
      if (G.collabo_type == '') {
        if (T.lang == 'kr') {
          gtag('event', 'conversion', {
            send_to: 'AW-881715956/cDuKCL252GYQ9NW3pAM',
          });
          gtag('event', 'conversion', { send_to: 'AW-881715956/kz_HCO776J8YEPTVt6QD' });
        } else if (T.lang == 'jp') {
          gtag('event', 'conversion', { send_to: 'AW-828951906/_vH5CPGf7cIBEOKao4sD' });
        } else {
          gtag('event', 'conversion', {
            send_to: 'AW-828951906/BZPzCKSE5o8BEOKao4sD',
          });
        }
      }
    }
  } catch (err) {}
};

export const bizGoodsTalk = async (collabo_type) => {
  return new Promise((rs) => {
    if (typeof gtag == 'function' && collabo_type == '') {
      const callback = function () {
        rs(true);
      };
      gtag('event', 'conversion', {
        send_to: 'AW-881715956/Ow-xCL-k9pcYEPTVt6QD',
        event_callback: callback,
      });
    } else {
      rs(true);
    }
  });
};
