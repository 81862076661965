import { each, every, go } from 'fxjs/es';
import { $qs } from 'fxdom';
import { $css, $find, $findAll, $height, $width } from 'fxdom/es';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';

!(function (LF) {
  function prerequisiteForAppPc() {
    if (G.collabo_type !== '') return;
    const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
    if (VectorEditorConstantS.EDITOR_TYPES.includes(maker_type)) return;
    if (window.innerWidth < 820) return;
    return go(
      ['.add_img.circle_icon', '.add_text.circle_icon', '.add_sticker2.circle_icon', '.maker_menu'],
      every((v) => go($qs(v), (el) => el && $css('display')(el) !== 'none')),
    );
  }

  function prerequisiteForAppMobile() {
    if (G.collabo_type !== '') return;
    const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
    if (VectorEditorConstantS.EDITOR_TYPES.includes(maker_type)) return;
    return go(
      ['.add_img.circle_icon', '.add_text.circle_icon', '.add_sticker2.circle_icon', '.maker_menu'],
      every((v) => go($qs(v), (el) => el && $css('display')(el) !== 'none')),
    );
  }

  const tip_1_tmpl = _p.t$(
    '\
    .tip_box.tip_1\
      .block\
      .body\
        .title {{T("Add text and design templates")}}\
        .description {{T("Design your own with text and design templates.")}}\
        .pagination\
          .dot\
          .dot.selected\
          .dot\
          .dot\
        .cancel\
        .next {{T("Next")}}\
  ',
  );
  const tip_2_tmpl = _p.t$(
    '\
    .tip_box.tip_2\
      .block\
      .body\
        .title {{T("Upload your photo or design")}}\
        .description {{T("You can upload your own design and photo.")}}\
        .pagination\
          .dot.selected\
          .dot\
          .dot\
          .dot\
        .cancel\
        .next {{T("Next")}}\
  ',
  );
  const tip_3_tmpl = _p.t$(
    '\
    .tip_box.tip_3\
      .block\
      .tr_block\
      .body\
        .title {{T("Edit your design")}}\
        .description {{T("You can easily edit your design by using editing toolbar.")}}\
        .pagination\
          .dot\
          .dot\
          .dot.selected\
          .dot\
        .cancel\
        .next {{T("Next")}}\
  ',
  );
  const tip_3_tmpl_pc = _p.t$(
    '\
    .tip_box.tip_3\
      .block._top\
      .block._board\
      .block._marpplizer\
      .tr_block\
      .body\
        .title {{T("Edit your design")}}\
        .description {{T("You can easily edit your design by using editing toolbar.")}}\
        .pagination\
          .dot\
          .dot\
          .dot.selected\
          .dot\
        .cancel\
        .next {{T("Next")}}\
  ',
  );

  const tip_4_tmpl = _p.t$(
    '\
    .tip_box.tip_4\
      .block\
      .body\
        .title {{T("Choose printing side")}}\
        .description {{T("You can have your product printed front and back of the product")}}\
        .pagination\
          .dot\
          .dot\
          .dot\
          .dot.selected\
        .cancel\
        .next {{T("Got it")}}\
  ',
  );

  function tip_func_2_pc() {
    _p.go(
      tip_1_tmpl(),
      $.append_to($1('#body')),
      _p.tap(function (tip_box) {
        _go(
          [$1('.add_sticker2.circle_icon').cloneNode(true), $1('.add_text.circle_icon').cloneNode(true)],
          $.append_to(tip_box),
        );
      }),

      LF.cancel_ev,
      $.on('click', '.next', function (e) {
        $.remove(e.delegateTarget);
        tip_func_3_pc();
      }),
      _p.tap(function (tip_box) {
        const add_sticker2_el = $1('.mp_maker .decoration_menu_for_pc .add_sticker2.circle_icon');
        const add_sticker2 = add_sticker2_el.getBoundingClientRect();
        const add_text_el = $1('.mp_maker .decoration_menu_for_pc .add_text.circle_icon');
        const add_text = add_text_el.getBoundingClientRect();
        const body = $.find1(tip_box, '>.body');

        _go(
          body,
          $.css({
            top: add_text.top,
            left: add_text.left + 65,
          }),
        );
        _go(
          tip_box,
          $.find1('.add_sticker2.circle_icon'),
          $.css({
            top: add_sticker2.top,
            width: add_sticker2.width,
            left: add_sticker2.left,
          }),
        );
        _go(
          tip_box,
          $.find1('.add_text.circle_icon'),
          $.css({
            top: add_text.top,
            width: add_text.width,
            left: add_text.left,
          }),
        );
      }),
      _p.tap(function (tip_box) {
        anime({
          targets: $.find1(tip_box, '>.body'),
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      }),
    );
  }

  G.mp.maker.tip_func_pc_start = tip_func_1_pc;
  G.mp.maker.tip_func_pc_close = function () {
    $.remove($('.canvas_man'));
    $.remove($('.tip_box'));
  };
  function tip_func_1_pc() {
    if (!prerequisiteForAppPc()) return;
    $.on(
      window,
      'resize',
      _p.throttle(function resize() {
        G.mp.maker.tip_func_pc_close();
      }, 30),
    );

    $.scroll_top(window, 0);
    $.body_fixed(true);
    return _p.go(
      tip_2_tmpl(),
      $.append_to($1('body')),
      _p.tap(function (tip_box) {
        _go($1('.add_img.circle_icon').cloneNode(true), $.append_to(tip_box));
      }),
      (LF.cancel_ev = $.on('click', '.cancel', function (e) {
        done_tutorial_f();
        $.body_fixed();
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 300,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
          },
        });
      })),
      $.on('click', '.next', function (e) {
        box.set('done_tutorial', true);
        $.remove(e.delegateTarget);
        tip_func_2_pc();
      }),
      _p.tap(function (tip_box) {
        const add_img = $1('.mp_maker .add_img.circle_icon').getBoundingClientRect();

        _go(
          tip_box,
          $.find1('.add_img.circle_icon'),
          $.css({
            top: add_img.top,
            width: add_img.width,
            left: add_img.left,
          }),
        );
        _go(
          $.find1(tip_box, '>.body'),
          $.css({
            top: add_img.top - 5,
            left: add_img.left + add_img.width,
          }),
        );
      }),
      _p.tap(
        _p.tap($.find1('>.body'), function (body) {
          anime({
            targets: body,
            opacity: 1,
            duration: 500,
            easing: 'linear',
          });
        }),
        $.find1('.block'),
        function (block) {
          return anime({
            targets: block,
            opacity: 0.3,
            duration: 500,
            easing: 'linear',
          }).finished;
        },
      ),
    );
  }

  function done_tutorial_f() {
    _p.go($.post('/@api/done_tutorial', {}), function () {
      box.set('done_tutorial', true);
      window.localStorage.setItem('done_tutorial', 'true');
    });
  }

  function tip_func_3_pc() {
    _go(
      tip_3_tmpl_pc(),
      $.append_to($1('#maker >.board')),
      _p.tap(function (el) {
        if ($.css($1('.mp_maker .select_face'), 'display') == 'none')
          _go(el, $.find1('.next'), $.text(T('Got it')));
      }),
      $.on('click', '.cancel', function (e) {
        $.body_fixed();
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 300,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
            $.remove_class($1('html'), 'tip_box_man');
            done_tutorial_f();
          },
        });
      }),
      $.on('click', '.next', function (e) {
        $.remove(e.delegateTarget);
        if ($.css($1('.mp_maker .select_face'), 'display') == 'none') {
          done_tutorial_f();
          $.body_fixed();
        } else {
          tip_func_4_pc();
        }
        $.remove_class($1('html'), 'tip_box_man');
      }),
      function (tip_box) {
        $.add_class($1('html'), 'tip_box_man');
        const maker_menu_top = $.offset($1('.mp_maker .maker_menu')).top - $.scroll_top(window);
        const width = $.width($1('.mp_maker .board'));
        const height = $.height($1('.mp_maker .board'));
        if (G.collabo_type !== '') {
          _go(
            $.find1(tip_box, '.block._top'),
            $.css({
              top: 0,
              height: maker_menu_top,
            }),
          );
          _go(
            $.find1(tip_box, '.block._marpplizer'),
            $.css({
              top: maker_menu_top - 1,
            }),
          );
          _go(
            $.find1(tip_box, '.block._board'),
            $.css({
              top: maker_menu_top + 66,
              width: width,
              height: height + 600,
            }),
          );
        }

        _go(
          $.find1(tip_box, '>.body'),
          $.css({
            top: 62 + maker_menu_top + 5,
            left: width / 2 - $.width($.find1(tip_box, '>.body')) / 2,
          }),
        );
        anime({
          targets: $.find1(tip_box, '>.body'),
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      },
    );
  }

  function tip_func_4_pc() {
    _go(
      tip_4_tmpl(),
      $.append_to($1('body')),
      _p.tap(function (tip_box) {
        _go($1('.select_face').cloneNode(true), $.append_to(tip_box));
        const select_face_el = $1('.mp_maker .select_face');
        const select_face = $1('.mp_maker .select_face').getBoundingClientRect();
        _go(
          tip_box,
          $.find1('>.select_face'),
          $.css({
            top: select_face.top,
            left: select_face.left,
          }),
        );

        const body = $.find1(tip_box, '>.body');
        _go(
          body,
          $.css({
            top: select_face_el.getBoundingClientRect().top + 30 + 10,
            left: select_face_el.getBoundingClientRect().left - 250,
          }),
        );
        anime({
          targets: body,
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      }),
      $.on('click', '.cancel, .next', function (e) {
        $.body_fixed();
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 500,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
          },
        });
        done_tutorial_f();
      }),
    );
  }

  function tip_func_start() {
    if (!prerequisiteForAppMobile()) return;
    setTimeout(function () {
      $.trigger($1('.decoration_menu .main_icon.circle_icon'), 'click');
    }, 600);
    tip_func_1();
  }
  G.mp.maker.tip_func_start = tip_func_start;

  function tip_func_2() {
    _p.go(
      tip_1_tmpl(),
      $.append_to($1('#body')),
      _p.tap(function (tip_box) {
        _go(
          [$1('.add_sticker2.circle_icon').cloneNode(true), $1('.add_text.circle_icon').cloneNode(true)],
          $.append_to(tip_box),
        );
      }),

      $.on('click', '.cancel', function (e) {
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 300,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
            $.trigger($1('.decoration_menu .main_icon.circle_icon'), 'click');
            done_tutorial_f();
          },
        });
      }),
      $.on('click', '.next', function (e) {
        _go(e.delegateTarget, $.find1('>.body'), $.hide);
        _go(e.delegateTarget, $.find1('.circle_icon'), $.hide);
        $.trigger($1('.decoration_menu .main_icon.circle_icon'), 'click');
        $.remove(e.delegateTarget);
        tip_func_3();
      }),
      function (tip_box) {
        const add_sticker2 = $qs('.decoration_menu .add_sticker2.circle_icon').getBoundingClientRect();
        const add_text = $qs('.decoration_menu .add_text.circle_icon').getBoundingClientRect();

        _go(
          tip_box,
          $.find1('.add_sticker2.circle_icon'),
          $.css({
            top: add_sticker2.top,
            width: add_sticker2.width,
            boxSizing: 'border-box',
            left: add_sticker2.left,
          }),
        );
        _go(
          tip_box,
          $.find1('.add_text.circle_icon'),
          $.css({
            top: add_text.top,
            boxSizing: 'border-box',
            width: add_text.width,
            left: add_text.left,
          }),
        );
        const body_el = go(tip_box, $.find1('>.body'));
        _go(
          body_el,
          $.css({
            top: add_text.top + 8,
            left: add_text.left - $width(body_el) + 10,
          }),
        );
        const body = $.find1(tip_box, '>.body');
        anime({
          targets: body,
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      },
    );
  }

  function tip_func_1() {
    _p.go(
      tip_2_tmpl(),
      $.append_to($1('#body')),
      _p.wait(1500),

      _p.tap(function (tip_box) {
        const body = $.find1(tip_box, '>.body');
        anime({
          targets: body,
          opacity: 1,
          duration: 500,
          easing: 'easeOutSine',
        });
      }),
      _p.tap(function (tip_box) {
        _go($1('.add_img.circle_icon').cloneNode(true), $.append_to(tip_box));
      }),

      $.on('click', '.cancel', function (e) {
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 300,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
            $.trigger($1('.decoration_menu .main_icon.circle_icon'), 'click');
            done_tutorial_f();
          },
        });
      }),
      $.on('click', '.next', function (e) {
        $.remove(e.delegateTarget);
        tip_func_2();
      }),
      function (tip_box) {
        const add_img = $1('.mp_maker .decoration_menu .add_img.circle_icon').getBoundingClientRect();
        _go(
          tip_box,
          $.find1('.add_img.circle_icon'),
          $.css({
            top: add_img.top,
            width: add_img.width,
            boxSizing: 'border-box',
            left: add_img.left,
          }),
        );
        const body_el = go(tip_box, $.find1('>.body'));
        _go(
          body_el,
          $.css({
            top: add_img.top - 34,
            left: add_img.left - $width(body_el) + 10,
          }),
        );
        const block = $.find1(tip_box, '>.block');
        anime({
          targets: block,
          opacity: 0.3,
          duration: 500,
          easing: 'easeOutSine',
          // easing: 'linear'
        });
      },
    );
  }

  function tip_func_3() {
    _go(
      tip_3_tmpl(),
      $.append_to($1('#body')),
      _p.tap(function (el) {
        if ($.css($1('.mp_maker .select_face'), 'display') == 'none')
          _go(el, $.find1('.next'), $.text(T('Got it')));
      }),
      $.on('click', '.cancel', function (e) {
        anime({
          targets: e.delegateTarget,
          opacity: 0,
          duration: 300,
          easing: 'linear',
          complete: function () {
            $.remove(e.delegateTarget);
            done_tutorial_f();
          },
        });
      }),
      $.on('click', '.next', function (e) {
        $.remove(e.delegateTarget);
        if ($.css($1('.mp_maker .select_face'), 'display') == 'none') {
          done_tutorial_f();
        } else {
          tip_func_4();
        }
      }),
      function (tip_box) {
        const maker_menu_el = $1('.mp_maker .maker_menu');
        const maker_menu_location = maker_menu_el.getBoundingClientRect();
        const body_el = go(tip_box, $.find1('>.body'));
        _go(
          body_el,
          $.css({
            top: maker_menu_location.top + 5 + $height(maker_menu_el),
            left: $width(window) / 2,
          }),
        );
        const body = $.find1(tip_box, '>.body');
        anime({
          targets: body,
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      },
    );
  }

  function tip_func_4() {
    _go(
      tip_4_tmpl(),
      $.append_to($1('#body')),
      _p.tap(function (tip_box) {
        _go($1('.select_face').cloneNode(true), $.append_to(tip_box));
      }),
      $.on('click', '.cancel, .next', function (e) {
        _p.go(
          e.delegateTarget,
          _p.tap(function (dt) {
            const body = $.find1(dt, '>.body');
            done_tutorial_f();
            const block = $.find1(dt, '>.block');

            return anime({
              targets: [body, block],
              duration: 300,
              opacity: 0,
              easing: 'linear',
            }).finished;
          }),
          function () {
            $.remove(e.delegateTarget);
          },
        );
      }),
      function (tip_box) {
        const body = $.find1(tip_box, '>.body');
        const select_face_el = $1('.mp_maker .select_face');
        const select_face_location = select_face_el.getBoundingClientRect();
        _go(
          body,
          $.css({
            top: select_face_location.top - 5 - $height(body),
            left: select_face_location.left + select_face_location.width / 2,
          }),
        );
        console.log(select_face_location.left);
        _go(
          tip_box,
          $findAll('.select_face'),
          each(
            $.css({
              top: select_face_location.top,
              left: select_face_location.left,
            }),
          ),
        );
        anime({
          targets: body,
          opacity: 1,
          duration: 500,
          easing: 'linear',
        });
      },
    );
  }
})({});
