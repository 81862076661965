import { extend, filter, find, flatMap, go, map, mapObject, pick, reduce, reject } from 'fxjs/es';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';

export const legacyCalcPriceKeyring = ({
  base_products_bp_option_groups,
  selected_option_group,
  cv_objs,
}) => {
  const cv_obj = go(
    cv_objs,
    find((cv_obj) => cv_obj.cid == 'c2323'),
  );

  if (!cv_obj) return;
  const price = go(
    base_products_bp_option_groups,
    flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
    reject((bp_option) => bp_option.is_affect_price),
    filter(({ id }) => selected_option_group.bp_option_ids.includes(id)),
    map((bp_option) => {
      return go(bp_option, pick(['price', 'price_en', 'price_jp']), mapObject(parseFloat));
    }),
    reduce((mem, selected_option) => {
      console.log(selected_option.price);
      return {
        price: PriceS.add(mem.price, selected_option.price),
        price_en: PriceS.add(mem.price_en, selected_option.price_en),
        price_jp: PriceS.add(mem.price_jp, selected_option.price_jp),
      };
    }),
  );
  extend(cv_obj._data, price);
};

export const legacyCalcPriceKeyringAsync = async (
  { base_products_bp_option_groups, selected_option_group, cv_objs },
  { bp_option_id_for_db_data, getOptionApi },
) => {
  const cv_obj = go(
    cv_objs,
    find((cv_obj) => cv_obj.cid == 'c2323'),
  );
  if (!cv_obj) return;
  const price = await go(
    base_products_bp_option_groups,
    flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
    reject((bp_option) => bp_option.is_affect_price),
    filter(({ id }) => selected_option_group.bp_option_ids.includes(id)),
    map(async (bp_option) => {
      if (bp_option_id_for_db_data && bp_option_id_for_db_data == bp_option.id) {
        bp_option = await getOptionApi(bp_option.id);
      }
      return go(bp_option, pick(['price', 'price_en', 'price_jp']), mapObject(parseFloat));
    }),
    reduce((mem, selected_option) => {
      return {
        price: PriceS.add(mem.price, selected_option.price),
        price_en: PriceS.add(mem.price_en, selected_option.price_en),
        price_jp: PriceS.add(mem.price_jp, selected_option.price_jp),
      };
    }),
  );
  extend(cv_obj._data, price);
};
