import { UtilArrayS } from '../../../Util/Array/S/Function/module/UtilArrayS.js';
import { go, map, filter, groupBy, flatMap, compact, find, head, each, pick } from 'fxjs/es';
import {
  getCurrentBpcfInMaker,
  getCurrentBpId,
  getProductFaces2InMaker,
  isMakerContext,
} from '../../../Maker/F/getSth.js';
import { UtilObjS } from '../../../Util/Object/S/Function/module/UtilObjS.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { BpOptionConstantS } from '../../S/Constant/module/BpOptionConstantS.js';
import { $el, $qs, $replaceWith } from 'fxdom/es';
import { BpOptionS } from '../../S/Function/module/BpOptionS.js';
import { OMPDosuConstantS } from '../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { BpOptionF } from './module/BpOptionF.js';

let current_bp_id_cached = null;
let current_bp_id_for_face_cached = null;
let current_bp_id_for_option_ids_for_cached = null;
let current_bpf_id_cached = null;

let biz_option_groups = null;
let biz_option_groups_by_face_id_cached = null;
let biz_option_ids_cached = null;
let biz_current_face_dosu_color_hex_codes_cached = null;
let biz_current_face_dosu_color_quantized_hex_codes_cached = null;

export const biz = {
  get: {
    currentFace: {
      /* 현재 면에 비즈와 관련된 옵션 그룹들 */
      optionsGroups: (dev_names, bpf_id) => {
        const option_groups_by_face_id = biz.get.currentBp.bpOptionGroupsByFaceId();
        const face_options_groups = option_groups_by_face_id[bpf_id || makerHelper.get.currentFaceId()];

        if (UtilArrayS.isArrayOk(face_options_groups)) {
          return dev_names
            ? face_options_groups.filter((g) => dev_names.includes(g.dev_name))
            : face_options_groups;
        }
        return [];
      },
      /* 도수 색상 옵션 그룹 */
      dosuColorOptionGroup: () => {
        return biz.get.currentFace.optionsGroups([BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COLORS])[0];
      },
      /* hex code 를 가진 dosu color option 찾기 */
      dosuColorOption: (color_hex_code) => {
        return go(
          biz.get.currentFace.dosuColorOptions(),
          find((co) => {
            const { hex_code } = biz.get.parseColorFromDosuColorOption(co);
            try {
              return hex_code.toUpperCase() === color_hex_code?.toUpperCase();
            } catch (err) {
              console.error(err);
            }
          }),
        );
      },
      defaultDosuColorOption: (bpf_id) => {
        const base_products_bp_option_group = go(
          box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
          filter(
            (base_products_bp_option) =>
              base_products_bp_option._.bp_option_group.dev_name ===
              BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COLORS,
          ),
          filter((base_products_bp_option) => base_products_bp_option.base_product_face_id === bpf_id),
          head,
        );
        return go(
          biz.get.currentFace.dosuColorOptions(),
          find((co) => {
            return co.id === base_products_bp_option_group.default_bp_option_id;
          }),
        );
      },
      /* 도수 색상 옵션들 */
      dosuColorOptions: () => {
        const option_group = biz.get.currentFace.dosuColorOptionGroup();
        if (UtilObjS.isNotEmpty(option_group)) {
          return option_group._.bp_options.filter((o) => o.is_public);
        }
      },
      /* 도수 색상 옵션의 컬러 hex code 값들 */
      dosuColorOptionHexCodes: () => {
        const editing_bpf_id = G.mp.maker.editing_canvas().bpf_id;

        if (
          current_bpf_id_cached !== editing_bpf_id ||
          biz_current_face_dosu_color_hex_codes_cached == null ||
          biz_current_face_dosu_color_quantized_hex_codes_cached == null
        ) {
          const getOptionDosuColorHexCodes = () => {
            const color_options = biz.get.currentFace.dosuColorOptions();

            if (UtilArrayS.isArrayOk(color_options)) {
              return go(
                color_options,
                map((co) => co.dev_name),
                filter((hex_code) => hex_code && hex_code.startsWith('#')),
                map((hex) => hex.split('**')[0]),
                map((hex) => hex.toUpperCase()), // 별색인 경우 / 뒤에 별색 이름이 올 수 있음
              );
            } else {
              return [];
            }
          };
          biz_current_face_dosu_color_hex_codes_cached = getOptionDosuColorHexCodes();
          biz_current_face_dosu_color_quantized_hex_codes_cached = go(
            biz_current_face_dosu_color_hex_codes_cached,
            map((hex_code) => OMPDosuF.analysis.getQuantizedHexColor({ hex_code })),
          );

          current_bpf_id_cached = editing_bpf_id;
          return {
            color_codes: biz_current_face_dosu_color_hex_codes_cached,
            quantized_color_codes: biz_current_face_dosu_color_quantized_hex_codes_cached,
          };
        }

        return {
          color_codes: biz_current_face_dosu_color_hex_codes_cached,
          quantized_color_codes: biz_current_face_dosu_color_quantized_hex_codes_cached,
        };
      },
      /* 현재 면이 1도 색상 옵션인지 여부 */
      isSingleDosuColorOption: () => {
        return biz.get.currentFace.maxDosuCount() === 1;
      },
      /* 도수 수량 옵션 그룹 */
      dosuCountOptionGroup: (bpf_id) => {
        return biz.get.currentFace.optionsGroups(
          [BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COUNTS],
          bpf_id,
        )[0];
      },
      /* 도수 수량 옵션들 */
      dosuCountOptions: (bpf_id) => {
        const option_group = biz.get.currentFace.dosuCountOptionGroup(bpf_id);
        if (UtilObjS.isNotEmpty(option_group)) {
          return option_group._.bp_options.filter((o) => o.is_public);
        }
      },
      dosuCountOptionNumbers: (bpf_id) => {
        const options = biz.get.currentFace.dosuCountOptions(bpf_id);

        if (UtilArrayS.isArrayOk(options)) {
          return go(
            options,
            map((o) => {
              const count = parseInt(o.dev_name);
              return Number.isNaN(count) ? null : count;
            }),
            compact,
          );
        }
      },
      /* 현재 면에 허용된 도수 최대 수량 (도수 수량 옵션들 중 최대값) */
      maxDosuCount: (bpf_id) => {
        const counts = biz.get.currentFace.dosuCountOptionNumbers(bpf_id);
        if (counts != null) {
          return Math.max(...counts);
        }
      },
      /* 제한 도수 색상 */
      hasDosuColorOptions: () => {
        return (
          isMakerContext() &&
          UtilArrayS.isArrayOk(
            biz.get.currentFace.optionsGroups([BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COLORS]),
          )
        );
      },
      /* 제한 도수 수량 */
      hasDosuCountOptions: () => {
        return (
          isMakerContext() &&
          UtilArrayS.isArrayOk(
            biz.get.currentFace.optionsGroups([BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COUNTS]),
          )
        );
      },
      hasDosuRelatedOptions: () => {
        return (
          isMakerContext() &&
          UtilArrayS.isArrayOk(
            biz.get.currentFace.optionsGroups([
              BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COLORS,
              BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COUNTS,
            ]),
          )
        );
      },
    },
    currentBp: {
      // 자동으로 도수 관련된 어떤 옵션을 사용했는지 판단하는 로직
      usedDosuRelatedOptions: () => {
        const used_dosu_colors_by_face_id = OMPDosuF.cvObj.get.usedDosuColorsByFaceId();

        return go(
          biz.get.currentBp.bpOptionGroups(),
          flatMap((g) => {
            const used_color_hex_codes_at_face = used_dosu_colors_by_face_id[g.base_product_face_id];
            if (UtilArrayS.isArrayOk(used_color_hex_codes_at_face)) {
              switch (g.dev_name) {
                // 사용된 도수 컬러 hex code 에 해당하는 bp dosu color 옵션 찾기
                case BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS: {
                  const bp_dosu_color_options = g._.bp_options;
                  return go(
                    bp_dosu_color_options.filter((color_option) => {
                      const { hex_code } = biz.get.parseColorFromDosuColorOption(color_option); // 별색 대응
                      return used_color_hex_codes_at_face.includes(hex_code);
                    }),
                    map((option) => ({
                      ...option,
                      bpf_id: g.base_product_face_id,
                    })),
                  );
                }
                // 사용된 도수 컬러 수량에 해당하는 bp dosu count 옵션 찾기
                case BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS: {
                  const bp_dosu_count_options = g._.bp_options;
                  return go(
                    bp_dosu_count_options.filter(
                      (count_option) =>
                        Number(count_option.dev_name) === Number(used_color_hex_codes_at_face.length),
                    ),
                    map((option) => ({
                      ...option,
                      bpf_id: g.base_product_face_id,
                    })),
                  );
                }
              }
            }
          }),
          compact,
        );
      },
      hasDosuRelatedOptions: () => {
        return BpOptionS.hasBpDosuRelatedOptions({ bp_option_groups: biz.get.currentBp.bpOptionGroups() });
      },
      bpOptionGroups: () => {
        if (!window?.box || !box()?.maker?.product_color) return null;

        const current_bp_id = getCurrentBpId();

        // 캐시되어 있는 bp id 와 다르면 biz option groups 캐시 데이터 (biz_option_groups_by_face_id_cached) 를 갱신
        if (current_bp_id_cached == null || current_bp_id_cached !== current_bp_id) {
          biz_option_groups = BpOptionS.convertBpBpogToBizBpog({
            base_products_bp_option_groups:
              box().maker.product_color._.base_product._.base_products_bp_option_groups,
          });
          current_bp_id_cached = current_bp_id;
        }

        return biz_option_groups;
      },
      bpOptionGroupsForDosuCounts: () => {
        return go(
          biz.get.currentBp.bpOptionGroups(),
          filter((g) => g.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS),
        );
      },
      bpOptionIds: () => {
        const current_bp_id = getCurrentBpId();

        if (
          current_bp_id_for_option_ids_for_cached == null ||
          current_bp_id_for_option_ids_for_cached !== current_bp_id
        ) {
          const biz_bp_option_groups = biz.get.currentBp.bpOptionGroups();

          biz_option_ids_cached = BpOptionS.getBizOptionIds({ biz_bp_option_groups });
          current_bp_id_for_option_ids_for_cached = current_bp_id;
        }

        return biz_option_ids_cached;
      },
      bpOptionGroupsByFaceId: () => {
        const current_bp_id = getCurrentBpId();

        // 캐시되어 있는 bp id 와 다르면 biz option groups 캐시 데이터 (biz_option_groups_by_face_id_cached) 를 갱신
        if (current_bp_id_for_face_cached == null || current_bp_id_for_face_cached !== current_bp_id) {
          biz_option_groups_by_face_id_cached = go(
            biz.get.currentBp.bpOptionGroups(),
            groupBy((g) => g.base_product_face_id),
          );
          current_bp_id_for_face_cached = current_bp_id;
        }

        return biz_option_groups_by_face_id_cached;
      },
    },
    parseColorFromDosuColorOption: (option) => {
      const [hex_code, spot_color_name] = option.dev_name.split('**');

      return { hex_code: hex_code.toUpperCase(), spot_color_name };
    },
  },
  convert: {
    dosuColorOptionToTextPressColor: (o) => {
      const { id, name, name_en, name_jp, no } = o;
      return {
        code: biz.get.parseColorFromDosuColorOption(o).hex_code,
        id,
        name,
        name_en,
        name_jp,
        no,
        only_flex: false,
      };
    },
    dosuColorOptionToTextFontData: ({ color_code }) => {
      const dosu_color_option = BpOptionF.biz.get.currentFace.dosuColorOption(color_code);

      if (UtilObjS.isEmNil(dosu_color_option)) {
        return {
          press_color_id: null,
          press_color_code: color_code,
          press_color_name: color_code,
          press_color_name_en: color_code,
          press_color_name_jp: color_code,
          [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]: color_code,
          only_flex: false,
        };
      }

      const { id, name, dev_name, name_en, name_jp } = dosu_color_option;

      return {
        press_color_id: id,
        press_color_code: dev_name,
        press_color_name: name,
        press_color_name_en: name_en,
        press_color_name_jp: name_jp,
        [OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]: dev_name,
        only_flex: false,
      };
    },
  },
};

export const handleSelectedOptionGroup = {
  get: {
    group: () => {
      return box().maker.product_color._?.selected_option_group;
    },
    optionIds: () => {
      return handleSelectedOptionGroup.get.group()?.bp_option_ids;
    },
    optionsIdsForBiz: () => {
      const biz_bp_option_ids = biz.get.currentBp.bpOptionIds();
      if (UtilArrayS.isEmNil(biz_bp_option_ids)) return;
      return go(
        handleSelectedOptionGroup.get.optionIds(),
        filter((o) => biz_bp_option_ids.includes(o)),
      );
    },
  },
  update: {
    // 박스의 selected_option_group 데이터를 biz 옵션 중 사용된 것을 자동으로 판단하여 교체
    boxData: () => {
      // 비즈 도수와 관련된 선택 옵션을 자동으로 판단
      const selected_dosu_options = biz.get.currentBp.usedDosuRelatedOptions();
      go(
        box().maker.product_color.product_faces2.value,
        each((pf) => {
          const selected_bp_options = go(
            selected_dosu_options,
            filter((option) => pf.bpf_id === option.bpf_id),
            map(pick(['bp_option_group_id', 'dev_name', 'id', 'name'])),
          );
          pf.selected_bp_options = selected_bp_options;
        }),
      );

      // if (UtilObjS.isEmNil(selected_option_group)) {
      //   box().maker.product_color._.selected_option_group = {
      //     bp_option_ids: selected_biz_bp_option_ids,
      //     title: '',
      //     title_en: '',
      //     title_jp: '',
      //   };
      // } else {
      //   const exist_bp_option_ids = box().maker.product_color._.selected_option_group.bp_option_ids;
      //   if (UtilArrayS.isEmNil(exist_bp_option_ids)) {
      //     box().maker.product_color._.selected_option_group.bp_option_ids = selected_biz_bp_option_ids;
      //   } else {
      //     box().maker.product_color._.selected_option_group.bp_option_ids = [
      //       ...filter((id) => !biz_bp_option_ids.includes(id), exist_bp_option_ids),
      //       ...selected_biz_bp_option_ids,
      //     ];
      //   }
      // }

      // setSelectedOptionGroupBpOptions(box().maker.product_color._.selected_option_group);
    },
  },
};

export const makerHelper = {
  get: {
    isEngraveForCurrentEditingCanvas: () => {
      const preview = G.mp.maker.editing_canvas()?.preview;
      if (preview != null && !preview?.shade?.is_black_and_white_contrast) {
        const shade = preview.shade;
        return {
          is_engrave: !!shade,
          engrave_hex_code: shade?.hex,
        };
      }

      return {
        is_engrave: false,
        engrave_hex_code: undefined,
      };
    },
    allCvObjsCurrentFace: () => {
      return BpOptionS.makerHelper.get.unGroupDesigns(G.mp.maker.designs());
    },
    currentFaceId: () => {
      const bpcf = getCurrentBpcfInMaker();
      return bpcf?.base_product_face_id;
    },
    currentPf: () => {
      const pf2 = box().maker.product_color.product_faces2;
      const editing_bpf_id = G.mp.maker.editing_canvas().bpf_id;
      // 디자인이 없는 경우 현재 면의 pf 가 없을 수도 있음.
      return pf2.value.find(({ bpf_id }) => editing_bpf_id === bpf_id);
    },
    currentFaceDesigns: () => {
      const current_pf = makerHelper.get.currentPf();
      if (current_pf == null) return [];
      return go(
        current_pf.designs,
        filter((cv_obj) => cv_obj.visible),
        BpOptionS.makerHelper.get.unGroupDesigns,
        filter((design) => {
          const cv_type = design._data.cv_type;
          return (
            design.visible &&
            (cv_type === 'cv_image' ||
              cv_type === 'cv_text_image' ||
              cv_type === 'cv_pattern' ||
              cv_type === 'cv_text_image_pattern')
          );
        }),
      );
    },
    allFacesDesigns: () => {
      return go(
        getProductFaces2InMaker(),
        filter((cv_obj) => cv_obj.visible),
        BpOptionS.makerHelper.get.unGroupDesigns,
        flatMap((pf) => pf.designs),
        filter((design) => {
          const cv_type = design._data.cv_type;
          return (
            design.visible &&
            (cv_type === 'cv_image' ||
              cv_type === 'cv_text_image' ||
              cv_type === 'cv_pattern' ||
              cv_type === 'cv_text_image_pattern')
          );
        }),
      );
    },
  },
};

export const bizDosuCard = {
  update: () => {
    const el = $qs('.dosu_option_cards');
    if (el == null) return;

    $replaceWith($el(bizDosuCard.get.html()), el);
  },
  get: {
    html: () => {
      if (!biz.get.currentBp.hasDosuRelatedOptions()) return;
      const pf2 = getProductFaces2InMaker();
      if (UtilArrayS.isEmNil(pf2)) return;
      if (pf2.every((pf) => UtilArrayS.isEmNil(pf.designs))) return;

      return BpOptionS.getBizDosuOptionCardHtml({
        pf2,
        base_products_bp_option_groups:
          box.sel('maker->product_color')._.base_product._.base_products_bp_option_groups,
      });
    },
  },
};
