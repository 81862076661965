import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NewMakerBaseProductsEtcS } from '../../NewMaker/BaseProducts/Etc/S/Function/module/NewMakerBaseProductsEtcS.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';

export const makeFakeMpMakerSize = function () {
  if (!UtilF.isLegacyMobile()) return;
  const temp_padding_height = 30;
  const temp_padding_width = 50;

  if (!box.sel('product_detail_other_height')) {
    const option_el =
      $1('#for_detail_change .product_color') ||
      $1('#for_detail_change .svg_editor_mobile_product_thumbnail_wrapper');
    const product_color_height = Math.min($.innerHeight(option_el), 230);
    const footer_buttons_height = $.height($1('.footer_buttons'));
    const header_height = NewMakerUtilF.getMobileHeaderHeight();
    box.set('product_detail_other_height_header', header_height);
    box.set('product_detail_other_height', product_color_height + header_height + footer_buttons_height);
  }

  const fake_mp_maker_width = G.mp.maker.window_width - temp_padding_width * 2;

  let fake_mp_maker_inner_height = G.mp.maker.window_height - box.sel('product_detail_other_height');

  if (fake_mp_maker_inner_height > G.mp.maker.window_width)
    fake_mp_maker_inner_height = G.mp.maker.window_width;

  const fake_mp_maker_height = fake_mp_maker_inner_height - 10 - temp_padding_height * 2;

  let scale;
  if (G.mp.maker.bpcf_size.height >= G.mp.maker.bpcf_size.width) {
    scale = fake_mp_maker_height / G.mp.maker.bpcf_size.height;
    if (scale * G.mp.maker.bpcf_size.width > fake_mp_maker_width) {
      scale = fake_mp_maker_width / G.mp.maker.bpcf_size.width;
    }
  } else {
    scale = fake_mp_maker_width / G.mp.maker.bpcf_size.width;
    if (scale * G.mp.maker.bpcf_size.height > fake_mp_maker_height) {
      scale = fake_mp_maker_height / G.mp.maker.bpcf_size.height;
    }
  }
  $.css($1('.fake_mp_maker'), {
    height: fake_mp_maker_inner_height,
  });
  if (box.sel('maker->product_color->_->base_product->maker_type') === VectorEditorConstantS.KEYRING_EDITOR) {
    scale = Math.min(1, scale);
  }
  const cached_scale =
    NewMakerBaseProductsEtcS.BP_VIEW_SIZE[box.sel('maker->product_color->_->base_product->id')];
  G.mp.maker.fake_mp_maker_size = {
    scale_for_canvas: cached_scale?.mobile_detail_scale ? cached_scale.mobile_detail_scale : scale,
    inner_height: fake_mp_maker_inner_height,
    header_height: box.sel('product_detail_other_height_header'),
  };
};
