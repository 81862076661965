import { find, go } from 'fxjs/es';
import { makeOutlineCanvas, sourceOverCanvas } from '../../../../../Canvas/S/util.js';

export async function makeEmbroideryPreview({ canvas, product_face, base_product_size_id }) {
  const sf = go(
    product_face.size_faces,
    find((sf) => sf.base_product_size_id === base_product_size_id),
  );
  const deep_step = 0.08 * sf.px_per_1cm * (canvas.width / sf.print.px.width);
  return sourceOverCanvas(canvas, makeOutlineCanvas(canvas, deep_step));
}
