import { go, html, strMap, zipWithIndexL } from 'fxjs/es';
import { MShopUtilS } from '../../../Util/S/Function/module/MShopUtilS.js';
import { MShopCoreConstantS } from '../Constant/module/MShopCoreConstantS.js';
import { MShopCoreTmplS } from './module/MShopCoreTmplS.js';

/**
 * @typedef option
 * @property {string} name
 * @property {string | number} value
 * @property {string} selected_name 셀렉 되었을 때 보여질 이름
 * @property {[]} [sub_options]
 */

/**
 *
 * @param {option} [selected_option]
 * @param {option[]} options
 * @param {string} [name]
 * @param {string} [klass]
 * @param {string} [title]
 * @param {'left' | 'right'} direction
 * @param {string} [variant] 스타일 변경할 때 사용할 분기용 옵션, class--{variant} 로 생성되고 css 에 스타일 넣기
 * @param {boolean} [need_highlight]
 * @return {string}
 */
export const dropdown = ({
  selected_option,
  options,
  name,
  klass,
  direction = 'right',
  title,
  variant,
  need_highlight,
}) => {
  const component = 'mshop-comp-dropdown';

  return html`
    <div
      class="${component} ${component}--${variant} ${klass}"
      data-name="${name}"
      data-open="false"
      data-selected="${!!selected_option}"
      data-title="${title}"
      data-highlight="${need_highlight}"
    >
      <label class="${component}__button" tabindex="0">
        <input type="checkbox" />
        <span class="${component}__button-text"
          >${selected_option?.selected_name || selected_option?.name || title}</span
        >
        ${MShopCoreTmplS.arrowIcon({
          is_up: false,
          klass: `${component}__button-icon`,
          color: MShopCoreConstantS.colors.black,
        })}
      </label>
      <div class="${component}__menu ${component}__menu--${direction}">
        ${go(
          options,
          zipWithIndexL,
          strMap(([idx, option]) => {
            const has_sub = option.sub_options?.length;
            const sub_html = MShopUtilS.returnIf(
              has_sub,
              html`
                <div data-idx="${idx}" data-open="false" class="${component}__option-sub-container">
                  ${strMap(
                    (sub_sort) => html`
                      <button
                        data-name="${sub_sort.name}"
                        data-value="${sub_sort.value}"
                        data-selected_name="${sub_sort.selected_name}"
                        class="${component}__option ${component}__option--sub"
                        data-selected="${!selected_option ? false : selected_option.value === sub_sort.value}"
                      >
                        ${sub_sort.name}
                      </button>
                    `,
                    option.sub_options,
                  )}
                </div>
              `,
            );

            return html`
              <button
                data-name="${option.name}"
                data-value="${option.value}"
                data-selected_name="${option.selected_name}"
                class="${component}__option ${MShopUtilS.returnIf(has_sub, `${component}__option--group`)}"
                data-idx="${idx}"
                data-selected="${!selected_option ? false : selected_option.value === option.value}"
                tabindex="${idx + 1}"
              >
                ${option.name}
                ${MShopUtilS.returnIf(
                  has_sub,
                  MShopCoreTmplS.arrowIcon({ is_up: false, klass: `${component}__option-icon` }),
                )}
              </button>
              ${sub_html}
            `;
          }),
        )}
      </div>
    </div>
  `;
};
