import { CommonNS } from '@marpple/sticker-editor';
import axios from 'axios';
import {
  each,
  eachC,
  filterL,
  go,
  isNil,
  join,
  mapL,
  reduce,
  rejectL,
  takeAll,
  takeAllC,
  noop,
} from 'fxjs/es';
import { VectorEditorAcrylicFigureFreeMobileF } from '../../Free/Mobile/F/Function/module/VectorEditorAcrylicFigureFreeMobileF.js';
import { VectorEditorFontF } from '../../../Font/F/Function/module/VectorEditorFontF.js';
import { SVGEditorFontConstS } from '../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';
import { beforeLoginCheck } from '../../../../User/Login/F/fs.js';
import { SVGEditorUtilF } from '../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { VectorEditorAcrylicFigureFreePCF } from '../../Free/PC/F/Function/module/VectorEditorAcrylicFigureFreePCF.js';
import { VectorEditorAcrylicFigureFreeCreatorPCF } from '../../Free/CreatorPC/F/Function/module/VectorEditorAcrylicFigureFreeCreatorPCF.js';

const PNG_DPI = 300;
const SVG_DPI = 72;
const INCH_PER_MM = 5 / 127;
const CUTTING_LINE_SVG_LAYER_ID = '4-Laser';

export const makeAcrylicFigureMobile = async ({ svg_file, options, postProcess }) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);

  const {
    free_meta_width,
    free_meta_height,
    free_meta_bounding_shape_path_data,
    empty_template_el,
    free_meta_stand_leg_ground_size_delta,
    free_meta_stand_leg_ground_position_delta,
    free_meta_stand_leg_ground_min_width,
    free_meta_stand_leg_ground_max_width,
    free_meta_stand_leg_ground_width,
    free_meta_stand_leg_ground_height,
    free_meta_stand_leg_notch_round,
    free_meta_stand_leg_notch_width,
    free_meta_stand_leg_notch_height,
  } = selectFreeAcrylicFigureDataFromMeta(meta);
  return VectorEditorAcrylicFigureFreeMobileF.makeAcrylicFigure({
    art_board: {
      width: free_meta_width,
      height: free_meta_height,
      shape_path_data: free_meta_bounding_shape_path_data,
    },
    empty_template_el,
    stand_leg: {
      ground: {
        size_delta: free_meta_stand_leg_ground_size_delta,
        position_delta: free_meta_stand_leg_ground_position_delta,
        min_width: free_meta_stand_leg_ground_min_width,
        max_width: free_meta_stand_leg_ground_max_width,
        width: free_meta_stand_leg_ground_width,
        height: free_meta_stand_leg_ground_height,
      },
      notch: {
        round: free_meta_stand_leg_notch_round,
        width: free_meta_stand_leg_notch_width,
        height: free_meta_stand_leg_notch_height,
      },
    },
    preProcess: makePreProcess({ svg_el }),
    postProcess: makePostProcess({
      postProcess,
      printable_scale_factor: INCH_PER_MM * PNG_DPI,
      cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
      cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
      selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
      selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
      selectViewBox: (payload) => payload?.view_box ?? null,
      selectStandLegPositionRatio: (payload) => payload?.stand_leg_position_ratio ?? null,
    }),
  });
};

export const makeAcrylicFigurePC = async ({
  title,
  price,
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_right_panel_el,
  onFrameHiding,
  svg_file,
  options,
  postProcess,
}) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);

  const {
    free_meta_width,
    free_meta_height,
    free_meta_bounding_shape_path_data,
    empty_template_el,
    free_meta_stand_leg_ground_size_delta,
    free_meta_stand_leg_ground_position_delta,
    free_meta_stand_leg_ground_min_width,
    free_meta_stand_leg_ground_max_width,
    free_meta_stand_leg_ground_width,
    free_meta_stand_leg_ground_height,
    free_meta_stand_leg_notch_round,
    free_meta_stand_leg_notch_width,
    free_meta_stand_leg_notch_height,
  } = selectFreeAcrylicFigureDataFromMeta(meta);
  return VectorEditorAcrylicFigureFreePCF.makeAcrylicFigure({
    title,
    price,
    frame_position: { top: frame_position_top, height: frame_position_height },
    prev_right_panel_el,
    onFrameHiding,
    art_board: {
      width: free_meta_width,
      height: free_meta_height,
      shape_path_data: free_meta_bounding_shape_path_data,
    },
    empty_template_el,
    stand_leg: {
      ground: {
        size_delta: free_meta_stand_leg_ground_size_delta,
        position_delta: free_meta_stand_leg_ground_position_delta,
        min_width: free_meta_stand_leg_ground_min_width,
        max_width: free_meta_stand_leg_ground_max_width,
        width: free_meta_stand_leg_ground_width,
        height: free_meta_stand_leg_ground_height,
      },
      notch: {
        round: free_meta_stand_leg_notch_round,
        width: free_meta_stand_leg_notch_width,
        height: free_meta_stand_leg_notch_height,
      },
    },
    preProcess: makePreProcess({ svg_el }),
    postProcess: makePostProcess({
      postProcess,
      printable_scale_factor: INCH_PER_MM * PNG_DPI,
      cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
      cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
      selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
      selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
      selectViewBox: (payload) => payload?.view_box ?? null,
      selectStandLegPositionRatio: (payload) => payload?.stand_leg_position_ratio ?? null,
    }),
  });
};

export const makeAcrylicFigureCreatorPC = async ({ title, price, svg_file, options, postProcess }) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);

  const {
    free_meta_width,
    free_meta_height,
    free_meta_bounding_shape_path_data,
    empty_template_el,
    free_meta_stand_leg_ground_size_delta,
    free_meta_stand_leg_ground_position_delta,
    free_meta_stand_leg_ground_min_width,
    free_meta_stand_leg_ground_max_width,
    free_meta_stand_leg_ground_width,
    free_meta_stand_leg_ground_height,
    free_meta_stand_leg_notch_round,
    free_meta_stand_leg_notch_width,
    free_meta_stand_leg_notch_height,
  } = selectFreeAcrylicFigureDataFromMeta(meta);
  return VectorEditorAcrylicFigureFreeCreatorPCF.makeAcrylicFigure({
    title,
    price,
    art_board: {
      width: free_meta_width,
      height: free_meta_height,
      shape_path_data: free_meta_bounding_shape_path_data,
    },
    empty_template_el,
    stand_leg: {
      ground: {
        size_delta: free_meta_stand_leg_ground_size_delta,
        position_delta: free_meta_stand_leg_ground_position_delta,
        min_width: free_meta_stand_leg_ground_min_width,
        max_width: free_meta_stand_leg_ground_max_width,
        width: free_meta_stand_leg_ground_width,
        height: free_meta_stand_leg_ground_height,
      },
      notch: {
        round: free_meta_stand_leg_notch_round,
        width: free_meta_stand_leg_notch_width,
        height: free_meta_stand_leg_notch_height,
      },
    },
    preProcess: makePreProcess({ svg_el }),
    postProcess: makePostProcess({
      postProcess,
      printable_scale_factor: INCH_PER_MM * PNG_DPI,
      cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
      cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
      selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
      selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
      selectViewBox: (payload) => payload?.view_box ?? null,
      selectStandLegPositionRatio: (payload) => payload?.stand_leg_position_ratio ?? null,
    }),
  });
};

function makePreProcess({ svg_el }) {
  return async function preProcess({ addEl }) {
    if (svg_el == null) {
      return;
    }

    const working_layer_el = svg_el.querySelector(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`);
    if (working_layer_el == null) {
      const error = new Error(
        `이전에 작업한 SVG 파일에서 working-layer 역할을 가진 엘리먼트를 찾을 수 없습니다.`,
      );
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::message::invalid_prev_acrylic_figure_svg',
      );
      throw error;
    }

    const image_els = working_layer_el.querySelectorAll(`image[data-mpse__original_url]`);
    if (image_els.length > 0) {
      await eachC(async (image_el) => {
        const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
        image_el.setAttributeNS(null, 'href', data_url);
      })(image_els);
    }

    await loadUsedFonts(working_layer_el);

    go(
      working_layer_el.children,
      takeAll,
      each((el) => addEl({ el })),
    );
  };
}

function makePostProcess({
  postProcess: outerPostProcess,
  printable_scale_factor,
  cutting_line_scale_factor,
  cutting_line_svg_layer_id,
  selectMakeTargetImageEl,
  selectCuttingLineSVGEl,
  selectViewBox,
  selectStandLegPositionRatio,
}) {
  return async function postProcess(payload) {
    const is_login = await beforeLoginCheck(true);
    if (!is_login) {
      return { success: false, value: T('modules::VectorEditor::AcrylicFigure::message::login_first') };
    }

    const loaderDone = SVGEditorUtilF.percentLoader({
      message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
      time: 10 * 1000,
      clazz: 'mobile',
    });
    try {
      const { makeTargetImageEl, cutting_line_svg_el, view_box, stand_leg_position_ratio } = (() => {
        const makeTargetImageEl = selectMakeTargetImageEl(payload);
        if (makeTargetImageEl == null) {
          throw new Error(`Cannot select "makeTargetImageEl" from payload.`);
        }

        const cutting_line_svg_el = selectCuttingLineSVGEl(payload);
        if (cutting_line_svg_el == null) {
          throw new Error(`Cannot select "cutting_line_svg_el" from payload.`);
        }

        const view_box = selectViewBox(payload);
        if (view_box == null) {
          throw new Error(`Cannot select "view_box" from payload.`);
        }

        const stand_leg_position_ratio = selectStandLegPositionRatio(payload);
        if (stand_leg_position_ratio == null) {
          throw new Error(`Cannot select "stand_leg_position_ratio" from payload.`);
        }

        return {
          makeTargetImageEl,
          cutting_line_svg_el,
          view_box,
          stand_leg_position_ratio,
        };
      })();

      const original_svg_el = await makeTargetImageEl({ factor: 1 });
      const printable_svg_el = await makeTargetImageEl({ factor: printable_scale_factor });

      cutting_line_svg_el.setAttributeNS(null, 'id', cutting_line_svg_layer_id);
      const cutting_line_svg_width = Math.ceil(view_box.width * cutting_line_scale_factor * 1000) / 1000;
      cutting_line_svg_el.setAttributeNS(null, 'width', `${cutting_line_svg_width}`);
      const cutting_line_svg_height = Math.ceil(view_box.height * cutting_line_scale_factor * 1000) / 1000;
      cutting_line_svg_el.setAttributeNS(null, 'height', `${cutting_line_svg_height}`);

      const xml_serializer = new XMLSerializer();
      const original_svg_file = await (async (original_svg_el) => {
        each((image_el) => {
          const original_url = image_el.dataset.mpse__original_url;
          image_el.setAttributeNS(null, 'href', original_url);
        })(original_svg_el.querySelectorAll('image[data-mpse__original_url]'));

        const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: false })(
          original_svg_el,
        );
        const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
        font_style_defs_el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE] = 'font-face-defs';
        font_style_defs_el.appendChild(font_style_el);
        original_svg_el.prepend(font_style_defs_el);

        return new File([xml_serializer.serializeToString(original_svg_el)], 'acrylic_figure_original.svg', {
          type: 'image/svg+xml',
        });
      })(original_svg_el);
      const cutting_line_svg_file = new File(
        [xml_serializer.serializeToString(cutting_line_svg_el)],
        'acrylic_figure_cutting_line.svg',
        { type: 'image/svg+xml' },
      );
      const makePrintablePNGFile = async () => {
        const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(
          original_svg_el,
        );
        const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
        font_style_defs_el.appendChild(font_style_el);
        printable_svg_el.prepend(font_style_defs_el);

        const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(`printable_svg_el 을 data_url 로 변환하는 데 실패했습니다.`);
            error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::too_large_image');
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(printable_svg_el)], { type: 'image/svg+xml' }),
          );
        });
        const img_el = await new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener(
            'error',
            (event) => {
              console.error(event);
              const error = new Error(`printable_svg_el 의 data_url 을 img_el 에 그리는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::too_large_image');
              reject(error);
            },
            { once: true },
          );
          img_el.addEventListener('load', () => resolve(img_el), { once: true });
          img_el.src = data_url;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));

        const canvas_el = document.createElement('canvas');
        canvas_el.width = img_el.width;
        canvas_el.height = img_el.height;
        const canvas_ctx = canvas_el.getContext('2d');
        canvas_ctx.drawImage(
          img_el,
          0,
          0,
          img_el.width,
          img_el.height,
          0,
          0,
          canvas_el.width,
          canvas_el.height,
        );

        const scaled_view_box_x = Math.floor(view_box.x * printable_scale_factor);
        const scaled_view_box_y = Math.floor(view_box.y * printable_scale_factor);
        const scaled_view_box_width = Math.ceil(view_box.width * printable_scale_factor);
        const scaled_view_box_height = Math.ceil(view_box.height * printable_scale_factor);

        const src_x = Math.max(0, scaled_view_box_x);
        const src_y = Math.max(0, scaled_view_box_y);
        let src_width = scaled_view_box_width + scaled_view_box_x - src_x;
        src_width = Math.min(src_width, canvas_el.width - src_x);
        let src_height = scaled_view_box_height + scaled_view_box_y - src_y;
        src_height = Math.min(src_height, canvas_el.height - src_y);

        const dest_x = src_x - scaled_view_box_x;
        const dest_y = src_y - scaled_view_box_y;
        const dest_width = Math.min(src_width, scaled_view_box_width - dest_x);
        const dest_height = Math.min(src_height, scaled_view_box_height - dest_y);

        const png_canvas_el = document.createElement('canvas');
        png_canvas_el.width = scaled_view_box_width;
        png_canvas_el.height = scaled_view_box_height;
        const png_canvas_ctx = png_canvas_el.getContext('2d');
        png_canvas_ctx.drawImage(
          canvas_el,
          src_x,
          src_y,
          src_width,
          src_height,
          dest_x,
          dest_y,
          dest_width,
          dest_height,
        );

        const blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
          png_canvas_el.toBlob((blob) => {
            if (blob == null) {
              const error = new Error(`png_canvas_el 에서 blob 객체를 만드는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::too_large_image');
              reject(error);
              return;
            }
            resolve(blob);
          }, 'image/png'),
        );
        return new File([blob], 'printable.png', { type: 'image/png' });
      };

      await outerPostProcess({
        original_svg_file,
        cutting_line_svg_file,
        makePrintablePNGFile,
        stand_leg_position_ratio,
      });

      return { success: true, value: null };
    } catch (error) {
      console.error(error);
      const message =
        error.__mp_alert_message ?? T('modules::VectorEditor::AcrylicFigure::message::output_making_error');
      return { success: false, value: message };
    } finally {
      await loaderDone().catch(noop);
    }
  };
}

async function makeImageDataURL(url) {
  const [url_without_qs, qs] = url.split(`?`);
  const converted_url = go(
    qs ?? `canvas=marpple-sticker-editor-v3`,
    (qs) => qs.split(`&`),
    mapL((a) => a.split(`=`)),
    mapL(([a, b]) => {
      if (decodeURIComponent(a) === 'canvas') {
        return [a, 'marpple-sticker-editor-v3'];
      }
      return [a, b];
    }),
    mapL(mapL(encodeURIComponent)),
    mapL(join('=')),
    join('&'),
    (qs) => `${url_without_qs}?${qs}`,
  );
  const blob = /** @type {Blob} */ await axios
    .get(converted_url, { responseType: 'blob' })
    .then(({ data }) => data);
  return /** @type {Promise<string>} */ new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      const error = new Error(`이미지의 blob 데이터를 읽는 데 실패했습니다.`);
      error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::load_image_error');
      reject(error);
    });
    file_reader.readAsDataURL(blob);
  });
}

async function loadUsedFonts(el) {
  const used_fonts = await VectorEditorFontF.getAllUsedFonts(el);
  if (used_fonts.size > 0) {
    await go(
      SVGEditorFontConstS.FONTS,
      filterL(({ fontFamily }) => used_fonts.has(fontFamily)),
      mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
        new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
      ),
      takeAllC,
    );
  }
}

async function parseSVGFile(svg_file) {
  if (svg_file == null) {
    return null;
  }

  const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
    $.don_loader_start();
    try {
      const file_reader = new FileReader();
      file_reader.addEventListener('load', () => resolve(file_reader.result));
      file_reader.addEventListener('error', reject);
      file_reader.readAsText(svg_file);
    } catch (error) {
      reject(error);
    } finally {
      $.don_loader_end();
    }
  });
  const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
  const parser_error_el = svg_doc.querySelector('parsererror');
  if (parser_error_el != null) {
    const error = new Error(parser_error_el.innerText);
    error.__mp_alert_message = T(
      'modules::VectorEditor::AcrylicFigure::message::invalid_prev_acrylic_figure_svg',
    );
    throw error;
  }
  const svg_el = svg_doc.querySelector('svg');
  if (svg_el == null) {
    const error = new Error('이전에 작업한 키링 SVG 파일에서 svg 엘리먼트를 찾을 수 없습니다.');
    error.__mp_alert_message = T(
      'modules::VectorEditor::AcrylicFigure::message::invalid_prev_acrylic_figure_svg',
    );
    throw error;
  }

  return svg_el;
}

function makeMetaFromOptions(options) {
  return go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );
}

function selectFreeAcrylicFigureDataFromMeta(meta) {
  const { free_meta } = meta;

  if (free_meta == null) {
    const error = new Error(`메타정보 객체에 free_meta 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }

  const {
    width: free_meta_width,
    height: free_meta_height,
    bounding_shape_path_data: free_meta_bounding_shape_path_data,
    stand_leg: {
      ground: {
        size_delta: free_meta_stand_leg_ground_size_delta,
        position_delta: free_meta_stand_leg_ground_position_delta,
        min_width: free_meta_stand_leg_ground_min_width,
        max_width: free_meta_stand_leg_ground_max_width,
        width: free_meta_stand_leg_ground_width,
        height: free_meta_stand_leg_ground_height,
      },
      notch: {
        round: free_meta_stand_leg_notch_round,
        width: free_meta_stand_leg_notch_width,
        height: free_meta_stand_leg_notch_height,
      },
    },
  } = free_meta;

  if (Number.isNaN(free_meta_width) || !Number.isFinite(free_meta_width) || free_meta_width <= 0) {
    const error = new Error(`메타정보 객체에 free_meta.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (Number.isNaN(free_meta_height) || !Number.isFinite(free_meta_height) || free_meta_height <= 0) {
    const error = new Error(`메타정보 객체에 free_meta.height 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_width) ||
    !Number.isFinite(free_meta_stand_leg_ground_width) ||
    free_meta_stand_leg_ground_width <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_height) ||
    !Number.isFinite(free_meta_stand_leg_ground_height) ||
    free_meta_stand_leg_ground_height <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.height 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_size_delta) ||
    !Number.isFinite(free_meta_stand_leg_ground_size_delta) ||
    free_meta_stand_leg_ground_size_delta <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.size_delta 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_position_delta) ||
    !Number.isFinite(free_meta_stand_leg_ground_position_delta) ||
    free_meta_stand_leg_ground_position_delta <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.position_delta 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_min_width) ||
    !Number.isFinite(free_meta_stand_leg_ground_min_width) ||
    free_meta_stand_leg_ground_min_width <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.min_width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_ground_max_width) ||
    !Number.isFinite(free_meta_stand_leg_ground_max_width) ||
    free_meta_stand_leg_ground_max_width <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.max_width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (free_meta_stand_leg_ground_width < free_meta_stand_leg_ground_min_width) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (free_meta_stand_leg_ground_width > free_meta_stand_leg_ground_max_width) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.ground.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_notch_round) ||
    !Number.isFinite(free_meta_stand_leg_notch_round) ||
    free_meta_stand_leg_notch_round <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.notch.round 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_notch_width) ||
    !Number.isFinite(free_meta_stand_leg_notch_width) ||
    free_meta_stand_leg_notch_width <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.notch.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }
  if (
    Number.isNaN(free_meta_stand_leg_notch_height) ||
    !Number.isFinite(free_meta_stand_leg_notch_height) ||
    free_meta_stand_leg_notch_height <= 0
  ) {
    const error = new Error(`메타정보 객체에 free_meta.stand_leg.notch.height 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }

  if (free_meta_bounding_shape_path_data == null) {
    const error = new Error(`메타정보 객체에 free_meta.bounding_shape_path_data 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::AcrylicFigure::message::invalid_meta_data');
    throw error;
  }

  /**
   * @todo
   * 나중에 free_meta 에 있는 S3 리소스 주소를 통해
   * 동적으로 SVG 리소스 다운받기
   */
  const empty_template_el = (() => {
    let font_size = Math.max(1, Math.round((Math.min(free_meta_width, free_meta_height) / 50) * 3));
    if (T.lang === 'jp') {
      font_size = Math.ceil((font_size / 3) * 2 * 1000) / 1000;
    }
    const svg_str = `
      <svg xmlns="http://www.w3.org/2000/svg">
        <g class="root">
          <text
            style="user-select: none;"
            x="${free_meta_width / 2}"
            y="${free_meta_height / 2}"
            font-family="AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, 'Droid Sans', 'Roboto', 'Segoe UI', 'Helvetica', Arial, sans-serif" 
            font-size="${font_size}"
            font-weight="normal"
            font-stretch="normal"
            font-style="normal"
            fill="${G.collabo_type === '' ? '#FF6B00' : '#000000'}"
            text-anchor="middle"
            alignment-baseline="middle"
            letter-spacing="0"
          >
            ${T('modules::VectorEditor::AcrylicFigure::template::empty_template_guide')}
          </text> 
        </g>
      </svg> 
    `;
    const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
    const el = svg_doc.querySelector('.root');
    el.removeAttributeNS(null, 'class');
    return el;
  })();

  return {
    free_meta_width,
    free_meta_height,
    free_meta_bounding_shape_path_data,
    empty_template_el,
    free_meta_stand_leg_ground_size_delta,
    free_meta_stand_leg_ground_position_delta,
    free_meta_stand_leg_ground_min_width,
    free_meta_stand_leg_ground_max_width,
    free_meta_stand_leg_ground_width,
    free_meta_stand_leg_ground_height,
    free_meta_stand_leg_notch_round,
    free_meta_stand_leg_notch_width,
    free_meta_stand_leg_notch_height,
  };
}
