import axios from 'axios';
import { each, find, flatMap, go, mapC, pluck, sel, unique } from 'fxjs/es';
import { makeOnlyDesignFaceCanvas } from '../../../../Maker/F/draw_product_faces.js';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { render_canvas } from '../../../../Composite/Core/S/composite_template_canvas.js';

export const makeCompositePreview = async ({ assoc_composite_template, product }) => {
  const print_area_canvass = await go(
    assoc_composite_template._.composite_masks,
    flatMap((cm) => cm._.composite_faces),
    pluck('base_product_face_id'),
    unique,
    mapC(async (base_product_face_id) => {
      return {
        base_product_face_id,
        canvas: await makeOnlyDesignFaceCanvas(
          go(
            product.product_faces2.value,
            find((pf) => pf.bpf_id === base_product_face_id),
          ),
          product.base_product_size_id,
          { width: 900 },
        ),
      };
    }),
    mapC(async ({ canvas, base_product_face_id }) => {
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob(resolve);
      });
      const file = new File([blob], 'temp_file.' + blob.type.replace(/image\//, ''), {
        type: blob.type,
        lastModified: new Date(),
      });
      const response = await axios
        .postForm(`/${T.lang}/@api/prerequisite_maker/temp_printable_image_files`, {
          files: [file],
        })
        .catch((error) => {
          $.alert(error?.response?.data?.message || '문제가 발생 했습니다.');
        });
      if (!response) return;
      return {
        base_product_face_id,
        url: response.data.files[0].url,
      };
    }),
  );
  if (!print_area_canvass?.length) return $.alert('문제가 발생 했습니다.');
  await go(
    assoc_composite_template._.composite_masks,
    each((composite_mask) =>
      go(
        composite_mask._.composite_faces,
        each(async (cf) => {
          cf.mockup_url = go(
            print_area_canvass,
            find((pf) => pf.base_product_face_id === cf.base_product_face_id),
            sel('url'),
          );
        }),
      ),
    ),
  );
  assoc_composite_template._.composite_result.design_ready_url = await go(
    axios.post(
      `/${T.lang}/@fileUpload/composite/create_design_ready_url_buffer`,
      {
        assoc_composite_template,
      },
      { responseType: 'arraybuffer' },
    ),
    sel('data'),
    (buffer) => new Uint8Array(buffer),
    function (buffer) {
      const blob = new Blob([buffer], { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    },
  );
  const canvas_el = document.createElement('canvas');
  const design_ready_canvas =
    assoc_composite_template._.composite_result.design_ready_url &&
    (await makeCanvasByUrl(assoc_composite_template._.composite_result.design_ready_url));
  canvas_el.width = 900;
  await render_canvas({
    assoc_composite_template,
    selected_base_product_colors: [
      {
        base_product_id: product.base_product_id,
        id: product.base_product_color_id,
      },
    ],
    design_ready_canvas,
    canvas_el,
  });
  return canvas_el;
};
