import { go, map } from 'fxjs/es';
import { $css, $find, $qs, $qsa, $setCss } from 'fxdom/es';

export function setIScroll(el) {
  if (!el) return;
  const marpplizer_height = go($qs('#marpplizer'), $.height);
  const margin_top = parseInt($css('marginTop', el));
  const bottom = go(
    $qs('#marpplizer .general_option_buttons'),
    (el) => $.height(el) + parseInt($css('bottom', el)),
  );
  // if ($qs('#maker_frame')?.dataset?.is_vector === 'true') {
  //   $setCss({ height: $height($qs('#maker_frame')) - 120 - 32 - 16 }, el);
  // } else {
  // }
  $setCss({ height: marpplizer_height - bottom - margin_top - 20 }, el);
  const buying_top = go(
    $qsa('#marpplizer .general_option_buttons'),
    map((buying) => $.offset(buying).top),
    (arr) => Math.max(...arr),
  );
  const body = go(el, $find('>.body'));
  const body_bottom = $.offset(body).top + $.height(body);
  if (buying_top >= body_bottom) return el;
  if (el.myScroll) el.myScroll.refresh();
  else
    el.myScroll = new IScroll(el, {
      mouseWheel: true,
      scrollbars: true,
      fadeScrollbars: true,
    });
  return el;
}

export const clickHandlerForReviewSummaryCount = () => {
  const review_el = $qs('.omp-review');
  if (review_el) {
    const el_position = review_el.getBoundingClientRect().top;
    window.scroll({
      top: window.scrollY + el_position - 50, // 상단 고정 header 고려
      behavior: 'smooth',
    });
  }
};
