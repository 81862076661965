import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';

export const errorHandlingEventWrapper = (eventFunc) => {
  return async (e) => {
    try {
      await eventFunc(e);
    } catch (err) {
      $.don_loader_end();
      if (err.__mp_message) return;
      ConfigSentryF.makerEventCapture(err, 'makerEvent');
    }
  };
};
