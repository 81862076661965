import { html } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppKeywordAutoCompleteTmplS } from '../../AutoComplete/S/Tmpl/module/MShopAppKeywordAutoCompleteTmplS.js';

export const searchContainer = ({ q, placeholder, input_id, is_mobile, container_id, is_app }) => {
  const is_query_empty = !!q.length;
  const SEARCH_BTN_VISIBLE_CLASS = is_query_empty ? '' : 'keyword-container__btn--show';
  const RESET_BTN_VISIBLE_CLASS = is_query_empty ? 'keyword-container__btn--show' : '';
  const MOBILE_CLASS = is_mobile ? 'is_mobile' : '';
  const APP_CLASS = is_app ? 'is_app' : '';
  const escaped_q = UtilS.escape(q);
  return html`
    <div id="${container_id}" class="keyword-container ${MOBILE_CLASS} ${APP_CLASS}">
      <form class="keyword-container__form ${APP_CLASS}">
        <input
          type="submit"
          class="keyword-container__btn keyword-container__btn_search ${SEARCH_BTN_VISIBLE_CLASS}"
          value=""
          alt="Search"
        />
        ${is_mobile
          ? html`
              <button
                class="keyword-container__btn keyword-container__btn_backward ${RESET_BTN_VISIBLE_CLASS}"
              ></button>
            `
          : ''}
        <input
          type="text"
          id="${input_id}"
          class="keyword-container__input_el"
          placeholder="${placeholder}"
          value="${escaped_q}"
          data-last-q="${escaped_q}"
          autocomplete="off"
          spellcheck="false"
        />
        <button
          class="keyword-container__btn keyword-container__btn_reset ${RESET_BTN_VISIBLE_CLASS}"
        ></button>
      </form>
      ${MShopAppKeywordAutoCompleteTmplS.autoCompleteShellTmpl()}
    </div>
  `;
};
