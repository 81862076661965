import axios from 'axios';
import { go, join, map } from 'fxjs/es';

// TODO @kjj cancel token 처리
export const getAutoCompleteList = async (query, source) => {
  // if (!props || !props.hasOwnProperty('cancel')) throw new Error('props is required.');
  if (query.length < 2) return [];
  return axios
    .get(`/${T.lang}/@api/keywords/autocomplete?q=${query}`, {
      cancelToken: source.token,
    })
    .then(({ data }) => data)
    .catch((e) => {
      if (e.code === 'ERR_CANCELED') return [];
      console.error(e);
      // TODO @kjj 에러 핸들링
    });
};

export const escapeRegExp = (str) => {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const getPatternWithSpaces = (input_value) => {
  const input = (input_value || '').replace(' ', '');
  if (input.length <= 1) {
    return input;
  }
  const WHITESPACE_ZERO_OR_ONE = '\\s?';

  return go(
    input,
    map((str) => escapeRegExp(str) + WHITESPACE_ZERO_OR_ONE),
    join(''),
    (str) => str.replace(/\\s\?$/i, ''),
    (str) => new RegExp(str, 'i'),
  );
};
