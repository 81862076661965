import { VectorEditorAcrylicFigureStandLegPCMuiS } from '../../S/Mui/module/VectorEditorAcrylicFigureStandLegPCMuiS.js';
import { $find, $findAll, $on, $setCss } from 'fxdom/es';
import { PCRightFixedPanelF } from '../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { each, go, tap } from 'fxjs/es';
import { VectorEditorAcrylicFigureStandLegPCF } from '../Function/module/VectorEditorAcrylicFigureStandLegPCF.js';
import acrylStandFactory from '@marpple/acryl-stand';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorAcrylicFigureStandLegPCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_frame_position_top = tab_el.__mp_frame_position_top ?? 0;
    tab_el.__mp_frame_position_height = tab_el.__mp_frame_position_height ?? 0;
    tab_el.__mp_prev_right_panel_el = tab_el.__mp_prev_right_panel_el ?? null;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_art_board_shape_path_data = tab_el.__mp_art_board_shape_path_data ?? `M 0 0 Z`;
    tab_el.__mp_ucs_x = tab_el.__mp_ucs_x ?? 0;
    tab_el.__mp_ucs_y = tab_el.__mp_ucs_y ?? 0;
    tab_el.__mp_ucs_width = tab_el.__mp_ucs_width ?? 0;
    tab_el.__mp_ucs_height = tab_el.__mp_ucs_height ?? 0;
    tab_el.__mp_stand_leg_ground_size_delta = tab_el.__mp_stand_leg_ground_size_delta ?? 4;
    tab_el.__mp_stand_leg_ground_position_delta = tab_el.__mp_stand_leg_ground_position_delta ?? 1;
    tab_el.__mp_stand_leg_ground_min_width = tab_el.__mp_stand_leg_ground_min_width ?? 0;
    tab_el.__mp_stand_leg_ground_max_width = tab_el.__mp_stand_leg_ground_max_width ?? 0;
    tab_el.__mp_stand_leg_ground_width = tab_el.__mp_stand_leg_ground_width ?? 0;
    tab_el.__mp_stand_leg_ground_height = tab_el.__mp_stand_leg_ground_height ?? 0;
    tab_el.__mp_stand_leg_notch_round = tab_el.__mp_stand_leg_notch_round ?? 1;
    tab_el.__mp_stand_leg_notch_width = tab_el.__mp_stand_leg_notch_width ?? 0;
    tab_el.__mp_stand_leg_notch_height = tab_el.__mp_stand_leg_notch_height ?? 0;
    tab_el.__mp_makeTargetImageEl = tab_el.__mp_makeTargetImageEl ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_acrylic_figure_stand_leg_editor = null;
    tab_el.__mp_is_stand_leg_moving = false;
    tab_el.__mp_acryl_stand = null;

    go(
      tab_el,

      tap($on('mousemove', VectorEditorAcrylicFigureStandLegPCF.handleTabElMouseMove({ tab_el }))),
      tap($on('mouseup', VectorEditorAcrylicFigureStandLegPCF.handleTabElMouseUp({ tab_el }))),
      tap($on('mouseleave', VectorEditorAcrylicFigureStandLegPCF.handleTabElMouseLeave({ tab_el }))),

      tap(
        $findAll(`.editor_container`),
        each(
          $on('mousedown', VectorEditorAcrylicFigureStandLegPCF.handleEditorContainerMouseDown({ tab_el })),
        ),
      ),

      tap(
        $findAll(`.right_container .wrapper_footer .cancel`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightCancelButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_footer .done`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightDoneButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_body_item_size .wrapper_body_item_control_wrapper_minus`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightMinusButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_body_item_size .wrapper_body_item_control_wrapper_plus`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightPlusButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_body_item_position .wrapper_body_item_control_wrapper_left`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightLeftButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_body_item_position .wrapper_body_item_control_wrapper_right`),
        each($on('click', VectorEditorAcrylicFigureStandLegPCF.handleRightRightButtonClick({ tab_el }))),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const acrylic_figure_stand_leg_editor =
      new EditorNS.AcrylicFigureStandLegEditorNS.PCNS.AcrylicFigureStandLegPCEditor({
        window,
        document,
      });
    tab_el.__mp_acrylic_figure_stand_leg_editor = acrylic_figure_stand_leg_editor;
    acrylic_figure_stand_leg_editor.initDOM();
    acrylic_figure_stand_leg_editor.initEditor();
    acrylic_figure_stand_leg_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_width,
      height: tab_el.__mp_art_board_height,
    });
    acrylic_figure_stand_leg_editor.setArtBoardShapePath({
      path_data: tab_el.__mp_art_board_shape_path_data,
    });

    const editor_container_el = $find(`.editor_container`)(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::StandLeg::PC::error_message::no_editor_container_el',
      );
      throw error;
    }
    acrylic_figure_stand_leg_editor.appendTo(editor_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el) {
    $setCss(['top', `${tab_el.__mp_frame_position_top}px`])(tab_el);
    $setCss(['height', `${tab_el.__mp_frame_position_height}px`])(tab_el);

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      const error = new Error(`tab_el.__mp_acrylic_figure_stand_leg_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::StandLeg::PC::error_message::no_acrylic_figure_stand_leg_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_makeTargetImageEl == null) {
      const error = new Error(`tab_el.__mp_makeTargetImageEl 함수가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::StandLeg::PC::error_message::no_make_target_image_el',
      );
      throw error;
    }
    const target_image_el = await tab_el.__mp_makeTargetImageEl();
    editor.setTargetImageEl(target_image_el);

    editor.fitSize();

    const art_board_shape_path_el = editor.getArtBoardShapePathEl();
    const art_board_shape_path_el_bbox = art_board_shape_path_el.getBBox();
    tab_el.__mp_ucs_x = art_board_shape_path_el_bbox.x;
    tab_el.__mp_ucs_y = art_board_shape_path_el_bbox.y;
    tab_el.__mp_ucs_width = art_board_shape_path_el_bbox.width;
    tab_el.__mp_ucs_height = art_board_shape_path_el_bbox.height;
    const view_box_vo = editor.calculateViewBoxFitTo({
      size_in_ucs: {
        x: tab_el.__mp_ucs_x - 5,
        y: tab_el.__mp_ucs_y - 5,
        width: tab_el.__mp_ucs_width + 10,
        height: tab_el.__mp_ucs_height + 10,
      },
      padding_in_vcs: { top: 100, right: 200, bottom: 100, left: 200 },
      vertical_align: 'center',
    });
    editor.setViewBox(view_box_vo);

    const result1 = acrylStandFactory({
      svg_artboard_rect: {
        x: 0,
        y: 0,
        width: tab_el.__mp_art_board_width,
        height: tab_el.__mp_art_board_height,
      },
      pathdata: tab_el.__mp_art_board_shape_path_data,
      size: {
        ground: { width: tab_el.__mp_stand_leg_ground_width, height: tab_el.__mp_stand_leg_ground_height },
        notch: {
          width: tab_el.__mp_stand_leg_notch_width,
          height: tab_el.__mp_stand_leg_notch_height,
          round: tab_el.__mp_stand_leg_notch_round,
        },
      },
      initial_position: `CENTER`,
    });
    if (!result1.ok) {
      const code = result1.value.code;
      const message = result1.value.message;
      throw new Error(`${code} - ${message}`);
    }
    const acryl_stand = result1.value;
    tab_el.__mp_acryl_stand = acryl_stand;

    const result2 = acryl_stand.getStandPathdata({});
    if (!result2.ok) {
      const code = result2.value.code;
      const message = result2.value.message;
      throw new Error(`${code} - ${message}`);
    }
    const stand_path_data = result2.value;

    editor.setStandLegPath({ path_data: stand_path_data });
    VectorEditorAcrylicFigureStandLegPCF.setSizeDisplayValue({ tab_el, size: acryl_stand.getGroundWidth() });

    const foreground_container_el = tab_el.querySelector(`.foreground_container`) ?? null;
    const drag_icon_el =
      foreground_container_el?.querySelector(
        `.reposition_guide_container .reposition_guide_container_drag_icon`,
      ) ?? null;
    const left_arrow_icon_el =
      foreground_container_el?.querySelector(
        `.reposition_guide_container .reposition_guide_container_left_icon`,
      ) ?? null;
    const right_arrow_icon_el =
      foreground_container_el?.querySelector(
        `.reposition_guide_container .reposition_guide_container_right_icon`,
      ) ?? null;
    const result3 = acryl_stand.getGroundMidPoint();
    if (
      foreground_container_el !== null &&
      drag_icon_el !== null &&
      left_arrow_icon_el !== null &&
      right_arrow_icon_el !== null &&
      result3.ok
    ) {
      const { x: stand_leg_x, y: stand_leg_y } = editor.convertCoordsToOutside({
        x: result3.value.x,
        y: result3.value.y,
      });
      const { y: origin_y } = editor.convertCoordsToOutside({ x: 0, y: 0 });
      const { y: notch_height_y } = editor.convertCoordsToOutside({
        x: 0,
        y: tab_el.__mp_stand_leg_notch_height,
      });
      const notch_height = notch_height_y - origin_y;

      const { x: foreground_container_x, y: foreground_container_y } =
        foreground_container_el.getBoundingClientRect();

      const center_x = stand_leg_x - foreground_container_x;
      const center_y = stand_leg_y + notch_height - foreground_container_y;

      const { width: drag_icon_width, height: drag_icon_height } = drag_icon_el.getBoundingClientRect();
      const drag_icon_x = center_x - drag_icon_width / 2;
      const drag_icon_y = center_y + drag_icon_height / 2 + 20;
      drag_icon_el.style.left = `${drag_icon_x}px`;
      drag_icon_el.style.top = `${drag_icon_y}px`;

      const { width: left_arrow_icon_width, height: left_arrow_icon_height } =
        left_arrow_icon_el.getBoundingClientRect();
      const left_arrow_icon_x = center_x - left_arrow_icon_width - 30;
      const left_arrow_icon_y = drag_icon_y + drag_icon_height / 2 - left_arrow_icon_height / 2;
      left_arrow_icon_el.style.left = `${left_arrow_icon_x}px`;
      left_arrow_icon_el.style.top = `${left_arrow_icon_y}px`;

      const { height: right_arrow_icon_height } = right_arrow_icon_el.getBoundingClientRect();
      const right_arrow_icon_x = center_x + 30;
      const right_arrow_icon_y = drag_icon_y + drag_icon_height / 2 - right_arrow_icon_height / 2;
      right_arrow_icon_el.style.left = `${right_arrow_icon_x}px`;
      right_arrow_icon_el.style.top = `${right_arrow_icon_y}px`;
    }
    (() => {
      if (foreground_container_el === null) {
        return;
      }
      const foreground_container_rect = foreground_container_el.getBoundingClientRect();

      const size_guide_container_el = foreground_container_el.querySelector(`.size_guide_container`) ?? null;
      if (size_guide_container_el === null) {
        return;
      }

      const size_guide_container_value_el =
        size_guide_container_el.querySelector(`.size_guide_container_value`) ?? null;
      if (size_guide_container_value_el === null) {
        return;
      }

      const size_guide_container_value_rect = size_guide_container_value_el.getBoundingClientRect();

      const result4 = acryl_stand.getUnitedPathdata({});
      if (!result4.ok) {
        return null;
      }

      const united_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, `path`);
      united_path_el.setAttributeNS(null, `d`, result4.value);
      united_path_el.setAttributeNS(null, `fill`, `#000000`);
      united_path_el.setAttributeNS(null, `stroke`, `none`);
      editor.setForeground(united_path_el);
      const bbox = united_path_el.getBBox();
      united_path_el.remove();
      const size_width = Math.round(bbox.width);
      const size_height = Math.round(bbox.height);
      const size_value = `${size_width} x ${size_height} mm`;

      const top_left_xy = editor.convertCoordsToOutside({ x: tab_el.__mp_ucs_x, y: tab_el.__mp_ucs_y });
      const bottom_right_xy = editor.convertCoordsToOutside({
        x: tab_el.__mp_ucs_x + tab_el.__mp_ucs_width,
        y: tab_el.__mp_ucs_y + tab_el.__mp_ucs_height,
      });
      const width = bottom_right_xy.x - top_left_xy.x;
      const x =
        top_left_xy.x - foreground_container_rect.x + width / 2 - size_guide_container_value_rect.width / 2;
      const y = top_left_xy.y - foreground_container_rect.y - size_guide_container_value_rect.height * 2;
      size_guide_container_value_el.style.left = `${x}px`;
      size_guide_container_value_el.style.top = `${y}px`;
      size_guide_container_value_el.innerHTML = size_value;
      size_guide_container_value_el.style.visibility = `visible`;
    })();

    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.showPcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.hidePcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_acrylic_figure_stand_leg_editor != null) {
      const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;
      editor.destroy();
    }

    tab_el.__mp_frame_position_top = 0;
    tab_el.__mp_frame_position_height = 0;
    tab_el.__mp_prev_right_panel_el = null;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_art_board_shape_path_data = `M 0 0 Z`;
    tab_el.__mp_ucs_x = 0;
    tab_el.__mp_ucs_y = 0;
    tab_el.__mp_ucs_width = 0;
    tab_el.__mp_ucs_height = 0;
    tab_el.__mp_stand_leg_ground_size_delta = 0;
    tab_el.__mp_stand_leg_ground_position_delta = 0;
    tab_el.__mp_stand_leg_ground_min_width = 0;
    tab_el.__mp_stand_leg_ground_max_width = 0;
    tab_el.__mp_stand_leg_ground_width = 0;
    tab_el.__mp_stand_leg_ground_height = 0;
    tab_el.__mp_stand_leg_notch_round = 1;
    tab_el.__mp_stand_leg_notch_width = 0;
    tab_el.__mp_stand_leg_notch_height = 0;
    tab_el.__mp_makeTargetImageEl = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_acrylic_figure_stand_leg_editor = null;
    tab_el.__mp_is_stand_leg_moving = false;
    tab_el.__mp_acryl_stand = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
