import axios from 'axios';
import { $attr, $delegate, $find, $qs, $setCss, $setVal } from 'fxdom/es';
import { go, mapObject, object, pick, tap } from 'fxjs/es';
import { AdScriptFbF } from '../../../../AdScript/Fb/F/Function/module/AdScriptFbF.js';
import { AdScriptGaF } from '../../../../AdScript/Ga/F/Function/module/AdScriptGaF.js';
import { AdScriptKakaoF } from '../../../../AdScript/Kakao/F/Function/module/AdScriptKakaoF.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { PrivacyMainMuiF } from '../../../../Privacy/Main/F/Mui/module/PrivacyMainMuiF.js';
import { PrivacyPopUpTmplS } from '../../../../Privacy/PopUp/S/Tmpl/module/PrivacyPopUpTmplS.js';
import { TermsConditionsMuiF } from '../../../../Terms/Conditions/F/Mui/module/TermsConditionsMuiF.js';
import { TermsConditionsTmplS } from '../../../../Terms/Conditions/S/Tmpl/module/TermsConditionsTmplS.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPAuthFindIDF } from '../../FindID/F/Function/module/OMPAuthFindIDF.js';
import { OMPAuthResetPasswordF } from '../../ResetPassword/F/Function/module/OMPAuthResetPasswordF.js';
import { OMPAuthConstantS } from '../../S/Constant/module/OMPAuthConstantS.js';
import { OMPAuthS } from '../../S/Function/module/OMPAuthS.js';
import { OMPAuthF } from './module/OMPAuthF.js';
import { OMPAuthEmailVerifyF } from '../../EmailVerify/F/Function/module/OMPAuthEmailVerifyF.js';

const fbAppLogin = (e) => {
  try {
    const data = JSON.parse(e.data);
    if (data.type !== 'fb') return;
    return onMessage(data);
  } catch (err) {}
};

const onMessage = (data) => {
  const fb_login_data = pick(['accessToken'], data.authResponse || {});
  if (!fb_login_data.accessToken) return;

  return go(
    axios.post('/@api/fb/login', { accessToken: fb_login_data.accessToken }),
    tap(({ data }) => {
      $.don_loader_end();

      if (data?.msg === 'Success') {
        AdScriptGaF.signUpMpCreator();
        AdScriptFbF.signUp();
        AdScriptKakaoF.signUp();
      }
    }),
    ({ data }) => {
      if (data?.msg === 'Success') {
        OMPAuthF.afterSignIn(data);
        const params = new URLSearchParams(location.search);
        const url = params.get('url');
        if ($qs('html.omp.login')) window.location.replace(url || '/');
        else MuiF.closeFrame({ is_session: true });
      }
    },
  ).catch((e) => {
    return OMPAuthF.catchError(e);
  });
};

export const event = (frame_el) => {
  go(
    frame_el,
    $delegate('submit', '.omp-auth-signup__data', async (e) => {
      e.originalEvent.preventDefault();
      try {
        await go(
          new FormData(e.currentTarget).entries(),
          object,
          mapObject(OMPCoreUtilS.trim),
          OMPAuthS.signupValid,
          async (signup_data) => {
            $.don_loader_start();
            const res = await axios.post(`/${TT.lang}/@api/sign_up`, signup_data);
            $.don_loader_end();
            AdScriptGaF.signUpMpCreator();
            AdScriptFbF.signUp();
            AdScriptKakaoF.signUp();
            OMPAuthF.afterSignIn(res.data.user);

            // MEMO: 상품만들기에서는 안뜨게한다.
            if ($qs('html.product.maker_page')) return false;
            return res.data.user;
          },
          (user) => {
            if (user) {
              return OMPAuthEmailVerifyF.openEmailVerifyPopUp(user, () => {
                // 로그인 페이지 일 때 메인으로 보내기
                if ($qs('html.omp.login')) {
                  const params = new URLSearchParams(location.search);
                  const url = params.get('url');
                  window.location.replace(url || '/');
                }
                // TODO: 팝업 꺼지면 끄는 방법이 있는지 확인하기
              });
            } else {
              MuiF.closeFrame({ is_session: true });
            }
          },
        );
      } catch (e) {
        await OMPAuthF.catchError(e);
      }
    }),
    $delegate('click', '.omp-auth__facebook', async (e) => {
      $.don_loader_start();

      if (!window.ReactNativeWebView) {
        if (!window.FB) {
          $.don_loader_end();
          return OMPCoreUtilF.alert({ content: TT('error::auth::error_35') });
        }

        if (OMPCoreUtilF.isMobile()) {
          $.don_loader_end();
        }

        return FB.login(onMessage, { scope: 'email' });
      } else {
        if (OMPCoreUtilF.isAndroid()) {
          return $.alert(T('mp_app::android_fb_login'));
        }
        window.addEventListener('message', fbAppLogin);
        document.addEventListener('message', fbAppLogin);
        $.don_loader_end();
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            fb_uri: `${T.lang}/fb_login_native`,
          }),
        );
      }
    }),
    $delegate('submit', '.omp-auth-login__data', async (e) => {
      e.originalEvent.preventDefault();
      try {
        await go(
          new FormData(e.currentTarget).entries(),
          object,
          mapObject(OMPCoreUtilS.trim),
          async (login_data) => {
            const valid_email = OMPCoreUtilS.isValidEmail(login_data.email);

            if (!valid_email) {
              throw new OMPAuthConstantS.AuthError(TT('error::auth::error_05'), { id: 'email' });
            }

            if (!login_data.password) {
              throw new OMPAuthConstantS.AuthError(TT('error::auth::error_22'), { id: 'password' });
            }

            $.don_loader_start();
            const res = await axios.post(`/${TT.lang}/@api/login`, login_data);
            $.don_loader_end();

            if (res.data.user_dormant_url) {
              return (window.location.href = res.data.user_dormant_url);
            }

            OMPAuthF.afterSignIn(res.data.user);

            const params = new URLSearchParams(location.search);
            const url = params.get('url');
            if ($qs('html.omp.login')) window.location.replace(url || '/');
            else MuiF.closeFrame({ is_session: true });
          },
        );
      } catch (e) {
        await OMPAuthF.catchError(e);
      }
    }),
    $delegate('click', '.agree_use', (e) => {
      e.originalEvent.preventDefault();
      return MuiF.openFrame(TermsConditionsMuiF.frame, (frame, page, [tab]) => {
        frame.header_height = 0;
        frame.el_class = 'terms_modal_style terms_style';
        frame.hide_frame_button_position = 'RIGHT';
        frame.hide_frame_button_type = 'X';
        frame.is_modal = !OMPCoreUtilF.isMobile();
        tab.template = () => TermsConditionsTmplS.termsHtml({ lang: T.lang });
      });
    }),
    $delegate('click', '.agree_privacy', (e) => {
      e.originalEvent.preventDefault();
      return MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
        frame.header_height = 0;
        frame.el_class = 'terms_modal_style terms_style';
        frame.hide_frame_button_position = 'RIGHT';
        frame.hide_frame_button_type = 'X';
        frame.is_modal = !OMPCoreUtilF.isMobile();
        tab.template = () => PrivacyPopUpTmplS.login();
      });
    }),
    $delegate('click', '.agree_email', (e) => {
      e.originalEvent.preventDefault();
      return MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
        frame.header_height = 0;
        frame.el_class = 'terms_modal_style terms_style';
        frame.hide_frame_button_position = 'RIGHT';
        frame.hide_frame_button_type = 'X';
        frame.title = TT('auth::signup::signup_08');
        frame.is_modal = !OMPCoreUtilF.isMobile();
        tab.template = () => PrivacyPopUpTmplS.marketing();
      });
    }),
    $delegate('click', '.omp-auth-login__find-id', (e) => {
      const is_modal = $attr('is_modal', e.delegateTarget) === 'true';
      const don_wrapper = $find('.don_wrapper', e.delegateTarget);

      const closed = (_, user) => {
        if (!OMPCoreUtilF.isMobile() && is_modal) {
          $setCss({ opacity: 1 }, don_wrapper);
        }

        if (user?.email) {
          $setVal(user.email, $find('input[name="email"]', don_wrapper));
          $find('input[name="password"]', don_wrapper)?.focus();
        } else {
          $find('input[name="email"]', don_wrapper)?.focus();
        }
      };
      if (!OMPCoreUtilF.isMobile() && is_modal) {
        $setCss({ opacity: 0, transition: 'opacity 300ms ease-in-out' }, don_wrapper);
      }

      return OMPAuthFindIDF.openFrame(closed, !is_modal);
    }),
    $delegate('click', '.omp-auth-login__find-pw', (e) => {
      const is_modal = $attr('is_modal', e.delegateTarget) === 'true';
      const don_wrapper = $find('.don_wrapper', e.delegateTarget);

      const closed = () => {
        if (!OMPCoreUtilF.isMobile() && is_modal) {
          $setCss({ opacity: 1 }, don_wrapper);
        }
        $find('input[name="email"]', don_wrapper)?.focus();
      };
      if (!OMPCoreUtilF.isMobile() && is_modal) {
        $setCss({ opacity: 0, transition: 'opacity 300ms ease-in-out' }, don_wrapper);
      }

      return OMPAuthResetPasswordF.openFrame(closed, !is_modal);
    }),
  );
};
