export default {
  common: {
    common_01: 'An error occurred.',
    all: 'Total',
  },
  customer_orders: {
    pick_up: 'Pickup',
    possible_pick_up: 'Available for pickup',
  },
  crew: {
    authorization: {
      signup_01: 'Email',
      signup_02: 'Name',
      signup_03: 'Employee number',
      signup_04: 'Agreement to Terms of Use',
      signup_05: 'Personal Information Use Agreement',
      signup_06: 'Agreement to Provide Personal Information to Third Parties',
      signup_07: 'Confirmation of age 14 and over',
      signup_08: '[Optional] Agreement to receive email marketing',
      signup_09: 'View',
      signup_10: 'Log in',
      signup_11: 'Please agree to the terms of use.',
      signup_12: 'Please agree to the collection and use of personal information.',
      signup_13: 'Please agree to the provision of personal information to third parties.',
      signup_14: 'Only those aged 14 and over can sign up.',
      signup_15: 'An existing user already exists. Please check.',
      signup_17: 'Verification has not been completed.',
      signup_00: 'Sign up',
      signup_18: 'Affiliated corporations are not eligible for NBOX services',
    },
  },
  layout: {
    drawer: {
      menu: {
        product: 'Official Product',
        review: 'Review',
        home: 'Home',
      },
    },
    footer1: 'MARPPLE Corporation',
    footer2: 'Customer center',
    footer3: 'Report center',
    footer4: 'Seller application',
    footer5: 'Corporate seller application',
    footer6: 'Privacy Policy',
    footer7: 'Terms of Use',
    footer8:
      'For seller products, MARPPLE Corporation is not a party to the telecommunications sale as an intermediary, and is not responsible for the product transaction or transaction information.',
    footer9: '2023 MARPPLE CORP.',
    footer: {
      address: 'Address',
      address_info: '10th Floor, IS Biz Tower, 75-24 Gasan Digital 1-ro, Geumcheon-gu, Seoul',
      apply_seller: 'Seller application',
      blog: 'Blog',
      ceo: 'Representative',
      ceo_name: 'Park Hye-yoon',
      company_name: 'MARPPLE Corporation',
      company_registration_info: 'Business Registration Number',
      company_registration_number: '105-88-13322',
      customer_center: 'Customer Center',
      customer_center_info: 'Weekdays 10:00 ~ 18:00',
      email: 'Email',
      instagram: 'Instagram',
      marpple_corp: 'MARPPLE Corporation',
      privacy_policy: 'Privacy Policy',
      report_center: 'Report center',
      terms_and_conditions: 'Terms of Use',
      twitter: 'Twitter',
      youtube: 'YouTube',
      nbox: {
        ceo_name: 'Jeongheon Lee',
        address: '7, Pangyo-ro 256beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea',
        phone: 'Phone',
        phone_number: '{{tel}}(On weekdays 10:00 ~ 18:00)',
        company_registration_number: '220-87-17483',
        footer1: 'Nexon Korea',
        footer9: '© NEXON Korea Corporation All Rights Reserved.',
      },
    },
  },
  my_page: {
    my_page_01: 'Hello <b>{{name}}</b> 👋',
    my_account_01: 'Profile Image',
    my_account_02: 'Please upload an image file within 10MB.<br/>(Image upload format: JPG, JPEG, PNG)',
    my_account_03: 'No file present.',
    my_account_04: 'Login is required.',
    my_account_05: 'File was not saved.',
    my_account_06: 'Delete',
    like_product_01: 'Like',
    like_product_02: 'There is no product that you like.',
  },
  order: {
    '배송지 변경': 'Change shipping address',
  },
  checkout: {
    receipt: {
      receipt_01: 'Your order has been completed.',
      receipt_02: "We'll ship it with a special touch.<br/>Please wait with excitement!",
    },
  },
  product: {
    sort: {
      최신순: 'Most Recent',
      '리뷰 많은 순': 'Most Reviewed',
      인기순: 'Most Popular',
      '낮은 가격 순': 'Lowest Price',
      '높은 가격 순': 'Highest Price',
    },
    review: {
      count: 'Product Review',
      score: 'Rating',
    },
  },
  plus_admin: {
    plus_admin_01: 'Theme Selection',
    plus_admin_02: 'Black Theme',
    plus_admin_03: 'White Theme',
    banner_01: 'Subtitle',
    banner_02: 'Supports multiple languages, preview available on the left as you input.',
    banner_03: 'Title',
    plus_admin_04: 'Choose background color',
  },
  home: {
    plus_home: {
      plus_home_01: 'Popular Product',
      plus_home_02: 'Most loved official product',
      plus_home_03: "Grab it before it's too late!",
      plus_home_04: 'The merchandise you need to meet right now!',
      plus_home_05: "When you're curious about buyers' reviews",
      plus_home_06: "Today's Review",
      channel_on: 'NBOX Channel on',
      nbox_hot_item: 'The NBOX Hot Item to Watch Right Now',
    },
  },
  community: {
    input: 'Input',
    comment_place_holder: 'Leave a comment',
    community_01: 'No changes made.',
  },
  cart: {
    title: 'Shopping Cart',
  },
  product_detail: {
    tab01: 'Story',
    tab02: 'Product Info',
    tab03: 'Purchase/Shipping',
    tab04: 'Reviews',
    review: {
      empty_title: 'There are no reviews yet.',
      empty_text: 'Be the first to write a review!',
    },
    nbox: {
      pick_title: 'Nexon Pickup',
      pick_desc1: 'Free',
      pick_desc2: 'Available for Nexon Pickup',
    },
    purchase_delivery: {
      pd01: 'If you order now, it will be shipped between <span style="color: {{color}};">{{start}}</span> and <span style="color: {{color}};">{{end}}</span>.',
      pd02: 'If you order custom-made products from Mapleshop together, they will be shipped according to the shipping date of the custom-made product.',
      pd03: 'All products shipped by Mapleshop are made to order, so exchanges/refunds due to simple change of mind are not possible.',
    },
  },
  review: {
    comment_header: 'Please leave a review for the product',
  },
  limit_product: {
    limit_product_01: 'Limited Edition',
  },
};
