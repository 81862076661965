import axios from 'axios';
import { $addClass, $delegate, $find, $on, $removeClass, $setVal, $val } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppKeywordAutoCompleteF } from '../../AutoComplete/F/Function/module/MShopAppKeywordAutoCompleteF.js';
import { MShopAppKeywordS } from '../../S/Function/module/MShopAppKeywordS.js';

export const delegateKeywordContainer = (container_el) => {
  const $input = $find('.keyword-container__input_el', container_el);
  const $reset_btn = $find('.keyword-container__btn_reset', container_el);
  const $search_btn = $find('.keyword-container__btn_search', container_el);

  const showResetAndHideSearch = () => {
    $addClass('keyword-container__btn--show', $reset_btn);
    if (MShopUtilF.isMobile()) {
      if (location.pathname === `/${T.lang}/@/keywords`) {
        return;
      }
      const $backward_btn = $find('.keyword-container__btn_backward', container_el);
      $addClass('keyword-container__btn--show', $backward_btn);
    }
    $removeClass('keyword-container__btn--show', $search_btn);
  };
  const showSearchAndHideReset = () => {
    if (!MShopUtilF.isMobile()) {
      $addClass('keyword-container__btn--show', $search_btn);
    }
    $removeClass('keyword-container__btn--show', $reset_btn);
  };

  if (MShopUtilF.isMobile()) {
    $delegate('click', '.keyword-container__btn_backward', (e) => {
      e.originalEvent.preventDefault();
      location.href = `/${T.lang}/@/keywords`;
    })(container_el);
  }

  if (!MShopUtilF.isMobile()) {
    $on('click', function ({ target }) {
      const is_autocomplete_outer_clicked = !container_el.contains(target);
      if (is_autocomplete_outer_clicked) {
        MShopAppKeywordAutoCompleteF.hideAutoCompleteList();
      }
    })(document);
  }

  return go(
    container_el,
    $delegate('click', '.keyword-container__input_el', () => {
      MShopAppKeywordAutoCompleteF.showAutoCompleteList(container_el);
    }),
    $delegate('input', '.keyword-container__input_el', ({ currentTarget: $input }) => {
      const q = ($input.value || '').trim();
      if (q) {
        showResetAndHideSearch();
      } else {
        showSearchAndHideReset();
      }
    }),
    $delegate('click', '.keyword-container__btn_reset', (e) => {
      e.originalEvent.preventDefault();
      showSearchAndHideReset();
      $setVal('', $input);
      $input.focus();
      MShopAppKeywordAutoCompleteF.setAutoCompleteHtml([], '');
    }),
    $delegate('submit', '.keyword-container__form', async (e) => {
      e.originalEvent.preventDefault();
      const q = go(e.currentTarget, $find('.keyword-container__input_el'), $val, (q) => (q || '').trim());
      if (!q) return;
      await axios.post(`/${T.lang}/@api/keywords/history?q=${q}`);

      const uri = MShopAppKeywordS.getSearchAllUri(q);
      if (MShopUtilF.isApp()) {
        MShopUtilF.postMessage(MShopAppKeywordS.getSearchPostMessage(uri));
      } else {
        location.href = uri;
      }
    }),
  );
};

export const navigateToKeywordsResult = (query, { disable_action_none_app = false } = {}) => {
  const q = (query || '').trim();
  if (!q) {
    return;
  }

  const uri = MShopAppKeywordS.getSearchAllUri(q);
  if (!MShopUtilF.isApp()) {
    if (!disable_action_none_app) {
      location.href = uri;
    }
    return;
  }

  MShopUtilF.postMessage({
    navigate: {
      sel: 'TabNavigation.SearchStack.SearchScreen',
      params: { uri, q },
    },
  });
};
