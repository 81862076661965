import {
  $addClass,
  $attr,
  $closest,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $on,
  $qs,
  $qsa,
  $setAttr,
} from 'fxdom/es';
import { each, filter, go, last } from 'fxjs/es';
import NewHeadroom from 'headroom.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { ComponentsDrawerF } from '../../../Drawer/F/Function/module/ComponentsDrawerF.js';

const is_app = MShopUtilF.isApp();
const is_mobile = $attr('is_mobile', $qs('html')) === 'true';
export const headroom = (el) => {
  const headroom_base_class = 'mshop-default-header__wrapper';
  return new NewHeadroom(el, {
    classes: {
      initial: headroom_base_class,
      pinned: `${headroom_base_class}--pinned`,
      unpinned: `${headroom_base_class}--unpinned`,
      top: `${headroom_base_class}--top`,
      notTop: `${headroom_base_class}--not-top`,
      bottom: `${headroom_base_class}--bottom`,
      notBottom: `${headroom_base_class}--not-bottom`,
      frozen: `${headroom_base_class}--frozen`,
    },
    offset: 200,
  });
};

export const findFocusElement = (el) => {
  return go(
    el,
    $findAll('*'),
    filter(
      (el) =>
        (el.tagName.match(/^A$|AREA|INPUT|TEXTAREA|SELECT|BUTTON/gim) &&
          parseInt(el.getAttribute('tabIndex')) !== -1) ||
        (el.getAttribute('tabIndex') !== null &&
          parseInt(el.getAttribute('tabIndex')) >= 0 &&
          el.getAttribute('tabIndex', 2) !== 32768),
    ),
    (focusEls) => [focusEls[0], last(focusEls)],
  );
};

let newHeadroom = null;

export const getHeaderHeadroom = () => newHeadroom;

// TODO: nbox 용으로 고치기
export const header = (element) => {
  if (is_app) $setAttr(['is_app', 'true'], $qs('html'));

  const component = 'mshop-default-header';

  // wrapper 가 실질적으로 fixed 되어있는 친구
  const header_el = $find(`.${component}__wrapper`, element);
  const drawer$ = $qs('.drawer__inner');
  const mask$ = $qs('.creator-mask');

  if (is_mobile && element.dataset.headroom === 'true') {
    newHeadroom = headroom(header_el);
    newHeadroom.init();
  }

  go(
    header_el,
    $delegate('click', '.mshop-default-header__menu-icon', async () => {
      $addClass('drawer__inner--slide-in', drawer$);
      $.body_fixed(true);
      if (newHeadroom) newHeadroom.freeze();
      else $addClass('creator-mask--is-open', mask$);
    }),
  );

  $on('click', function (e) {
    const drawer_inner$ = $qs('.drawer__inner');

    if (
      drawer_inner$ &&
      $hasClass('drawer__inner--slide-in', drawer_inner$) &&
      !$closest('.mshop-default-header__menu-icon', e.target)
    ) {
      if (!$hasClass('drawer', e.target) && !$qs('.drawer').contains(e.target)) {
        $addClass('drawer__inner--slide-out', drawer_inner$);
      }
    }
  })(document);

  if ($qs('.drawer')) ComponentsDrawerF.init($qs('.drawer'), newHeadroom);
};

export const init = () => {
  each(header, $qsa('.mshop-default-header'));
};
