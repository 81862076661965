import { reverse, filter, each, extend, go, sortByDesc, values } from 'fxjs/es';
import {
  createCanvasElement,
  hexToRGB,
  makeCanvasByUrl,
  makeCanvasByUrlWithResize,
  rgbToHex,
} from '../../../../Canvas/S/util.js';
import { OMPDosuConstantS } from '../../S/Constant/module/OMPDosuConstantS.js';
import Image from 'image-js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

export async function applyColorToImage({ hex_code, img_src, isToDataUrl }) {
  if (hex_code == null || !hex_code.startsWith('#')) {
    throw new Error(`Invalid hexcode`);
  }
  const canvas = await canvasHelper.get.canvasElFromImageUrl({ url: img_src });

  const ctx = canvas.getContext('2d');
  ctx.globalCompositeOperation = 'source-in';

  ctx.fillStyle = hex_code;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.globalCompositeOperation = 'source-over';

  return isToDataUrl ? canvas.toDataURL() : canvas;
}

export const canvasHelper = {
  get: {
    canvasEl: ({ width, height }) => createCanvasElement({ width, height }),
    canvasElFromImageUrl: async ({ url }) => makeCanvasByUrl(url),
    canvasElFromImageUrlWithResize: async ({ url, pixel_amount_limit }) =>
      makeCanvasByUrlWithResize(url, pixel_amount_limit),
    download: ({ canvas, filename }) => {
      const data_url = canvas.toDataURL('image/png'); // 형식을 PNG로 설정할 수 있습니다.
      const a = document.createElement('a');
      a.href = data_url;
      a.download = filename ?? 'untitled.png';
      a.click();
    },
  },
  update: {
    removeEdgePixels: async ({ canvas }) => {
      const original_image = await Image.fromCanvas(canvas);
      const sobel_mask = original_image
        .grey()
        .sobelFilter()
        .mask({ threshold: 0.1, useAlpha: false, invert: true });

      // downloadDataUrl(sobel_mask.toDataURL(), 'edge mask');

      return original_image.extract(sobel_mask).getCanvas();
    },
  },
};

const pixels = {
  get: {
    pixelDataFromCanvas: ({ canvas }) => {
      const ctx = canvas.getContext('2d');
      return ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    },
    pixelDataFromImg: ({ pixel_data, alpha_threshold = 0 }) => {
      const pixel_array = [];

      for (let i = 0; i < pixel_data.length; i += 4) {
        const r = pixel_data[i];
        const g = pixel_data[i + 1];
        const b = pixel_data[i + 2];
        const a = pixel_data[i + 3];

        // mostly opaque
        if (typeof a === 'undefined' || a >= alpha_threshold) {
          pixel_array.push([r, g, b]);
        }
      }

      return pixel_array;
    },
    preMultipliedAlpha: ({ color, alpha }) => {
      return Math.round((color * alpha) / 255);
    },
    euclideanDistance: (rgb1, rgb2) => {
      function distanceSquared(a, b) {
        let sum = 0;
        for (let i = 0; i < a.length; i++) {
          sum += Math.pow(a[i] - b[i], 2);
        }
        return sum;
      }
      return Math.sqrt(distanceSquared(rgb1, rgb2));
    },
  },
};

export const analysis = {
  getQuantizedHexColor: ({ hex_code, quantized_bit = OMPDosuConstantS.QUANTIZED_BIT_FOR_DOSU }) => {
    if (hex_code && hex_code.startsWith('#')) {
      const { r, g, b } = hexToRGB(hex_code);
      return rgbToHex(...[r, g, b].map((c) => (c >> quantized_bit) << quantized_bit));
    }
    return null;
  },
  fromImage: async ({ image_src, options }) => {
    /* 도수 분석 알고리즘
     *  1. 경계 픽셀 제거
     *     - Sobel filter (https://en.wikipedia.org/wiki/Sobel_operator)
     *     - anti-aliasing 으로 인한 색상 경계 blending 픽셀들 제거
     *     - 이미지 중 경계가 차지하는 영역은 무시해도 합리적임
     *  2. 일부 색상 비트 제거, color cube 를 bit 크기만큼 색 공간 양자화
     *     - 육안상으로 비슷한 색상 구별 어려움.
     *     - 균형있게 비슷한 색상끼리를 하나의 sub color cube 공간으로 묶어 버리는 것이 합리적임
     *     - 도수가 제한된 판촉물 인쇄가 컬러 품질에 아주 민감한 요소가 아닌 판단이 합리적임
     *  3. 최대 픽셀 컬러 수 대비 매우 적은 비율의 픽셀은 spot 이라고 생각하고 무시
     * */

    options = extend(
      {
        quantized_bit: OMPDosuConstantS.QUANTIZED_BIT_FOR_DOSU, // (0 ~ 8)
        alpha_threshold: 125, // (0 ~ 255)
        count_threshold: 0.01, // percent
        is_hex_code: false,
        chunk: undefined,
      },
      options,
    );

    const { quantized_bit, alpha_threshold, count_threshold, is_hex_code, chunk } = options;

    const canvas_original = await canvasHelper.get.canvasElFromImageUrlWithResize({
      url: image_src,
      pixel_amount_limit: 1000 ** 2,
    });

    const edge_removed_canvas = await canvasHelper.update.removeEdgePixels({
      canvas: canvas_original,
    });

    const pixel_data = pixels.get.pixelDataFromCanvas({ canvas: edge_removed_canvas });

    const color_counts = {};

    for (let i = 0, r = 0, g = 0, b = 0, a = 0, rgb = { r: 0, g: 0, b: 0 }; i < pixel_data.length; i += 4) {
      r = pixel_data[i] >> quantized_bit;
      g = pixel_data[i + 1] >> quantized_bit;
      b = pixel_data[i + 2] >> quantized_bit;
      a = pixel_data[i + 3];

      if (a < alpha_threshold) continue;

      rgb = { r, g, b };

      const key = `${rgb.r}-${rgb.g}-${rgb.b}`;

      if (color_counts[key]) {
        color_counts[key].count += 1;
      } else {
        const r = rgb.r << quantized_bit;
        const g = rgb.g << quantized_bit;
        const b = rgb.b << quantized_bit;

        color_counts[key] = {
          color: is_hex_code ? rgbToHex(r, g, b) : { r, g, b },
          count: 1,
        };
      }
    }

    if (UtilObjS.isEmNil(color_counts)) {
      // 완전 투명 이미지 -> Black 색상으로 간주
      return [{ color: '#000000', count: 9999 }];
    }

    const sorted_color_counts = go(
      color_counts,
      values,
      sortByDesc((c) => c.count),
    );

    const total_pixel_counts = pixel_data.length / 4;

    const result_colors = sorted_color_counts.filter(
      (c) => c.count > total_pixel_counts * (count_threshold / 100),
    );
    const DIS_LIMIT = 15;

    const rr = go(
      result_colors,
      reverse,
      each((c) => {
        c.near_color = result_colors.find((rc) => {
          if (rc.near_color != null) return false;
          if (rc.color === c.color) return false;
          return pixels.get.euclideanDistance(hexToRgbArr(rc.color), hexToRgbArr(c.color)) < DIS_LIMIT;
        });
      }),
      filter((c) => !c.near_color),
    );

    if (options.chunk) {
      return rr.slice(0, chunk);
    }

    return rr;
  },
};
function hexToRgbArr(hex) {
  return Object.values(hexToRGB(hex));
}
