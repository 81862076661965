import { entries, go, map } from 'fxjs/es';
export const FONTS = [
  {
    fontFamily: 'Gaegu',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/gaegu-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Gaegu',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/gaegu-v5-latin_korean-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/gothic-a1-v7-korean_latin-500.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/gothic-a1-v7-korean_latin-800.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Kirang Haerang',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/kirang-haerang-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Nanum Myeongjo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/nanum-myeongjo-v12-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Nanum Myeongjo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/nanum-myeongjo-v12-latin_korean-800.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Nanum Gothic',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/nanum-gothic-v14-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Nanum Gothic',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/nanum-gothic-v14-latin_korean-800.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Nanum Pen Script',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/nanum-pen-script-v12-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'drfont_daraehand',
    fontSeight: 'normal',
    fontWtyle: 'normal',
    src: [
      {
        url: '/font/maker/new_download/drfont_daraehand.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Do Hyeon',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/do-hyeon-v8-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Dokdo',
    fontStyle: 'normal',
    fontWeight: '400',
    src: [{ url: '/font/maker/ko/dokdo-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'DungGeunMo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/DungGeunMo.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'SDMiSaeng',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/SDMiSaeng.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'BareunBatang',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/BareunBatangOTFM.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'BareunBatang',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [
      {
        url: '/font/maker/new_download/BareunBatangOTFB.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Yeon Sung',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/yeon-sung-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Jua',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/jua-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'BMHANNA',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/BMHANNA.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Noto Sans KR',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/noto-sans-kr-v9-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Noto Sans KR',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/noto-sans-kr-v9-latin_korean-900.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'NotoSerifKR',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/NotoSerifKR.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'NotoSerifKR',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/NotoSerifKR-Bold.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Busan',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/Busan.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Black Han Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/black-han-sans-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Binggrae',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/BinggraeII.woff', format: 'woff' }],
  },
  {
    fontFamily: 'Binggrae',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/BinggraeII-B.woff', format: 'woff' }],
  },
  {
    fontFamily: 'SeoulHangangM',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/SeoulHangangM.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Sunflower',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/sunflower-v6-latin_korean-300.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Sunflower',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/ko/sunflower-v6-latin_korean-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Song Myung',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ko/song-myung-v5-latin_korean-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'SunBatang-Medium',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/SunBatang-Medium.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'SunBatang-Medium',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [
      {
        url: '/font/maker/new_download/SunBatang-Bold.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Arita-buri-SemiBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/Arita-buri-SemiBold.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'yg-jalnan',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/JalnanOTF00.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Jeju Myeongjo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '//fonts.gstatic.com/ea/jejumyeongjo/v3/JejuMyeongjo-Regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Jeju Hallasan',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '//fonts.gstatic.com/ea/jejuhallasan/v3/JejuHallasan-Regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'headline',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/headline.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Hogook',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/new_download/HogookStd.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Hogook',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [
      {
        url: '/font/maker/new_download/HogookBold.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Abril Fatface',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/abril-fatface-v9-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Anton',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/anton-v9-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Architects Daughter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/architects-daughter-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Archivo Black',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/archivo-black-v7-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Baloo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/baloo-v3-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Bangers',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/bangers-v10-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Berkshire Swash',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/berkshire-swash-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Bree Serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/bree-serif-v7-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Cambo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/cambo-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Chonburi',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/chonburi-v2-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Coiny',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/coiny-v3-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Creepster',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/creepster-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Cutive',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/cutive-v9-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Fjalla One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/fjalla-one-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Grand Hotel',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/grand-hotel-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Hammersmith One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/hammersmith-one-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Holtwood One SC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/holtwood-one-sc-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Indie Flower',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/indie-flower-v9-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Kaushan Script',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/kaushan-script-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Knewave',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/knewave-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/lato-v14-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/lato-v14-latin-italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/lato-v14-latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/lato-v14-latin-700italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Limelight',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/limelight-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'New Rocker',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/new-rocker-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Notable',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/notable-v2-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Oleo Script Swash Caps',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/oleo-script-swash-caps-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Oleo Script Swash Caps',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/oleo-script-swash-caps-v5-latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Oswald',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/oswald-v16-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Oswald',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/oswald-v16-latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'PT Serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/pt-serif-v9-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'PT Serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/pt-serif-v9-latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'PT Serif',
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/pt-serif-v9-latin-italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'PT Serif',
    fontStyle: 'italic',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/pt-serif-v9-latin-700italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Pattaya',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/pattaya-v3-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Permanent Marker',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/permanent-marker-v7-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Racing Sans One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/racing-sans-one-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Reem Kufi',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/reem-kufi-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Ribeye Marrow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ribeye-marrow-v7-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Righteous',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/righteous-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/roboto-v19-cyrillic_vietnamese_greek_latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/roboto-v19-cyrillic_vietnamese_greek_latin-italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/roboto-v19-cyrillic_vietnamese_greek_latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/roboto-v19-cyrillic_vietnamese_greek_latin-700italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Rum Raisin',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/rum-raisin-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Sanchez',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/sanchez-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Sanchez',
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/sanchez-v5-latin-italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Shojumaru',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/shojumaru-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Shrikhand',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/shrikhand-v3-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Sofia',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/sofia-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Sonsie One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/sonsie-one-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Source Serif Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/source-serif-pro-v5-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Source Serif Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/source-serif-pro-v5-latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Special Elite',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/special-elite-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Spicy Rice',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/spicy-rice-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Taviraj',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/other/Taviraj/taviraj-v5-thai_latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Taviraj',
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/other/Taviraj/taviraj-v5-thai_latin-italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Taviraj',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/other/Taviraj/taviraj-v5-thai_latin-700.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Taviraj',
    fontStyle: 'italic',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/other/Taviraj/taviraj-v5-thai_latin-700italic.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Telex',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/telex-v6-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Ultra',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/ultra-v10-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Yellowtail',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/yellowtail-v8-latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/jp_ch/noto-sans-jp-v21-japanese_latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [{ url: '/font/maker/jp_ch/noto-sans-jp-v21-japanese_latin-900.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'Noto Sans SC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/jp_ch/noto-sans-sc-v4-chinese-simplified_japanese_latin-regular.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Noto Sans SC',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [
      {
        url: '/font/maker/jp_ch/noto-sans-sc-v4-chinese-simplified_japanese_latin-900.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Noto Sans TC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/jp_ch/noto-sans-tc-v4-chinese-traditional_japanese_latin-regular.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Noto Sans TC',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: [
      {
        url: '/font/maker/jp_ch/noto-sans-tc-v4-chinese-traditional_japanese_latin-900.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'Kosugi Maru',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [{ url: '/font/maker/jp_ch/kosugi-maru-v3-japanese_latin-regular.woff2', format: 'woff2' }],
  },
  {
    fontFamily: 'ZCOOL QingKe HuangYou',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/maker/jp_ch/zcool-qingke-huangyou-v2-chinese-simplified_latin-regular.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: 'ChosunGu',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: [
      {
        url: '/font/MakerFont/KR/ChosunGu/ChosunGu.woff2',
        format: 'woff2',
      },
    ],
  },
];

const DEFAULT_FONT_FAMILLY = 'BMHANNA';
const DEFAULT_FONT_FAMILLY_EN = 'Bree Serif';
const DEFAULT_FONT_FAMILLY_JP = 'Bree Serif';
export const DEFAULT_DEFAULT_FONT_FAMILLY = 'Roboto, Noto Sans KR, Noto Sans TC, Taviraj';
export const BASE_FONTS = [
  [
    T.lang === 'kr'
      ? DEFAULT_FONT_FAMILLY
      : T.lang === 'jp'
      ? DEFAULT_FONT_FAMILLY_JP
      : DEFAULT_FONT_FAMILLY_EN,
  ],
  ['Noto Sans KR'],
  ['Noto Sans KR', { weight: 'bold' }],
  ['Taviraj'],
  ['Taviraj', { style: 'italic' }],
  ['Taviraj', { weight: 'bold' }],
  ['Taviraj', { weight: 'bold', style: 'italic' }],
  ['Noto Sans TC'],
  ['Noto Sans TC', { weight: 'bold' }],
  ['Roboto'],
  ['Roboto', { style: 'italic' }],
  ['Roboto', { weight: 'bold' }],
  ['Roboto', { weight: 'bold', style: 'italic' }],
];

export const DEFAULT_FONT_STYLE = {
  'font-weight': 'normal',
  'font-family':
    T.lang === 'kr'
      ? DEFAULT_FONT_FAMILLY
      : T.lang === 'jp'
      ? DEFAULT_FONT_FAMILLY_JP
      : DEFAULT_FONT_FAMILLY_EN,
  'font-style': '',
  'text-decoration': '',
  'letter-spacing': 0,
  fill: '#000000',
  opacity: 1,
  stroke: '#000000',
  'stroke-width': 0,
  'font-size': 16,
  'stroke-dasharray': '0, 0',
};

export const FONT_PROPERTIES = go(
  DEFAULT_FONT_STYLE,
  entries,
  map(([key]) => key),
);

export const ALL_POSSIBLE_TEXT_REG =
  /[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ぁ-ゔァ-ヴー々〆〤一-龥\u0400-\u04FF{}[\]/?.ㆍᆞ·ᆢ,;:|)*~_`!^\-+<>@#$%&\\=(']/g;
