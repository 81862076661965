/**
 *
 * @param {object} params
 * @param {HTMLDivElement} params.tab_el
 * @param {import("@marpple/sticker-editor").EditorNS.AcrylicCuttingLineEditorNS.PCNS.AcrylicCuttingLinePCEditor} params.editor
 * @returns {void}
 */
export const setSizeGuide = (params) => {
  const foreground_container_el = params.tab_el.querySelector(`.foreground_container`) ?? null;
  if (foreground_container_el === null) {
    return;
  }
  const foreground_container_rect = foreground_container_el.getBoundingClientRect();

  const size_guide_container_el = foreground_container_el.querySelector(`.size_guide_container`) ?? null;
  if (size_guide_container_el === null) {
    return;
  }

  const size_guide_container_value_el =
    size_guide_container_el.querySelector(`.size_guide_container_value`) ?? null;
  if (size_guide_container_value_el === null) {
    return;
  }

  const size_guide_container_value_rect = size_guide_container_value_el.getBoundingClientRect();

  const outer_cutting_line_path_el = params.editor.getOuterCuttingLinePathEl();
  const outer_cutting_line_path_el_bbox = outer_cutting_line_path_el.getBBox();
  const size_width = Math.round(outer_cutting_line_path_el_bbox.width);
  const size_height = Math.round(outer_cutting_line_path_el_bbox.height);
  const size_value = `${size_width} x ${size_height} mm`;

  const top_left_xy = params.editor.convertCoordsToOutside({
    x: outer_cutting_line_path_el_bbox.x,
    y: outer_cutting_line_path_el_bbox.y,
  });
  const bottom_right_xy = params.editor.convertCoordsToOutside({
    x: outer_cutting_line_path_el_bbox.x + outer_cutting_line_path_el_bbox.width,
    y: outer_cutting_line_path_el_bbox.y + outer_cutting_line_path_el_bbox.height,
  });
  const width = bottom_right_xy.x - top_left_xy.x;
  const x =
    top_left_xy.x - foreground_container_rect.x + width / 2 - size_guide_container_value_rect.width / 2;
  const y = top_left_xy.y - foreground_container_rect.y - size_guide_container_value_rect.height * 2;
  size_guide_container_value_el.style.left = `${x}px`;
  size_guide_container_value_el.style.top = `${y}px`;
  size_guide_container_value_el.innerHTML = size_value;
  size_guide_container_value_el.style.visibility = `visible`;
};
