export const NBOX_ID = 465;

export const ENABLE_COMPANY_CODES = [
  'AG',
  'DV',
  'GN',
  'MD',
  'MT',
  'NO',
  'NT',
  'NU',
  'NW',
  'NX',
  'SE',
  'XC',
  'NF',
  'UV',
];
