import { chunk, go, html, strMap } from 'fxjs/es';
export const font = (fonts) =>
  go(
    fonts,
    chunk(12),
    (fontss) => html`
      <div class="swiper-container">
        <div class="swiper-wrapper">
          ${strMap(
            (fonts) => html`
              <div class="swiper-slide">
                ${strMap(
                  ({ id, url_2x, url_2x_a, original_name }) => html`
                    <div class="font" data-id="${id}" data-font_family="${original_name}">
                      <img src="${url_2x}" alt="" class="deactive" />
                      <img src="${url_2x_a}" alt="" class="active" />
                    </div>
                  `,
                  fonts,
                )}
              </div>
            `,
            fontss,
          )}
        </div>
        <div class="swiper-pagination"></div>
      </div>
    `,
  );
