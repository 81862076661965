import { find, go, map, some } from 'fxjs/es';

const font_names_1 = go(
  [
    'Archivo Black',
    'Racing Sans One',
    'Righteous',
    'anton',
    '도현',
    '나눔고딕',
    '배민 한나체',
    '배민 주아체',
    'Bree Serif',
    'PT Serif',
    'abril fatface',
    '송명체',
    '나눔명조',
    'BALOO',
    'permanent marker',
    '다래 손글씨',
    '미생체',
  ],
  map((a) => a.replace(/\s/g, '').toLowerCase()),
);
const font_names_2 = go(
  [
    '도현',
    '배민 연성체',
    '배민 주아체',
    '부산체',
    '블랙한산스',
    '잘난체',
    '헤드라인',
    'Bree Serif',
    'Anton',
    'Archivo Black',
    'BALOO',
    'HOLTWOOD ONE SC',
    'permanent marker',
    'Racing Sans One',
    'Righteous',
    'Roboto',
    'Shrikhand',
  ],
  map((a) => a.replace(/\s/g, '').toLowerCase()),
);
export const PROPERTY = {
  isEmbroideryApp: (configs, bp_id) => {
    return go(
      configs,
      some((config) => config.bp_ids.includes(bp_id)),
    );
  },
  name: 'is_embroidery_app',
  getConf: (configs, bp_id) => {
    return go(
      configs,
      find((config) => config.bp_ids.includes(bp_id)),
    );
  },
  multiply_image_url:
    '//s3.marpple.co/files/u_2798351/2023/10/original/acf8686a889f6e92d13849f48124a69e6e5671101.png',
  font_color: {
    only_flex: false,
    press_color_id: 10010,
    press_color_code: '#3635ac',
    press_color_name: '파랑',
    press_color_name_en: 'Blue',
    press_color_name_jp: 'ブルー',
  },
  configs: [
    {
      bp_ids: [6938],
      height_cm_in_line: 0.8,
      /* 한줄마다 높이 설정 (크기고정) */
      align: 'center',
      /* 중앙 정렬 및 고정 */
      line_length: 2,
      /* 줄 갯수 */
      obj_length: 1,
      font_names: font_names_1,
      // char_length_in_line: 6,
      /* 한줄당 글자 수(문자 상관없이 갯수로) */
    },
    {
      bp_ids: [6939, 6940],
      height_cm_in_line: 0.8,
      /* 한줄마다 높이 설정 (크기고정) */
      align: 'center',
      /* 중앙 정렬 및 고정 */
      line_length: 1,
      /* 줄 갯수 */
      obj_length: 1,
      /* cv_obj 갯수 */
      // char_length_in_line: 6,
      /* 한줄당 글자 수(문자 상관없이 갯수로) */
      font_names: font_names_1,
    },
    {
      bp_ids: [6941, 6942],
      height_cm_in_line: 0.8,
      /* 한줄마다 높이 설정 (크기고정) */
      align: 'center',
      /* 중앙 정렬 및 고정 */
      line_length: 2,
      /* 줄 갯수 */
      obj_length: 2,
      // char_length_in_line: 6,
      /* 한줄당 글자 수(문자 상관없이 갯수로) */
      font_names: font_names_1,
    },
    {
      bp_ids: [6945],
      height_cm_in_line: 0.8,
      /* 한줄마다 높이 설정 (크기고정) */
      align: 'center',
      /* 중앙 정렬 및 고정 */
      line_length: 2,
      /* 줄 갯수 */
      obj_length: 2,
      // char_length_in_line: 6,
      /* 한줄당 글자 수(문자 상관없이 갯수로) */
      font_names: font_names_2,
    },
  ],
  /*
   * 텍스트 1개만 허용, 패턴 없음
   * 이미지 안됌 (업로드, 무료이미지)
   * 각종 편의 기능 안됌(앞으로, 뒤로, 그룹, 그룹해제, 좌우반전, 상하반전)
   * */
  press_colors: [
    {
      id: 10000,
      no: 1,
      name: '빨강',
      name_en: 'Red',
      name_jp: 'レッド',
      code: '#c40000',
      only_flex: false,
    },
    {
      id: 10001,
      no: 2,
      name: '푸시아 핑크',
      name_en: 'Fuchsia Pink ',
      name_jp: 'フューシャピンク',
      code: '#DE3C65',
      only_flex: false,
    },
    {
      id: 10002,
      no: 3,
      name: '분홍',
      name_en: 'Pink',
      name_jp: 'ピンク',
      code: '#dd85be',
      only_flex: false,
    },
    {
      id: 10003,
      no: 4,
      name: '주황',
      name_en: 'Orange',
      name_jp: 'オレンジ',
      code: '#fe7a00',
      only_flex: false,
    },
    {
      id: 10004,
      no: 5,
      name: '레몬 옐로우',
      name_en: 'Lemon Yellow',
      name_jp: 'レモンイエロー',
      code: '#FBE240',
      only_flex: false,
    },
    {
      id: 10005,
      no: 6,
      name: '노랑',
      name_en: 'Yellow',
      name_jp: 'イエロー',
      code: '#ffd200',
      only_flex: false,
    },
    {
      id: 10006,
      no: 7,
      name: '청녹',
      name_en: 'Blue Green',
      name_jp: 'ブルークグリーン',
      code: '#1e564e',
      only_flex: false,
    },
    {
      id: 10007,
      no: 8,
      name: '카키',
      name_en: 'Khaki',
      name_jp: 'カーキ',
      code: '#466632',
      only_flex: false,
    },
    {
      id: 10008,
      no: 9,
      name: '녹색',
      name_en: 'Green',
      name_jp: 'グリーン',
      code: '#0d3e12',
      only_flex: false,
    },
    {
      id: 10009,
      no: 10,
      name: '네이비',
      name_en: 'Navy',
      name_jp: 'ネイビー',
      code: '#080c3e',
      only_flex: false,
    },
    {
      id: 10010,
      no: 11,
      name: '파랑',
      name_en: 'Blue',
      name_jp: 'ブルー',
      code: '#3635ac',
      only_flex: false,
    },
    {
      id: 10011,
      no: 12,
      name: '하늘색',
      name_en: 'Sky Blue',
      name_jp: 'スカイブルー',
      code: '#4aacd3',
      only_flex: false,
    },
    {
      id: 10012,
      no: 13,
      name: '밤색',
      name_en: 'Brown',
      name_jp: 'ブラウン',
      code: '#543b1f',
      only_flex: false,
    },
    {
      id: 10013,
      no: 14,
      name: '베이지',
      name_en: 'Beige',
      name_jp: 'ベージュ',
      code: '#EDE8CC',
      only_flex: false,
    },
    {
      id: 10014,
      no: 15,
      name: '보라',
      name_en: 'Purple',
      name_jp: 'パープル',
      code: '#7a22a2',
      only_flex: false,
    },
    {
      id: 10015,
      no: 16,
      name: '연보라',
      name_en: 'Light Purple',
      name_jp: 'ライトパープル',
      code: '#c69ae5',
      only_flex: false,
    },
    {
      id: 10016,
      no: 17,
      name: '연회색',
      name_en: 'Light gray',
      name_jp: 'ライトグレー',
      code: '#c1c1c1',
      only_flex: false,
    },
    {
      id: 10017,
      no: 18,
      name: '딥그레이',
      name_en: 'Deep Gray',
      name_jp: 'ディープグレー',
      code: '#565454',
      only_flex: false,
    },
    {
      id: 10018,
      no: 19,
      name: '검정',
      name_en: 'black',
      name_jp: 'ブラック',
      code: '#000000',
      only_flex: false,
    },
    {
      id: 10019,
      no: 20,
      name: '흰색',
      name_en: 'White',
      name_jp: 'ホワイト',
      code: '#ffffff',
      only_flex: false,
    },
  ],
};
