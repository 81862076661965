import { curry, defaultTo, find, go, head, html, sel, selEq, strMap } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { PRODUCT_STATUS_TEXT, PRODUCT_STATUS_TOOLTIP } from './constant.js';
import { formatFullTime } from './fs.js';

const makeThumbnailButtonHtml = (thumbnail_image, product) => legacyHtml`
  <li data-selected="${Boolean(thumbnail_image.is_thumb)}" data-url="${thumbnail_image.url}">
    ${MpShopProductTmplS.thumbnail(product, {
      width: 52,
      thumbnail_image,
    })}
  </li>
`;

const makeProductSizeOptionHtml = curry(
  (default_value, size) => legacyHtml`
    <option
      value="${size.id}"
      data-price="${size.price}"
      ${default_value === size.id ? 'selected' : ''}
      ${size._is_not_stock ? 'disabled' : ''}
    >
      ${(size._is_not_stock ? `[${T('일시 품절')}] ` : '') + UtilS.escape(size['name' + _en])}
    </option>
  `,
);

const makeProductColorOptionHtml = (default_value) => (color) =>
  legacyHtml`
  <option value="${color.id}" ${UtilS.addAttrIf(default_value === color.id, 'selected')}>
    ${UtilS.escape(color['name' + _en])}
  </option>
`;

export const makeThumbnailPreviewHtml = (product_detail, is_mobile) => {
  const thumbnails = go(product_detail, sel('thumbnails.value'), defaultTo([]));
  const thumb_index = thumbnails.findIndex((a) => a.is_thumb);
  const main_index = thumb_index === -1 ? 0 : thumb_index;
  return is_mobile && thumbnails.length > 1
    ? legacyHtml`
        <div
          class="product-detail-preview swiper-container"
          data-len="${thumbnails.length}"
          data-main-index="${main_index}"
        >
          <div class="swiper-wrapper">
            ${strMap(
              (thumbnail_image) => html`
                <div class="thumbnail-main swiper-slide">
                  <div class="thumbnail-main-image-wrapper">
                    ${MpShopProductTmplS.thumbnail(product_detail, {
                      thumbnail_image,
                      width: 600,
                    })}
                  </div>
                </div>
              `,
              thumbnails,
            )}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      `
    : html`
        <div class="product-detail-preview">
          <div class="thumbnail-main">
            <div class="thumbnail-main-image-wrapper">
              ${MpShopProductTmplS.thumbnail(product_detail, {
                width: is_mobile ? 600 : 520,
              })}
            </div>
          </div>
          ${UtilS.htmlIf(
            thumbnails.length > 1,
            `<ul class="preview-thumbnails">
             ${strMap((thumb) => makeThumbnailButtonHtml(thumb, product_detail), thumbnails)}
           </ul>`,
          )}
        </div>
      `;
};

export const makeProductColorSelectorHtml = curry((selected_bpc_id, base_product_colors) => {
  const selected_bpc = go(
    base_product_colors,
    find(selEq('id', selected_bpc_id)),
    defaultTo(head(base_product_colors)),
  );

  return legacyHtml`
    <div class="wrap-selector">
      <select name="color">
        ${strMap(makeProductColorOptionHtml(selected_bpc.id), base_product_colors)}
      </select>
      <div class="selector-view">
        <span class="selector-view-name">${UtilS.escape(selected_bpc['name' + _en])}</span>
        <span class="selector-view-btn"
          ><img
            src="https://s3.marpple.co/files/u_1187077/2020/1/original/1416625d00f2cd0a85493e5bd05c373d582f6988b2b8c84.svg"
        /></span>
      </div>
    </div>
  `;
});

export const makeProductSizeSelectorHtml = (base_product_size_id, base_product_sizes) => {
  const selected_item = find(({ id }) => id === base_product_size_id, base_product_sizes);
  return legacyHtml`
    <div class="wrap-selector" data-type="select">
      <select name="size">
        ${strMap(makeProductSizeOptionHtml(base_product_size_id), base_product_sizes)}
      </select>
      <div class="selector-view">
        <span class="selector-view-name"
          >${
            (selected_item._is_not_stock ? `[${T('일시 품절')}] ` : '') +
            UtilS.escape(selected_item['name' + _en])
          }</span
        >
        <span class="selector-view-btn"
          ><img
            src="https://s3.marpple.co/files/u_1187077/2020/1/original/1416625d00f2cd0a85493e5bd05c373d582f6988b2b8c84.svg"
        /></span>
      </div>
    </div>
  `;
};

export const makeProductSellingTimeHtml = ({ day, hour, minute, second, millisecond }) => legacyHtml`
  <div class="remain_time_unit">
    ${formatFullTime(day)}
    <div class="comment">${T('time_unit::days')}</div>
  </div>
  :
  <div class="remain_time_unit">
    ${formatFullTime(hour)}
    <div class="comment">${T('time_unit::hours')}</div>
  </div>
  :
  <div class="remain_time_unit">
    ${formatFullTime(minute)}
    <div class="comment">${T('time_unit::minutes')}</div>
  </div>
  :
  <div class="remain_time_unit ${!day && !hour && !minute && second <= 59 ? 'imminent' : ''}">
    ${formatFullTime(second)}
    <div class="comment">${T('time_unit::seconds')}</div>
  </div>
`;

export const makeProductStatusHtml = (product_status) =>
  html`<div class="mp-shop-product-status" data-status="${product_status}">
    ${PRODUCT_STATUS_TEXT(product_status)}
    <div class="mp-shop-product-status-tooltip">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 10 6">
        <g fill="none" fill-rule="evenodd">
          <g fill="rgba(17, 17, 17, 0.9)" stroke="rgba(17, 17, 17, 0.9)">
            <path
              d="M1 6L5 0 9 6"
              transform="translate(-1604 -233) translate(1531 213) translate(73 20) matrix(-1 0 0 1 10 0)"
            />
          </g>
        </g>
      </svg>
      <div>${PRODUCT_STATUS_TOOLTIP(product_status)}</div>
    </div>
  </div>`;
