import { $addClass, $find, $findAll, $hide, $qs, $removeClass, $show } from 'fxdom/es';
import { each, go, map, mapC } from 'fxjs/es';
import { addLineSticker } from '../../../../Maker/F/image.js';
import { addCvTextImageInMakerA } from '../../../../Maker/F/text.js';
import { MarppleCustomDetailF } from '../../../../Marpple/CustomDetail/F/Function/module/MarppleCustomDetailF.js';
import { NewMakerCvObjectCvBackgroundMuiF } from '../../../CvObject/CvBackground/F/Mui/module/NewMakerCvObjectCvBackgroundMuiF.js';
import { NewMakerCvObjectCvImageF } from '../../../CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { openFrameLocOptimization } from '../../../../Maker/F/LocOptimization.js';
import { errorHandlingEventWrapper } from '../../../Error/F/Function/errorHandlingEventWrapper.js';

let is_on_animation = false;

export const decorationToggle = async (is_off) => {
  if (!UtilF.isLegacyMobile()) return;
  if (is_on_animation) return;
  is_on_animation = true;
  const duration = 500;
  const easing = 'easeInOutQuart';
  const decoration_menu_el = $qs('#maker_frame .decoration_menu');

  const lis = go(decoration_menu_el, (decoration_menu_el) => {
    const mp_maker_el = $qs('#maker_frame');
    if (
      mp_maker_el.dataset.is_carved_phonecase_product === 'true' ||
      mp_maker_el.dataset.is_embroidery_app === 'true'
    ) {
      return $findAll('.add_text')(decoration_menu_el);
    }
    if (mp_maker_el.dataset.is_creator === 'true') {
      if (mp_maker_el.dataset.has_background === 'true') {
        return go(
          ['.add_text', '.add_img', '.open_cv_background_frame'],
          map((selector) => $find(selector)(decoration_menu_el)),
        );
      }
      return go(
        ['.add_text', '.add_img'],
        map((selector) => $find(selector)(decoration_menu_el)),
      );
    }

    const deco_buttons_tobe_show = ['.add_sticker2', '.add_text', '.add_img'];

    const maker_el = $qs('#maker_frame');
    const is_has_background = maker_el.dataset.has_background === 'true';
    is_has_background && deco_buttons_tobe_show.push('.open_cv_background_frame');

    /* 위치 최적화 활성화 face 인 경우에 "위치 최적화" 버튼 보여주기 */
    const is_enable_loc_optimization = maker_el.dataset.is_enable_loc_optimization === 'true';
    is_enable_loc_optimization && deco_buttons_tobe_show.unshift('.loc_optimization_btn');

    return go(
      deco_buttons_tobe_show,
      map((selector) => $find(selector)(decoration_menu_el)),
    );
  });

  const text_els = go(lis, map($find('.text')));
  if (is_off) {
    $removeClass('decoration_mode')(decoration_menu_el);
    await Promise.all([
      anime({
        targets: lis,
        translateY: 0,
        duration,
        easing,
      }).finished,
      anime({
        targets: text_els,
        duration: 10,
        easing,
        opacity: 0,
      }).finished,
    ]);
    each($hide)(lis);
  } else {
    $addClass('decoration_mode')(decoration_menu_el);
    each($show)(lis);
    let i = 0;
    await Promise.all([
      mapC(async function (el) {
        await anime({
          targets: el,
          translateY: -95 * (i++ + 1),
          duration,
          easing,
        }).finished;
      })(lis),
      anime({
        targets: text_els,
        duration: 200,
        delay: 200,
        easing,
        opacity: 1,
      }).finished,
    ]);
  }
  is_on_animation = false;
};

export const decorationOn = async () => {
  await decorationToggle();
};
export const decorationOff = async () => {
  await decorationToggle(true);
};

export const decorationEvent = (el_maker_body) => {
  /* .decoration_menu */
  _go(
    el_maker_body,
    $.on(
      'click',
      '.decoration_menu .marpple_icon',
      errorHandlingEventWrapper(async function (e) {
        await decorationToggle($qs('#maker_frame .decoration_menu.decoration_mode'));
      }),
    ),
    $.on(
      'click',
      '.decoration_menu .open_cv_background_frame',
      errorHandlingEventWrapper(async function (e) {
        decorationOff();
        await NewMakerCvObjectCvBackgroundMuiF.openFrame(8);
      }),
    ),
    $.on(
      'click',
      '.decoration_menu .add_img',
      errorHandlingEventWrapper(function (e) {
        decorationOff();
        $.frame.open({
          frame_name: 'maker.image_upload_frame',
          closing: async function (X, image) {
            if (!image) return;
            await NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
          },
        });
      }),
    ),

    /* 위치 최적화 버튼 클릭시 보편적 위치 가이드 심플 모달 팝업 */
    $.on(
      'click',
      '.decoration_menu .loc_optimization_btn',
      errorHandlingEventWrapper(openFrameLocOptimization),
    ),
    $.on2(
      'click',
      '.decoration_menu .add_text',
      errorHandlingEventWrapper(function () {
        decorationOff();
        return addCvTextImageInMakerA();
      }),
    ),

    $.on('click', '.decoration_menu .add_sticker_line', errorHandlingEventWrapper(addLineSticker)),
    $.on(
      'click',
      '.decoration_menu .add_sticker2',
      errorHandlingEventWrapper(function () {
        decorationOff();
        $.frame.open(
          {
            frame_name: 'maker.sticker2',
            page_name: 'maker_sticker_page',
            animation: true,
            appending: __($.attr('is_fixed_head', 'true')),
            closing: function (don_frame, image) {
              if (!image) return;
              return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
            },
          },
          {
            page_name: 'maker_sticker_page',
            tabs: [
              {
                tab_name: 'maker_sticker_tab',
                selected: true,
              },
              {
                tab_name: 'maker_embro_tab',
              },
            ],
          },
        );
      }),
    ),

    $.on(
      'click',
      '.decoration_menu .add_sticker_custom',
      errorHandlingEventWrapper(MarppleCustomDetailF.addCustomSticker),
    ),

    $.on(
      'click',
      '.decoration_menu .my_lib',
      errorHandlingEventWrapper(function () {
        decorationOff();
        $.frame.open(
          {
            frame_name: 'maker.my_lib',
            page_name: 'maker_my_lib_page',
            animation: true,
            loader: true,
            appending: __($.attr('is_fixed_head', 'true')),
            closing: function (don_frame, image) {
              if (!image) return;
              return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
            },
          },
          {
            page_name: 'maker_my_lib_page',
          },
        );
      }),
    ),
  );
  /*면 바꾸기*/
  //
  // _go(
  //   el_maker_body,
  //   $.on('click', '.super_menu >.body li', function () {
  //     $.remove_class($1('#maker_frame'), 'decoration_menu product_menu');
  //   }),
  // );
};
