import { go, map } from 'fxjs/es';

/**
 * @param {string?} str
 * @return {!string}
 */
export const trim = (str) => {
  return (str || '').trim();
};

/**
 * cate_list와 cate_item에 슬래쉬 좌우 공백이 없으면 넣어준다.
 * 공백이 없으면 word break가 불가하여 넣은 추가함
 * @param {CateList[]} cate_lists
 * @return {CateList[]} normalized cate_lists
 */
export const normalizeSlashesOfCateLists = (cate_lists) => {
  return go(
    cate_lists,
    map((cate_list) => {
      return {
        ...cate_list,
        name: normalizeSlashes(cate_list.name),
        _: {
          cate_items: map((cate_item) => {
            return {
              ...cate_item,
              name: normalizeSlashes(cate_item.name),
            };
          }, cate_list._.cate_items),
        },
      };
    }),
  );
};

/** 슬래쉬 사이에 공백이 없다면 좌우에 공백을 만들어주는 함수, 이미 공백이 있다면 변화가 없다.
 * @param str
 * @return {str}
 * @example
 * `normalizeSlashes('A/B')` returns `A / B`
 * `normalizeSlashes('A /B')` returns `A / B`
 * `normalizeSlashes('A / B')` returns `A / B`
 */
export const normalizeSlashes = (str) => {
  return str.replace(/\s*\/\s*/g, ' / ');
};
