import { go, join, map } from 'fxjs/es';
// const taviraj =
//   'ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*ๆฯฯฯ๏๚๛฿';
// const roboto =
//   'ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžАБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈέΉίϊΐΊόΌύΰϋΎΫΏĂÂÊÔƠƯăâêôơư1234567890‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*';

// const CH_JP_TEXT_REG = /[ぁ-ゔァ-ヴー々〆〤一-龥]/g;
const ALL_POSSIBLE_TEXT_REG =
  /[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ぁ-ゔァ-ヴー々〆〤一-龥\u0400-\u04FF[{}[\]/?.ᆢㆍᆞ,;:|)*~_`!^\-+<>@#$%&\\=('"ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘’“”÷×®©€£¥¢ๆฯ฿АБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈΉίϊΐΊόΌύΰϋΎΫΏ]/g;
const ENG_NUM_TEXT_REG = /[a-zA-Z0-9]/g;
const not_good_spaces =
  // eslint-disable-next-line no-control-regex,no-misleading-character-class
  /[\v\f\b\u200B\u200C\u200D\u200E\u200F\u000b\u2028\u2029\uFEFF\u202D\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000]/g;
export const getOnlyPossibleText = (text) => {
  if (!text) return '';
  const _text = go(
    text.replace(not_good_spaces, ''),
    (text) => text.matchAll(ALL_POSSIBLE_TEXT_REG),
    map((v) => v[0]),
    join(''),
  );
  return _text || '';
};
export const getOnlyPossibleUpperCaseEnAndKrText = (text) => {
  if (!text) return '';
  const _text = go(
    text.replace(not_good_spaces, ''),
    (text) => text.matchAll(/[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣[{}[\]/?.ᆢㆍᆞ,;:|)*~_`!^\-+<>@#$%&\\=(]/g),
    map((v) => v[0]),
    map((v) => v.toUpperCase()),
    join(''),
  );
  return _text || '';
};

export const hasWrongText = (text) => {
  return text !== getOnlyPossibleText(text);
};

export const hasOnlyEngAndNum = (text) => {
  return text.replace(ENG_NUM_TEXT_REG, '') === '';
};
