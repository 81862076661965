import { filter, find, go, html, sel, strMap, sortBy } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { BpOptionS } from '../Function/module/BpOptionS.js';
import { NewMakerUtilS } from '../../../NewMaker/Util/S/Function/module/NewMakerUtilS.js';

const bpOptionGroupsHtmlArr = ({
  selected_bp_option_id,
  base_products_bp_option_group,
  bp_option_group,
  bp_options,
  is_designed,
}) => {
  const { can_change, default_bp_option_id } = base_products_bp_option_group;
  const { is_visibility, is_name, is_lockable, is_virtual, name, name_en, name_jp, dev_name, id } =
    bp_option_group;
  return html`
    <div
      class="bp_option_group"
      data-id="${id}"
      data-_default_bp_option_id="${default_bp_option_id}"
      data-is_lockable="${is_lockable}"
      data-dev_name="${dev_name}"
      data-is_virtual="${is_virtual}"
      data-is_name="${is_name}"
      data-can_change="${can_change}"
      data-is_visibility="${is_visibility}"
      data-name="${name}"
      data-name_en="${name_en}"
      data-name_jp="${name_jp}"
      data-items_more_than_4="${BpOptionConstantS.SELECT_BOX_STYLE_BP_OPTION_GROUP_IDS.includes(
        bp_option_group.id,
      ) ||
      go(
        bp_options,
        filter((bp_option) => bp_option.is_public),
      ).length > 4}"
    >
      <div class="title">${bp_option_group['name' + G._en]}</div>
      ${bp_option_group.dev_name === BpOptionUploadTypeS.UPLOAD_TYPE.dev_name
        ? BpOptionUploadTypeS.UPLOAD_TYPE.customSelectBoxHtml(selected_bp_option_id)
        : html`<div class="select_box">
            ${strMap(
              (bp_option) =>
                html`
                  <div
                    data-is_public="${bp_option.is_public}"
                    class="option ${selected_bp_option_id === bp_option.id ? 'selected' : ''}"
                    data-id="${bp_option.id}"
                  >
                    ${bp_option['name' + G._en]}
                  </div>
                `,
            )(bp_options)}
          </div>`}
      <select
        name="${bp_option_group.id}"
        data-fx-json="${$dataStr(bp_option_group)}"
        data-id="${bp_option_group.id}"
        data-name="${bp_option_group.name}"
        data-name_en="${bp_option_group.name_en}"
        data-name_jp="${bp_option_group.name_jp}"
        ${is_designed && is_lockable ? 'disabled' : ''}
      >
        ${strMap(
          (bp_option) =>
            html`
              <option
                data-id="${bp_option.id}"
                data-fx-json="${$dataStr(bp_option)}"
                data-dev_name="${bp_option.dev_name}"
                data-is_public="${bp_option.is_public}"
                data-name="${bp_option.name}"
                data-name_en="${bp_option.name_en}"
                data-name_jp="${bp_option.name_jp}"
                value="${bp_option.id}"
                ${selected_bp_option_id === bp_option.id ? 'selected' : ''}
              >
                ${bp_option['name' + G._en]}
              </option>
            `,
        )(bp_options)}
      </select>
    </div>
  `;
};

export const bpAllOptions = ({ product, selected_option_group }) => {
  const is_designed =
    (BpOptionS.hasBpOptionListWithoutVectorEditor(product._.base_product) &&
      NewMakerUtilS.hasDesignsInFaces(product)) ||
    !!product.svg_product_id ||
    !!product._.svg_product_materials?.length;
  selected_option_group = selected_option_group || product?._?.selected_option_group;
  const base_products_bp_option_groups = product._.base_product._.base_products_bp_option_groups;
  return html`
    <div class="bp_option_groups" data-is_designed="${is_designed}">
      ${go(
        base_products_bp_option_groups,
        sortBy(
          (base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group.is_visibility,
        ),
        strMap((base_products_bp_option_group) => {
          const bp_option_group = base_products_bp_option_group._.bp_option_group;
          const bp_options = bp_option_group._.bp_options;
          const selected_bp_option_id =
            go(
              bp_option_group._.bp_options,
              find(
                ({ id }) =>
                  selected_option_group?.bp_option_ids?.length &&
                  selected_option_group.bp_option_ids.includes(id),
              ),
              sel('id'),
            ) || base_products_bp_option_group.default_bp_option_id;

          return bpOptionGroupsHtmlArr({
            selected_bp_option_id,
            base_products_bp_option_group,
            bp_option_group,
            bp_options,
            is_designed,
          });
        }),
      )}
    </div>
  `;
};
