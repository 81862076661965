export default {
  common: {
    common_01: 'エラーが発生しました。',
    all: '全体',
  },
  customer_orders: {
    pick_up: 'ピックアップ',
    possible_pick_up: 'ピックアップ可能',
  },
  crew: {
    authorization: {
      signup_01: 'メールアドレス',
      signup_02: '名前',
      signup_03: '社員番号',
      signup_04: '利用規約に同意',
      signup_05: '個人情報収集及び利用に同意',
      signup_06: '個人情報の第三者提供に同意',
      signup_07: '14歳以上確認',
      signup_08: '[選択] メールマーケティングの受信に同意',
      signup_09: '見る',
      signup_10: 'ログイン',
      signup_11: '利用規約に同意してください。',
      signup_12: '個人情報収集の利用に同意してください。',
      signup_13: '個人情報の第三者提供に同意してください。',
      signup_14: '13歳以上会員登録が可能です。',
      signup_15: '既存のユーザーが存在します。 ご確認ください。',
      signup_17: '認証が完了されていません。',
      signup_00: '新規会員登録',
      signup_18: '所属する法人はNBOXサービスの対象ではありません',
    },
  },
  layout: {
    drawer: {
      menu: {
        product: '公式商品',
        review: 'レビュー',
        home: 'ホーム',
      },
    },
    footer1: '(株)マープルコーポレーション',
    footer2: 'カスタマーセンター',
    footer3: '通報センター',
    footer4: 'セラー申請',
    footer5: '企業セラー申請',
    footer6: '個人情報処理方針',
    footer7: '利用規約',
    footer8:
      'セラー商品の場合、(株)マープルコーポレーションは通信販売仲介者として通信販売の当事者ではなく、商品取引及び取引情報に対して責任を負いません。',
    footer9: '© 2023 MARPPLE CORP.',
    footer: {
      address: '住所',
      address_info: 'ソウル市衿川区加山デジタル1路75-24アイエスビズタワー10階',
      apply_seller: 'セラー申請',
      blog: 'ブログ',
      ceo: '代表',
      ceo_name: 'パク·ヘユン',
      company_name: '(株)マープルコーポレーション',
      company_registration_info: '事業者登録番号',
      company_registration_number: '105-88-13322',
      customer_center: 'カスタマーセンター',
      customer_center_info: '(平日10:00~18:00)',
      email: 'メールアドレス',
      instagram: 'インスタグラム',
      marpple_corp: 'マープルコーポレーション',
      privacy_policy: '個人情報処理方針',
      report_center: '通報センター',
      terms_and_conditions: '利用規約',
      twitter: 'ツイッター',
      youtube: 'ユーチューブ',
      nbox: {
        address: '京畿道城南市盆唐区板橋路256-7',
        ceo_name: 'イ·ジョンホン',
        phone: '電話番号',
        phone_number: '{{tel}}(平日10:00~18:00)',
        company_registration_number: '220-87-17483号',
        footer1: 'ネクソンコリア',
        footer9: '© NEXON Korea Corporation All Rights Reserved.',
      },
    },
  },
  my_page: {
    my_page_01: '<b>{{name}}</b>様, こんにちは。 👋',
    my_account_01: 'プロフィールイメージ',
    my_account_02:
      '10MB以内のイメージファイルをアップロードしてください。<br />(イメージアップロード形式:JPG、JPEG、PNG)',
    my_account_03: 'ファイルがありません。',
    my_account_04: 'ログインが必要です。',
    my_account_05: 'ファイルが保存されていません。',
    my_account_06: '削除',
    like_product_01: 'いいね',
    like_product_02: 'いいねを押した商品がありません。',
  },
  order: {
    '배송지 변경': 'お届け先変更',
  },
  checkout: {
    receipt: {
      receipt_01: '注文が完了しました。',
      receipt_02: '特別さを込めて製作し配送いたします。<br />お楽しみにお待ちください！',
    },
  },
  product: {
    sort: {
      최신순: '最新順',
      '리뷰 많은 순': 'レビューが多い順',
      인기순: '人気順',
      '낮은 가격 순': '価格が低い順',
      '높은 가격 순': '価格が高い順',
    },
    review: {
      count: '商品レビュー',
      score: '評点',
    },
  },
  plus_admin: {
    plus_admin_01: 'テーマ選択',
    plus_admin_02: 'ブラックテーマ',
    plus_admin_03: 'ホワイトテーマ',
    banner_01: 'サブタイトル',
    banner_02: '多言語対応で、入力時に左側からプレビューが可能です。',
    banner_03: 'タイトル',
    plus_admin_04: '背景カラー選択',
  },
  home: {
    plus_home: {
      plus_home_01: '人気商品',
      plus_home_02: '最も愛されている公式商品',
      plus_home_03: '遅れる前に買いましょう。',
      plus_home_04: 'まさに今！チェックすべきグッズ',
      plus_home_05: '購入した人たちのレビューが気になる時',
      plus_home_06: 'リアルタイムレビュー',
      channel_on: 'NBOX チャンネル on',
      nbox_hot_item: '今注目するNBOXホットテム',
    },
  },
  community: {
    input: '入力',
    comment_place_holder: 'コメントを残してみてください。',
    community_01: '変更事項はありません。',
  },
  cart: {
    title: 'カート',
  },
  product_detail: {
    tab01: 'ストーリー',
    tab02: '商品情報',
    tab03: '購入/配送',
    tab04: 'レビュー',
    review: {
      empty_title: 'まだ登録されたレビューがありません。',
      empty_text: '初レビューの主人公になってみてください！',
    },
    nbox: {
      pick_title: 'ネクソンピックアップ',
      pick_desc1: '無料',
      pick_desc2: 'ネクソンピックアップ可能',
    },
    purchase_delivery: {
      pd01: '今ご注文いただくと<span style="color:{color};">{start}</span>から<span style="color:{color};">{end}}</span>の間に出荷予定です。',
      pd02: 'マープルショップのオーダーメイド商品と一緒に注文する場合、オーダーメイド商品の出庫日に合わせて一緒に配送されます。',
      pd03: '全てのマープルショップ配送商品は<span style="color:{color};">注文製作方式</span>で製作され、単純な心変わりによる交換/返品は致しかねます。',
    },
  },
  review: {
    comment_header: '商品レビューを残してください。',
  },
  limit_product: {
    limit_product_01: '限定版',
  },
};
