import { html } from 'fxjs/es';

export const svg = {
  heart: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <g stroke="#DDD" stroke-width="1.5">
          <g>
            <g>
              <g>
                <path
                  d="M10 5.351c-.821-1.333-2.245-2.94-3.864-2.94-2.545 0-4.607 2.044-4.607 4.835 0 2.184 1.086 3.422 1.735 4.201.651.779 3.522 3.39 6.736 6.024 3.214-2.635 6.085-5.245 6.736-6.024.65-.779 1.735-2.017 1.735-4.201 0-2.79-2.062-4.834-4.607-4.834-1.619 0-3.043 1.606-3.864 2.94z"
                  transform="translate(-1333 -96) translate(932 88) translate(394) translate(7 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  heart2: html`
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6.88796C13.4721 6.14814 12.8191 5.38817 12.0717 4.7638C11.1139 3.96374 9.95581 3.34961 8.67867 3.34961C4.89609 3.34961 1.83337 6.30926 1.83337 10.3182C1.83337 13.3505 3.35417 15.1169 4.26499 16.1748C4.29176 16.2059 4.31799 16.2364 4.34365 16.2663L4.3439 16.2665C4.81957 16.8196 6.06068 17.9771 7.70881 19.4163C9.36859 20.8657 11.4696 22.6251 13.6886 24.3923C13.8708 24.5374 14.1292 24.5374 14.3115 24.3923C16.5305 22.6251 18.6315 20.8657 20.2913 19.4163C21.9394 17.9771 23.1805 16.8196 23.6562 16.2665L23.6564 16.2663C23.6821 16.2364 23.7083 16.2059 23.7351 16.1748C24.6459 15.1169 26.1667 13.3505 26.1667 10.3182C26.1667 6.30926 23.104 3.34961 19.3214 3.34961C18.0443 3.34961 16.8862 3.96374 15.9284 4.7638C15.181 5.38817 14.528 6.14814 14 6.88796Z"
        stroke="#CCCCCC"
        stroke-linejoin="round"
      />
    </svg>
  `,

  default_profile: html`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="82"
      height="82"
      viewBox="0 0 82 82"
    >
      <defs>
        <rect id="4r5rd2syoa" width="80" height="80" x="0" y="0" rx="20" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g transform="translate(-79 -905) translate(80 906)">
            <mask id="j11u9cc9ub" fill="#fff"><use xlink:href="#4r5rd2syoa" /></mask>
            <use fill="#FFF" stroke="#F8F8F8" stroke-width="2" xlink:href="#4r5rd2syoa" />
            <g fill="#DDD" mask="url(#j11u9cc9ub)">
              <g>
                <path
                  d="M12.88 12.88V1.84C8.044 4.147 4.146 8.044 1.84 12.88h11.04zM29.44 29.44L29.44 16.56 16.56 16.56 16.56 29.44zM32.2 12.88h11.04C40.934 8.044 37.036 4.147 32.2 1.84v11.04zM16.56 12.879h12.88V.875c-4.208-1.167-8.672-1.167-12.88 0V12.88v-.001zM12.88 33.12H1.84c2.308 4.836 6.205 8.733 11.04 11.04V33.12zM32.2 33.12v11.04c4.835-2.307 8.732-6.204 11.04-11.04H32.2zM33.12 16.56v12.88h12.004c1.168-4.208 1.168-8.672 0-12.88H33.12zM29.44 33.12H16.56v12.004c4.206 1.168 8.668 1.168 12.874 0V33.12h.006zM12.88 29.439V16.56H.875c-1.167 4.208-1.167 8.672 0 12.88H12.87l.009-.001z"
                  transform="translate(17 17)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  plus: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g fill="none" fill-rule="evenodd">
        <g fill="#4E4E4E">
          <g>
            <g>
              <g>
                <g
                  transform="translate(-1012 -523) translate(932 88) translate(0 405) translate(80 30) translate(7 6)"
                >
                  <rect width="12" height="1" y="6" rx=".5" />
                  <path
                    d="M.5 6h11c.276 0 .5.224.5.5s-.224.5-.5.5H.5C.224 7 0 6.776 0 6.5S.224 6 .5 6z"
                    transform="rotate(-90 6 6.5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  minus: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g fill="none" fill-rule="evenodd">
        <g fill="#4E4E4E">
          <g>
            <g>
              <g transform="translate(-932 -523) translate(932 88) translate(0 405) translate(0 30)">
                <rect width="12" height="1" x="7" y="12" rx=".5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  ruler: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g transform="translate(-24 -627) translate(24 610) translate(0 17)">
              <rect width="17.286" height="1" x="2.357" y="4.696" fill="#4E4E4E" rx=".5" />
              <path
                fill="#4E4E4E"
                d="M1.971 4.696H3.75c.253 0 .458.205.458.458s-.205.458-.458.458H1.97c-.253 0-.458-.205-.458-.458s.205-.458.458-.458z"
                transform="rotate(-90 2.86 5.154)"
              />
              <path
                fill="#4E4E4E"
                d="M5.06 9.316h1.104c.253 0 .458.206.458.459s-.205.458-.458.458H5.06c-.253 0-.458-.205-.458-.458s.205-.459.458-.459z"
                transform="rotate(-90 5.612 9.775)"
              />
              <path
                fill="#4E4E4E"
                d="M10.448 9.316h1.104c.253 0 .458.206.458.459s-.205.458-.458.458h-1.104c-.253 0-.458-.205-.458-.458s.205-.459.458-.459z"
                transform="rotate(-90 11 9.775)"
              />
              <path
                fill="#4E4E4E"
                d="M13.142 9.316h1.104c.253 0 .458.206.458.459s-.205.458-.458.458h-1.104c-.253 0-.458-.205-.458-.458s.205-.459.458-.459z"
                transform="rotate(-90 13.694 9.775)"
              />
              <path
                fill="#4E4E4E"
                d="M6.744 10.327h3.124c.253 0 .459.205.459.458s-.206.458-.459.458H6.744c-.253 0-.458-.205-.458-.458s.205-.458.458-.458z"
                transform="rotate(-90 8.306 10.785)"
              />
              <path
                fill="#4E4E4E"
                d="M14.826 10.327h3.124c.253 0 .458.205.458.458s-.205.458-.458.458h-3.124c-.253 0-.459-.205-.459-.458s.206-.458.459-.458z"
                transform="rotate(-90 16.388 10.785)"
              />
              <path
                fill="#4E4E4E"
                d="M18.251 4.696h1.778c.253 0 .458.205.458.458s-.205.458-.458.458H18.25c-.253 0-.458-.205-.458-.458s.205-.458.458-.458z"
                transform="rotate(-90 19.14 5.154)"
              />
              <rect width="16.417" height="8.167" x="2.745" y="8.806" stroke="#4E4E4E" rx=".5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  box: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fill-rule="evenodd">
        <rect stroke="#111" stroke-width="1.2" x=".6" y=".6" width="16.8" height="5.775" rx=".5" />
        <rect fill="#0E2EE2" x="6.188" y="9" width="5.625" height="1.35" rx=".675" />
        <path d="M16.275 6.225v10.694L2.2 17.381 1.75 6.225h14.525z" stroke="#111" stroke-width="1.2" />
      </g>
    </svg>
  `,
  period: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fill-rule="evenodd">
        <path
          d="M11.794 4.982 9.301 8l-.081-.014A1.419 1.419 0 0 0 9 7.968c-.776 0-1.407.618-1.407 1.375 0 .756.631 1.374 1.407 1.374.775 0 1.407-.618 1.407-1.374 0-.17-.032-.335-.094-.492l-.031-.077 2.493-3.014a.613.613 0 0 0-.091-.871.639.639 0 0 0-.89.093z"
          fill="#0E2EE2"
        />
        <circle stroke="#111" stroke-width="1.2" cx="9" cy="9" r="8.4" />
      </g>
    </svg>
  `,
  truck: html`<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(4.4 10.5)" fill="#F8F8F8" stroke="#000" stroke-width="1.2">
        <circle cx="2.25" cy="2.25" r="1.65" />
        <circle cx="9.25" cy="2.25" r="1.65" />
      </g>
      <path
        stroke="#000"
        stroke-width="1.2"
        fill="#FFF"
        stroke-linejoin="round"
        d="M11.4 5.15v7h6v-3.5l-1.5-3.5z"
      />
      <path
        d="m12.332 3.378.437 8.301-9.301.443-.437-8.301 9.301-.443z"
        stroke="#000"
        stroke-width="1.2"
        fill="#FFF"
      />
      <path fill="#FFF" d="M.4 3.95h4v6h-4z" />
      <g transform="translate(0 5.15)" fill="#0E2EE2">
        <rect width="6" height="1.2" rx=".6" />
        <rect x="2" y="2.4" width="4" height="1.2" rx=".6" />
      </g>
    </g>
  </svg> `,

  arrow_right: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g stroke="#A2A2A2">
          <g>
            <g>
              <g>
                <path
                  d="M7.725 2.125L7.725 8.525 1.325 8.525"
                  transform="translate(-339 -632) translate(24 610) translate(0 17) matrix(1 0 0 -1 315 17) rotate(-45 4.525 5.325)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  limit: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fill-rule="evenodd">
        <g stroke="#4E4E4E">
          <g>
            <g>
              <g>
                <g>
                  <g
                    transform="translate(-80 -1725) translate(80 1130) translate(0 321) translate(0 253) translate(0 21) translate(3 3)"
                  >
                    <circle cx="8" cy="8" r="8" />
                    <path d="M7.55 -0.587L8.245 15.398" transform="rotate(-45 7.897 7.406)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  quality: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fill-rule="evenodd">
        <g stroke="#4E4E4E">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke-linejoin="round"
                      d="M9 16.2L6.219 17.56 4.768 14.825 1.719 14.29 2.152 11.225 0 9 2.152 6.775 1.719 3.71 4.768 3.175 6.219 0.44 9 1.8 11.781 0.44 13.232 3.175 16.281 3.71 15.848 6.775 18 9 15.848 11.225 16.281 14.29 13.232 14.825 11.781 17.56z"
                      transform="translate(-80 -1757) translate(80 1130) translate(0 321) translate(0 253) translate(0 53) translate(2 2)"
                    />
                    <path
                      stroke-linecap="round"
                      d="M6 8.616L8.375 11 12 7"
                      transform="translate(-80 -1757) translate(80 1130) translate(0 321) translate(0 253) translate(0 53) translate(2 2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  arrow_up: html`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none">
      <path
        d="M15.834 8L10.0007 13.8333L4.16732 8"
        stroke="#A2A2A2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,

  swiper_next: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="52" viewBox="0 0 22 52">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".25">
        <g stroke="#000" stroke-width="3">
          <g>
            <g>
              <path
                d="M735 2L751 26 735 50"
                transform="translate(-829 -456) translate(80 88) translate(17 368)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  swiper_prev: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="52" viewBox="0 0 22 52">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".25">
        <g stroke="#000" stroke-width="3">
          <g>
            <g>
              <g>
                <path
                  d="M3 2L19 26 3 50"
                  transform="translate(-100 -3841) translate(80 1130) translate(0 2465) translate(20 246) matrix(-1 0 0 1 22 0)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  clip: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g stroke="#DDD" stroke-width="1.5">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M8.25 11.253v3.13c0 2.182-1.847 3.95-4.125 3.95S0 16.565 0 14.383v-3.13M0 7.08V3.95C0 1.769 1.847 0 4.125 0S8.25 1.769 8.25 3.95v3.13M4.125 6.814L4.125 11.519"
                        transform="translate(-319.000000, -159.000000) translate(80.000000, 88.000000) translate(144.000000, 0.000000) translate(83.000000, 67.000000) translate(12.000000, 4.000000) translate(1.718628, 1.481173) translate(9.398628, 9.398628) rotate(-315.000000) translate(-9.398628, -9.398628) translate(5.273628, 0.231961)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  pencil: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g stroke="#4E4E4E" stroke-width="1.415">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M2.088 0C.935 0 0 .834 0 1.864v14.654l2.088 4.614 2.087-4.614V1.864C4.175.834 3.24 0 2.088 0zM0 14.099L4.175 14.099"
                      transform="translate(-760.000000, -291.000000) translate(482.000000, 100.000000) translate(185.000000, 98.000000) translate(88.000000, 88.000000) translate(5.283019, 5.283019) translate(10.143813, 10.335456) rotate(-315.000000) translate(-10.143813, -10.335456) translate(8.056195, -0.230581)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  close: html`
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fill-rule="evenodd">
        <g fill="#FFF">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M8.965 2.626c.318 0 .576.258.576.576v5.186h5.186c.319 0 .577.258.577.577 0 .318-.258.576-.577.576H9.541v5.186c0 .319-.258.577-.576.577-.319 0-.577-.258-.577-.577V9.541H3.202c-.318 0-.576-.258-.576-.576 0-.319.258-.577.576-.577h5.186V3.202c0-.318.258-.576.577-.576z"
                    transform="translate(-889.000000, -425.000000) translate(482.000000, 100.000000) translate(50.000000, 223.000000) translate(351.000000, 96.000000) translate(3.137019, 3.137019) translate(8.964676, 8.964676) rotate(-225.000000) translate(-8.964676, -8.964676)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,

  share_ios: html`
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 10h2a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h1.997"
        stroke="#828282"
        stroke-width="1.6"
        stroke-linecap="square"
      />
      <path d="M11 16V3M7 6l4-4 4 4" stroke="#828282" stroke-width="1.6" />
    </svg>
  `,

  chevron_left: html`
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11.458 17.667-7.543-7.543 7.543-7.542"
        stroke="#111"
        stroke-width="2"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
};
