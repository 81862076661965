export const HEADER_TYPE = {
  home: 'home',
  search: 'search',
  basic: 'basic',
};

export const MOBILE_HEADER_HEIGHT = 64;
export const MOBILE_HEADER_SUB_MENUS_HEIGHT = 50;
export const MOBILE_HEADER_BANNER_HEIGHT = 30;

export const LEFT_MODE = {
  left: 'left',
  left_hide: 'left_hide',
  left_fixed: 'left_fixed',
  left_delete: 'left_delete',
};

// TODO: URL 수정 필요
export const LEFT_SUB_MENU = () => ({
  sign_up: { title: TT('omp_layout::left::sub_01'), key: 'sign_up', url: 'signup' },
  login: { title: TT('omp_layout::left::sub_02'), key: 'login', url: 'login' },
  my_page: { title: TT('omp_layout::left::sub_06'), key: 'my_page', url: 'my-page' },
  print_service: { title: '인쇄 서비스', key: 'print_service', url: 'product/detail?bp_id=2984' },
  question: {
    title: TT('omp_layout::left::sub_03'),
    key: 'question',
    url: ``,
  },

  // ...(TT.lang == 'kr'
  //   ? {
  //       group: {
  //         title: TT('omp_layout::left::sub_08'),
  //         key: 'group',
  //         url: `group`,
  //       },
  //     }
  //   : {}),

  customer_service: {
    title: TT('omp_layout::left::sub_04'),
    key: 'customer_service',
    url: `help_center?type=${TT.lang === 'kr' ? 'order_shipping_kr' : 'buying_shipping'}`,
  },
  about: { title: TT('omp_layout::left::sub_05'), key: 'about', url: 'about' },
  logout: { title: TT('omp_layout::left::sub_07'), key: 'logout', url: 'logout' },
});
// MEMO: uri, key 부분은 route 주소 수정에 따라 맞춰서 수정되어야 합니다.
export const LEFT_MENU = () => ({
  make_product: {
    title: TT('omp_layout::left::left_01'),
    url: '',
    key: 'layout',
  },
  group_biz_products: {
    title: TT('omp_layout::left::left_08'),
    key: 'group_biz_products',
    url: 'biz',
  },
  all_products: { title: TT('omp_layout::left::left_03'), key: 'search', url: 'search' },
  corp_goods: { title: TT('omp_layout::left::left_04'), key: 'kit', url: 'kit' },
  reviews: {
    title: TT('omp_layout::left::left_05'),
    title_mo: TT('omp_layout::left::left_07'),
    key: 'review',
    url: 'review/list?is_best=true&is_all=false',
  },
  ...(TT.lang == 'kr'
    ? { know_how: { title: TT('omp_layout::left::left_06'), key: 'content', url: 'custom/list' } }
    : {}),
});

export const HAM_MENU = () => ({
  make_product: {
    title: TT('omp_layout::left::left_01'),
    url: '',
    key: 'layout',
  },
  group_biz_products: {
    title: TT('omp_layout::left::left_08_01'),
    key: 'group_biz_products',
    url: 'biz',
  },
  all_products: { title: TT('omp_layout::left::left_03'), key: 'search', url: 'search' },
  corp_goods: { title: TT('omp_layout::left::left_04_mo'), key: 'kit', url: 'kit' },
  reviews: {
    title: TT('omp_layout::left::left_05'),
    title_mo: TT('omp_layout::left::left_07'),
    key: 'review',
    url: 'review/list?is_best=true&is_all=false',
  },
  ...(TT.lang == 'kr'
    ? { know_how: { title: TT('omp_layout::left::left_06'), key: 'content', url: 'custom/list' } }
    : {}),
});

export const OMP_OG = () => {
  /* TODO 카테고리 하드코딩 */
  /* TODO 카테고리 분리 */
  return {
    home: {
      title: TT('omp_title::home'),
      og_title: TT('omp_og::title::home'),
      og_description: TT('omp_og::description::home'),
      og_image:
        'https://s3.marpple.co/files/u_2947328/2023/11/original/0031e406dd0675ab933fd43d9a916e6f82da7a511.png',
    },
    cate_list_id_9: {
      title: TT('omp_title::apparel'),
      og_title: TT('omp_og::title::apparel'),
      og_description: TT('omp_og::description::apparel'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/a3cd423efc8ed2971fbc4bb535c1840181118b5f7.png',
    },
    cate_list_id_188: {
      title: TT('omp_title::goods'),
      og_title: TT('omp_og::title::goods'),
      og_description: TT('omp_og::description::goods'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/281c7f695a1695dc805dfaf1592ece0a3f973d771.png',
    },
    cate_list_id_113: {
      title: TT('omp_title::sports'),
      og_title: TT('omp_og::title::sports'),
      og_description: TT('omp_og::description::sports'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/cd5602936f2d5c677a503999f44ee27190c5c0358.png',
    },
    cate_list_id_189: {
      title: TT('omp_title::Cushion'),
      og_title: TT('omp_og::title::Cushion'),
      og_description: TT('omp_og::description::Cushion'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/d5516f4b60b795fc69ee4bfc779f078006b064f71.png',
    },
    cate_list_id_12: {
      title: TT('omp_title::electronics'),
      og_title: TT('omp_og::title::electronics'),
      og_description: TT('omp_og::description::electronics'),
      og_image:
        'https://s3.marpple.co/files/u_2947328/2023/11/original/9d0cd3afbf9e99549db7821d0b29ddd1417eb7111.png',
    },
    cate_list_id_10: {
      title: TT('omp_title::accessories'),
      og_title: TT('omp_og::title::accessories'),
      og_description: TT('omp_og::description::accessories'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/8fa25c55c4002d53176eae5450419d8d72dd7c5e1.png',
    },
    cate_list_id_11: {
      title: TT('omp_title::home_deco'),
      og_title: TT('omp_og::title::home_deco'),
      og_description: TT('omp_og::description::home_deco'),
      og_image:
        'https://s3.marpple.co/files/u_2947328/2023/11/original/64af6c165b339e316a5d79b80724f5d62419108e1.png',
    },
    cate_list_id_84: {
      title: TT('omp_title::sticker'),
      og_title: TT('omp_og::title::sticker'),
      og_description: TT('omp_og::description::sticker'),
      og_image:
        'https://s3.marpple.co/files/u_2947328/2023/11/original/59df86a15bfe2a17356ec2e0c3dbe0de13ceb8b01.png',
    },
    cate_list_id_82: {
      title: TT('omp_title::frames'),
      og_title: TT('omp_og::title::frames'),
      og_description: TT('omp_og::description::frames'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/fab9da5e96ef6ac72e9c6c0c7152cda6de9f38965.png',
    },
    cate_list_id_14: {
      title: TT('omp_title::stationery'),
      og_title: TT('omp_og::title::stationery'),
      og_description: TT('omp_og::description::stationery'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/3b6257018879f90180dcdabafcf4097f65e8f16314.png',
    },
    cate_list_id_13: {
      title: TT('omp_title::kids'),
      og_title: TT('omp_og::title::kids'),
      og_description: TT('omp_og::description::kids'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/6a63758200bf24d9f77cce6cb9eed6a82b6d8bec1.png',
    },
    cate_list_id_190: {
      title: TT('omp_title::pet'),
      og_title: TT('omp_og::title::pet'),
      og_description: TT('omp_og::description::pet'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/db99e33aab98afe9139fdf96ab9162342a62b4361.png',
    },
    cate_list_id_191: {
      title: TT('omp_title::digital'),
      og_title: TT('omp_og::title::digital'),
      og_description: TT('omp_og::description::digital'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/10/original/b3257bf27bb522e78419c4b5b6e6613df963bf761.png',
    },
    new: {
      title: TT('omp_title::new'),
      og_title: TT('omp_og::title::new'),
      og_description: TT('omp_og::description::new'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/056683001ad8fa4be63fa706dcf8d292bf6b20454.png',
    },
    best: {
      title: TT('omp_title::best'),
      og_title: TT('omp_og::title::best'),
      og_description: TT('omp_og::description::best'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/046271a7a53646b2f76e0ba8e9811fc703e80d012.png',
    },
    review: {
      title: TT('omp_title::review'),
      og_title: TT('omp_og::title::review'),
      og_description: TT('omp_og::description::review'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/97b2dddf82b67c859111520954386ff95e76cd455.png',
    },
    about: {
      title: TT('omp_title::about'),
      og_title: TT('omp_og::title::about'),
      og_description: TT('omp_og::description::about'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/54887dbae32224fb30f403e71b95f0a5640fcc971.png',
    },
    biz: {
      title: TT('omp_title::biz'),
      og_title: TT('omp_og::title::biz'),
      og_description: TT('omp_og::description::biz'),
      og_image:
        'https://s3.marpple.co/files/u_2659171/2023/10/original/c70c02707cf8b8adfd366cd556efab4ab79f47291.png',
    },
    group: {
      title: TT('omp_title::group'),
      og_title: TT('omp_og::title::group'),
      og_description: TT('omp_og::description::group'),
      og_image:
        'https://s3.marpple.co/files/u_218933/2023/8/original/ddb01b707c07efdfee725b8fb54515db68911fbd1.png',
    },
    group_biz: {
      title: TT('omp_title::group_biz'),
      og_title: TT('omp_og::title::group_biz'),
      og_description: TT('omp_og::description::group_biz'),
      og_image:
        'https://s3.marpple.co/files/u_2659202/2023/10/original/e9bd2ffdf9a43d61871b0acb29ed5f37a372c0891.png',
    },
    search: {
      title: TT('omp_title::search'),
    },
    my_page: {
      title: TT('omp_title::my_page'),
    },
    login: {
      title: TT('omp_title::login'),
    },
    sign_up: {
      title: TT('omp_title::sign_up'),
    },
    cart: {
      title: TT('omp_title::cart'),
    },
    wish: {
      title: TT('omp_title::wish'),
    },
    my_info: {
      title: TT('omp_title::my_info'),
    },
    my_review: {
      title: TT('omp_title::my_review'),
    },
    order: {
      title: TT('omp_title::order'),
    },
    order_detail: {
      title: TT('omp_title::order_detail'),
    },
    help: {
      title: TT('omp_title::help'),
    },
    event: {
      title: TT('omp_title::event'),
    },
    point: {
      title: TT('omp_title::point'),
    },
    know_how: {
      title: TT('omp_title::know_how'),
    },
    etc: {
      title: TT('omp_title::etc'),
      og_title: TT('omp_og::title::etc'),
      og_description: TT('omp_og::description::etc'),
      og_image:
        'https://s3.marpple.co/files/u_2947328/2023/11/original/0031e406dd0675ab933fd43d9a916e6f82da7a511.png',
    },
  };
};
