import axios from 'axios';
import {
  $closest,
  $delegate,
  $find,
  $findAll,
  $on,
  $qs,
  $removeAttr,
  $setAttr,
  $trigger,
  $val,
} from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { ComponentsInputF } from '../../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { ComponentsInputConstantS } from '../../../../../Components/Input/S/Constant/module/ComponentsInputConstantS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppKeywordAutoCompleteF } from '../../AutoComplete/F/Function/module/MShopAppKeywordAutoCompleteF.js';
import { MShopAppKeywordAutoCompleteS } from '../../AutoComplete/S/Function/module/MShopAppKeywordAutoCompleteS.js';
import { MShopAppKeywordS } from '../../S/Function/module/MShopAppKeywordS.js';
import { MShopAppKeywordF } from './module/MShopAppKeywordF.js';

export const events = async (tab_el$) => {
  // TODO @kjj axios cancel token
  const source = axios.CancelToken.source();

  const onDebounceInput = async (q) => {
    const query = (q || '').trim();
    const auto_complete_list =
      query.length < 2 ? [] : await MShopAppKeywordAutoCompleteS.getAutoCompleteList(query, source);
    MShopAppKeywordAutoCompleteF.setAutoCompleteHtml(auto_complete_list, query, tab_el$);
  };

  const onDebounceInputEvent = async (e) => {
    const q = ($val(e.target) || '').trim();
    await onDebounceInput(q);
  };

  const $keyword_container = $qs('#keyword-container', tab_el$);
  const $search_btn = $find('.keyword-container__btn_search', $keyword_container);
  const $input = $find('.keyword-container__input_el', $keyword_container);
  const triggerSearch = (query) => {
    const q = (query || '').trim();
    if (!q) {
      return;
    }
    $input.value = q;

    const uri = MShopAppKeywordS.getSearchAllUri(q);
    if (MShopUtilF.isApp()) {
      MShopUtilF.postMessage({
        navigate: {
          sel: 'TabNavigation.SearchStack.SearchScreen',
          params: { uri, q },
        },
      });
      MShopAppKeywordAutoCompleteF.flushAutoCompleteHtml(tab_el$);
    } else {
      $trigger('click', $search_btn);
    }
  };

  MShopAppKeywordAutoCompleteF.delegateAutoCompleteKeyDown(
    triggerSearch,
    '.keyword-container__input_el',
    $keyword_container,
  );
  MShopAppKeywordF.delegateKeywordContainer($keyword_container);
  go(
    $input,
    tap($on('debounce-input', onDebounceInputEvent)),
    ComponentsInputF.makeDebounceInput,
    ComponentsInputF.focusToInput,
  );

  if (MShopUtilF.isMobile()) {
    // 뒤로 가기 했을 떄 검색어 자동완성 리스트가 남아있는 현상 해결
    $on('pagehide', (e) => {
      if (e.persisted) {
        const is_keywords_result_page = /@\/keywords\/(all|sellers|products|contents)$/.test(
          location.pathname,
        );
        if (is_keywords_result_page) {
          MShopAppKeywordAutoCompleteF.setAutoCompleteHtml([], '', tab_el$);
        }
      }
    })(window);
    $on('onpageshow', (e) => {
      if (e.persisted) {
        const params = new URLSearchParams(location.search);
        $input.value = params.get('q');
      }
    })(window);
  }

  if (!MShopUtilF.isMobile()) {
    $on('keydown', (e) => {
      if (e.code === ComponentsInputConstantS.KEY_CODE.ESC) {
        MShopAppKeywordAutoCompleteF.hideAutoCompleteList(tab_el$);
      }
    })(document);
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handlePostMessage = async (e) => {
    if (!isJsonString(e?.data)) {
      return;
    }

    const parsed = JSON.parse(e.data);
    if (parsed?.q) {
      await onDebounceInput(parsed.q);
    }
    if (parsed?.type === 'FlushAutoComplete') {
      MShopAppKeywordAutoCompleteF.hideAutoCompleteList(tab_el$);
    }
  };

  if (MShopUtilF.isApp()) {
    $on('message', handlePostMessage)(window);
    $on('message', handlePostMessage)(document);
  }

  return go(
    tab_el$,
    $delegate('click', '.auto-complete__item', ({ currentTarget }) => {
      const { name } = currentTarget.dataset;
      const $keyword_container = $closest('#keyword-container', currentTarget);
      const $input = $find('.keyword-container__input_el', $keyword_container);
      $input.value = name;

      if (MShopUtilF.isApp()) {
        MShopAppKeywordF.navigateToKeywordsResult(name, { disable_action_none_app: true });
      } else {
        $trigger('click', $search_btn);
      }
      MShopAppKeywordAutoCompleteF.flushAutoCompleteHtml(tab_el$);
    }),
    $delegate('mouseover', '.auto-complete__item', ({ currentTarget }) => {
      const $list = $closest('.auto-complete__list', currentTarget);
      const $items = $findAll('.auto-complete__item', $list);
      const $cur_focused = MShopAppKeywordAutoCompleteF.findFocusedItem($items);
      if ($cur_focused) {
        $removeAttr('selected', $cur_focused);
      }
      $setAttr({ selected: true }, currentTarget);
    }),
    $delegate('click', '.app-search__view-more', ({ currentTarget }) => {
      const { link } = currentTarget.dataset;
      location.href = link;
    }),
  );
};
