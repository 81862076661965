import { defaults, equals2, go } from 'fxjs/es';
import { SVGEditorMobileImageUploadF } from '../../../MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { SVGEditorUploadImageF } from './module/SVGEditorUploadImageF.js';
import axios from 'axios';
import { $on } from 'fxdom/es';

export const uploadSvg = async (svg_file) => {
  const formData = new FormData();
  formData.append('files[]', svg_file);
  return go(
    $.upload(formData, {
      url: '/@fileUpload/svg_images_images/svg_upload',
      data: {},
    }),
    (result) => {
      return result;
    },
  );
};

export const uploadPng = async (png_file) => {
  const formData = new FormData();
  formData.append('files[]', png_file);
  return go(
    $.upload(formData, {
      url: '/@fileUpload/maker/add_img',
      data: { type: 'svg' },
    }),
    (result) => {
      return result;
    },
  );
};

export const uploadSvgFilePngFileToImages = ({ svg_file, png_file }) => {
  return go(
    Promise.all([uploadSvg(svg_file), uploadPng(png_file)]),
    async ([{ svg_image_id, url }, { result, image }]) => {
      if (!result || !svg_image_id) {
        return $.post('/@api/svg_images_images/svg_upload_fail', { svg_image_id, image_id: image.id });
      }
      await $.post('/@api/svg_images_images/svg_upload_success', { svg_image_id, image_id: image.id });
      return { image, svg_image: { id: svg_image_id, url } };
    },
  );
};
export const uploadSvgProductMaterialFiles = async ({
  original_svg_file_url,
  printable_file,
  original_cutting_line_svg_file,
}) => {
  if (!original_svg_file_url) throw Error('original_svg_file_url 없습니다.');
  const formData = new FormData();
  formData.append('printable_file', await printable_file);
  formData.append('original_cutting_line_svg_file', await original_cutting_line_svg_file);
  return defaults(
    { original_svg_file_url },
    await $.upload(formData, {
      url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
      data: {},
    }),
  );
};

export const uploadImageFromInput = async (input_el) => {
  if (input_el.files.length <= 0) {
    input_el.value = '';
    return;
  }

  const file = input_el.files.item(0);
  if (file == null) {
    input_el.value = '';
    return;
  }

  if (file.size > 26214400) {
    input_el.value = '';
    await $.alert(T(`maker_error_alert::too_large_file`, { mb: 25 }));
    return;
  }

  if (equals2(file.type)('image/svg+xml')) {
    try {
      const { svg_file, png_file } = await SVGEditorMobileImageUploadF.processSVGFile(file);
      const { svg_image } = await SVGEditorUploadImageF.uploadSvgFilePngFileToImages({
        svg_file,
        png_file,
      });
      const res = await axios.get(svg_image.url);
      const svg_str = res.data;
      return SVGEditorMobileImageUploadF.convertSVGStrToEl(svg_str);
    } catch (error) {
      console.error(error);
      await $.alert(error?.__mp_alert_message ?? T(`maker_error_alert::default_msg`));
      return;
    } finally {
      input_el.value = '';
    }
  }

  if (new Set(['image/jpeg', 'image/png']).has(file.type)) {
    try {
      const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
        const file_reader = new FileReader();
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.addEventListener('error', reject);
        file_reader.readAsDataURL(file);
      });
      const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
        const img_el = new Image();
        img_el.onload = () => resolve(img_el);
        img_el.onerror = reject;
        img_el.src = data_url;
      });
      const min_img_size = 500;
      if (img_el.width < min_img_size && img_el.height < min_img_size) {
        await $.alert(
          T(`maker_error_alert::too_small_img`, {
            px: '500',
          }),
        );
        return;
      }
      const max_img_size = 8000;
      if (img_el.width > max_img_size || img_el.height > max_img_size) {
        await $.alert(
          T(`maker_error_alert::too_large_img`, {
            px: '8,000',
          }),
        );
        return;
      }
      const max_img_ios_size = 16777216;
      if (img_el.width * img_el.height > max_img_ios_size) {
        await $.alert(
          T(`maker_error_alert::too_large_img2`, {
            px: `16,777,216`,
          }),
        );
        return;
      }

      const res = await $.upload(input_el, {
        url: '/@fileUpload/maker/add_img',
        data: {},
      });
      if (res?.result === false) {
        await $.alert(res?.msg ?? T(`maker_error_alert::fail_upload_img`));
        return;
      }
      const url = res?.image?.url;
      if (url == null) {
        await $.alert(`업로드한 url 을 찾을 수 없습니다.`);
        return;
      }
      return SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(url));
    } catch (error) {
      console.error(error);
      await $.alert(error?.__mp_alert_message ?? T(`maker_error_alert::default_msg`));
      return;
    } finally {
      input_el.value = '';
    }
  }

  await $.alert('잘못된 파일 형식입니다.');
};

export const uploadImage = ({ postProcess }) => {
  const input_el = document.createElement('input');
  input_el.type = 'file';
  input_el.accept = 'image/svg+xml,image/png,image/jpeg';
  input_el.click();
  $on('change', async ({ currentTarget: input_el }) => {
    $.don_loader_start();
    try {
      const el = await uploadImageFromInput(input_el);
      if (el) {
        await postProcess(el);
      }
    } catch (error) {
      console.error(error);
      return $.alert(error?.__mp_alert_message ?? '이미지를 그리는 데 실패했습니다.');
    } finally {
      $.don_loader_end();
    }
  })(input_el);
};

export const openPcMyLibrary = async ({ postProcess }) =>
  $.frame.open(
    {
      is_modal: true,
      frame_name: 'maker.my_lib',
      page_name: 'maker_my_lib_page',
      loader: true,
      appending: (frame_el) => {
        $.attr('is_fixed_head', 'true')(frame_el);
      },
      closing: async function (don_frame, image) {
        if (!image) return;
        $.don_loader_start();
        try {
          if (image.type === 'svg') {
            const url = await SVGEditorUploadImageF.makeImageIdToSvgUrl(image.id);
            const res = await axios.get(url);
            const svg_str = res.data;
            const el = await SVGEditorMobileImageUploadF.convertSVGStrToEl(svg_str);
            await postProcess(el);
          } else {
            const el = await SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(image.url));
            await postProcess(el);
          }
        } catch (error) {
          console.error(error);
          await $.alert(error?.__mp_alert_message ?? '이미지를 그리는 데 실패했습니다.');
        } finally {
          $.don_loader_end();
        }
      },
    },
    {
      page_name: 'maker_my_lib_page',
      hide_frame_button_type: 'X',
    },
  );
