import { html, isEmpty, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

export const searchHistory = (search_queries) => {
  if (!search_queries || isEmpty(search_queries)) {
    return emptySearchHistory();
  }

  return html`
    <div class="search-history__header">
      <span class="search-history__title">${T('mshop::최근 검색어')}</span>
      <button class="search-history__delete-all-btn">${T('Remove All')}</button>
    </div>
    <div class="search-history__items">
      ${strMap((q) => {
        const query = UtilS.escape(q);
        const uri = `/${T.lang}/@/keywords/all?q=${query}`;
        return html` <div class="search-history__item">
          <a class="search-history__link" data-q="${query}" data-uri="${uri}"><span>${query}</span></a>
          <button class="search-history__delete-btn"></button>
        </div>`;
      }, search_queries)}
    </div>
  `;
};

const emptySearchHistory = () => {
  return html`
    <div class="search-history__header">
      <span class="search-history__title">${T('mshop::최근 검색어')}</span>
    </div>
    <div class="search-history__no-result">${T('mshop::최근 검색어 내역이 없습니다.')}</div>
  `;
};
