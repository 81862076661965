import { compact, each, filter, find, flatMap, go, map, pluck } from 'fxjs/es';
import { $addClass, $closest, $find, $findAll, $qs, $removeClass } from 'fxdom/es';
import { BpOptionF } from './module/BpOptionF.js';
import { getBaseProductInMaker, getProductColorInMaker } from '../../../Maker/F/getSth.js';
import { BpOptionConstantS } from '../../S/Constant/module/BpOptionConstantS.js';
import { NewMakerCvObjActionF } from '../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { NewMakerMaskingTapeF } from '../../../NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { makeBpcfAvgSize } from '../../../Maker/F/canvas_size.js';
import { makeFakeMpMakerSize } from '../../../Maker/F/fake_mp_maker.js';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BpOptionKeyRingConstantS } from '../../KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { stickerChangePreview } from '../../../SVGEditor/ProductDetail/F/Function/fs.js';
import { changeBpByBpidBasic } from '../../../Maker/F/change_bp.js';
import { NewMakerBpOptionF } from '../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';

export const selectBpOption = ({ bp_option_group_el, selected_bp_option_id }) => {
  go(
    bp_option_group_el,
    $findAll('.select_box .option'),
    each($removeClass('selected')),
    find((el) => {
      return el.dataset.id === selected_bp_option_id;
    }),
    $addClass('selected'),
  );
  go(bp_option_group_el, $find('select'), (select_el) => {
    select_el.value = selected_bp_option_id;
  });
};

export const changeNotVirtualSelect = async (select_el) => {
  const bp_option_groups_el = $closest('.bp_option_groups')(select_el);
  const bp_option_group_el = $closest('.bp_option_group')(select_el);
  const selected_bp_option_id = select_el.value;
  BpOptionF.selectBpOption({ bp_option_group_el, selected_bp_option_id });

  const product = getProductColorInMaker();
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  product._.selected_option_group = selected_option_group;
  if (getBaseProductInMaker().maker_type == BpOptionConstantS.MASKING_TAPE_EDITOR) {
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
    await NewMakerMaskingTapeF.resetCloneHorizontalCvObj();
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
  } else if (getBaseProductInMaker().maker_type == VectorEditorConstantS.KEYRING_EDITOR) {
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    if (
      UtilF.isLegacyMobile() &&
      product._.selected_option_group.bp_option_ids.includes(
        BpOptionKeyRingConstantS.KEYRING_NO_GORI_BP_OPTION_ID,
      )
    ) {
      G.mp.maker.bpcf_size = {
        top: 207.12209302325581,
        left: 132.99418604651163,
        height: 239.38953488372093,
        bottom: 220.63953488372093,
        width: 110.75581395348837,
      };
    }
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
  } else if (getBaseProductInMaker().maker_type == VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
    const preview_metas = go(
      box().maker.product_color._.base_product._.base_products_bp_option_groups,
      flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
      filter(({ id }) => selected_option_group.bp_option_ids.includes(id)),
      pluck('preview_meta'),
      compact,
    );
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      if (preview_metas?.length) {
        await NewMakerBpOptionF.addPreviewImage({ preview_metas });
      }
    }
  } else {
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      await stickerChangePreview(bp_option_groups_el);
    }
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
  }
  const base_products_bp_option_groups =
    box().maker.product_color._.base_product._.base_products_bp_option_groups;
  await BpOptionF.makeSelectedOptionPriceC2323({
    base_products_bp_option_groups,
    bp_option_groups_el,
    selected_option_group,
  });
  if (BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(base_products_bp_option_groups)) {
    const mp_maker_el = $qs('#maker_frame');
    mp_maker_el.dataset.maker_upload_type = BpOptionUploadTypeS.UPLOAD_TYPE.getUploadType(
      base_products_bp_option_groups,
      selected_option_group?.bp_option_ids,
    );
  }
  G.mp.maker.reset_box_data();
};

export const changeVirtualSelect = async (bp_option_groups_el) => {
  await go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_virtual="true"] select'),
    map((select_el) => select_el.value),
    (bp_virtual_option_ids) =>
      $.post(`/@api/svg_editor/redirect_bp_id`, {
        bp_virtual_option_ids,
      }),
    async (data) => {
      if (!data?.base_product_id) throw new Error('network_error');
      if ($qs('#dream_factory') || $qs('.don_frame[frame_name="maker.design_collection"]')) {
        $.don_loader_start();
        await changeBpByBpidBasic(data.base_product_id);
        $.don_loader_end();
      } else {
        if (window.location.search?.includes('lang_col')) {
          const params = new URLSearchParams(location.search);
          params.set('bp_id', data.base_product_id);
          params.set('lang_col', T.lang);
          window.location.search = `?${params.toString()}`;
        } else {
          const params = new URLSearchParams(location.search);
          params.set('bp_id', data.base_product_id);
          params.delete('pc_id');
          window.location.search = `?${params.toString()}`;
        }
      }
    },
  );
};
