import { $delegate } from 'fxdom/es';
import { delay, pipe, html } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { m_image_pattern_tab } from '../CvPattern/frame.js';
import { changeCvTextImageToCvTextForTextingA, editOldCvTextText } from '../text.js';
import { getFonts, rejectFonts } from '../util.js';
import {
  eventChangeFontFamily,
  findFontFamily,
  getCvTextImage,
  getFontColors,
  makeCharSpacingFunctionEvent,
  makeCvTextImageColorItemEvent,
  makeItProperCvTextImage,
  makeLineHeightFunctionEvent,
  makeSelectFontAndStyleEvent,
} from './fs.js';
import { colorTmplPc, makeCvTextImageMobileHtml, makeFontChangeHtml } from './tmpl.js';
import { NewMakerRangeSliderF } from '../../../NewMaker/RangeSlider/F/Function/module/NewMakerRangeSliderF.js';
import { SVGEditorUtilF } from '../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';

const m_text_char_page_event = pipe(
  $delegate(
    'click',
    '.select_font',
    errorHandlingEventWrapper(function () {
      if (!G.mp.maker.active()) return $.frame.close();
      $.frame.add_page({
        page_name: 'm_text_font_change',
      });
    }),
  ),
  $delegate(
    'click',
    '.text_editor_ev .style button:not(.selected)',
    errorHandlingEventWrapper(makeSelectFontAndStyleEvent),
  ),
  makeCharSpacingFunctionEvent,
  makeLineHeightFunctionEvent,
);

const m_text_color_page_event = pipe(
  $delegate(
    'click',
    '.maker-color-picker .maker-color-picker__item:not(.selected)',
    errorHandlingEventWrapper(function (e) {
      if (!G.mp.maker.active()) return $.frame.close();
      return makeCvTextImageColorItemEvent(e);
    }),
  ),
  $.on('click', '.dosu-colors .color-picker', OMPDosuF.handleDosuColorPicker.text),
);

const m_text_font_change_event = $.on3(
  'click',
  '.m_text_font_swiper_container .option.font',
  errorHandlingEventWrapper(function (e) {
    if (!G.mp.maker.active()) return $.frame.close();
    return eventChangeFontFamily(e);
  }),
);

$.frame.defn_frame({
  frame_name: 'm_keyboard',
  page_name: 'm_keyboard_page',
  animation: false,
  el_class: 'm_keyboard_frame',
  prev_frame_show: true,
  height: $.height(window),
  always_remove: true,
});

$.frame.defn_page({
  page_name: 'm_keyboard_page',
  tabs: [
    {
      tab_name: 'm_keyboard_tab',
      template: () => legacyHtml` <div></div> `,
    },
  ],
});

$.frame.defn_frame({
  frame_name: 'maker.m_text',
  el_class: 'cv_text_editor no_header',
  page_name: 'm_text_char_page',
  animation: true,
  prev_frame_show: true,
  height: 280 + G.mp.frame.v_height,
  header_height: G.mp.frame.v_height,
  hide_frame_button_type: 'V',
  always_remove: true,
  appended: (frame_el) => {
    $delegate(
      'click',
      '.tab_button',
      errorHandlingEventWrapper(function () {
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
      }),
    )(frame_el);
    SVGEditorUtilF.halfFrameClose(frame_el);
  },
  showing: function () {
    G.mp.maker.ban_frame_show();
  },
  showed: $.on_prevent_default,
  hiding: __($.off_prevent_default, function () {
    G.mp.maker.zoom.recover();
    G.mp.maker.ban_frame_hide();
  }),
});

const color_tab = {
  tab_name: 'm_text_color_page',
  el_class: 'm-image-dosu-color-picker mp-maker-padding-tab-1',
  title: T('Color'),
  template: async function () {
    if (!G.mp.maker.active()) return;
    const press_colors = await getFontColors();
    const cv_text_image = getCvTextImage(G.mp.maker.active());
    return html`
      <div class="don-tab-body-i-scroll__wrapper">
        ${OMPDosuF.dosuImageColorPickerHtml(cv_text_image)}
        ${BpOptionF.biz.get.currentFace.hasDosuColorOptions()
          ? OMPDosuF.dosuColorPickerHtml(cv_text_image)
          : press_colors?.length
          ? colorTmplPc(cv_text_image, press_colors)
          : ``}
      </div>
    `;
  },
  hided: (tab_el) => {
    tab_el.__iscroll.destroy();
  },
  showed: function (don_tab_el) {
    don_tab_el.__iscroll = new IScroll(don_tab_el, {
      mouseWheel: true,
      scrollbars: true,
      fadeScrollbars: true,
    });
    // const don_page_height = go(don_tab_el, $closest('.don_page'), $height);
    // const don_tab_header_height = go(don_tab_el, $closest('.don_page'), $find('.header'), $height);
    // const don_wrapper_height = don_page_height - don_tab_header_height;
    // const press_color_list_el = go(don_tab_el, $find('.press_color_list'));
    // const press_color_list_height = $height(press_color_list_el);
    // $setCss({ marginTop: (don_wrapper_height - press_color_list_height) / 2 }, press_color_list_el);
  },
  appended: m_text_color_page_event,
};

const font_style_tab = {
  tab_name: 'm_text_char_page',
  el_class: 'mp-maker-padding-tab-1',
  title: T('Text'),
  template: async () => {
    const cv_text_image = getCvTextImage(G.mp.maker.active());
    await getFonts();
    const proper_cv_text_image = await makeItProperCvTextImage(cv_text_image);
    const font = findFontFamily(cv_text_image);
    const cv_text = proper_cv_text_image._data.text_info;
    return makeCvTextImageMobileHtml(cv_text, font);
  },
  showed: NewMakerRangeSliderF.start,
  appended: m_text_char_page_event,
};

$.frame.defn_page({
  page_name: 'm_text_char_page',
  el_class: 'after_bar page_in_mp_maker don-tab-body-i-scroll',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [font_style_tab, color_tab, m_image_pattern_tab],
});

$.frame.defn_page({
  page_name: 'm_text_char_page2',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [font_style_tab],
});

$.frame.defn_page({
  page_name: 'm_text_char_page3',
  el_class: 'after_bar page_in_mp_maker is_embroidery_app don-tab-body-i-scroll',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [font_style_tab, color_tab],
});

$.frame.defn_page({
  page_name: 'm_text_char_page4',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [font_style_tab, m_image_pattern_tab],
});

export const openMobileKeyboardA = () => {
  if ($1('.mp_maker.cv_old_text')) {
    return editOldCvTextText();
  }
  /*이상한 상황일때---*/
  const active = G.mp.maker.active();
  if (!active) return G.mp.maker.remove_class_el_maker();
  if (active._data.cv_type !== 'cv_text_image') return G.mp.maker.unselect_all();
  if ($1('.m_keyboard_frame')) return;
  /*---이상한 상황일때*/
  $.add_class($1('html'), 'btm_menus_hide');
  return $.frame.open({
    frame_name: 'm_keyboard',
    showed: errorHandlingEventWrapper(async function () {
      G.mp.maker.ban_frame_show();
      $.add_class($1('html'), 'maker_showing_keyboard');
      anime({
        targets: $('.m_keyboard_frame .don_page'),
        opacity: 0.8,
        duration: 200,
        easing: 'linear',
      });

      await delay(10, undefined);
      $.css($1('.cv_text_hidden_text_area'), {
        opacity: 1,
      });
    }),
    appending: errorHandlingEventWrapper(async function () {
      _p.go(changeCvTextImageToCvTextForTextingA(active), function (cv_text) {});
    }),
    closed: function () {
      const active = G.mp.maker.active();
      if (!active) return;
      if (active._data.cv_type === 'cv_text') active.exitEditing();
    },
    closing: function () {
      return anime({
        targets: $('.m_keyboard_frame .don_page'),
        opacity: 0,
        duration: 300,
        easing: 'linear',
      }).finished;
    },
  });
};

$.frame.defn_page({
  page_name: 'm_text_font_change',
  animation: true,
  el_class: 'one_tab_button after_bar page_in_mp_maker',
  tabs: [
    {
      tab_name: 'm_text_font_change',
      title: T('Change Font'),
      template: pipe(
        () => getFonts(),
        rejectFonts,
        (fonts) => _p.how_many(fonts, 12),
        makeFontChangeHtml,
      ),
      showed: font_change_render,
      appended: m_text_font_change_event,
    },
  ],
});

function font_change_render(el) {
  const cv_text =
    G.mp.maker.active()._data.cv_type === 'cv_text'
      ? G.mp.maker.active()
      : G.mp.maker.active()._data.text_info;
  return _go(
    cv_text,
    _p.v('fontFamily'),
    errorHandlingEventWrapper(function (font_family) {
      return _go(
        el,
        $.find1('.option.font[font_family="' + font_family + '"]'),
        _p.tap(
          _p.add_arg($.closest('.swiper-slide')),
          function (option_font, slide) {
            return _p.index_of($('.don_page[page_name="m_text_font_change"] .swiper-slide'), slide);
          },
          function (index) {
            new Swiper('.m_text_font_swiper_container', {
              pagination: { el: '.m_text_font_swiper_pagination' },
              initialSlide: index,
            });
          },
        ),
        $.add_class('selected'),
      );
    }),
  );
}
