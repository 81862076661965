import { changeCvObjectToImgSrc } from '../../../../../modules/Maker/F/draw_product_faces.js';
import { NewMakerMenuF } from '../../../../../modules/NewMaker/Menu/F/Function/module/NewMakerMenuF.js';
import { find, go, map } from 'fxjs/es';
import { getCurrentFcanvas, getCvDesigns } from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';

(function () {
  function is_locked(obj) {
    if (!obj || !obj._data) return;
    return !obj.evented;
  }

  function render_state() {
    const count = G.mp.maker.locked_designs().length;
    const el_unlock_count = $('#maker_frame .maker_menu .unlock i');
    if (count) {
      $.add_class($1('#maker_frame'), 'has_lock_obj');
      $.show(el_unlock_count);
    } else {
      $.remove_class($1('#maker_frame'), 'has_lock_obj');
      $.hide(el_unlock_count);
    }
    $.text(el_unlock_count, count);
  }

  function locking(obj) {
    obj.selectable = false;
    obj.evented = false;
  }

  function locking_func(canvas, objs) {
    _go($.has_class($1('#maker_frame'), 'group') ? objs._objects : objs, _p.wrap_arr, _each(locking));
    G.mp.maker.unselect_all();
    if ($.has_class($1('#maker_frame'), 'pinch_mode'))
      $.remove($('#maker .pinch_menu .obj_img[selected="true"]'));
  }

  function toggle_locking(target_cv_obj, is_shift) {
    const obj = target_cv_obj;
    const is_locked = !obj.evented;
    if (is_locked) {
      obj.selectable = true;
      obj.evented = true;
      if (is_shift) G.mp.maker.add_select(obj);
      // else G.mp.maker.multiple_select(obj);
    } else {
      obj.selectable = false;
      obj.evented = false;
      G.mp.maker.reject_select(obj);
    }
    G.mp.maker.modified();
    G.mp.maker.editing_canvas().renderAll();
  }

  $.frame.defn_frame({
    frame_name: 'maker.unlock_editor',
    el_class: 'unlock_editor after_bar',
    animation: true,
    prev_frame_show: true,
    title: 'Unlock',
    always_remove: true,
    page_name: 'm_unlock_imgs_page',
    height: 113 + 48 + G.mp.frame.v_height,
    header_height: 48 + G.mp.frame.v_height,
    hide_frame_button_type: 'V',
    closing: __(function () {
      $.remove_class($1('#maker_frame'), 'unlock_mode');
      G.mp.maker.ban_frame_hide();
      // G.mp.maker.zoom.recover();
      G.mp.maker.zoom.maker_modeA();
    }),
    showing: function () {
      G.mp.maker.unselect_all();
      G.mp.maker.ban_frame_show();
      G.mp.maker.zoom.maker_modeA(
        {
          scale: 1,
          transformOrigin: `${$.width($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2}px ${
            $.height($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2
          }px 0`,
        },
        212,
      );
      // G.mp.maker.zoom.by_active1(null, 113 +48+ G.mp.frame.v_height);
      // G.mp.maker.
    },
    appending: function () {
      NewMakerMenuF.hide();
      $.add_class($1('#maker_frame'), 'unlock_mode');
    },
    appended: $.frame_close_bg,
    showed: $.on_prevent_default,
    hiding: $.off_prevent_default,
  });

  const t_obj_imgs = _p.sum(
    _p.t$(
      '\
    .obj_set.i_item[is_locked="true" cid="{{$.cid}}"]\
      .obj_img\
        img[src="{{$.url}}"]\
      .lock\
        span\
      .unlock\
        span\
    ',
    ),
  );

  function make_locked_view_obj() {
    const locked_designs = G.mp.maker.locked_designs();
    return go(
      locked_designs,
      map(async (locked_design) => ({
        url: await changeCvObjectToImgSrc(locked_design, 50),
        cid: locked_design.cid,
      })),
    );
  }

  $.frame.defn_page({
    page_name: 'm_unlock_imgs_page',
    el_class: 'm_unlock_imgs_page',
    showed: $.iscroll_horizontal_on,
    hiding: $.iscroll_off,
    tabs: [
      {
        tab_name: 'unlock_imgs',
        page_name: 'm_unlock_imgs_page',
        template: __(
          make_locked_view_obj,
          (locked_objs) => locked_objs.reverse(),
          _p.t(
            'imgs',
            '\
          .overflow_wrapper.iscroll_wrapper_horizontal\
            .obj_imgs.iscroll_ul_wrapper\
              {{_.go(imgs, ',
            t_obj_imgs,
            ')}}\
      ',
          ),
        ),
        appended: _p.pipe(
          _p.tap(function (el) {
            const obj_sets = $.find(el, '.obj_set');
            if ($.width(window) < obj_sets.length * $.outerWidth($.find1(obj_sets[0], '.obj_img'), true))
              $.css($.find1(el, '.obj_imgs'), { 'justify-content': 'initial' });
          }),
          $.on('click', '.obj_set', function (e) {
            const ct = e.$currentTarget;
            $.toggle_attr(e.$currentTarget, 'is_locked');
            const cid = $.attr(ct, 'cid');
            go(
              getCvDesigns(getCurrentFcanvas()._objects),
              find((cv_obj) => cv_obj.cid === cid),
              toggle_locking,
            );
          }),
        ),
      },
    ],
  });

  G.mp.maker.lock = {
    is_locked: is_locked,
    render_state: render_state,
    locking_func: locking_func,
    toggle_locking: toggle_locking,
  };
})();
