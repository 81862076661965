import { MPS_ADDRESS_ENEN } from '../../../../../../../modules/Creator/Address/S/Constant/address.js';
import { MP_ADDRESS_EN } from '../../../../../../../modules/Mp/Address/S/Constant/address.js';

export default {
  modules: {
    VectorEditor: {
      AcrylicFigure: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create figure shape.',
                svg_img_el_load_error: 'Failed to create figure shape.',
                svg_canvas_to_blob_error: 'Failed to create figure shape.',
                png_data_url_making_error: 'Failed to create figure shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit the acrylic shape',
                prev_button: 'Previous',
                next_button: 'Next',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_acrylic_figure: 'First, make a figure',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              no_editor_container_el: 'Unable to open figure editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_acrylic_figure_editor: 'Unable to open figure editor.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create figure shape.',
                svg_img_el_load_error: 'Failed to create figure shape.',
                svg_canvas_to_blob_error: 'Failed to create figure shape.',
                png_data_url_making_error: 'Failed to create figure shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: 'Cancel',
                done_button: 'Completed',
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                path_button_reset: 'Make new one',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'Layer',
                layer_label_group: 'Group',
                layer_label_text: 'Text',
                layer_label_shape: 'Shape',
                layer_label_image: 'Image',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_close: 'Close path',
                path_button_open: 'Open path',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                done_button: 'Completed',
              },
            },
            template: {
              cancel_button: 'Cancel',
              next_button: 'Next',
              text_menu_decorate_text: 'Decorate text',
              text_menu_open_keyboard: 'Open keyboard',
              text_menu_duplicate: 'Duplicate',
              text_menu_delete: 'Delete',
              path_menu_set_color: 'Color',
              path_menu_set_stroke: 'Border',
              path_menu_edit_path: 'Edit path',
              path_menu_duplicate: 'Duplicate',
              path_menu_delete: 'Delete',
              shape_menu_set_color: 'Color',
              shape_menu_set_stroke: 'Border',
              shape_menu_set_duplicate: 'Duplicate',
              shape_menu_set_delete: 'Delete',
              object_menu_duplicate: 'Duplicate',
              object_menu_delete: 'Delete',
              bottom_menu_image: 'Image upload',
              bottom_menu_shape: 'Shape',
              bottom_menu_path: 'Path',
              bottom_menu_text: 'Text',
              bottom_menu_group: 'Decorate',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: 'Failed to restore previous job.',
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              empty_acrylic_figure: 'First, make a figure',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create figure shape.',
                svg_img_el_load_error: 'Failed to create figure shape.',
                svg_canvas_to_blob_error: 'Failed to create figure shape.',
                png_data_url_making_error: 'Failed to create figure shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit the acrylic shape',
                prev_button: 'Previous',
                next_button: 'Next',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_acrylic_figure: 'First, make a figure',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              no_editor_container_el: 'Unable to open figure editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_acrylic_figure_editor: 'Unable to open figure editor.',
            },
          },
        },
        StandLeg: {
          CreatorPC: {
            template: {
              header_title: 'Edit acrylic stand base support',
              description: 'Reposition the acrylic stand base support.',
              minus_button: '-',
              plus_button: '+',
              size_description: 'Adjust the horizontal size of the acrylic stand base support.',
              position_description: 'Reposition the acrylic stand base support.',
              cancel_button: 'Previous',
              done_button: 'Completed',
            },
            error_message: {
              no_editor_container_el: 'Unable to connect editor.',
              no_acrylic_figure_stand_leg_editor: 'Unable to connect editor.',
              no_make_target_image_el: 'Figure image not found.',
              max_stand_leg_width_error: 'Can not increase width any longer.',
              min_stand_leg_width_error: 'Can not decrease width any longer.',
              cutting_line_calculation_error: 'Failed to calculate cutting line',
            },
          },
          Mobile: {
            template: {
              title: 'Edit acrylic stand base support',
              cancel_button: 'Previous',
              done_button: 'Completed',
              description: 'Reposition the acrylic stand base support.',
              minus_button: '-',
              plus_button: '+',
              size_description: 'width',
              position_description: 'position',
            },
            error_message: {
              max_stand_leg_width_error: 'Can not increase width any longer.',
              min_stand_leg_width_error: 'Can not decrease width any longer.',
              cutting_line_calculation_error: 'Failed to calculate cutting line',
            },
          },
          PC: {
            template: {
              header_title: 'Edit acrylic stand base support',
              description: 'Reposition the acrylic stand base support.',
              minus_button: '-',
              plus_button: '+',
              size_description: 'Adjust the width of the acrylic stand base support.',
              position_description: 'Reposition the acrylic stand base support.',
              cancel_button: 'Previous',
              done_button: 'Completed',
            },
            error_message: {
              no_editor_container_el: 'Unable to connect editor.',
              no_acrylic_figure_stand_leg_editor: 'Unable to connect editor.',
              no_make_target_image_el: 'Figure image not found.',
              max_stand_leg_width_error: 'Can not increase width any longer.',
              min_stand_leg_width_error: 'Can not decrease width any longer.',
              cutting_line_calculation_error: 'Failed to calculate cutting line',
            },
          },
        },
        template: {
          empty_template_guide: 'Create your own stand.',
        },
        message: {
          invalid_prev_acrylic_figure_svg: 'Can not recall previous jobs.',
          login_first: 'Login is required. Please log in first.',
          make_images: 'Generating a high-resolution image for output. Please wait until it is completed.',
          too_large_image: 'The figure image is too large to be created. Please make it in a PC environment.',
          output_making_error: 'Failed to create file for product production.',
          load_image_error: 'The image file could not be loaded.',
          invalid_meta_data: 'Figure data could not be found.',
        },
      },
      FreeShape: {
        Mobile: {
          template: {
            title: 'Shape',
          },
        },
        PC: {
          template: {
            title: 'Shape',
          },
        },
      },
      Keyring: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create key ring shape.',
                svg_img_el_load_error: 'Failed to create key ring shape.',
                svg_canvas_to_blob_error: 'Failed to create key ring shape.',
                png_data_url_making_error: 'Failed to create key ring shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit the acrylic shape',
                prev_button: 'Previous',
                next_button: 'Next',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              hook_cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
              no_editor_container_el: 'Unable to open key ring editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_keyring_editor: 'Unable to open key ring editor.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create key ring shape.',
                svg_img_el_load_error: 'Failed to create key ring shape.',
                svg_canvas_to_blob_error: 'Failed to create key ring shape.',
                png_data_url_making_error: 'Failed to create key ring shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: 'Cancel',
                done_button: 'Completed',
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                path_button_reset: 'Make new one',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'Layer',
                layer_label_group: 'Group',
                layer_label_text: 'Text',
                layer_label_shape: 'Shape',
                layer_label_image: 'Image',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_close: 'Close path',
                path_button_open: 'Open path',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                done_button: 'Completed',
              },
            },
            template: {
              cancel_button: 'Cancel',
              next_button: 'Next',
              text_menu_decorate_text: 'Decorate text',
              text_menu_open_keyboard: 'Open keyboard',
              text_menu_duplicate: 'Duplicate',
              text_menu_delete: 'Delete',
              path_menu_set_color: 'Color',
              path_menu_set_stroke: 'Border',
              path_menu_edit_path: 'Edit path',
              path_menu_duplicate: 'Duplicate',
              path_menu_delete: 'Delete',
              shape_menu_set_color: 'Color',
              shape_menu_set_stroke: 'Border',
              shape_menu_set_duplicate: 'Duplicate',
              shape_menu_set_delete: 'Delete',
              object_menu_duplicate: 'Duplicate',
              object_menu_delete: 'Delete',
              bottom_menu_image: 'Image upload',
              bottom_menu_shape: 'Shape',
              bottom_menu_path: 'Path',
              bottom_menu_text: 'Text',
              bottom_menu_group: 'Decorate',
            },
            error_message: {
              reopen_prev_keyring_error: 'Failed to restore previous job.',
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the acrylic shape',
                description: 'Please edit the acrylic shape.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create key ring shape.',
                svg_img_el_load_error: 'Failed to create key ring shape.',
                svg_canvas_to_blob_error: 'Failed to create key ring shape.',
                png_data_url_making_error: 'Failed to create key ring shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit the acrylic shape',
                prev_button: 'Previous',
                next_button: 'Next',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_keyring_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create key ring image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate ring cutting line',
                cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              hook_cutting_line_making_error: 'Failed to create a ring cutting line. Please try again.',
              no_editor_container_el: 'Unable to open key ring editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_keyring_editor: 'Unable to open key ring editor.',
            },
          },
        },
        Grid: {
          CreatorPC: {
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close open dots',
                path_button_open: 'Open closed point',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_calculation_error: 'Failed in calculating cutting line',
              hook_cutting_line_making_error: 'Failed to create a ring cut. please try again.',
              no_editor_container_el: 'Unable to open key ring editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_keyring_editor: 'Unable to open key ring editor.',
            },
          },
          Mobile: {
            ElementLayer: {
              template: {
                header_title: 'Layer',
                label_group: 'Group',
                label_text: 'Text',
                label_shape: 'Shape',
                label_image: 'Image',
              },
            },
            PathEditor: {
              template: {
                done_button: 'Completed',
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_close: 'Close path',
                path_button_open: 'Open path',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
              },
            },
            template: {
              cancel_button: 'Cancel',
              next_button: 'Next',
              text_menu_decorate_text: 'Decorate text',
              text_menu_open_keyboard: 'Open keyboard',
              text_menu_duplicate: 'Duplicate',
              text_menu_delete: 'Delete',
              path_menu_set_color: 'Color',
              path_menu_set_stroke: 'Border',
              path_menu_edit_path: 'Edit path',
              path_menu_duplicate: 'Duplicate',
              path_menu_delete: 'Delete',
              shape_menu_set_color: 'Color',
              shape_menu_set_stroke: 'Border',
              shape_menu_set_duplicate: 'Duplicate',
              shape_menu_set_delete: 'Delete',
              object_menu_duplicate: 'Duplicate',
              object_menu_delete: 'Delete',
              bottom_menu_image: 'Image upload',
              bottom_menu_shape: 'Shape',
              bottom_menu_path: 'Path',
              bottom_menu_text: 'Text',
              bottom_menu_group: 'Decorate',
            },
            error_message: {
              reopen_prev_keyring_error: 'Failed to restore previous job.',
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              cutting_line_calculation_error: 'Failed in calculating cutting line',
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
          },
          PC: {
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close open dots',
                path_button_open: 'Open closed point',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Completed',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
            },
            alert_message: {
              empty_keyring: 'First, make a key ring',
              close_keyring_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_calculation_error: 'Failed in calculating cutting line',
              hook_cutting_line_making_error: 'Failed to create a ring cut. please try again.',
              no_editor_container_el: 'Unable to open key ring editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_keyring_editor: 'Unable to open key ring editor.',
            },
          },
        },
        Hook: {
          CreatorPC: {
            template: {
              header_title: 'Edit key ring hole',
              description: 'Select the type of key ring hole and move the location.',
              inside_button: 'Inside',
              outside_button: 'Outside',
              cancel_button: 'Previous',
              done_button: 'Completed',
            },
            error_message: {
              no_editor_container_el: 'Unable to connect editor.',
              no_keyring_hook_editor: 'Unable to connect editor.',
              no_make_target_image_el: 'Key ring image not found.',
            },
          },
          Mobile: {
            template: {
              title: 'Edit key ring hole',
              cancel_button: 'Previous',
              done_button: 'Completed',
              description: 'Select the type of key ring hole and move the location.',
              inside_button: 'Inside',
              outside_button: 'Outside',
            },
          },
          PC: {
            template: {
              header_title: 'Edit key ring hole',
              description: 'Select the type of key ring hole and move the location.',
              inside_button: 'Inside',
              outside_button: 'Outside',
              cancel_button: 'Previous',
              done_button: 'Completed',
            },
            error_message: {
              no_editor_container_el: 'Unable to connect editor.',
              no_keyring_hook_editor: 'Unable to connect editor.',
              no_make_target_image_el: 'Key ring image not found.',
            },
          },
        },
        template: {
          empty_template_guide: 'Make your own key ring.',
        },
        message: {
          invalid_editor_type: 'It is an invalid editor type.',
          invalid_prev_keyring_svg: 'Can not recall previous jobs.',
          login_first: 'Login is required. Please log in first.',
          make_images: 'Generating a high-resolution image for output. Please wait until it is completed.',
          too_large_image:
            'The key ring image is too large to be created. Please make it in a PC environment.',
          output_making_error: 'Failed to create file for product production.',
          load_image_error: 'The image file could not be loaded.',
          invalid_meta_data: 'Key ring data could not be found.',
        },
      },
      TopMenu: {
        PC: {
          template: {
            top_menu_history_reset: 'Return',
            top_menu_history_undo: 'Cancel',
            top_menu_history_redo: 'Rerun',
            top_menu_toggle_layers_mode: 'Layer',
            top_menu_duplicate: 'Duplicate',
            top_menu_delete: 'Delete',
            top_menu_forward: 'Foward',
            top_menu_backward: 'Back',
            top_menu_group: 'Group',
            top_menu_ungroup: 'Ungroup',
            top_menu_flip_horizontal: 'Reverse left and right',
            top_menu_flip_vertical: 'Reverse up and down',
            top_menu_move_to_left_end: 'Left',
            top_menu_move_to_right_end: 'Right',
            top_menu_move_to_top_end: 'Up',
            top_menu_move_to_bottom_end: 'Down',
            top_menu_move_to_horizontal_center: 'Middle',
            top_menu_move_to_vertical_center: 'Middle',
          },
        },
      },
      Sticker: {
        Single: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the sticker',
                description:
                  'Please edit the sticker to have a dotted line shape so that it can be peeled off.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
                done_button: 'Done',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create figure shape.',
                svg_img_el_load_error: 'Failed to create figure shape.',
                svg_canvas_to_blob_error: 'Failed to create figure shape.',
                png_data_url_making_error: 'Failed to create figure shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit directly',
                prev_button: '닫기',
                next_button: 'Next',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                done_button: 'Done',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Done',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
              done_button: 'Done',
            },
            alert_message: {
              empty_acrylic_figure: 'First, make a figure',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              no_editor_container_el: 'Unable to open figure editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_acrylic_figure_editor: 'Unable to open figure editor.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit sticker cutting line',
                description: 'Please edit the shape of the cut line so that the sticker can be removed.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
              },
              error_message: {
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: 'Cancel',
                done_button: 'Done',
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                path_button_reset: 'Make new one',
              },
              error_message: {
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: 'Layer',
                layer_label_group: 'Group',
                layer_label_text: 'Text',
                layer_label_shape: 'Shape',
                layer_label_image: 'Image',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: 'Add straight line',
                path_button_add_c: 'Add curve',
                path_button_remove: 'Delete point',
                path_button_close: 'Close path',
                path_button_open: 'Open path',
                path_button_change_to_l: 'Change straight line',
                path_button_change_to_c: 'Change curve',
                done_button: 'Done',
              },
            },
            template: {
              cancel_button: 'Cancel',
              next_button: 'Next',
              done_button: 'Done',
              text_menu_decorate_text: 'Decorate text',
              text_menu_open_keyboard: 'Open keyboard',
              text_menu_duplicate: 'Duplicate',
              text_menu_delete: 'Delete',
              path_menu_set_color: 'Color',
              path_menu_set_stroke: 'Border',
              path_menu_edit_path: 'Edit path',
              path_menu_duplicate: 'Duplicate',
              path_menu_delete: 'Delete',
              shape_menu_set_color: 'Color',
              shape_menu_set_stroke: 'Border',
              shape_menu_set_duplicate: 'Duplicate',
              shape_menu_set_delete: 'Delete',
              object_menu_duplicate: 'Duplicate',
              object_menu_delete: 'Delete',
              object_menu_background: 'Backgorund',
              bottom_menu_image: 'Image upload',
              bottom_menu_shape: 'Shape',
              bottom_menu_path: 'Path',
              bottom_menu_text: 'Text',
              bottom_menu_group: 'Decorate',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: 'Failed to restore previous job.',
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: 'Edit the sticker',
                description:
                  'Please edit the sticker to have a dotted line shape so that it can be peeled off.',
                edit_manual_button: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
                done_button: 'Done',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                no_default_outer_cutting_line: 'Can not find basic acrylic shape.',
                svg_data_url_making_error: 'Failed to create figure shape.',
                svg_img_el_load_error: 'Failed to create figure shape.',
                svg_canvas_to_blob_error: 'Failed to create figure shape.',
                png_data_url_making_error: 'Failed to create figure shape.',
                max_cutting_line_offset_error: 'Can not increase offset any longer.',
                min_cutting_line_offset_error: 'Can not decrease offset any longer.',
                manual_cutting_line_error: 'Can not directly edit acrylic shapes.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: 'Edit directly',
                prev_button: 'Previous',
                next_button: 'Next',
                done_button: 'Done',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                path_button_reset: 'Start from the beginning',
              },
              error_message: {
                no_editor_container_el: 'Unable to open acrylic shape editor.',
                no_acrylic_figure_cutting_line_editor: 'Unable to open acrylic shape editor.',
                no_make_target_image_el: 'Can not create figure image to create acrylic look.',
                waiting_cutting_line_update:
                  'The inner cut line has not been reflected yet. Please check the updated inner cut line.',
                cutting_line_calculation_error: 'Failed to calculate cutting line',
                cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
                inner_cutting_line_making_error: 'Failed to calculate acrylic inner shape.',
              },
            },
            PathEditor: {
              template: {
                header_title: 'Edit path',
                path_button_group_add: 'Add',
                path_button_group_delete_close: 'Delete/Close',
                path_button_group_change: 'Change',
                path_button_add_l: 'Add straight line to selected point',
                path_button_add_c: 'Add curve to selected point',
                path_button_remove: 'Delete selected points',
                path_button_close: 'Close opened points',
                path_button_open: 'Open closed points',
                path_button_change_to_l: 'Change selected points to straight lines',
                path_button_change_to_c: 'Change selected points to curves',
                done_button: 'Done',
                prev_button: 'Close',
              },
            },
            template: {
              menu_upload_image: 'Image upload',
              menu_my_image: 'My image',
              menu_shape: 'Shape',
              menu_path: 'Path',
              menu_text: 'Text',
              layer_title: 'Layer',
              layer_label_group: 'Group',
              layer_label_text: 'Text',
              layer_label_shape: 'Shape',
              layer_label_image: 'Image',
              cancel_button: 'Cancel',
              next_button: 'Next',
              prev_button: 'Prev',
              done_button: 'Done',
            },
            alert_message: {
              empty_acrylic_figure: 'First, make a figure',
              close_acrylic_figure_editor:
                'All designs will be deleted.<br />Are you sure you want to end the editor?',
            },
            error_message: {
              cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
              no_editor_container_el: 'Unable to open figure editor.',
              no_pre_process: 'Failed to restore previous job.',
              no_acrylic_figure_editor: 'Unable to open figure editor.',
            },
          },
        },
        template: {
          empty_template_guide: 'Create your own stand.',
        },
        FreeMobile: {
          cancel: 'Cancel',
          done: 'Done',
          set_background: 'Background color',
          add_sticker: 'Add sticker',
          edit: 'Edit',
          duplicate: 'Copy',
          remove: 'Delete',
        },
        obj: {
          '배경 색상': 'Background color',
        },
        message: {
          login_first: 'Login is required. Please log in first.',
          make_images:
            'Generating a high-resolution image for output.<br />Please wait until it is completed.',
          too_large_image: 'The figure image is too large to be created. Please make it in a PC environment.',
          output_making_error: 'Failed to create file for product production.',
          load_image_error: 'The image file could not be loaded.',
          invalid_meta_data: 'Figure data could not be found.',
          fail_image: 'Failed to create an image',
          cutting_line_making_error: 'Failed to create a cutting line. Please try again.',
          cutting_line_making_error2: 'Failed to create a cutting line.',
          '현재 모든 스티커가 1개로':
            'All stickers are linked together. It cannot be completed.<br />Please make multiple stickers.',
          '스티커 에디터 안전 간격 찾을 수 없습니다.': 'Sticker editor safe gap not found.',
          '스티커 에디터 안전 영역 찾을 수 없습니다.': 'Sticker editor safe area not found.',
          '안전 간격을 계산할 수 없습니다.': '안전 간격을 계산할 수 없습니다.',
          '안전 영역을 계산할 수 없습니다.': '안전 영역을 계산할 수 없습니다.',
          '스티커 에디터 앞면 이미지를 찾을 수 없습니다.': '스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
          '스티커 영역을 계산할 수 없습니다.': '스티커 영역을 계산할 수 없습니다.',
          '잘못된 그리드 크기입니다.': '잘못된 그리드 크기입니다.',
          '잘못된 그리드입니다.': '잘못된 그리드입니다.',
          '재단선을 만들 수 없습니다.': '재단선을 만들 수 없습니다.',
          '스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.':
            'The sticker image is too large to be created. Please make it in the PC environment.',
          '스티커를 찾을 수 없습니다.': 'No stickers found.',
          '디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?':
            'All designs will be deleted.<br />Are you sure you want to quit the editor?',
          '죄송합니다. 다시 시도해 주세요.': 'Sorry. Please try again.',
          '빈 스티커입니다. 스티커를 만들어주세요 :)': 'Blank sticker.<br />Make a sticker please :)',
          '너무 가깝거나 가장자리에 있는 스티커를 움직여주세요.':
            'Stickers are too close together or on the edge.<br>Please move the sticker.',
          '복사에 실패했습니다.': 'Copy failed.',
          '스티커는 최대 20개까지 생성할 수 있습니다.': 'You can create up to 20 stickers.',
          '선택된 스티커가 없습니다.': 'There are no stickers selected.',
        },
        FreeGrid: {
          '스티커 추가하기': 'Add sticker',
          '스티커 추가': 'Add sticker',
          '기존 스티커를 덮어쓰기 하시겠습니까?': 'Do you want to overwrite the existing sticker?',
          취소: 'Cancel',
          완료: 'Done',
          편집: 'Edit',
          삭제: 'Delete',
          스티커: 'Sticker',
          '배경 설정': 'Background',
          '만든 스티커 수정이 필요하신가요?': 'Do you need any modifications to the created sticker?',
          '스티커 편집하기': 'Edit sticker',
          복제: 'Duplicate',
          '복제할 영역을 선택하고 완료해 주세요.': 'Please select the area to duplicate and complete.',
          이전: 'Prev',
          '반칸이 있습니다. 모든 빈칸을 채워주세요.':
            'There are empty spaces.<br />Please fill in all blanks.',
        },
      },
    },
  },
  mshop_cart_and_checkout: {
    구매불가: 'Unable to purchase',
    마플샵배송: 'MarppleShop Shipping',
    셀러배송: 'Creator Shipping',
    디지털상품: 'Digital Goods',
    요청사항: 'Request',
    '수량 변경': 'Quantity Change',
    배송비: 'Shipping Cost',
    무료배송: 'Free Shipping',
    도서산간비: ' additional fare for remote areas',
    이상: 'Or More',
    '구매시 무료 배송': 'Free Shipping on Purchase',
    '결제 완료 후 7일간 다운로드 가능': 'Downloadable for 7 days post-payment',
    '결제 완료 후': 'Post-payment',
    '7일간 다운로드': '7-day Download',
    가능: 'Available',
    상품금액: 'Product Price',
    주문금액: 'Order Total',
    '주문 정보': 'Order Info',
    주문정보: 'Order Info',
    '총 주문 금액': 'Total Order Amount',
    수량: 'Quantity',
    개: 'Qty',
    '총 수량': 'Total Quantity',
    '총 상품금액': 'Total Product Price',
    '총 배송비': 'Total Shipping Cost',
    '마플샵배송 도서산간': '마플샵배송 도서산간',
    '셀러배송 도서산간': '셀러배송 도서산간',
    '쿠폰 할인': 'Coupon Discount',
    '포인트 할인': 'Point Discount',
    '주문서 작성': 'Order Form',
    '구매 불가 상품이 있습니다.': `There are items that cannot be purchased.<br>Please refer to the description below for items that cannot be purchased.`,
    '상품의 옵션이 변경되었습니다. 확인후 장바구니에 다시 넣어주세요.':
      'Product options have been changed. Please check and put it back in your shopping cart.',
    '현재 가능한 재고는 개 입니다.': 'We currently have {{remaining_stock_quantity}} available in stock.',
    '선택한 옵션 또는 상품이 품절되었습니다.': 'The option or product you selected is out of stock.',
    '선택한 옵션 또는 상품의 재고가 부족하여 주문이 불가합니다. 수량을 변경해 주세요.':
      'The selected option or product is out of stock. Please change the quantity.',
    '오류가 발생했습니다.<br>새로고칩 됩니다.': 'An error occurred.<br>The page will reload.',
  },

  mshop_customer_order: {
    '배송지 변경 불가': 'Shipping address cannot be changed',
    '상품 취소 요청 접수': 'Product cancellation request',
    '상품 취소 요청': 'Product cancellation',
    '취소 상품은 다시 복구할 수 없습니다.<br>정말 주문을 취소하시겠습니까?':
      'Items cannot be restored.<br>Are you sure you want to cancel your order.',
    '취소 완료': 'Canceled',
    '확인 후 자동 취소가 완료됩니다.': 'After confirmation, automatic cancellation is complete.',
    '취소 요청': 'Cancellation request',
    '문제가 발생했습니다.': 'Problem occurred.',
    '스토어의 모든 "마플샵 배송" 상품들이 취소 됩니다.<br>계속 하시겠습니까?': `All “Marple Shop Delivery” products in the store [ {{name}} ] will be cancelled.<br>Do you want to continue?`,
  },

  mp_checkout: {
    '쿠폰이 적용 됐습니다.': 'Coupon has been applied.',
  },
  masking_tape_modal_page: {
    title: 'Preview',
    description1: 'Only the middle of the masking tape is produced with patterns like the image above. ',
    description2:
      '* If it seems something wrong, please check a part of "Decorating Patterns > Dotted area" from our guideline and then design your own masking tape again.',
    cancel: 'Check Our Guideline.',
    done: 'Continue',
  },

  composite_preview_modal_page: {
    title: 'Preview',
    description1:
      '* The uploaded design will be printed as it is, so please check the guide on the detailed page.',
    description2:
      '* Due to the difference in color mode, the screen color and the actual product may be slightly different.',
    cancel: 'Check Our Guideline.',
    done: 'Continue',
  },

  // TODO 영준 en 받으면 적용하자!
  stores_delivery_company_info: {
    '배송 방법': 'Shipping Method',
    '일반 택배': 'Regular courier service',
    '기본 배송비': 'Basic Shipping Fee',
    '도서 산간비': 'Remote Area Surcharge',
    '배송 지역': 'Delivery Area',
    전국: 'Nationwide',
    '배송 기간': 'Delivery Timeframe',
    '상품 출고 후 영업일':
      'After the product is shipped, it can be received within 1-3 business days. However, in the case of remote or mountainous areas or when the courier service has a high volume of shipments, it may take a little longer.',
    '무료 배송 조건': '무료 배송 조건',
    '제작 소요 기간': '제작 소요 기간',
    배송기간: '배송기간',
    '지금 주문하면':
      'If you place an order now, it will be shipped between &nbsp;<span class="pd-guide__start-end-strong">{{start}}&nbsp;AND&nbsp;{{end}}</span>!',
    '특별함을 담아 제작해서 배송해드려요.':
      'We will carefully craft it with special care and deliver it to you.<br />Please wait with excitement!',
    배송정보: 'Shipping Info',
  },
  plus_admin: {
    pa1: 'Creator management',
    pa2: 'Add and manage crew creators’ store.',
    pa3: 'Store',
    pa4: 'Label',
    pa5: 'Creator info.',
    pa6: 'Create a store',
    pa7: 'Refresh',
    pa8: 'Search',
    pa9: 'No label',
    pa10: 'Important Youtuber',
    pa11: 'Important Instar',
    pa12: 'Important artist',
    pa13: 'Other important',
    pa14: 'Problems and issues',
    pa15: 'Others',
    pa16: 'Bupyeong Cultural Foundation',
    pa17: 'Logo',
    pa18: 'Label',
    pa19: 'SNS',
    pa20: 'Store name',
    pa20_1: 'Filter',
    pa21: 'Created time',
    pa22: 'Crew name',
    pa23: 'Creator',
    pa24: 'Number of goods',
    pa25: 'Disclose',
    pa26: 'Hide',
    pa27: 'Introduce',
    pa28: 'Link',
    pa29: 'Studio',
    pa30: 'Edit',
    pa31: 'Editing store label failed.',
    pa32: 'Create a store',
    pa33: 'Store domain',
    pa34: 'Creator’s e-mail',
    pa35: 'Store domain is all saved in a lower case.',
    pa36: 'Add',
    pa37: 'Enter e-mail',
    pa38: 'Enter {{label}}',
    pa39: 'Enter a valid domain',
    pa40: 'Store has been created.',
    pa41: 'Creating a store failed.',
    pa42: 'Edit the store',
    pa43: 'Edit',
    pa44: 'Delete',
    pa45: 'Register',
    pa46: 'Describe a store',
    pa47: 'Commentary on the Store',
    pa48: 'Crew name',
    pa49: 'Reference URL',
    pa50: 'Attachment file',
    pa51: 'The number of goods disclosed',
    pa52: 'The number of goods hidden',
    pa53: 'Store address',
    pa54: 'Studio address',
    pa55: 'HOT creator',
    pa56: 'Store thumbnail',
    pa57: 'Thumbnail upload',
    pa58: 'Shop operation hold',
    pa59: 'Breach of the internal policy of Marpple Shop',
    pa60: 'Sales approval',
    pa61: 'Inviting creator',
    pa62: 'View Store',
    pa63: 'Disclose goods on the Crew Mall’s Home',
    pa64: 'Disclose Brand home',
    pa65: 'Apply for withdrawal from the Mall',
    pa66: 'Tattoo sales',
    pa67: 'Yes',
    pa67_1: 'No',
    pa68: 'Rejected.',
    pa69: 'Will you really delete the creator of the store?',
    pa70: 'Will you really delete the store?',
    pa71: 'Thumbnail upload failed.',
    pa72: 'Thumbnail upload succeeded',
    pa73: 'Thumbnail deletion failed',
    pa74: 'Thumbnail deletion succeeded',
    pa75: 'Editing failed',
    pa76: 'Edition succeeded',
    pa77: 'Edit the name of the store',
    pa78: 'Enter the name of the store.',
    pa79: 'Inactive creator management',
    pa80: 'Check inactive creator stores and launch the operation.',
    pa81: 'Store ID',
    pa82: 'Label',
    pa83: 'SNS',
    pa84: 'Created date',
    pa85: 'Unapproved store',
    pa86: 'File',
    pa87: 'No name',
    pa88: 'Sales approval',
    pa89: 'Sales approval failed',
    pa90: 'Finally rejected.',
    pa91: 'Final rejection failed.',
    pa92: 'Invited creator',
    pa93: 'Invited creator',
    pa94: 'No creator connected',
    pa95: 'Unregistered store',
    pa96: 'Rejected store',
    pa97: 'Creator’s sales status',
    pa98: 'You can check the sales status and profits of the creator.',
    pa98_1: '셀러 상품의 판매 현황을 확인할 수 있습니다.',
    pa99: '{{domain}} crew not found.',
    pa100: 'Weekly',
    pa101: 'Monthly',
    pa102: 'Yearly',
    pa103: 'All',
    pa104: 'Crew',
    pa105: 'The number of orders',
    pa106: 'Cancel',
    pa107: 'Qty',
    pa108: 'consumer selling price ({{unit}})',
    pa111: 'Design profits',
    pa112: 'Additional design profits',
    pa113: 'Total design profits',
    pa114: 'Creator’s design profits',
    pa115: 'Design profits of corporation',
    pa116: 'Discount profits of corporation',
    pa117: 'Total profits of corporation',
    pa118: 'Creator withdrawal',
    pa119: 'Check the requested withdrawal amount for the creator’ profit, and proceed with settlement.',
    pa120: 'Store',
    pa121: 'Amount',
    pa122: 'Actual amount received',
    pa123: 'Account info.',
    pa124: 'Processing status',
    pa125: 'Requested date and time',
    pa126: 'Processed date and time',
    pa127: 'Cancelled date and time',
    pa128: 'Expected money receiving date',
    pa129: 'Function',
    pa130: 'Processing status',
    pa131: 'Processing',
    pa132: 'Approve',
    pa133: 'Cancel',
    pa134: 'Verification (Y/N)',
    pa135: 'Verified withdrawal',
    pa136: 'Unverified withdrawal',
    pa137: 'Bank account type',
    pa138: 'Individual',
    pa139: 'under the age of 19',
    pa140: 'Entrepreneur',
    pa141: 'Overseas resident',
    pa142: 'Excel download',
    pa143: 'Change dates',
    pa144: 'Approve',
    pa145: 'Send money',
    pa146: 'Will you really reject the money withdrawal?',
    pa147: 'No money withdrawal selected.',
    pa148: 'Money withdrawal has been requested.',
    pa149: 'Server errors.',
    pa150: 'Check if the tax invoice has been issued before proceeding!',
    pa151: 'Lock the bank account info. change',
    pa152: 'Bank account info.',
    pa153: 'Store name',
    pa154: 'Type',
    pa155: 'Amount to be sent',
    pa156: 'Bank name',
    pa157: 'PayPal account',
    pa158: 'Bank account number',
    pa159: 'A captured copy of PayPal account',
    pa160: 'A copy of bankbook',
    pa161: 'Personal information',
    pa162: 'Name',
    pa163: 'Nationality',
    pa164: 'Mobile phone',
    pa165: 'A copy of ID card',
    pa166: 'Consent to the provision of information (Y/N)',
    pa167: 'Agree',
    pa168: 'Disagree',
    pa169: 'Company info.',
    pa170: 'Name of company',
    pa171: 'Name of CEO',
    pa172: 'Business registration number',
    pa173: 'e-mail of the person in charge',
    pa174: 'Address of the business site',
    pa175: 'Business category, Business type',
    pa176: 'A copy of business registration certificate',
    pa177: 'Personal information',
    pa178: 'Legal representative info.',
    pa179: 'Birth date of the legal representative',
    pa180: 'Name of the legal representative',
    pa181: 'Legal representative’s consent',
    pa182: 'Won (Korea)',
    pa183: 'Dollar',
    pa184: 'Actual amount received',
    pa185: 'Reject',
    pa186: 'PayPal e-mail',
    pa187: 'Account number',
    pa188: 'Resident registration number',
    pa189:
      'All the required bank account info. hasn’t been entered. Enter the information on the bank account info page.',
    pa190:
      'After checking if the tax invoice has been issued, Marpple send the money. You have to issue tax invoice first before requesting money withdrawal. Will you continue?',
    pa191:
      'Wait! Did you accurately enter the account info. in the registration? If the information is wrong, the request for the money withdrawal will be cancelled, with the money carried forward to the next month.',
    pa192: 'The requested amount is exceeding the settlement money. Click “Ok” to continue.',
    pa193: 'Corporate withdrawal',
    pa194: 'You can check the amount requested for corporate withdrawal, and apply for it',
    pa195: 'Analysis on Marpple Shop',
    pa196: 'Store invitation',
    pa197: '{n} Qty',
    pa198: 'Invitation approved for sale',
    pa199: 'Approved shop open',
    pa200: 'Shops opened',
    pa201: 'Shops that created goods',
    pa202: 'Shops that haven’t created goods.',
    pa203: 'Shops not opened',
    pa204: 'Stores that haven’t created goods for more than a week after the acceptance e-mail was sent.',
    pa205: 'Average number of goods by shop',
    pa206: 'Shops that sold more than one goods.',
    pa207: 'Accepted creators',
    pa208: 'Rejected creators',
    pa209: 'Withdrawal amount',
    pa210: 'Accumulated amount of Settlement / Withdrawal',
    pa211: 'Statistics on goods manufactured by time',
    pa212: 'TOP 10 creators on the sales amount / sales units',
    pa213: 'Top 15 creators on the number of goods created.',
    pa214: 'Statistics on Creators',
    pa215: 'Category of the creator application',
    pa216: 'Weekly revenue',
    pa217: 'Statistics on the accumulated revenue',
    pa218: 'Weekly number of creator application',
    pa219: 'Statistics on the accumulated number of creator application',
    pa220: 'Weekly number of creators opened',
    pa221: 'Statistics on the accumulated number of creators opened',
    pa222: 'Weekly number of goods created',
    pa223: 'Statistics on the accumulated number of goods created',
    pa224: 'The number of creator application',
    pa225: 'The number of creators opened',
    pa226: 'The number of goods created',
    pa227: 'Revenue',
    pa228: 'Date',
    pa229: 'The number of orders',
    pa230: 'Number of creation',
    pa231: 'Actual number of creators',
    pa232: 'The number of creator application',
    pa233: 'Type by creator',
    pa234: 'Revenue by creator',
    pa235: 'Sales units by creator',
    pa236: 'Monthly withdrawal (10,000 won)',
    pa237: 'Settlement money (10,000 won)',
    pa238: 'Withdrawal amount (10,000 won)',
    pa239: 'Marpple Shop’s designer list',
    pa240: 'All',
    pa241: 'Application',
    pa242: 'Application date',
    pa243: 'Status',
    pa244: 'View Details',
    pa245: 'Marpple Shop’s designer profile',
    pa246: 'Instagram',
    pa247: 'Youtube',
    pa248: 'Others',
    pa249: 'Portfolio',
    pa250: 'Designer portfolio',
    pa251: 'The request failed.',
    pa252: 'The application for the designer has been approved.',
    pa253: 'The application for the designer has been rejected.',
    pa254: 'All goods',
    pa255: 'Disclosed goods',
    pa256: 'Hidden goods',
    pa257: 'Goods with sales holding',
    pa258: 'Inspection complete',
    pa259: 'Under inspection',
    pa260: 'All goods',
    pa261: 'Clothes',
    pa262: 'Phone case / Tech',
    pa263: 'Fashion Items',
    pa264: 'Home Deco / Living',
    pa265: 'Sticker / Paper',
    pa266: 'Frame',
    pa267: 'Stationery / Office',
    pa268: 'Digital Goods',
    pa269: 'Kids / Pet',
    pa270: 'General goods',
    pa271: 'Digital goods',
    pa272: 'No goods that needs inspection!',
    pa273: 'Sales holding',
    pa274: 'Inspections left',
    pa275: 'Passed',
    pa276:
      'If you want to check the goods having passed inspection, go to “All goods -> Inspection complete” .\n As the design change needs another inspection, there might be more inspections for one goods.',
    pa277: 'Ok',
    pa278: 'Will you allow the keyboard to work? \n the right Key [➡️] passed \n bottom Key [⬇️️] sales hold',
    pa279: 'More than 1,000 people',
    pa280: '{{number}} people',
    pa281: 'Creator name',
    pa282: 'Processing',
    pa283: 'The number of subscribers',
    pa284: 'Youtuber',
    pa285: 'Designer',
    pa286: 'Goods design inspection',
    pa287: 'Youtube sales creator',
    pa288: 'Creator Tip management',
    pa289: 'Receive reporting',
    pa290: 'Goods inspection',
    pa291: 'PB goods order',
    pa292: 'PB goods order details',
    pa293: 'PB goods list',
    pa294: 'Corporate bank account registration',
    pa295: 'Goods quality management',
    pa296: 'Corporate settlement',
    pa297: 'Site management',
    pa298: 'The number of goods that needs inspection',
    pa299: 'A shortcut to Store',
    pa300: 'A shortcut to Inspection',
    pa301: 'Price',
    pa302: 'PB goods exchange / refunds (Complete:{{settled}} / All:{{total}})',
    pa303: 'Requested refunds',
    pa304: 'Refunds complete',
    pa305: 'Requested cancellation',
    pa306: 'Cancellation complete',
    pa307: 'Invalid search_condition_key',
    pa308: 'By order date',
    pa309: 'Last 1 month',
    pa310: 'Last 3 months',
    pa311: 'Last 6 months',
    pa312: 'Last 1 year',
    pa313: 'Descending order',
    pa314: 'Ascending order',
    pa315: 'Search condition',
    pa316: 'Door-to-door delivery',
    pa317: 'Combined shipping',
    pa318: 'Quick delivery',
    pa319: 'The pick-up',
    pa320: 'Individual delivery',
    pa321: 'Refund',
    pa322: 'Exchange',
    pa323: 'Ordered date',
    pa324: 'Buyer',
    pa325: 'Payment method',
    pa326: 'Ordered goods',
    pa327: 'Summary info.',
    pa328: 'Order Details',
    pa329: 'Ordered goods ID',
    pa330: 'Goods name',
    pa331: 'The number of orders',
    pa332: 'Order amount',
    pa333: 'Delivery info.',
    pa334: 'Shipping ID',
    pa335: 'Shipping type',
    pa336: 'Receiver',
    pa337: 'Waybill',
    pa338: 'Contact',
    pa339: 'Address',
    pa340: 'Memo',
    pa341: 'Contact number 1',
    pa342: 'Contact number 2',
    pa343: 'Delivery notice for the buyer',
    pa344: 'No',
    pa345: 'Marpple Shop’s Goods code',
    pa346: 'Image',
    pa347: 'Category',
    pa348: 'Marpple Shop Studio',
    pa349: 'Option',
    pa350: 'PB goods exchange / Refunds',
    pa351: 'Goods’ ID collected back',
    pa352: 'Created date/Edited date',
    pa353: 'Status',
    pa354: 'The amount of refund',
    pa355: 'Waybill no.',
    pa356: 'Refunded goods',
    pa357: 'Goods ID (collected back / ordered)',
    pa358: 'Collection type',
    pa359: 'Ordered Goods name',
    pa360: 'Volume (collected back / ordered)',
    pa361: 'Not a refunded goods',
    pa362: 'Reason',
    pa363: 'Additional explanation',
    pa364: 'Exchanged / Refunded goods',
    pa365: 'All',
    pa366: 'Processing',
    pa367: 'Complete',
    pa368: 'Payment method',
    pa369: 'Discount the total amount',
    pa370: 'Real-time money transfer',
    pa371: 'Inicis real-time money transfer',
    pa372: 'Credit card',
    pa373: 'Samsung pay',
    pa374: 'PAYCO',
    pa375: 'Naver Pay',
    pa376: 'SSGPAY',
    pa377: 'LPAY',
    pa378: 'Alipay',
    pa379: 'Inicis credit card',
    pa380: 'Deposit without a bankbook',
    pa381: 'Offline card',
    pa382: 'Offline cash',
    pa383: 'Virtual account',
    pa384: 'Kakao Pay',
    pa385: 'Toss Pay',
    pa386:
      'Fill in the information in an accurate manner, as the profit of the corporation shall be settled with the information you entered.',
    pa387: 'PayPal e-mail',
    pa388:
      'While requesting withdrawal, you can’t change bank account. If you’ve entered wrong information, please contact us.',
    pa389: 'PayPal account screen (optional)',
    pa390: 'Upload a copy of bankbook (optional)',
    pa391: 'You are not qualified for legal representative.',
    pa392: 'The minor under 19 should apply for the type “Under 19”.',
    pa393: 'Errors found Contact the development team.',
    pa394: 'PayPal account (e-mail)',
    pa395: 'The following information is invalid',
    pa396: 'Creators’ sales units and the settlement money can be found.',
    pa397: 'Total withdrawal amount',
    pa398: 'Sum',
    pa399: 'Excel download',
    pa400: 'Total money received',
    pa401: 'Balance',
    pa402: 'Brand management',
    pa403: 'Apply the color and logo to the site by reflecting the company’s brand identity.',
    pa404: 'Main page’s GNB settings',
    pa405: 'Logo for PC version',
    pa406: 'Recommended size (width 80~920px,, length 88px)',
    pa407: 'Logo for mobile version',
    pa408: 'Recommended size (width 60~460px, length 56px)',
    pa409: 'No attachment found.',
    pa410: 'File upload',
    pa411: 'Footer settings',
    pa412: 'Company name',
    pa413: 'Enter the company name',
    pa414: 'Company’s official homepage URL',
    pa415: 'Facebook URL',
    pa416: 'Instagram URL',
    pa417: 'Youtube URL',
    pa418: 'Twitter URL',
    pa419: 'Save Changes',
    pa420: 'Not an image file',
    pa421: 'Saved',
    pa422: 'Promotion banner management',
    pa423: 'Create and manage a promotion banner to disclose on the main page.',
    pa424: 'Create and manage a promotion banner you wish to disclose.',
    pa425: 'All promotion',
    pa426: 'Change of the alignment on the main page',
    pa427: 'Promotion banner management',
    pa428: 'Create and manage a promotion banner to disclose on the main page.',
    pa429: 'Scheduled posting',
    pa430: 'Create a promotion banner to be registered<br/>on the main page of the site.',
    pa431: 'You can change the alignment as you wish by using drag function.',
    pa432: 'Change the promotion banner to be hidden status',
    pa433:
      'The banner with the disclosure lifted will be changed to be hidden.<br/>Will you now lift the disclosure from the main page?',
    pa434: 'Create a promotion banner',
    pa435: 'Select image',
    pa436: 'Enter the banner image for PC version',
    pa437: 'Recommended size',
    pa438: 'Register the banner image for mobile version',
    pa439: 'Create a banner',
    pa440: 'PC promotion title settings',
    pa441: 'Enter description',
    pa442: 'Multi-language is supported, and the preview is available on the left if you enter the field.',
    pa443: 'Enter title',
    pa444: 'Select font color',
    pa445: 'Content Details',
    pa446: 'Product Details',
    pa447: 'Creator Shop Home',
    pa448: 'Marpple Shop Plus Home',
    pa449: 'Combination of goods list and category',
    pa450: 'Application for creator',
    pa451: 'Mobile promotion title settings',
    pa452: 'Promotion URL · disclosure settings (Y/N)',
    pa453: 'URL address',
    pa454: 'Please enter the URL to go into when clicking the banner',
    pa455: 'Link type',
    pa456: 'Current page',
    pa457: 'New page',
    pa458: 'Scheduling',
    pa459: 'They are kept in the hidden status until the date of posting you set arrives at KST.',
    pa460: 'App link type',
    pa461: 'Enter the name of the creator and Marpple Shop Plus',
    pa462: 'Create a banner',
    pa463: 'Save Changes',
    pa464: 'Refresh',
    pa465: 'Apply for the Marpple Shop creators',
    pa466: 'Register the image for mobile version.',
    pa467: 'Saved',
    pa468: 'Image volume is available up to {{size}}KB for upload',
    pa469: 'Will you delete the promotion banner?',
    pa470: 'Manage Content',
    pa471: 'Create and manage Content with a variety of theme.',
    pa472: 'All Content',
    pa473: 'Change of the alignment on the main page',
    pa474: 'Create a new Content by organizing goods<br/>with a variety of theme.',
    pa475: 'Save',
    pa476: 'Writing Content',
    pa477: 'Write Content by using text, photo, product, and link.',
    'pa477-1':
      '(Content image recommended size: width 1120px * length 5000px or less / registerable format: jpg, jpeg, png, gif, webp / capacity: 5MB or less)',
    pa478: 'Preview',
    pa479: 'Issue',
    pa480: 'Register the thumbnail image<br/>at the designated ratio',
    'pa480-1': 'Thumbnail image mandatory size',
    'pa480-2': 'Registrationable format: jpeg, png / capacity of 5MB or less',
    'pa480-3': 'Check image size',
    'pa480-4': 'The image size is not correct. <br/>Please check and upload it again.',
    'pa480-5': 'Check Image Capacity',
    'pa480-6': 'The capacity of the image has exceeded.<br/>Please check and upload it again.',
    'pa480-7': 'Verify Image Extensions',
    'pa480-8': 'The extension of the image is not correct extension.<br/>Please check and upload it again.',
    pa481: 'Content time',
    pa482: 'Marpple Shop now',
    pa483: 'Detailed image settings',
    pa484: 'Registration in the main page (Y/N)',
    pa485: 'View the goods in thumbnail in advance',
    pa486: 'Close (Y/N)',
    pa487: 'Content’s category',
    pa488: 'Select',
    pa489: 'Required field hasn’t been entered.',
    pa490: 'Content’s title',
    pa491: 'Enter the title.',
    pa492: 'The location of the thumbnail text',
    pa493: 'Up',
    pa494: 'Bottom',
    pa495: 'Key visual image',
    'pa495-1': 'Key visual image required size',
    'pa495-2': 'Registrationable format: jpeg, png / capacity of 5MB or less',
    pa496: 'Image upload',
    pa497: 'Key visual text',
    pa498: 'Key visual text color',
    pa499: 'Will you hide the special selling event from the main page?',
    pa500: 'Problem occurred.',
    pa501: 'Product page management',
    pa502: 'Decorate the category of the goods page',
    pa503: 'Grouping by category',
    pa504: 'Grouping',
    pa505: 'Alignment',
    pa506: 'Select type',
    pa507: 'Enter the title with at least one language',
    pa508: 'Add categories. Recommended: small, medium – more than 6, large- more than 4',
    pa509: 'Category is required field',
    pa510: 'Add categories',
    pa511: 'Category setting (required)',
    pa512: 'Change image',
    pa513: 'Change style (optional)',
    pa514: 'Choose category',
    pa515: 'Manage live broadcast',
    pa516: 'Create and manage a live broadcast to be disclosed on the main page.',
    pa517: 'All live broadcast',
    pa518: 'Change of the alignment on the main page',
    pa519: 'Create a live broadcast to be registered<br/>on the main page of the site.',
    pa520: 'Set the title of the live broadcast',
    pa521: 'Enter title',
    pa522: 'Enter the details',
    pa523: 'Support multi-languages',
    pa524: 'Live broadcast URL disclosure (Y/N)',
    pa525: 'URL address',
    pa526: 'Bring the iframe video clip',
    pa527: 'Save',
    pa528: 'Create a live broadcast',
    pa529: 'Not a right iframe',
    pa530: 'Will you delete live broadcast streaming?',
    pa531: 'Enter title',
    pa532: 'Support multi-languages',
    pa533: 'Enter the details',
    pa534: 'Support multi-languages',
    pa535: 'Set the title of the live broadcast',
    pa536: 'Live broadcast URL / disclosure (Y/N)',
    pa537: 'URL address',
    pa538: 'Please enter the URL to go into when clicking the banner',
    pa539: 'Bring the iframe video clip',
    pa540: 'Enter the iframe address to be disclosed on the main screen.',
    pa541: 'URL of the representative goods',
    pa542: 'Enter the URL of the representative goods to be disclosed on the main screen.',
    pa543: 'Disclosure (Y/N)',
    pa544: 'Main Page disclosure (Y/N)',
    pa545:
      'If live broadcast streaming is lifted from exposure,<br/>it is changed to the hidden status.<br/>Will you now lift the disclosure from the main page?',
    pa546: 'Manager page',
    pa547: 'Add manager for the site management, and set the authority range',
    pa548: 'Function',
    pa549: 'Crew authority list',
    pa550: 'Authority',
    pa551: 'Master',
    pa552: 'Authorized area: Crew',
    pa553: 'Inquiry of creator’s money withdrawal details',
    pa554: 'Approve creator’s money withdrawal',
    pa555: 'Cancel creator’s money withdrawal',
    pa556: 'Register crew account',
    pa557: 'Crew’s money withdrawal application',
    pa558: 'Inquiry of crew’s money withdrawal',
    pa559: 'Access to all the studio',
    pa560: 'Change store label',
    pa561: 'Create a store',
    pa562: 'Delete store',
    pa563: 'Change store creator',
    pa564: 'Change store name',
    pa565: 'Change whether to expose the goods on the home',
    pa566: 'Inquiry of order and sales analysis, and profits',
    pa567: 'Change manger authority',
    pa568: 'Delete manager',
    pa569: 'Add manager',
    pa570: 'Site management',
    pa571: 'Access to crew admin',
    pa572: 'Authorized area: Studio',
    pa573: 'Inquiry of creator’s money withdrawal details',
    pa574: 'Request for money withdrawal',
    pa575: 'Inquiry of account info.',
    pa576: 'Manufacturing goods',
    pa577: 'Inquiry of settlement details',
    pa578: 'Inquiry of order details',
    pa579: 'Inquiry of sales analysis',
    pa580: 'Change store name',
    pa581: 'Delete goods',
    pa582: 'Sales holding',
    pa583: 'Inquiry of order and sales analysis, and profits',
    pa584: 'Do you really want to delete it?',
    pa585: 'Server error! Please contact the development team.',
    pa586: 'Enter {{label}}',
    pa587: 'Add manager',
    pa588: 'Manager email',
    pa589: 'Crew’s authority',
    pa590: 'Register creator',
    pa591: 'Rejected',
    pa592: 'Adult Verification Required',
    pa593: 'Failed',
    pa594: 'Permission list',
    pa595: 'Edit crew permission',
    pa596: 'There is no amount that can be withdrawn.',
    pa597: 'The amount that can be withdrawn does not exceed the minimum amount of KRW 10,000.',
    pa598: 'Bank',
    pa599: 'Product List',
    pa600: 'Please register a thumbnail image first.',
    pa601: 'Please enter contents',
    pa602: 'Thumbnail',
    pa603: 'Key visual',
    pa604: 'Please check the aspect ratio of the image. Estimated height: {{height}}',
    pa605: 'There is no thumbnail image.',
    pa606: 'There is no key visual image.',
    pa607: 'Main',
    pa608: 'Choose your main brand color',
    pa609: 'Brand color',
    pa610: "Both the site's main top menu and point color are reflected.",
    pa611: 'Meta tag settings',
    pa612: 'OpenGraph Image',
    pa613: 'OpenGraph Title',
    pa614: 'OpenGraph Content',
    pa615: 'Recommended size (Width 1200px, Height 600px)',
    pa616: 'Please check the preview and save.',
    pa617: "Once you save it, you can't get it back. Save?",
    pa618: 'Getting ready ..',
    pa619: 'Request date',
    pa620: 'Save failed.',
    pa621: 'You can select up to 7 main creators.<br/>Please proceed after deselecting other creators.',
    pa622: 'Main Creator Management',
    pa623:
      'Please select the creators to be exposed on the main page. You can change the exposure order by dragging.',
    pa624:
      'Please select a creator to be displayed on the main site from the list of creators below.<br />Maximum of 7 main creators can be registered.',
    pa625: 'All creators',
    pa626: 'You must enter the actual amount.',
    pa627: 'You must enter the actual amount as a number.',
    pa628: 'This is a withdrawal request that has already been processed.',
    pa629: 'This is a withdrawal request that has already been canceled.',
    pa630: 'Social Security Number',
    pa631: 'Add product quality',
    pa632: 'Modify product quality',
    pa634: 'Site Management - Banner',
    pa635: '{{period}} to be published',
    pa636: '{{period}} End of posting',
    pa637: 'Posting {{period}}',
    pa638: 'Posting from {{date}}',
    pa639: 'No banner ownership.',
    pa640: 'Site Management - Brand',
    pa641: 'Number of products requested for inspection',
    pa642: 'Inspection page',
    pa643: 'The designer ({{designer_id}}) could not be found.',
    pa644: 'Site Management - Live Broadcasting',
    pa645: 'Live broadcast edit',
    pa646: 'Creator e-mail is empty',
    pa647: 'This e-mail is already registered.',
    pa648: 'The requested crew permission ({{level}}) does not exist.',
    pa649: 'There is no crew.',
    pa650: 'There is no certification.',
    pa651: 'There is no corresponding order form.',
    pa652: 'Invalid deduction amount.',
    pa653: 'Order ID',
    pa654: 'Add',
    pa655: 'Excel upload',
    pa666: 'Request',
    pa667: 'Site Management - Title',
    pa668: 'Title Management',
    pa669: 'Try changing the title of each area in the main page and applying it to the site.',
    pa670: 'Main creator title setting',
    pa671: 'Supports multiple languages.',
    pa672: 'Enter the title. (Up to 29 characters including spaces)',
    pa673: 'Enter content',
    pa674: 'Setting up the title of the exhibition',
    pa675: 'No corresponding store found.',
    pa676: 'No corresponding label found.',
    pa677: "You can't modify the corresponding properties of the store.",
    pa678: "You don't have permission to modify the corresponding properties of the store.",
    pa679: 'Changed share ratios do not apply to previously created products.',
    pa680: 'Revenue Share Ratio',
    pa681: 'Plus',
    pa682: 'This creator email is already in use.',
    pa683: 'All crew does not exist.',
    pa684: 'Marpple crew does not exist.',
    pa685: 'There is no crew authority for the creator.',
    pa686: '{{type}} does not exist.',
    pa687: 'No domain.',
    pa688: 'There is no store name.',
    pa689: 'Duplicate domains exist.',
    pa690: 'Content Description',
    pa691: 'Product Category',
    pas690: 'Manage Style',
    pas691: 'Manage Site-Manage Style',
    pas692: 'Create and manage a picture of the product according to the style.',
    pas693: 'Public',
    pas694: 'Private',
    pas695: 'Add Product',
    pas696: 'Change product info',
    pas697: 'Product address',
    pas698: 'Please enter the address of the product.',
    pas699: 'Banners have been released.',
    pas700: 'Banners have been processed privately.',
    pas701: `It's a temporary error.\n Please try again.`,
    pas702: 'Product has been added.',
    pas703: 'Product has been modified.',
    pas704: '이미지 용량은 {{size}}KB까지 업로드할 수 있습니다.',
    pas705: '이미지 사이즈는 {{size}}까지 업로드할 수 있습니다.',
    pas706: '투데이 상세',
    pa707: 'Search engine exposure',
    pa708: 'Tags',
    pa709: 'SEO - Description',
    pa710: 'SEO - Title',
  },

  event: {
    e1: 'Please enter your comments.\nComments unrelated to the article, malicious comments, etc. may be deleted :)',
    e2: '1000 characters or less',
    e3: 'Delete event',
    e4: 'Clicking Save is irreversible. Public is only available in preview.',
    e5: 'Be sure to click Preview after saving.',
    e6: 'Event name',
    e7: 'List subtitle.',
    e8: 'Event Duration:',
    e9: 'Thumbnail of the list (350x350)',
    e10: 'Change image',
    e11: '992px',
    e12: 'Banner for mobile (600x600)',
    e13: 'User Confirmation',
    e14: 'A problem has occurred. please try again.',
    e15: "Please check your email.<br/>If you haven't received it for a few minutes, please check your spam folder.",
    e16: 'Please write in 5 or more and 1000 or less characters.',
    e17: "It's not an event period, or it's a temporary error. please try again.",
    e18: 'This is a temporary error. please try again.',
    e19: 'Creating a new event',
  },

  creator: {
    feed: {
      f1: 'No feed ownership.',
    },
  },

  bank_account: {
    ba1: `Register account info.`,
    ba2: `You can receive the profit settlement with the account info. you had entered before.`,
    ba3: `Select creator type`,
    ba4: `At the age of 14~19`,
    ba5: `Individual`,
    ba6: `Entrepreneur`,
    ba7: `Overseas resident`,
    ba8: `Register account info.`,
    ba9: `Bank`,
    ba10: `Enter the bank name of your account.`,
    ba11: `Bank account holder`,
    ba12: `Enter the name of the bank account holder.`,
    ba13: `Account number`,
    ba14: `Enter the number without ‘-‘.`,
    ba15: `A copy of bankbook (optional)`,
    ba16: `No attachment found.`,
    ba17: `PayPal account`,
    ba18: `Enter the e-mail of the PayPal account.`,
    ba19: `A capture of the PayPal account`,
    ba20: `Log on to the PayPal website,&nbsp;{{- link}}`,
    ba21: `Please capture and upload the page you can see by clicking ‘settings’ button with gear image in the upper right area.&nbsp;{{- link}}`,
    ba22: `See the file example`,
    ba23: `Register personal information`,
    ba24: `Identity verification is required via the mobile phone registered by the same identity of the Marpple Shop creator.`,
    ba25: `The income reporting is made by withholding taxes from the income in accordance with the profits earned by the individual person.`,
    ba26: `Identity verification through mobile phone`,
    ba27: `Enter the real name of the creator`,
    ba28: `Nationality`,
    ba29: `Enter the nationality of the creator`,
    ba30: `Resident registration number`,
    ba31: `The initial number of the resident registration number`,
    ba32: `A copy of ID card (optional)`,
    ba33: `Agree to the Terms of Service`,
    ba34: `Agree to the use of personal information (required)`,
    ba35: `Paying profits, handling withholding tax, submitting payment statement, reporting business income of the self-employed businesses To report income for the businesses closed temporarily or shut down and receive the result of the reporting, name and resident registration number are required.`,
    ba36: `I agree to the Company’s use of my personal information according to the {{- link}}.`,
    ba37: `I agree.`,
    ba38: `As they are the required information for the Company to pay profits and report taxes, you may not get paid your profits if you don’t agree to it. Despite the fact, will you cancel your agreement?`,
    ba39: `Agree to the Terms of settlement (required)`,
    ba40: `I hereby fill out necessary information for the settlement and provide my consent to the collection, use and provision of my personal information as above according to the MarppleShop Studio’s Terms of Service, in order to get paid for the profits earned by using the service as a creator of Marpple Shop, the service of Marpple Corporation.`,
    ba41: `The information entered above is all true, and I agree that I won’t raise any issue, or claim compensations toward the Company for any loss incurred by entering wrong or false information.`,
    ba42: `Identity verification of legal representative and register his/her consent`,
    ba43: `If the creator of Marpple Shop is a minor at the age of 14 ~19, he/she needs the consent of the legal representative (ex. parent) for the profit-seeking activities on the Marpple Shop.`,
    ba44: `The income reporting is made by withholding taxes from the income in accordance with the profits earned by the individual person.`,
    ba45: `Therefore, the legal representative needs to verify his/her identity through the mobile phone registered by the same identity. After identity verification, the legal representative should attach a scanned copy or camera footage of the written document (manually) to the consent form.`,
    ba46: `Verify legal representative’s identity through mobile phone`,
    ba47: `Download legal representative’s consent form`,
    ba48: `Mobile number of the legal representative`,
    ba49: `Birth date of the legal representative`,
    ba50: `Name of the legal representative`,
    ba51: `Register legal representative’s consent`,
    ba52: `Availability of the tax invoice issue (Y/N)`,
    ba53: `Are you available to issue a tax invoice?`,
    ba54: `You can select the “Business type” only when you are available to issue a tax invoice.`,
    ba55: `Before selecting creator type, check whether you are available to issue tax invoice, and if not available, register with “Individual type”.`,
    ba56: `Available to issue a tax invoice`,
    ba57: `Unavailable to issue a tax invoice`,
    ba58: `Register the company info.`,
    ba59: `Name of company`,
    ba60: `Enter the company name`,
    ba61: `Name of CEO`,
    ba62: `Enter the CEO name of your company.`,
    ba63: `Business registration number`,
    ba64: `e-mail of the person in charge`,
    ba65: `Enter the e-mail address.`,
    ba66: `Address of the business site`,
    ba67: `Enter the address of the business site.`,
    ba68: `Business Category`,
    ba69: `Enter the company’s business category.`,
    ba70: `Business item`,
    ba71: `Enter the business item.`,
    ba72: `A copy of business registration certificate`,
    ba73: `Check whether to meet the condition for the settlement of the overseas resident`,
    ba74: `The condition for the settlement of the overseas resident`,
    ba75: `When having stayed more than 183 days overseas`,
    ba76: `When there is no income to be reported to the National Tax Service (in Korea) other than the incomes from the Marpple Shop`,
    ba77: `I meet the “condition for the settlement of foreign resident”.`,
    ba78: `FX standard`,
    ba79: `The settlement money is paid in USD translated at the central rate (FX) as of the last business day of the previous month from the money receiving date.`,
    ba80: `ex: If paid in Jun., 2020, the central rate on the 29th of May, 2020, which is the last business day of May, is applied.`,
    ba81: `I agree to receive settlement money in USD by the application standard of FX rate.`,
    ba82: `PayPal e-mail`,
    ba83: `A captured copy of PayPal account`,
    ba84: `Upload a copy of bankbook (optional)`,
    ba85: `Please capture and upload the account page you can see after clicking ‘settings’ button with gear image in the upper right area.`,
    ba86: `Company info.`,
    ba87: `Fill in the information in an accurate manner, as the profit of the corporation shall be settled with the information you entered.`,
    ba88: `I hereby provide the required information for the settlement in order to get paid for the profits earned by using the service of Marpple Shop, the service of Marpple Corporation, as a creative creator, and provide my consent to the provision of the information according to the Terms of Service of MarppleShop Studio.`,
    ba89: `The information entered above is all true, and I agree that I won’t raise any issue, or claim compensations toward the Company for any loss incurred by entering wrong or false information.`,
    ba90: 'Check file',

    ba91: 'Account information is locked when applying for withdrawal. Modification/change is not possible.',
    ba92: 'Mobile number',
    ba93: 'Last digit of resident registration number',
    ba94: 'MarppleShop Studio Terms and Conditions',
    ba95: 'Accept settlement terms and conditions (required)',
    ba96: 'When electronic tax invoice issuance is not possible',
    ba97: 'Businesses who cannot issue electronic tax invoices, please register account information as an individual type.',
    ba98: 'Would you like to register as an individual type?',
    ba99: 'Continue with Business Type',
    ba100: 'Register as an individual type',
    ba101: 'Delete file',
    ba102: 'File Check',
    ba103: 'There is no PayPal account capture.',
    ba104: 'It cannot be downloaded from the app. Please proceed on PC.',

    ba105: 'You must agree to the terms and conditions.',
    ba106: 'Account Verification Error: {{msg}}',
    ba107: 'Account information is incorrect.',
    ba108: 'The required information has not yet been filled in.',
  },

  translation: {
    google_ua: '',
    'Facebook Error': 'Facebook Error',
    MARPPLE: 'MARPPLE',
    마플샵: 'MARPPLE SHOP',
    님: '{{name}}',
    Korean: 'Korean',
    English: 'English',
    Japanese: 'Japanese',
    '자세히 보기': 'View more',
    'If you move from this page, the design you created will disappear.':
      'If you move from this page, the design you created will disappear.',
    // maker
    'No Print': 'No Print',
    'Change Character': 'Change Character',
    Text: 'Text',
    Design: 'Design',
    Wappens: 'Wappens',
    Character: 'Character',
    Library: 'Library',
    'Upload Your Design': 'Upload Your Design',
    Image: 'Image',
    'Add To Cart': 'Add To Cart',
    'Start Creating': 'Start Creating',
    Revert: 'Revert',
    Undo: 'Undo',
    Redo: 'Redo',
    Delete: 'Delete',
    Forward: 'Forward',
    Backward: 'Backward',
    Group: 'Group',
    Ungroup: 'Ungroup',
    Horizontal: 'Horizontal',
    Vertical: 'Vertical',
    Left: 'Left',
    Center: 'Center',
    Right: 'Right',
    Top: 'Top',
    Middle: 'Middle',
    Bottom: 'Bottom',
    Font: 'Font',
    Spacing: 'Spacing',
    Rotate: 'Rotate',
    Line: 'Line',
    Opacity: 'Opacity',
    Lock: 'Lock',
    Unlock: 'Unlock',
    'Edit Image': 'Edit Image',
    'Change Font': 'Change Font',
    Change: 'Change',
    'Change Product': 'Change Product',
    'Coming soon': 'Coming soon',

    'One size': 'One size',

    'Hidden images are automatically deleted.': 'Hidden images are automatically deleted.',

    Share: 'Share',
    'Wish List': 'Wish List',

    'Privacy Policy': 'Privacy Policy',
    'Terms & Conditions': 'Terms & Conditions',
    모음: '',

    //tutorial
    'Add text and design templates': 'Add text and design templates',
    'Design your own with text and design templates.': 'Design your own with text and design templates.',
    'Upload your photo or design': 'Upload your photo or design',
    'You can upload your own design and photo.': 'You can upload your own design and photo.',
    'Edit your design': 'Edit your design',
    'You can easily edit your design by using editing toolbar.':
      'You can easily edit your design by using editing toolbar.',
    'Choose printing side': 'Choose printing side',
    'You can have your product printed front and back of the product':
      'You can have your product printed front and back of the product.',
    Next: 'Next',
    'Got it': 'Got it',

    'Select the image at the bottom and edit it with your finger.':
      'Select the image at the bottom and edit it with your finger.',
    'Embroidery patches are not available for this product':
      'Embroidery patches are not available for this product.',
    'Embroidery patches can not be grouped.': 'Embroidery patches can not be grouped.',
    'The font colors are not available for this product.':
      'The font colors are not available for this product.',
    'The font colors or image colors are not available for this product.':
      'The font colors or image colors are not available for this product.',
    'The image color is not available for this product. Please choose the others.':
      'The image color is not available for this product. Please choose the others.',
    'Please check if the image is on the printable area.':
      'Please check if the image is on the printable area.',
    'Some image size is too large. Please set the image size smaller.':
      'Some image size is bigger than 40cm.<br/>Please set the image size smaller.',
    'Some image size is too small. Please set the image size bigger.':
      'Some image size is too small to print.<br/>Please set the image size bigger.',
    'The image placed on the non-printable area will be deleted automatically. Would you like to proceed?':
      'The image placed on the non-printable area will be deleted automatically.<br/>Would you like to proceed?',
    'Item is overflowed.': 'Item is overflowed.',
    'Embroidery patches can not be resized.': 'Embroidery patches can not be resized.',
    'Enlarge function makes image editing easier.': 'Enlarge function makes image editing easier.',
    "It's easy to edit with the lock function.": "It's easy to edit with the lock function.",
    'Emoji and special characters are not supported.': 'Emoji and special characters are not supported.',
    Cancel: 'Cancel',
    Done: 'Done',
    'Design Complete': 'Design is complete',
    'Drag and drop your images.': 'Drag and drop your images.',
    'There are no images selected.': 'There are no images selected..',
    'Background Remove Request': 'Background Remove Request',
    'Buckle Color': 'Buckle Color',
    Copy: 'Copy',
    Duplicate: 'Duplicate',
    Cut: 'Cut',
    Paste: 'Paste',
    'Select All': 'Select All',
    'Remove All': 'Remove All',

    Check: 'Check',
    'Check before you start': 'Check before you start',
    'Read carefully before you start designing': 'Read carefully before you start designing',

    Product: 'Product',

    'Font Style': 'Font Style',
    keyboard: 'keyboard',
    Color: 'Color',
    'Text Color': 'Text Color',
    'Image Color': 'Image Color',
    Pattern: 'Pattern',
    Crop: 'Crop',
    Layer: 'Layer',

    'Next step': 'Next step',

    'Add Product': 'Add Product',
    'Sell Your Products': 'Sell Your Products',
    Title: 'Title',
    'Set Your Price': 'Set Your Price',
    'Minimum Price': 'Minimum Price',
    Profit: 'Profit',
    Submit: 'Submit',
    'All Collaboration': 'All Collaboration',
    Collaboration: 'Collaboration',

    'Added to cart': 'Added to cart.',

    'My Cart': 'My Cart',
    Cart: 'Cart',
    Checkout: 'Checkout',
    'Order Complete': 'Order Complete',
    'Your shopping cart is empty': 'Your shopping cart is empty.',
    'Your shopping cart is empty--mobile': 'Your shopping cart is empty.',
    'Your wish list is empty': 'Your wish list is empty.',

    'Product Details': 'Product Details',
    'Size/Quantity': 'Size/Quantity',
    Price: 'Price',
    Optional: 'Optional',
    'Total Qty': 'Total Qty',
    Qty: 'Qty',
    Device: 'Devices and options',
    'How many do you need?': 'How many do you need?',
    Confirm: 'Confirm',
    'Buy Now': 'Buy Now',
    'Buy Now 2': 'Buy Now',
    Buy: 'Buy',
    'Add to Cart': 'Add to Cart',
    'Add All To Cart': 'Add All To Cart',
    Subtotal: 'Subtotal',
    Shipping: 'Shipping',
    Discount: 'Discount',
    Total: 'Total',
    'No.': 'No.',
    Print: 'Print',
    Size: 'Size',
    Brand: 'Brand',
    Edit: 'Edit',
    'Save for Later': 'Save for Later',
    Remove: 'Remove',
    'Add Color': 'Add Color',
    '+ Add Color': '+ Add Color',
    'Proceed To Checkout': 'Proceed To Checkout',
    'Order completed': 'Order completed',
    'Resizing failed. Please try again.': 'Resizing failed. Please try again.',
    'Please select one or more products.': 'Please select one or more products.',
    'There are some items in your cart':
      'There are some items in your cart that have not been specified in quantity and size.',
    'This item is already in your cart': 'This item is already in your cart.',
    'Failed to put in cart': 'Failed to put in cart. Please try again.',
    'Your payment request failed': 'Your payment request failed.',
    'Please check your balance': 'Please check your balance.',
    'Payment has been stopped': 'Payment has been stopped. User canceled payment.',

    'View more': 'View more',

    Featured: 'Featured',
    'Low to High': 'Low to High',
    'High to Low': 'High to Low',
    Filter: 'Filter',
    Filters: 'Filters',
    'Select Filters': 'Select Filters',
    Clear: 'Clear',
    Apply: 'Apply',
    'All products': 'All products',
    'Show products': 'Show products',
    'No products matching the conditions.': 'No products matching the conditions.',

    'Go to my shop': 'Go to my shop',

    'Sign in': 'Sign In',
    'Sign in Facebook': 'Sign in Facebook',
    'Sign up Facebook': 'Sign up Facebook',
    'Sign out': 'Sign Out',
    'Sign up': 'Sign Up',
    'Your Name': 'Your Name',
    Male: 'Male',
    Female: 'Female',
    'failed please retry': 'failed please retry',

    'This ID is already Withdrawal': 'This ID is already Withdrawal.',
    'This ID is already registered': 'This ID is already registered.',
    'You already have a registered email': 'You already have a registered email. Please use the Sign in.',
    'Name is empty': 'Name is empty',
    'Email is empty': 'Email is empty',
    'Password is empty': 'Password is empty',
    'This email address is not available.': 'This email address is not available.',
    'Incorrect email or password': 'Incorrect email or password.',
    'Sign up failed': 'Sign up failed',
    'Reset password failed': 'Reset password failed',
    'Check your email for a link':
      'Check your email for a link to reset your password.<br/><br/>If it doesn’t appear within a few minutes, check your spam folder.',

    'Please enter your shipping address.': 'Please enter your shipping address.',
    'Please select the filter you want.': 'Please select the filter you want.',
    'Unfortunately this promo': 'Unfortunately this promo code is invalid, please check and try again.',
    'The promo code has been exhausted.': 'The promo code has been exhausted.',
    '주문 금액보다 할인 금액이 큰 경우 쿠폰 적용이 불가능합니다.':
      'If the amount of discount is bigger than the ordered price, you can’t use the coupon.',
    'Please choose the size of the product.': 'Please choose the size of the product.',
    'Special Requests': 'Special Requests',
    'Shipping Address': 'Shipping Address',
    'Payment Summary': 'Payment Summary',
    Coupon: 'Coupon',
    Point: 'Point',
    Description: 'Description',
    'My Point': 'My Point',
    'Total Price': 'Total Price',
    'Cancel Price': 'Cancel Price',
    'No special requests.': 'No special requests.',
    'Credit Card': 'Credit Card',
    'Without a Bank Account': 'Without a Bank Account',
    NAVERPAY: 'NAVERPAY',
    SSGPAY: 'SSGPAY',
    Alipay: 'Alipay',
    SamsungPay: 'SamsungPay',

    'Refund Price': 'Refund Price',
    Free: 'Free',

    'Other Discount': 'Other Discount',
    'Other Price': 'Other Price',
    'Other Shipping Discount': 'Other Shipping Discount',
    'Other Shipping Price': 'Other Shipping Price',
    'Additional Payment': 'Additional Payment',
    'Additional Payment Price': 'Additional Payment Price',
    'Amount due': 'Amount due',
    'Paid Price': 'Paid Price',
    Payable: 'Payable',

    'Are you sure you want to delete?': 'Are you sure you want to delete?',
    Post: 'Post',
    '로그인 후 이용해 주세요.': '로그인 후 이용해 주세요.',
    '이벤트기간에만 댓글을 작성할 수 있습니다.': '이벤트기간에만 댓글을 작성할 수 있습니다.',
    '내용을 적어주세요.': '내용을 적어주세요.',
    'Page Not Found': 'Page Not Found',
    'View all': 'View all',

    'Are you sure you want to cancel order?': 'Are you sure you want to cancel order?',
    'Cancel request is fail.': 'Cancel request is fail',
    'Cancel request is success.': 'Cancel request is success.',
    'Upload an Image': 'Image upload',
    'My Review': 'My Review',
    'Writable Review': 'Writable Review',
    'Written Review': 'Written Review',
    'File size is too large': 'File size is too large.<br/>(Max Size: {{mb}}MB)',

    'You have pay an unpaid balance due to the order has been changed':
      'You have pay an unpaid balance due to the order has been changed.<br/>Will you pay it now?',

    /* eslint-disable no-template-curly-in-string */
    coupon_min_price_error: 'This coupon can be used when the total price is over ${{min_price}}.',

    'Order Number': 'Order Number',

    'Product Mismatched': 'Product Mismatched',
    'Price Mismatched': 'Price Mismatched',

    'Payment Error': 'The order has been failed. Please try again.',

    'This browser is not supported and will not work properly':
      'This browser is not supported and will not work properly. Please use the latest IE11 or higher, Chrome, Safari, mobile browser.',

    'Create your style, Curate your vibe': "Create Your Style,<span class='br'></span>Curate Your Vibe!",
    "Don't miss out": "Don't miss out",
    'Be yourself, Be a creator': 'Be yourself, Be a creator',
    'More Themes': 'More Themes',
    'All Themes': 'All Themes',
    'See more': 'See more',
    'Start Designing': 'Start Designing',

    '이전에 페이스북으로 가입하신 마플 계정이 있습니다. 페이스북으로 로그인 하시겠습니까?':
      'You have a Marpple Shop account that you already registered via Facebook. Will you log in through Facebook account?',
    '같은 이메일로 가입하신 마플 계정이 있습니다. 로그인 하시겠습니까?':
      'You have a Marpple Shop account that you already registered with the same email address. Will you log in?',

    money_sign: '$',
    '판매 종료': 'End of Sale',
    '판매 시작': 'Start Sale',
    '판매 종료까지 남은 시간': 'Time remaining until end of sale',
    '판매 시작까지 남은 시간': 'Time remaining to start selling',
    '한정 판매 기간': 'Limited Selling Period',

    // settings
    '썸네일 선택': 'Select a thumbnail.',
    '선택 완료': 'Selection is complete',
    '상품 공개 여부': 'Goods disclosure (Y/N)',
    '배송 출발일 노출': 'Delivery departure date disclosure (Y/N)',
    '판매 색상 선택': 'Select the color for sale.',
    '다국어 지원 (선택 사항)': 'Multi-language support (optional)',
    '전체 선택': 'Select all',
    '대표 색상 선택': 'Select the representative color',
    '판매 수량': 'Sales unit',
    '1개당 판매 수익': 'Sales profit per a unit',
    '1개당 추가 수익': 'Extra profits per a unit',
    '디자인 스토리 작성하기': 'Write a design story',
    '한정 판매 기간 & 수량 설정': 'Limited sales settings',
    '1인당 구매 가능 수량': 'Purchase quantity per user',
    '상품 생성': 'Create goods',
    '파일 선택': 'Select files',
    '언어별 스토리 설정': 'Setting stories by language',
    'SNS에 올린 글 가져오기': 'Bring the text uploaded in the SNS',
    '이미지 첨부': 'Attach image',
    '다중 선택 가능': 'Multi-selection is available',

    Up: 'Up',
    Down: 'Down',
    '샵 디자인': 'Style Editor',
    '상품 만들기': 'Make Products',
    '상품별 판매 분석': 'Sales analysis by goods',
    계좌정보등록: 'Bank Account Info',
    '계좌정보 확인': 'Bank Account',
    '내샵 보기': 'Go to My Shop',
    '출금 신청': 'Manage Withdraws',
    '상품 정렬': 'Product Arrangement',
    로그아웃: 'Sign Out',
    '로고 &middot; 배너 변경': 'Edit Style',
    유형: 'Type',
    공개: 'Public',
    비공개: 'Hide',
    '판매 보류': 'Discontinued',
    '관리자에 의해 판매가 보류된 상품입니다.': 'This item has been discontinued by your administrator.',
    '소비자 가격': 'Retail Price',
    '판매 수량별 수익': 'Profits by sales unit',
    '판매 수량에 따라 추가 수익이 발생됩니다.':
      'Additional revenue is generated by certain sales thresholds.',
    날짜: 'Date',
    수량: 'Qty',
    '취소된 수량': 'Canceled Quantity',
    '주문 내역': 'Orders',
    '취소된 주문수': 'Canceled Orders',
    '1개당 수익': 'Revenue per item',
    '총 수익': 'Total profits',
    기업: 'Enterprise',
    '셀러 수익': 'Creator Revenue',
    '1개당 제작비': 'Manufacturing cost per a unit',
    '1개당 내 수익': 'My profits per a unit',
    '내 수익': 'My profit',
    수정: 'Edit',
    삭제: 'Delete',
    시작일: 'Start Date',
    종료일: 'End Date',
    '공개 여부': 'Set Public',
    '디자인 이름': 'Design Name',
    '디자인 가격': 'Design Price',
    한국어: '한국어',
    영어: 'English',
    일본어: '日本語',
    '판매 기간': 'Sales period',
    '판매 기간은 1주에서 4주로 설정할 수 있으며, 판매 종료 후 일괄 제작하여 출고됩니다.':
      'The sales period can be set for 1~4 week. After the sales are close, the goods are manufactured in batches and shipped out.',
    '제품 생성': 'Create goods',
    '스토어 생성': 'Create Store',
    '변경 사항 취소': 'Cancel the change',
    저장하기: 'Save',
    '디자인 수정': 'Edit Design',
    '이 디자인으로 다른 상품 만들기': 'Make other goods with this design',
    '상품 복제': 'Copy goods',
    '미리 보기': 'Preview',
    '상품 삭제': 'Delete goods',
    '샵 이름': 'Shop name',
    '대표 SNS 주소': 'Representative SNS address',
    '오른쪽 상단에 표시': 'Show in the upper right side',
    '샵 설명': 'Describe the shop',
    '로고 이미지 업로드': 'Logo image upload',
    '배너 배경색': 'Banner’s background color',
    '배너 이미지 업로드': 'Banner image upload',
    '배너 제목': 'Banner title',
    '배너 설명': 'Describe the banner',
    저장: 'Save',
    취소: 'Cancel',
    '실행 취소': 'Reset',
    완료: 'Complete',
    변경: 'Change',
    '기본 이미지로 지정': 'Set as a default image',
    '필터 적용': 'Apply filter',
    '판매할 상품을 만들어보세요.': 'Create goods for sale.',
    계정정보: 'My Account',
    마케팅: 'Marketing',
    정산내역: 'Payments Summary',
    '판매 순위': 'Sales ranking',
    상품: 'Product',
    '공개 상태': 'Product Status',
    판매량: 'Quantity',
    수익: 'Revenue',
    '추가 수익': 'Additional Revenue',
    선택: 'Optional',
    '스토리 작성하기 <i>(권장)</i>': 'Add Story <i>(Recommended)</i>',
    '제목을 작성해 주세요.': 'Enter the title.',
    '내용을 작성해 주세요.': 'Enter the contents.',
    '유튜브 비디오의 링크를 입력해 주세요.': 'Enter the Youtube video link.',
    '인스타그램 글의 링크를 입력해 주세요.': 'Enter the Instagram page link',
    '트위터 글의 링크를 입력해 주세요.': 'Enter the Twitter page link',
    '페이스북 글의 링크를 입력해 주세요.': 'Enter the Facebook page link',
    '이미지를 업로드해 주세요.': 'Upload image',
    '상품 디자인': 'Goods design',
    '상품 정보 입력': 'Enter goods info.',
    '상품 선택': 'Select goods',
    '상품 카테고리 선택': 'Select Product Category',
    '상품 정보 수정': 'Change goods info.',
    '다시 시도해 주세요.': 'Try again.',
    '구매되었거나 장바구니에 담긴 상품은<br/>삭제 할 수 없습니다.<br/>대신 공개 설정을 이용해보세요.':
      'Design change is not available for the goods purchased or put in the shopping basket.<br/>Instead, use the goods copy function.',
    '삭제 하시겠습니까?': 'Will you delete it?',
    '구매되었거나 장바구니에 담긴 상품은<br/>디자인 가격을 수정할 수 없습니다.':
      'Price change is not available for the goods purchased or put in the shopping basket.',
    '디자인에 대한 가격을 설정해 주세요.': 'Set the price for the design',
    최소: 'MIN',
    최대: 'MAX',
    로고: 'Logo',
    스토어: 'Store',
    이름: 'Name',
    기능: 'Feature',
    스튜디오: 'Studio',
    검색: 'Search',
    '상품 수': 'Number of Products',
    편집: 'Edit',
    크루: 'Crew',
    링크: 'Link',
    '날짜 변경': 'Change date',
    '판매 기간을 설정해 주세요.': 'Set the sales period.',
    '최소 높이 28px, 최대 높이 56px': 'minimum height 28px, maximum height 56px',
    '계정정보에서 전화번호를 입력해 주세요.': 'Please enter your phone number in your account.',
    '최소 1120 * 400px, 최대 2240 * 800px': 'minimum 1120 * 400px, maximum 2240 * 800px',
    '최소 400px * 400px, 최대 800 * 800px': 'minimum 400px * 400px, maximum 800 * 800px',
    '프로필 이미지': 'Profile image',
    셀러: 'Creator',
    '이 화면은 미리보기 페이지입니다.': 'This is a preview page.',
    '디자인 하기': 'Designing',
    '디자인 수정하기': 'Change design',

    '약관에 동의해 주세요.': 'Agree to the Terms of Service.',
    '판매 기간이 설정된 상품만 구매할 수 있습니다.':
      'Only for the goods with sales period set can be purchased.',
    '이미지 크기를 확인해 주세요.': 'Check the image size.',
    '이미지의 비율을 확인해 주세요.': 'Check the image ratio.',
    '판매 기간 설정 필요': 'Sales period setting is required.',
    '상품이 판매되거나 장바구니에 담길 경우 가격 변경이 불가능하며 판매 기간 수정이 제한됩니다. 상품을 공개하시겠습니까?':
      'If the product is sold or placed in a cart, the price cannot be changed and modification of the sales period is restricted. Do you want to make the product public?',
    파일선택: 'Select files',
    KIDS: 'Kids',
    '다른 판매 기간과 겹치게 설정할 수 없습니다.': 'It can’t be overlapped with other sales periods.',
    품절: 'Sold out',
    '한정 판매': 'Limited Edition',
    '한정 판매 수량': 'Limited sales settings',
    기간: 'Period',
    daily: 'Daily',
    yearly: 'Yearly',
    monthly: 'Monthly',
    weekly: 'Weekly',
    all: 'All',
    'Please enter your address and name in English.': 'Please enter your address and recipient in English.',
    '1:1 문의': '1:1 Chat',
    클립보드: 'Clipboard',
    정산금액: 'Settlement amount',
    결제수수료제외: 'PG commission excluded',
    '상품 색상 변경': 'Change colors',
    '색상 선택 가능': 'Available for selecting colors',
    '색상 선택 불가능': 'Unavailable for selecting colors',
    '대표 색상': 'Primary Color',
    '판매 가능 색상': 'Colors Available For Sale',
    '인쇄 불가능 색상': 'Non-Printable Colors',
    '대표 색상은 판매 가능 색상에서 제외 할 수 없습니다.':
      'The primary color cannot be excluded from the available colors.',
    '대표 색상은 인쇄 불가능 색상으로 지정할 수 없습니다.':
      'The primary color cannot be specified as non-printable.',
    '스토어 도메인은 모두 소문자로 저장됩니다.': 'All store domains are stored in lowercase letters.',
    매니저: 'Manager',
    권한: 'Level',
    목록: 'List',
    추가: 'Add',
    '권한 목록표': 'Level Table',
    도메인: 'Domain',
    Community: 'Community',
    Seller: 'Creator',
    '카테고리 보기': 'View category',
    '카테고리 닫기': 'Close category',
    최신순: 'Most recent',
    인기순: 'Popular',
    'Sorry. Payment could not be completed because the limited quantity was exceeded.':
      'Sorry. Payment could not be completed because the limited quantity was exceeded.',
    '신고 접수는 PC에서만 가능합니다.': 'Receiving a reporting is available only on PC.',
    전체: 'All',
    '답변 대기': 'Answer on standby',
    '판매 재개/샵 정상 운영': 'Resume sales /<br/>Normally operate the shop',
    '판매 중지/샵 운영 중지': 'Sales hold /<br/>Shop operation hold',
    '내부 규정 위반 답변완료': 'Answered for the breach<br/>of the internal policy',
    '접수된 신고가 없습니다.': 'No reporting received.',
    '마플 개인 커스텀 주문 리뷰를 참고해보세요!': 'Refer to the Marpple’s individual custom order reviews!',
    '부가세 포함 가격': '{{profit_title}} includes VAT.',
    '디자인 가격이 더해져 자동 계산됩니다.':
      'Total price is automatically calculated by combining {{title}} and design price.',
    '판매 수수료': 'Sales fee',
    '기본 상품 가격': 'Price of basic goods',
    '환불 정보': 'Refund Information',
    '취소 금액 합계': 'Total Cancellation Amount',
    '환불 수단': 'Refund Method',
  },

  crew_level: {
    master: 'Master',
    accounter: 'Accounter',
    analyzer: 'Analyzer',
    maker: 'Maker',
    seller: 'Creator',
    designer: 'Designer',
    manager: 'Manager',
  },

  image_editor: {
    image_editor: 'Edit Image',
    edit: 'Edit',
    filter: 'Filter',
    adjustment: 'Adjust',
    transform: 'Transform',
    initialize: 'Reset',
    edit_freely: 'Try out our Image Editor',
    cancel: 'Cancel',
    complete: 'Complete',
    original: 'Original',
    Paris: 'Paris',
    LosAngeles: 'LosAngeles',
    Oslo: 'Oslo',
    Lagos: 'Lagos',
    Melbourne: 'Melbourne',
    Jakarta: 'Jakarta',
    AbuDhabi: 'AbuDhabi',
    SaoPaulo: 'SaoPaulo',
    NewYork: 'NewYork',
    Cairo: 'Cairo',
    Moon: 'Moon',
    brightness: 'Brightness',
    contrast: 'Contrast',
    saturation: 'Saturation',
    hue: 'Hue',
    red_tone: 'Red Tone',
    green_tone: 'Green Tone',
    blue_tone: 'Blue Tone',
    brown_tone: 'Brown Tone',
    opacity: 'Opacity',
    definition: 'Definition',
    technicolor: 'Technicolor',
    film_camera: 'Film',
    polaroid: 'Polaroid',
    vintage: 'Vintage',
    vibrance: 'Vibrance',
    vignette: 'Vignette',
    noise: 'Noise',
    gamma: 'Gamma',
    warmth: 'Warmth',
    bright_area: 'Bright Area',
    dark_area: 'Dark Area',
    invert: 'Invert',
    texture: 'Emboss',
    size: 'Zoom',
    rotate: 'Rotate',
    move_A: 'X-Move',
    move_B: 'Y-Move',
    apply: 'Would you like to apply?',
    editor_cancel: 'Would you like to cancel?',
    vertical: 'height',
    horizontal: 'width',
    crop_limit_1: 'The transform function is limited due to the image size. Please edit the ',
    crop_limit_2: ' of the image size into not less than 80px',
    ie_warning:
      "The 'Edit Image' function is limited in the browser you are using. Please use another browser (Chrome, Safari, mobile browsers, etc.)",
    cmyk_alert:
      'Please notice the Image Editor automatically converts CMYK color mode image to RGB color mode and this may generate some color changes on CMYK color mode images.',
    crop_alert:
      'Enlarging the image may deteriorate the quality of the image file and it may cause the printing to be blurred or uneven.',
    color_change_alert:
      'The image may differ from the actual printed product color and by using the filter, print quality may deteriorate.',
  },

  crew_admin: {
    디자인: 'Design',
    매출: 'Sales',
  },

  plus_main: {
    event: 'Event',
    'no-today': 'There is no Contents yet.<br/> We will deliver various news soon!',
    event_default_title: 'Special Curations Just For You',
    event_default_desc: 'Explore Selected Items Picked for You',
    'Featured Creators': 'Featured Creators',
    '지금 가장 인기 있는 셀러들의 굿즈를 만나보세요.': `Meet Our Creator's Special Merchandises`,
    '셀러샵 전체 보기': 'Creators Shop View All',
  },

  marppleshop_action: {
    read_orders: 'Read Orders',
    read_sales_amount: 'Read Sales Amount',
    read_adjustments: 'Read Adjustments',
    delete_manager: 'Delete Manager',
    delete_store: 'Delete Store',
    create_store: 'Create Store',
    update_store_seller: 'Update Store Creator',
    update_store_name: 'Update Store Name',
    create_manager: 'Create Manager',
    read_bank_account: 'Read Bank Account',
    read_sales_analytics: 'Read Sales Analytics',
    update_manager_level: 'Update Manager Level',
    request_withdraw: 'Request Withdraw',
    cancel_withdraw: 'Cancel Withdraw',
    approve_withdraw: 'Approve Withdraw',
    read_withdraws: 'Read Withdraws',
    make_product: 'Make Product',
    open_pb_private_info: 'Open PB Private Info',
    access_all_studio: 'Access All Studio',
    access_crew_admin: 'Access Crew Admin',
    update_product_confirm: 'Update Product Confirm',
    make_pb_product: 'Make PB Product',
    update_marppleshop_marketing_confirm: 'Update MarppleShop Marketing Confirm',
    update_marppleshop_marketing_thumbnail: 'Update MarppleShop Marketing Thumbnail',
    read_marppleshop_analytics: 'READ MARPPLE SHOP ANALYTICS',
    delete_product: 'Delete Product',
    prohibit_product: 'Prohibit Product',
    update_suspend_store: 'Update Suspend Store',
    confirm_designer_requests: 'Confirm Designer Request',
    read_pb_projections: 'Read PB Projections',
    update_crew_store_labels: 'Update Store Labels',
    update_want_withdrawal: 'Update Want Withdrawal',
    update_store_can_selling: 'Update Store Can Selling',
    invite_friend: 'Invite Friend',
    update_is_brand_home: 'Show Store at Home or not',
    manage_site: 'Manage Site',
  },

  crew_menu: {
    seller_management: 'Creator management',
    pb_seller_management: '셀러상품 스토어관리',
    inactive_seller_list: 'Inactive creator management',
    disapproval_store_list: 'Unapproved store',
    unregistered_store_list: 'Unregistered store',
    rejected_store_list: 'Rejected store',
    invited_store_list: 'Invited creator',
    manager_management: 'Manager page',
    seller_adjustment: 'Creator withdrawal',
    store_profit: 'Design Prod sales status',
    store_seller_profit: 'Creator Prod sales status',
    store_tpl_profit: '입고 상품 판매 현황',
    store_dtg_profit: '디지털 상품 판매 현황',
    confirmations: 'Goods inspection',
    pb_projections: 'PB goods order',
    pb_projection_detail: 'PB goods order details',
    pb_products: 'PB goods list',
    designers: 'Designer',
    products: 'All goods',
    make_archive_admin: 'Goods quality management',
    product_check: 'Goods design inspection',
    feeds: 'Creator Tip management',
    solution: 'Receive reporting',
    crew_withdraw: 'Corporate withdrawal',
    crew_bank_account: 'Corporate bank account registration',
    crew_adjustment: 'Corporate settlement',
    crew_youtube: 'Youtube sales creator',
    website: 'Site management',
  },

  time_unit: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  },

  creator_adjustment: {
    release: 'release',
    change: 'change',
    confirmed: 'confirmed',
    '정산 날짜': 'Adjustment Date',
  },

  line_theme: {
    'Customizable Products': 'Customizable Products',
    'T-Shirts, Sweat Shirts, Hoodies, Zip Ups, Tote bags, Phone Cases':
      'T-Shirts, Sweat Shirts, Hoodies, Zip Ups, Tote bags, Phone Cases',
    iPhone: 'iPhone',
    'Samsung Galaxy': 'Samsung Galaxy',
  },

  dialog_msg: {
    Yes: 'Yes',
    No: 'No',
    Ok: 'Ok',
  },

  maker: {
    select_face: {
      tooltip: 'Print on the other side! 👀',
    },
    locOpt: {
      name: 'Location<br>Optimization',
      guideTitle: 'Location Optimization Guide',
      guideDescription: 'We adjust the design to its best position on the product.',
      guideWarning:
        "*We don't alter the design's size, color, or position, except for the four specified cases.",
      guideWarningCheckbox: 'Agreed: no returns, refunds, or exchanges if location optimized.',
      requestActivation: 'Request Optimization',
      requestCancel: 'Cancel Optimization',
      resetWarning: 'Product change resets location optimization.',
      noDesignWarning: 'There is no design to optimize the location for.',
      frontLeft: 'Front Left',
      frontRight: 'Front Right',
      frontCenter: 'Front Center',
      backCenter: 'Back Center',
      close: 'Close',
    },
    icon: {
      Preview: 'Preview',
    },
    'lets design': "Let's design!",
    '양옆 채우기': 'fill on both sides',
    'Accepted File Types: JPEG, PNG, AI.': 'Accepted File Types: JPEG, PNG, AI.',
    'Accepted File Types: JPEG, PNG, SVG.': 'Accepted File Types: JPEG, PNG, SVG.',
    'Accepted File Types: JPEG, PNG.': 'Accepted File Types: JPEG, PNG.',
    Product: 'Product',
    Design: 'Design',
    Character: 'Character',
    'This product does not support ai file. Please convert the file into png or jpeg format.':
      'This product does not support ai file.<br/>Please convert the file into png or jpeg format.',
    'Please notice that unsupportive characters are going to be excluded.':
      'Please notice that unsupportive characters are going to be excluded.',
    'This font does not support bold. The font will be changed to the default thickness.':
      'This font does not support bold.<br/>The font will be changed to the default thickness.',
    'This size is temporarily out of stock.': 'This size is temporarily out of stock.',
    'The size of the embroidery patch is': 'The size of the embroidery patch is',
    'cm.': 'cm.',
    Preview: 'Preview',
    'At least 500px': 'At least 500px of image is required for good quality printing.',
    'MAX 8000px': 'Please resize your file to below 8,000px.',
    '100%': '100%',
    'Something went wrong. Please check all the designs.':
      'Something went wrong. Please check all the designs.',
    'The font is no longer supported. Please select the other fonts.':
      'The font is no longer supported.<br/>Please select the other fonts.',
    Unknown: 'Unknown',
    "The font doesn't support Bold.": "The font doesn't support Bold.",
    Message: {
      undesigned_area_message: `Oops! There's an undesigned area.😂`,
      there_is_no_design_message: `Oh! There's no design.😂`,
      'Fill the image to the dotted line for full-sided printing.':
        'Fill the image to the dotted line for full-sided printing.',
      'Place important images inside the dotted line.': 'Place important images inside the dotted line.',
      'Fill the image to the solid line for full-sided printing.':
        'Fill the image to the solid line for full-sided printing.',
      'Please fill in the design to the outer dotted line to full print the image.':
        'Please fill in the design to the outer dotted line to full print the image.',
      'Please fill in the design to the dotted line for production safety.':
        'Please fill in the design to the dotted line for production safety.',
      'The product image has been changed. Please check out the positions of your designs.':
        'The product image has been changed.<br/>Please check out the positions of your designs.',
      'If you dont put an image on the bottom of it, its made in white. Would you like to proceed with it?':
        "If you don't put an image on the bottom of it, it's made in white. Would you like to proceed with it?",
      'If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.':
        'If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.',
      'This product is does not allow background remove request.':
        'This product does not allow background remove request.',
      'Images with small resolution may reduce print quality. Do you want to change to the optimal size?':
        'Images with small resolution may reduce print quality. Do you want to change to the optimal size?',
      go_to_not_designed_fcanvas: `This product has a side that doesn't have a design. Would you like to proceed with the order?`,
      cancel_fit_width: `If you need to resize the image, turn off the 'Fill Both Sides' option.`,
      it_is_not_product_for_fit_width_function: 'it_is_not_product_for_fit_width_function',
      border_1mm: `The border area of 0.04" is not printed.`,
      border_2mm: `The border area of 0.08" is not printed.`,
      embro_product: `Additional charges may apply depending on the size of the uploaded image.`,
      important_in_green: `The key components of your design should be inside the green dotted lines.`,
      '전면 인쇄를 원하시는 경우 반드시 이미지를 점선 끝까지 채워주시기 바랍니다.':
        'If you want to print all over, be sure to fill in the image to the end of the dotted line.',
      '로고 주변은 피해서 디자인 해주세요.':
        'It is not possible to print on the logo, so please do not design on the logo.😉',
      '해당 제품의 경우 자수 제작은 불가합니다.': 'This product cannot be embroidered.',
      '제품 이미지가 수정 됐습니다. 디자인의 위치와 크기를 확인해주세요.': `The product image has been edited.<br>Please double-check the design's position and size.`,
      '그대로 작업하기': 'Make as is',
      '수정하러 가기': 'Go back to edit',
    },
  },

  line_maker: {
    Change: 'Change',
    'Right click is not available.': 'Right click is not available.',
    'Address Copied. Paste the copied address.': 'Address Copied.<br/>Paste the copied address.',
    Share: 'Share',
  },

  line_create: {
    '의류 커스텀': 'Clothing',
    '폰케이스 커스텀': 'Phone Cases',
    '캔버스백 커스텀': 'Canvas Bags',
    '에어팟케이스 커스텀': 'AirPods Cases',
    '홈데코 커스텀': 'Home &amp; Decor',
    '키즈의류 커스텀': 'Kids’ Clothing',
    '펫 용품 커스텀': 'Pet Goods',
    '모바일 액세서리 커스텀': 'Mobile Acc',
  },

  product_detail: {
    Tip: 'Tip',
    Story: 'Story',
    'Product Info': 'Product Info',
    'Print Guide': 'Production guide',
    Guide: 'Guide',
    'Size chart': 'Size chart',
    'Size Compare': 'Size compare',
    '자주 묻는 질문': 'FAQ',
    '배송 및 환불': 'Shipping & Refund',
    Reviews: 'Reviews',
    'FAQ 전체 보러가기': 'See All FAQ',
    '배송 FAQ 보러가기': 'Visit Shipping FAQ for more information.',
    '교환/환불 FAQ 보러가기': 'Visit Exchange/Refund FAQ for more information.',
    '1:1 문의하기': '1:1 Inquiry',
    '일대일 문의하기': 'Individual Inquiry',
    precautions: 'Precautions for Purchase',
    precautions_info:
      'A purchase order made by a minor may be cancelled by the same minor or his/her guardian if his/her guardian has not consented to such purchase.',
    '셀러 배송 상품': 'Creator Shipping Products',
    '해당 상품은 셀러가 직접 배송하는 상품입니다. 구매 안내를 확인해주세요.':
      'This product is delivered directly by the creator.<br>Please check the notice on the purchase.',
    'The product information is invalid.<br>Will refresh.':
      'The product information is invalid.<br>Will refresh.',
    '상품 기본 정보': 'Product Basic Info',
    'KC 및 기타 인증': 'KC and Other Certifications',
    인증번호확인: 'Verify Certification No',
    '구매전 확인': 'Please ensure to check the safety certification information before purchasing.',
    '인증 책임 판매자':
      'This information has been provided directly by the creator, who is responsible for its accuracy.',
    '수량은 1개 이상이어야 합니다.': 'Quantity must be 1 or more.',
    '옵션은 필수로 선택하셔야해요.': 'Options must be selected.',
    '옵션으로 선택된 상품이 더 이상 존재하지 않습니다.': 'The selected option no longer exists.',
  },

  pattern: {
    'Pattern-Size': 'Pattern-Size',
    'Pattern-Type': 'Pattern-Type',
    Copies: 'Copies',
    Type: 'Type',
    Repeat: 'Repeat',
    Lattice1: 'Lattice1',
    Lattice2: 'Lattice2',
    'Pattern-Move': 'Pattern-Move',
  },

  pattern_mobile: {
    'Pattern-Size': 'Size',
    'Pattern-Type': 'Type',
    Copies: 'Copies',
    Type: 'Type',
    Repeat: 'Repeat',
    Lattice1: 'Lattice1',
    Lattice2: 'Lattice2',
    'Pattern-Move': 'Move',
  },

  color: {
    Add: 'Add',
    Done: 'Done',
    'No colors are available to add': 'No colors are available to add.',
  },

  sticker: {
    'Are you sure to delete this?': 'Are you sure to delete this?',
    'Delete failed. Please try again.': 'Delete failed. Please try again.',
    'Delete Success.': 'Delete Success.',
  },

  group: {
    Group: 'Group',
  },

  payment_done: {
    'Payment Successful': 'Payment Successful',
    'View Orders': 'View Orders',
    'Continue Shopping': 'Continue Shopping',
  },

  mpay: {
    '결제 안내': '결제 안내',
    copy_link: '링크복사',
    url_copied: 'URL이 클립보드에 복사되었습니다.',
    url_copied2: '주소가 복사되었습니다. 원하는 곳에 붙여넣기 해주세요.',
    url_failed: 'URL 복사에 실패했습니다.',
    wrong_url: '잘못된 주소 입니다.',
  },

  home: {
    Home: 'Home',
    Reviews: 'Reviews',
    Goods: `Today's Goods`,
    'Make Your Own': 'Make Your Own',
    'Quick and easy start': 'Quick and easy start',
    'Complete within 3 minutes': 'Complete within 3 minutes',
    'with no installation of any programs': 'with no installation of any programs',
    'Meticulously crafted products': 'Meticulously crafted products',
    'Guarantee the highest quality': 'Guarantee the highest quality',
    'in the industry': 'in the industry',
    'Free design template': 'Free design template',
    'Enjoy the best collection of': 'Enjoy the best collection of',
    'art and fonts on the web': 'art and fonts on the web',
  },

  login: {
    Email: 'Email',
    'Your Name': 'Your Name',
    Email_placeholder: 'Email',
    'Reset your password': 'Find Password',
    'Forgot Password?': 'Forgot Password?',
    'Reset password': 'Reset password',
    'Enter your email address and we will send you a link to reset your password.':
      'Enter your email address and we will send you a link to reset your password.',
    'Your email address is verified.': 'Your email address is verified.',
    'Your email address is not verified.': 'Your email address is not verified.',
    'Your email address is not verified2':
      'Your email address is not verified.<br/>Do you want to authenticate?',
    check_agree: 'Check Agree to Privacy Policy and Terms & Conditions.',
    'Change password': 'Change password',
    '비밀번호 10 ~ 64자': '10 to 64 characters',
    'Success change password': 'Success change password',
    'Please verify your e-mail address': 'Please verify your e-mail address',
    'Please click on the verification link you sent to': 'Please click on the verification link you sent to',
    'Resend verification email': 'Resend verification email',
    'Change Email Address': 'Change Email Address',
    'Current Email Address': 'Current Email Address',
    'We ll send an order confirmation to this address.': "We'll send an order confirmation to this address.",
    'Please verify your current e-mail address.': 'Please verify your current e-mail address.',
    'New Email Address': 'New Email Address',
    Password: 'Password',
    'Save Changes': 'Save Changes',
    'Enter your new email': 'Enter your new email',
    'Enter your password': 'Enter your password',
    'Check your email again': 'Check your email again',
    'There was a problem<br/>Your password is incorrect':
      'There was a problem<br/>Your password is incorrect',
    'Email address already in use': 'Email address already in use',
    'There was a problem<br/>Please try Again': 'There was a problem<br/>Please try Again',
    'Check your email.':
      'Check your email.<br/>If it doesn’t appear within a few minutes,<br/>check your spam folder.',
    'failed please retry': 'failed please retry',
    Back: 'Back',
    'login failed': 'login failed',
    'sign up failed': 'sign up failed',
    '비밀번호는 10자 이상 최대한 길게 작성해주세요.':
      'Please write the password as long as possible with more than 10 characters.',
    '비밀번호에 동일한 문자를 과도하게 연속해서 사용할 수 없습니다.':
      'It must not contain too many consecutive uses of the same character.',
    '비밀번호에 ID를 포함할 수 없습니다.': 'It must not contain your ID in password.',
    '비밀번호에 특수문자는 !@#$%^*+=-만 사용 가능합니다.':
      'The only special characters allowed in passwords are the following:!@#$%^*+=-.',
    '연속된 문자를 사용할 수 없습니다.': 'Repeating characters is disallowed.',
  },

  seller_login: {
    '초대 받으신 이메일과 다릅니다.': 'It is different from the email you were invited to.',
    '마플샵 셀러 신청시 입력하신 이메일과 다릅니다. 동일한 이메일로 이용해 주세요.':
      'It is different from the email you entered when applying for the Marpple Shop creator. Please use the same email address.',
    '인증 정보가 잘못되었습니다.': '인증 정보가 잘못되었습니다.',
    '다른 그룹에 이미 속해 있어 해당 스토어에 가입 및 로그인을 할 수 없습니다.':
      'You cannot join and log in to the store because you already belong to another group.',
    '유저의 스토어가 아닙니다.': 'This is not your store.',
    '마플샵 관련 정보가 없습니다. 고객센터에 문의해 주세요.':
      'There is no information about the Marpple Shop. Please contact the customer center.',
    '스토어가 없습니다. 고객센터에 문의해 주세요.': 'There is no store. Please contact the customer center.',
  },

  gnb: {
    REVIEW: 'REVIEW',
    PREVIEW: 'PREVIEW',
    EVENT: 'EVENT',
    HELP: 'HELP',
    CUSTOM: 'CUSTOM',
    CUSTOM2: 'CUSTOM',
    GROUP: 'GROUP',
    COLLABORATION: 'COLLABORATION',
    CHAT: '1:1 Live chat',
    'WELCOME!': 'WELCOME!',
    Collaboration: 'Collaboration',
    'PHONE CASE': 'PHONE CASE',
  },

  line_gnb: {
    의류: 'Clothing',
    가방: 'Bags',
    폰케이스: 'Phone Cases',
    에어팟케이스: 'AirPods Cases',
    쿠션: 'Cushion',
    '담요/테피스트리': 'Blanket/Wall Tapestries',
    시계: 'Clocks',
    펫용품: 'Pet Supplies',
  },

  review: {
    'Accepted File Types: JPEG, PNG, GIF': 'The image file available for uploading is JPEG, PNG, and GIF.',
    'Write a Review': 'Leave a review',
    'Support your Sellers': 'Supporting as a review',
    'Select Points': 'Select a rating',
    '사진을 올려 주세요.': 'Upload a picture',
    Review: 'Leave a review on goods',
    'Support your sellers with a review.': 'Support your creator with review.',
    'Minimum of 5 words': 'Enter the field with at least 5 letters.',
    'Maximum of 2000 words': 'Enter the field with less than 2,000 letters.',
    'Write a review for this item.':
      'Leave a review on your experience in Marpple and the goods you created. We won’t forget to give points for the great reviews.',
    'Write a review for this item.2': 'Enter the review.',
    '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.':
      '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.',
    'Get MarppleShop points for both you and your seller by writing a review.':
      'If you leave a review supporting creators, we will give you {{order_point}}P. We give {{seller_point}}P to creators as well to support their creative activity.',
    'Post Review': 'The review has been registered.',
    'Thank you for your comment': 'Thank you for the review.',
    'Continue Shopping': 'Continue shopping',
    'Wrong sns': 'The SNS address is wrong. Please check on that.',
    'Maximum of MAX photos': 'Upload less than {{max}} pages.',
    'Agree to terms of review': 'Consent to the use of review.',
    view: 'View',
    'The status will be changed to Delivered':
      'If you leave a review, the status will be changed to “Delivery complete”',
    'Discontinued product': 'Goods with sales holding',
    'No reviews have been written.': 'No review.',
    'No Photo review.': 'No photo reviews.',
    'There are no reviews available to write.': 'No reviews available to write',
    '리뷰 응원 완료': 'Review support complete',
    '리뷰 작성 완료': 'The review has been registered.',
    '리뷰 응원이 완료된 상품입니다.': `It is the goods with review support complete`,
  },

  checkout: {
    Checkout: 'Checkout',
    Cart: 'Cart',
    'Order Complete': 'Order Complete',
    'Total to Pay': 'Total to Pay',
    'Total Qty': 'Total Qty',
    Subtotal: 'Subtotal',
    Shipping: 'Shipping',
    Promotion: 'Promotion',
    Total: 'Total',
    Email: 'Email',
    'Order Summary': 'Order Summary',
    'Change Email': 'Change Email',
    "We'll send an order confirmation to this address.": "We'll send an order confirmation to this address.",
    "We'll send an order confirmation to this address. Please verify your current e-mail address.":
      "We'll send an order confirmation to this address. Please verify your current e-mail address.",
    'Enter your email address': 'Enter your email address',
    'Keep me up to date on news and exclusive offers': 'Keep me up to date on news and exclusive offers',
    'Shipping Address': 'Shipping Address',
    'Search Address': 'Search Address',
    'Street address': 'Street address',
    'Apt., ste., bldg.': 'Apt., ste., bldg.',
    City: 'City',
    'State or Province': 'State or Province',
    'Postal Code': 'Postal Code',
    Recipient: 'Recipient',
    'Full Name': 'Full Name',
    'Phone Number': 'Phone Number',
    'Save this information for next time': 'Save this information for next time',
    'Special Requests': 'Special Requests',
    'Please enter the details of your request.': 'Please enter the details of your request.',
    'Promotion Code': 'Promotion Code',
    'Promotion Code (Optional)': 'Promotion Code (Optional)',
    'We Accept': 'We Accept',
    'This payment method is provided by Eximbay and is billed as www.eximbay.com.':
      'This payment method is provided by Eximbay and is billed as www.eximbay.com.',
    'Note: Please note that the billing descriptor will be listed as EXIMBAY.COM.':
      'Note: Please note that the billing descriptor will be listed as EXIMBAY.COM.',
    'Proceed To Checkout': 'Proceed To Checkout',
    'Delivery Time': 'Delivery Time',
    'The estimated delivery time is 10~15 business days.':
      'The estimated delivery time is 10~15 business days.',
    'The estimated delivery time is 3~5 business days after the shipping starts. Express shipping will be delivered by DHL.':
      'The estimated delivery time is 3~5 business days after the shipping starts.&nbsp;<br/>Express shipping will be delivered by DHL.',
    Economy: 'Economy',
    Express: 'Express',
    Warning: 'Warning',
    'Shipping Options': 'Shipping Options',
    'This country is not availble for Economy Shipping option.':
      'This country is not availble for Economy Shipping option.',
    'In some countries outside the United States, the additional payments related to import taxes, customs duties and taxes maybe required. In such cases, the additional payments must be made through local customs. Please contact the local customs directly for more details.':
      'In some countries outside the United States, the additional payments related to import taxes, customs duties and taxes maybe required. In such cases, the additional payments must be made through local customs. Please contact the local customs directly for more details.',
  },

  order: {
    'Sold Out': 'Sold-out or renewed item',
    'Checking deposit': 'Payment being checked',
    'Order Placed': 'Processing',
    Processing: 'Manufacturing',
    'Preparing to Ship': 'Ready to ship out',
    Shipped: 'In delivery',
    Delivered: 'Delivery complete',
    Delivered2: 'Purchase confirmed',
    Cancelled: 'Order cancelled',
    'Confirming Cancellation': 'Order cancellation being checked',
    'Order details': 'Purchase Details',
    'Order Number': 'Order Number',
    Tracking: 'Delivery search',
    'Cancel Order': 'Order cancellation',
    'Ordered Items': 'Order Details',
    'Product Details': 'Product Info.',
    'Size/Quantity': 'Size / Qty',
    Price: 'Amount',
    'Payment Info': 'Payment info',
    Method: 'Payment method',
    'Paid at': 'Approved time',
    Paid: 'Payment amount',
    Amount: 'Expected payment amount',
    Confirming: 'Payment being checked',
    'Change Payment Method': 'Change the payment method',
    Receipt: 'Evidence',
    'Request Receipt': 'Apply for evidence',
    cancel_req_err: 'If it doesn’t work even if you try again, please contact customer center.',
    재구매: 'Repurchase',
    '배송지 수정': 'Modifying shipping destination',
    배송지: 'Shipping destination',
    '배송 메모': 'Shipping Memo',
    '결제 정보': 'Payment Info',
    마플샵배송: 'MarppleShop Shipping',
    셀러배송: 'Creator Shipping',
    디지털상품: 'Digital Goods',
    주문번호: 'Order No',
    외몇개: 'and {{count}} others',
    주문일시: 'Order date',
    구매불가: 'Unable to purchase',
    '배송지 수정이 완료되었습니다.': 'Shipping address modification has been completed.',
    invoiceCopy: 'You have copied the waybill number to the clipboard.',
    permissionDeny: 'Permission Denied',
    '까지 다운로드 가능': 'Downloadable by <span style="color: {{color}}">{{date}}</span>',
    '도착 예정': 'Scheduled to arrive on <span style="color: {{color}}">{{date}}</span>',
    다운로드: 'Download',
    '다운로드 만료': 'Download Expired',
    '구매내역으로 이동': 'Move to Order Status',
    '총 상품 금액': 'Price of goods',
    '총 배송비': 'Shipping cost',
    '쿠폰 할인': 'Coupon',
    '포인트 사용': 'Point',
    '총 주문 금액': 'Order amount',
    연락처: 'Mobile',
  },

  order_list: {
    'Order Status': 'Order Status',
    Products: 'Products',
    Total: 'Total',
    Status: 'Status',
    VIEW: 'VIEW',
    'No orders yet': 'No orders yet',
    '마음에 드는 상품을 찾아볼까요?': "Let's find our favorite product.",
    '상품 보러 가기': 'Go to see goods',
  },

  my_info: {
    'Personal Information Modify': 'Personal Information',
    Email: 'Email',
    Password: 'Password',
    'Change Password': 'Change Password',
    'This email address is not available.': 'This email address is not available.',
    'Nick Name': 'Nick Name',
    BirthDay: 'Birthday',
    Name: 'Name',
    Phone: 'Phone',
    'Only Numbers': 'Only Numbers',
    Gender: 'Gender',
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    'SMS News': 'SMS News',
    'Email News': 'Email News',
    YES: 'YES',
    NO: 'NO',
    Modify: 'Modify',
    '스토어 url': 'Store Url',
    Withdrawal: 'Cancel Membership',
    탈퇴하기: 'Cancel Membership',
    'It has not changed. please try again.': 'It has not changed. please try again.',
    Changed: 'Changed',
    'There is no user information. Please log in again after login.':
      'There is no user information. Please log in again after login.',
    'fail please try again.': 'fail please try again.',
    'Do you really want to leave?': 'Do you really want to leave?',
    Success: 'Success',
    'ex) 19940203': 'ex) 19940203',
    check_birthday: 'Please enter a valid date of birth.',
    'Only Numbers and Text': 'Only numbers and text for password combination.',

    '이메일 주소 (ID)': 'e-mail address (ID)',
    '이메일 변경': 'Change e-mail',
    'ⓘ 위 이메일로 주문 내역 메일이 전송됩니다.': 'An order history e-mail will be sent to the above e-mail.',
  },

  withdrawal: {
    '고객님 마플샵을 정말 탈퇴하시나요?': 'Are you really leaving the<span class="m_br"></span>Marpple Shop?',
    '회원 탈퇴 전 아래 사항을 숙지해 주세요.':
      'Please read the following information before canceling membership.',
    ol_1: '1. If you are a general member, you can rejoin after withdrawal from membership, but all member information such as purchased content / order history / points will be deleted.',
    ol_2: `
In the case of a creator, the transaction in progress must be '0', the remaining profit amount must be '0 won',
You can apply for withdrawal after all income settlement is completed. After withdrawal, creator shop and product sales/settlement history, etc.
All member information will be deleted and cannot be recovered. Please check with MarppleShop Studio for details.
If you are having trouble, please contact the customer center (<a href="tel:{{tel}}">{{tel}}</a> or
Please contact us at <a href="mailto:{{email}}">{{email}}</a>) and we will guide you.`,
    ol_3: "3. Customers' personal information is managed in accordance with the Marpple Shop customer information protection policy in accordance with the 「Act on Consumer Protection in E-Commerce, Etc.」 for product return and after-sales service even after membership withdrawal.",
    '진행 중인 거래': 'Transaction in progress',
    '잔여 수익 금액': 'Residual Revenue Amount',
    '보유 포인트': 'Holding points',
    몇건: '{{count}}transactions',
    몇원: 'KRW {{amount}}',
    몇포인트: '{{point}}P',
    그동안: 'Thank you for using Marpple Shop.<br />I will try to seeing you again, the better service, sir.',
    '회원 탈퇴가 완료되었습니다.': 'Membership withdrawal has been completed.',
    '회원 탈퇴를 완료할 수 없습니다.': 'Unable to complete membership cancellation.',
    '진행 중인 거래 0건':
      "You can cancel membership only when there are '0 transactions' in progress and '0 won' in the remaining revenue. Please check with MarppleShop Studio for details. If you are unable to proceed, please contact the customer center and we will guide you.",
    '평일 10:00~18:00': 'Weekday 10:00~18:00',
  },

  creator_account_info: {
    Email: 'Email',
    'Save Changes': 'Save Changes',
    Password: 'Password',
    'Reset password': 'Reset password',
    Name: 'Name',
    BrandName: 'Brand Name',
    Phone: 'Phone',
    '스토어 url': 'Shop URL',
    Modify: 'Modify',
    '유효한 도메인을 입력하세요.': 'Please enter a valid domain name',
  },

  phonecases: {
    'Personalize Your Own Phone case': 'Personalize Your Own Phone case',
    'Make it with your own photos':
      "Make it with your own photos,<span class='br'></span>initials and design",
    'Upload your image': 'Upload your image',
    'Insert Text': 'Insert Text',
    'Free Designs': 'Free Designs',
    'SELECT YOUR SMART PHONE': 'SELECT YOUR SMART PHONE',
    'SELECT brand': 'SELECT YOUR BRAND',
    'SELECT model': 'SELECT YOUR MODEL',
    'ex brand..': 'ex iPhone, Galaxy, LG',
    'ex model..': 'ex iPhone XR, Galaxy S10, LG V40...',
    'CUSTOM YOUR CASE': 'CUSTOM YOUR CASE',
    'SHOP * CASES': 'SHOP GLITTER CASES',
    'please choose': 'Please choose an option',
    'Please select brand and model.': 'Please select brand and model.',
  },

  error: {
    SORRY: 'SORRY',
    "We couldn't find that page.": "We couldn't find that page.",
    'Go home': 'Go home',
    'This product is out of stock.': 'This product is out of stock.',
  },

  shop_home: {
    'Go to Sign in': 'Go to Sign in',
    'card1-maintitle': 'Welcome to MarppleShop!',
    'card2-maintitle': `Create & Sell in 3 Minutes`,
    'card3-maintitle': `For Creators, Artists, and Creators`,
    'card4-maintitle': `We Do It All`,
    'card5-maintitle': `Dropshipping and Fulfillment`,
    'Set the price for this t-shirt': 'Set the price for this t-shirt',
    "Here's how much you'll get!": "Here's how much you'll get!",
    'card1-mobile1':
      'https://s3.marpple.co/files/u_1165777/2021/10/original/188e915030ce9cd896e96ef1e1bc5dffe30a6fbf1.png',
    'card1-title1':
      'https://s3.marpple.co/files/u_1165777/2021/6/original/6e26adf60f36f57a2d43dfefa03c2dc2e7dead701.png',
    'card1-detail1': `We’re your one-stop solution for <br /> custom products and merchandise.<br /><br /> Want to build your brand with us? <br />You’ve come to the right place. <br /><br />Selling products of your own design&nbsp <span class='d_br'></span> has never been easier.<br /><br />Are you a creator, influencer<br /> or artist? <br /><br />Whoever you are, we promise you<br />the best service, from production<br />and delivery to customer service<br />and inventory management.`,
    'card1-title2': '<p>Create & Sell </p> <p>in 3 Minutes</p>',
    'card1-detail2':
      'Design, create, and put your products up&nbsp <span class="d_br"></span> for sale inside 3 minutes! <br/>  <br/> We make e-commerce super easy. <br/> Select from over 600+ products we offer. <br/>  <br/> Design them with your one-of-a-kind ideas,&nbsp <span class="d_br"></span> and just sell, baby, sell!',
    'card1-title3': '<p>Set your</p> <p>own price</p>',
    'card1-detail3': `“I wonder how many shirts I’ve sold today.” <br/> “What should my next item be?” <br/> <br/> Feel your heart pounding already?<br/> You should.<br/> <br/> We provide you the easiest way to sell&nbsp <span class='d_br'></span> your own merchandise. <br/> <br/> Who sets the price? You do!<br/> Who makes the sales? You do! `,
    'card1-title4': '<p>Boost </p><p>your sales</p> <p>on your</p> <p>channels.</p>',
    'card1-detail4': `Connect with your fans and followers&nbsp <span class='d_br'></span> all over social media. <br/> <br/> Link your shop with YouTube, Facebook,&nbsp <span class='d_br'></span> or Instagram to drop your merch. <br/> <br/> It’s really simple as:`,
    'card1-youtube': `Announcing and link your <br/> merch shop to your <br/> YouTube channel.`,
    'card1-instagram': `Add shoppable Instagram <br/> tags to your stuff.`,
    'card1-facebook': `Link up with your Facebook <br/> business page.`,
    'card1-title5': '<p> All you need </p>  <p>is an Idea. </p>',
    'card1-detail5': `One great idea is all you need <br/> to hit the ground running. <br/> <br/> MarppleShop does order confirmation,&nbsp <span class='d_br'></span>  stock management, customer service,&nbsp <span class='d_br'></span>  delivery, etc., etc., etc., <br/>  just so you don’t have to.`,
    'card1-step1': `Sell like a Pro`,
    'card1-step1-detail': `Congrats, you've got a new order!`,
    'card1-step2': `Make & Deliver`,
    'card1-step2-detail': `We bring your designs to life <br/> and deliver them around the world.`,
    'card1-step3': `*Ka-Ching*`,
    'card1-step3-detail': `Track your sales and stack your cash.`,
    'card2-title1': '<p>Create & Sell </p> <p>in 3 Minutes</p>',
    'card2-detail1': `3 minutes is all it takes. <br/>  <br/> Don’t waste your time and money&nbsp <span class='d_br'></span> creating merchandise all by yourself. <br/> <br/> Pick a product from MarppleShop, <br/> get your design on, <br/> set the price, and voila!`,
    'card2-title2': 'First you choose it...',
    'card2-detail2': ` 600+ unique Products to choose from:&nbsp <span class='d_br'></span> T-shirts, hoodies, bags, hats, phone cases,&nbsp <span class='d_br'></span> blankets, and many, many more. `,
    'card2-design1': `Add Your Image`,
    'card2-design1-detail': `Get busy applying your designs and place them wherever you want!`,
    'card2-design2': `Change Product Colors`,
    'card2-design2-detail': `Pick and choose from our generous array of color options!`,
    'card2-design3': `Write Something You Like`,
    'card2-design3-detail': `Design and experiment with different fonts and colors.`,
    'card2-title3': 'Then you price it',
    'card2-detail3': `Yup, you get to have your cake <br/> and price it too!`,
    'card2-title4': 'Ready, Set, OPEN!',
    'card2-detail4': ` No investment. No bricks and mortar. No inventory.&nbsp <span class='d_br'></span> Time is money. Don’t blow it on manufacturing, stocking,&nbsp <span class='d_br'></span>  managing, customer care and every other non-creative process. <br/> <br/> We’re only too happy to do everything for you. <br/>  So yes, it’s all free. Yass!`,
    'card3-title1': `<p> Creator? </p> <p> Artist? </p>`,
    'card3-detail1': `MarppleShop strives to bring&nbsp <span class='d_br'></span> creator merch and works of art&nbsp <span class='d_br'></span> to people's lives. <br/> <br/> Anyone can be a creator- <br/> Use your doodles, sketches, photos, whatever.&nbsp <span class='d_br'></span> Indulge any and all ideas that are fit to share`,
    'card3-title2': `<p> For creators </p> <p> considering </p> <p> merchandise sales </p>`,
    'card3-detail2': `Designed by you, shared with your fans,&nbsp <span class='d_br'></span> and delivered by us. <br/> <br/> All you need to do is share <br/> the good news with your followers. <br/><br/> Billing, manufacturing requests, shipping, customer care: <br/> we do it all, <br/> so you don’t have to.`,
    'card3-design1': `Create Your Merch&nbsp <span class='d_br'></span> with MarppleShop`,
    'card3-design1-detail': `In just a few minutes,&nbsp <span class='d_br'></span> you can create your merch&nbsp <span class='d_br'></span> using your very own images and writings.`,
    'card3-design2': `<p> Promote </p> <p> Your Merch </p> <p> on Social Media </p>`,
    'card3-design2-detail': `Youtube, Twitch, Instagram, Facebook. <span class='d_br'></span> You can promote and sell your merch from a variety of social platforms`,
    'card3-design3': `Sit back and watch the money rolling in`,
    'card3-design3-detail': `Whether it's order confirmation, inventory management, delivery, or even customer services - We've got you covered!`,
    'card3-title3': `<p> We want </p> <p> every artist </p> <p>in the world </p><p> to succeed</p>`,
    'card3-detail3': `Let your creations give happiness <br/> and inspiration to people everywhere.&nbsp <span class='d_br'></span> Your fans eagerly await&nbsp <span class='d_br'></span> your next masterpiece.`,
    'card3-design4': `Bring out your awesome artwork`,
    'card3-design4-detail': `Upload anything you fancy. <br/> Use your pictures and images to create a truly unparalleled item.`,
    'card3-design5': `Choose any item you like from MarppleShop`,
    'card3-design5-detail': `We have more than 600 items you can choose from`,
    'card3-design6': `Spread Your Creations Far and Wide`,
    'card3-design6-detail': `Push out meaningful, lovingly crafted items for buyers to cherish and show off for years.`,
    'card3-title4': `<p> Anyone can </p><p> be a creator </p>`,
    'card3-detail4': `Anyone can sell ideas. <br/><br/> Doodles you’ve drawn while commuting. <br/> Pictures from your last vacation. <br/> Logo designs that you’ve only thought of. <br/><br/> We want to give artists the world <br/> over a proper platform to unleash <br/> their creativity and monetize their works effectively. <br/><br/> So, you’ve got some crazy ideas? <br/> Get started with MarppleShop: right here, right now.`,
    'card4-title1': `<p> We </p> <p> Do It All </p>`,
    'card4-detail1': `DO NOT worry about Sales & Marketing. <br/><br/> Enjoy your selling and promoting experience <span class='d_br'></span> with our amazing features and services. <br/><br/> Get sales and marketing support <br/> with our tools. <br/><br/> You don’t have to be an expert!`,
    'card4-title2': `<p> Super Easy </p><p> Shop Editor </p>`,
    'card4-detail2': `At MarppleShop,&nbsp <span class='d_br'></span> you get to fit out&nbsp <span class='d_br'></span> and set up your shop&nbsp <span class='d_br'></span> with ease, style, and loads of creativity.`,
    'card4-title3': `<p> Stats and </p> <p> Data Manager </p>`,
    'card4-detail3': `Stay on top of your sales <br/> stats in real time. <br/><br/> Sales, earnings, and visitor info,<br/>  all there at your fingertips.`,
    tshirt: 'Shirt',
    cushion: 'Cushion',
    cup: 'Cup',
    rank1: '1st.',
    rank2: '2nd.',
    rank3: '3rd.',
    count: '',
    'Sales Status': 'Sales Status',
    'Cumulated Product Sales Number': 'Cumulated Product Sales Number <br/>',
    'Cumulated Sales Amount': 'Cumulated Sales Amount <br/>',
    'card4-title4': `<p> Seamless </p> <p> Integration </p>`,
    'card4-detail4': `Plug your wares on your&nbsp <span class='d_br'></span> YouTube, Facebook, or Instagram channel&nbsp <span class='d_br'></span> to drive up traffic to your MarppleShop. <br/><br/> Easy peasy!`,
    'card5-title1': `<p> Drop shipping </p> <p> & Fulfillment </p> <p> services </p>`,
    'card5-detail1': `We are with you <br/> every step of the way. <br/><br/> Ordering, purchasing, making, delivering,&nbsp <span class='d_br'></span> stock managing, customer service. <br/><br/> No investment needed. <br/> No questions asked.`,
    'card5-shop1': `<p> Selling my own Merch </p> <p> on my own Shop </p>`,
    'card5-shop1-detail': `When a customer places an order, you do... Absolutely nothing! <br/> MarppleShop will handle everything </br> from order confirmation to production`,
    'card5-shop2': `<p> We deliver right to </p> <p> the customer's </p> <p> doorstep </p>`,
    'card5-shop2-detail': `After production is complete, <br/> we guarantee a safe delivery to your customers.`,
    'card5-shop3': `<p> Track your sales </p> <p> and stack your cash </p>`,
    'card5-shop3-detail': `Your sales will directly <br/> be deposited to your bank`,
    'card5-shop4': `<p> Customer Service? </p> <p> Leave it to us </p>`,
    'card5-shop4-detail': `Product Q&A, exchange and returns <br/> - we will take care of everything`,
    'card5-title2': `<p> 12 years of </p> <p> manufacturing </p> <p> excellence. </p>`,
    'card5-detail2': `We make the best products <br/> with state-of-the-art printing technologies: <br/><br/> digital printing, silk screen, UV printing,&nbsp <span class='d_br'></span> material printing, and needlework. <br/><br/> We ensure unbeatable quality <br/> with Korea’s finest Print-on-Demand<br/> and production operation systems.`,
    'footer-customer': `Office Hours: Weekdays 10am~6pm`,
    'footer-info': `Marpple Corp. Representative Hyeyun Park <br/> <span class='m_br'></span> Business license number : 105-88-13322 <span class='m_br'></span> <span class='d_br'></span> ${MPS_ADDRESS_ENEN} <span class='m_br'></span> <span class='d_br'></span> Customer Service : {{tel}} Fax : 02-6486-9437`,
    'copyright-info': `Copyright © ${new Date().getFullYear()} Marpple Corp. All rights reserved.`,
    'Customer Center': 'Customer Center',
    '1to1chat': '1:1 Chat',
    'Go to Customer Service': 'Customer Service',
  },

  community: {
    '권한이 없습니다.': 'No Access Allowed',
    '로그인 된 유저가 없습니다.': `There's No Available User`,
    실패: 'Fail',
    등록하기: 'Upload',
    '파일 업로드 실패했습니다.': 'File Upload failed',
    '로그인 후 이용해 주세요. 로그인 하시겠습니까?': 'Please Log In First',
    '크리에이터님의 소식을 기다리고 있습니다. 다양한 소식을 전해 주세요 !':
      'We are waiting for your news!<br/>Update us with your stories.',
    '글 작성하기': 'Write a Post',
    '이미지를 등록해 주세요.': 'Please Upload an Image',
    '커뮤니티에 올릴 글을 작성해 주세요.': 'Please Write a Posting for Your Community',
    '더 보기': 'See More',
    댓글: 'Comment',
    상품: 'Product',
    커뮤니티: 'Community',
    '댓글 달기': 'Write a Comment',
    등록: 'Upload',
    좋아요: 'Like',
    개: '',
    수정: 'Edit',
    삭제: 'Delete',
    신고: 'Report',
    '불러오기 실패 다시 클릭해 주세요.': 'Loading Failed. Please Click Again.',
    '내용을 입력해 주세요': 'Please Enter a Context',
    '삭제하시겠습니까?': 'Delete?',
    '크리에이터님의 소식이 아직 없습니다. 곧 다양한 소식을 전해 드릴게요 !': `No news from the creator yet.<br/>We'll tell you various news soon.`,
    인기글: 'Popular Article',
    최신글: 'Recent Article',
    '리뷰 상세': 'Review Details',
    '갤러리 상세': 'Gallery Details',
    '갤러리 글 수정하기': 'Edit gallery posting',
    글쓰기: 'Write',
    '커뮤니티 글 등록하기': 'Upload community posting',
    '커뮤니티 글 수정하기': 'Edit community posting',
    '이미지 첨부': 'Upload image',
    '커뮤니티 상세': 'Community Details',
    '팬심 가득 담은 응원 한마디!': 'Support your creator with reviews!',
    '포토 상세': 'Photo Details',
  },

  ad_res: {
    '지금 마플샵 사전예약 신청하세요 !': 'Now, join in the preliminary application for Marpple Shop!',
    '마플샵의 첫번째 셀러가 되어주세요': `Be the first creator of&nbsp;<span class='w_br m_br'></span>Marpple Shop`,
    '마플샵의 두번째 셀러가 되어주세요':
      'Be the 2nd term creator of the&nbsp;<span class="w_br m_br"></span>Marpple Shop',
    '2020년 1월, 크리에이터 커머스 플랫폼 마플샵이 오픈합니다.':
      'The Creator Commerce Platform Marpple Shop is open in Jan., 2020.',
    '2020년 1월 28일 ~ 2020년 2월 20일 크리에이터 커머스 플랫폼 마플샵의 2기 셀러를 모집합니다.':
      'We are recruiting the 2nd term creator of the Creator Commerce Platform Marpple Shop during Jan. 28, 2020 ~ Feb. 20, 2020.',
    '첫번째 셀러로 당첨되시는 분들에게는 상품제작 및 촬영 서비스등 다양한 혜택을 제공해 드립니다.':
      'Those who are selected as the first creator will be provided with a variety of benefits including goods manufacturing service and shooting service.',
    '2기 셀러로 선정되시는 분들에게는 상품 제작 및 촬영 서비스 등 다양한 혜택을 제공해 드립니다.':
      'Those who are selected as the 2nd term creator will be provided with a variety of benefits including goods manufacturing service and shooting service.',
    '아래 간단한 질문에 대한 답변을 작성해주시면 검토 후 개별 연락 드릴 예정입니다.':
      'If you answer the following questions, we will review them and contact you.',

    '지금 신청하기 !': 'セラー申請する！',
    '원하는 마플샵 웹 도메인명을 써주세요(영문/숫자 20자 이내)': `Enter the web domain name you wish on the Marpple Shop<span class='m_br'></span>(English/number with 2~20 letters)`,
    '당첨 결과 통지를 위해 이메일 주소를 입력해 주세요': `Enter the e-mail address to be&nbsp;<span class='m_br'></span>used for the notification of the winning.`,
    '당첨되시면 연락드릴 본인의 연락처를 입력해 주세요': `Enter your contact number to be&nbsp;<span class='m_br'></span>used for the notification of the winning.<span class='m_select'>&nbsp;(Optional)`,
    '판매하게 될 콘텐츠와 관련하여 마플샵 입점심사과정에서 참고할 수 있는 자료나 웹사이트를 알려주세요': `Regarding the contents to be sold,&nbsp;<span class='m_br'></span>enter the materials or website&nbsp;<span class='m_br'></span>that could be referred to, in the screening process of being a creator of Marpple Shop&nbsp;<span class='m_br'></span>(Optional)`,
    '판매하게 될 콘텐츠 또는 디자인과 관련하여 운영하고 계신 SNS 주소를 알려주세요': `Regarding the contents or design to be sold,&nbsp;<span class='m_br'></span>enter the SNS address that you are operating&nbsp;<span class='m_br'></span>(Optional)`,
    '마지막이에요! 본인 또는 브랜드를 간략하게 소개해 주세요 (200자 이하)': `This is the last step!&nbsp;<span class='m_br'></span>Briefly introduce yourself or the brand&nbsp;<span class='m_br'></span>(with less than 200 letters)`,
    '마플샵 셀러 신청이 완료되었습니다': `The application for the Marpple Shop creator&nbsp;<span class='m_br'></span>has been complete.`,
    '저희와 함께 하고 싶으신가요?': `Do you want to&nbsp;<span class='m_br'></span>join us?`,
    '기업 파트너쉽 문의는':
      'For the inquiry of business partnership,&nbsp;<span class="w_br m_br"></span>contact <a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>',
    '영문/숫자 20자 이내로 입력해 주세요': 'Enter the field with English/number with 2~20 letters',
    '문자/숫자 20자 이내로 입력해 주세요': 'Enter the field with characters/number with 2~20 letters.',
    'sample@sample.com': 'sample@sample.com',
    '엔터키를 누르면 다음으로 진행됩니다': 'If you press the enter key, you can proceed with the next step.',
    '버튼을 누르면 다음으로 진행됩니다': 'If you press the button, you can proceed with the next step.',
    '010-0000-0000': '010-0000-0000',

    '올바른 연락처 형식을 입력해 주세요': 'Enter the right form of the contact number',
    '올바른 URL 형식을 입력해 주세요': 'Enter the right form of URL',
    '200자 이하로 입력해 주세요': 'Enter the field with less than 200 letters.',
    '닉네임 또는 브랜드 명을 입력하세요.': 'Enter the nickname or brand name.',
    '브랜드 명을 입력하세요.': 'Enter the brand',
    '도메인 명을 입력하세요.': 'Enter the domain name',
    '간략하게 소개해 주세요': 'Briefly introduce yourself.',
    '참고 할 수 있는 사이트를 입력해 주세요.': 'Enter the reference sites.',
    '버튼을 누르시면 사전예약이 완료됩니다 !':
      'If you click the button, the preliminary reservation will be complete!',
    '사전신청이 완료되지 않았습니다.': `Applying for creator hasn’t been complete.<br/>Please try it again, or contact&nbsp;<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>`,
    '사전 신청은 PC에서 해주세요.': 'Please fill out a preliminary application on PC.',
    '유효한 도메인을 입력하세요.': 'Enter a valid domain',
    '유효한 브랜드 명을 입력하세요.': 'Enter a valid brand',
    '이미 사용중인 도메인입니다.': 'The domain is already being used.',
    '담당자가 제휴제안 내용을 검토하는 과정에서 추가 자료를 요청하거나 재문의할 수 있습니다. 제안 내용 및 관련자료는 제휴 검토 목적으로만 이용됩니다.': `During the screening of the affiliate proposals,&nbsp;<span class='m_br'></span>the person in charge may request additional documents or inquire again.&nbsp;<span class='m_br'></span>The content of the proposals and related documents are only used for the review of the affiliate.`,
    '30일 이내에 제안자가 담당자의 자료요청 또는 재문의에 회신을 하지 않는 경우 해당 제휴제안은 종료 처리됩니다.': `If the proposer doesn’t respond to the material request or re-inquiry within 30 days, the affiliate proposal is deemed to have been closed.`,
    삭제: 'Delete',
    '보내주실 디자인, 포트폴리오 등이 있다면 첨부해 주세요.':
      'If there are design and portfolio you can send, please attach them.',
    '업로드 중...': 'Uploading…',
    '개인정보 수집’이용 동의에 관한 안내사항':
      'Notice on the collection, use, and the agreement of the personal information',
    '- 수집’이용기관명:(주)마플코퍼레이션': `
  <p>- Name of the company for the collection and use: Marpple Corporation</p>
  <p>- Collecting items: (Required) Name, contact, e-mail</p>
  <p>- Purpose of the collection and use: Applying for Marpple Shop creator and providing service</p>
  <p>- Period for the Retention and Use One year from the date that inquiry for the application for creator was made</p>
`,
    '*개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 셀러접수 안내가 불가능 합니다.':
      '* Those who provide personal information have the rights to refuse to agree to the collection and use of their information, but in that case, it is not available to notify them of application for creators.',
    '상기와 같이 개인정보의 수집 및 이용에 관하여 동의합니다.':
      'I hereby provide my consent to the collection, use and provision of my personal information as above.',
    '개인정보 수집 이용에 동의해 주세요':
      'Please agree to the collection and use of the personal information.',
    참고사항: 'Note',
    '파일 업로드 중입니다. 업로드 후에 다시 시도해 주세요.':
      'Files are being uploaded. Try it again after uploading',

    '당신만의 특별한 마플샵을 런칭해볼까요?': 'Do you want to launch a special Marpple Shop on your own?',
    필수입력: 'Required',
    브랜드명: 'Brand name',
    BRAND: 'BRAND',
    '영문/한글/숫자 조합하여 2자 이상 20자 이내로 입력해주세요.':
      'Enter the field in the combination of English / Korean / number with 2~20 letters. You can change it on My Page later.',
    '영문/숫자 조합하여 2자 이상 20자 이내로 추후 마이페이지에서 수정이 가능합니다.':
      'Enter the field in the combination of English / Number with 2 ~20 letters.',
    도메인명: 'Domain name',
    '우리에게 당신의 숨겨져있는 가능성을 보여주세요!': 'Show us your hidden potential!',
    '1개 이상 필수 입력': 'Required for more than one item',
    '운영중인 SNS': 'Active SNS',
    '파일 업로드': 'File upload',
    '포트폴리오 첨부': 'Attach portfolio',
    '첨부파일은 jpg':
      'Attachment file is only available with jpg, png, ppt, keynote, and pdf with 50 MB limit (Maximum 3 files can be uploaded)',
    '심사에서 참고할 사이트': 'Reference site in the screening',
    이메일: 'e-mail',
    '이메일 입력': 'Enter e-mail',
    휴대전화번호: 'Mobile number',
    '휴대전화번호(-없이 숫자만 입력)': 'Mobile number (without ‘-‘)',
    '마지막으로 본인이나 브랜드에 대해 깊은 인상을 남겨주세요!':
      'Lastly, please leave a deep impression on yourself or the brand!',
    '본인 또는 브랜드 소개': 'Introduce yourself or the brand',
    '개인정보 수집•이용 동의에 관한 안내사항을 확인해보세요.':
      'Check the notice on the consent to the collection and use of the personal information.',
    '전체 항목에 동의합니다.': 'I agree with all items.',
    '개인정보의 수집 및 이용에 관하여 동의합니다.':
      'I agree to the Company’s collection and use of my personal information.',
    '크리에이터로 선정되면 어디로 연락 드릴까요?':
      'If you are selected as our creator, how can we contact you?',
    본문보기: 'View main text',
    '만 14세 이상입니다.': 'I am 14 years old or over.',
    '꼭 읽어주세요!': 'Never miss to check this!',
    '판매하는 콘텐츠의 저작권은':
      'The copyright of the contents for sale lies with creators, and it is deemed their ownership of the copyright has been verified in the preliminary application for creator.',
    '저작권 등 타인의 권리를':
      'The liabilities for posting the image and design that defame others’ rights such as copyright are on the creator.',
    '제공된 개인정보는': `The personal information shall not be used for other purposes than the agreed one, and if you are to refuse your personal information to be used within the retention period, you can contact the personal information manager (e-mail: partner@marppleship.com, customer center {{tel}}) to request the reading, correction, and deletion.`,
    '신청 완료하기': 'Complete the application',
    '개인정보 수집 및 이용에 관한 동의': 'Consent to the collection and use of the personal information',
    '수집•이용기관명 : (주)마플코퍼레이션':
      '-Name of the company for the collection and use: Marpple Corporation” Name of the company for the collection and use: Marpple Corporation',
    '- 수집항목 : (필수항목) 성명, 연락처, 이메일': '- Collecting items: (Required) Name, contact, e-mail',
    '- 수집•이용목적 : 마플샵 셀러 신청 및 서비스 제공':
      '- Purpose of the collection and use: Applying for Marpple Shop creator and providing service',
    '- 보유 및 이용기간 : 셀러 신청 문의 일로부터 1년':
      '- Period for the Retention and Use One year from the date that inquiry for the application for creator was made',
    '* 개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 셀러접수 안내가 불가능 합니다.':
      '* Those who provide personal information have the rights to refuse to agree to the collection and use of their information, but in that case, it is not available to notify them of application for creators.',
    '최대 3개의 파일만 선택 가능합니다.': 'Maximum 3 files can be selected.',
    '파일 용량이 너무 큽니다.': 'The file volume is too big.<br/>Please upload with less than 50MB',
    '파일업로드가 실패했습니다.':
      'File upload failed.<br/>Try it again, or contact<br/><a href="mailto:partner@marppleshop.com">partner@marppleshop.com.</a>',
    '올바른 이메일 형식을 입력해 주세요': 'Enter the right e-mail form.',
    '중복된 이메일입니다': 'Repeated e-mail',
    '만 14세 이상 동의해 주세요.': 'Agree that you are 14 years old or over.',
    '셀러 신청 완료': 'Application for creator complete',
    '마플샵 생성 링크가 이메일로 전송되었습니다.':
      'The Marpple Shop creation link has been sent to your e-mail.',
    '마플샵 메인 가기': 'Go to the main page of Marpple Shop.',
    '심사에 참고할 자료가 부족하거나':
      'If the reference documents are insufficient, or improper contents are found, your application may be rejected.',
    '기업 파트너십 문의': `For the inquiry of business partnership, contact <a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>`,
    '셀러신청이 완료되지 않았습니다.':
      "Application for creator hasn’t been complete.<br/>Please try it again, or contact&nbsp;<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>",
    '같은 이메일로 이미 신청되었습니다.':
      "The same e-mail was found in the previous application.<br/>If you haven’t received the e-mail, check the spam box. If you still can’t find it in the spam box, inquire to&nbsp;<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>",
    '마플샵 생성 링크를 전송했습니다.': 'The Marpple Shop creation link has been sent to {{email}}.',
    '전송된 링크를 클릭하여 나만의 마플샵을 만들고 지금 바로 상품도 만들어보세요.':
      'By clicking the link sent, you can create your own Marpple Shop and goods.',
    '2자 이상 20자 이내로 입력해 주세요': 'Enter the field with 2~20 letters.',
    '메일이 오지 않았다면 스팸함을 확인해 주시고, 그래도 없다면 메일로 문의해 주세요.':
      'If you haven’t received the e-mail, check the spam box, and you still can’t find it in the spam box, inquire to&nbsp;<a href="mailto:{{mail}}">{{mail}}</a>.',
    '유튜브, 인스타그램, 트위터 URL 등': 'YouTube, Instagram, Twitter URL, etc...',
    '개인 웹사이트, 노트폴리오, 비핸스 URL 등': 'Your websites, Behance URL, etc...',
  },

  store_header: {
    customer_service: 'Customer Service',
  },

  store_footer: {
    privacy_policy: 'Privacy Policy',
    terms_and_conditions: 'Terms & Conditions',
    faq: 'FAQ',
    customer_service: 'Customer Service',
    about_us: 'About Us',
    seller_studio: 'MarppleShop Studio',
    my_store: 'My Store',
    logout: 'Logout',
    login: 'Login',
    company_name: 'Marpple Corp.',
    detail_info: `
  <p>
    ${MP_ADDRESS_EN}
  </p>
`,
  },

  store_help: {
    customer_service: 'FAQ',
  },

  return_stauts: {
    on_request: 'Request',
    on_process: 'Processing',
    rejected: 'Rejected',
    completed: 'Completed',
  },

  return_type: {
    exchange: 'exchange',
    refund: 'refund',
  },

  marppleshop_designers_status: {
    ON_REQUEST: 'On Request',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
  },

  marppleshop_designers_stores_status: {
    ON_REQUEST: 'On Request',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    DISMISSED: 'Dismissed',
  },

  crew_mall: {
    mo_creator: 'Total Creators<br/><strong class="shop-status__bold">{{ s_count }}</strong>',
    mo_product: 'Products on Sale<br/><strong class="shop-status__bold">{{ p_count }}</strong>',
    pc_title:
      'Over <span>{{p_count}}</span> Merchandises from <span>{{s_count}}</span> Youtube Creators, Artists, and Musicians have been sold!',
    mo_title: 'MarppleShop is where CREATORS, ARTISTS, and MUSICIANS  create and sell official merch!',
    sub_title:
      'Over {{p_count}} Official Merchandises from {{s_count}} Youtube Creators,<br/>Artists, and Musicians have been sold from MarppleShop! ',
    title: '<p>Merchandise Commerce Platform for</p><p>Every Creator in the World</p>',
    '제품 퀄리티': 'Product Quality',
    '굿즈 탐색': 'Search',
    '셀러 신청하기': 'Work With Us',
    'HOT 셀러': 'HOT Creator',
    '마플샵 리뷰': 'Reviews',
    '실시간 상품': 'Top Trending Products',
    'HOT 셀러로 선정된 마플샵 크리에이터를 소개합니다!': 'Introducing Hottest Creators from MarppleShop!',
    '지금 마플샵에서 판매 중인 상품들을 탐색해보세요!': 'Meet Top Selling Products from MarppleShop!',
    '고객 서비스에 대한 엄격한 기준, 최상의 상품과 최고의 커스텀 프린팅':
      "Meet MarppleShop's Quality&nbsp<span>Guaranteed Merchandises.</span>",
    FAQ: 'FAQ',
    고객센터: 'Customer Center',
    'No Store': 'No Store.',
    '마플샵 Content': 'Marppleshop Contents',
    '마플샵의 소식을 전달합니다!': "Here's the news from the Maple Shop!",
    플러스: 'plus',
  },

  Shopify: {
    footer: {
      terms: 'Terms & Conditions',
      privacy: 'Privacy Policy',
      저작권: '',
    },
    product: {
      '다음에 하기': 'Do it later',
      등록하기: 'Register',
      게시하기: 'Publish',
    },
    dashboard: {
      '단 3분이면 가능 !{{- sp}}지금 바로 상품을 만들어보세요.':
        'It only takes 3 minutes!{{- sp}}Start making a product now!', // TODO: 영문 채우기
      '상품 주문 시 사용할 카드를{{- sp}}추가/변경할 수 있습니다.':
        'You can add or change the card{{- sp}}information when ordering products.', // TODO: 영문 채우기
      '내 마플샵 주문 내역은{{- sp}}이곳에서 확인하세요.': 'Check your order{{- sp}}status here.',
      '궁금하신 내용을{{- sp}} 고객센터를 통해 해결해 드립니다.':
        'Check out our FAQ{{- sp}}page for more questions.',
      '내가 만드는 굿즈샵{{- sp}}마플샵을 소개합니다.': `Introducing, "MarppleShop"{{- sp}}Every Creator's Merch Shop`,
      '비밀번호와 연락처 등을{{- sp}}수정할 수 있습니다.':
        'You can change your{{- sp}}Password and Other Information.',
    },
    signup: {
      '약관 전체 동의': 'I agree to All Terms and Conditions',
      '[필수] 만 19세 이상입니다.': "Yes, I'm over 19 years old",
      '[필수] 서비스 이용약관 동의': 'I agree to the Terms of Service',
      '[필수] 개인정보수집 이용 동의': 'I agree to Collection and Use of Personal Information',
    },
    my_info: {
      이름: '이름 en',
      변경: '변경 en',
      비밀번호: '비밀번호 en',
      '비밀번호 변경': '비밀번호 변경 en',
      '휴대폰 번호': '휴대폰 번호 en',
      '휴대폰 번호 변경': '휴대폰 번호 변경 en',
    },
  },

  mypage: {
    마이페이지: 'My Page',
    '크리에이터를 위한 마플샵을 만나보세요.': `Meet with Marpple Shop for creators.`,
    버전정보: 'Version Info',
    현재버전: 'Current Version',
    '신고 접수 서비스': 'Reporting receipt service',
    '이메일 상담': 'E-mail counseling',
    '사이트 이용 시 유의사항': 'Notes on the use of site',
    쇼핑정보: 'Shopping Info',
    '내 샵 가기': 'Go to My Shop',
    '셀러스튜디오 가기': 'MarppleShop Studio',
    '플러스 어드민 보기': 'MarppleShop Admin',
    '셀러 신청하기': 'Apply for creator',
    설정: 'Settings',
    '계정 설정': 'Account Management',
    '최근 본 셀러': 'The creator you recently visited',
    '관심있는 셀러는 좋아요 해주세요.': 'Click “Like” for the creators you are interested in',
    '더 많은 셀러 보러 가기': 'Go to see more creators',
    '관심있는 상품은 좋아요 해주세요.': 'Click “Like” for the goods you are interested in',
    '더 많은 상품 보러 가기': 'Go to see more goods',
    '최근 본 셀러가 없어요.': 'No creators found you recently visited.',
  },

  format: {
    date: {
      short: 'M/d',
      ym_locale: 'MM/yyyy',
      ym_digit: 'MM/yyyy',
    },
  },

  seller_type: {
    유튜버: 'Youtuber',
    틱톡커: 'Tiktoker',
    스트리머: 'Streamer',
    크리에이터: 'Creator',
    인플루언서: 'Influencer',
    일러스트레이터: 'Illustrator',
    타투이스트: 'Tattooist',
    포토그래퍼: 'Photographer',
    '캐릭터 디자이너': 'Character designer',
    '웹툰/만화 작가': 'Webtoon / Webtoonist',
    뮤지션: 'Musician',
    아티스트: 'Artist',
    브랜드: 'Brand',
    '마플샵 플러스': 'Marpple Shop Plus',
    가나다: 'A, B, C',
  },

  mshop: {
    Shopping: 'Shopping',
    Live: 'Live',
    Community: 'Review',
    Review: 'Review',
    PhotoReview: 'Photo Review',
    Search: 'Search',
    Mypage: 'My Page',
    bnb_coupon: 'Nexon cash (3,000 won)',
    'welcome text': 'Hello, {{- name}}',
    Login: 'Login',
    About: 'About',
    Please: '{{- login}}, please',
    Cart: 'Cart',
    Cart2: 'Add to Cart',
    '인기 셀러': 'Best Creators',
    '인기 상품': 'Popular Products',
    'Go to Seller Studio': 'Go to MarppleShop studio{{emoji}}',
    'Go to Apply Seller': 'Apply for the Marpple Shop’s creator{{emoji}}',
    'Go to Seller Apply2': 'Apply for creator',
    '댓글이 등록되었습니다.': 'Reply has been registered.',
    Close: 'Close',
    'Edit My Info': 'Change my info.',
    'All Menu': 'All',
    신규: 'New',
    셀러: 'Creator',
    스타일: 'Style',
    상품상세: 'Product Details',
    Contents: 'Contents',
    'Content Detail': 'Contents',
    'Related Contents': 'Related Contents',
    '베스트 후기': 'Best Reviews',
    '배경화면, 아이콘 등의 디지털 상품은 앱에서 결제할 수 없습니다. 장바구니에서 디지털 상품을 삭제한 후 결제를 다시 시도하시거나 PC에서 구매해 주세요.':
      '<h3>The payment for the digital goods such as computer wallpaper or icon are not available on the App.</h3><p>Please try the payment again after deleting digital goods in the shopping basket, or purchase them on PC.</p>',
    '조건에 딱! 맞는 상품이 없어요.': 'No suitable items were found.',
    '검색조건을 초기화하거나, 바꿔서 검색해 보세요.':
      'Please refresh the search condition,{{- br}}or change the search word.',
    '목록으로 돌아가기': 'Go back to the list',
    '상품 준비 중입니다.': 'Goods are under processing.',
    '상품을 준비하는 동안 마플샵의 상품을 만나보세요!':
      'How about meeting goods of Marpple Shop{{- br}}while goods are being prepared?',
    '마플샵 구경하기': 'Browsing Marpple Shop',
    '주소가 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.':
      'No page. The address may be<br/>wrong, changed, or deleted.',
    '페이지를 찾을 수 없습니다.': 'No page.',
    '잠시 후 다시 이용해 주세요. 서비스 이용에 불편을 드려 죄송합니다.':
      'Please try after a while.<br/>Sorry for the inconvenience.',
    '서버 에러가 발생하였습니다.': 'Server error',
    '이전 페이지': 'Previous page',
    '메인 페이지': 'Main page',
    '#지금이_아니면_안돼_한정판': '#Now_Or_Never_Limited Edition',
    '#난리났네_난리났어_인기상품': '#People_Love it_Hit item',
    '#신상은_타이밍!': '#New_items_Timing!',
    상품목록: 'Product List',
    '셀러명 혹은 상품명으로 검색해보세요.': 'Search with the creator name',
    '어떤 상품을 원하시나요?': 'What are you looking for?',
    '인기 검색어': 'Popular search word',
    '검색 결과가 없습니다.': 'No result',
    '에 대한 검색 결과가 없습니다.': 'No results for <span class="keyword">{{q}}</span>',
    '검색어의 철자나 띄어쓰기가 정확한지 확인해 주세요.':
      'Please check if the spelling and spacing of the search terms are correct.',
    '최근 검색어': 'Recent search word',
    '최근 검색어 내역이 없습니다.': 'You don’t have any recent searches.',
    '등록된 상품이 없습니다.': 'No products here.',
    '스타일 필터': 'Style Filter',
    '게시물을 등록해 보세요!': 'Write a post.',
    '카테고리 선택': 'Select Category',
    Apply: 'Ok',
    신규상품: 'New Products',
    '좋아요 목록에 추가되었습니다.': 'Added to the “Likes” list',
    '좋아요 목록에서 삭제되었습니다.': 'Deleted from the “Likes” list',
    인기순: 'Popular',
    최신순: 'Latest',
    '낮은 가격순': 'Lowest Price',
    '높은 가격순': 'Highest Price',
    '리뷰 많은 순': 'Many reviews',
    '좋아요 순': 'Most liked',
    '최근 팔린 순': 'Recently sold',
    '신규 등록 순': 'The latest registered',
    '첫번째 글을 등록해 보세요!': 'Be a first reviewer!',
    '첫 번째 리뷰어가 될 기회': 'The chance to become a first reviewer',
    '첫 번째 게시물이 될 기회': 'Be the first person to post',
    '게시물을 첫번째로 작성하고 셀러의 댓글을 받아보세요!':
      'Post for the first time and get creator’s reply!',
    더보기: 'View more',
    '마플샵 셀러 신청': 'Apply for the Marpple Shop creators',
    '상품을 구매하고 리뷰를 등록하시면 포인트를 드려요.':
      'If you purchase goods and leave a review, you will get points.',
    채널ON: 'Channel ON',
    '마플샵 바로가기': '{{name}} Marpple Shop',
    '관심있는 셀러는 좋아요 해주세요.': 'Click “Like” for the creators you are interested in',
    '더 많은 셀러 보러 가기': 'Go to see more creators',
    '관심있는 상품은 좋아요 해주세요.': 'Click “Like” for the goods you are interested in',
    '더 많은 상품 보러 가기': 'Go to see more goods',
    '세상 모든 크리에이터가 여기에!': 'All the creators are here!',
    '이벤트 응모가 완료되었습니다!': 'Participation in the event has been complete!',
    '팬심으로 보고, 사심으로 사는 공식굿즈': 'The official merch that buy with affection',
    '마플샵 팝업스토어': 'MarppleShop Popup Store',
    '최애 아티스트와 브랜드의 공식굿즈까지!': "Our favorite artist and brand's official goods!",
    '지금 주목해야 할 크리에이터': 'Creators to pay attention to now',
    '성인 인증이 필요합니다.인증을 진행하시겠습니까?':
      'Verify your age to use this shop.<br />Would you like the age verification?',
    'AGE VERIFICATION': 'AGE VERIFICATION',
    'You must be at least 18 years old to enter this shop!':
      'You must be at least 18 years old to enter this shop!',
    Age: 'Age',
    'I agree to the Marrple':
      'I agree to the Marpple&nbsp;<a href="/@/terms" class="go_terms" target="_blank">Terms &amp; Conditions.</a>',
    "I'll be held responsible for the information I provided.":
      "I'll be held responsible for the information I provided.",
    'I am 18+': 'I am 18+',
    'I am under 18': 'I am under 18',
    'Access Denied': 'Access Denied',
    'Users must be at least 18 to access age-restricted contents.':
      'Users must be at least 18 to access<br>age-restricted contents.',
    Return: 'Return',
    '본인 인증 되었습니다.': 'Your identity has been verified.',
    HomeTitle: {
      '지금 이 순간에도 새로운 상품이 업데이트 중': 'New items are being updated now',
      '미리 미리 알아둬요, 언제 유명해질지 모르니까.':
        'Pick the items in advance because{{- br}}they will be popular any time soon',
      '당신의 마음을 훔치러 온 뉴페이스 👋': 'New face who came to steal your heart 👋',
      '읽다 보니 재밌다. 자세히 보니 후기다.': 'Funny as reading Thoroughly see the review',
      '관심 있을 만한 걸 가져왔어요 💘': 'you will be interested in this 💘',
      '마음으로 낳아 지갑으로 길렀다.': 'Beginning with the heart, and complete with the wallet',
      '요즘은 이런 굿즈가 잘 나가요 😎': 'These merch is popular these days 😎',
      '어차피 할 덕질, 행복하게 덕질하자!': "let's have fun, as long as we fangirl.",
      '직접 구매한 사람들의 후기 ✏️': 'Reviews of direct purchasers ✏️',
      '추천은 내가 할게, 사는건 누가 할래?': 'I will recommend, and who will buy?',
      '늦기 전에 데려가세요! 🏃‍♂️': "Take this before it's too late! 🏃‍♂️",
      '크리에이터를 위한 마플샵을 만나봐': 'Meet with Marpple Shop for creators',
      '마플샵 셀러 신청하기': 'Apply for Marpple Shop creator',
      '한정판 & 마감임박 상품': 'Limited edition & About to be closed',
      '이런 신상 처음이야!': 'I’ve never seen such new goods',
      '최애는 지금 영업 중 🕺': 'My favorite do business now 🕺',
    },
    plusTitle: {
      '가장 많이 찾는 샵': 'The most visited shop',
      '오늘의 브랜드 스토리': 'Today’s brand story',
      '가장 사랑 받는 굿즈': 'The most loved goods',
      '지금은 방송중': 'Now it is on air',
      '고객님의 소중한 리뷰': 'Your precious review',
      '내일이면 못 살 굿즈': 'Goods to be sold out tomorrow',
      '마음을 사로잡을 신상 굿즈': 'New goods to catch my heart',
      '가장 많이 공감 받은 리뷰': 'The most relatable review',
      '주목할 만한 새로운 샵': 'New shop worthy of attention',
    },
    ProductBadge: {
      매진: 'Sold out!',
      한정: 'Limited Edition',
      '??개남음': '{{c}} remaining',
      '??시간전_one': 'in {{count}} hour',
      '??시간전_other': 'in {{count}} hours',
      '??분전_one': 'in {{count}} minute',
      '??분전_other': 'in {{count}} minutes',
      출시예정: 'Coming Soon',
      '출시??': 'Releasing {{d}}',
      마감임박: 'Ending Soon',
      '마감??': 'Ends {{d}}',
      quantity_alert: 'Only {{quantity}} left!',
      date_alert: '{{diff}} days left!',
      time_alert: 'left!',
      soldout_alert: 'Sold out!',
    },
    tag: {
      marpple_product: 'Marpple Shop products',
      seller_product: '셀러상품',
      is_free_shipping_product: '무료배송',
      custom_description: '원하는 대로 커스터마이징하여 주문할 수 있는 상품',
      hand_made_description: '셀러가 직접 손으로 만드는 상품',
      print_on_demand_description: '주문 후 제작을 시작하는 상품',
      marpple_product_description: '마플샵에서 제작하여 배송되는 상품',
      seller_product_description: '셀러가 제작하거나 보유한 상품을 직접 발송하는 상품',
    },
    ProductStatus: {
      pb_me: '마플샵 공개',
      prohibited: 'Sales holding',
      checking: 'Under screening',
      public: 'Disclose',
      private: 'Hide',
      not_confirmed: 'Under inspection',
      ready_confirm: 'Inspection not requested',
      not_use_product: 'Sales holding goods',
      // end_of_sale: 'End of sale',
      end_of_sale: '{{start_at}} ~ {{end_at}}',
      until_close:
        '&nbsp;Until the close&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
      until_open:
        '&nbsp;Until the release&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
    },
    ProductStatusTooltip: {
      pb_me: '셀러샵과 마플샵에 공개되는 상품입니다.',
      prohibited: '저작권 등의 문제로 판매보류된 상품입니다.',
      checking: '디지털 상품은 심사 후 판매할 수 있습니다.',
      public: '셀러샵에서만 공개되는 상품입니다.',
      private: '셀러만 볼 수 있는 비공개 상품입니다.',
      not_confirmed: '판매 담당자에게 검수 요청한 상품입니다.',
      ready_confirm: '판매 담당자에게 검수를 요청해보세요.',
      not_use_product: '{{bp_name}} 제품이 단종되었습니다.',
      // end_of_sale: '판매 기간 종료',
    },
    ProductDetail: {
      tabs: {
        story: 'Story',
        review: 'Review',
        bp: 'Product Info',
        purchaseInfo: 'Notice on the purchase',
        faq: 'FAQ',
      },
      purchaseInfo: {
        title: 'Shipping Info',
        desc: 'All the goods are newly manufactured in custom order and delivered.',
        expect:
          'If you order now, the manufacturing process begins on <span>{{start}}</span>, and the goods are to be shipped out before <span>{{end}}</span>.',
        tpl_expect:
          "If you place an order now, it is scheduled to be shipped between&nbsp;<span>{{start}}</span>&nbsp;and&nbsp;<span>{{end}}</span>.<br />If you order together with Mapple Shop's custom-made products, it will be shipped together according to the custom-made product's shipping date.",
        producing_period: '평균&nbsp;{{avg_days}}&nbsp;/&nbsp;최대{{max_days}}',
        refund_title: 'Exchange/Refund Info',
        refund_desc1:
          'All products delivered by Mapple Shop are custom-made based on your order, so exchanges/refunds due to simple change of mind are not possible.',
        refund_desc2:
          'However, if you receive a defective product or if there is a shipping error, you can contact our customer service within 7 days via email, Individual Inquiry, or through our customer center for exchange or refund.',
        take_back_title: '반송지 정보',
        take_back_mobile: '문의 연락처',
        take_back_price: '반품 배송비',
        take_back_address: '보내실 곳',
        seller_info_title: '셀러 정보 안내',
        seller_name: '셀러명',
        seller_email: 'Email',
        seller_company_name: '상호명',
        seller_business_license_number: '사업자 번호',
        seller_company_tel: '연락처',
        cs_title: 'Customer Center',
        email_title: 'e-mail',
        btn_guide1: 'Quality Assurance Guidelines',
        btn_guide2: 'Quality Warranty Criteria',
      },
      faq: {
        group1: 'Order / Delivery',
        group2: 'Exchange / Refund',
        group3: 'Purchase guide on digital goods',
        q1: 'How long would it take to receive goods after order?',
        a1: 'As all the goods of Marpple Shop are manufactured in custom order, it takes a certain period of time to receive goods. You may check the expected delivery date on the page of goods detail and order details.',
        q2: 'How can I check the order status?',
        a2: `
Login>My page >Purchase detail The manufacturing process of ordered goods and delivery schedule can be checked.<br/>
If you order goods  through 5the steps below manufacturing and delivery are made, and only at the “Processing” status, order change and cancellation can be made.<br/>
<br/>
Processing : As the payment was made  goods are on processing for manufacturing.<br/>
On Manufacturing : Your ordered goods are being manufactured, and from this step order cancellation, /change,/and refund are not available.<br/>
Shipped  : Manufacturing is complete, and the inspection of goods and packaging are started.. <br/>
In delivery : Your ordered goods were shipped out, and delivered  to the logistics company, and the waybill number will be sent to your SMS. From the next day of shipping out, you can search the waybill number to check the delivery status.<br/>
Delivery complete : Delivery has been complete.`,
        q3: 'Is it possible for the non-member to order?',
        a3: `
Non-member can order as well. When placing an order, they will receive the order details via e-mail.<br/>
Through the order details via email, they can check order details and delivery information.<br/>
The Marpple members can use the same ID to use Marpple shop As Marpple Shop members can get a variety of benefits such as points credit and events, be a member.`,
        q4: 'May I cancel orders?',
        a4: `
Given the nature of custom order, the cancellation is not available once the manufacturing of the goods begin. After placing an order, you may change or cancel until the goods are “processing” status,<br/>
but not cancellable after the status. So, please cancel your order before manufacturing begins. You may cancel an order at the following page by clicking “Cancel the order”: Login > My page > Purchase details > View details. Marpple Shop manufactures goods with custom order only to prevent the waste of resources and to make a better environment. Please make a careful order.`,
        q5: 'I’ve received goods. Is exchange/refund available?',
        a5: `
Given the nature of custom order, exchange / refund due to a simple change of mind is not available.<br/>
However, if the goods received is faulty, you may please inquire to customer center of Marpple Shop ({{tel}}) or 1:1 inquiry to get a refund or exchange goods according to the “Act on the Consumer Protection in Electronic Service.” If you attach more than two photos of the faulty part, we will check them in an immediate manner.<br/>
However, exchanging to other goods not the ordered goods, and any deformation of goods due to the negligence in the laundering or damages in printing are not available.<br/>
Please check the laundering method on the page of goods detail.`,
        q6: 'Before purchasing digital goods, please check the following!',
        a6: '<img src="https://s3.marpple.co/files/u_1184090/2021/4/original/40992d192beed7c089fdaaa40136b2b78b1400021.jpg">',
        q7: 'The license of the contents of digital goods lies with the creator.',
        a7: `
All the rights including copyright of the contents lie with the “Marpple Shop creator”.<br/>
The Company doesn’t give any assurance to the copyright of the contents and license.<br/>
Just because buyers purchased the contents, the ownership and copyright are not transferred to them.<br/>
Directly/indirectly using the contents for profit-seeking is not available, and they are not to be used for the purpose of commercial, marketing, and promotion.<br/>
If all of the above is violated, the issues of property right and portrait right might occur, and the purchaser shall take all the liability resulting from it.`,
        q8: 'The purchased contents are available for the personal use only.',
        a8: `
The image purchased is available permanently.<br/>
In principle, the contents are supposed to be used only for one person, and used for non-commercial purpose.<br/>
Unauthorized use for commercial purpose shall be subject to the legal liability according to the copyright law.<br/>
If you want to purchase extended license, please inquire with the creator.`,
        q9: 'Notice on the Prohibition of ID sharing',
        a9: `
In principle, the contents purchased are supposed to be used only for one person.<br/>
Marpple Shop prohibits ID sharing in order to protect personal information of the customers and to prevent unauthorized image sharing issues.<br/>
If ID sharing is identified, the ID holder may be limited from using the service according to the Terms of Service of Marpple Shop, and might be punished according to the copyright law and related laws`,
        q10: 'The digital goods purchased are prohibited from change/editing/sharing, and resale.',
        a10: `
As purchasing digital goods means purchasing only the rights to use, not the copyright of the image, the goods are not to be used for the purpose of redistribution/reallocation/resale.<br/>
The buyer shall not produce the 2nd (printing) copyright work such as goods package, post card, and frames by using the purchased items.<br/>
Uploading and scrapping the purchased contents to the SNS, blog, café, and web hard in its original form for the purpose of redistribution and reallocation.<br/>
If any breach on the use of image was found, and any damages or losses were incurred to the Company or the copyright owner of the image, the buyer shall take all the liability for that, compensating for the damages.`,
        q11: 'The refunds for the digital goods are not available.',
        a11: `
Given the nature of digital contents, the image purchased is not refundable.<br/>
As the refunds due to the different ratio or pixel of the image by device are not available, please precisely check that matters before purchasing.<br/>
All the information including copyright, portrait right, and the range of use for the contents, and the phrases used in the image are provided by the creator, and the Company can’t assure 100% accuracy on them.<br/>
If refunds are made for an exceptional reason, the amount exempt from the commission is refunded.`,
      },
      tpl_faq: {
        a1: "The product is usually shipped within 3-5 business days from the order date. Payments made on public holidays will be processed on the next business day. Please understand that the delivery schedule may vary depending on the courier's circumstances.",
        a2: 'You can check the order status and delivery schedule of the product in "My Page" after logging in.',
        a4: 'Order cancellation is possible until the order is in the processing stage.<br />You can cancel the order by going to "My Page" > "Purchase History" > "View Details" and clicking on the "Cancel Order" button.',
        a5: 'Generally, for regularly sold items, exchange and refund are possible before confirming the purchase. For inquiries regarding exchange and refund, please contact Mapple Shop Customer Service at {{tel}}. We appreciate your understanding.',
      },
      preview_by_device: 'See previews on my phone',
      color: 'Color',
      quantity: 'Qty',
      size: 'Size',
      sold_out: 'Sold out',
      sold_out_products: 'The goods are sold out 😢',
      sold_out_size: 'The size is temporarily out of stock',
      device: 'Device type',
      check_size_guide: 'Check guide!',
      max_purchase_per_user: 'You can purchase up to {{max_purchase_per_user}} products per user!',
      possible_quantity: 'You can put up to {{possible_quantity}} in your cart!',
      already_possible_quantity_title: 'Purchase available per person exceeded',
      already_possible_quantity: "It's either a product that I already bought or it's in your cart.",
      expect: `If you order now, the delivery will begin between {{start}} ~ {{end}}!`,
      producing_period:
        '<strong style="color:#000; margin-right:6px;">제작소요기간</strong><span style="color:#828282;">평균&nbsp;{{avg_days}}일&nbsp;/&nbsp;최대{{max_days}}일</span>',
      total_count: `Price for <span class="dynamic-value">{{count}}</span> EA`,
      left_count: `{{left}} out of {{total}} left! 🤭`,
      coming_soon: 'Drum roll! Available for sale soon 🤩',
      not_approve: 'Purchase is available after the approval on the sales.',
      end_sale: 'Sales closed. 😢',
      only_pc: 'Purchasing digital goods are available on PC only 😉',
      purchased_dtg: '이미 구매한 상품이에요! 🛍',

      size_guide: 'Size guide',
      product_base_info: 'Product Basic Info',
      check_cart_in: 'Will you put it in your shopping basket?',
      opt_prod: 'Goods option',
      preparing: 'Under preparation',
      quality_modal: {
        p1: 'The goods were produced by a thorough process of quality control and process control, and have passed appearance, standards, and physical properties test.',
        p2: 'To extend the life of the goods, be sure to check the warnings on the label of the goods and the laundering methods.',
        p3: 'If there are problems in the quality of the goods, please contact customer center.',
        p4: 'Exchange of the goods or refund should be applied for within 7 days from the receipt.',
      },
      retraction_modal: {
        p1: 'If goods are damaged by the trace of wearing or laundering, smells, stains, or the trace of repairs, or if worn or used even for the faulty goods, refund/exchange of the goods are not available.',
        p2: 'Limitation on the revocation of subscription<br/>(Article 17-②-6 of the law and the Article 21 of the enforcement decree of the same law)',
        li1: 'The size detail may have errors of 1~3 cm, depending on the measuring method and location. The difference between the specified sized on the Mall and the real size within 1~3 cm should not be the reason for refunds / exchange of goods.',
        li2: 'The goods sold on the Marpple Shop are supplied after going through sampling by the brand store and wholesaler, the size could be different from the one of the specific brands you might have. When purchasing by measuring the real size, be sure to check the size detail.',
        li3: 'The size should be measured from one end to the other end by spreading the goods on the floor. For the elastic fabric, the size should be measure by keeping the fabric in tact on the floor, not pulling it. The wrong measuring other than the error range should not be the reason for refunds / exchange of goods.',
      },
    },
    Menu: {
      Home: 'Home',
      Contents: 'Contents',
      Product: 'Product',
      Creator: 'Creator',
      작가: 'Creator',
      Official: 'Official MD',
      All: 'All',
      가나다: 'A, B, C',
      '마플샵 플러스': 'Marpple Shop Plus',
      'Customer Center': 'Customer Center',
      'Order Status': 'Purchase Details',
      Community: 'Review',
    },
    Seller: {
      셀러: 'Creator',
      기타: 'Others',
      명: '{{- count}} creators',
      건: '{{- count}} Qty',
    },
    Footer: {
      CompanyName: 'Marpple Corporation',
      CEO: 'CEO',
      CeoName: 'Hyeyun Park',
      CustomerCenter: 'CS',
      CustomerCenterInfo: '(On weekdays 10:00 ~ 18:00)',
      CompanyRegistrationInfo: 'Business registration number',
      CompanyRegistrationNumber: '105-88-13322',
      Address: 'Address',
      AddressInfo: '823 Bldg. 5F, 70, Nonhyeon-ro 85-gil, Gangnam-gu, Seoul, Korea 06234',
      Email: 'e-mail',
      ReportCenter: 'Reporting Center',
      ApplySeller: 'Apply for Creator',
      TermsAndConditions: 'Terms of Service',
      PrivacyPolicy: 'Privacy Policy',
      Instagram: 'Instagram',
      Youtube: 'Youtube',
      Twitter: 'Twitter',
      Blog: 'Blog',
      MarppleCorp: 'Marpple Corporation',
    },
    Hello: {
      '안녕, 마플샵!': 'Hi, Marpple Shop',
      '3분 샵만들기': '3-minute Shop making',
      '아이디어가 있나요?': 'Do you have an idea?',
      '마플샵이 다 해요!': 'Marpple Shop does all!',
      '당신은 디자인만!': 'You only design!',
    },
    live: {
      '지금 총알 쏘러 갑니다.': 'Now, go for shooting bullets.',
      '라이브 방송중': 'Now On Air',
      '제일 인기있는 셀러': 'The most popular creator.',
      '제일 인기있는 셀러를 보여줘.': 'Show me the most popular creator.',
      '지금 막! 팔렸어요.': 'Just now! Sold',
      '제일 많이 팔린 상품을 보여줘.': 'Show me the most-sold goods.',
      '월간 랭킹': 'Monthly Ranking',
      '내가 이 구역 리뷰왕!': 'I am the review king!',
      '50위까지 펼쳐보기': 'Expand to Top 50',
      '30개까지 보기': 'View up to 30',
    },
    '몇월 몇째주': '{{weeks}} of $t(mshop::month::{{month}})',
    month: {
      1: 'Jan.',
      2: 'Feb.',
      3: 'Mar.',
      4: 'Apr.',
      5: 'May.',
      6: 'Jun.',
      7: 'Jul.',
      8: 'Aug.',
      9: 'Sep.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
    },
    '언제 기준': '<strong>{{date}}</strong>',
    week1: '1st week',
    week2: '2nd week',
    week3: '3rd week',
    week4: '4th week',
    week5: '5th week',
    week6: '6th week',
    '1:1 채팅 문의는 PC 혹은 모바일 웹사이트에서 이용가능합니다. 주소창에 marpple.shop 을 입력해 주세요.':
      'Inquiry by 1:1 chat is available on PC or mobile website. Enter marpple.shop in URL',
    report: {
      title: 'Report',
      complete: 'Report has been received.',
      limit: 'You can write up to 100 letters.',
      placeholder: 'Please fill out the reason for filing the report.',
      noticeTitle: 'Please check before reporting.',
      noticeDescription1:
        'If a group or individual is infringed on the post due to copyright infringement, sale of counterfeit products, or violation of internal regulations of the maple shop, please register it at the Maple Shop > Reporting Center in PC version.',
      noticeDescription2:
        'This application is operated for the purpose of reporting and no separate answers are provided.',
      confirmMessage: 'Do you want to report this comment?',
      reportItem1: "It doesn't fit the theme.",
      reportItem2: 'The information is inaccurate.',
      reportItem3: 'Too much advertising.',
      reportItem4: "There's a lot of swear words and slander.",
      reportItem5: "It's violent or hateful content.",
      reportItem6: 'It includes obscene content.',
      reportItem7: "I don't want to see this user's post.",
      reportItem8: '(If you choose, your posts and comments will not be exposed.)',
    },
    QuickMenu: {
      t1: 'Welcome',
      t2: 'Best Creators',
      t2_mo: 'Best Creators',
      t3: 'Reviews',
      t4: 'Best Ranking',
      t5: 'Art Shop',
      t6: 'Official MD',
      t7: 'OMNUUM NFT',
      t8: '기업셀러신청',
      t10: 'Creators Club',
      t11: 'Youtube Shopping',

      t12: 'Apply for Creator',
      t13: 'Pop-up Store',
      t14: 'Reviews',
      t15: '',
    },
    Styles: {
      t1: 'Style',
      t2: 'can get style, using one merch✨',
      t3: 'You want to make your own and sell them?',
      t4: '지금 신청하면 5,000포인트 지급!',
    },
    meta_title: {
      service_name: 'Marpple Shop', // 아래는 서비스 네임 사용한 번역 쓰기, 서비스 이름이 케이스에 따라서 다른 것으로 바뀔 수 있는데 서비스 네임 스트링을 가지고 진행
      mshop_home: 'MAKE IT REAL | MarppleShop',
      mshop_creator: 'Marpple Shop Creator',
      mshop_contents: 'Marpple Shop Contents',
      mshop_products: 'Marpple Shop Product',
      review: 'Marpple Shop Review',
      creator: 'Marpple Shop Creator',
      contents: 'Marpple Shop Contents',
      products: 'Marpple Shop Product',
      live: 'Marpple Shop Live',
      search: 'Marpple Shop Search',
      registration: 'Marpple Shop Apply',
    },
  },

  report: {
    '신고 접수 서비스 안내': 'Notice on the reporting service',
    '서비스 안내': 'Notice on the service',
    '신고 접수하기': 'Receive reporting',
    조회하기: 'Search',
    '마플샵에서 판매 중인 상품이 제3자 저작물의':
      'It is the service that reporting is received for the case where it is concluded that the goods on sale in the Marpple Shop infringed on the portrait right or were made with unauthorized use of the third party’s copyrighted work, and where the breach of internal policy of the Marpple Shop was made by the creator.',
    '해당 상품이 실제로 제3자의 권리를 침해하였는지 여부는': `Whether the goods, in fact, infringed on the third party’s rights can’t be judged by Marpple Shop, but be investigated and determined by the related institution with qualification, and follow-up measure shall be take accordingly. Marpple Shop temporarily holds the goods from being sold until the final ruling from the related institution is made and operates the reporting service in order to prevent any potential infringement on the rights.`,
    '신고 접수 서비스는 고객님을 보호하기 위한 임시적인 방법': `As the reporting service is just a temporary method to protect customers, the customers may seek for counseling and relief procedure through related institutions for the ultimate resolution.`,
    '신속하고 정확한 신고접수 서비스 운영을 통해': `Marpple Shop is trying to do its best by operating a reporting service in a swift and accurate manner as a commerce platform service provider, while protecting all the related parties until the disputes between them are reasonably resolved through the due procedures.`,
    '관련기관 홈페이지': 'Related institution’s homepage',
    '이미지를 클릭 하면, 해당 홈페이지로 이동합니다.': 'Click on the image to go to the homepage.',
    '신고 접수 서비스를 접수할 수 있는 대상': 'The subjects who can apply for the reporting.',
    '① 저작권침해': `<strong>① Copyright infringement</strong> Those (individual or group) who have due rights to the copyrighted work under infringement and those who created the original work for the goods used without permission`,
    '② 위조상품 판매': `<strong>② Forgery goods sales</strong> The owner of the right or the proxy who are authorized to ace on behalf of the owner, for the case where goods on sale violated trademark or design right or forgery goods are sold`,
    '③ 마플샵 내부 규정 위반': `<strong>③ reach of internal policy of the Marpple Shop</strong> Those who can prove the breach of the internal policy of the Marpple Shop by the minor creators under the age of 14, or the creators who are not the owner of the copyright while doing a business under the name of others`,
    '신고 접수 시 처리 과정': 'The process made after the reporting is received',
    '저작권 침해 / 위조 상품 판매 (상표권·디자인권 침해)':
      'Infringement on the copyright / Sales of forgery goods (Infringement on trademark and design right)',
    '저작권 침해 신고 접수 시 유의사항': 'Notes on the reporting for the copyright infringement',
    '저작권침해 신고 접수 시 해당 상품을 판매 중인': `When reporting on the copyright infringement was received, the reason for the reporting (as a “corporate name or group name which have the rights” for a group / as a “the holder of the rights” for an individual) shall be notified to the creator and the reported goods will be hidden status on the Mall.`,
    '셀러가 저작권자이거나 정당한 권리자임을 증빙': `Only when creators are the owner of the copyright or those who are entitled to obtain the right, the request for resale is available. (The request for resale should be made within 14 days, and if not, the sales are hold)`,
    '신고 접수 스레드는 요청자-셀러 간의 합의를 위한 공간': `The reporting thread is a space where request and creators can reach an agreement, making a settlement, and Marpple Shop doesn’t involve in the matters other than creating thread for them, as it doesn’t have copyright.`,
    '저작권법 제 103조 제 3항 및 동법 시행령': `According to the Article 103-③ of the Copyright Law and Article 43-② of the enforcement decree of Copyright Law, Marpple Shop notifies those who receive the reporting of the expected date (7 days after receiving the request for resale), and the goods are restored on the expected date.`,
    '재판매된 상품에 대해서는 반복하여 신고 접수를 할 수 없으며': `Another reporting is not available for the resale goods any more. If any dispute occurs, The parties may seek for the settlement between the parties, the deliberation by the related administrative institutions, or the rulings of court.`,
    '위조상품판매 신고 접수 시 유의사항': 'Notes on the reporting for the forgery goods sales',
    '위조상품에 대한 조치 요청은 상표법 제107조':
      'Only the right holders can report and request action for the forgery goods according to the Article 107 of the Trademark Law (Right of injunction for the infringement on the rights) and the Article of 113 of the Design Protection Law.',
    '상표법 제108조':
      'If the reported case is applicable to the Article 108 of the Trademark Law (Behaviors considered as an infringement) and the Article 114 of the Design Protection Law (Behaviors considered as an infringement)',
    '위조상품 판매로 인한 조치 시 해당 상품을':
      'When taking action on the forgery goods sale, we notify the creator who are selling the goods of what the infringed right is (as a “corporate name or group name which have the rights” for a group / as a “the holder of the rights” for an individual)',
    '셀러는 위조 상품이 아니라 정당한 상표 등의':
      'Creators can register answers to the thread only when they are able to prove their selling goods are not forgery goods, but a real brand.',
    '마플샵 내부 규정 위반': 'Breach of the internal policy of the Marpple Shop',
    '마플샵 내부 규정 위반 신고 접수 시 유의사항':
      'Notes on the reporting for the breach of the internal policy of the Marpple Shop',
    '마플샵 내부 규정 위반 신고 접수 시 해당 상품을 판매 중인':
      'When the reporting on the breach of the internal policy of the Marpple Shop was received, the reason for the reporting shall be notified to the creator and a hidden thread is created for that.',
    '셀러가 내부 규정 위반 사실이 없음을 증명할 수 있는 자료를 첨부한':
      'If the creator registers the answer proving there is no breach of the internal policy of the Marpple Shop, such answer shall be reviewed internally, and the reporting is resolved.',
    '14일 이내 답변 등록이 되지 않은 경우와 심사 후 규정 위반':
      'If the answer is not registered within 14 days and the breach of the internal policy is identified after deliberation, shop operation is held.',
    본인인증: 'Identity verification',
    '신고 접수 서비스는 무분별한 상품 판매 정지 또는 샵의 운영 정지를 방지하고':
      'The reporting service requires identity verification in order to prevent the goods sales and shop’s operation from being indiscriminately held and to check the will of the parties for the reporting process.',
    '본인 명의로 가입된 휴대전화를 통해 본인 인증을 할 수 있습니다.':
      'The identity verification is available through the mobile phone registered by the same identity.',
    '신고 접수 조회': 'Search for reporting',
    '고객님의 신고 접수 요청 내역이 확인되지 않습니다.': 'Your application for the reporting is not found.',
    '1. 신고 접수 유형 선택': '1. Select reporting type',
    '유형 사유/유형 별 요청 서식이 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 진행되지 않습니다.':
      'As the request form is different by reason of type / type, if you don’t select the field accurately, the reporting may not proceed.',
    '저작권침해 (고객님의 작품, 콘텐츠 등을 침해한 상품 관련)':
      'Copyright infringement (regarding the goods that infringed on your copyrighted work and contents)',
    '위조상품 판매 (상표권/디자인권 침해 상품 관련)':
      'Forgery goods sales (regarding the goods that infringed trademark right / design right)',
    '마플샵 내부 규정 위반 (연령제한, 타인 명의로 운영 중인 샵 관련)':
      'Breach of the internal policy of the Marpple Shop(age limit, operating shops under the name of others)',
    '2. 권리 침해를 받은 당사자 선택': '2. Select the party whose rights were infringed.',
    '단체/개인에 따라 필요 서류가 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 되지 않습니다.':
      'As the required documents are different by group/individual, if you don’t select the field accurately, the reporting may not proceed.',
    '단체 (기업, 사업체, 종교단체, 학교 등)':
      'Group (corporations, businesses, religious group, school, etc.)',
    '개인 (본인, 가족, 친척, 지인 등)': 'Individual (the person concerned, family, relatives, friends, etc.)',
    '3. 필요 서류 안내 및 구비 여부 선택':
      '3. Notice on the required documents and selection of whether they are prepared',
    '신고 접수 시 접수 유형과 당사자 선택에 따라 필요 서류가 달라지며 서류 구비가 되지 않은 경우, 신고 접수가 진행되지 않습니다.':
      'Depending on the selection of reporting type and the selection of the party, the required documents are different, and if they are not prepared, the reporting may not proceed.',
    '① 저작권 침해 (고객님의 작품, 컨텐츠 등을 침해한 상품 관련)':
      '①Copyright infringement (regarding the goods that infringed on your copyrighted work and contents)',
    '해당 셀러의 저작권 침해 사실을 증빙할 수 있는 자료 첨부':
      'Attach the materials proving that your copyrights has been infringed by the creator',
    '기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부':
      'For corporations / group, attach a copy of business registration certificate and corporate registration certificate',
    '접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부':
      'If you are not the representative of the group, a copy of power of attorney with the seal of the group or signature of the representative on it.',
    '② 위조 상품 판매 (상표권/디자인권 침해 상품 관련)':
      '② Forgery goods sales (regarding the goods that infringed trademark right / design right)',
    '상표권/디자인권 및 해당 셀러의 침해 사실을 증빙할 수 있는 자료 첨부':
      'Attach the materials proving that your trademark / design rights has been infringed by the creator',
    '③ 마플샵 내부 규정 위반 (연령 제한, 타인 명의로 운영 중인 샵 관련)':
      '③ Breach of internal policy of the Marpple Shop (age limit, operating shops under the name of others)',
    '해당 셀러가 마플샵 내부 규정을 위반했음을 증빙할 수 있는 자료 첨부':
      'Attach the materials that help prove the creator violated the internal policy of the Marpple Shop',
    '서류 구비 완료': 'Documents are prepared',
    '서류 구비가 되지 않음': 'Documents are not prepared',
    '본인 인증 후 신고 접수하기': 'Apply for the reporting process after verifying identity',
    '본인 인증 후 신고 조회하기': 'Search for reporting process after verifying identity',
    '요청 처리 불가': 'You can’t proceed the request.',
    '신고 접수 유형을 선택해 주세요.': 'Select the reporting type',
    '권리 침해를 받은 당사자를 선택해 주세요.': 'Select the party whose rights were infringed.',
    '서류 구비가 되지 않으면 요청을 처리할 수 없습니다.':
      'If you don’t submit documents, the reporting request can’t be handled. Apply for the reporting process again when necessary documents are prepared.',
    '신고 접수 유형': 'Reporting type',
    '권리 침해를 받은 당사자': 'Those whose rights were infringed',
    '신고 접수자': 'Reporting receiver',
    '상표권/디자인권 보유자': 'Owner of the trademark / design right',
    '개인, 법인명, 단체이름 등을 입력해 주세요.':
      'Enter the name of the individual, corporations, and group.',
    'ⓘ 위의 이메일과 연락처':
      'ⓘ The process of the reporting will be sent to the e-mail and contact above. Be sure to describe in an accurate manner.',

    '해당 샵 URL': 'URL of the shop at issue',
    '해당 상품 URL': 'URL of the goods at issue',
    '존재하지 않는 주소입니다.': 'No page found',
    '마플샵 주소를 다시 확인해 주세요.': 'Check the address {{type}} of Marpple Shop again.',
    '신고 사유': 'Reason for reporting',
    '원활한 신고 접수를 위하여 내용을 구체적으로 적어주세요.':
      'Describe in detail for a smoother reporting procedure',
    '필요 서류': 'Required documents',
    'ⓘ 상표권 / 디자인권':
      'ⓘ Attach the materials that can prove the infringement on the trademark / design right by the creator (File size: Less than 10MB)',
    '인증 문제가 있습니다. 다시 시도 부탁드립니다.': 'Errors found in the verification Try it again.',
    '단체인 경우 사업자등록증':
      'For a group, not an individual, attach a copy of evidence such as business registration certificate or corporate registration certificate.',
    '신고 접수 후 내용을 수정할 수 없습니다.':
      'After the reporting is received, it is not editable.<br/>Will you continue?',
    '신고 접수가 완료되었습니다.': 'Reporting has been received.',
    '스레드 생성이 완료 되었습니다.':
      'Thread has been created. When it comes to the reporting, the answer and reply of the creator can be made only in the thread.',
    '비공개 스레드 바로가기': 'Go to hidden thread.',
    '내부 규정 위반 여부 확인':
      'After checking whether the internal policy has been violated or not, we will notify you of the result via e-mail. Thank you.',
    '스레드 종료': 'Thread closed',
    '재요청 기간이 몇일 남았습니다.': 'The period to make a request again is {{- days}} days left.',
    '답변 기간이 몇일 남았습니다.': 'The period for the answer is {{- days}} days left.',
    '신고 대상 셀러': 'The creator reported',
    '신고 상세 내용': 'Reporting Details',
    '답변 대기': 'Answer on standby',
    '판매 재개': 'Resume Sales',
    '판매 중지': 'Sales Hold',
    '내부 규정 위반 답변완료': 'Answered for the breach of the internal policy',
    '샵 정상 운영': 'Normally operate the shop',
    '샵 운영 중지': 'Hold operating the shop',
    답변완료: 'Answered',
    '판매 재개/샵 정상 운영': 'Resume sales / Normally operate the shop',
    '판매 중지/샵 운영 중지': 'Sales hold / Shop operation hold',

    '경찰청 사이버안전국': 'National Police Agency Cyber Security Bureau',
    '생년 월일': 'Date of birth',
    연락처: 'Contact',
    이메일: 'e-mail',
    '이메일을 입력해 주세요.': 'Please enter your e-mail.',
    '파일 첨부': 'Attachments',
    '단체 증명 자료': 'Group proof',
    스토어: 'Store',
    상품: 'Goods',
    '신고 접수에 대한 답변이 완료되었습니다.': 'The response to the report has been completed.',
    '해당 상품 판매가 재개되었습니다.': 'Sales of the product have resumed.',
    '이의신청 내용을 검토하여 알려드리겠습니다.': 'We will review the appeal and notify you.',
    '저작권 침해': 'Copyright infringement',
    '위조상품 판매': 'Sale of counterfeit products',
    개인: 'Personal',
    단체: 'Group',
    '신고 조회하기': 'View report',
    '신고 접수는 PC에서만 가능합니다.': 'Report submission is possible only on PC.',
    한국저작권위원회: 'Korea Copyright Commission',
    '1. 저작권침해': '1. Copyright infringement',
    '저작물에 대한 정당한 권리가 있는 당사자(개인 및 단체) 및 무단 도용된 상품에 대한 원본 제작 당사자':
      'Those (individual or group) who have due rights to the copyrighted work under infringement and those who created the original work for the goods used without permission',
    '2. 위조상품 판매': '2. Forgery goods sales',
    '판매 중인 상품이 상표권 또는 디자인권을 위반한 경우 해당 권리자 또는 권리자로부터 위임 받은 대리인':
      'The owner of the right or the proxy who are authorized to ace on behalf of the owner, for the case where goods on sale violated trademark or design right or forgery goods are sold',
    '3. 마플샵 내부 규정 위반': '3. reach of internal policy of the Marpple Shop',
    '만 14세 미만 미성년자 셀러, 저작권자가 아닌 타인의 명의로 운영 중인 셀러의 위반 사실을 입증할 수 있는 자':
      'Those who can prove the breach of the internal policy of the Marpple Shop by the minor creators under the age of 14, or the creators who are not the owner of the copyright while doing a business under the name of others',
    '4. 본인 인증': '4. Identity verification',
  },

  about: {
    '게시글이 없어요.': 'No posts found.',
    '님의 갤러리를 기다려주세요.': 'Wait for {{- store_name}}’s gallery.',
    '님, 갤러리를 등록해 보세요!': '{{- store_name}}, how about registering your gallery?',
  },

  common: {
    lang: {
      kr: 'Korean',
      en: 'English',
      jp: 'Japanese',
    },
    unit: {
      krw: 'KRW',
      usd: 'Dollar',
      jpy: 'Yen',
    },
    action: {
      save: 'Save',
      do_save: 'Save Changes',
      cancel: 'Cancel',
      do_cancel: 'Cancel',
      regist: 'Register',
    },
    reveal_type: 'Disclosure (Y/N)',
    public: 'Disclose',
    private: 'Hide',
    all_cate: 'All Category',
    seller: 'Creator',
    save: 'Save',
  },

  s_about: {
    edit_modal_title: 'Profile Setting',
    sns_label: 'SNS',
    sns_holder: 'Enter the SNS URL',
    desc_label: 'Introduce',
    desc_holder: 'Enter Introduce',
    rep_label: 'Representative image/video',
    rep_image: 'Register image',
    rep_video: 'Register video URL',
    rep_video_holder: 'Youtube, Twitch, Africa video address',
    rep_tip: 'ⓘ Register either representative image or video.',
    copy_link: 'Copy link',
    url_copied: 'URL has been copied to the clipboard.',
    url_copied2: 'Address was copied. Paste on the place you wish',
    url_failed: 'URL copy failed.',
    edit_profile: 'Profile editing',
    write: 'Post',
    completed: 'Edited.',
    failed: 'Save failed. Try again.',
    wrong_url: 'Wrong address',
  },

  studio: {
    bank_account_required_product: 'You can create goods only when having registered account info.',
    title: 'MarppleShop Studio',
    marpple_seller: 'Marpple Shop creator',
    type_mobile: 'Enter phone number in the account info.',
    '아쉽게도 이번에는 마플샵 셀러로 모시지 못하게 되었습니다.': `We regret to inform you that you are not selected for the Marpple Shop creator this time.&nbsp;<span class="m_br"></span>We are looking forward to seeing you with a better opportunity in the future.`,
    need_allow:
      'Decorate the shop and create goods.&nbsp;<span class="m_br"></span>All the legal liabilities on the copyright lies with creators.',
    possible_device_change: 'Device type change is available',
    select_category: 'Select goods category',
    shop_admin: {
      발급: '발급',
      'API Key 발급': 'API Key 발급',
      'Key 발급일시': 'Key 발급일시',
      '연동 컨설팅사': '연동 컨설팅사',
      '외부 서비스와의 연동을 관리하는 페이지 입니다.': '외부 서비스와의 연동을 관리하는 페이지 입니다.',
      '복사되었습니다.': '복사되었습니다.',
      '약관 동의': '약관 동의',
      '약관 보기': '약관 보기',
      'REST API Key 발급이 완료되었습니다.': 'REST API Key 발급이 완료되었습니다.',
      'REST API Key 안내':
        '고객님께서 가입하신 연동 컨설팅사에<br />발급된 REST API Key를 복사하여 서비스 연동을<br />등록해 주시기 바랍니다.',
      '이미 발급되었습니다.': '이미 발급되었습니다.',
      '이미 연동되었습니다.': '이미 연동되었습니다.',
      '외부 서비스 연동은 PC에서만 확인 가능합니다.': '외부 서비스 연동은 PC에서만 확인 가능합니다.',
      'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.':
        'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.',
      '연동 컨설팅사 해제': '연동 컨설팅사 해제',
      '연동 컨설팅사와 연동을 해제하시겠습니까?': '연동 컨설팅사와 연동을 해제하시겠습니까?',
    },
    menus: {
      make_products: 'Create Design Goods',
      products: 'Create Goods',
      orders: 'Order History',
      seller_product: "Creator's Product",
      real_pb_products: 'My Product Registration',
      inventory_management: 'Inventory MGMT',
      pb_orders: 'Order MGMT',
      pb_item_orders: 'Delivery MGMT',
      pb_refund_orders: 'Refunds MGMT',
      digital_product: 'Digital Goods',
      make_digital_products: 'Create Design Goods',
      question_list: 'Customer Contact MGMT',
      shop_management: 'Shop MGMT',
      styles: 'Shop Design',
      arrangement: 'Arrange Goods',
      shop_info: 'Shop Info',
      ecommerce_info: 'Creator Info',
      open_api: 'OPEN API Key',
      adjustments: 'Settlement MGMT',
      design_adjustments: 'Settlement of Design Goods',
      seller_adjustments: 'Settlement of Creator Goods',
      tpl_adjustments: 'Settlement of Receiving Goods',
      dtg_adjustments: 'Settlement of Digital Goods',
      withdraws: 'Withdrawal application',
      bank_account: 'Register account info.',
      analytics: 'Statistics',
      analytics_by_prod: 'Sales analysis by goods',
      my_shop: 'View My Shop',
      youtube: 'Sell On Youtube',
      seller_guide: `Creator Guide`,
      seller_tip: 'Tips for creators',
      faq: 'FAQ',
      solution: 'Receive Reporting',
      invite: 'Invite Friends',
      creator_community_club: 'Creator Community Club',
      update_note: 'Update Note',
      invite_info: 'Invite Creator',
      account: 'Account Info',
      marketing: 'Marketing',
      help: 'Customer Center',
      mp_shop: 'MarppleShop',
      contact_marppleshop: 'Contact MarppleShop',
      logout: 'Log out',
      designer: 'Designer',
      adobe_illustrator: `Illustrator Download`,
    },
    footer: {
      privacy: 'Privacy Policy',
      terms: 'Terms of Service',
      p: 'The copyright of the Marpple Shop contents belongs to the owner of the copyright and the provider. Unauthorized use and theft may hold you legally liable according to the Copyright Law.',
    },
    form: {
      title1: 'Option name',
      title2: 'Add money (optional) Won / Dollar / Yen',
    },
    shop_info: {
      p1: '현재 운영중인 샵의 기본 정보들을 관리하는 페이지 입니다.',
    },
    ecommerce_info: {
      p1: '셀러 배송 상품 판매를 위한 정보를 입력하는 페이지 입니다.',
    },
    seller_info: {
      certification: '',
      '개인 셀러 정보': '',
    },
    styles: {
      p1: 'Change profile, logo, and banner to design your shop.',
      btn1: 'Change shop design',
    },
    products: {
      p1: 'Just 3 minutes are enough!',
      p2: 'Today, what goods would you like to make?',
    },
    real_pb_products: {
      p1: '나만의 상품 등록 시작!',
      p2: '아이디어를 상품으로 만들어 볼까요?',
    },
    digital_products: {
      p1: 'Sell a wide range of digital products easily and quickly!',
      p2: 'Should I make my own digital products?',
      p3: 'Please select a digital product to sell!',
      p4: 'You can easily and quickly sell a variety of digital products at MarppleShop.',
      cate_wall_paper: 'Wallpaper',
      cate_voice_pack: 'Voice pack',
      cate_good_note: 'Good note',
      cate_digital_asset: 'Digital asset',
      header: 'Product Registration',
      header_modify: 'Product modification',
      required: 'marking is mandatory.',
      no_image_info_title: 'No images registered.',
      no_image_info_contents: `
      Please upload an image to register as a product image.<span class="m_br w_br"></span> Image Recommended Size :
      square 740px ~ 1480px<span class="m_br w_br"></span>
      Uploadable Format : jpg, png / Capacity : 5MB less
    `,
      image_upload_btn: 'Upload Image',
      set_first: 'Designate as representative image',
      recommend_size:
        'Image Recommended Size : square 740px ~ 1480px / Uploadable Format : jpg, png / Capacity 5MB less',
      delete_image: 'delete_image',
      btn_register: 'Product Registration',
    },
    make: {
      p1: 'Pick the goods and design them.',
      p2: 'As luxury brand goods, it uses the optimal printing method.',
      p3: 'A variety of categories are prepared.',
      p4: 'Marpple Shop is continuously updating goods.',
    },
    invite: {
      p1: 'Send a creator invitation letter to your friends you need Marpple Shop.',
      p2: 'We will give both of you 5,000 points that can be used for purchasing samples.',
      p3: 'Those who receive an invitation letter from {{name}} will be given the benefits of opening the shop right away without application process and 5,000 points that can be used for purchasing samples.',
      p4: 'If your invitee makes goods and launch an operation in Marpple Shop, we will give 5,000 points to {{name}} to show our gratitude.',
      p5: 'The number of invitation letter left is <span class="count">{{count}}</span>.',
      p6: 'Enter the name or nickname of the invitees.',
      b1: 'Invitation complete',
      t1: 'Invite via e-mail',
      t2: 'Create an invitation link',
      l1: 'Send an invitation letter',
      l2: 'Create an invitation link',
      l3: 'Copy link',
      '이메일 주소를 확인해 주세요.': 'Check the e-mail address.',
      '이메일 주소가 맞는지 확인해 주세요':
        'Check if it is the right e-mail address. Will you send an invitation?',
      '초대 링크를 생성하시겠습니까?': 'Will you create an invitation link for {{name}}?',
    },
    invite_info: {
      p1: '{{name}} invited you to the Maple Shop creator!',
      p2: 'If you make a shop and make a product, we will quickly approve it after reviewing the printing suitability and copyright issues.',
      p3: 'As an invitation from {{name}}, you will also receive 5,000 points that can be used to purchase samples.',
      p4: 'Please check it out!<br />Sales approval is required for the invited creator to fully engage in activities.<br />For approval of sales, please wait after making the shop design and product.<br />(Sales approval takes 2 to 4 business days)',
      t1: 'Curious about the MarppleShop?',
      t2: "Curious about other creators' MarppleShop?",
      t3: 'Please enter the information below to open the MarppleShop',
      l1: 'Go to MarppleShop Home',
      la1: 'Shop Name',
      la2: 'Mobile',
      la3: 'MarppleShop domain name (2 or more English/numeric characters and less than 20 characters)',
      la4: 'SNS url (optional)',
    },
    marketing: {
      p1: 'Agree to the disclosure on Naver Shopping',
      agree: 'Agree',
      not_agree: 'Disagree',
      modify: 'Change',
    },
    account: {
      p1: 'Your account information will be encrypted and safely stored.',
      email: 'e-mail',
      save_changes: 'Change e-mail',
      pw: 'Password',
      reset_pw: 'Change password',
      name: 'Name',
      brand_name: 'Brand name',
      phone: 'Mobile number',
      store_url: 'Shop URL',
      modify: 'Change',
      e1: 'Enter a valid domain',
      my_point: 'Point reserves',
    },
    orders: {
      p1: 'You can check the order details that occurred for the week.',
      t1: 'Weekly sales analysis by goods',
      empty: 'Oh, you have no orders yet.',
    },
    analytics: {
      p1: 'Check the sales ranking for the goods you made',
    },
    table: {
      th1: 'Ordered period',
      th2: 'The number of orders',
      th3: 'Cancelled orders',
      th4: 'Qty',
      th5: 'Cancelled units',
      th6: 'Profits',
      th7: 'Extra profits',
      th8: 'Total profits',
      th9: 'Creator’s profits',
      th10: 'Corporate profits',
      th11: 'Ranking',
      th12: 'Goods Name',
      th13: 'Sales volume',
      th14: 'Disclosure (Y/N)',
      th15: 'Settlement date',
      th16: 'Settlement money',
      th16_1: 'PG commission',
      th16_2: 'MarppleShop fee',
      th16_3: 'Sales fee',
      th16_4: 'Stock fee',
      th17: 'Processing status',
      th18: 'Sum',
      th19: 'Type',
      th20: 'Date',
      th21: 'Store',
      th22: 'Design profits',
      th23: 'Additional design profits',
      th24: 'Total design profits',
      th25: 'Creator’s design profits',
      th26: 'Design profits of corporation',
      th27: 'Discount profits of corporation',
      th28: 'Total profits of corporation',
      th29: 'Total withdrawal amount',
      th30: 'Requested date and time',
      th31: 'Withdrawal Requested amount',
      th32: 'Actual amount received',
      th33: 'Expected money receiving date',
      th34: 'Date and time of receiving money',
      th35: 'Cancelled date and time',
      th36: 'Reason',
      th37: 'Processing status',
      th38: 'Amount',
      th39: 'Account info.',
      th40: 'Processed date and time',
      th41: 'Function',
      th42: 'Name',
      th43: 'Status',
      th44: 'Consumer sales amount.',
    },
    adjustments: {
      long_format: 'MM yyyy',
      short_format: 'MM yyyy',
      p1: 'Settlement details are calculated as of the shipping-out date for sale on a monthly basis.',
      p2: '셀러 상품의 정산 내역은 구매확정일을 기준으로 월별 산정됩니다.',
      btn1: 'Change dates',
      release: 'Shipped out',
      change: 'Change',
      confirmed: 'Purchase confirmation',
      shipping: 'shipping cost',
      release_date: 'Release Date',
    },
    withdraws: {
      p1: 'You can withdraw the sales profits created during a month.',
      p1_1: 'You can apply for withdrawal of the cumulative settlement amount.',
      p2: 'Amounts available for withdrawal',
      p3: 'You can check the amount requested for corporate withdrawal, and apply for it',
      p4: 'Check the requested withdrawal amount for the creator’ profit, and proceed with settlement.',
      t1: 'Creator withdrawal',
      btn1: 'Withdrawal request',
      btn2: 'Edit',
      btn3: 'Proceed',
      btn4: 'Approve',
      btn5: 'Excel download',
      btn6: 'Change dates',
      approve: 'Approve',
      finish: 'Complete',
      cancel: 'Cancel',
      pending: 'Processing',
      tt_title: 'Actual amount received',
      lb1: 'Total money received',
      lb2: 'Balance',
      opt1: 'Verification (Y/N)',
      opt2: 'Verified withdrawal',
      opt3: 'Unverified withdrawal',
      opt4: 'Bank account type',
    },
    arrangement: {
      only_pc: `The goods alignment function is available on PC only.`,
      desc: `Drag any goods on sale in my shop , and arrange them as you wish.`,
      desc2: `You may store goods here by dragging them, moving them to the left shop area to easily arrange them.`,
    },
    tooltip: {
      h2: `
<h4>굿즈 정산</h4><div>출고 날짜 기준으로 월별 산정됩니다.</div><br/>
<h4>셀러 상품 정산{{tpl_adjustments}} & 디지털 상품 정산</h4><div>구매확정일을 기준으로 월별 산정됩니다.</div>`,
      t1: 'We will give you additional profits by discounting production costs according to the sales volume for a week. When setting the design price, additional proceeds from the product are automatically calculated.',
      t2: `<h4>Settlement of Design Goods</h4><div>Design price + extra profits – PG commission(3.52%)<br/>= Settlement amount<br/>For entrepreneur creator, the money withdrawal is available after the tax invoice was issued.
</div>`,

      t3: `Profit - MarppleShop fee - PG commission(3.52%) = Settlement amount`,
      t3_1: `수익 - 입고 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t3_2: `수익 - 판매 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t4: `Production costs will be discounted depending on the sales volume for a week.<br/>When setting my profits, the production cost discount per unit and my profits are automatically calculated in the chart.`,
      t5: 'If you want limited sales for some goods, you can use the settings. If you don’t tick the checkbox, it is sold at all times.<br/>You can set the limited units and period, raising the goods’ scarcity as limited goods.',
      'PG사 수수료': 'PG commission',
      'PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.':
        'PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.',
      '셀러 상품 정산': 'Creator product settlement',
      t6: `<div>Design product calculation amount + creator product settlement amount = Estimated withdrawal amount </div><br/><h4>What is "Design product calculation amount"?</h4><br/><div>- Individual creator<div>Design product settlement amount - VAT (10%) - Withholding tax (3.3%)<br/>- Design product settlement amount </div>- Business creator<div>- Design product settlement amount = Design product calculation amount<br/> 사업 For business operators, withdraw money after issuing tax invoice.</div></div>`,
    },
    youtube: {
      '유튜브에서 판매': 'Sold at Youtube',
      '유튜브에서도 마플샵 상품을 판매해 보세요.': 'Sell the goods of MarppleShop in Youtube as well.',
      '유튜브 채널 ID를 입력해 주세요.': 'Enter the Youtube Channel ID.',
      '이미 등록된 유튜브 채널 ID 입니다.': 'Already registered YouTube channel ID.',
      '올바른 채널 ID를 입력해 주세요.': 'Enter the right channel ID.',
      '새로고침 후 다시 시도해 주세요.': 'Please refresh and try again.',
      '유튜브 스튜디오에서 상품을 공개해 주세요.': 'Disclose the goods on the Youtube Studio.',
      '채널 ID 확인': 'Check Channel ID',
      '채널 ID 삭제': 'Delete Channel ID',
      '저장되었습니다.': 'Channel ID saved',
      '유튜브 상품 기능 사용 조건을 확인해 주세요.':
        'Check the condition that you can use the Youtube goods’ function',
      'delete-confirm':
        'Your YouTube account will be disconnected after 1-3 days. Are you sure you want to delete your channel ID though?',
      '셀러 스튜디오에 공개 중인 상품만 연동 프로세스가 진행됩니다.':
        'Only the goods disclosed on the MarppleShop Studio is interlocked to the Youtube account.',
      '유튜브 팀에서 1~3일간 1차 심사를 완료하면 유튜브 스튜디오에서 상품을 확인할 수 있습니다.':
        'If the initial screening is complete by the Youtube team for 1~3 days, you may see the goods on the Youtube Studio.',
      '만 18세 이상 & 구독자 수 1,000명 이상이어야 합니다.':
        'You should be 18 years old or over, and the number of the subscriber to your channel should be more than 1,000.',
      'info-2':
        'You can post the goods on the bottom of your video, store tab, and live broadcast, etc.{{- br}}If the 2nd screening is complete by the Youtube team for 1~5 days, the goods will be disclosed to the viewers and you may sell the goods on Youtube.',
      'correct-info': 'You can check it on the "{{- check}}".',
      'YouTube 스튜디오 > 수익 창출 > 쇼핑에서 스토어 연결을 눌러 MarppleShop과 YouTube 계정을 연결해 주세요.': `Link your YouTube account with MarppleShop through "Connect Shop" in YouTube Studio→Monetization→Shopping`,
      'YouTube 스튜디오에서 노출하고 싶은 상품을 선택해 주세요': `Please select the products you want to display in YouTube Studio`,
      '채널에 제품 표시에 있는 메뉴를 눌러서 채널에 표시하고 싶은 상품을 선택해 주세요': `Select the product you want to display on the channel by clicking the menu in 'display products on channel'`,
      '(설정에 따라 하단에 표시되는 메뉴가 다를 수 있습니다)':
        '(The menu displayed at the bottom may differ depending on the setting)',
      '채널에 제품 표시에 있는 토글을 활성화 해주셔야 합니다.':
        'You will need to enable the toggle under Show Products on Channel.',
      '토글을 끄면 상품이 보이지 않습니다.': `If the toggle is turned off, the product will not be visible.`,
      '왼쪽에서 표시하기를 원하는 상품을 골라 오른쪽으로 드래그 해주세요.':
        'Select the product you want to display from the left and drag it to the right.',
      '상품은 총 30개까지 채널에 노출할 수 있습니다.': `A total of 30 products can be displayed on a channel.`,
      '채널 연동 후 1~3일 정도 지나면 YouTube 스튜디오 > 수익 창출 > 쇼핑에서 마플샵 상품을 확인하실 수 있습니다.': `After 1-3 days after linking the channel, you can see that the MappleShop products are displayed on YouTube.`,
      '※ 유의사항': `※ Precautions`,
      '유튜브 상품 연동을 위해서 마플샵 상품을 공개 상태로 설정해주세요.': `To link YouTube products, set the MappleShop product to public.`,
      '비공개 상품의 경우 유튜브 채널에 노출되지 않습니다.': `Private products are not exposed on the YouTube channel.`,
      '상품이 품절이 되어도 유튜브 채널에 노출되지 않습니다.': `Even if the product is out of stock, it will not be displayed on the YouTube channel.`,
      '영상 별로 노출하고 싶은 상품을 고를 수 있습니다.': `You can select the products you want to display for each video.`,
      'YouTube 영상을 업로드 할 때 YouTube 스튜디오→콘텐츠→세부 정보→수익창출에서 노출하고 싶은 상품을 선택할 수 있습니다.': `You can select the products you want to display in YouTube Studio→Contents→Details→Monetization`,
      '먼저 채널 콘텐츠의 수익 창출에서 쇼핑 탭을 눌러주세요. 이곳에서 어떤 상품을 노출할지 선택할 수 있습니다.': `Click the Shopping tab in Monetization. You can set which products to display.`,
      'a. 맞춤 설정 : 영상 별로 노출하고 싶은 상품을 고르고 싶을 때 사용합니다.': `a. CUSTOMIZE : You can select the product you want to display for each video.`,
      'b. 채널 선택사항 사용 : 채널에서 노출하고 있는 상품 설정 그대로 사용합니다.': `b. USE CHANNEL SELECTION : Products are displayed in the way previously set by the user.`,
      'c. 제품 세션 토글 : 토글을 끄면 해당 영상에서만 상품이 보이지 않습니다.': `c. PRODUCT SECTION : If the toggle is turned off, the product is not visible only in the video.`,
      'YouTube Shopping 기능 자세히 알아보기': `Check more about YouTube Shopping features`,
      '이미 등록 되어 있는 채널입니다. 연결된 샵에서 연동 해제 후 다시 등록해주세요.':
        'A channel that is already registered. Please unlink it from the connected shop and register again.',
    },
    pc_info: {
      '셀러 스튜디오를 PC에서 이용해 보세요': 'Use MarppleShop Studio on PC',
      '스마트폰뿐 아니라 PC에서도 손쉽게 이용 가능해요 !':
        'You may also use it on PC as well <br/>as smartphone in an easy manner!',
    },
  },

  unsupported_browser: {
    title_for_ie: 'Notice on the unavailability of<br/>Internet Explorer',
    description_for_ie: `As Microsoft stopped the support of the security update for Internet Explorer,\nthe important data in your PC may be exposed to the security vulnerability with malicious code. Please use quick and safe latest browser.`,
    description_for_another_browser:
      'If you see this page on the browser other than Internet Explorer, please update your browser to the latest version.',
    recommended_pc_browser: 'Recommended browser for PC',
    recommended_mobile_browser: 'Use it on the smartphone right away.',
    description_for_mobile_phone: {
      creator:
        'Search with the word “Marpple Shop” on mobile Chrome, Safari, or enter https://marpple.shop/ in URL',
      mp: 'Search with the word “Marpple” on mobile Chrome, Safari, or enter https://www.marpple.com/ in URL.',
      line: 'Search with the word “Line Friends Creator” on mobile Chrome, Safari, or enter https://creator.linefriends.com/ in URL.',
    },
    description_to_using_app: {
      creator: 'Please use the Apps Creator Goods Shop, “Marpple Shop”.',
      mp: 'Please use the Marpple App, the printing app.',
    },
  },

  mp_app: {
    android_fb_login:
      '"Facebook login" is temporarily not working on the Android app. Please try "Facebook login" on your web browser.',
  },

  maker_text_editor: {
    '문자 간격': 'Spacing',
    '텍스트 입력': 'Type text',
    텍스트: 'Text',
    '서체 선택': 'Choose font',
    '글씨 색상': 'Font color',
    폰트: 'Font',
    '텍스트 편집': 'Edit text',
  },

  maker_color_editor: {
    색상: 'Color',
  },
  maker_path_editor: {
    패스: 'Path',
    '패스 편집': 'Edit Path',
    '도형 편집': 'Edit Shape',
  },
  maker_border_editor: {
    두께: 'Thickness',
    테두리: 'Border',
    '테두리 색상': 'Border Color',
    점선: 'Dotted',
  },

  maker_background_color_editor: {
    '배경 색상': 'Background Color',
  },

  maker_error_alert: {
    '문제가 발생했습니다. 다시 시도해 주세요.': 'A problem has occurred. Please try again.',
    '문제가 발생했습니다. 디자인을 다시 확인해주세요.': 'A problem has occurred. Please check designs again.',
    'PNG 이미지를 해석할 수 없습니다.': 'The PNG image could not be interpreted.',
    'SVG 파일을 해석할 수 없습니다.': 'The SVG file could not be interpreted.',
    'SVG 객체를 찾을 수 없습니다.': 'Can not find SVG object.',
    '이미지를 그리는 데 실패했습니다.': 'Failed to draw image.',
    fail_upload_img: 'Failed to upload images. Please try again.',
    too_large_file: 'The file size is too large.<br />The file should be smaller than {{mb}}MB.',
    too_small_img: 'For good quality printing, please upload at least 500px images.',
    too_large_img: 'Please resize your file to below {{px}}px.',
    too_large_img2:
      'The value of width x height should be less than 16,777,216 px.<br />Please resize the file and upload it.',
    '앗! 죄송합니다. 다시 시도해 주세요.': 'Oops. Sorry, try again please.',
    '이 기능이 지원되지 않는 상품입니다.': 'This function is not supported for this product.',
    '앗! 제품을 만들지 않으셨어요.': 'Oh No! You forgot to create a product 😂',
    '임시 저장된 디자인을 불러오겠습니까?': 'Would you like to bring back a temporary saved design?',
    '크기에 맞춰 가격이 책정되었어요.': 'Prices are based on the size.',
    '선택된 색상으로 패턴을 만들수 없습니다.': `You can't make a pattern with the selected color.`,
    '확인했습니다.': 'Confirmed.',
    '점선 밖으로 디자인을 위치 시킬수 없습니다.': 'The design cannot be placed outside the dotted line.',
    '패치 위에 다른 다지인을 올릴 수 없습니다.': 'No other design can be placed on the patch.',
    '특수 색상 글씨 위에 다른 다지인을 올릴 수 없습니다.':
      'Other designs cannot be placed on top of special color letters.',
    'This is a temporary error. please try again.': 'This is a temporary error. please try again.',
    'We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.':
      'We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.',
    'The selected background color is removed.': 'The selected background color is removed.',
    'Pattern is not supported for this product. Pattern image is automatically removed.':
      'Pattern is not supported for this product.<br/>Pattern image is automatically removed.',
    '시스템 글꼴이 만들기툴에 영향을 줌': `The font design may be impacted by fonts in your web browser.<br>Because a font could appear differently on the screen, we ask that you use {{browsers}}.`,
    '시스템 글꼴이 마플샵 고객 뷰에 영향을 줌': `This product is only available for purchase via {{browsers}}. For purchase, please check it out on the {{browsers}}.`,
    '시스템 글꼴이 마플 고객 뷰에 영향을 줌': `The font design may be impacted by fonts in your web browser.<br>Because a font could appear differently on the screen, please use the {{browsers}} and re-design it.`,
    '품절되었습니다.': 'Out of stock.',
    '해당 상품은 태블릿에서 모바일 화면으로만 작업이 가능합니다.':
      'On tablet pc, this product can only be used on mobile screens.<br>It switches to the mobile screen.',
    'PC 화면으로 전환됩니다.': 'It switches to the PC screen.',
    '오류가 발생했습니다.<br>새로고칩 됩니다.': 'An error occurred.<br>The page will reload.',
  },
  maker_upload_type: {
    '만들기 툴로 쉽게 제작하기': 'Easy Editor',
    '전문가라면? 직접 제작하기': 'Expert Editor',
    '내 디자인 업로드하기': 'Expert Editor',
    '디자인 업로드': 'Upload Design',
    '내 디자인 파일로 만드는 방법': 'Creating with my design file',
    '템플릿 다운로드': 'Download Template',
    '전문가용 제작하기': 'Create for Experts',
    '도안 업로드': 'Upload Design',
    '일러스트레이터 버전': 'Needs Illustrator 2021+ for use.',
    '첨부 파일 형식: SVG (10MB 이하)': 'Attachment File Format: SVG (Under 10MB)',
    '위 템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.':
      'Please design following the guide in the template file.',
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 키링을 만들어 보세요.': `Download the template and create<br>your own acrylic keyring.`,
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 스탠드를 만들어 보세요.': `Download the template and create<br>your own acrylic stand.`,
    '커스텀하는 방법': 'How to Customize',
    '전문가용 에디터를 활용해 제작한 도안은 PC에서 확인하실 수 있어요.':
      'Designs created using the expert editor can be previewed on your PC.',
    '템플릿을 다운로드하시고 직접 나만의 아크릴 키링을 만들어 보세요.': `Download the template and create<br>your own acrylic keyring.`,
    '템플릿을 다운로드하시고 직접 나만의 아크릴 스탠드를 만들어 보세요.': `Download the template and create<br>your own acrylic stand.`,
    maker_upload_type_01: 'Created using the expert editor<br>can be previewed on your PC.',
    maker_upload_type_02: 'Designs created using the Expert Editor.<br>You can edit it on your PC.',
  },
  maker_basic_word: {
    사이즈: 'Size',
    가격: 'Price',
    '스탠드 포함': 'Includes stand base',
  },

  maker_unlock_editor: {
    Unlock: 'Unlock',
  },

  maker_upload_image_editor: {
    '이미지 업로드': 'Image upload',
    '업로드 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일이며 25MB 이하입니다.':
      'Accepted File Types: JPEG, PNG, SVG (Max size: 25MB)',
    '제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드 (CMYK/RGB)에 따라 차이가 발생할 수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작원에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지 않음을 확인합니다.':
      'By uploading an image you agree that you have the legal right to use it. See our <a href="/privacy">Privacy Policy.</a><br />For best results, See our <a href="/guideline/images">Image Guidelines.</a>',
    '이미지 올리기': 'Upload',
    '내 이미지 보기': 'Library',
  },

  marpple_shop_detail_alert: {
    '컬러 없음': `There is no selected color for this model. 😂`,
  },

  marpple_shop_select_page: {
    'Please select at least one thumbnail.': 'Please select at least one thumbnail.',
    'Please select 5 or fewer thumbnails.': 'Please select 5 or fewer thumbnails.',
    'Thumbnails are being created.': 'Thumbnails are being created.',
    'Please, wait for a moment.': 'Please, wait for a moment.',
  },

  tokenGate: {
    서명: 'Sign',
    tg001: 'Authentication has expired.',
    tg002: 'All of the above NFTs are required.',
    tg003: 'Go Back to {{storeName}}',
    tg004:
      'This signature is a certification process for purchasing the product and does not incur any costs.',
    tg005: '',
    tg006: 'One or more of the above NFTs are required.',
    tg007: `Open Android settings , search "default" and select "Choose Default apps" > "Choose Default apps" > Opening Links > scroll down to MetaMask and tap it > Supported Web addresses > check if links are selected or "verified/trusted" by default (most importantly metamask.app.link). If they are not, then check them/it`,
    tg009: 'NFT Certification and Purchase',
    tg010: 'NFT Certification',
    tg010_1: 'NFT Certification',
    tg011: 'Only the holder can buy it.',
    tg012: 'This contract address is already registered.',
    tg013: 'Please check the contract address.',
    tg014: 'If you add a contract address, only the holder of that NFT can purchase the product.',
    tg015: 'All',
    tg016: 'One of',
    tg017: 'Token Gating Settings',
    tg018:
      'By entering the NFT contract address, only the holder of that NFT can purchase the product. We currently support Ethereum and Polygon chains.',
  },

  youtube: {
    '유튜브 쇼핑 공식 플랫폼 마플샵': 'The official YouTube shopping platform Maple Shop',
    '마플샵과 함께 유튜브 쇼핑을 시작하세요. 유튜브 영상, 쇼츠, 라이브에서 내 상품 또는 브랜드 제품을 전시하고 판매할 수 있습니다. 상품 제작부터, 배송, CS까지 원스톱 커머스 솔루션을 제공합니다.':
      'Start shopping on YouTube with Marple Shop. You can display and sell my products or branded products on YouTube videos, shorts, and live. It offers one-stop commerce solutions from production to delivery to CS.',
    '유튜브 쇼핑 플랫폼 마플샵': '유튜브 쇼핑 플랫폼 마플샵',
    '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.':
      '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.',
    '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.':
      '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.',
    '자격 요건이 충족되어야 연동할 수 있어요.': '자격 요건이 충족되어야 연동할 수 있어요.',
    '약관 동의하러 가기': '약관 동의하러 가기',
  },

  cart_modal: {
    quantity: 'Qty',
    일시품절: 'Sold out',
  },
  seller_info: {
    certification: '',
  },
};
