import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerGuideConfirmS } from '../../S/Function/module/NewMakerGuideConfirmS.js';
import { NewMakerGuideConfirmMuiF } from '../Mui/module/NewMakerGuideConfirmMuiF.js';
import { go, mapObject } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';

export const open = (base_product) => {
  if (!base_product) return;
  const { id: base_product_id } = base_product;
  const db_confirm_popup =
    base_product._?.confirm_popup?.id &&
    base_product._.confirm_popup['m' + G._en] &&
    base_product._.confirm_popup['pc' + G._en] &&
    base_product._?.confirm_popup;
  const is_mobile = UtilF.isLegacyMobile();
  const confirm_popup = go(db_confirm_popup || NewMakerGuideConfirmS.findBp(base_product_id), (confirm) => {
    if (!confirm) return;
    const src1 = is_mobile ? confirm['m' + G._en] : confirm['pc' + G._en];
    const src2 = is_mobile ? confirm['m2' + G._en] : confirm['pc2' + G._en];
    if (!src1) return;
    return { src1, src2 };
  });
  if (!confirm_popup) return;

  return MuiF.openFrame(NewMakerGuideConfirmMuiF.frame, (frame, page, [tab]) => {
    frame.prev_frame_show = is_mobile;
    frame.is_modal = !is_mobile;
    tab.makeData = () => {
      return mapObject(
        (url) =>
          url
            ? UtilImageS.getResizedUrl({
                url,
                width: is_mobile ? 800 : 1760,
                format: 'webp',
                quality: 85,
              })
            : null,
        confirm_popup,
      );
    };
  });
};
