import { $qs } from 'fxdom/es';

export const setDataUploadTypeIsDesigned = () => {
  const _product = box.sel('maker->product_color');
  if (!_product) return;
  const is_designed =
    _product.product_faces2?.value?.length &&
    (!!_product.svg_product_id || !!_product._.svg_product_materials?.length);
  const mp_maker_el = $qs('#maker_frame');
  if (mp_maker_el) mp_maker_el.dataset.upload_type_is_designed = is_designed;
};
