import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { searchContainer } from '../../../S/Tmpl/search_container.js';

export const shell = (query) => {
  return html`
    <div class="drawer" id="search-drawer">
      <div class="drawer__inner">
        <div class="drawer__header">
          <h2>${T('mshop::Search')}</h2>
        </div>
        <div class="drawer__body">
          ${searchContainer({
            q: query,
            placeholder: T('mshop::어떤 상품을 원하시나요?'),
            input_id: 'search-drawer-input',
            container_id: 'drawer-keyword-container',
          })}
          <div class="search-history"></div>
          <div class="search-popular">
            <div class="search-popular__title">${T('mshop::인기 검색어')}</div>
            <div class="search-popular__items"></div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export const searchPopular = (popular_list) => {
  return html`
    ${strMap(({ q: query }) => {
      const q = UtilS.escape(query);
      const uri = `/${T.lang}/@/keywords/all?q=${UtilS.escape(q)}`;
      return html`<a href="${uri}" class="search-popular__item" data-q="${UtilS.escape(q)}" data-uri="${uri}">
        <span>${q}</span>
      </a>`;
    }, popular_list)}
  `;
};

//
// // 인기 검색어 주석
// // html`<div class="app-search__result app-search__result--popular">
// //     //       <div class="app-search__title">${T('mshop::인기 검색어')}</div>
//     //       <div class="app-search__popular-list app-search__popular-list--popular">
//     //         ${strMap(
//           ({ q }) => html`
//             <div class="app-search__popular-item">
//               <button type="button" data-q="${q}">${q}</button>
//             </div>
//           `,
//           popular,
//         )}
// //       </div>
// //     </div>`
