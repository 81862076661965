import { compact, each, find, go, map, reject, tap } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerMaskingTapeF } from '../../../NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { makeLayerHtml } from './tmpl.js';
import { changeCvObjectToImgSrc } from '../draw_product_faces.js';
import { $attr, $closest, $find, $qs } from 'fxdom/es';
import { isWorkerInMaker } from '../util.js';
import { getCurrentFcanvas, getCvDesigns } from '../Fcanvas/cv_object.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { marpplizerProxy } from '../Marpplizer/marpplizer.js';

function _renderDesignAttr(el) {
  const cid = go(el, $attr('cid'));
  const fcanvas = getCurrentFcanvas();
  if (!fcanvas) return;
  const design = go(
    fcanvas._objects,
    getCvDesigns,
    find((cv_obj) => cv_obj.cid === cid),
  );
  if (!design) return;
  $.attr(el, {
    visible: design.visible || design._data._visible,
    is_locked: !design.evented,
    is_overflow: design.is_overflow,
  });
  design.active ? $.add_class(el, 'selected') : $.remove_class(el, 'selected');
}

let btn_tip_height = 0;

export const setLayerTopInMaker = function () {
  if (!$1('.decoration_menu_for_pc')) return 100;
  let marpple_layer_top = $.position($1('.decoration_menu_for_pc')).top;
  const top = go(
    $('.btn_tip'),
    _p.reject((btn_tip) => $.css(btn_tip, 'display') === 'none'),
    _p.last,
    (el) => (el ? $.position(el).top : 100),
  );

  btn_tip_height = btn_tip_height || (top ? top + 16 : top);
  // btn_tip_height = $1('.btn_tip') ? btn_tip_height : 0;
  marpple_layer_top = Math.max(btn_tip_height, marpple_layer_top);
  if ($1('html#dream_factory')) marpple_layer_top = 100;
  return marpple_layer_top;
};

export const renderSelectInLayerInMaker = function () {
  const designs_els = $('#marpple_layer .design_list .design');
  go(
    designs_els,
    each((designs_el) => _renderDesignAttr(designs_el)),
  );
  reset_iscroll();
};

function reset_iscroll() {
  const iscroll_ul_wrapper = $qs('#marpple_layer .design_list_wrapper');
  if (!iscroll_ul_wrapper) return;
  if (iscroll_ul_wrapper.myScroll) {
    iscroll_ul_wrapper.myScroll.destroy();
    iscroll_ul_wrapper.myScroll = null;
  }
  iscroll_ul_wrapper.myScroll = new IScroll(iscroll_ul_wrapper, {
    mouseWheel: true,
    scrollbars: true,
    fadeScrollbars: true,
  });
  iscroll_ul_wrapper.myScroll.scrollToElement($find('.i_item.selected', iscroll_ul_wrapper), 0, true, true);
}

G.mp.maker.reset_layer = async function () {
  if (UtilF.isLegacyMobile()) return;
  const canvas = G.mp.maker.editing_canvas();
  if (!canvas) return;
  // if (sel('preview.on', canvas)) return;
  const cv_objects = go(
    G.mp.maker.designs(canvas),
    reject((cv_obj) => cv_obj._data.clone_parent_cid),
  );
  const marpple_layer_el = $1('#marpple_layer');
  $.css(marpple_layer_el, { top: setLayerTopInMaker() });
  marpple_layer_el.dataset.has_info_option = Boolean(isWorkerInMaker());
  try {
    await go(
      cv_objects,
      map(async (cv_object) => [cv_object, await changeCvObjectToImgSrc(cv_object, 50, true)]),
      map(function ([cv_object, src]) {
        if (src)
          return {
            cv_object,
            src,
          };
      }),
      compact,
      tap(function (designs) {
        $.attr(marpple_layer_el, { designs_length: designs.length });
      }),
      (designs) => designs.reverse(),
      makeLayerHtml,
      function (tmpl) {
        $1('#marpple_layer .design_list').outerHTML = tmpl;
        reset_iscroll();
      },
    );
  } catch (e) {}
};

function getCvDesignByCidInLayer(ct) {
  return go(ct, $.closest('.design'), $.attr('cid'), (cid) => {
    return go(
      getCvDesigns(getCurrentFcanvas()._objects),
      find((cv_obj) => cv_obj.cid === cid),
    );
  });
}

async function toggle_visible(target_cv_obj) {
  const visible = !target_cv_obj.visible;
  const obj = target_cv_obj;
  obj.visible = visible;
  go(
    NewMakerMaskingTapeF.getCloneHorizontalCvObj(obj),
    each((co) => (co.visible = visible)),
  );
  G.mp.maker.reject_select(obj);
  // modified();
  G.mp.maker.editing_canvas().renderAll();
  await G.mp.maker.modified();
  await marpplizerProxy(G.mp.maker.active());
}

export const initDesignListLayer = (el_mp_maker) => {
  return go(
    el_mp_maker,
    $.find1('.design_list_wrapper'),
    $.css({
      maxHeight: 388 - 34 - 20,
    }),
  );
};

export const makeDesignListLayerEvent = function makeDesignListLayerEvent(maker_el) {
  return _p.go(
    maker_el,
    $.on(
      'click',
      '#marpple_layer .head',
      errorHandlingEventWrapper((e) => {
        const marpple_layer_el = $closest('#marpple_layer')(e.currentTarget);
        marpple_layer_el.dataset.is_hidden = marpple_layer_el.dataset.is_hidden === 'true' ? 'false' : 'true';
      }),
    ),
    $.on(
      'click',
      '.design_list_ev .hide',
      errorHandlingEventWrapper(function (e) {
        e.stopPropagation();
        _p.go(___, function () {
          _go(e.currentTarget, getCvDesignByCidInLayer, toggle_visible);
        });
      }),
    ),
    $.on(
      'click',
      '.design_list_ev .locking',
      errorHandlingEventWrapper(function (e) {
        e.stopPropagation();
        _p.go(___, function () {
          _go(e.currentTarget, getCvDesignByCidInLayer, function (target_cv_obj) {
            G.mp.maker.lock.toggle_locking(target_cv_obj, e.altKey || e.ctrlKey || e.metaKey);
          });
        });
      }),
    ),
    $.on(
      'click',
      '.design_list_ev .design',
      errorHandlingEventWrapper(function (e) {
        const ct = e.currentTarget;
        _go(
          ct,
          _p.if2(function (el_design) {
            return !$.attr(el_design, 'visible') || $.attr(el_design, 'is_locked');
          }, _p.not)(
            function (el_design) {
              return getCvDesignByCidInLayer(el_design);
            },
            function (target_cv_obj) {
              const cv_objects = G.mp.maker.designs();
              if (e.altKey || e.ctrlKey || e.metaKey) {
                if ($.has_class(ct, 'selected')) {
                  target_cv_obj.active = false;
                } else {
                  target_cv_obj.active = true;
                }
                const selecteds = _filter(cv_objects, _p.v('active'));
                return selecteds;
              } else {
                _each(cv_objects, function (obj) {
                  obj.active = false;
                });
                return [target_cv_obj];
              }
            },
            function (selecteds) {
              if (selecteds.length === 0) return G.mp.maker.unselect_all();
              G.mp.maker.multiple_select(selecteds);
            },
          ),
        );
      }),
    ),
  );
};
