import { $attr, $closest, $css, $find, $findAll, $hasClass, $height, $setCss } from 'fxdom/es';
import { each, filter, go, head, map, noop, sel, some, sortByDesc, tap } from 'fxjs/es';
import { renderDesignCollectionContentPaddingMobile } from '../../../DesignCollection/Detail/F/fs.js';
import { changeReviewListInfi } from '../../../Review/List/F/fs.js';
import { getOffsetTop } from './event.js';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

function toFitSimple(cb) {
  let tick = false;
  return function trigger() {
    if (tick) {
      return;
    }

    tick = true;
    return requestAnimationFrame(function task() {
      tick = false;

      try {
        return cb();
      } catch (e) {}
    });
  };
}

export function scrollDestroy() {
  if (G.__product_detail_tab_scroll) {
    window.removeEventListener('scroll', G.__product_detail_tab_scroll);
    G.__product_detail_tab_scroll = null;
  }
}
export function scrollInit({
  product_detail_tabs_container,
  scroll_target,
  header_height,
  is_header_headroom,
}) {
  scrollDestroy();
  if (!UtilF.isLegacyMobile() && document.body.clientWidth < 740) return;
  const tab_wrapper_el = go(product_detail_tabs_container, $find('.product_detail_tabs .wrapper'));
  $setCss({ top: is_header_headroom ? 0 : header_height }, tab_wrapper_el);
  if (is_header_headroom) {
    const headroom = new Headroom(tab_wrapper_el);
    headroom.init();
    if (product_detail_tabs_container.getBoundingClientRect().top < 0) {
      product_detail_tabs_container.dataset.is_top_fixed = 'true';
    }
  }
  G.__product_detail_tab_scroll = toFitSimple(() => {
    const tab_body_targets = go(
      product_detail_tabs_container,
      $findAll('.product_detail_tab_body'),
      filter((el) => el.dataset.is_hidden === 'false'),
      map((product_detail_tab_body_el) => {
        const offset_top = getOffsetTop(product_detail_tab_body_el);
        return {
          tab_name: product_detail_tab_body_el.dataset.tab_name,
          offset_top,
          height: $height(product_detail_tab_body_el),
        };
      }),
    );
    const target_height = scroll_target === window ? window.innerHeight : $height(scroll_target);
    const top = scroll_target === window ? window.scrollY : scroll_target.scrollTop;
    const product_detail_tab_top = go(
      product_detail_tabs_container,
      $find('.product_detail_tabs'),
      getOffsetTop,
    );
    const is_pinned = $hasClass('headroom--pinned')(tab_wrapper_el);
    product_detail_tabs_container.dataset.is_top_fixed = String(
      scroll_target === window
        ? product_detail_tab_top <=
            (is_header_headroom ? (is_pinned ? top + header_height : top) : top + header_height)
        : false,
    );
    go(
      tab_body_targets,
      each((tab_body_target) => {
        const tab_body_top = tab_body_target.offset_top - top;
        if (tab_body_top > target_height) {
          tab_body_target.body_height = 0;
        } else if (tab_body_top < 0 && tab_body_top + tab_body_target.height < 0) {
          tab_body_target.body_height = 0;
        } else {
          const tab_body_bottom = tab_body_top + tab_body_target.height;
          if (tab_body_top < 0 && tab_body_bottom > 0 && tab_body_bottom < target_height) {
            tab_body_target.body_height = tab_body_bottom;
          } else if (
            tab_body_top > 0 &&
            tab_body_bottom > 0 &&
            target_height > tab_body_top &&
            target_height > tab_body_bottom
          ) {
            tab_body_target.body_height = tab_body_bottom - tab_body_top;
          } else if (tab_body_top > 0 && tab_body_top < target_height && tab_body_bottom > target_height) {
            tab_body_target.body_height = target_height - tab_body_top;
          } else {
            tab_body_target.body_height = target_height;
          }
        }
      }),
      sortByDesc(({ body_height }) => body_height),
      head,
      (tab_body_target) => {
        product_detail_tabs_container.dataset.current_tab = tab_body_target.tab_name;
      },
    );
  });
  scroll_target.addEventListener('scroll', G.__product_detail_tab_scroll, { passive: true });
}

export const init = async (tab_el, is_update) => {
  const product_detail_tabs_container = $find('.product_detail_tabs_container', tab_el);
  const don_frame_el = go(tab_el, $closest('.don_frame'));
  const { scroll_target, is_mobile, is_header_headroom, header_height } = {
    is_mobile: UtilF.isLegacyMobile(),
    scroll_target: go(don_frame_el, $attr('is_modal'), (v) => v === 'true')
      ? go(don_frame_el, $find('>.don_wrapper>.body'))
      : window,
    is_header_headroom:
      UtilF.isLegacyMobile() &&
      go(NewMakerUtilF.getMobileHeader(), (el) => (el ? $hasClass('headroom')(el) : false)),
    header_height: !UtilF.isLegacyMobile() ? 0 : NewMakerUtilF.getMobileHeaderHeight(),
  };
  product_detail_tabs_container._tab_move_attributes = {
    scroll_target,
    is_mobile,
    is_header_headroom,
    header_height,
  };
  const product_detail_tab_body_els = go(
    tab_el,
    $findAll('.product_detail_tab_body'),
    filter((el) => el.dataset.is_hidden === 'false'),
  );
  tab_el.__product_detail_tabs_lazy = new Blazy({
    selector: '.don_lazy',
    container: `.product_info_detail`,
  });

  product_detail_tabs_container.dataset.is_header_headroom = String(is_header_headroom);
  const thumb_swiper_el = $find('.thumb_swiper', tab_el);
  const bp_thumbnails_el = $find('.swiper_thumbnails', thumb_swiper_el);
  const bp_thumbnails_swiper_slides = $findAll('.swiper-slide', bp_thumbnails_el);
  const is_tab_display_none = $css('display', tab_el) === 'none';
  if (!is_tab_display_none && bp_thumbnails_swiper_slides?.length && bp_thumbnails_el) {
    bp_thumbnails_el.__swiper =
      bp_thumbnails_el.__swiper ||
      new Swiper(bp_thumbnails_el, {
        spaceBetween: !UtilF.isLegacyMobile() ? 16 : 8,
        slidesPerView: 'auto',
        centeredSlides: true,
        centerInsufficientSlides: true,
        grabCursor: true,
        autoResize: false,
        resizeReInit: true,
        loop: true,
        initialSlide: 1,
        watchSlidesVisibility: true,
        preloadImages: false,
        lazy: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: false,
          clickable: true,
        },
      });
  }
  if (thumb_swiper_el) $.css(thumb_swiper_el, 'opacity', 1);
  // BP_DETAIL_TAB
  if (
    some((product_detail_tab_body_el) => product_detail_tab_body_el.dataset.tab_name === 'BP_DETAIL_TAB')(
      product_detail_tab_body_els,
    )
  )
    if (is_mobile) {
      const size_compare_items_el = $find('.size_compare_items.swiper-container', tab_el);
      if (!is_tab_display_none && size_compare_items_el) {
        size_compare_items_el.__swiper =
          size_compare_items_el.__swiper ||
          new Swiper(size_compare_items_el, {
            slidesPerView: 'auto',
            on: { slideChange: G.don_lazy },
          });
      }
    }
  if (
    some((product_detail_tab_body_el) => product_detail_tab_body_el.dataset.tab_name === 'STORY_TAB')(
      product_detail_tab_body_els,
    )
  ) {
    if (is_mobile) renderDesignCollectionContentPaddingMobile();
  }

  if (
    some((product_detail_tab_body_el) => product_detail_tab_body_el.dataset.tab_name === 'REVIEW_TAB')(
      product_detail_tab_body_els,
    )
  ) {
    if (is_update) {
      if (G.collabo_type == '') {
        const changeReviewListInfi2 = (don_review_list) =>
          go(box.sel('review_list'), function (review_list) {
            go(review_list.reviews, (rs) =>
              (sel('change', $.closest(don_review_list, '.don_tab').tab_opt.infi) || noop)(rs, 1),
            );
          });

        await go(
          tab_el,
          $find('.omp-review__contents.infi_container'),
          $.css({ visibility: 'hidden', minHeight: window.innerHeight }),
          tap((don_review_list) => changeReviewListInfi2(don_review_list)),
          $.css({ visibility: '', minHeight: '' }),
        );
      } else {
        await go(
          tab_el,
          $find('.review_list'),
          $.css({ visibility: 'hidden', minHeight: window.innerHeight }),
          tap((don_review_list) => changeReviewListInfi(don_review_list, 'no')),
          $.css({ visibility: '', minHeight: '' }),
        );
      }
    }
  }
  scrollInit({ product_detail_tabs_container, scroll_target, header_height, is_header_headroom });
};
