import {
  compact,
  each,
  every,
  extend,
  filter,
  find,
  go,
  ippL,
  map,
  mapC,
  omit,
  pick,
  pipe,
  range,
  sel,
  strMap,
  tap,
} from 'fxjs/es';
import { BpOptionMaskingTapeConstantS } from '../../BpOption/MaskingTape/S/Constant/module/BpOptionMaskingTapeConstantS.js';
import { BpOptionConstantS } from '../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerPrintResultF } from '../../NewMaker/PrintResult/F/Function/module/NewMakerPrintResultF.js';

import { changeBpc2 } from '../../ProductColor/S/fs.js';
import { legacyHtml } from '../../Util/S/Function/util.js';
import {
  BASE_PRODUCT_COLOR_BY_BPC_ID_API_URL,
  NEW_PRODUCT_COLOR_BY_BP_ID_BPC_ID_API_URL,
} from '../S/constant.js';
import {
  createCanvasElementByBpcfs,
  getBaseProductSizesInMaker,
  getBpfOfFcanvas,
  getRealFcanvass,
  setProductFaces2Value2,
} from './getSth.js';
import { makeFcanvasBasic, unsetFcanvass } from './mp_maker.js';
import { renderSpecialColor } from './text.js';
import {
  getFheight,
  getFwidth,
  makeImageFromUrl,
  rejectWrongCvTextImage,
  ungroupAllWithObjAttrs,
} from './util.js';
import { rotateCanvas } from './canvas_trim.js';
import { checkCvObjFullOverBp } from './overflow.js';
import { createCanvasElement, loadImageFromUrl } from '../../Canvas/S/util.js';
import { MAKER_STROKE_WIDTH, minusStrokeWidth } from './Fcanvas/stroke.js';
import { getCvObj } from './Fcanvas/cv_object.js';
import axios from 'axios';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerImageF } from '../../NewMaker/Image/F/Function/module/NewMakerImageF.js';
import { $el, $insertBefore, $on } from 'fxdom/es';
import { addCvImage } from './CvImage/fs.js';
import { calculatePrintableFileShiboriCm } from '../../Df/Projection/List/F/shibori.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerBaseProductsEmbroideryF } from '../../NewMaker/BaseProducts/Embroidery/F/Function/module/NewMakerBaseProductsEmbroideryF.js';

export function getTdesignsFromPf(product_face, sf, is_on_mask2, is_force_print_area_on) {
  function reset_bpcf(cv_bpcf) {
    if (!sf) return cv_bpcf;
    if (!cv_bpcf) return;
    const prev_cv_bpcf_width = cv_bpcf.width;
    cv_bpcf.width = sf.img.width;
    cv_bpcf.height = (cv_bpcf.height * sf.img.width) / prev_cv_bpcf_width;
    cv_bpcf.top = (G.mp.maker.CANVAS_WIDTH_ORIGIN - cv_bpcf.height) / 2;
    cv_bpcf.left = (G.mp.maker.CANVAS_WIDTH_ORIGIN - cv_bpcf.width) / 2;
    cv_bpcf.top = cv_bpcf.top - sf.img.diff / G.mp.maker.NSCREEN_ZOOM;
    return cv_bpcf;
  }
  const bpc_face_imgs = compact([
    product_face.cv_preview,
    is_on_mask2 ? reset_bpcf(product_face.cv_mask2) : reset_bpcf(product_face.cv_mask1),
    reset_bpcf(product_face.cv_bpcf),
    reset_bpcf(product_face.cv_shading),
    is_force_print_area_on ? product_face.cv_print_area : null,
    is_force_print_area_on ? product_face.cv_safe_area : null,
  ]);
  const designs = G.mp.maker.reject_ai(product_face.cv_preview ? [] : product_face.designs) || [];
  const cv_background = product_face.cv_background;
  if (cv_background) return [cv_background].concat(designs).concat(bpc_face_imgs);
  return designs.concat(bpc_face_imgs);
}

// export function addTdeisngsToFcanvasA(Tdesigns, f_canvas, zoom) {
//   return each(
//     Tdesign =>
//       go(G.mp.maker.from_cv_attrs_for_maker(Tdesign, zoom, true), item => {
//         if (getFwidth(item) < 1 || getFheight(item) < 1) return;
//         item && f_canvas.add(item);
//       }),
//     Tdesigns
//   );
// }

export function addTdeisngsToFcanvasInMaker(t_designs, f_canvas, zoom, is_draw) {
  return go(
    t_designs,
    each((t_design) =>
      go(
        t_design,
        (t_design) => G.mp.maker.from_cv_attrs_for_maker(t_design, zoom, is_draw),
        function (item) {
          if (getFwidth(item) < 1 || getFheight(item) < 1) return;
          return item && f_canvas.add(item);
        },
      ),
    ),
  );
}

function getDesignsFromPfFittingInTargetPrintarea1(pf, fcanvas) {
  const is_nscreened = G.mp.maker.cv_print_area(fcanvas)._data.nscreened;
  const target_cv_print_area = minusStrokeWidth(
    pick(['top', 'left', 'width', 'height'], G.mp.maker.cv_print_area(fcanvas)),
  );
  pf = JSON.parse(JSON.stringify(pf));
  const stroke_width = MAKER_STROKE_WIDTH;
  const current_cv_print_area = minusStrokeWidth(pf.cv_print_area);
  const current_cv_print_area_center = {
    x: current_cv_print_area.left + stroke_width + current_cv_print_area.width / 2,
    y: current_cv_print_area.top + stroke_width + current_cv_print_area.height / 2,
  };
  const target_cv_print_area_center = {
    x: target_cv_print_area.left + stroke_width + target_cv_print_area.width / 2,
    y: target_cv_print_area.top + stroke_width + target_cv_print_area.height / 2,
  };

  const designs = map((design) => ({ ...design }))(pf.designs);
  const ratio = (() => {
    const is_not_can_overflow = !box.sel('maker->product_color->_->base_product').can_overflow;
    if (is_not_can_overflow) {
      const bpf = getBpfOfFcanvas(fcanvas);
      const size_face = find(
        (sf) => sf.base_product_size_id === box().maker.product_color.base_product_size_id,
      )(bpf.size_faces);
      return size_face.px_per_1cm / pf.size_faces[0].px_per_1cm;
    }
    return target_cv_print_area.width / current_cv_print_area.width;
  })();

  return go(
    designs,
    each((design) => {
      const top = design.top - current_cv_print_area_center.y;
      const left = design.left - current_cv_print_area_center.x;
      design.top = target_cv_print_area_center.y + top * ratio;
      design.left = target_cv_print_area_center.x + left * ratio;
      design.scaleX *= ratio;
      design.scaleY *= ratio;
      if (design._data.width_cm) design._data.width_cm *= ratio;
      if (design._data.height_cm) design._data.height_cm *= ratio;
      if (is_nscreened) design._data.nscreened = true;
      if (design._data.is_embro) {
        const width = G.mp.maker.to_px('image_width_cm', design._data);
        const height = G.mp.maker.to_px('image_height_cm', design._data);
        design.width = width;
        design.height = height;
        design.scaleX = 1;
        design.scaleY = 1;
      }
    }),
  );
}

// function getDesignsFromPfFittingInTargetPrintarea(pf, target_cv_print_area, need_nscreen_true) {
//   target_cv_print_area = minusStrokeWidth(target_cv_print_area);
//   pf = JSON.parse(JSON.stringify(pf));
//   const { bounding_rect } = getCvDesignsSize(pf.designs);
//   const cv_print_area_attr = minusStrokeWidth(pf.cv_print_area);
//   // const design_center = {
//   //   x: (center_point.x - cv_print_area_attr.left) / cv_print_area_attr.width,
//   //   y: (center_point.y - cv_print_area_attr.top) / cv_print_area_attr.height,
//   // };
//
//   const designs = map((design) => ({ ...design }))(pf.designs);
//
//   const is_horizontal =
//     Math.abs(cv_print_area_attr.width - target_cv_print_area.width) >
//     Math.abs(cv_print_area_attr.height - target_cv_print_area.height);
//
//   let ratio = is_horizontal
//     ? target_cv_print_area.width / cv_print_area_attr.width
//     : target_cv_print_area.height / cv_print_area_attr.height;
//
//   const isDesignsHeightBiggerThanPrintarea = target_cv_print_area.height <= bounding_rect.height * ratio;
//   const isDesignsWidthBiggerThanPrintarea = target_cv_print_area.width <= bounding_rect.width * ratio;
//
//   const isWidthBigger =
//     bounding_rect.width * ratio - target_cv_print_area.width >=
//     bounding_rect.height * ratio - target_cv_print_area.height;
//
//   const is_not_can_overflow_and_overflowed =
//     !box.sel('maker->product_color->_->base_product').can_overflow &&
//     (isDesignsHeightBiggerThanPrintarea || isDesignsWidthBiggerThanPrintarea);
//   let value_to_go_center;
//   if (is_not_can_overflow_and_overflowed) {
//     if (isWidthBigger) {
//       ratio = target_cv_print_area.width / bounding_rect.width;
//       value_to_go_center = (target_cv_print_area.height - bounding_rect.height * ratio) / 2;
//     } else {
//       ratio = target_cv_print_area.height / bounding_rect.height;
//       value_to_go_center = (target_cv_print_area.width - bounding_rect.width * ratio) / 2;
//     }
//   }
//
//   return go(
//     designs,
//     each((design) => {
//       if (is_not_can_overflow_and_overflowed) {
//         design.top = design.top - bounding_rect.top;
//         design.left = design.left - bounding_rect.left;
//         design.left *= ratio;
//         design.top *= ratio;
//         if (isWidthBigger) {
//           design.top += value_to_go_center;
//         } else {
//           design.left += value_to_go_center;
//         }
//       } else {
//         design.top = design.top - cv_print_area_attr.top;
//         design.left = design.left - cv_print_area_attr.left;
//         design.left *= ratio;
//         design.top *= ratio;
//         // if (is_horizontal) {
//         //   const design_height = bounding_rect.height * ratio;
//         //   const top = (bounding_rect.top - cv_print_area_attr.top) * ratio;
//         //   const center_top = top + design_height / 2;
//         //   design.top += design_center.y * target_cv_print_area.height - center_top;
//         // } else {
//         //   const design_height = bounding_rect.width * ratio;
//         //   const top = (bounding_rect.left - cv_print_area_attr.left) * ratio;
//         //   const center_top = top + design_height / 2;
//         //   design.left += design_center.x * target_cv_print_area.width - center_top;
//         // }
//       }
//
//       design.scaleX *= ratio;
//       design.scaleY *= ratio;
//
//       design.top += target_cv_print_area.top;
//       design.left += target_cv_print_area.left;
//       if (need_nscreen_true) design._data.nscreened = true;
//       if (design._data.is_embro) {
//         const width = G.mp.maker.to_px('image_width_cm', design._data);
//         const height = G.mp.maker.to_px('image_height_cm', design._data);
//         design.width = width;
//         design.height = height;
//         design.scaleX = 1;
//         design.scaleY = 1;
//       }
//     }),
//   );
// }

export async function fitPfInFacanvasInMaker(pf, fcanvas, is_draw) {
  const designs = getDesignsFromPfFittingInTargetPrintarea1(pf, fcanvas);
  await addTdeisngsToFcanvasInMaker(designs, fcanvas, 1, is_draw);
  const idx = getRealFcanvass().indexOf(fcanvas);
  go(
    G.mp.maker.designs(fcanvas),
    each((design) => {
      G.mp.maker.reset_data_cm(design, idx);
    }),
  );
}

export async function addPfToFcanvasInMaker(product_faces, is_draw) {
  const fcanvass = getRealFcanvass();
  let editing_canvas_idx = G.mp.maker.editing_canvas_idx() || 0;
  if (fcanvass.length < editing_canvas_idx + 1) {
    editing_canvas_idx = 0;
  }
  await go(
    product_faces,
    ippL,
    each(async ([idx, pf]) => {
      const fcanvas = fcanvass[idx];
      G.mp.maker.editing_canvas(idx);
      if (!fcanvas) return;
      await fitPfInFacanvasInMaker(pf, fcanvas, is_draw);
    }),
    function () {
      G.mp.maker.editing_canvas(editing_canvas_idx);
    },
  );
}

export function makeProductColorByPcAndBpcId(product_color, bpc_id) {
  product_color = JSON.parse(JSON.stringify(omit(['_'], product_color)));
  return _p.go($.get(BASE_PRODUCT_COLOR_BY_BPC_ID_API_URL, { bpc_id }), function ({ base_product_color }) {
    changeBpc2(product_color, base_product_color);
    go(
      product_color.product_faces2.value,
      each((pf) => {
        if (pf.cv_shading.src) {
          pf.cv_shading.visible = true;
        }
      }),
    );
    return { new_pc: product_color };
  });
}

export async function makeProductColorByPcAndBpcIdAndBpId(product_color, bpc_id, bp_id) {
  product_color = JSON.parse(JSON.stringify(omit(['_'], product_color)));
  // naver inapp issue -> https://www.notion.so/marpple/f7e22a543f35469badd5a7dc038901a4
  const result = await go(
    $.get(NEW_PRODUCT_COLOR_BY_BP_ID_BPC_ID_API_URL, { bp_id, bpc_id }),
    async function ({ new_pc, has_no_color }) {
      if (has_no_color) {
        await $.alert(T('marpple_shop_detail_alert::컬러 없음'));
        location.reload();
      }
      box().maker = box().maker || {};
      box.set('maker->product_color', new_pc);
      new_pc.collabo_type = product_color.collabo_type;
      const product_faces = product_color.product_faces2.value;
      await makeFcanvasBasic(createCanvasElementByBpcfs());

      if (NewMakerProductStyleS.isCarvedPhoneCase(new_pc._.base_product)) {
        G.mp.maker.reset_box_data();
        const { print } =
          new_pc._.base_product_color._.base_product_color_faces[0]._.base_product_face.size_faces[0];
        const print_area = product_faces[0].cv_print_area;
        if (product_faces[0].cv_preview) {
          product_faces[0].cv_preview.top = print.px.top - 2;
          product_faces[0].cv_preview.left = print.px.left - 2;
          box().maker.product_color.product_faces2.value[0].cv_preview = product_faces[0].cv_preview;
        }
        if (product_faces[0].designs[0]) {
          product_faces[0].designs[0].top =
            product_faces[0].designs[0].top - (print_area.top + 2) + print.px.top;
          product_faces[0].designs[0].left =
            product_faces[0].designs[0].left - (print_area.left + 2) + print.px.left;
          box().maker.product_color.product_faces2.value[0].designs = product_faces[0].designs;
        }
      } else {
        await addPfToFcanvasInMaker(product_faces);
        G.mp.maker.reset_box_data();
      }
      checkCvObjFullOverBp();
      unsetFcanvass();
      return { new_pc: omit('_', box.sel('maker->product_color')) };
    },
  );
  return result;
}

function throughDesignFilter(designs) {
  return go(
    designs,
    G.mp.maker.reject_ai,
    rejectWrongCvTextImage,
    filter((cv_obj) => cv_obj.visible),
  );
}

export async function makeCvBpcfWithCvPrintAreaToDataURL(product_color, bpf_id, zoom = 1) {
  const product_face = find((pf) => pf.bpf_id === bpf_id, product_color.product_faces.value);
  const canvas_size = G.mp.maker.CANVAS_WIDTH_ORIGIN * zoom;

  const f_canvas = new fabric.StaticCanvas(
    document.createElement('canvas'),
    extend(
      {
        enableRetinaScaling: false,
      },
      {
        width: canvas_size,
        height: canvas_size,
      },
    ),
  );
  product_face.cv_print_area.rx = 0;
  product_face.cv_print_area.ry = 0;
  product_face.cv_print_area.strokeDashArray = null;
  await go(
    [product_face.cv_bpcf, product_face.cv_print_area].concat(product_face.designs),
    throughDesignFilter,
    (designs) => addTdeisngsToFcanvasInMaker(designs, f_canvas, zoom),
  );
  const cv_print_area = G.mp.maker.cv_print_area(f_canvas);
  cv_print_area.visible = true;
  return f_canvas.toDataURL();
}

export async function makeOnlyDesignFaceCanvasByPrintArea({
  product_face,
  print_area,
  preview,
  width,
  height,
}) {
  const zoom =
    width && !height
      ? width / print_area.width
      : print_area.width > print_area.height
      ? width / print_area.width
      : height / print_area.height;
  const print_px = {
    top: print_area.top * zoom,
    left: print_area.left * zoom,
    width: print_area.width * zoom,
    height: print_area.height * zoom,
  };
  const f_canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: print_px.width,
    height: print_px.height,
    enableRetinaScaling: false,
  });
  f_canvas.getContext().imageSmoothingQuality = 'high';
  if (product_face) {
    const cv_background = product_face.cv_background;
    await go(
      [cv_background].concat(product_face.designs),
      compact,
      throughDesignFilter,
      (designs) => ungroupAllWithObjAttrs(f_canvas, designs),
      (designs) => addTdeisngsToFcanvasInMaker(designs, f_canvas, zoom),
    );
    go(
      f_canvas._objects,
      each((design) => {
        design.set({
          top: Math.round(design.top - print_px.top),
          left: Math.round(design.left - print_px.left),
        });
      }),
    );
  }
  f_canvas.renderAll();

  return go(f_canvas.lowerCanvasEl, async (canvas) => {
    const original_texture_url = preview?.shade?.original_texture_url;
    if (original_texture_url) {
      const _canvas = createCanvasElement(canvas);
      const ctx = _canvas.getContext('2d');
      const img = await loadImageFromUrl(original_texture_url);
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, _canvas.width, _canvas.height);
      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(canvas, 0, 0);
      return _canvas;
    }
    return canvas;
  });
}

export async function makeOnlyDesignFaceCanvas(product_face, base_product_size_id, { width, height }) {
  if (!product_face) {
    return createCanvasElement({ width, height: height || width });
  }
  const { bpf_id: base_product_face_id } = product_face;
  const {
    data: { print_area, preview, base_product_id, maker_type },
  } = await axios.get('/@api/print_area', {
    params: { base_product_face_id, base_product_size_id },
  });
  if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
    const real_width_cm = product_face.cv_print_area._data.real_width_cm;
    const px_width = width * (real_width_cm / BpOptionMaskingTapeConstantS.mockup_pattern_cm);
    return go(NewMakerPrintResultF.makePrintCanvasFromCvPrintArea({ pf: product_face, px_width }), (c) => {
      const canvas = createCanvasElement({ height: c.height, width });
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = ctx.createPattern(c, 'repeat-x');
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      return canvas;
    });
  } else {
    return go(
      makeOnlyDesignFaceCanvasByPrintArea({ product_face, print_area, preview, width, height }),
      (c) => {
        const is_embroidery_app = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.isEmbroideryApp(
          NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
          base_product_id,
        );
        if (is_embroidery_app) {
          return NewMakerBaseProductsEmbroideryF.makeEmbroideryPreview({
            canvas: c,
            product_face,
            base_product_size_id,
          });
        }
        return c;
      },
    );
  }
}

export async function uploadThumbProductFace(pf, size) {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  await drawProductFace(canvas, pf, null, null, true);
  return go($.uploadFileToUrl(canvas.toDataURL(), 'preview_image', 'png'), sel('url'));
}

export function drawProductFace(
  el,
  product_face,
  is_mask2,
  base_product_size_id,
  async,
  is_no_thumbnail,
  is_force_print_area_on,
) {
  /*is_mask2 = 미리보기*/
  if (!product_face) return el;
  const canvas_el = $.closest2(el, 'canvas');
  if (!canvas_el) return canvas_el;
  const width = parseInt($.attr(canvas_el, 'width') || $.css(canvas_el, 'width')); // 레티나 디스플레이를 위해 미리 가져온 값으로만 해야함, css는 상관 없으나 attr은 주의
  const height = parseInt($.attr(canvas_el, 'height') || $.css(canvas_el, 'height'));
  const zoom = width / (G.mp.maker.CANVAS_WIDTH * G.mp.maker.NSCREEN_ZOOM);
  if (!is_no_thumbnail && product_face.temp_thumb_url && zoom < 0.3) {
    const ctx = canvas_el.getContext('2d');
    return go(product_face.temp_thumb_url, makeImageFromUrl, (img) => {
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        canvas_el.width * 0.05,
        canvas_el.height * 0.05,
        canvas_el.width * 0.9,
        canvas_el.height * 0.9,
      );
      $.don_loader_end2($.parent(canvas_el));
      return canvas_el;
    });
  }
  if (product_face.preview_thumbnail_url) {
    canvas_el.width = width;
    canvas_el.height = height;
    const ctx = canvas_el.getContext('2d');
    const src = NewMakerImageF.makeResizedImage({
      url: product_face.preview_thumbnail_url,
      width,
    });
    return go(src, makeImageFromUrl, (img) => {
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas_el.width, canvas_el.height);
      $.don_loader_end2($.parent(canvas_el));
      return canvas_el;
    });
  }
  const f_canvas = new fabric.StaticCanvas(canvas_el, {
    width,
    height,
    enableRetinaScaling: false,
  });
  $.css(canvas_el, { opacity: 0 });
  f_canvas.getContext().imageSmoothingQuality = 'high';

  const is_on_mask2 = !product_face.cv_preview && is_mask2 && product_face.cv_mask2.src;
  if (is_on_mask2) product_face.cv_mask2.visible = true;
  if (product_face.collabo_type === 'creator') base_product_size_id = product_face.base_product_size_id;
  const sf = base_product_size_id
    ? _p.find(product_face.size_faces, function (sf) {
        return sf.base_product_size_id === base_product_size_id;
      })
    : null;
  function f() {
    const f1 = pipe(
      () => getTdesignsFromPf(product_face, sf, is_on_mask2, is_force_print_area_on),
      rejectWrongCvTextImage,
      (Tdesigns) => addTdeisngsToFcanvasInMaker(Tdesigns, f_canvas, zoom),
    );
    return _p.go(
      undefined,
      f1,
      function () {
        $.don_loader_end2($.parent(canvas_el));
        if (is_on_mask2 || is_force_print_area_on) {
          const cv_safe_area = getCvObj(f_canvas._objects, 'cv_safe_area');
          const cv_print_area = getCvObj(f_canvas._objects, 'cv_print_area');
          if (cv_safe_area) {
            cv_safe_area.visible = true;
            f_canvas.renderAll();
          } else if (cv_print_area) {
            cv_print_area.visible = true;
            f_canvas.renderAll();
          }
        }
      },
      async function () {
        if (product_face.rotate) await rotateCanvas(f_canvas, product_face.rotate);
      },
      function () {
        $.css(canvas_el, { opacity: 1 });
      },
    );
  }

  if (async) {
    return _p.go(f(), () => canvas_el);
  } else {
    f();
    return canvas_el;
  }
}

G.mp.maker = G.mp.maker || {};

G.mp.maker.draw_product_face2 = function (el, product_color) {
  if (!_p.v(product_color, 'product_faces2.value.0')) return;
  return drawProductFace(el, product_color.product_faces2.value[0]);
};

G.mp.maker.draw_product_face4 = function (el_product_colors, is_mask2) {
  /*df preview 일때만 쓰이고 있음.*/
  _each(_p.wrap_arr(el_product_colors), function (el_product_color) {
    const product_color = box.sel(el_product_color);
    _go(
      el_product_color,
      $.find('.product_face'),
      _p.each(function (el_pf, i) {
        if (!_p.v(product_color, 'product_faces2.value.0')) return;
        drawProductFace(el_pf, product_color.product_faces2.value[i], is_mask2, undefined);
      }),
    );
  });
  return el_product_colors;
};

G.mp.maker.draw_product_face5 = _tap(function (el_parent) {
  _go(el_parent, $.find('.product_color'), G.mp.maker.draw_product_face4);
});

G.mp.maker.draw_product_face6 = function (el_product_colors) {
  _each(_p.wrap_arr(el_product_colors), function (el_product_color) {
    const product_color = box.sel(el_product_color);
    _go(
      el_product_color,
      $.find('.product_face'),
      _p.each(function (el_pf, i) {
        if (!_p.v(product_color, 'product_faces2.value.0')) return;
        const pf_val = JSON.parse(JSON.stringify(product_color.product_faces2.value[i]));
        drawProductFace(el_pf, pf_val);
      }),
    );
  });
  return el_product_colors;
};

G.mp.maker.draw_product_face_in_ups = _tap(function (el_parent, is_mask2) {
  /*df에서만 쓰이고 있음 주의*/
  const el_up_items = $.is(el_parent, '.up_item') ? [el_parent] : _go(el_parent, $.find('.up_item'));
  _p.each(el_up_items, function (el_up_item) {
    const up = box.sel(el_up_item);
    if (!up.base_product_id) return;
    const bpfs = up._.base_product._.base_product_faces;
    const el_up_c_items = _go(el_up_item, $.find('.up_c_item'));
    _p.each(el_up_c_items, function (el_up_c_item) {
      const el_up_c_s_item =
        $.find1(el_up_c_item, '.up_c_s_item.selected') || $.find1(el_up_c_item, '.up_c_s_item');
      const up_c_s = box.sel(el_up_c_s_item);
      const base_product_size_id = up_c_s && up_c_s.base_product_size_id;
      _go(
        el_up_c_item,
        $.find('.product_color'),
        _p.each(function (el_product_color) {
          const product_color = box.sel(el_product_color);
          _go(
            el_product_color,
            $.find('.product_face'),
            _p.each(function (el_pf, i) {
              if (!_p.v(product_color, 'product_faces2.value.0')) return;
              const pf = product_color.product_faces2.value[i];
              const bpf = _p.find(bpfs, function (bpf) {
                return bpf.id == pf.bpf_id;
              });
              const sf =
                _p.find(pf.size_faces, function (sf) {
                  return sf.base_product_size_id == base_product_size_id;
                }) || _p.first(pf.size_faces);

              _p.each($.find(el_pf, '.design'), function (el_design) {
                const idx = $.attr(el_design, 'idx');
                const design = _p.go(
                  $.closest(el_design, '.designs'),
                  box.sel,
                  G.mp.maker.reject_ai,
                  _p.filter(function (d) {
                    return d.visible;
                  }),
                  _p.v(idx),
                );

                bpf &&
                  $.html(
                    $.find(el_design, '.position'),
                    !bpf.start_name
                      ? ''
                      : '<span>' + bpf.start_name + '</span> ' + G.mp.maker.from_start_cm(design, sf),
                  );
              });
              drawProductFace(el_pf, pf, is_mask2, base_product_size_id, false, true);
            }),
          );
        }),
      );
    });
  });
});
export function makeCanvassHtml(product_faces, width) {
  return legacyHtml`
    <div class="swiper-container">
      <div class="swiper-wrapper">
        ${go(
          product_faces,
          strMap(
            (pf) => legacyHtml`
              <div class="swiper-slide">
                <div class="canvas_wrapper" bpf_id="${pf.bpf_id}">
                  <div class="text">${pf.face_name}</div>
                  <canvas width="${width}" height="${width}"></canvas>
                  <div class="don_loader_wrap2">
                    <div class="don_loader_img"></div>
                  </div>
                </div>
              </div>
            `,
          ),
        )}
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  `;
}

export function makeCanvassHtmlRender(el, product_faces) {
  return go(
    $.find(el, '.canvas_wrapper'),
    ippL,
    each(([i, el]) => drawProductFace(el, product_faces[i], false, null)),
    function () {
      new Swiper($.find1(el, '.swiper-container'), {
        pagination: {
          el: $.find1(el, '.swiper-pagination'),
          type: 'fraction',
        },
        navigation: {
          nextEl: $.find1(el, '.swiper-button-next'),
          prevEl: $.find1(el, '.swiper-button-prev'),
        },
      });
    },
  );
}

export const initUploadAllPrintableFilesBtn = function () {
  go(
    $el(`
        <li class="load_all_pfs circle_icon">
          <div class="icon"></div>
          <div class="text">시안 불러오기</div>
        </li>
       `),
    $on('click', async () => {
      try {
        $.don_loader_start();
        const pf = go(
          box().maker.product_color.product_faces2.value,
          find((pf) => pf.bpf_id === G.mp.maker.editing_canvas().bpf_id),
        );

        const bp_size_id = box().maker.product_color.base_product_size_id;

        const cv_objs = G.mp.maker.designs();
        const fcanvas = G.mp.maker.editing_canvas();
        const exist_printable_files = go(
          pf.printable_files,
          filter((pf) => pf.url),
        );

        if (exist_printable_files.length === 0) {
          $.alert(`가져올 인쇄용 시안 파일이 존재하지 않습니다.`);
          return;
        }

        const is_all_printable_file_already_imported = go(
          exist_printable_files,
          every((file) => find((cv_obj) => cv_obj._data.file_id === file.id, cv_objs)),
        );

        if (is_all_printable_file_already_imported) {
          $.alert(`이미 모든 인쇄용 시안 파일이 로드되어 있습니다.`);
          return;
        }

        await go(
          exist_printable_files,
          each(async (file) => {
            /* 1. 파일 가드 - 인쇄용 시안 불러오기에 허용할 로직
             *    - AI 파일: png 추출 X => 메이커에 이미지 로드 X
             *    - PNG, SVG 파일: 메이커에 이미지 로드 허용
             *    - 그 외 확장자 block
             * */

            if (!['image/png', 'image/svg+xml', 'application/postscript'].includes(file.type)) {
              $.alert(`인쇄용 시안 파일 중 메이커에서 허용되지 않은 파일 형식은 제외됩니다. ${file.type}`);
              return;
            }

            const exist_cv_obj_file = find((cv_obj) => cv_obj._data.file_id === file.id, cv_objs);
            if (exist_cv_obj_file) {
              return;
            }

            /* 2. 메이커에서 이미지 로드
             *    - SVG 파일 대응
             *      : svg 인쇄용 시안 업로드 시 svg 에 대응하는 png 파일을 함께 upload 하고 svg file data 의 png 키 값에 png file data 정보를 추가함
             *      : svg_file_data = {... png: { [png_file_data] }  }
             *    - 그 외 PNG 이미지는 바로 업로드
             * */
            const { image: printable_file_image } = await $.post('/@api/maker/add_img_by_file', {
              file: file.type === 'image/svg+xml' ? file.png : file,
            });
            const file_width =
              ((file.type === 'image/svg+xml' ? file.png.width : file.width) / 300) *
              2.54 *
              fcanvas._px_per_1cm;

            const cv_image = await addCvImage({ image: printable_file_image, scale_to_width: file_width });
            cv_image._data.file_id = file.id;
            cv_image._data.original_cid = undefined;

            /* 3. 파일 추적 태깅 관리 */
            if (file.cid == null) {
              // 외부 ADOBE 를 통한 수동 인쇄용 시안 업로드 -> file 에 cid tagging X -> file id 로만 추적
              /* 3-1. 원본 디자인 추적이 안되므로 캔버스의 중앙 정렬 */
            } else {
              // 내부 이미지 편집기를 통한 인쇄용 시안 업로드 -> file 에 cid tagging O (추적 가능)
              cv_image._data.original_cid = file.cid;

              const exist_cv_obj = find((cv_obj) => cv_obj.cid === file.cid, cv_objs);
              if (exist_cv_obj == null) {
                // 추적 실패.
                $.alert(`원본 디자인을 추적하지 못한 인쇄용 시안 파일이 존재합니다.`);
              } else {
                // 추적 성공

                /* 3-1. 원본 디자인의 위치를 추적해서 포지셔닝 */
                const is_left = isCvObjectPositionLeft({ cv_obj: exist_cv_obj });

                let design_top_px;
                const shibori = calculatePrintableFileShiboriCm({ pf, bp_size_id, file });
                if (shibori) {
                  const face_info = find((face) => face.base_product_size_id === bp_size_id, pf.size_faces);
                  const { print, start_point_cm, px_per_1cm } = face_info;
                  const print_area_top_to_design_px = (shibori - start_point_cm) * px_per_1cm;
                  design_top_px = print.px.top + print_area_top_to_design_px;
                } else {
                  design_top_px = exist_cv_obj.top;
                }

                if (is_left) {
                  cv_image.set({ top: design_top_px, left: exist_cv_obj.left });
                } else {
                  cv_image.set({
                    top: design_top_px,
                    left: exist_cv_obj.left - (file_width - exist_cv_obj.getWidth()),
                  });
                }
              }
            }
            /* fabric canvas 이벤트 정렬 갱신 */
            cv_image.setCoords();
          }),
        );
        fcanvas.renderAll();
        await G.mp.maker.reset_layer();
      } catch (e) {
        console.error(e);
        $.alert(`시안 불러오기 중에 문제가 발생했습니다.`);
      } finally {
        $.don_loader_end();
        $.alert('시안 파일이 추가됐습니다.');
      }
    }),
    $insertBefore($1('html#dream_factory ul.decoration_menu_for_pc .open_chooser')),
  );
};

const isCvObjectPositionLeft = ({ cv_obj }) => {
  const canvas = G.mp.maker.editing_canvas();
  const print = G.mp.maker.cv_print_area(canvas);

  const { mt: print_mt } = print.absoluteCoords;
  const { mt: cv_obj_mt } = cv_obj.absoluteCoords;

  return cv_obj_mt.x < print_mt.x;
};

export const initPrevProductFaces = function (product_faces) {
  go(
    legacyHtml`
      <li class="click_for_prev_cv_bpcf circle_icon">
        <div class="icon"></div>
        <div class="text">고객 시안 보기</div>
      </li>
    `,
    $.el,
    $.on('click', function () {
      $.show($1('#prev_product_face_canvass'));
    }),
    $.append_to($1('html#dream_factory ul.decoration_menu_for_pc')),
  );
  const canvas_container_el = $1('.canvass .canvas-container');
  const prev_product_face_canvass_css = {
    width: 650,
    height: $.height(canvas_container_el) - 40,
    top: 85,
  };

  return go(
    legacyHtml`
      <div id="prev_product_face_canvass" style="visibility:hidden;">
        ${makeCanvassHtml(product_faces, $.width(canvas_container_el))}
        <div class="option">
          <button type="button" class="close">닫기</button>
        </div>
      </div>
    `,
    $.el,
    $.append_to($1('#marpplizer')),
    $.css(prev_product_face_canvass_css),
    tap((el) => makeCanvassHtmlRender(el, product_faces)),
    _p.tap(function () {
      $.css($1('#prev_product_face_canvass'), {
        display: 'none',
        visibility: 'visible',
      });
    }),
    $.on('click', '.close', function () {
      $.hide($1('#prev_product_face_canvass'));
    }),
  );
};

export const changeCvObjectToImgSrc = function (cv_object, resize_width, is_trim) {
  return new Promise(function (next) {
    const timeout = setTimeout(function () {
      next('');
      console && console.log && console.log('이미지 생성 실패 타임아웃', cv_object);
    }, 3000);
    try {
      cv_object.clone(async function (cv_object2) {
        try {
          // $도수 - 가드(면 도수 관련 옵션 그룹)
          // BpOptionF.biz.get.currentFace.hasDosuRelatedOptions() &&
          //   (await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj: cv_object2 }));

          if (resize_width) {
            cv_object2.scale(resize_width / cv_object2.width);
          } else {
            cv_object2.scale(1);
          }
          cv_object2.globalCompositeOperation = 'source-over';
          cv_object2.visible = true;

          const shadow = cv_object2.shadow;
          const stroke = cv_object2.stroke;
          let l = 0;
          let t = 0;
          let w = cv_object2.width;
          let h = cv_object2.height;

          /* 회전 처리*/
          if (cv_object2.angle) {
            const bounding_rect = cv_object2.getBoundingRect();
            w = bounding_rect.width;
            h = bounding_rect.height;
            w = w / cv_object2.scaleX;
            h = h / cv_object2.scaleY;
          }
          /* shadow 처리 */
          if (shadow) {
            if (shadow.offsetX < 0) l = l + shadow.offsetX;
            w = w + Math.abs(shadow.offsetX);
            if (shadow.offsetY < 0) t = t + shadow.offsetY;
            h = h + Math.abs(shadow.offsetY);
          }
          /* stroke 처리 */
          if (stroke) {
            w = w + cv_object2.strokeWidth * 2;
            h = h + cv_object2.strokeWidth * 2;
          }
          _p.go(
            renderSpecialColor(cv_object2),
            function () {
              return cv_object2[is_trim ? 'toDataURL2' : 'toDataURL']({
                left: l,
                top: t,
                width: w * cv_object2.scaleX,
                height: h * cv_object2.scaleY,
              });
            },
            function (src) {
              clearTimeout(timeout);
              next(src);
            },
            _p.catch(function () {
              clearTimeout(timeout);
              next('');
            }),
          );
        } catch (e) {
          clearTimeout(timeout);
          next('');
          console && console.log && console.log('이미지 생성 실패 캐치', cv_object);
        }
      });
    } catch (e) {
      console.error(e);
      next('');
      clearTimeout(timeout);
    }
  });
};

export async function makeFcanvasByProductColor(product_color, is_default) {
  if (!box.sel('maker')) {
    box.set('maker', {});
  }
  box.set('maker->product_color', product_color);
  if (is_default) {
    box.sel('maker->product_color').base_product_size_id = go(
      getBaseProductSizesInMaker(),
      (bps) => find((bp) => bp.is_thumb, bps) || bps[0],
      sel('id'),
    );
  }
  await go(
    range(6),
    map(() => document.createElement('canvas')),
    makeFcanvasBasic,
    function () {
      setProductFaces2Value2();
    },
  );
  return omit(['_'], product_color);
}

export async function setProductOnlyDesignFaceUrl(new_pc, size) {
  await go(
    new_pc.product_faces2.value,
    mapC(async (product_face) => {
      await makeOnlyDesignFaceCanvas(product_face, new_pc.base_product_size_id, size).then(async (c) => {
        product_face.only_design_face_url = await go(
          $.uploadFileToOnlyOriginalUrl({
            original_name: 'only_design_face_url',
            image_type: 'PNG',
            url: c.toDataURL(),
          }),
          sel('url'),
        );
      });
    }),
  );
}
