import { each, entries, find, go, map, sortBy, sortByDesc, values, range } from 'fxjs/es';
function _isBase64(url) {
  return url.indexOf('base64') > -1 || url.indexOf('blob') > -1;
}

// eslint-disable-next-line no-new-func
const isNode = new Function(`try {return this===global;}catch(e){return false;}`)();
function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const hexToRGB = (hexColor) => {
  const hex = hexColor.replace('#', '');
  const hexArray = hex.match(/[a-f\d]{2}/gi);
  const r = parseInt(hexArray[0], 16);
  const g = parseInt(hexArray[1], 16);
  const b = parseInt(hexArray[2], 16);
  return { r, g, b };
};

export function NormalizeHex(hexColor) {
  const { r, g, b } = hexToRGB(hexColor);
  return [r / 255, g / 255, b / 255, 1];
}

export const makeRepeatPatternToCanvas = async (src_url, dest_width, dest_height) => {
  const src_image = await loadImageFromUrl(src_url);
  const dest_canvas = createCanvasElement({ width: dest_width, height: dest_height });
  const ctx = dest_canvas.getContext('2d');
  ctx.rect(0, 0, dest_canvas.width, dest_canvas.height);
  ctx.fillStyle = ctx.createPattern(src_image, 'repeat');
  ctx.fill();
  return dest_canvas;
};

export const resizeImage = (img, sx, sy, sw, sh, dx, dy, dw, dh) => {
  const canvas = createCanvasElement({ width: dw, height: dh });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
  return canvas;
};
export function makeCanvasCutByRatio(c, { width, height, left, top }) {
  if (width === 1 && height === 1 && left === 0 && top === 0) return c;
  const ctxx = c.getContext('2d');
  const imageData = ctxx.getImageData(left * c.width, top * c.height, c.width * width, c.height * height);
  const canvas = createCanvasElement({ width: imageData.width, height: imageData.height });
  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);
  return canvas;
}

export function makeCanvasCutByLocation(c, { width, height, left, top }) {
  const ctxx = c.getContext('2d');
  const imageData = ctxx.getImageData(left, top, width, height);
  const canvas = createCanvasElement({ width: imageData.width, height: imageData.height });
  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);
  return canvas;
}

export function makeCanvasByImageWithSize(image, { width, height }) {
  width = width || image.width * (height / image.height);
  height = height || image.height * (width / image.width);
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas;
}

function makeRGBavg(r, g, b, isWeighted) {
  return (
    r * (isWeighted ? 0.2126 : 1 / 3) + g * (isWeighted ? 0.7152 : 1 / 3) + b * (isWeighted ? 0.0722 : 1 / 3)
  );
}

export const evaluateColorBrightness = (hexColor, isWeighted = false) => {
  const rgb = values(hexToRGB(hexColor));
  return makeRGBavg(...rgb, isWeighted) / 255;
};

export const evaluateImageBrightness = (canvas, isWeighted = false) => {
  const { width: w, height: h } = canvas;
  const pixelArray = canvas.getContext('2d').getImageData(0, 0, w, h).data;
  let pixelValueTotal = 0;
  let len = 0;
  for (let y = 0; y < h; y++)
    for (let x = 0; x < w; x++) {
      const idx = (x + w * y) * 4;
      if (pixelArray[idx + 3] / 255 < 0.5) continue;
      const w_avg = makeRGBavg(pixelArray[idx], pixelArray[idx + 1], pixelArray[idx + 2], isWeighted);
      pixelValueTotal += w_avg / 255;
      len++;
    }
  return pixelValueTotal / len;
};

export const convertImageDataToCanvas = (image_data) => {
  const canvas = createCanvasElement({ width: image_data.width, height: image_data.height });
  const ctx = canvas.getContext('2d');
  ctx.putImageData(image_data, 0, 0);
  return canvas;
};

export function fillColorOnCanvas(canvas, color) {
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return canvas;
}

export function setMaskedCanvas(canvas, maskImg) {
  const ctx = canvas.getContext('2d');
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(maskImg, 0, 0, maskImg.width, maskImg.height, 0, 0, canvas.width, canvas.height);
  ctx.globalCompositeOperation = 'source-over';
  return canvas;
}
let createCanvas, loadImage;
const canvas = isNode ? import('canvas') : new Promise((res) => res({}));
canvas.then(function (c) {
  if (isNode) {
    createCanvas = c.default?.createCanvas;
    loadImage = c.default?.loadImage;
  }
});
export function createCanvasElement({ width, height }) {
  if (isNode) {
    return createCanvas(width, height);
  } else {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
}
export const loadImageFromUrl = async (url) => {
  if (isNode) {
    if (url.indexOf('http') > -1)
      return loadImage(url.includes('?') ? url + '&canvas=v10' : url + '?canvas=v10');
    else return loadImage('http:' + (url.includes('?') ? url + '&canvas=v10' : url + '?canvas=v10'));
  } else {
    const img = document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.src = _isBase64(url) ? url : url.includes('?') ? url + '&canvas=v10' : url + '?canvas=v10';
    return new Promise(function (resolve, reject) {
      img.addEventListener('load', function () {
        resolve(img);
      });
      img.addEventListener('error', function (event) {
        reject(event);
      });
    });
  }
};

export function makeCanvasByImg(img) {
  const canvas = createCanvasElement({ width: img.width, height: img.height });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  return canvas;
}

export function makeCanvasByImgWithResize(img, resize_ratio) {
  const width = resize_ratio === 1 ? img.width : Math.ceil(img.width * resize_ratio);
  const height = resize_ratio === 1 ? img.height : Math.ceil(img.height * resize_ratio);
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
  return canvas;
}

export function makeCloneCanvas(c, width, height) {
  width = width || c.width;
  height = height || c.height;
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(c, 0, 0, c.width, c.height, 0, 0, width, height);
  return canvas;
}

export function makeCloneCanvasPut(c) {
  const img_data = c.getContext('2d').getImageData(0, 0, c.width, c.height);
  const canvas = createCanvasElement({ width: c.width, height: c.height });
  const ctx = canvas.getContext('2d');
  ctx.putImageData(img_data, 0, 0);
  return canvas;
}

export function makeCanvasByUrl(url) {
  return go(loadImageFromUrl(url), (img) => makeCanvasByImg(img));
}

export function makeCanvasByUrlWithResize(url, pixel_amount_limit) {
  return go(loadImageFromUrl(url), (img) => {
    const img_pixel_amount = img.width * img.height;
    let resize_ratio = 1;

    if (img_pixel_amount > pixel_amount_limit) {
      resize_ratio = Math.sqrt(pixel_amount_limit / img_pixel_amount);
    }

    return makeCanvasByImgWithResize(img, resize_ratio);
  });
}

export const makeImageCanvasByRatio = (
  trimed_canvas,
  width,
  backgorund_color,
  canvas_ratio = 1,
  trimed_canvas_width_ratio = 1,
  trimed_canvas_height_ratio = 1,
) => {
  const canvas = createCanvasElement({ width, height: width * canvas_ratio });
  const ctx = canvas.getContext('2d');

  let trimed_canvas_width = canvas.width * trimed_canvas_width_ratio;
  let trimed_canvas_height = (trimed_canvas_width / trimed_canvas.width) * trimed_canvas.height;

  if (trimed_canvas_height > canvas.height) {
    trimed_canvas_height = canvas.height * trimed_canvas_height_ratio;
    trimed_canvas_width = (trimed_canvas_height / trimed_canvas.height) * trimed_canvas.width;
  }

  if (backgorund_color) {
    ctx.fillStyle = backgorund_color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  ctx.drawImage(
    trimed_canvas,
    0,
    0,
    trimed_canvas.width,
    trimed_canvas.height,
    canvas.width / 2 - trimed_canvas_width / 2,
    canvas.height / 2 - trimed_canvas_height / 2,
    trimed_canvas_width,
    trimed_canvas_height,
  );
  return canvas;
};

export function makeGrayCanvas(c, is_weighted) {
  const _ctx = c.getContext('2d');
  const imageData = _ctx.getImageData(0, 0, c.width, c.height);
  return go(createCanvasElement({ width: c.width, height: c.height }), (canvas) => {
    const ctx = canvas.getContext('2d');
    for (let y = 0; y < canvas.height; y++)
      for (let x = 0; x < canvas.width; x++) {
        const idx = (x + canvas.width * y) * 4;
        if (imageData.data[idx + 3] === 0) continue;
        const avg = makeRGBavg(
          imageData.data[idx],
          imageData.data[idx + 1],
          imageData.data[idx + 2],
          is_weighted,
        );
        imageData.data[idx] = avg;
        imageData.data[idx + 1] = avg;
        imageData.data[idx + 2] = avg;
      }
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  });
}

export function makeCanvasByContrastEffect(c, is_weighted) {
  const _ctx = c.getContext('2d');
  const imageData = _ctx.getImageData(0, 0, c.width, c.height);
  const image_255 = {};
  return go(createCanvasElement({ width: c.width, height: c.height }), (canvas) => {
    const ctx = canvas.getContext('2d');
    const total_px = canvas.width * canvas.height;
    const number_of_both_end_px = Math.round(total_px * 0.005);
    /* 0, 255 개수 */
    for (let y = 0; y < canvas.height; y++)
      for (let x = 0; x < canvas.width; x++) {
        const idx = (x + canvas.width * y) * 4;
        if (imageData.data[idx + 3] < 200) continue;
        const avg = Math.round(
          makeRGBavg(imageData.data[idx], imageData.data[idx + 1], imageData.data[idx + 2], is_weighted),
        );
        imageData.data[idx] = avg;
        imageData.data[idx + 1] = avg;
        imageData.data[idx + 2] = avg;
        image_255[avg] = !image_255[avg] ? 1 : image_255[avg] + 1;
      }
    // ctx.putImageData(imageData, 0, 0);
    /*흑백완료*/
    let maximum_brightness_count = 0;
    const maximum_brightness = go(
      image_255,
      entries,
      sortByDesc(([brightness]) => parseInt(brightness)),
      find(([brightness, count]) => {
        maximum_brightness_count += count;
        return maximum_brightness_count >= number_of_both_end_px;
      }),
      ([brightness, count]) => brightness,
    );
    /* 0.5/전체 최대 밝기 */
    let minimum_brightness_count = 0;
    const minimum_brightness = go(
      image_255,
      entries,
      sortBy(([brightness]) => parseInt(brightness)),
      find(([brightness, count]) => {
        minimum_brightness_count += count;
        return minimum_brightness_count >= number_of_both_end_px;
      }),
      ([brightness, count]) => brightness,
    );
    const spectrum_size = maximum_brightness - minimum_brightness;
    /* 0.5/전체 최저 밝기 */
    if (spectrum_size === 0 || spectrum_size === 1) {
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(c, 0, 0);
    } else {
      for (let y = 0; y < canvas.height; y++)
        for (let x = 0; x < canvas.width; x++) {
          const idx = (x + canvas.width * y) * 4;
          if (imageData.data[idx + 3] === 0) continue;
          if (imageData.data[idx] >= maximum_brightness) {
            imageData.data[idx] = (maximum_brightness - minimum_brightness) * (255 / spectrum_size);
            imageData.data[idx + 1] = imageData.data[idx];
            imageData.data[idx + 2] = imageData.data[idx];
          } else if (imageData.data[idx] <= minimum_brightness) {
            imageData.data[idx] = 0;
            imageData.data[idx + 1] = imageData.data[idx];
            imageData.data[idx + 2] = imageData.data[idx];
          } else {
            imageData.data[idx] = (imageData.data[idx] - minimum_brightness) * (255 / spectrum_size);
            imageData.data[idx + 1] = imageData.data[idx];
            imageData.data[idx + 2] = imageData.data[idx];
          }
        }

      ctx.putImageData(imageData, 0, 0);
    }
    // for (let y = 0; y < canvas.height; y++)
    //   for (let x = 0; x < canvas.width; x++) {
    //     const idx = (x + canvas.width * y) * 4;
    //     if (imageData.data[idx + 3] === 0) continue;
    //     if (imageData.data[idx] <= minimum_brightness) {
    //       imageData.data[idx] = minimum_brightness;
    //       imageData.data[idx + 1] = minimum_brightness;
    //       imageData.data[idx + 2] = minimum_brightness;
    //     }
    //   }
    // for (let y = 0; y < canvas.height; y++)
    //   for (let x = 0; x < canvas.width; x++) {
    //     const idx = (x + canvas.width * y) * 4;
    //     if (imageData.data[idx + 3] === 0) continue;
    //     imageData.data[idx] = imageData.data[idx] - minimum_brightness;
    //     imageData.data[idx] *= 255 / spectrum_size;
    //     imageData.data[idx + 1] = imageData.data[idx];
    //     imageData.data[idx + 2] = imageData.data[idx];
    //   }
    /*전체 자동 명암대비*/
    return canvas;
  });
}

export function makeLightenCanvas(c, strength) {
  const _ctx = c.getContext('2d');
  const imageData = _ctx.getImageData(0, 0, c.width, c.height);
  return go(c, (canvas) => {
    const ctx = canvas.getContext('2d');
    for (let y = 0; y < canvas.height; y++)
      for (let x = 0; x < canvas.width; x++) {
        const idx = (x + canvas.width * y) * 4;
        if (imageData.data[idx + 3] === 0) continue;
        imageData.data[idx] += strength;
        imageData.data[idx + 1] += strength;
        imageData.data[idx + 2] += strength;
      }
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  });
}

export function makeCanvasAdjustedAlpha(c, strength) {
  const _ctx = c.getContext('2d');
  const imageData = _ctx.getImageData(0, 0, c.width, c.height);
  return go(c, (canvas) => {
    const ctx = canvas.getContext('2d');
    for (let y = 0; y < canvas.height; y++)
      for (let x = 0; x < canvas.width; x++) {
        const idx = (x + canvas.width * y) * 4;
        imageData.data[idx + 3] = strength;
      }
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  });
}

export function plusHexWithDecimal(hex, decimal) {
  return go(
    hexToRGB(hex),
    values,
    map((v) => v + decimal),
    map((v) => {
      if (v > 255) return 255;
      if (v < 0) return 0;
      return v;
    }),
    map((v) => v.toString(16)),
    map((v) => {
      if (v.length == 1) return '0' + v;
      return v;
    }),
    (arr) => '#' + arr.join(''),
  );
}

export function makeCanvasByEach(c, func) {
  const _ctx = c.getContext('2d');
  const imageData = _ctx.getImageData(0, 0, c.width, c.height);
  return go(createCanvasElement({ width: c.width, height: c.height }), (canvas) => {
    const ctx = canvas.getContext('2d');
    for (let y = 0; y < canvas.height; y++)
      for (let x = 0; x < canvas.width; x++) {
        const idx = (x + canvas.width * y) * 4;
        func(imageData.data, idx);
      }
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  });
}

export function makeGradientCanvas({ width, height }, arr, grad_direction) {
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');

  const gradient = ctx.createLinearGradient(
    grad_direction.x1,
    grad_direction.y1,
    grad_direction.x2,
    grad_direction.y2,
  );
  go(
    arr,
    each((grad) => {
      gradient.addColorStop(grad.offset, grad.hex);
    }),
  );

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return canvas;
}
export function multiplyCanvas(canvas, img) {
  const c = makeCloneCanvas(canvas);
  const ctx = c.getContext('2d');
  ctx.globalCompositeOperation = 'multiply';
  ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
  return c;
}
export function sourceOverCanvas(canvas, img, is_extend) {
  const c = is_extend ? canvas : makeCloneCanvas(canvas);
  const ctx = c.getContext('2d');
  ctx.globalCompositeOperation = 'source-over';
  ctx.drawImage(img, 0, 0);
  return c;
}

export function destinationInCanvas(canvas, img) {
  const c = makeCloneCanvas(canvas);
  const ctx = c.getContext('2d');
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(img, 0, 0);
  return c;
}

function a(target_image_data, src_image_data, idx, brightness) {
  // if (target_image_data.data[idx + 3] > 0) return;
  target_image_data.data[idx] = src_image_data.data[idx] + brightness;
  target_image_data.data[idx + 1] = src_image_data.data[idx + 1] + brightness;
  target_image_data.data[idx + 2] = src_image_data.data[idx + 2] + brightness;
  target_image_data.data[idx + 3] = src_image_data.data[idx + 3];
}

export function makeOutlineCanvas(c, step) {
  const ctxx = c.getContext('2d');
  const src_image_data = ctxx.getImageData(0, 0, c.width, c.height);
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  const target_image_data = ctx.getImageData(0, 0, c.width, c.height);
  /*top*/
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx - canvas.width * 4;
      function next(idx, i) {
        return idx + canvas.width * (4 * i);
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        a(target_image_data, src_image_data, idx, 4 * step);
        a(target_image_data, src_image_data, next(idx, 1), 3 * step);
        a(target_image_data, src_image_data, next(idx, 2), 2 * step);
        a(target_image_data, src_image_data, next(idx, 3), 1 * step);
      }
    }
  }
  // /*left*/
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx - 4;
      function next(idx, i) {
        return idx + 4 * i;
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        a(target_image_data, src_image_data, idx, 4 * step);
        a(target_image_data, src_image_data, next(idx, 1), 3 * step);
        a(target_image_data, src_image_data, next(idx, 2), 2 * step);
        a(target_image_data, src_image_data, next(idx, 3), 1 * step);
      }
    }
  }
  // /*right*/
  for (let y = 0; y < canvas.height; y++) {
    for (let x = canvas.width; x > 0; x--) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx + 4;
      function next(idx, i) {
        return idx - 4 * i;
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        a(target_image_data, src_image_data, idx, -4 * step);
        a(target_image_data, src_image_data, next(idx, 1), -3 * step);
        a(target_image_data, src_image_data, next(idx, 2), -2 * step);
        a(target_image_data, src_image_data, next(idx, 3), -1 * step);
      }
    }
  }
  // /*bottom*/
  for (let x = 0; x < canvas.width; x++) {
    for (let y = canvas.height; y > 0; y--) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx + canvas.width * 4;
      if (prev_idx < 0) continue;
      function next(idx, i) {
        return idx - canvas.width * (4 * i);
      }
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        a(target_image_data, src_image_data, idx, -4 * step);
        a(target_image_data, src_image_data, next(idx, 1), -3 * step);
        a(target_image_data, src_image_data, next(idx, 2), -2 * step);
        a(target_image_data, src_image_data, next(idx, 3), -1 * step);
      }
    }
  }
  ctx.putImageData(target_image_data, 0, 0);
  return canvas;
}

export function makeOutlineCanvas2(c, step, depth_brightness) {
  const ctxx = c.getContext('2d');
  const src_image_data = ctxx.getImageData(0, 0, c.width, c.height);
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  const target_image_data = ctx.getImageData(0, 0, c.width, c.height);
  const range_arr = range(Math.abs(step));
  const sign = step < 0 ? -1 : 1;
  const strength = (depth_brightness / range_arr.length) * sign;

  /*top*/
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx - canvas.width * 4;
      function next(idx, i) {
        return idx + canvas.width * (4 * i);
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        go(
          range_arr,
          each((i) => {
            a(target_image_data, src_image_data, next(idx, i), (range_arr.length - i) * strength);
          }),
        );
      }
    }
  }
  // /*left*/
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx - 4;
      function next(idx, i) {
        return idx + 4 * i;
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        go(
          range_arr,
          each((i) => {
            a(target_image_data, src_image_data, next(idx, i), (range_arr.length - i) * strength);
          }),
        );
      }
    }
  }
  // /*right*/
  for (let y = 0; y < canvas.height; y++) {
    for (let x = canvas.width; x > 0; x--) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx + 4;
      function next(idx, i) {
        return idx - 4 * i;
      }
      if (prev_idx < 0) continue;
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        go(
          range_arr,
          each((i) => {
            a(target_image_data, src_image_data, next(idx, i), (range_arr.length - i) * strength * -1);
          }),
        );
      }
    }
  }
  // /*bottom*/
  for (let x = 0; x < canvas.width; x++) {
    for (let y = canvas.height; y > 0; y--) {
      const idx = (x + canvas.width * y) * 4;
      const prev_idx = idx + canvas.width * 4;
      if (prev_idx < 0) continue;
      function next(idx, i) {
        return idx - canvas.width * (4 * i);
      }
      if (src_image_data.data[prev_idx + 3] < 255 && src_image_data.data[idx + 3] === 255) {
        go(
          range_arr,
          each((i) => {
            a(target_image_data, src_image_data, next(idx, i), (range_arr.length - i) * strength * -1);
          }),
        );
      }
    }
  }
  ctx.putImageData(target_image_data, 0, 0);
  return canvas;
}
