import { go, strMap, html } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { makeOpacityFunctionHtml } from '../opacity.js';
import { makeRangeSliderHtml } from '../range_slider.js';
import { makeAngleAttr, makeCharSpacingFunctionAttr, makeLineHeightAttr } from './fs.js';
import { makeCvPatternHtml } from '../CvPattern/tmpl.js';
import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { requiringBorder } from '../Product/bpc_tmpl.js';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';

const font_family_font_styl_tmpl = (cv_text, font) => {
  // const font = find_font_family(cv_text);
  return legacyHtml`
    <div class="control select_font_and_style section">
      <div class="head">${T('Font')}</div>
      <div class="select_font">
        <div class="select">
          <img src="${(font && font.url_2x) || ''}" alt="" />
          <div class="arrow"></div>
        </div>
      </div>
      <div
        class="style"
        has_bold="${font.has_bold}"
        font_weight="${cv_text.fontWeight}"
        font_style="${cv_text.fontStyle}"
        underline="${cv_text.underline}"
        linethrough="${cv_text.linethrough}"
        text_align="${cv_text.textAlign}"
      >
        <div class="check_box">
          <button type="button" key="fontWeight" val="bold" _default="normal" class="bold check"></button>
          <button type="button" key="fontStyle" val="italic" _default="normal" class="italic check"></button>
          <button type="button" key="underline" val="true" _default="false" class="underline check"></button>
          <button type="button" key="linethrough" val="true" _default="false" class="strike check"></button>
        </div>
        <div class="radio_box">
          <button type="button" val="left" key="textAlign" class="left radio"></button>
          <button type="button" val="center" key="textAlign" class="center radio"></button>
          <button type="button" val="right" key="textAlign" class="right radio"></button>
        </div>
      </div>
    </div>
  `;
};
function specialColorBackground(code) {
  if (code == 'hologram')
    return 'url(//s3.marpple.co/files/u_193535/2018/12/150/f_18183_1544769840948_l2N5B0ls6cXkmaFCf9c7h.jpeg)';
  else if (code == 'gold')
    return 'url(//s3.marpple.co/files/u_193535/2018/12/150/f_18193_1544770312919_fQg2GPzIGBWTc7H2aZ.jpg)';
  else if (code == 'sliver')
    return 'url(//s3.marpple.co/files/u_193535/2018/12/150/f_18197_1544770975819_gan5dOyuzuNur5Uws.png)';
  return code;
}

function makeSelected(press_color, selected_color) {
  try {
    if (selected_color.code?.includes('#')) {
      if (!press_color.code.includes('#')) return;
      return press_color.code.toLowerCase() == selected_color.code.toLowerCase();
    } else {
      return press_color.id == selected_color.id;
    }
  } catch (e) {
    ConfigSentryF.makerCapture(e);
  }
}

const makeColorItemHtml = (press_color, selected_color) => {
  const [hex_code, spot_color_name] = press_color.code.split('**'); // 별색 이름 대응
  return legacyHtml`
  <div
    class="maker-color-picker__item color_item ${makeSelected(press_color, selected_color) ? 'selected' : ''}"
    _id="${press_color.id}"
    color_code="${hex_code}"
     is-spot-color="${!!spot_color_name}"
     spot-color-name="${spot_color_name ?? ''}"
    _sel="press_colors->(#${press_color.id})"
  >
    <span class="${
      requiringBorder([hex_code]) ? 'too_bright' : ''
    }" style="background: ${specialColorBackground(hex_code)}"></span>
  </div>
`;
};

export const makeMakerColorPickerColorName = (color_name) => {
  return color_name ? ` - ${color_name}` : ``;
};
export const colorTmplPc = (cv_text_image, press_colors) => {
  const { press_color_id: selected_press_color_id, press_color_code } = cv_text_image._data;
  const color_name = cv_text_image._data['press_color_name' + _en];
  return html`
    <div class="control section maker-color-picker">
      <div class="head maker-color-picker__title">
        ${T('Text Color')}<span class="maker-color-picker__color-name"
          >${makeMakerColorPickerColorName(color_name)}</span
        >
      </div>
      <div class="maker-color-picker__list">
        ${go(
          press_colors,
          strMap((press_color) =>
            makeColorItemHtml(press_color, { id: selected_press_color_id, code: press_color_code }),
          ),
        )}
      </div>
    </div>
  `;
};

const makeCharSpacingFunctionHtml = function (cv_object) {
  return makeRangeSliderHtml(makeCharSpacingFunctionAttr(cv_object));
};

const makeLineHeightFunctionHtml = function (cv_object) {
  return makeRangeSliderHtml(makeLineHeightAttr(cv_object));
};

const makeAngleFunctionHtml = function (cv_text_image) {
  return makeRangeSliderHtml(makeAngleAttr(cv_text_image));
};

export const makeCvTextImageMarpplizerHtml = (
  cv_text,
  font,
  press_colors,
  cv_text_image,
  pattern_data,
) => html`
  <div class="cv_text_editor body text_editor_ev marpplizer_contents_wrapper">
    <div class="main_head">
      <div class="body">${T('Font Style')}</div>
      <div class="option"><button class="marpplizer_back_btn marpplizer_back_btn_styl"></button></div>
    </div>
    ${font_family_font_styl_tmpl(cv_text, font)}
    ${BpOptionF.biz.get.currentFace.hasDosuColorOptions()
      ? html`
          ${OMPDosuF.dosuImageColorPickerHtml(cv_text_image)} ${OMPDosuF.dosuColorPickerHtml(cv_text_image)}
        `
      : press_colors?.length
      ? colorTmplPc(cv_text_image, press_colors)
      : ``}
    <div class="control input_number_row section">
      ${makeCharSpacingFunctionHtml(cv_text)} ${makeLineHeightFunctionHtml(cv_text)}
      <div class="visibility_hidden" style="visibility: hidden;"></div>
      ${makeAngleFunctionHtml(cv_text_image)}
    </div>
    ${makeCvPatternHtml(pattern_data)} ${makeOpacityFunctionHtml(cv_text_image)}
  </div>
`;

export const makeCvTextImageMobileHtml = (cv_text, font) => legacyHtml`
  <div class="m_text wrapper text_editor_ev">
    ${font_family_font_styl_tmpl(cv_text, font)} ${makeCharSpacingFunctionHtml(cv_text)}
    ${makeLineHeightFunctionHtml(cv_text)}
  </div>
`;
export const makeFontChangeHtml = (fontss) => legacyHtml`
  <div class="body section m_font_change">
    <div class="swiper-container m_text_font_swiper_container">
      <div class="swiper-wrapper">
        ${strMap(
          (fonts) => legacyHtml`
            <div class="swiper-slide">
              ${strMap(
                (font) => legacyHtml`
                  <div class="option font" font_family="${font.original_name}" thumb_url="${font.url_2x}">
                    <img src="${font.url_2x || ''}" alt="" class="off" />
                    <img src="${font.url_2x_a || ''}" alt="" class="on" />
                  </div>
                `,
                fonts,
              )}
            </div>
          `,
          fontss,
        )}
      </div>
      <div class="swiper-pagination m_text_font_swiper_pagination"></div>
    </div>
  </div>
`;

export const makeSelectFontsHtml = (fonts, selected_font) => legacyHtml`
  <div id="select_fonts_app">
    <div
      class="position_absolute_block"
      style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: transparent;"
    ></div>
    <div class="fonts_wrapper iscroll_wrapper">
      <div class="fonts iscroll_ul_wrapper">
        ${strMap(
          (font) => legacyHtml`
            <div
              class="font i_item ${selected_font.original_name === font.original_name ? 'selected' : ''}"
              font_family="${font.original_name}"
              thumb_url="${font.url_2x}"
            >
              <img src="${font.url_2x || ''}" alt="" class="off" />
              <img src="${font.url_2x_a || ''}" alt="" class="on" />
            </div>
          `,
          fonts,
        )}
      </div>
    </div>
  </div>
`;
