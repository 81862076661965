import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

const filterItemBasicHtml = (filter_item, filter_list) => {
  return html`<div class="omp-product-filter-item__item">
    <label class="omp-product-filter-item__checkbox">
      <input
        type="checkbox"
        class="omp-product-filter-item__input-checkbox"
        ${filter_item.checked ? 'checked' : ''}
        name="f_${filter_list.id}[]"
        value="${filter_item.id}"
        data-name="${filter_item.name}"
        data-filter_list_id="${filter_item.filter_list_id}"
      />
      <div class="omp-product-filter-item__checkbox-box" data-checkbox-checked="${filter_item.checked}"></div>
      <div class="omp-product-filter-item__checkbox-name">${filter_item.name}</div>
    </label>
  </div>`;
};

const filterItemColorHtml = (filter_item, filter_list) => {
  return html`<div class="omp-product-filter-item__color-item">
    <label class="omp-product-filter-item__color-checkbox">
      <input
        type="checkbox"
        class="omp-product-filter-item__input-checkbox"
        ${filter_item.checked ? 'checked' : ''}
        name="f_${filter_list.id}[]"
        value="${filter_item.id}"
        data-name="${filter_item.name}"
        data-filter_list_id="${filter_item.filter_list_id}"
      />
      <div
        class="omp-product-filter-item__color-checkbox-box-wrapper"
        data-checkbox-checked="${filter_item.checked}"
      >
        <div
          class="omp-product-filter-item__color-checkbox-box"
          style="background-color: ${filter_item.color_code}; border-color: ${filter_item.color_code};"
          data-color-code="${filter_item.color_code}"
        ></div>
      </div>
      <div class="omp-product-filter-item__color-checkbox-name">${filter_item.name.replace('계열', '')}</div>
    </label>
  </div>`;
};

const filterListHtml = (filter_list, filterItemHtml = filterItemBasicHtml) => {
  if (filter_list._.filter_items.length) {
    return html`<div
      class="omp-product-filter-list__list"
      data-id="${filter_list.id}"
      data-is_open="${filter_list.count > 0}"
    >
      <div class="omp-product-filter-list__name">
        <div>
          ${filter_list.name}
          <span class="omp-product-filter-list__select-count" data-count="${filter_list.count}"
            >(${filter_list.count})</span
          >
        </div>
        <div class="omp-product-filter-list__arrow">${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</div>
      </div>
      <div class="omp-product-filter-item__list">
        ${strMap((fi) => {
          return filterItemHtml(fi, filter_list);
        }, filter_list._.filter_items)}
      </div>
    </div>`;
  }
  return '';
};

export const leftFilter = ({ brand_filter, color_filter, size_filter, filter_lists }) => {
  const tt_product_filter_header = TT('product::list::list_7_1');
  return html`<div class="omp-product-filter">
    <div class="omp-product-filter__header">
      ${tt_product_filter_header}
      <button type="button" class="omp-product-filter__reset-icon">${OMPCoreAtomTmplS.resetIcon()}</button>
    </div>
    <div class="omp-product-filter__body">
      ${filterListHtml(brand_filter)}${filterListHtml(color_filter, filterItemColorHtml)}${filterListHtml(
        size_filter,
      )}${strMap((filter_list) => {
        return filterListHtml(filter_list);
      }, filter_lists)}
    </div>
  </div>`;
};
export const leftMenuFilter = (ompLeftMenu, { brand_filter, color_filter, size_filter, filter_lists }) => {
  return html`
    <div id="omp-left" class="omp-left">
      <div class="omp-left__wrap">
        ${ompLeftMenu}
        <div class="omp-left__filter-list">
          ${leftFilter({ brand_filter, color_filter, size_filter, filter_lists })}
        </div>
      </div>
    </div>
  `;
};
