import axios from 'axios';
import { $closest, $delegate, $find, $qs, $setHTML } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopAppKeywordF } from '../../../F/Function/module/MShopAppKeywordF.js';
import { MShopAppKeywordHistoryTmplS } from '../../S/Tmpl/module/MShopAppKeywordHistoryTmplS.js';

export const delegateSearchHistory = (tab_el) => {
  return go(
    tab_el,
    $delegate('click', '.search-history__delete-btn', async ({ currentTarget }) => {
      const $history_item = $closest('.search-history__item')(currentTarget);
      const $history_link = $qs('.search-history__link', $history_item);
      const { q } = $history_link.dataset;

      if (!q) {
        $.alert('최근 검색어 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return;
      }

      try {
        const {
          data: { queries },
        } = await axios.delete(`/${T.lang}/@api/keywords/history?q=${q}`);
        go(tab_el, $find('.search-history'), $setHTML(MShopAppKeywordHistoryTmplS.searchHistory(queries)));
      } catch (e) {
        $.alert(e.toString());
      }
    }),
    $delegate('click', '.search-history__delete-all-btn', async () => {
      try {
        const {
          data: { queries },
        } = await axios.delete(`/${T.lang}/@api/keywords/history/all`);
        go(tab_el, $find('.search-history'), $setHTML(MShopAppKeywordHistoryTmplS.searchHistory(queries)));
      } catch (e) {
        $.alert(e.toString());
      }
    }),
    $delegate('click', '.search-history__link', async (e) => {
      e.originalEvent.preventDefault();
      const { q: raw_q } = e.currentTarget.dataset;
      const q = (raw_q || '').trim();
      if (!q) {
        return;
      }
      await axios.post(`/${T.lang}/@api/keywords/history?q=${q}`);
      MShopAppKeywordF.navigateToKeywordsResult(q);
    }),
    $delegate('click', '.search-popular__item', async (e) => {
      e.originalEvent.preventDefault();
      const { q: raw_q } = e.currentTarget.dataset;
      const q = (raw_q || '').trim();
      if (!q) {
        return;
      }
      await axios.post(`/${T.lang}/@api/keywords/history?q=${q}`);
      MShopAppKeywordF.navigateToKeywordsResult(q);
    }),
  );
};
