import { CommonNS } from '@marpple/sticker-editor';
import { equals2, go, isNil, map, rangeL, reduce, rejectL } from 'fxjs/es';

export const processSVGFile = async (file) => {
  const svg_file_name = file.name;
  const png_file_name = `${svg_file_name.replace(/\.svg$/, '')}.png`;

  const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', reject);
    file_reader.readAsText(file);
  });

  const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
  const parser_error_el = svg_doc.querySelector('parsererror');
  if (parser_error_el != null) {
    const error = new Error(parser_error_el.innerText);
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = T('maker_error_alert::SVG 파일을 해석할 수 없습니다.');
    throw error;
  }
  const svg_el = svg_doc.querySelector('svg');
  if (svg_el == null) {
    const error = new Error('Cannot find a SVG element.');
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = T('maker_error_alert::SVG 객체를 찾을 수 없습니다.');
    throw error;
  }

  const { width: original_width, height: original_height } =
    svg_el.hasAttributeNS(null, 'width') && svg_el.hasAttributeNS(null, 'height')
      ? { width: svg_el.width.baseVal.value, height: svg_el.height.baseVal.value }
      : svg_el.viewBox.baseVal;
  if (Number.isNaN(original_width) || !Number.isFinite(original_width) || original_width <= 0) {
    const error = new Error(`Invalid width : ${original_width}`);
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = 'SVG 의 width 가 유효하지 않습니다.';
    throw error;
  }
  if (Number.isNaN(original_height) || !Number.isFinite(original_height) || original_height <= 0) {
    const error = new Error(`Invalid height : ${original_height}`);
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = 'SVG 의 height 가 유효하지 않습니다.';
    throw error;
  }

  const min_px = 1000;
  const sx = min_px / original_width;
  const sy = min_px / original_height;
  const s = Math.round(Math.min(sx, sy));
  const width = original_width * s;
  const height = original_height * s;
  svg_el.setAttributeNS(null, 'width', `${width}`);
  svg_el.setAttributeNS(null, 'height', `${height}`);

  if (!svg_el.hasAttributeNS(null, 'viewBox')) {
    svg_el.setAttributeNS(null, 'viewBox', `0 0 ${original_width} ${original_height}`);
  }

  const svg_str2 = new XMLSerializer().serializeToString(svg_el);

  const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
    const blob = new Blob([svg_str2], { type: 'image/svg+xml' });
    const file_reader = new FileReader();
    file_reader.addEventListener('error', reject);
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(blob);
  });

  const image_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'image');
  image_el.setAttributeNS(null, 'x', '0');
  image_el.setAttributeNS(null, 'y', '0');
  image_el.setAttributeNS(null, 'width', `${width}`);
  image_el.setAttributeNS(null, 'height', `${height}`);
  image_el.setAttributeNS(null, 'href', data_url);

  const svg_el2 = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
  svg_el2.setAttributeNS(null, 'x', '0');
  svg_el2.setAttributeNS(null, 'y', '0');
  svg_el2.setAttributeNS(null, 'width', `${width}`);
  svg_el2.setAttributeNS(null, 'height', `${height}`);
  svg_el2.setAttributeNS(null, 'viewBox', `0 0 ${width} ${height}`);
  svg_el2.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
  svg_el2.appendChild(image_el);

  const svg_str3 = new XMLSerializer().serializeToString(svg_el2);

  const svg_file = new File([svg_str3], svg_file_name, {
    type: 'image/svg+xml',
  });

  const data_url2 = /** @type {string} */ await new Promise((resolve, reject) => {
    const blob = new Blob([svg_str3], { type: 'image/svg+xml' });
    const file_reader = new FileReader();
    file_reader.addEventListener('error', reject);
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(blob);
  });

  const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
    const img_el = new Image();
    img_el.addEventListener('load', () => resolve(img_el));
    img_el.addEventListener('error', reject);
    img_el.src = data_url2;
  });

  const canvas_el = document.createElement('canvas');
  canvas_el.width = img_el.width;
  canvas_el.height = img_el.height;
  canvas_el
    .getContext('2d')
    .drawImage(img_el, 0, 0, img_el.width, img_el.height, 0, 0, canvas_el.width, canvas_el.height);

  const png_file = await new Promise((resolve, reject) => {
    try {
      canvas_el.toBlob((blob) => {
        if (blob == null) {
          return reject(new Error('SVG 파일을 PNG 로 변환할 수 없습니다.'));
        }
        resolve(new File([blob], png_file_name, { type: 'image/png' }));
      }, 'image/png');
    } catch (error) {
      reject(error);
    }
  });

  return { svg_file, png_file };
};

export const convertSVGStrToEl = (svg_str) => {
  const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
  const parser_error_el = svg_doc.querySelector('parsererror');
  if (parser_error_el != null) {
    const error = new Error(parser_error_el.innerText);
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = 'SVG 파일을 해석할 수 없습니다.';
    throw error;
  }
  const svg_el = svg_doc.querySelector('svg');
  if (svg_el == null) {
    const error = new Error('Cannot find a SVG element.');
    // @todo 메세지 i18n 처리
    error.__mp_alert_message = 'SVG 객체를 찾을 수 없습니다.';
    throw error;
  }

  if (equals2(svg_el.children.length)(0)) {
    return null;
  }

  if (equals2(svg_el.children.length)(1)) {
    return svg_el.children.item(0);
  }

  return go(
    rangeL(svg_el.children.length),
    map(
      (i) =>
        new Promise((resolve, reject) =>
          requestAnimationFrame(() => {
            try {
              resolve(svg_el.children.item(i));
            } catch (error) {
              reject(error);
            }
          }),
        ),
    ),
    rejectL(isNil),
    (els) =>
      reduce(
        (g_el, el) =>
          new Promise((resolve, reject) =>
            requestAnimationFrame(() => {
              try {
                g_el.appendChild(el);
                resolve(g_el);
              } catch (error) {
                reject(error);
              }
            }),
          ),
        document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g'),
        els,
      ),
  );
};
