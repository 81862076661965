import { errorHandlingEventWrapper } from '../../../Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerMenuF } from './module/NewMakerMenuF.js';
import { setCvTextImageNewScale } from '../../../../Maker/F/text.js';
import { delay, go, identity } from 'fxjs/es';
import { $css, $delegate, $qs } from 'fxdom/es';
import { NewMakerFcanvasFuncF } from '../../../Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { NewMakerCompositePreviewF } from '../../../CompositePreview/F/Function/module/NewMakerCompositePreviewF.js';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { processBulkCvObjsAdd } from '../../../Util/F/Function/processBulkCvObjsAdd.js';

function changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(func) {
  return async function (e) {
    const active = G.mp.maker.active();
    if (!active) return;
    if (active._data.cv_type === 'cv_text') await active._changing;
    func(e);
    return active;
  };
}

let is_clicked = false;
function clicked_menu(func) {
  return async function (e) {
    if ($css('opacity', e.currentTarget) != 1) return;
    if (is_clicked) return;
    is_clicked = true;
    await func(e);
    is_clicked = false;
  };
}

export const event = function (el_maker_frame) {
  const custom_detail_style_a = $qs('html.custom-detail-style-a');
  return go(
    el_maker_frame,
    $delegate(
      'click',
      '.maker_menu .done',
      errorHandlingEventWrapper(async function () {
        const product = getProductColorInMaker();
        if (product.product_faces2?.value[0]?.designs?.length) {
          const result = await NewMakerCompositePreviewF.openCompositePreview(product);
          if (result?.frame_done === false) {
            $.don_loader_end();
            return;
          }
          if (result?.frame_done === true) {
            $.don_loader_start();
            await delay(1000)();
            $.don_loader_end();
          }
        }
        if ($qs('.don_frame[frame_name="maker.design_collection"]')) return;
        return $.frame.close();
      }),
    ),
    $delegate(
      'click',
      '.maker_menu .more_btn',
      errorHandlingEventWrapper(clicked_menu(() => NewMakerMenuF.show())),
    ),
    $delegate('click', '.maker_menu .reset', errorHandlingEventWrapper(clicked_menu(G.mp.maker.state.reset))),
    $delegate('click', '.maker_menu .undo', errorHandlingEventWrapper(clicked_menu(G.mp.maker.state.undo))),
    $delegate('click', '.maker_menu .redo', errorHandlingEventWrapper(clicked_menu(G.mp.maker.state.redo))),
    $delegate(
      'click',
      '.maker_menu .trash',
      errorHandlingEventWrapper(clicked_menu(() => NewMakerFcanvasFuncF.removeActiveCvObj())),
    ),
    $delegate('click', '.maker_menu .forward', errorHandlingEventWrapper(clicked_menu(G.mp.maker.forward))),
    $delegate('click', '.maker_menu .backward', errorHandlingEventWrapper(clicked_menu(G.mp.maker.backward))),
    $delegate(
      'click',
      '.maker_menu .group',
      errorHandlingEventWrapper(async (e) => {
        await processBulkCvObjsAdd(async () => {
          await clicked_menu(G.mp.maker.grouping)(e);
        });
      }),
    ),
    $delegate(
      'click',
      '.maker_menu .ungroup',
      errorHandlingEventWrapper(async (e) => {
        await processBulkCvObjsAdd(async () => {
          await clicked_menu(G.mp.maker.ungrouping)(e);
        });
      }),
    ),
    $delegate('click', '.maker_menu .lock', errorHandlingEventWrapper(clicked_menu(G.mp.maker.locking))),
    $delegate(
      'click',
      '.maker_menu .unlock',
      errorHandlingEventWrapper(
        clicked_menu(function () {
          if (!$qs('#maker_frame.has_lock_obj') || $qs('.don_frame.unlock_editor.is_show')) return;
          $.frame.open({
            frame_name: 'maker.unlock_editor',
          });
        }),
      ),
    ),
    custom_detail_style_a
      ? identity
      : $delegate(
          'click',
          '.maker_menu .flip_h',
          errorHandlingEventWrapper(
            clicked_menu(
              __(changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(G.mp.maker.flip_x), (cv_obj) =>
                G.mp.maker.cv_objects_deep_each(cv_obj, function (cv_obj) {
                  if (cv_obj._data.cv_type === 'cv_text_image') setCvTextImageNewScale(cv_obj, true);
                }),
              ),
            ),
          ),
        ),
    custom_detail_style_a
      ? identity
      : $delegate(
          'click',
          '.maker_menu .flip_v',
          errorHandlingEventWrapper(
            clicked_menu(
              __(changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(G.mp.maker.flip_y), (cv_obj) =>
                G.mp.maker.cv_objects_deep_each(cv_obj, function (cv_obj) {
                  if (cv_obj._data.cv_type === 'cv_text_image') setCvTextImageNewScale(cv_obj, true);
                }),
              ),
            ),
          ),
        ),
    $delegate(
      'click',
      '.maker_menu .center_h',
      errorHandlingEventWrapper(
        clicked_menu(changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(G.mp.maker.center_h)),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .center_v',
      errorHandlingEventWrapper(
        clicked_menu(changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(G.mp.maker.center_v)),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_left',
      errorHandlingEventWrapper(
        clicked_menu(
          changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
            return $qs('#maker_frame.group') ? G.mp.maker.group_left(e) : G.mp.maker.left(e);
          }),
        ),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_center_h',
      errorHandlingEventWrapper(
        clicked_menu(
          changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
            return $qs('#maker_frame.group') ? G.mp.maker.group_center_h(e) : G.mp.maker.center_h(e);
          }),
        ),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_right',
      errorHandlingEventWrapper(
        clicked_menu(
          changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
            return $qs('#maker_frame.group') ? G.mp.maker.group_right(e) : G.mp.maker.right(e);
          }),
        ),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_top',
      errorHandlingEventWrapper(
        clicked_menu(
          changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
            return $qs('#maker_frame.group') ? G.mp.maker.group_top(e) : G.mp.maker.top(e);
          }),
        ),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_center_v',
      clicked_menu(
        changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
          return $qs('#maker_frame.group') ? G.mp.maker.group_center_v(e) : G.mp.maker.center_v(e);
        }),
      ),
    ),
    $delegate(
      'click',
      '.maker_menu .group_bottom',
      errorHandlingEventWrapper(
        clicked_menu(
          changeCvTextToCvTextImageAndSelectAndOpenMarpplizer(function (e) {
            return $qs('#maker_frame.group') ? G.mp.maker.group_bottom(e) : G.mp.maker.bottom(e);
          }),
        ),
      ),
    ),
  );
};
