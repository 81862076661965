import axios from 'axios';
import {
  $addClass,
  $attr,
  $closest,
  $contains,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $on,
  $qs,
  $removeAttr,
  $removeClass,
  $setAttr,
  $setHTML,
  $val,
} from 'fxdom/es';
import { curry, find, go, tap } from 'fxjs/es';
import { ComponentsInputF } from '../../../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { ComponentsInputConstantS } from '../../../../../../Components/Input/S/Constant/module/ComponentsInputConstantS.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppKeywordAutoCompleteF } from '../../../AutoComplete/F/Function/module/MShopAppKeywordAutoCompleteF.js';
import { MShopAppKeywordAutoCompleteS } from '../../../AutoComplete/S/Function/module/MShopAppKeywordAutoCompleteS.js';
import { MShopAppKeywordHistoryF } from '../../../History/F/Function/module/MShopAppKeywordHistoryF.js';
import { MShopAppKeywordHistoryTmplS } from '../../../History/S/Tmpl/module/MShopAppKeywordHistoryTmplS.js';
import { MShopAppKeywordS } from '../../../S/Function/module/MShopAppKeywordS.js';
import { MShopAppKeywordDrawerTmplS } from '../../S/Tmpl/module/MShopAppKeywordDrawerTmplS.js';
import { MShopAppKeywordDrawerF } from './module/MShopAppKeywordDrawerF.js';

export const drawer = async ($drawer) => {
  if (!$drawer) return;

  const input_sel = '#search-drawer-input';
  const $keyword_container = $find('.keyword-container', $drawer);
  const $input_form = $find('.keyword-container__form', $keyword_container);

  await go(
    $qs(input_sel, $input_form),
    tap($on('debounce-input', onDebounceInput($drawer))),
    tap(fillInputWithValueInUri),
    ComponentsInputF.makeDebounceInput,
  );

  await go(
    $drawer,
    MShopAppKeywordAutoCompleteF.delegateAutoCompleteKeyDown(routeToSearchPage, input_sel),
    MShopAppKeywordAutoCompleteF.triggerEventOnClickAutoCompleteItem(routeToSearchPage),
    MShopAppKeywordHistoryF.delegateSearchHistory,
    $on('close', closeSearchDrawer),
    $delegate('submit', '.keyword-container__form', async (e) => {
      e.originalEvent.preventDefault();

      const q = go(e.currentTarget, $find('.keyword-container__input_el'), $val, (q) => (q || '').trim());
      if (!q) {
        return;
      }

      await axios.post(`/${T.lang}/@api/keywords/history?q=${q}`);
      closeSearchDrawer();
      location.href = MShopAppKeywordS.getSearchAllUri(q);
    }),
    $delegate('mouseover', '.auto-complete__item', ({ currentTarget }) => {
      const $list = $closest('.auto-complete__list', currentTarget);
      const $items = $findAll('.auto-complete__item', $list);
      const $cur_focused = MShopAppKeywordAutoCompleteF.findFocusedItem($items);
      if ($cur_focused) {
        $removeAttr('selected', $cur_focused);
      }
      $setAttr({ selected: true }, currentTarget);
    }),
  );

  if (!MShopUtilF.isApp()) {
    $on('keydown', (e) => {
      if (e.code !== ComponentsInputConstantS.KEY_CODE.ESC) {
        return;
      }

      if (MShopAppKeywordAutoCompleteF.isAutoCompleteListVisible($keyword_container)) {
        MShopAppKeywordAutoCompleteF.hideAutoCompleteList($keyword_container);
      } else {
        $drawer.dispatchEvent(closeDrawerCustomEvent);
      }
    })(document);

    // 외부 클릭 시 자동완성 삭제
    $on('click', function ({ target }) {
      if (!$contains(target, $keyword_container)) {
        MShopAppKeywordAutoCompleteF.flushAutoCompleteHtml($drawer);
      }
    })(document);
  }
};

export const closeDrawerCustomEvent = new CustomEvent('close', {});

export const closeSearchDrawer = () => {
  const $search_drawer = $qs('#search-drawer');
  const $drawer_inner = $find('.drawer__inner', $search_drawer);
  if ($drawer_inner && $hasClass('drawer__inner--slide-in', $drawer_inner)) {
    $addClass('drawer__inner--slide-out', $drawer_inner);
    const $search_anchor = $qs('.bottom-tab-bar__anchor--search');
    const prev_anchor = $attr('prev_anchor', $search_anchor);
    $removeClass('bottom-tab-bar__anchor--active', $search_anchor);
    $addClass('bottom-tab-bar__anchor--active', $qs(`.${prev_anchor}`));
    $removeAttr('prev_anchor', $search_anchor);
  }
};

export const openSearchDrawer = async () => {
  const $search_drawer = $qs('#search-drawer');
  const $drawer_inner = $find('.drawer__inner', $search_drawer);
  const $drawer_body = $find('.drawer__body', $search_drawer);
  const $creator_mask = $qs('.creator-mask');

  const [search_queries, popular_list] = await Promise.all([
    axios.get(`/${T.lang}/@api/keywords/history`).then(({ data }) => data?.queries ?? []),
    axios.get(`/${T.lang}/@api/keywords/popular`).then(({ data }) => data),
  ]);

  $setHTML(MShopAppKeywordHistoryTmplS.searchHistory(search_queries), $qs('.search-history', $search_drawer));
  $setHTML(
    MShopAppKeywordDrawerTmplS.searchPopular(popular_list),
    $qs('.search-popular__items', $search_drawer),
  );

  // `.bottom-tab-bar`의 실제 width 계산해서 drawer의 right margin 조정
  const $bottom_tab_bar = $qs('.bottom-tab-bar');
  const { offsetWidth: bottom_bar_actual_width } = $bottom_tab_bar;
  if (bottom_bar_actual_width) {
    $search_drawer.style.right = `${bottom_bar_actual_width}px`;
    $creator_mask.style.right = `${bottom_bar_actual_width}px`;
    $creator_mask.style.left = 'auto';
  }

  const $active_anchor = $qs('.bottom-tab-bar__anchor--active', $bottom_tab_bar);
  const $search_anchor = $qs('.bottom-tab-bar__anchor--search', $bottom_tab_bar);

  $removeClass('bottom-tab-bar__anchor--active', $active_anchor);
  $addClass('bottom-tab-bar__anchor--active', $search_anchor);

  const bottom_anchor_regex = /^bottom-tab-bar__anchor--(shopping|live|community|search|mypage)$/;
  const prev_anchor = find((klass) => bottom_anchor_regex.test(klass), $active_anchor.classList);

  $setAttr({ prev_anchor }, $search_anchor);
  $addClass('drawer__inner--slide-in', $drawer_inner);
  $addClass('creator-mask--is-open', $creator_mask);

  ComponentsInputF.focusToInput($qs('.keyword-container__input_el', $drawer_body));
};

const onDebounceInput = curry(async ($drawer, e) => {
  const input_value = ($val(e.target) || '').trim();
  if (input_value.length < 2) {
    MShopAppKeywordAutoCompleteF.setAutoCompleteHtml([], '', $drawer);
    return;
  }

  const source = axios.CancelToken.source();
  const auto_complete_list = await MShopAppKeywordAutoCompleteS.getAutoCompleteList(input_value, source);
  MShopAppKeywordAutoCompleteF.setAutoCompleteHtml(auto_complete_list, input_value, $drawer);
});

const routeToSearchPage = (input_value) => {
  const value = (input_value || '').trim();
  if (value.length < 2) {
    MShopAppKeywordAutoCompleteF.setAutoCompleteHtml([], '');
  }
  if (value === '') {
    return;
  }
  MShopAppKeywordDrawerF.closeSearchDrawer();
  location.href = MShopAppKeywordS.getSearchAllUri(value);
};

const fillInputWithValueInUri = ($input) => {
  const params = new URLSearchParams(location.search);
  if ($input.value !== params.get('q')) {
    $input.value = params.get('q');
  }
  return $input;
};
