import { each, extend, find, go, mapObject, pick, reject } from 'fxjs/es';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import {
  getActiveCvObj,
  getCvDesigns,
  getCvObj,
  getNotDesigns,
  makeCvObjsVisibleFalse,
  makeCvObjsVisibleTrue,
  makeFilterCvObj,
} from '../Fcanvas/cv_object.js';
import { makeCanvasTextured, makeShadowAndGeometryCanvas } from '../cylinder.js';
import {
  makeCanvasByImageWithSize,
  makeCanvasByUrl,
  makeCanvasCutByLocation,
  makeCloneCanvas,
  makeGradientCanvas,
  resizeImage,
  rgbToHex,
  sourceOverCanvas,
} from '../../../Canvas/S/util.js';
import { enCylinderizeSize, makeCircleWarpedCanvas } from '../../../Composite/Core/F/mapping.js';
import { isCompositeStyle, isCylinderStyle, isNeedLine } from '../categorize.js';
import {
  giveCanvasShadeAndLightByTarget,
  makeOptionShadeAndLight,
} from '../../../Composite/Core/S/composite_template_canvas.js';
import { imageMapper } from '../../../Composite/Core/F/image_mapping.js';
import { isShadeStyle, isShadeStyle2 } from './Shade/render.js';
import { getBaseProductColorInMaker } from '../getSth.js';
import { isMultiplyStyle } from './Multiply/fs.js';
import { $qs } from 'fxdom/es';
import { minusStrokeWidth } from '../Fcanvas/stroke.js';
import { NewMakerCvObjectCvImageF } from '../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { makeOnlyDesignFaceCanvasByPrintArea } from '../draw_product_faces.js';
import { NewMakerBaseProductsEmbroideryF } from '../../../NewMaker/BaseProducts/Embroidery/F/Function/module/NewMakerBaseProductsEmbroideryF.js';
import { NewMakerPropertyBpfConstantS } from '../../../NewMaker/Property/Bpf/S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { NewMakerPropertyBpfF } from '../../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { NewMakerPropertyBpfS } from '../../../NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';

function attr() {
  return {
    selectable: false,
    evented: false,
    globalCompositeOperation: 'source-over',
    _data: { cv_type: 'cv_preview', is_not_design: true },
  };
}

function extractDesignAreaFromFcanvas(fcanvas, { width, height, top, left }, is_undo_redo) {
  const not_designs = getNotDesigns(fcanvas._objects);
  makeCvObjsVisibleFalse(not_designs);
  fcanvas.renderAll();
  const src_c = makeCanvasCutByLocation(fcanvas.lowerCanvasEl, { width, height, left, top });
  if (is_undo_redo) makeCvObjsVisibleFalse(getCvDesigns(fcanvas._objects));
  makeCvObjsVisibleTrue(not_designs);
  fcanvas.renderAll();
  return src_c;
}
function muliplyFcanvas(fcanvas, { src_c, target_c }, { width, height, left, top }, is_undo_redo) {
  const not_designs = getNotDesigns(fcanvas._objects);
  const cv_bpcf = getCvObj(fcanvas._objects, 'cv_bpcf');
  const not_cv_bpcf = reject((cv_obj) => cv_obj === cv_bpcf)(fcanvas._objects);
  makeCvObjsVisibleFalse(not_cv_bpcf);
  fcanvas.renderAll();
  const canvas = target_c || makeCanvasCutByLocation(fcanvas.lowerCanvasEl, { width, height, left, top });
  if (is_undo_redo) makeCvObjsVisibleFalse(getCvDesigns(fcanvas._objects));
  makeCvObjsVisibleTrue(not_designs);
  fcanvas.renderAll();
  const ctx = canvas.getContext('2d');
  ctx.globalCompositeOperation = 'multiply';
  ctx.drawImage(src_c, 0, 0);
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(src_c, 0, 0);
  return canvas;
}

export async function makeCvPreview2(fcanvas) {
  function _getType(fcanvas) {
    return isCylinderStyle(fcanvas)
      ? 'cv_cylinder_area'
      : isCompositeStyle(fcanvas)
      ? 'cv_mockup'
      : isMultiplyStyle(fcanvas)
      ? fcanvas?.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR
        ? 'cv_bpcf'
        : 'cv_print_area'
      : 'cv_print_area';
  }
  const scale_factor = isShadeStyle2(fcanvas) ? 4 : NewMakerPropertyBpfF.additionalRender.getScale(fcanvas);

  const base_product_size_id = box.sel('maker->product_color->base_product_size_id');
  const product_face = go(
    box.sel('maker->product_color->product_faces2->value'),
    find((pf) => pf.bpf_id === fcanvas.bpf_id),
  );
  const sf = go(
    product_face.size_faces,
    find((sf) => sf.base_product_size_id === base_product_size_id),
  );

  const src = await makeOnlyDesignFaceCanvasByPrintArea({
    product_face,
    print_area: sf.print.px,
    width: sf.print.px.width * scale_factor,
  });
  const target_mockup_area = () => {
    const cv_type = _getType(fcanvas);
    return go(
      cv_type,
      (cv_type) => getCvObj(fcanvas._objects, cv_type),
      pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY']),
      (obj) => {
        obj.width *= obj.scaleX;
        obj.height *= obj.scaleY;
        return obj;
      },
      (obj) => (cv_type === 'cv_print_area' ? minusStrokeWidth(obj) : obj),
    );
  };
  let shade_material = null;
  return go(
    src,
    (src) => {
      if (isShadeStyle2(fcanvas))
        return NewMakerBaseProductsEmbroideryF.makeEmbroideryPreview({
          canvas: src,
          product_face,
          base_product_size_id,
        });
      return src;
    },
    async (src_c) => {
      const foil_effects = fcanvas.preview?.[NewMakerPropertyBpfConstantS.FOIL_EFFECTS];
      if (foil_effects) {
        const color_code = G.mp.maker.designs()[0]?._data?.dosu_color;
        if (!color_code) return src_c;
        const foil_effect = go(
          foil_effects,
          find((foil_effect) => foil_effect.hex.toUpperCase() === color_code.toUpperCase()),
        );
        if (!foil_effect) return src_c;
        const canvas = await NewMakerPropertyBpfF.foilEffects.makeCanvas(foil_effect, {
          width: src.width,
          height: src.height,
          px_per_1cm: sf.px_per_1cm * scale_factor,
        });
        if (canvas) {
          return makeCanvasTextured(src_c, null, canvas);
        }
      }
      return src_c;
    },
    async (src) => {
      const bpc_color_code2_render = NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(fcanvas);
      if (bpc_color_code2_render) {
        const color_code = NewMakerPropertyBpfF.bpcColorCode2Render.getColorCode2();
        const design_src = NewMakerPropertyBpfS.bpcColorCode2Render.makeColorRenderedCanvas(src, color_code);
        const shade_canvas = await NewMakerPropertyBpfS.bpcColorCode2Render.getShadeCanvas({
          canvas: design_src,
          color_code,
          base_product_size_id,
        });

        shade_material = NewMakerPropertyBpfF.bpcColorCode2Render.makeShadeMaterialData({
          color_code,
          // shade_data_url: shade_canvas.toDataURL(),
          design_data_url: design_src.toDataURL(),
        });
        return sourceOverCanvas(design_src, shade_canvas);
      }
      return src;
    },
    (c) => c.toDataURL(),
    (url) => {
      return new Promise(function (resolve) {
        fabric.Image.fromURL(
          url,
          (cv_obj) => {
            cv_obj._data.shade_material = shade_material;
            resolve(cv_obj);
          },
          extend(
            attr(),
            go(target_mockup_area(), (size) => {
              if (_getType(fcanvas) === 'cv_cylinder_area') {
                return enCylinderizeSize(size, fcanvas.preview.cylinder);
              } else return size;
            }),
          ),
        );
      });
    },
  );
}

export function makeCvPreview(fcanvas, is_undo_redo) {
  const { shade, cylinder, composite, multiply_image_url } = fcanvas.preview;

  const ratio = fcanvas.lowerCanvasEl.width / fcanvas.width;
  const { color_code } = getBaseProductColorInMaker();
  function _getType(fcanvas) {
    return isCylinderStyle(fcanvas)
      ? 'cv_cylinder_area'
      : isCompositeStyle(fcanvas)
      ? 'cv_mockup'
      : isMultiplyStyle(fcanvas)
      ? fcanvas?.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR
        ? 'cv_bpcf'
        : 'cv_print_area'
      : 'cv_print_area';
  }

  const target_mockup_area = () => {
    const cv_type = _getType(fcanvas);
    return go(
      cv_type,
      (cv_type) => getCvObj(fcanvas._objects, cv_type),
      pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY']),
      (obj) => {
        obj.width *= obj.scaleX;
        obj.height *= obj.scaleY;
        return obj;
      },
      (obj) => (cv_type === 'cv_print_area' ? minusStrokeWidth(obj) : obj),
    );
  };
  const is_multiply = isMultiplyStyle(fcanvas);
  const is_cylinder = isCylinderStyle(fcanvas);
  return go(
    target_mockup_area(),
    mapObject((v) => v * ratio),
    async ({ width, height, left, top }) => {
      const src_c = extractDesignAreaFromFcanvas(fcanvas, { width, height, top, left }, is_undo_redo);
      if (!is_cylinder && is_multiply) {
        return muliplyFcanvas(
          fcanvas,
          {
            src_c,
            target_c: multiply_image_url
              ? makeCanvasByImageWithSize(await makeCanvasByUrl(multiply_image_url), { width, height })
              : null,
          },
          { width, height, left, top },
          is_undo_redo,
        );
      }
      return src_c;
    },
    async (src_c) => {
      if (shade?.texture_url)
        return makeCanvasTextured(src_c, null, await makeCanvasByUrl(shade.texture_url));
      return src_c;
    },
    async (c) => {
      if (cylinder && cylinder.reflection_url) {
        return giveCanvasShadeAndLightByTarget(
          makeCloneCanvas(c),
          makeOptionShadeAndLight(
            await go(makeCanvasByUrl(cylinder.reflection_url), (reflection_c) => {
              return resizeImage(
                reflection_c,
                0,
                0,
                reflection_c.width,
                reflection_c.height,
                0,
                0,
                c.width,
                c.height,
              );
            }),
            null,
            true,
            '#ffffff',
          ),
          { br_strength: 0 },
        );
      }
      return c;
    },
    (canvas) => {
      if (cylinder) return makeCircleWarpedCanvas(canvas, cylinder);
      if (composite)
        return go(
          resizeImage(
            canvas,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            composite.area.width * 2,
            composite.area.height * 2,
          ),
          (c) => imageMapper(c, composite.m),
          (c) => resizeImage(c, 0, 0, c.width, c.height, 0, 0, canvas.width, canvas.height),
        );
      return canvas;
    },
    async (c) => {
      if (is_cylinder && is_multiply) {
        const ratio = fcanvas.lowerCanvasEl.width / G.mp.maker.CANVAS_WIDTH_ORIGIN;
        const { width, height, left, top } = go(
          target_mockup_area(),
          (size) => {
            if (_getType(fcanvas) === 'cv_cylinder_area') {
              return enCylinderizeSize(size, fcanvas.preview.cylinder);
            } else return size;
          },
          mapObject((v) => v * ratio),
        );

        return muliplyFcanvas(fcanvas, { src_c: c }, { width, height, left, top }, is_undo_redo);
      }
      if (shade?.deep_step > 0) {
        const shadow = await makeShadowAndGeometryCanvas(c, { shade, cylinder }, color_code);
        return sourceOverCanvas(c, shadow);
      }
      return c;
    },
    (c) => c.toDataURL(),
    (url) => {
      return new Promise(function (resolve) {
        fabric.Image.fromURL(
          url,
          resolve,
          extend(
            attr(),
            go(target_mockup_area(), (size) => {
              if (_getType(fcanvas) === 'cv_cylinder_area') {
                return enCylinderizeSize(size, fcanvas.preview.cylinder);
              } else return size;
            }),
          ),
        );
      });
    },
  );
}
export async function addCvPreview(fcanvas, is_undo_redo) {
  if (fcanvas?.preview?.on) return;
  const additional_render = NewMakerPropertyBpfF.additionalRender.getIt(fcanvas);
  if (
    !isCompositeStyle(fcanvas) &&
    !isCylinderStyle(fcanvas) &&
    !isShadeStyle(fcanvas) &&
    !isShadeStyle2(fcanvas) &&
    !isMultiplyStyle(fcanvas) &&
    !additional_render
  )
    return;
  const all_designs = getCvDesigns(fcanvas._objects);
  if (all_designs.length === 0) {
    removeCvPreview(fcanvas);
    return;
  }
  if (fcanvas.is_preview_pass) return;
  if (fcanvas.preview.shade) {
    G.mp.maker.cv_objects_deep_each(fcanvas._objects, (cv_obj) => {
      if (NewMakerCvObjectCvImageF.getRequestBgRemoval(cv_obj)) {
        NewMakerCvObjectCvImageF.setRequestBgRemoval(cv_obj, false);
      }
      if (cv_obj._data.cv_type === 'cv_text_image') {
        if ($qs('#maker_frame')?.dataset?.is_carved_phonecase_product === 'true') {
          extend(cv_obj._data, {
            press_color_code: '#ffffff',
            press_color_id: 34,
            press_color_name: '흰색',
            press_color_name_en: 'White',
            press_color_name_jp: 'ホワイト',
          });
        } else if (fcanvas.preview.shade.is_black_and_white_contrast) {
          extend(cv_obj._data, {
            press_color_code: '#ffffff',
            press_color_id: 34,
            press_color_name: '흰색',
            press_color_name_en: 'White',
            press_color_name_jp: 'ホワイト',
          });
        } else {
          extend(cv_obj._data, {
            press_color_code: '#000000',
            press_color_id: 54,
            press_color_name: '검정',
            press_color_name_en: 'black',
            press_color_name_jp: 'ブラック',
          });
        }
      }
    });
  }
  const is_shade_style2 = isShadeStyle2(fcanvas);
  return go(
    is_shade_style2 || additional_render
      ? makeCvPreview2(fcanvas, is_undo_redo)
      : makeCvPreview(fcanvas, is_undo_redo),
    (cv_preivew) => {
      cv_preivew._data.nscreened = true;
      makeCvObjsVisibleFalse(all_designs);
      getCvObj(fcanvas._objects, 'cv_print_area').set('visible', false);
      if (!fcanvas.is_editing_cylinder) {
        G.mp.maker.cv_mask1(fcanvas).set('visible', true);
        G.mp.maker.cv_mask2(fcanvas).set('visible', false);
      }
      fcanvas.add(cv_preivew).renderAll();
      fcanvas.preview.on = true;
      if (getActiveCvObj(fcanvas)) removeCvPreview(fcanvas);
      else if (!getCvDesigns(fcanvas._objects).length) removeCvPreview(fcanvas);
    },
  );
}

export function removeCvPreview(fcanvas, is_no_render, cv_print_area_false) {
  if (!fcanvas?.preview?.on) return;
  fcanvas.preview.on = false;
  each((cv_obj) => cv_obj.remove(), makeFilterCvObj(fcanvas._objects, 'cv_preview'));
  makeCvObjsVisibleTrue(fcanvas._objects);
  G.mp.maker.cv_mask1(fcanvas).set('visible', false);
  G.mp.maker.cv_mask2(fcanvas).set('visible', true);
  if (!cv_print_area_false && isNeedLine()) {
    const cv_safe_area = getCvObj(fcanvas._objects, 'cv_safe_area');
    if (cv_safe_area._element) {
      cv_safe_area.set('visible', true);
    } else {
      const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
      cv_print_area.set('visible', true);
      const cv_safety_area = getCvObj(fcanvas._objects, 'cv_safety_area');
      if (cv_safety_area) cv_safety_area.set('visible', true);
    }
  }
  if (!is_no_render) {
    fcanvas.renderAll();
  }
}

export const keys_for_cv_preview = [
  'is_cylinder',
  'is_shade',
  'up_side',
  'deep_step',
  'down_side',
  'hex',
  'lighten_hex',
  'darken_hex',
  'light_location',
  'lighten_url',
  'reflection_url',
  'texture_url',
];

export function makePreviewGradation(ratio, { width, height }, brightness = 150) {
  const x1 = go(ratio, (center_ratio) => width * center_ratio * 2 - width);
  const hex = rgbToHex(brightness, brightness, brightness);
  return makeGradientCanvas(
    { width, height },
    [
      {
        offset: 0,
        hex,
      },
      {
        offset: 0.5,
        hex: '#ffffff',
      },
      {
        offset: 1,
        hex,
      },
    ],
    { x1, y1: height / 2, x2: width, y2: height / 2 },
  );
}
