import { html } from 'fxjs/es';

export const defaultButton = ({ title, color, border } = {}) => {
  return html`
    <button
      class="mshop-core__button"
      data-style_border="${border || true}"
      ${color ? `style="background: ${color};"` : ''}
    >
      ${title}
    </button>
  `;
};
