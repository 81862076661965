import axios from 'axios';
import { $delegate, $find, $qs, $toggleClass } from 'fxdom/es';
import { each, go, indexBy, partition, tap } from 'fxjs/es';
import { pushLoginStack } from '../../../../../Creator/Login/F/fs.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';

function changeHeart$(target) {
  const { already_like } = target.dataset;
  $toggleClass('app-store-like__heart--on', $find('.app-store-like__heart', target));
  target.dataset.already_like = already_like != 'true';
}

export const updateLike = async (already_like, store_id) => {
  const {
    data: { ok, like_count },
  } = await axios.post(`/${T.lang}/@api/stores_like`, {
    already_like,
    store_id,
  });
  if (!ok) await Promise.reject(Error('Not ok'));

  // if (apply_event?.id) await $.alert('👏👏👏<br>' + T('mshop::이벤트 응모가 완료되었습니다!'));
  MShopUtilF.popToastMsg(
    already_like ? T('mshop::좋아요 목록에서 삭제되었습니다.') : T('mshop::좋아요 목록에 추가되었습니다.'),
    'confirm',
  );
  return like_count;
};

const my_heart_list = {};

const p = { promise: null };

export const getMyHeartList = async (is_ajax) => {
  if (p.promise) await p.promise.then(() => (p.promise = null));
  if (!is_ajax && my_heart_list.data) return my_heart_list.data;
  p.promise = go(
    axios.get(`/${T.lang}/@api/my_stores_like`, {
      params: {
        store_id: box.sel('store_id'),
        d: Date.now(),
      },
    }),
    ({ data }) => (my_heart_list.data = data),
  );
  return p.promise;
};

export const replaceHeartData = async (data) => {
  const [activated_likes, deactivated_likes] = await getMyHeartList();
  const index_activated_likes = indexBy((s) => s.store_id, activated_likes);
  const index_deactivated_likes = indexBy((s) => s.store_id, deactivated_likes);
  return go(
    data,
    tap(
      partition(({ stores_like_id }) => !!stores_like_id),
      ([already_likes, not_likes]) => {
        each((s) => {
          if (index_deactivated_likes[s.id]) s.stores_like_id = null;
        }, already_likes);
        each((s) => {
          const sl = index_activated_likes[s.id];
          if (sl) s.stores_like_id = sl.id;
        }, not_likes);
      },
    ),
  );
};

const setChangeHeart$ =
  (already) =>
  ({ store_id }) => {
    const like_el = $qs(`.app-store-like__btn[data-already_like="${already}"][data-store_id="${store_id}"]`);
    if (like_el) changeHeart$(like_el);
  };

const replaceHeart = async (is_persisted) => {
  if (window.box.sel('is_user->id') && window.box.sel('is_user->type') != 'TEMP' && is_persisted)
    return go(await getMyHeartList(true), ([activated_likes, deactivated_likes]) => {
      each(setChangeHeart$(false), activated_likes);
      each(setChangeHeart$(true), deactivated_likes);
    });
};

const appListener = async function (e) {
  try {
    const { is_focused } = JSON.parse(e.data);
    if (is_focused) await replaceHeart(true);
  } catch (err) {}
};

const resetLikeBackForward = () => {
  if (!MShopUtilF.isApp()) return UtilF.initBackForward(replaceHeart);
  window.addEventListener('message', appListener);
  document.addEventListener('message', appListener);
};

export const delegateUpdateLike = async (tab_el$) =>
  go(
    tab_el$,
    tap(resetLikeBackForward),
    $delegate('click', '.app-store-like__btn', async (e) => {
      if (!window.box.sel('is_user->id') || window.box.sel('is_user->type') == 'TEMP')
        return (
          (await $.confirm(T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?'))) &&
          (MShopUtilF.isApp()
            ? pushLoginStack()
            : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`))
        );
      const { already_like, store_id } = e.currentTarget.dataset;
      changeHeart$(e.currentTarget);
      try {
        await updateLike(already_like == 'true', store_id);
      } catch (error) {
        changeHeart$(e.currentTarget);
      }
    }),
  );
