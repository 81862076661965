import i18next from 'i18next';
import omp_i18n from './omp_i18n/index.js';

const omp_translator = i18next.createInstance();
omp_translator.init({
  debug:
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && process.env.I18N_DEBUG === 'true',
  resources: omp_i18n,
  nsSeparator: '::',
  keySeparator: ' ##### ',
  fallbackLng: 'kr',
});

export default omp_translator;
