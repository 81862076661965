import { $closest, $delegate, $qs } from 'fxdom/es';
import { extend, go, noop } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { isLoginCheck } from '../../../../../../modules/User/Login/F/fs.js';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS.js';

const crew_mall = '9ca9ebcf-b7cb-4f85-9a7f-b515d3812522';
const settings = '7f7f558e-52df-4c50-b79e-5a2ce35fdae5';
const nbox = '6f8562a3-edd4-4678-a266-1439abf07f87';

const channel_key = () => {
  if (UtilS.isNbox({ domain_name: box.sel('crew_domain_name') })) {
    return nbox;
  }
  if ($qs('html.creator-settings')) {
    return settings;
  } else {
    return crew_mall;
  }
};

function getChannelLocale(lang) {
  if (lang == 'kr') return 'ko';
  if (lang == 'en') return 'en';
  if (lang == 'jp') return 'ja';
}

const html_el = $qs('html');
const type = new UAParser().getDevice().type;

window.channelPluginSettings = extend(
  {
    pluginKey: channel_key(),
    locale: getChannelLocale(T.lang),
  },
  isLoginCheck()
    ? {
        userId: box.sel('is_user -> id'),
        profile: {
          name: box.sel('is_user -> name'),
          email: box.sel('is_user -> email'),
        },
      }
    : undefined,
);

/* 앱이거나, 웹에서 마이페이지, 스튜디오 아니면 채널톡 안함 */

if (
  UtilS.isNboxApp() ||
  MShopUtilF.isApp() ||
  (!$qs('html.creator.creator-settings') && !$qs('html.creator.mypage'))
) {
  window.loadChannelIO = function () {
    const w = window;
    w.ChannelIO = function () {};
  };
} else {
  window.loadChannelIO = function (need_delay) {
    const w = window;
    if (w.ChannelIO) {
      return;
    }
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l2() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    function l() {
      need_delay ? setTimeout(l2, 3000) : l2();
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  };
}

G.change_window(isSessionCheckNow);

$.on(window, 'resize', function () {
  if (type == new UAParser().getDevice().type) return;
  $.hide(html_el);
  window.location.reload();
});

$.add_class(html_el, G.is_mobile_size() ? 'is_mobile_size' : 'is_pc_size');

window.loadChannelIO();
if (MShopUtilF.isApp()) {
  $.on($('body'), 'click', '.live_chat', function () {
    return MShopUtilF.postMessage({ openChat: true });
  });
} else if (UtilS.isNboxApp()) {
  // TODO nbox 는 채널톡 걷어내서 1:1문의 누르면 email로 띄운다.
  $.on($('body'), 'click', '.live_chat', function () {
    return window.open('mailto:help@marpple.com');
  });
} else {
  ChannelIO('onBoot', function () {
    $.on($('body'), 'click', '.live_chat', function () {
      ChannelIO('showMessenger');
    });
  });
}

$.frame.defn_frame({
  frame_name: 'mp.fake.chat',
  page_name: 'mp.fake.chat',
  height: 0,
  // prev_frame_show: true,
  closing: function (_, pass) {
    G.mp && G.mp.headroom && setTimeout(G.mp.headroom.play, 500);
    pass || ChannelIO('hide');
  },
});

$.frame.defn_page({
  page_name: 'mp.fake.chat',
  tabs: [{ tab_name: 'mp.fake.chat', title: '', template: noop }],
});

go(
  html_el,
  $.on('click', '#creator_layer_pop .one_day_close', function () {
    go($.post('/@api/creator/layer_popup_cookie', {}), function () {
      go($('#creator_layer_pop'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '.main_layer .close', function (e) {
    const creator_layer = $closest('.creator_layer', e.currentTarget);
    go(creator_layer, $.fade_out, $.hide);
  }),
  $.on('click', '#creator_settings_layer_pop .one_day_close', function () {
    go($.post('/@api/creator/settings_layer_popup_cookie', {}), function () {
      go($('#creator_settings_layer_pop'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop2 .one_day_close', function () {
    go($.post('/@api/creator/layer_popup2_cookie', {}), function () {
      go($('#creator_layer_pop2'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop3 .one_day_close', function () {
    go($.post('/@api/creator/layer_popup3_cookie', {}), function () {
      go($('#creator_layer_pop3'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop4 .one_day_close', function () {
    go($.post('/@api/creator/layer_popup4_cookie', {}), function () {
      go($('#creator_layer_pop4'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop5 .one_day_close', function () {
    go($.post('/@api/nbox/layer_popup5_cookie', {}), function () {
      go($('#creator_layer_pop5'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop6 .one_day_close', function () {
    go($.post('/@api/creator/layer_popup6_cookie', {}), function () {
      go($('#creator_layer_pop6'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_pop7 .one_day_close', function () {
    go($.post('/@api/creator/layer_popup7_cookie', {}), function () {
      go($('#creator_layer_pop7'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#creator_layer_popup_notice .one_day_close', function () {
    go($.post('/@api/creator/layer_popup_notice_cookie', {}), function () {
      go($('#creator_layer_popup_notice'), $.fade_out, $.hide);
    });
  }),
  $.on('click', '#nbox_layer_pop1 .one_day_close', function (e) {
    go($.post('/@api/nbox/layer_popup1_cookie', {}), function () {
      const creator_layer = $closest('.creator_layer', e.currentTarget);
      go(creator_layer, $.fade_out, $.hide);
    });
  }),
);

const from_main_reg = window.ENV.is_prod
  ? window.ENV.is_stg
    ? /marpple\.cc/
    : /marpple\.shop/
  : /(:9077|:9078|:9079)/;
const noBack = () => {
  if (box.sel('store_url'))
    window.location.href = box.sel('store_url') + (box.sel('is_studio') ? '/settings/styles' : '');
  else window.location.href = `/${T.lang}`;
};
go(
  document.body,
  $delegate('click', '.mshop-history-back', (e) => {
    e.originalEvent.preventDefault();
    window.history.back();
    setTimeout(() => $.don_loader_start(), 300);
    setTimeout(noBack, 1000);
  }),
);
