import { imageUploadAlert } from '../../../../Maker/F/imageUploadAlert.js';
import { SVGEditorMobileImageUploadF } from '../../../../SVGEditor/MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { SVGEditorUploadImageF } from '../../../../SVGEditor/UploadImage/F/Function/module/SVGEditorUploadImageF.js';

export const uploadFile = async (file_input_el) => {
  if ($.valid_files(file_input_el)) {
    await imageUploadAlert(file_input_el.getAttribute('accept').split(','));
    return;
  }
  try {
    if (file_input_el.files[0]?.type === 'image/svg+xml') {
      const { svg_file, png_file } = await SVGEditorMobileImageUploadF.processSVGFile(file_input_el.files[0]);
      const { image } = await SVGEditorUploadImageF.uploadSvgFilePngFileToImages({
        svg_file,
        png_file,
      });
      return image;
    } else {
      await $.add_img_size_valid(file_input_el);
      const { result, image, msg } = await $.upload(file_input_el, {
        url: '/@fileUpload/maker/add_img',
        data: {},
      });
      if (!result) throw new Error(msg);
      if (msg) throw new Error(msg);
      return image;
    }
  } catch (err) {
    if (err == 'add_img_size_valid') return;
    if (err.message) $.alert(err.message);
    if (err?.response?.data?.message) $.alert(err.response.data.message);
  }
};
