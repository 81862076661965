export const SEARCH_TABS = Object.freeze({
  ALL: 0,
  SELLERS: 1,
  PRODUCTS: 2,
  CONTENTS: 3,
});

export const SEARCH_QUERY_LIMIT = 10;

export const SEARCH_AUTO_COMPLETE_LIMIT = 10;

/**
 * @deprecated Use `ComponentsInputConstantS.KEY_CODE`
 */
export const SPECIAL_KEY_CODE = Object.freeze({
  BACKSPACE: 8,
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
});

/**
 * 검색 결과 "전체"에서 보여지는 limit
 */
export const ALL_SEARCH_RESULT_LIMIT = Object.freeze({
  SELLER: {
    PC: 5,
    MOBILE: 5,
  },
  PRODUCT: {
    PC: 5,
    MOBILE: 4,
  },
  CONTENTS: {
    PC: 3,
    MOBILE: 1,
  },
});

export const searchHeaderMenus = [
  {
    getName: () => T('mshop::Menu::All'),
    getLink: (q) => `/@/keywords/all${q?.length ? `?q=${q}` : ''}`,
  },
  {
    getName: () => T('mshop::Seller::셀러'),
    getLink: (q) => `/@/keywords/sellers?q=${q}`,
  },
  {
    getName: () => T('mshop::Menu::Product'),
    getLink: (q) => `/@/keywords/products?q=${q}`,
  },
  {
    getName: () => T('mshop::Menu::Contents'),
    getLink: (q) => `/@/keywords/contents?q=${q}`,
  },
];
