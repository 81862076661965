import { MPS_ADDRESS } from '../../../Creator/Address/S/Constant/address.js';

export default {
  common: {
    common_01: '오류가 발생했습니다.',
    all: '전체',
  },
  crew: {
    authorization: {
      signup_00: '회원가입',
      signup_01: '이메일',
      signup_02: '이름',
      signup_03: '사번',
      signup_04: '이용 약관 동의',
      signup_05: '개인 정보 수집 및 이용 동의',
      signup_06: '개인 정보 제3자 제공 동의',
      signup_07: '만 14세 이상 확인',
      signup_08: '[선택] 이메일 마케팅 수신 동의',
      signup_09: '보기',
      signup_10: '로그인',
      signup_11: '이용약관에 동의해 주세요.',
      signup_12: '개인정보수집 이용에 동의해 주세요.',
      signup_13: '개인정보 제3자 제공에 동의해 주세요.',
      signup_14: '만 14세 이상 가입이 가능합니다.',
      signup_15: '기존 사용자가 존재합니다. 확인바랍니다.',
      signup_17: '인증이 완료되지 않았습니다.',
      signup_18: '소속된 법인은 NBOX 서비스 대상이 아닙니다.',
      signup_18_mobile: '소속된 법인은<br/>NBOX 서비스 대상이 아닙니다.',
    },
  },
  customer_orders: {
    pick_up: '픽업',
    possible_pick_up: '픽업 가능',
  },
  layout: {
    footer1: '(주)마플코퍼레이션',
    footer2: '고객센터',
    footer3: '신고센터',
    footer4: '셀러 신청',
    footer5: '기업 셀러 신청',
    footer6: '개인정보처리방침',
    footer7: '이용약관',
    footer8:
      '셀러 상품의 경우 (주)마플코퍼레이션은 통신판매중개자로서 통신판매의 당사자가 아니며 상품 거래 및 거래정보에 대하여 책임을 지지 않습니다.',
    footer9: '© 2023 MARPPLE CORP.',
    footer: {
      company_name: '(주)마플코퍼레이션',
      ceo: '대표',
      ceo_name: '박혜윤',
      customer_center: '고객센터',
      customer_center_info: '(평일 10:00 ~ 18:00)',
      company_registration_info: '사업자등록번호',
      company_registration_number: '105-88-13322',
      address: '주소',
      address_info: `${MPS_ADDRESS}`,
      email: '이메일',
      report_center: '신고센터',
      apply_seller: '셀러신청',
      terms_and_conditions: '이용약관',
      privacy_policy: '개인정보처리방침',
      instagram: '인스타그램',
      youtube: '유튜브',
      twitter: '트위터',
      blog: '블로그',
      marpple_corp: '마플코퍼레이션',
      nbox: {
        address: '경기도 성남시 분당구 판교로 256번길 7',
        phone: '전화',
        phone_number: '{{tel}}(평일 10:00 ~ 18:00)',
        company_registration_number: '220-87-17483호',
        ceo_name: '강대현·김정욱',
        footer1: '㈜넥슨코리아',
        footer9: '© NEXON Korea Corporation All Rights Reserved.',
      },
    },
    drawer: {
      menu: {
        review: '리뷰',
        product: '공식 상품',
        home: '홈',
      },
    },
  },
  my_page: {
    my_page_01: '<b>{{name}}</b>님, 반가워요 👋',
    my_account_01: '프로필 이미지',
    my_account_02: '10MB 이내의 이미지 파일을 업로드 해주세요.<br/>(이미지 업로드 형식 : JPG, JPEG, PNG)',
    my_account_03: '파일이 없습니다.',
    my_account_04: '로그인이 필요합니다.',
    my_account_05: '파일이 저장되지 않았습니다.',
    my_account_06: '삭제',
    like_product_01: '좋아요',
    like_product_02: '좋아요한 상품이 없습니다.',
  },
  order: {
    '배송지 변경': '배송지 변경',
  },
  checkout: {
    receipt: {
      receipt_01: '주문이 완료되었습니다.',
      receipt_02: '특별함을 담아 제작해서 배송해드려요.<br/>설레는 마음으로 기다려주세요!',
    },
  },
  product: {
    sort: {
      최신순: '최신순',
      '리뷰 많은 순': '리뷰 많은 순',
      인기순: '인기순',
      '낮은 가격 순': '낮은 가격 순',
      '높은 가격 순': '높은 가격 순',
    },
    review: {
      count: '상품 리뷰',
      score: '평점',
    },
  },
  plus_admin: {
    plus_admin_01: '테마 선택',
    plus_admin_02: '블랙 테마',
    plus_admin_03: '화이트 테마',
    banner_01: '서브 타이틀',
    banner_02: '다국어를 지원하며, 입력 시 좌측에서 미리보기가 가능합니다.',
    banner_03: '타이틀',
    plus_admin_04: '배경 색상 선택',
  },
  home: {
    plus_home: {
      plus_home_01: '인기 상품',
      plus_home_02: '가장 사랑받는 공식 상품',
      plus_home_03: '늦기 전에 데려가세요!',
      plus_home_04: '바로 지금! 만나야하는 굿즈',
      plus_home_05: '구매한 사람들의 후기가 궁금할 때',
      plus_home_06: '오늘의 리뷰',
      channel_on: 'NBOX 채널 on',
      nbox_hot_item: '지금 주목할 NBOX 핫템',
    },
  },
  community: {
    input: '입력',
    comment_place_holder: '댓글을 남겨보세요',
    community_01: '변경사항이 없습니다.',
  },
  cart: {
    title: '장바구니',
  },
  product_detail: {
    tab01: '스토리',
    tab02: '상품 정보',
    tab03: '구매/배송',
    tab04: '리뷰',
    review: {
      empty_title: '아직 등록된 리뷰가 없습니다.',
      empty_text: '첫번째 리뷰의 주인공이 되어보세요!',
    },
    nbox: {
      pick_title: '넥슨 픽업',
      pick_desc1: '무료',
      pick_desc2: '넥슨 픽업 가능',
    },
    purchase_delivery: {
      pd01: '지금 주문하시면 <span style="color: {{color}};">{{start}}</span>부터 <span style="color: {{color}};">{{end}}</span> 사이에 출고 예정입니다.',
      pd02: '마플샵의 주문 제작 상품과 함께 주문하실 경우, 주문 제작 상품의 출고일에 맞춰 함께 배송됩니다.',
      pd03: '모든 마플샵 배송 상품은 <span style="color: {{color}};">주문 제작 방식</span>으로 제작되어 단순변심으로 인한 교환/환불이 불가합니다.',
    },
  },
  review: {
    comment_header: '상품에 대한 리뷰를 남겨주세요.',
  },
  limit_product: {
    limit_product_01: '한정판 상품',
  },
};
