import { html, strMap } from 'fxjs/es';
import { MShopUtilS } from '../../../../MShop/Util/S/Function/module/MShopUtilS.js';

const privacy = (tds, crew_id) => html`
  <div class="privacy-popup">
    <div class="privacy-popup__body">
      <table class="privacy-popup__table privacy-popup__table--marketing">
        <thead>
          <tr>
            <th>수집 방법</th>
            <th>수집 항목</th>
            <th>보유 기간</th>
            <th>수집 목적</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            ${strMap((td) => html` <td>${td}</td> `, tds)}
          </tr>
        </tbody>
      </table>
      <p class="privacy-popup__p">
        고객은 언제든지 이메일 마케팅 수신 동의에 거부할 수 있으며, 동의 거부시에도 서비스 이용에 제한은
        없습니다. 수신거부를 원할 시
        ${G.collabo_type == 'creator'
          ? `마플샵 고객센터 (${MShopUtilS.getCustomerServiceInfo(crew_id).tel}, ${
              MShopUtilS.getCustomerServiceInfo(crew_id).email
            })`
          : '마플 고객센터 (1566-9437, help@marpple.com)'}
        또는, 1:1문의로 요청할 수 있습니다.
      </p>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  </div>
`;

export const marketing = (crew_id) =>
  privacy(
    [
      '회원 가입 시',
      '이메일',
      '회원 탈퇴하거나 회원에 제명된 때 즉시 파기',
      html`<ul>
        <li>신상품 출시 안내, 이벤트 정보 전달 등 마케팅 목적 활용</li>
        <li>고객 분석, 설문조사 등</li>
      </ul> `,
    ],
    crew_id,
  );
