import { go, isArray, take, unique } from 'fxjs/es';
import { MShopAppKeywordConstantS } from '../Constant/module/MShopAppKeywordConstantS.js';

export const isLastHistoryMatchesNewSearchQuery = (search_query, search_history) => {
  if (!search_history.length) return false;
  const last_idx = search_history.length - 1;
  return search_history[last_idx] === search_query;
};

export const getRefinedSearchQueries = (search_query, search_history) => {
  const queries = isArray(search_query) ? search_query : [search_query];
  return go([...queries, ...search_history], unique, take(MShopAppKeywordConstantS.SEARCH_QUERY_LIMIT));
};

export const isTempUser = (user_id, type) => {
  return !user_id || type === 'TEMP';
};

export const isUserAdultCertificated = (login_user) => {
  return !!login_user?._?.users_adult?.id;
};

export const getSearchPostMessage = (uri, q) => {
  return {
    navigate: {
      sel: 'TabNavigation.SearchStack.SearchScreen',
      params: { uri, q },
    },
  };
};

export const getSearchAllUri = (query) => {
  const q = (query || '').trim();
  if (!q) return '';
  return `/${T.lang}/@/keywords/all?q=${q}`;
};

export const getSearchAllTabSellerFetchLimit = (is_mobile) => {
  return is_mobile
    ? MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.SELLER.MOBILE
    : MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.SELLER.PC;
};

export const getSearchAllTabProductFetchLimit = (is_mobile) => {
  return is_mobile
    ? MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.PRODUCT.MOBILE
    : MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.PRODUCT.PC;
};

export const getSearchAllTabContentsFetchLimit = (is_mobile) => {
  return is_mobile
    ? MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.CONTENTS.MOBILE
    : MShopAppKeywordConstantS.ALL_SEARCH_RESULT_LIMIT.CONTENTS.PC;
};
