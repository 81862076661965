import { go, pipe, sel, map } from 'fxjs/es';
import { convert10To1, convert1To10, makeRangeSliderEvent } from '../range_slider.js';
import {
  changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA,
  changeCvTextToCvTextImageA,
  full_cv_text_image,
  renderSpecialColor,
} from '../text.js';
import { makeMakerColorPickerColorName, makeSelectFontsHtml } from './tmpl.js';
import {
  $addClass,
  $appendTo,
  $closest,
  $el,
  $find,
  $offset,
  $qs,
  $remove,
  $removeClass,
  $setCss,
  $setText,
} from 'fxdom/es';
import { getFonts, rejectFonts, rejectPressColors } from '../util.js';
import { isBpMousePad } from '../categorize.js';
import { getCurrentFcanvas } from '../Fcanvas/cv_object.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuConstantS } from '../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerPropertyBpfF } from '../../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';

const mappingDosuColorsToFontColors = ({ dosu_color_options }) => {
  const ONLY_FLEX_FOR_DOSU_COLOR = false;
  return go(
    dosu_color_options,
    map((dosu_color) => {
      const { id, dev_name: code, name, name_en, name_jp, no } = dosu_color;

      return {
        id,
        code,
        name,
        name_en,
        name_jp,
        no,
        only_flex: ONLY_FLEX_FOR_DOSU_COLOR,
      };
    }),
  );
};

export function getFontColors() {
  const font_colors = (() => {
    const has_dosu_color_option = BpOptionF.biz.get.currentFace.hasDosuColorOptions();
    const mp_maker_el = $qs('#maker_frame');
    const is_embroidery_app = mp_maker_el?.dataset?.is_embroidery_app === 'true';
    let press_colors;
    if (has_dosu_color_option) {
      // 다를 때만 캐시 업데이트
      if (has_dosu_color_option === true) {
        // 도수 색상 옵션이 있으면 도수 색상 옵션의 컬러 팔레트로 press_colors 덮어쓰기
        press_colors = mappingDosuColorsToFontColors({
          dosu_color_options: BpOptionF.biz.get.currentFace.dosuColorOptions(),
        });
      } else {
        // 반대의 경우에는 원본으로 덮어쓰기
        press_colors = $.get('/@api/press_colors');
      }
    } else if (is_embroidery_app) {
      press_colors = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.press_colors;
    } else {
      // 캐시 상태가 변경되지 않는 경우 box 데이터 재사용
      press_colors = box.sel('press_colors') ?? $.get('/@api/press_colors');
    }
    return press_colors;
  })();

  return _p.go(
    font_colors,
    function (press_colors) {
      if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(getCurrentFcanvas())) {
        return [];
      }
      box.set('press_colors', press_colors);
      return press_colors;
    },
    function (press_colors) {
      if (isBpMousePad()) {
        return rejectPressColors([40])(press_colors);
      }
      return press_colors;
    },
    function (press_colors) {
      if (G.mp.maker.can_flex()) return press_colors;
      return _p.reject(press_colors, function (color) {
        return color.only_flex;
      });
    },
  );
}

export async function click_color_item(font_color, cv_text) {
  if (!G.mp.maker.can_flex() && font_color.only_flex)
    return $.alert(T('The font colors are not available for this product.'));

  renderCvTextColor(font_color, cv_text);

  // const [color_code] = font_color.code.split('**'); // 별색 대응
  // await handleDosuColorPicker.text({ cv_obj: cv_text, color_code });
}

export const makeCvTextImageColorItemEvent = async function (e) {
  const active = G.mp.maker.active();
  if (active._changing) return;
  const ct = e.currentTarget;
  const font_color = box.sel(ct);
  $.selectable2(ct);
  if (font_color.only_flex && active._data.cv_type === 'cv_text_image_pattern')
    return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
  await full_cv_text_image(function (cv_text) {
    if (!G.mp.maker.can_flex() && font_color.only_flex)
      return $.alert(T('The font colors are not available for this product.'));
    renderCvTextColor(font_color, cv_text);
    go(
      ct,
      $closest('.maker-color-picker'),
      $find('.maker-color-picker__color-name'),
      (color_name_el) =>
        color_name_el &&
        $setText(makeMakerColorPickerColorName(cv_text._data['press_color_name' + _en]), color_name_el),
    );
  });
};

export const changeCvTextColorData = (press_color, cv_text) => {
  const color_hex_code = press_color.code.split('**')[0]; // 별색 대응
  cv_text._data.press_color_id = press_color.id;
  cv_text._data.press_color_code = color_hex_code;
  cv_text._data.press_color_name = press_color.name;
  cv_text._data.press_color_name_en = press_color.name_en;
  cv_text._data.press_color_name_jp = press_color.name_jp;
  cv_text._data.only_flex = press_color.only_flex;

  if (BpOptionF.biz.get.currentFace.hasDosuColorOptions()) {
    cv_text._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] = color_hex_code;
  }
};

export function renderCvTextColor(press_color, cv_text) {
  changeCvTextColorData(press_color, cv_text);
  return renderSpecialColor(cv_text);
}

export function getCvText(cv_text) {
  cv_text = cv_text || G.mp.maker.active();
  if (!cv_text) return;
  if (cv_text.fontFamily) return cv_text;
  const cv_type = cv_text._data.cv_type;
  if (cv_type === 'cv_text_image') return cv_text._data.text_info;
  if (cv_type === 'cv_text_image_pattern') return cv_text._data.cv_text_image_attrs._data.text_info;
}

export function getCvTextImage(cv_text_image) {
  if (!cv_text_image) return;
  if (cv_text_image._data.cv_type === 'cv_text_image') return cv_text_image;
  return sel('_data.cv_text_image_attrs', cv_text_image);
}

export function findFontFamily(cv_text) {
  const cv_text_attr = getCvText(cv_text);
  const font_family = _p.v(cv_text_attr, 'fontFamily');
  return _p.go(
    getFonts(),
    _p.flatten,
    _find(function (f) {
      return f.original_name == font_family;
    }),
  );
}

export async function makeItProperCvTextImage(cv_text) {
  if (findFontFamily(cv_text)) return cv_text;
  $.alert(T('maker::The font is no longer supported. Please select the other fonts.'));
  const fonts = await getFonts();
  const font = _p.flatten(fonts)[0].original_name;
  return full_cv_text_image(function (cv_text) {
    return _p.go(
      _p.mr(
        new FontFaceObserver(font).load(undefined, 30000).catch(function (e) {
          console.error('font loading failed ' + font);
          console.error(e);
        }),
        font?.has_bold &&
          new FontFaceObserver(font, { weight: 'bold' }).load(undefined, 30000).catch(function (e) {
            console.error('font bold ----- loading failed ' + font);
            console.error(e);
          }),
      ),
      function () {
        cv_text.set('fontFamily', font);
      },
    );
  });
}
export function hasNotBold(cv_text) {
  const font_family = _p.v(cv_text, 'fontFamily');
  return _p.go(
    getFonts(),
    _p.find(function (f) {
      return font_family == f.original_name;
    }),
    function (font) {
      if (!font) return false;
      return !font.has_bold;
    },
  );
}

export async function openSelectFonts() {
  const select_font_el = $1('#marpplizer .select_font');
  $addClass('clicked', select_font_el);
  const event = pipe(
    $.on3('click', '.i_item', eventChangeFontFamily),
    $.on3('click', '.position_absolute_block', function (e) {
      $removeClass('clicked', select_font_el);
      $remove(e.delegateTarget);
    }),
  );

  const fonts = await go(getFonts(), rejectFonts);

  const outer_left = $qs('.mp_maker').getBoundingClientRect().left;
  const outer_top = $qs('.mp_maker').getBoundingClientRect().top + window.scrollY;
  go(
    G.mp.maker.active(),
    findFontFamily,
    (selected_font) => makeSelectFontsHtml(fonts, selected_font),
    $el,
    $appendTo($qs('#maker_frame')),
    $setCss({
      width: $.width($1('#marpplizer .select_font_and_style')),
      top: $offset(select_font_el).top - outer_top + $.height(select_font_el),
      left: $offset(select_font_el).left - outer_left,
    }),
    event,
    $find('.iscroll_wrapper'),
    (iscroll_wrapper) => {
      iscroll_wrapper.myScroll = new IScroll(iscroll_wrapper, {
        mouseWheel: true,
        scrollbars: true,
        fadeScrollbars: true,
      });
      iscroll_wrapper.myScroll.scrollToElement($find('.i_item.selected', iscroll_wrapper), 0, true, true);
    },
  );
}

export async function eventChangeFontFamily(e) {
  $.don_loader_start();
  await go(e.currentTarget, $.selectable3, $.attr('font_family'), changeFontFamily);
  $.don_loader_end();
}

export function changeFontFamily(font) {
  return _p.go(
    full_cv_text_image(function (cv_text) {
      return _p.go(
        _p.mr(
          new FontFaceObserver(font).load(undefined, 30000).catch(function (e) {
            console.error('font loading failed ' + font);
            console.error(e);
          }),
          font?.has_bold &&
            new FontFaceObserver(font, { weight: 'bold' }).load(undefined, 30000).catch(function (e) {
              console.error('font bold ----- loading failed ' + font);
              console.error(e);
            }),
        ),
        function () {
          change_location_by_textAlign(cv_text, function () {
            cv_text.set('fontFamily', font);
          });
        },
      );
    }),
    getCvText,
    function (cv_text) {
      renderSelectFontAndStyle(cv_text);
    },
  );
}

const debounced1 = _p.debounce(
  () => G.mp.maker.active() && changeCvTextImageToCvTextCalculatingPadAndSelectNoEventA(G.mp.maker.active()),
  300,
  true,
);
const debounced2 = _p.debounce(() => {
  if (!G.mp.maker.active()) return;
  G.mp.maker.cleared_pass = true;
  _p.go(changeCvTextToCvTextImageA(G.mp.maker.active()), async function (cv_text_image) {
    G.mp.maker.cleared_pass = false;
    if ($qs('#maker_frame').dataset.is_carved_phonecase_product === 'true') {
      const fcanvas = getCurrentFcanvas();
      G.mp.maker.align_print_area_center(cv_text_image, fcanvas);
      fcanvas.renderAll();
    }
    await G.mp.maker.modified();
    cv_text_image.canvas.setActiveObject2(cv_text_image);
  });
}, 300);

function change_location_by_textAlign(cv_text, cb) {
  if (cv_text.textAlign === 'left') cv_text.textAlign_point = cv_text.calcCoords(true).tl;
  else if (cv_text.textAlign === 'center') cv_text.textAlign_point = cv_text.getCenterPoint();
  else if (cv_text.textAlign === 'right') cv_text.textAlign_point = cv_text.calcCoords(true).tr;
  cb();
  if (cv_text.textAlign === 'left') cv_text.setPositionByOrigin(cv_text.textAlign_point, 'left', 'top');
  else if (cv_text.textAlign === 'center')
    cv_text.setPositionByOrigin(cv_text.textAlign_point, 'center', 'center');
  else if (cv_text.textAlign === 'right')
    cv_text.setPositionByOrigin(cv_text.textAlign_point, 'right', 'top');
}

export function makeLineHeightAttr(cv_object) {
  return {
    id: 'lineHeight_function',
    title: T('Line'),
    value: cv_object && cv_object.lineHeight,
    min: 0.1,
    only_number: !UtilF.isLegacyMobile(),
    number_input_disabled: UtilF.isLegacyMobile(),
    max: 7,
    step: 0.1,
    up_down_control: !UtilF.isLegacyMobile(),
    to_fixed: 1,
    render: function (e) {
      const active = G.mp.maker.active();
      if (active?._data?.cv_type === 'cv_text_image_pattern')
        return go(
          full_cv_text_image(function (cv_text) {
            render_ranges_canvas(cv_text, parseFloat($.val(e.currentTarget)), 'lineHeight');
          }),
          function () {
            return getCvText()?.lineHeight;
          },
        );
      return _p.go(
        debounced1(),
        (cv_text) => render_ranges_canvas(cv_text, parseFloat($.val(e.currentTarget)), 'lineHeight'),
        function () {
          debounced2();
          return getCvText()?.lineHeight;
        },
      );
    },
  };
}

export function makeAngleAttr(cv_object) {
  return {
    id: 'angle_function',
    title: T('Rotate'),
    value: cv_object && cv_object.angle,
    min: 0,
    only_number: true,
    max: 359,
    step: 1,
    loop: true,
    render: function (e) {
      render_ranges_canvas(G.mp.maker.active(), $.val(e.currentTarget), 'angle');
      return G.mp.maker.active()?.angle;
    },
  };
}

function render_ranges_canvas(cv_text, val, key) {
  if (!cv_text) return;
  const point = new fabric.Point(cv_text.getCenterPoint().x, cv_text.getCenterPoint().y);

  if (key === 'charSpacing') {
    change_location_by_textAlign(cv_text, function () {
      cv_text.set(key, val);
    });
  } else {
    if (key === 'angle') {
      cv_text.set(key, val).setPositionByOrigin(point, 'center', 'center');
    } else {
      change_location_by_textAlign(cv_text, function () {
        cv_text.set(key, val).setPositionByOrigin(point, 'center', 'center');
      });
    }
  }
  G.mp.maker.editing_canvas().renderAll();
}

export function makeCharSpacingFunctionAttr(cv_object) {
  const cv_text = getCvText(cv_object);
  return {
    id: 'charSpacing_function',
    title: T('Spacing'),
    value: cv_text && convert10To1(cv_text.charSpacing),
    min: -20,
    only_number: !UtilF.isLegacyMobile(),
    number_input_disabled: UtilF.isLegacyMobile(),
    max: 200,
    step: 1,
    up_down_control: !UtilF.isLegacyMobile(),
    render: function (e) {
      const active = G.mp.maker.active();
      if (active?._data?.cv_type === 'cv_text_image_pattern')
        return go(
          full_cv_text_image(function (cv_text) {
            render_ranges_canvas(cv_text, convert1To10($.val(e.currentTarget)), 'charSpacing');
          }),
          function () {
            return convert10To1(getCvText()?.charSpacing);
          },
        );
      return _p.go(
        debounced1(),
        (cv_text) => render_ranges_canvas(cv_text, convert1To10($.val(e.currentTarget)), 'charSpacing'),
        function () {
          debounced2();
          return convert10To1(getCvText()?.charSpacing);
        },
      );
    },
  };
}

export const makeCharSpacingFunctionEvent = function (el) {
  return makeRangeSliderEvent(el, makeCharSpacingFunctionAttr());
};

export const makeLineHeightFunctionEvent = function (el) {
  return makeRangeSliderEvent(el, makeLineHeightAttr());
};

export const makeAngleFunctionEvent = function (el) {
  return makeRangeSliderEvent(el, makeAngleAttr());
};

export async function makeSelectFontAndStyleEvent(e) {
  if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
  if (G.mp.maker.active()._changing) return;
  const ct = e.currentTarget;

  if (
    $.attr(ct, 'key') === 'textAlign' &&
    $.attr(ct, 'val') === go(ct, $.closest('.style'), $.attr('text_align'))
  )
    return;
  await _p.go(
    full_cv_text_image(function (cv_text) {
      render_text_char_canvas(ct, cv_text);
    }),
    getCvText,
    function (cv_text) {
      renderSelectFontAndStyle(cv_text);
    },
  );
}

function renderSelectFontAndStyle(cv_text) {
  _p.go(findFontFamily(cv_text), function (font) {
    $.attr($1('.select_font_and_style .select_font .select > img'), {
      src: font.url_2x,
    });
    _p.go(
      $1('.select_font_and_style .style'),
      $.attr({
        font_weight: cv_text.fontWeight,
        font_style: cv_text.fontStyle,
        underline: cv_text.underline,
        linethrough: cv_text.linethrough,
        text_align: cv_text.textAlign,
        has_bold: font.has_bold,
      }),
    );
  });
}

function render_text_char_canvas(ct, cv_text) {
  const key = $.attr(ct, 'key');
  const selected = $.attr(ct, 'val');
  const el_control_style = $.closest(ct, '.style');
  const canvas = G.mp.maker.editing_canvas();

  if ($.has_class(ct, 'radio')) {
    cv_text.set(key, selected);
  } else {
    change_location_by_textAlign(cv_text, function () {
      cv_text.set(
        key,
        selected == $.attr(el_control_style, _p.underscored(key)) ? $.attr(ct, '_default') : selected,
      );
    });
  }

  /*????*/
  cv_text.width += 0.01;
  cv_text.height += 0.01;
  cv_text.setCoords();
  canvas.renderAll();
  cv_text.width -= 0.01;
  cv_text.height -= 0.01;
  cv_text.setCoords();
  canvas.renderAll();
}
