import { entries, filter, go, object, sel, strMap, sumBy, html } from 'fxjs/es';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';

const h1_p1 = (design_collection_content, margin_bottom, _mobile) => {
  const target_name = design_collection_content.type == 'h1' ? 'title' : 'description';
  return html`
    <div
      class="design_collection_content"
      design_collection_content_id="${design_collection_content.id}"
      _sel="./_->design_collection_contents->(#${design_collection_content.id})"
      type="${design_collection_content.type}"
      style="margin-bottom: ${margin_bottom}px; text-align : ${design_collection_content[
        'text_align' + _mobile
      ]};"
    >
      <div class="body val" style="color:${design_collection_content[target_name + '_color']};">
        ${design_collection_content[target_name + _en] || ''}
      </div>
    </div>
  `;
};

function makeThumbnailUrl({ design_collection_content_item, width = 484 }) {
  const is_line = G.collabo_type === 'line';
  return UtilImageS.getResizedUrl(
    go(
      {
        url: design_collection_content_item.url,
        bg: is_line ? 'fafafa' : 'f6f6f6',
        width,
        quality: 90,
        format: 'jpeg',
        r: is_line
          ? design_collection_content_item._.product_color._.base_product.thumbnail_ratio * 100
          : design_collection_content_item.is_thumbnail_size_full
          ? undefined
          : (!design_collection_content_item._.product_color._.base_product.is_composite_publish ||
              new Date(design_collection_content_item.created_at) < new Date('2021-10-06')) &&
            design_collection_content_item._.product_color._.base_product.thumbnail_ratio * 100,
      },
      entries,
      filter(([_, v]) => v),
      object,
    ),
  );
}

const left_img_right_img = (design_collection_content, margin_bottom, _mobile) => pug`
  .design_collection_content[design_collection_content_id="${
    design_collection_content.id
  }" _sel="./_->design_collection_contents->(#${design_collection_content.id})" type="${
  design_collection_content.type
}" style="margin-bottom: ${margin_bottom}px;"]
    .body
      .wrapper
        .thumbnail_url
          .body
            img[src="${design_collection_content.thumbnail_url || ''}"]
        .title_description_background_color[style="background-color: ${
          design_collection_content.background_color
        };"]
          .wrapper[is_title_none="${design_collection_content['title' + _en] == ''}" is_description_none="${
  design_collection_content['description' + _en] == ''
}"]
            .wrapper2
              .title
                .body[style="color: ${design_collection_content.title_color};"] ${
  design_collection_content['title' + _en]
}
              .description
                .body[style="color:${design_collection_content.description_color};"] ${
  design_collection_content['description' + _en]
}
  `;

const full_img_banner = (design_collection_content, margin_bottom, _mobile) => pug`
  .design_collection_content[design_collection_content_id="${
    design_collection_content.id
  }" _sel="./_->design_collection_contents->(#${design_collection_content.id})" type="${
  design_collection_content.type
}" style="margin-bottom: ${margin_bottom}px;"]
    .body
      .wrapper
        .thumbnail_url
          .body
            img[src="${design_collection_content['thumbnail_url' + _mobile] || ''}"]
  `;

const design_collection_content_by_type = (is_mobile) =>
  function (design_collection_content) {
    const _mobile = is_mobile ? '_mobile' : '';
    const margin_bottom = is_mobile
      ? design_collection_content.margin_bottom
      : (design_collection_content.margin_bottom * 6) / 7;
    if (design_collection_content.type == 'h1' || design_collection_content.type == 'p1') {
      return h1_p1(design_collection_content, margin_bottom, _mobile);
    }
    if (design_collection_content.type == 'left_img' || design_collection_content.type == 'right_img') {
      return left_img_right_img(design_collection_content, margin_bottom, _mobile);
    }
    if (design_collection_content.type == 'full_img' || design_collection_content.type == 'banner') {
      return full_img_banner(design_collection_content, margin_bottom, _mobile);
    }
    if (
      design_collection_content.type == 'items' ||
      design_collection_content.type == 'items_6' ||
      design_collection_content.type == 'items_5'
    ) {
      return items(design_collection_content, margin_bottom, _mobile);
    }
  };

export const design_collection_page_mobile_j = (design_collection) => legacyHtml`
  <div
    class="design_collection"
    _sel="design_collection"
    no_background_url="${design_collection.option_mobile == 0}"
    option_pc="${design_collection.option_pc}"
    option_mobile="${design_collection.option_mobile}"
  >
    <div class="header">
      <div class="background_url">
        <div class="body">
          <img src="${design_collection.background_url_mobile || ''}" />
        </div>
      </div>
      <div
        class="wrapper"
        is_title_none="${design_collection['title' + _en] == ''}"
        is_description_none="${design_collection['description' + _en] == ''}"
      >
        <div class="title">
          <div class="body" style="color:${design_collection.title_color_mobile};">
            ${design_collection['title' + _en]}
          </div>
        </div>
        <div class="description">
          <div class="body" style="color:${design_collection.description_color_mobile};">
            ${design_collection['description' + _en]}
          </div>
        </div>
      </div>
    </div>
    <div class="design_collection_contents">
      ${go(
        design_collection,
        sel('_.design_collection_contents'),
        strMap(design_collection_content_by_type(true)),
      )}
    </div>
  </div>
`;

export const design_collection_page_pc_j = (design_collection) => html`
  <div
    class="design_collection"
    _sel="design_collection"
    no_background_url="${design_collection.option_pc == 0}"
    option_pc="${design_collection.option_pc}"
    option_mobile="${design_collection.option_mobile}"
  >
    <div class="header">
      <div class="title">
        <div class="body" style="color:${design_collection.title_color_pc};">
          ${design_collection['title' + _en]}
        </div>
      </div>
      <div class="description">
        <div class="body" style="color:${design_collection.description_color_pc};">
          ${design_collection['description' + _en]}
        </div>
      </div>
      <div class="background_url">
        <div
          class="body"
          style="height: ${design_collection.background_height ||
          0}px; background: url(${design_collection.background_url_pc ||
          ''}) no-repeat center center; background-size: cover;"
        ></div>
      </div>
    </div>
    <div class="design_collection_contents">
      ${go(
        design_collection,
        sel('_.design_collection_contents'),
        sumBy(design_collection_content_by_type(false)),
      )}
    </div>
  </div>
`;

export const makeDciBasicHtml = (is_lazy) => (design_collection_content_item) =>
  legacyHtml`
  <div class="design_collection_content_item" _sel="./_->design_collection_content_items->(#${_p.v(
    design_collection_content_item,
    'id',
  )})"">
    <div class="wrapper">
      <a href="/product/detail?bp_id=${
        design_collection_content_item._.product_color.base_product_id
      }&pc_id=${design_collection_content_item._.product_color.id}" class="img_wrapper">
        <img ${
          is_lazy
            ? `class="don_lazy" data-src="${makeThumbnailUrl({ design_collection_content_item })}"`
            : `src="${makeThumbnailUrl({ design_collection_content_item })}"`
        }>
      </a>
      <div class="info">
        <div class="name">${makeProductColorName(design_collection_content_item._.product_color)}</div>
        <div class="mp_currency price">${PriceS.pricify(
          design_collection_content_item._.product_color['price' + _en],
        )}</div>
      </div>
    </div>
  </div> 
`;

const items = (design_collection_content, margin_bottom, _mobile) => {
  const length =
    design_collection_content.type == 'items_6' ? 6 : design_collection_content.type == 'items_5' ? 5 : 4;
  return pug`
    .design_collection_content[design_collection_content_id="${
      design_collection_content.id
    }" _sel="./_->design_collection_contents->(#${design_collection_content.id})" type="${
    design_collection_content.type
  }" style="margin-bottom: ${margin_bottom}px;"]
      .body
        .wrapper
          .design_collection_content_items[lines="${Math.ceil(
            design_collection_content._.design_collection_content_items.length / length,
          )}"]
            ${go(
              design_collection_content,
              sel('_.design_collection_content_items'),
              strMap(makeDciBasicHtml(false)),
            )}
  `;
};
_p.row_length_br = function (val) {
  if (!val) return 1;
  const arr = val.match(/<br>|<br \/>/g);
  return arr ? arr.length + 1 : 1;
};

const brToN = (str) => {
  if (!str) return '';
  return str?.replace(/<br>|<br \/>/g, '\n') || '';
};

const update_design_collection_content_by_type_pc = function (design_collection_content, i) {
  const margin_ratio = 6 / 7;
  if (design_collection_content.type == 'h1') {
    const target_name = design_collection_content.type == 'h1' ? 'title' : 'description';
    const mobile_margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    const margin_bottom = mobile_margin_bottom * margin_ratio;
    return pug`
      .design_collection_content.option_wrapper_default[type="${
        design_collection_content.type
      }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px; text-align : ${design_collection_content.text_align};"]
        .body.textarea_wrapper.edit_btn_wrapper[key="${target_name + '_color'}"]
          textarea.body.val[key="${target_name + _en}" hei="40" style="color:${
      design_collection_content[target_name + '_color']
    }; height:${_p.row_length_br(design_collection_content[target_name + _en]) * 40}px;" placeholder="${
      _en != '_en' ? (_en != '_jp' ? '제목을 입력해 주세요.' : 'Japanese title please~') : 'Title please~'
    }"] ${brToN(design_collection_content[target_name + _en])}
          button.edit_btn 수정
        .option
          .body
            button.text_align[data-text_align="left" data-device_type=""  data-checked = ${
              design_collection_content.text_align === 'left'
            }] PC L
            button.text_align[data-text_align="center" data-device_type=""  data-checked = ${
              design_collection_content.text_align === 'center'
            }] PC C
            button.text_align[data-text_align="left" data-device_type="_mobile" data-checked = ${
              design_collection_content.text_align_mobile === 'left'
            }] MO L
            button.text_align[data-text_align="center" data-device_type="_mobile" style="" data-checked = ${
              design_collection_content.text_align_mobile === 'center'
            }] MO C
            input[type="number" key="margin_bottom" value="${mobile_margin_bottom}"]
            button.sorting.up 위
            button.sorting.down 아래
            button.duplicate 복제
            button.remove 삭제
      .plus_content
        button.plus_button[_idx="${i + 1}"]    
    `;
  }

  if (design_collection_content.type == 'p1') {
    const target_name = design_collection_content.type == 'h1' ? 'title' : 'description';
    const mobile_margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    const margin_bottom = mobile_margin_bottom * margin_ratio;
    return pug`
      .design_collection_content.option_wrapper_default[type="${
        design_collection_content.type
      }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px; text-align : ${design_collection_content.text_align};"]
        .body.textarea_wrapper.edit_btn_wrapper[key="${target_name + '_color'}"]
          textarea.body.val[key="${target_name + _en}" hei="26" style="color:${
      design_collection_content[target_name + '_color']
    }; height:${_p.row_length_br(design_collection_content[target_name + _en]) * 26}px;" placeholder="${
      _en != '_en' ? (_en != '_jp' ? '제목을 입력해 주세요.' : 'Japanese title please~') : 'Title please~'
    }"] ${brToN(design_collection_content[target_name + _en])}
          button.edit_btn 수정
        .option
          .body
            button.text_align[data-text_align="left" data-device_type=""  data-checked = ${
              design_collection_content.text_align === 'left'
            }] PC L
            button.text_align[data-text_align="center" data-device_type=""  data-checked = ${
              design_collection_content.text_align === 'center'
            }] PC C
            button.text_align[data-text_align="left" data-device_type="_mobile" data-checked = ${
              design_collection_content.text_align_mobile === 'left'
            }] MO L
            button.text_align[data-text_align="center" data-device_type="_mobile" style="" data-checked = ${
              design_collection_content.text_align_mobile === 'center'
            }] MO C
            input[type="number" key="margin_bottom" value="${mobile_margin_bottom}"]
            button.sorting.up 위
            button.sorting.down 아래
            button.duplicate 복제
            button.remove 삭제
      .plus_content
        button.plus_button[_idx="${i + 1}"]    
    `;
  }

  if (design_collection_content.type == 'left_img' || design_collection_content.type == 'right_img') {
    const mobile_margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    const margin_bottom = mobile_margin_bottom * margin_ratio;
    return pug`
      .design_collection_content.option_wrapper_default[type="${
        design_collection_content.type
      }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
        .body
          .wrapper
            ${update_design_collection_content_tumbnail(design_collection_content)}
            ${update_design_collection_content_title_discription(design_collection_content)}
        .option
          .body
            input[type="number" key="margin_bottom" value="${mobile_margin_bottom}"]
            button.sorting.up 위
            button.sorting.down 아래
            button.duplicate 복제
            button.remove 삭제
      .plus_content
        button.plus_button[_idx="{{i+1}}"]
    `;
  }

  if (
    design_collection_content.type == 'full_img' ||
    design_collection_content.type == 'banner' ||
    design_collection_content.type == 'banner_margin'
  ) {
    const mobile_margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    const margin_bottom = mobile_margin_bottom * margin_ratio;
    return pug`
        .design_collection_content.option_wrapper2.option_wrapper_default[type="${
          design_collection_content.type
        }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
          .body
            .wrapper
              ${update_design_collection_content_tumbnail(design_collection_content)}
          .option
            .body
              input[type="number" key="margin_bottom" value="${mobile_margin_bottom}"]
              button.sorting.up 위
              button.sorting.down 아래
              button.duplicate 복제
              button.remove 삭제
        .plus_content
          button.plus_button[_idx="{{i+1}}"]
      `;
  }

  if (
    design_collection_content.type == 'items' ||
    design_collection_content.type == 'items_6' ||
    design_collection_content.type == 'items_5'
  ) {
    const item_length =
      design_collection_content.type == 'items_6' ? 6 : design_collection_content.type == 'items_5' ? 5 : 4;
    const mobile_margin_bottom =
      design_collection_content.margin_bottom == null
        ? design_collection_content.type == 'items_6'
          ? 55
          : design_collection_content.type == 'items_5'
          ? 40
          : 30
        : design_collection_content.margin_bottom;
    const margin_bottom = mobile_margin_bottom * margin_ratio;
    return ((design_collection_content, i) => pug`
        .design_collection_content.option_wrapper2.option_wrapper_default[type="${
          design_collection_content.type
        }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
          .body
            .wrapper
              .design_collection_content_items[lines="${Math.ceil(
                design_collection_content._.design_collection_content_items.length / item_length,
              )}"]
                ${_p.sum(
                  design_collection_content._.design_collection_content_items,
                  (design_collection_content_item) => pug`
                  .design_collection_content_item.option_wrapper_default[_sel="./_->design_collection_content_items->(#${_p.v(
                    design_collection_content_item,
                    'id',
                  )})"]
                    .body
                      .img
                        img[src="${makeThumbnailUrl({ design_collection_content_item })}"]
                        .zoom_text
                      .info[lang="${_en == '_en' ? 'en' : _en == '_jp' ? 'jp' : 'kr'}"]
                        .name ${name_maker(design_collection_content_item._.product_color)}
                        .mp_currency.price ${PriceS.pricify(
                          design_collection_content_item._.product_color['price' + _en],
                        )}
                    .option
                      .body
                        button.duplicate 복제
                        input.file_dcci_upload[type="file" style="display:none;"]
                        button.file_dcci_add.only_marpple 업로드
                        button.revise 수정
                        button.remove 삭제
                `,
                )}
                .option.col.add_product_wrapper
                  .add_product
                    button.plus_product_color.plus_button
                    .text ${T('Add Product')}
          .option
            .body
              input[type="number" key="margin_bottom" value="${mobile_margin_bottom}"]
              button.sorting.up 위
              button.sorting.down 아래
              button.duplicate 복제
              button.remove 삭제
        .plus_content
          button.plus_button[_idx="{{i+1}}"]
      `)(design_collection_content, i);
  }
};

const design_collection_thumbnail_maker_line = _p.t(
  'design_collection',
  '\
    .design_collection_thumbnail[design_collection_id="{{design_collection.id}}" _sel="design_collection"]\
      .header 최근 업데이트 된 테마 썸네일 만들기\
      .thumbnail_url_wrapper.only_line\
        .thumbnail.main_thumbnail_url\
          .header PC 썸네일 (540px X 540px)\
          .thumbnail_url.edit_btn_wrapper[has_item={{!!design_collection.main_thumbnail_url}} key="main_thumbnail_url"]\
            .body\
              img.val[src="{{design_collection.main_thumbnail_url || ""}}"]\
            button.edit_btn 수정\
        .thumbnail.main_thumbnail_url_mobile\
          .header 모바일 썸네일 (630px X 630px)\
          .thumbnail_url.edit_btn_wrapper[has_item={{!!design_collection.main_thumbnail_url_mobile}} key="main_thumbnail_url_mobile"]\
            .body\
              img.val[src="{{design_collection.main_thumbnail_url_mobile || ""}}"]\
            button.edit_btn 수정\
      .thumbnail_title\
        label 국문 제목 -\
        input.text_input[type="text" key={{"thumbnail_title"}} placeholder="ex) 펭귄북스" value="{{design_collection["thumbnail_title"] || ""}}"]\
      .thumbnail_description\
        label 국문 짧은 설명 -\
        input.text_input[type="text" key={{"thumbnail_description"}} placeholder="귀여운 펭귄굿증와 함께해요." value="{{design_collection["thumbnail_description"] || ""}}"]\
      .thumbnail_title\
        label 영문 제목 -\
        input.text_input[type="text" key={{"thumbnail_title_en"}} placeholder="English-title" value="{{design_collection["thumbnail_title_en"] || ""}}"]\
      .thumbnail_description\
        label 영문 짧은 설명 -\
        input.text_input[type="text" key={{"thumbnail_description_en"}} placeholder="English-description" value="{{design_collection["thumbnail_description_en"] || ""}}"]\
      .thumbnail_title\
        label 일문 제목 -\
        input.text_input[type="text" key={{"thumbnail_title_jp"}} placeholder="Japenese-title" value="{{design_collection["thumbnail_title_jp"] || ""}}"]\
      .thumbnail_description\
        label 일문 짧은 설명 -\
        input.text_input[type="text" key={{"thumbnail_description_jp"}} placeholder="Japenese-description" value="{{design_collection["thumbnail_description_jp"] || ""}}"]\
  ',
);

const design_collection_thumbnail_maker_mp = _p.t(
  'design_collection',
  '\
    .design_collection_thumbnail[design_collection_id="{{design_collection.id}}" _sel="design_collection"]\
      .header 메인 썸네일 만들기\
      .thumbnail.only_marpple\
        .thumbnail_url.edit_btn_wrapper[has_item={{!!design_collection.thumbnail_url}} key="thumbnail_url"]\
          .body\
            img.val[src="{{G.to_350(design_collection.thumbnail_url)}}"]\
          button.edit_btn 수정\
      .short_name.none_for_line\
        label 검색어 이름 - \
        input.text_input[type="text" key={{"short_name"+_en}} placeholder="ex) 펭귄" value="{{design_collection["short_name" + _en] || ""}}"]\
      .thumbnail_title\
        label 제목 -\
        input.text_input[type="text" key={{"thumbnail_title"+_en}} placeholder="ex) 펭귄북스" value="{{design_collection["thumbnail_title" + _en] || ""}}"]\
      .thumbnail_description\
        label 짧은 설명 -\
        input.text_input[type="text" key={{"thumbnail_description"+_en}} placeholder="귀여운 펭귄굿증와 함께해요." value="{{design_collection["thumbnail_description" + _en] || ""}}"]\
  ',
);

const update_design_collection_content_tumbnail = function (dc) {
  return _p.t(
    'design_collection_content',
    '\
      .thumbnail_url.has_img_wrapper.edit_btn_wrapper[has_item="{{!!design_collection_content.thumbnail_url}}" temp_key="design_collection_content->thumbnail_url" key="thumbnail_url"]\
        .body.temp.tool_wrapper\
          img.val[src="{{design_collection_content.thumbnail_url || ""}}"]\
        button.edit_btn 수정\
    ',
  )(dc);
};

function makeProductColorName(product_color) {
  if (_collabo === '_line') {
    return legacyHtml`
      <div class="pc_name">${product_color['name' + _en]}</div>
      <div class="bp_name">${product_color._.base_product['name' + _collabo + _en]}</div>
    `;
  }
  return legacyHtml` <div class="pc_name">${product_color['name' + _en]}</div> `;
}

function name_maker(product_color) {
  if (_collabo === '_line') {
    return product_color['name' + _en] + ' ' + product_color._.base_product['name' + _collabo + _en];
  }
  return product_color['name' + _en];
}

const update_design_collection_content_title_discription = function (design_collection_content) {
  return html`
    <div class="title_description_background_color">
      <div class="background_color edit_btn_wrapper" key="background_color">
        <div
          class="body val"
          style="background-color: ${design_collection_content.background_color || '#e9439a'};"
        ></div>
        <button class="edit_btn">수정</button>
      </div>
      <div class="wrapper">
        <div class="title textarea_wrapper edit_btn_wrapper" key="title_color">
          <textarea
            key="title${G._en}"
            hei="41"
            class="body val"
            style="color:${design_collection_content.title_color}; height:${_p.row_length_br(
              design_collection_content['title' + _en],
            ) * 41}px;"
            placeholder="${_en != '_en'
              ? _en != '_jp'
                ? '제목을 입력해 주세요.'
                : 'Japanese title please~'
              : 'Title please~'}"
          >
${_p.to_n(design_collection_content['title' + _en])}</textarea
          >
          <button class="edit_btn">수정</button>
        </div>
        <div class="description textarea_wrapper edit_btn_wrapper" key="description_color">
          <textarea
            class="body val"
            key="description${G._en}"
            hei="24"
            style="color:${design_collection_content.description_color}; height:${_p.row_length_br(
              design_collection_content['description' + _en],
            ) * 24}px;"
            placeholder="${_en != '_en'
              ? _en != '_jp'
                ? '설명을 입력해 주세요.'
                : 'Japanese description please~'
              : 'Description please~'}"
          >
${_p.to_n(design_collection_content['description' + _en])}</textarea
          >
          <button class="edit_btn">수정</button>
        </div>
      </div>
    </div>
  `;
};

const design_collection_long_thumbnail_maker = (design_collection) => pug`
    .design_collection_long_thumbnail[design_collection_id="${design_collection.id}" _sel="design_collection"]
      .header 목록용 썸네일 만들기
      .long_thumbnail
        .long_thumbnail_url.edit_btn_wrapper[has_item=${!!design_collection.long_thumbnail_url} key="long_thumbnail_url"]
          .header pc 썸네일 (1180px X 400px)
          .body
            a[href="/${design_collection.type}/${design_collection.id}"]
              img.val[src="${design_collection.long_thumbnail_url || ''}"]
          button.edit_btn 수정
      .long_thumbnail_mobile
        .long_thumbnail_url.edit_btn_wrapper[has_item=${!!design_collection.long_thumbnail_url_mobile} key="long_thumbnail_url_mobile"]
          .header 모바일 썸네일 (640px × 640px)
          .body
            img.val[src="${design_collection.long_thumbnail_url_mobile || ''}"]
          button.edit_btn 수정
      .design_collection_content_thumbnail_items.option_wrapper2.option_wrapper_default
        .body
          .wrapper
            .design_collection_content_items
              ${_p.sum(
                design_collection._.design_collection_content_thumbnail_items,
                (design_collection_content_thumbnail_item) => pug`
              .a_wrapper.design_collection_content_item.thumbnail_item.option_wrapper_default[_sel="./_->design_collection_content_thumbnail_items->(#${_p.v(
                design_collection_content_thumbnail_item,
                'id',
              )})" dci_id="${design_collection_content_thumbnail_item.id}" href="/product/detail?bp_id=${
                  design_collection_content_thumbnail_item._.product_color.base_product_id
                }&pc_id=${design_collection_content_thumbnail_item._.product_color.id}"]
                .body
                  .img.img_wrapper
                    img[src="${makeThumbnailUrl({
                      design_collection_content_item: design_collection_content_thumbnail_item,
                    })}"]
                    .zoom_text
                  .info[lang="${_en == '_en' ? 'en' : _en == '_jp' ? '_jp' : 'kr'}"]
                    .name ${name_maker(design_collection_content_thumbnail_item._.product_color)}
                    .mp_currency.price ${PriceS.pricify(
                      design_collection_content_thumbnail_item._.product_color['price' + _en],
                    )}
                .option
                  .body
                    button.remove 삭제  
              `,
              )}
            .option.col.select_product_wrapper
              .select_product
                .text 메인용 4가지 상품 선택
  `;

const design_collection_content_by_type_mobile_update = function (design_collection_content, i) {
  if (design_collection_content.type == 'h1' || design_collection_content.type == 'p1') {
    const target_name = design_collection_content.type == 'h1' ? 'title' : 'description';
    let margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    margin_bottom = margin_bottom <= 0 ? 0 : margin_bottom;
    return pug`
        .design_collection_content[type="${
          design_collection_content.type
        }" index="${i}" design_collection_content_id="${
      design_collection_content.id
    }" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px; text-align : ${
      design_collection_content.text_align_mobile
    };"]
          .body[style="color:${design_collection_content[target_name + '_color']};"] ${
      design_collection_content[target_name + _en]
    }
      `;
  }

  if (design_collection_content.type == 'left_img' || design_collection_content.type == 'right_img') {
    const margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    return pug`
        .design_collection_content[type="${
          design_collection_content.type
        }" index="${i}" design_collection_content_id="${
      design_collection_content.id
    }" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
          .body
            .wrapper
              .thumbnail_url
                .body
                  img[src="${design_collection_content.thumbnail_url || ''}"]
              .title_description_background_color[style="background-color: ${
                design_collection_content.background_color
              };"]
                .wrapper[is_title_none="${
                  design_collection_content['title' + _en] == ''
                }" is_description_none="${design_collection_content['description' + _en] == ''}"]
                  .wrapper2
                    .title
                      .body[style="color: ${design_collection_content.title_color};"] ${
      design_collection_content['title' + _en]
    }
                    .description
                      .body[style="color:${design_collection_content.description_color};"] ${
      design_collection_content['description' + _en]
    }
      `;
  }

  if (
    design_collection_content.type == 'full_img' ||
    design_collection_content.type == 'banner' ||
    design_collection_content.type == 'banner_margin'
  ) {
    const margin_bottom =
      design_collection_content.margin_bottom == null ? 70 : design_collection_content.margin_bottom;
    return pug`
      .design_collection_content[type="${
        design_collection_content.type
      }" index="${i}" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
        .body
          .wrapper
            .thumbnail_url_mobile.has_img_wrapper.edit_btn_wrapper[has_item="${!!design_collection_content.thumbnail_url_mobile}" temp_key="design_collection_content->thumbnail_url_mobile" key="thumbnail_url_mobile"]
              .body.temp.tool_wrapper
                img.val[src="${design_collection_content.thumbnail_url_mobile || ''}"]
              button.edit_btn 수정
      `;
  }

  if (
    design_collection_content.type == 'items' ||
    design_collection_content.type == 'items_6' ||
    design_collection_content.type == 'items_5'
  ) {
    const item_length =
      design_collection_content.type == 'items_6' ? 6 : design_collection_content.type == 'items_5' ? 5 : 4;
    const margin_bottom =
      design_collection_content.margin_bottom == null ? 55 : design_collection_content.margin_bottom;
    return pug`
        .design_collection_content[type="${
          design_collection_content.type
        }" index="${i}" design_collection_content_id="${
      design_collection_content.id
    }" _sel="./_->design_collection_contents->(#${
      design_collection_content.id
    })" style="margin-bottom: ${margin_bottom}px;"]
          .body
            .wrapper
              .design_collection_content_items[lines="${Math.ceil(
                design_collection_content._.design_collection_content_items.length / item_length,
              )}"]
                ${go(
                  design_collection_content,
                  sel('_.design_collection_content_items'),
                  strMap(makeDciBasicHtml(false)),
                )}
      `;
  }
};

export const design_collection_mobile_j = (design_collection) => pug`
  .design_collection[_sel="design_collection" no_background_url="${
    design_collection.option_mobile == 0
  }" option_mobile="${design_collection.option_mobile}"]
    .header
      .background_url.edit_btn_wrapper[has_item="${!!design_collection.background_url_mobile}" key="background_url_mobile"]
        .body
          img.val[src="${design_collection.background_url_mobile || ''}"]
        button.edit_btn 수정
      .wrapper[is_title_none="${design_collection['title' + _en] == ''}" is_description_none="${
  design_collection['description' + _en] == ''
}"]
        .title.edit_btn_wrapper[key="title_color_mobile"]
          .body.val[style="color:${design_collection.title_color_mobile};"] ${
  design_collection['title' + _en]
}
          button.edit_btn 수정
        .description.edit_btn_wrapper[key="description_color_mobile"]
          .body.val[style="color:${design_collection.description_color_mobile};"] ${
  design_collection['description' + _en]
}
          button.edit_btn 수정
    .design_collection_contents
      .hidden_option
        button.show[key="option_mobile"] 배경 보이기
      ${_p.sum(
        _p.v(design_collection, '_.design_collection_contents'),
        design_collection_content_by_type_mobile_update,
      )}
`;

export const design_collection_pc_line_j = _p.t(
  'design_collection',
  '\
      .design_collection[_sel="design_collection" no_background_url="{{design_collection.option_pc == 0}}" option_pc="{{design_collection.option_pc}}" option_mobile="{{design_collection.option_mobile}}"]\
        .design_collection_contents\
          .plus_content\
            button.plus_button[_idx="0"]\
          {{_.sum(design_collection._.design_collection_contents, ',
  update_design_collection_content_by_type_pc,
  ')}}\
        {{_.go(design_collection, ',
  design_collection_thumbnail_maker_line,
  ')}}\
        {{_.go(design_collection, ',
  design_collection_long_thumbnail_maker,
  ')}}\
  ',
);

export const design_collection_pc_mp_j = _p.t(
  'design_collection',
  '\
      .design_collection[_sel="design_collection" no_background_url="{{design_collection.option_pc == 0}}" option_pc="{{design_collection.option_pc}}" option_mobile="{{design_collection.option_mobile}}"]\
        .header.design_collection_header\
          .title.edit_btn_wrapper.textarea_wrapper[has_item="{{!!design_collection["title"+_en]}}" key="{{"title_color_pc"}}"]\
            textarea.body.val[key="{{"title"+_en}}" hei="48" style="color:{{design_collection.title_color_pc}}; height:{{_.row_length_br(design_collection["title"+_en]) * 48}}px;" placeholder="{{_en != "_en" ? _en != "_jp" ? "제목을 입력해 주세요." : "Japanese title please~" : "Title please~"}}" ] {{_.to_n(design_collection["title"+_en])}}\
            button.edit_btn 수정\
          .description.edit_btn_wrapper.textarea_wrapper[has_item="{{!!design_collection["description"+_en]}}" key="{{"description_color_pc"}}"]\
            textarea.body.val[key="{{"description"+_en}}" hei="24" style="color:{{design_collection.description_color_pc}}; height:{{_.row_length_br(design_collection["description"+_en]) * 24}}px;" placeholder="{{_en != "_en" ? _en != "_jp" ?  "설명을 입력해 주세요." : "Japanese description please~" : "Description please~"}}"] {{_.to_n(design_collection["description"+_en])}}\
            button.edit_btn 수정\
        .background_url.edit_btn_wrapper[has_item="{{!!design_collection.background_url_pc}}" key="background_url_pc"]\
          .body.val[style="background: url({{design_collection.background_url_pc || ""}}) no-repeat center center; background-size: cover; height: {{design_collection.background_height || 450}}px"]\
          button.edit_btn 수정\
        .design_collection_contents\
          .hidden_option\
            button.show[key="option_pc"] 배경 보이기\
          .plus_content\
            button.plus_button[_idx="0"]\
          {{_.sum(design_collection._.design_collection_contents, ',
  update_design_collection_content_by_type_pc,
  ')}}\
        {{_.go(design_collection, ',
  design_collection_thumbnail_maker_mp,
  ')}}\
  ',
);
