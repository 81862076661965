import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const needNessPrice = () => {
  try {
    // return MShopUtilConstantS.NBOX_CREW_ID == box.sel('crew_id') || MarketplaceS.isNessApp();
    return UtilS.isNessApp() || UtilS.isNboxApp();
  } catch (e) {
    return false;
  }
};
