import { go, html, ippL, range, sel, strMap } from 'fxjs/es';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { makePrevProductMatcherHtml } from './prev_product_matcher.js';
import { makeEnterMarppleShopHtml } from '../../Marpple/PopUp/F/enter_marpple_shop1.js';
import { NewMakerMenuTmplS } from '../../NewMaker/Menu/S/Tmpl/module/NewMakerMenuTmplS.js';
import { NewMakerIconMenuTmplS } from '../../NewMaker/IconMenu/S/Tmpl/module/NewMakerIconMenuTmplS.js';
import { NewMakerMobileBottomMenuTmplS } from '../../NewMaker/MobileBottomMenu/S/Tmpl/module/NewMakerMobileBottomMenuTmplS.js';
import { getIsBizProduct, makeProductColorInProductDetailHtmlMobile } from '../S/product.js';
import { OMPCoreAtomTmplS } from '../../OMP/Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { BpOptionUploadTypeS } from '../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';

const makeCanvasContainersTmpl = () => {
  return go(
    range(6),
    strMap(
      (idx) => legacyHtml`
        <div class="canvas_container" idx="${idx}">
          <div class="canvas_zoom_wrapper">
            <canvas></canvas>
            <div class="cross vertical"></div>
            <div class="cross horizontal"></div>
          </div>
        </div>
      `,
    ),
  );
};
const makeUnderCanvassOptionTmpl = () => {
  return legacyHtml`
    <div class="canvass_option">
      <div class="pinch_canvas"></div>
      <div class="only_rotation_info">
        <div class="rotate">0</div>
      </div>
      <div class="rotation_info">
        <div class="from_start">0</div>
        <div class="rotate">0</div>
        <div class="width">0</div>
      </div>
      <div class="width_info">0</div>
      <div id="tooltip_canvas">
        <div class="copy">${T('Copy')}</div>
        <div class="duplicate">${T('Duplicate')}</div>
        <div class="cut">${T('Cut')}</div>
        <div class="remove">${T('Remove')}</div>
        <div class="paste">${T('Paste')}</div>
        <div class="select_all">${T('Select All')}</div>
        <div class="remove_all">${T('Remove All')}</div>
      </div>
      <div class="special_message" id="bpf_special_message"></div>
      <div class="fetch_warning">
        ${T('maker::The size of the embroidery patch is')}<span></span>${T('maker::cm.')}
      </div>
      <div class="tip_lock">${T("It's easy to edit with the lock function.")}</div>
    </div>
  `;
};

export const makeFrontBlankHtml = (base_product_color_faces) => {
  return legacyHtml`
    <div class="front_blank for_select_faces">
      ${go(
        base_product_color_faces,
        ippL,
        strMap(
          ([i, bpcf]) => legacyHtml`
            <div class="square" idx="${i}" face_name="${bpcf._.base_product_face['name' + _en]}">
              <div class="text">
                <div class="face_name">
                  ${bpcf._.base_product_face['name' + _en]}
                  <img
                    alt=""
                    src="//s3.marpple.co/files/u_193535/2018/11/original/f_13089_1541591467700_EWbbGydXWR3Fn4Gi3D.svg"
                    class="ic_check"
                  />
                </div>
              </div>
            </div>
          `,
        ),
      )}
    </div>
  `;
};

const makeDecorationMenuForPc = ({ my_image_count, loc_optimization_icon_html }) => {
  return legacyHtml`
    <ul class="decoration_menu_for_pc">
      <li class="open_chooser circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Change')}</div>
      </li>
      <li class="open_cv_background_marpplizer circle_icon">
        <div class="icon"></div>
        <div class="text">${T('maker_background_color_editor::배경 색상')}</div>
      </li>
      <li class="add_img circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Image')}</div>
        <input
          type="file"
          style="display: none;"
          accept="image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript"
        />
      </li>
      <li class="my_lib circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Library')}</div>
        <div class="count">${my_image_count}</div>
      </li>
      <li class="add_text circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Text')}</div>
      </li>
      <li class="add_sticker2 circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Design')}</div>
      </li>
      ${loc_optimization_icon_html}
      <li class="add_sticker_line circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Character')}</div>
      </li>
      <li class="share_j circle_icon">
        <div class="icon"></div>
        <div class="text">${T('line_maker::Share')}</div>
      </li>
      <li class="add_sticker_custom circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Character')}</div>
      </li>
    </ul>
  `;
};
const makeMarppleLayerTmpl = () => {
  return legacyHtml`
    <div id="marpple_layer">
      <div class="head">${T('Layer')}</div>
      <div class="design_list_wrapper iscroll_wrapper">
        <div class="design_list" design_length="0"></div>
      </div>
    </div>
  `;
};
const makeBtnTipTmpl = (product_color) => {
  const is_empty = go(product_color, function (pc) {
    const bpd = sel('_.base_product._.base_product_detail', pc);
    if (!UtilF.isLegacyMobile() && sel('tip_images_pc' + _en + '.length', bpd) >= 1) return 'false';
    if (UtilF.isLegacyMobile() && sel('tip_images_m' + _en + '.length', bpd) >= 1) return 'false';
    return 'true';
  });
  return legacyHtml`
    <div class="btn_tip how_to_custom" is_empty="${is_empty}" is_pc="${!UtilF.isLegacyMobile()}">
      <div class="icon">
        <img
          src="//s3.marpple.co/files/u_14355/2018/11/original/f_12535_1542811417154_xM3MLv5mnvmtFli0Vq.png"
          alt=""
        />
      </div>
      <div class="txt1">${T('Check')}</div>
      <div class="is_open">
        <div class="txt2">${T('Check before you start')}</div>
        <div class="txt3">${T('Read carefully before you start designing')}</div>
      </div>
      <div class="btn_close">
        <img
          src="//s3.marpple.co/files/u_14355/2018/11/original/f_12534_1542811410660_S7yIMHo9CttwWXqM5X.png"
          alt=""
        />
      </div>
    </div>
  `;
};

const locOptimizationIndicatorHtml = legacyHtml`
    <div class="loc_optimization_indicator">보편적 위치 맞춤 요청</div>
  `;

const makeSelectFaceHtml = () => {
  return legacyHtml`
    <div class="select_face">
      <div class="body">
        <div class="selected_face selected">
          <div class="text"></div>
          <div class="wrapper"></div>
        </div>
        <div class="other_faces">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="wrapper"></div>
        </div>
      </div>
    </div>
  `;
};

const makeMarpplizerHtml = () => {
  return legacyHtml`
    <div id="marpplizer">
      <div class="wrapper iscroll_wrapper">
        <div class="marpplizer_wrapper iscroll_ul_wrapper">
          <div class="home"></div>
          <div class="activitys" style="opacity:0; transform: translateX(20%);"></div>
        </div>
      </div>
      <div class="general_option_buttons">
        ${
          G.collabo_type === ''
            ? html`<button id="to_vector_editor" type="button">${T('Start Creating')}</button>`
            : html`
                <div id="to_vector_editor">
                  <div class="border_box">
                    <div class="text">${T('Start Creating')}</div>
                    <div class="border"></div>
                    <div class="one deco"></div>
                    <div class="two deco"></div>
                    <div class="three deco"></div>
                    <div class="four deco"></div>
                  </div>
                </div>
              `
        }
        <button id="to_option" class="buying marpplizer_footer maker_to_cart" type="button">${T(
          'Add To Cart',
        )}</button>
        <button id="to_next" class="buying marpplizer_footer maker_go_next" type="button">${T(
          'Design Complete',
        )}</button>
        <button id="to_direct_custom" class="buying marpplizer_footer" type="button" style="display: none;">${T(
          '바로구매',
        )}</button>
      </div>
    </div>
  `;
};

export const makeImageUploadDescHtml = () => legacyHtml`
  <div class="image_upload_desc">
    <div class="arrow"></div>
    ${go(void 0, () => {
      if (T.lang == 'kr') {
        return legacyHtml`
          <h3>
            업로드 가능한 이미지 파일은 JPEG 또는 PNG<span class="creator_del_ai">, AI&nbsp;</span> 파일이며
            최대 25MB 이하입니다.
          </h3>
          <p class="txt1">
            제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드(CMYK/RGB)에 따라 차이가 발생할 수 있으며
            시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작권에
            대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지
            않음을 확인합니다.
          </p>
        `;
      } else if (T.lang == 'en') {
        return legacyHtml`
          <h3>Upload an Image</h3>
          <p class="txt1">
            Accepted File Types:<br />
            JPEG, PNG<span class="creator_del_ai">, AI&nbsp;</span> (Max size: 25MB)
          </p>
          <p class="txt2">
            By uploading an image you agree that you have the legal right to use it. See our
            <a href="/privacy" target="_blank">Privacy Policy.</a><br />
            For best results, See our
            <a href="/guideline/images" target="_blank">Image Guidelines.</a>
          </p>
        `;
      } else if (T.lang == 'jp') {
        return legacyHtml`
          <h3>イメージアップロード</h3>
          <p class="txt1">
            アップロードできるファイル:<br />
            JPEG, PNG<span class="creator_del_ai">, AI&nbsp;</span> (最大: 25MB)
          </p>
          <p class="txt2">
            製品やデザインの色合いはモニターやカラーモード(CMYK/RGB)により誤差が生じる場合がございます。<br />
            またイメージをアップロードすることで著作権に対するすべての責任は当人にあるということに同意したこととみなされます<br />
            イメージについてはイメージガイドラインをご覧ください。
          </p>
        `;
      }
      return '';
    })}
  </div>
`;

export const makeMakerHtml = (product_color, my_image_count, is_printable) => {
  /* 위치 최적화 버튼 html
   *  - PC / MO 공통, 인쇄용에서는 제어 버튼 제공 X. Indicator 로 표시만 제공
   * */
  const loc_optimization_icon_html = legacyHtml`
      <li class="loc_optimization_btn circle_icon">
        <div class="icon"></div>
        <div class="text">${T('maker::locOpt::name')}</div>
      </li>`;

  return legacyHtml`
    <div id="maker">
      <div class="board">
        ${makeImageUploadDescHtml()}
        ${makeFrontBlankHtml(product_color._.base_product_color._.base_product_color_faces)}
        <div class="canvass">${makeCanvasContainersTmpl()} ${makeUnderCanvassOptionTmpl()}</div>
        ${makeDecorationMenuForPc({
          my_image_count,
          loc_optimization_icon_html: is_printable ? '' : loc_optimization_icon_html,
        })}
        ${makePrevProductMatcherHtml(product_color._.base_product)}
        ${makeMarppleLayerTmpl()}
        ${BpOptionUploadTypeS.UPLOAD_TYPE.boardHtml(box().maker.product_color)}
      </div>
      ${is_printable ? locOptimizationIndicatorHtml : ''}
      ${makeBtnTipTmpl(product_color)}
      ${makeEnterMarppleShopHtml()}
      ${NewMakerIconMenuTmplS.product}
      ${NewMakerIconMenuTmplS.decoration({ loc_optimization_icon_html })}
      ${makeSelectFaceHtml()}
      ${NewMakerMobileBottomMenuTmplS.fn}
      ${makeMarpplizerHtml()}
      <video
        src=""
        id="marpple_video"
        muted="muted"
        loop="loop"
        playsinline="playsinline"
        controls="controls"
        crossorigin="anonymous"
        style="position: fixed; top: -10000px;"
      ></video>
    </div>
  `;
};

export const makeMpMakerHtml = (product_color, my_image_count, is_printable) => {
  const bpcf = product_color._.base_product_color._.base_product_color_faces;

  const { is_pure_biz_product, is_biz_product } = getIsBizProduct(product_color);

  if (G.collabo_type === '') {
    return legacyHtml`
    <div class="mp_maker_wrapper_wrapper">
      <div class="mp_maker_wrapper">
        <div
          class="mp_maker"
          id="maker_frame"
          faces_length="${bpcf.length}"
          is_biz_product="${is_biz_product}"
          is_pure_biz_product="${is_pure_biz_product}"
        >
          ${NewMakerMenuTmplS.menu}
          ${makeMakerHtml(product_color, my_image_count, is_printable)}
        </div>
      </div>
    </div>
  `;
  }
  return legacyHtml`
      <div
        class="mp_maker"
        id="maker_frame"
        faces_length="${bpcf.length}"
      >
        ${NewMakerMenuTmplS.menu}
        ${makeMakerHtml(product_color, my_image_count, is_printable)}
      </div>
  `;
};

export const renderProductColorInProductDetailMobile = () => {
  if (!UtilF.isLegacyMobile()) return;
  if (!$1('#ProductColorInProductDetail')) return;
  $1('#ProductColorInProductDetail').outerHTML = makeProductColorInProductDetailHtmlMobile(
    box.sel('maker->product_color'),
    box.sel('review_list->review_total'),
  );
  const like_el = $1('.mp_product_detail .omp-atom__heart-count-button');
  if (like_el) {
    like_el.outerHTML = OMPCoreAtomTmplS.heartCountButton({
      active: !!box.sel('maker->product_color')._.base_product._.base_products_like?.user_id,
      badge: box.sel('maker->product_color')._.base_product.like_count || 0,
    });
  }
};
