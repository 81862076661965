import { $closest, $delegate, $find, $next, $prev, $qsa } from 'fxdom/es';
import { curry, curry2, find, go, head, isFunction, last } from 'fxjs/es';
import { ComponentsInputConstantS } from '../../../../../../Components/Input/S/Constant/module/ComponentsInputConstantS.js';
import { MShopAppKeywordAutoCompleteF } from './module/MShopAppKeywordAutoCompleteF.js';

// Class Name
// wrap: .auto-complete
// list: .auto-complete__list
// item: .auto-complete__item

export const triggerEventOnClickAutoCompleteItem = curry((triggerEvent, $tab_el) => {
  return go(
    $tab_el,
    $delegate('click', '.auto-complete__item', ({ currentTarget }) => {
      const { name } = currentTarget.dataset;
      triggerEvent(name);
    }),
  );
});

export const delegateAutoCompleteKeyDown = curry2((triggerEventOnEnter, input_sel, tab_el) => {
  if (!triggerEventOnEnter || !isFunction(triggerEventOnEnter)) {
    throw new Error('triggerEventOnEnter is required.');
  }

  return go(
    tab_el,
    $delegate('keydown', input_sel, (e) => {
      // input 에서 한글자판 사용시 IME 에서 메시지를 가로채기 때문에 keyup/keydown 이벤트가 여러 번 울린다.
      // 이를 방지하기 위해 `isComposing`이 `true`일 경우에는 이벤트를 무시한다.
      if (e.isComposing) {
        return;
      }

      const { currentTarget: $input } = e;
      const $search_container = $closest('.keyword-container', $input);
      const $list = $find('.auto-complete__list', $search_container);
      if (!$list) {
        console.warn('$list element is invalid.');
        return;
      }

      const $items = $qsa('.auto-complete__item', $list);

      if (e.code === ComponentsInputConstantS.KEY_CODE.ENTER) {
        e.originalEvent.preventDefault();
        const $focused_item = findFocusedItem($items);
        MShopAppKeywordAutoCompleteF.setAutoCompleteHtml([], input_sel, tab_el);
        triggerEventOnEnter($focused_item?.dataset?.name ?? $input.value);
        return;
      }

      if (!$items || !$items.length) return;
      if (e.code === ComponentsInputConstantS.KEY_CODE.ARROW_DOWN) {
        e.originalEvent.preventDefault();
        return moveFocusedAutoCompleteItemToDownOnArrowDown($items);
      }
      if (e.code === ComponentsInputConstantS.KEY_CODE.ARROW_UP) {
        e.originalEvent.preventDefault();
        return moveFocusedAutoCompleteItemToUpOnArrowUp($items);
      }
    }),
  );
});

export const findFocusedItem = ($items) => {
  return find(($item) => $item.getAttribute('selected'), $items);
};

const moveFocusedAutoCompleteItemToDownOnArrowDown = ($items) => {
  const $focused_item = findFocusedItem($items);
  if (!$focused_item) {
    return head($items).setAttribute('selected', true);
  }
  const $next_item = $next($focused_item) ?? head($items);
  $focused_item.removeAttribute('selected');
  $next_item.setAttribute('selected', true);
};

const moveFocusedAutoCompleteItemToUpOnArrowUp = ($items) => {
  const $focused_item = findFocusedItem($items);
  if (!$focused_item) {
    return last($items).setAttribute('selected', true);
  }
  const $prev_item = $prev($focused_item) ?? last($items);
  $focused_item.removeAttribute('selected');
  $prev_item.setAttribute('selected', true);
};
