import { makeImageFromUrl } from '../../../../../Maker/F/util.js';

const makeGradientCanvas = ({ width, height, color_codes }) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
  const [a, b] = color_codes;
  gradient.addColorStop(0, a);
  gradient.addColorStop(0.1, b);
  gradient.addColorStop(0.6, a);
  gradient.addColorStop(0.7, b);
  gradient.addColorStop(0.8, a);
  gradient.addColorStop(0.9, b);
  gradient.addColorStop(1, a);

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return canvas;
};

const makePatternCanvas = async ({ width, height, pattern_url, px_per_1cm }) => {
  const pattern_img = await makeImageFromUrl(pattern_url);
  const pattern_cm_width = (pattern_img.width / 300) * 2.54;
  const pattern_cm_height = (pattern_img.height / 300) * 2.54;
  const pattern_canvas = document.createElement('canvas');
  pattern_canvas.width = Math.round(pattern_cm_width * px_per_1cm);
  pattern_canvas.height = Math.round(pattern_cm_height * px_per_1cm);
  const pattern_ctx = pattern_canvas.getContext('2d');
  pattern_ctx.drawImage(
    pattern_img,
    0,
    0,
    pattern_img.width,
    pattern_img.height,
    0,
    0,
    pattern_canvas.width,
    pattern_canvas.height,
  );
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const pattern = ctx.createPattern(pattern_canvas, 'repeat');
  ctx.fillStyle = pattern;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  // return canvas;
  const black_gradient = makeGradientCanvas({
    width,
    height,
    color_codes: ['rgb(0,0,0)', 'rgb(11,11,11)', 'rgb(0,0,0)'],
  });
  const black_gradient_ctx = black_gradient.getContext('2d');
  // black_gradient_ctx.globalCompositeOperation = 'lighten';
  black_gradient_ctx.globalCompositeOperation = 'lighter';
  black_gradient_ctx.drawImage(canvas, 0, 0);
  return black_gradient;
};

const makeResizedCanvas = async ({ url, width, height }) => {
  const img = await makeImageFromUrl(url);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
  return canvas;
};
export const foilEffects = {
  /**
   * @param {Object} foil_effect
   * @param {string=} foil_effect.pattern_dpi300_url
   * @param {string=} foil_effect.gradient_color_codes
   * @param {string=} foil_effect.gradient_1000px_square_url
   * @param {string} foil_effect.hex
   * @param {Object} size_info
   * @param {number} size_info.width
   * @param {number} size_info.height
   * @param {number} size_info.px_per_1cm
   * @return {HTMLCanvasElement}
   */
  makeCanvas: async (foil_effect, size_info) => {
    const { width, height, px_per_1cm } = size_info;
    if (foil_effect.pattern_dpi300_url) {
      const canvas = await makePatternCanvas({
        width,
        height,
        pattern_url: foil_effect.pattern_dpi300_url,
        px_per_1cm,
      });
      return canvas;
    }
    if (foil_effect.gradient_1000px_square_url) {
      const canvas = await makeResizedCanvas({
        width,
        height,
        url: foil_effect.gradient_1000px_square_url,
      });
      return canvas;
    }
    return makeGradientCanvas({ width, height, color_codes: foil_effect.gradient_color_codes });
  },
};
