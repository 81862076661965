import { add, go, map, reduce, constant, range, strMap } from 'fxjs/es';
import { CREW_DOMAIN_NAME } from '../../../../Creator/Crew/S/constant.js';
import { EMOJI_REGX, SPECIAL_CHARACTERS_REGX } from '../../../../Util/S/constant.js';
import { MShopCoreConstantS } from '../../../Core/S/Constant/module/MShopCoreConstantS.js';
import { MShopExtraAuthCrewConstantS } from '../../../ExtraAuth/Crew/S/Constant/module/MShopExtraAuthCrewConstantS.js';

export const APP_RESET_NAVIGATE_TYPE = {
  HOME: 'Home',
  LIVE: 'Live',
  REVIEW: 'Community',
  SEARCH: 'Search',
  MYPAGE: 'Mypage',
};

// FIXME: 앱 공지 필요할 때 주석 제거 (아래 코드는 예시입니다)
// eslint-disable-next-line no-unused-vars
const NOTICE = {
  id: 'NOTICE_2023060700',
  title: { kr: '공지', en: 'Notice', jp: '通知する' },
  description: { kr: '공지입니다.', en: 'This is notice.', jp: 'これは通知です' },
  iconFilePath:
    'https://s3.marpple.co/files/u_14345/2021/6/original/913490c997da447298f1b0c53cda2dc4ac0daf921.png',
  buttons: [
    {
      text: { kr: '닫기', en: 'Close', jp: '閉まる' },
      close: true,
      setDoNotShowLater: true,
    },
    {
      text: { kr: '이동', en: 'Go', jp: '行く' },
      url: {
        kr: 'https://www.marpple.com/kr',
        en: 'https://www.marpple.com/en',
        jp: 'https://www.marpple.com/jp',
      },
      close: true,
      setDoNotShowLater: true,
    },
  ],
};

// MEMO: 버전 기록은 아래 링크에서 확인 가능합니다.
// MEMO: https://www.notion.so/marpple/07af015590404b6eb997860af9d718ae?pvs=4#29ceeea7c57a47308a95a99be8a38a8b

const NEED_UPDATE = {
  version: '1.6.2', // 해당 버전 "미만"일 때 강제 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '이번 업데이트에서는\\n화면 개선 및 업데이트 관련 설정이\\n개선되었습니다.',
    en: `This update includes\\nscreen improvements and enhancements\\nto update-related settings.`,
    jp: 'このアップデートでは、\\n画面の改善とアップデート関連の設定が向上しました。',
  },
};

const RECOMMEND_UPDATE = {
  version: '1.9.0', // 해당 버전 "미만"일 때 권장 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '리뉴얼된 브랜드의 마플샵을 만나보세요.\\n사용자 경험 개선을 위해 UI도 개선되었어요.\\n지금 바로 업데이트하고 만나보세요!',
    en: 'Experience the renewed Marppleshop.\\nThe UI has been improved for a better experience.\\nUpdate now and discover it!',
    jp: 'リニューアルされたMarppleShopご覧ください。\\nユーザーエクスペリエンスのためにUIも改善されました。\\n今すぐアップデートしてお楽しみください。',
  },
};

export const isNeedUpdateForDtg = (version) => {
  if (!version) return true;
  return compareVersion(version, '1.6.3') === -1;
};

const isNeedUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, NEED_UPDATE.version) === -1;
};

const isRecommendUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, RECOMMEND_UPDATE.version) === -1;
};

export const notice = (version, platform) => {
  // 1.6.2 이상 버전에서만 공지 화면 노출이 가능합니다.
  // 1.6.2 미만 버전 대응을 위해 추가한 코드이니 `NEED_UPDATE.version`이 1.6.2 이상으로 올라간다면 아래 if 문을 삭제해주세요.
  // 그 전까지 절대 삭제하시면 안 됩니다!
  if (compareVersion(version, '1.6.2') === -1) {
    return null;
  }
  // FIXME: 앱 공지 필요할 때 주석 제거
  // if (platform === 'Android' || platform === 'iOS') {
  //   return JSON.stringify({
  //     ...NOTICE,
  //     ...getUpdate(version, platform),
  //   });
  // }
  return null;
};

// eslint-disable-next-line no-unused-vars
const getUpdate = (version, platform) => {
  if (isNeedUpdate(version)) {
    return { needUpdate: needUpdate(version, platform) };
  }
  if (isRecommendUpdate(version)) {
    return { recommendUpdate: recommendUpdate(version, platform) };
  }
  return {};
};

export const needUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isNeedUpdate(version)) {
        return { platform, ...NEED_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const recommendUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isRecommendUpdate(version)) {
        return { platform, ...RECOMMEND_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const is_sharable = (version, platform) => {
  return !(platform === 'Android' && version < '1.1.0');
};

/*
 * 나중에 추상화
 * */
export const getSellerWording = (crew, is_seller) =>
  crew.domain_name == CREW_DOMAIN_NAME.NAVER_WEBTOON
    ? T('mshop::Menu::작가')
    : is_seller
    ? T('Seller')
    : T('mshop::Menu::Creator');

/**
 * version을 비교하는 함수
 * @param {string} version1
 * @param {string} version2
 * @return {number}
 * `version1` > `version2`이면 `1`
 *
 * `version1` < `version2`이면 `-1`
 *
 * `version1` = `version2`이면 `0`
 */
export const compareVersion = (version1, version2) => {
  const v1 = parseVersion(version1);
  const v2 = parseVersion(version2);

  if (v1.major > v2.major) return 1;
  if (v1.major < v2.major) return -1;

  if (v1.minor > v2.minor) return 1;
  if (v1.minor < v2.minor) return -1;

  if (v1.patch > v2.patch) return 1;
  if (v1.patch < v2.patch) return -1;

  return 0;
};

/**
 * @typedef {Object} ParsedVersion
 * @property {number} major
 * @property {number} minor
 * @property {number} patch
 */

/**
 *
 * @param {string} version
 * @return ParsedVersion
 */
export const parseVersion = (version) => {
  const versionFormat = /^(\d+).(\d+).(\d+)$/;
  const match = version.match(versionFormat);
  if (!match) {
    throw new Error('version is not valid.');
  }

  const [, major, minor, patch] = match;
  return {
    major: Number.parseInt(major),
    minor: Number.parseInt(minor),
    patch: Number.parseInt(patch),
  };
};

export const isPermitSpecialCharProductName = (value) => {
  if (SPECIAL_CHARACTERS_REGX.test(value) || EMOJI_REGX.test(value)) {
    return false;
  }
  return true;
};

export const moneySign = (price) => {
  if (T.lang === 'kr') {
    return `${price} 원`;
  }
  if (T.lang === 'en') {
    return `$ ${price}`;
  }
  if (T.lang === 'jp') {
    return `¥ ${price}`;
  }
};

export const getColorInlineStyle = ({ style_props, color }) => {
  if (!color || !style_props || !style_props.length) return '';

  return go(
    style_props,
    map((target) => `${target}: ${color};`),
    reduce(add),
    (styles) => `style="${styles}"`,
  );
};

/**
 * @param {object} plus_crew
 * @param {string} plus_crew.brand_color
 * @param {string} plus_crew.menu_color
 * @param {boolean} isApp
 * @return {{menu_color: string, brand_color: string}}
 */
export const getCrewBrandColors = (plus_crew, isApp) => {
  const DEFAULT_COLOR = {
    brand_color: MShopCoreConstantS.colors.ms_blue,
    menu_color: MShopCoreConstantS.colors.white,
  };
  const is_white = plus_crew?.brand_color?.toLowerCase() === '#ffffff';

  if (!plus_crew?.brand_color || isApp || is_white) return DEFAULT_COLOR;

  return { brand_color: plus_crew.brand_color, menu_color: plus_crew.menu_color };
};

/**
 *
 * @param {boolean} _case
 * @param {any} returnVal
 */
export const returnIf = (_case, returnVal) => {
  const is_fn = typeof returnVal == 'function';
  if (_case) return is_fn ? returnVal() : returnVal;
};

export const getCustomerServiceInfo = (crew_id) => {
  if (crew_id == MShopExtraAuthCrewConstantS.NBOX_ID) {
    return {
      tel: '1566-9437',
      sms: '15669437',
      email: 'help@marpple.com',
    };
  }

  return {
    tel: '1566-5496',
    sms: '15665496',
    email: 'cs@marppleshop.com',
  };
};

export const makeMaskingBlur = (val, is_masking_blur) => {
  if (is_masking_blur && val) {
    const len = Math.min(Math.ceil(val.length / 4), 3);
    return val.substr(0, len) + strMap(constant('*'), range(val.length - len));
  } else {
    return val;
  }
};
