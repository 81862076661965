export function start(don_page) {
  return new Promise(function (resolve) {
    const inputs = jQuery(
      _go(
        don_page,
        $.find('input[type="range"]'),
        _p.reject(function (input_el) {
          return (
            $.css($.closest(input_el, '.range'), 'display') == 'none' ||
            $.attr($.closest(input_el, '.maker_range_wrapper'), 'only_number')
          );
        }),
      ),
    );
    if (inputs.length == 0) resolve(don_page);
    _p.each(inputs, function (input) {
      jQuery(input)
        .rangeslider('destroy')
        .rangeslider({
          polyfill: false,
          onSlide: function () {
            $.trigger(input, 'input', { mode: 'range_slider' });
          },
          onInit: function () {
            $.remove_class($1('.m_image_pattern.ready'), 'ready');
            resolve(don_page);
          },
          onSlideEnd: function () {
            $.trigger(input, 'input', { mode: 'range_slider' });
          },
        });
    });
  });
}
