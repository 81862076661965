import { head, map, range, sel, strMap } from 'fxjs/es';
import { MpShopProductS } from '../../../MpShop/Product/S/Function/module/MpShopProductS.js';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { makeReviewLink } from '../../Detail/S/tmpl.js';

export const makeReviewListImgSrc = (review) =>
  review.collabo_type == 'creator'
    ? MpShopProductS.setOnDemandJpegUrl({
        width: 50,
        url: MpShopProductTmplS.thumbnailUrl(review),
      })
    : UtilImageS.getResizedUrl({
        width: 50,
        url:
          review._.base_product_color.product_thumb_url || review._.base_product.base_product_color_face_url,
      });

export const makeReviewListScoreHtml = (score) =>
  strMap((i) => legacyHtml` <div class="star" is="${score > i}" rate="${i + 1}"></div> `, range(5));

export const makeReviewListStarsHtml = function (avg) {
  const int = parseInt(avg);
  const flt = parseFloat(avg) - int;
  return strMap(
    map(function (val) {
      if (val < int) return '100%';
      if (val == int) {
        if (flt == 0) return '0%';
        if (flt > 0.5) return '65%';
        if (flt < 0.5) return '35%';
        return '50%';
      }
      return '0';
    }, range(5)),
    (percent) => legacyHtml`
      <div class="star_off">
        <div class="star_on" style="width:${percent};"></div>
      </div>
    `,
  );
};

export const makeReviewListSnsUrl = function (sns_url) {
  if (!sns_url) return 'sns';
  else if (sns_url.match(/instagram\.com/i)) return 'instagram';
  else if (sns_url.match(/facebook\.com/i)) return 'facebook';
  else if (sns_url.match(/twitter\.com/i)) return 'twitter';
  else if (sns_url.match(/naver\.com/i)) return 'naver';
  else if (sns_url.match(/youtube.com|youtu.be/i)) return 'youtube';
  else return 'sns';
};

export const makeReviewListSnsHref = function (url) {
  if (!url || !linkify.test(url)) return '';
  return 'http://' + url.replace(/^http:\/\/|^https:\/\/|^\/\//, '');
};

export const makeDfReviewListReplyHtml = (review) =>
  review.reply
    ? legacyHtml`
        <div class="reply_wrap" is_reply="true">
          <div class="owner"></div>
          <div class="reply">${UtilS.linkify(review.reply)}</div>
        </div>
      `
    : '';

export const makeReviewListComment = (review) =>
  UtilS.linkify(review['comment' + G._en] || review.comment || review.comment_en || review.comment_jp);

export const makeReviewItemHtml = (review) => legacyHtml`
  <div class="review_item infi_item" _sel="./(#${review.id})" has_photo="${review.has_photo}">
    <div class="photo_info">
      <div class="review_img">
        <img src="${UtilS.escape(
          UtilImageS.getResizedUrl({ width: 320, url: sel('url', head(review.files)) }),
        )}" />
        <div class="more_img" is_more="${review.files.length > 1}"></div>
      </div>
      <div class="user_name">${UtilS.escape(review._.user.email)}</div>
    </div>
  </div>
`;

export const makeReviewItemHtml2 = (review) => legacyHtml`
  <div class="review_item infi_item" _sel="./(#${review.id})" has_photo="${review.has_photo}">
    <div class="info">
      <div class="base_product" is_bp_review="${!!review._.base_product.name}">
        <div class="product_thumb_url">
          <a href="${makeReviewLink(review)}">
            <img src="${UtilS.escape(makeReviewListImgSrc(review))}" alt="" />
          </a>
        </div>
      </div>
      <div class="right_info" files_len="${sel('files.length', review)}">
        <div class="right_left">
          <div class="bp_name" is_bp_review="${!!review._.base_product.name}">
            <a href="${makeReviewLink(review)}">${review._.base_product.name || 'NO NAME'}</a>
          </div>
          <div class="review_score">${makeReviewListScoreHtml(review.score)}</div>
          <div class="created_at">${moment(review.created_at).format('YYYY-MM-DD')}</div>
          <div class="user_name">${UtilS.escape(review._.user.email)}</div>
          <div class="best_mark_wrap" is_best="${review.is_best}">
            <div class="best_mark">BEST REVIEW</div>
          </div>
          <div class="review_comment">
            <div class="comment_wrap comment_wrap2">
              <div class="comment">${makeReviewListComment(review)}</div>
              <div class="curtain"></div>
            </div>
            <div class="more">Read more</div>
          </div>
        </div>
        <div class="review_imgs_wrap">
          <div class="review_img">
            <div class="img" style="background-image: url(${UtilImageS.getResizedUrl({
              width: 160,
              url: sel('url', head(review.files)),
            })});">
              <div class="more_img" is_more="${review.files.length > 1}"></div>
            </div>
          </div>
        </div>
        <div class="is_kr" lang="${T.lang}">
          <div class="sns_url_wrap" is_sns_url="${!!review.sns_url}">
            <a href="${makeReviewListSnsHref(review.sns_url)}" target="_blank" class="sns_url">
              <span class="sns_info" sns_info="${makeReviewListSnsUrl(review.sns_url)}"></span>
              <span>SNS</span>
            </a>
          </div>
          ${makeDfReviewListReplyHtml(review)}
        </div>
      </div>
      ${makeDeleteReviewButton(review)}
    </div>
  </div>
`;

export const makeDeleteReviewButton = (review) =>
  review.is_me
    ? legacyHtml`
        <div class="review-delete">
          <button type="button">${T('Delete')}</button>
        </div>
      `
    : '';

export const makeReviewListHtml = (reviews, is_all) => legacyHtml`
  <div class="review_list infi_container" _sel="./reviews" photo_review="${!is_all}">
    ${strMap(is_all ? makeReviewItemHtml2 : makeReviewItemHtml, reviews)}
  </div>
`;

export const makeReviewUpCsHtml = (up_c) => legacyHtml`
  <div class="up_c infi_item" _sel="./(#${up_c.id})">
    <div class="ordered_at">
      ${moment(up_c.ordered_at).format('YYYY-MM-DD HH:mm')}
      <span>No.${up_c.id}</span>
    </div>
    <div class="img canvas_100"><canvas></canvas></div>
    <div class="bp_info">
      <div class="bp_name">
        ${up_c._.product_color._.base_product.name}
        [${up_c._.product_color._.base_product_color['name' + G._en]}]
      </div>
      <div class="review_info">
        <button class="write_a_review">
          <span
            >${
              G.collabo_type == 'creator' ? T('review::Support your Sellers') : T('review::Write a Review')
            }</span
          >
        </button>
      </div>
    </div>
  </div>
`;

export const makeReviewListMainHtml = (reviews, review_total, query) => legacyHtml`
  <div class="mp_review_list" _sel="review_list">
    <div class="review_header" photo_review="${!query.is_all}">
      <h1 class="review_title">
        ${T('home::Reviews')}
        <span class="review_total_count">${UtilS.commify(review_total?.count)}</span>
        <span class="score_percent"> ${makeReviewListStarsHtml(review_total?.avg)} </span>
      </h1>
      <div class="review_header_right">
        <div class="cate_list_selector_wrap">
          <div
            id="cate_list_selector"
            el_id="review_cate_list"
            current_value="${parseInt(query.cate_list_id) || 'all'}"
            name_key="name"
            value_key="id"
            _sel="./cate_lists"
          >
            <div class="selected">${T('All products')}</div>
          </div>
        </div>
        <div class="photo_reviews">
          <div class="photo_toggle">
            <span class="title">${T('maker::Photo')}</span>
            <span class="radio_toggle ${!query.is_all ? 'check_on' : ''}">
              <label class="on">
                <input
                  type="radio"
                  name="photo_review"
                  value="${!!query.is_all}"
                  ${query.is_all ? 'checked' : ''}
                />
                <span>ON</span>
              </label>
              <label class="off">
                <input
                  type="radio"
                  name="photo_review"
                  value="${!query.is_all}"
                  ${!query.is_all ? 'checked' : ''}
                />
                <span>OFF</span>
              </label>
              <div class="control_button"></div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="reviews_wrap"
      total_count="${review_total.count}"
      review_len="${reviews.length || review_total.count}"
    >
      <div id="not_found_results">
        ${T(
          (reviews.length || review_total.count) > 0
            ? 'review::No Photo review.'
            : 'review::No reviews have been written.',
        )}
      </div>
      ${makeReviewListHtml(reviews, query.is_all)}
    </div>
  </div>
`;

function lineReviewVisualText() {
  if (T.lang == 'kr') {
    return legacyHtml`
      <div class="text">
        <p class="txt1">
          수많은 리뷰가 증명하는<span class="w_br m_br"></span>
          커스텀의 매력
        </p>
        <p class="txt2">
          LINE FRIENDS CREATOR에서는<span class="w_br m_br"></span>
          누구나 금손이 될 수 있어요!
        </p>
      </div>
    `;
  } else {
    return legacyHtml`
      <div class="text">
        <p class="txt1">
          Hundreds of positives<span class="m_br"></span>
          reviews showing the upward<span class="m_br"></span>
          trend of customized items
        </p>
        <p class="txt2">
          At LINE FRIENDS CREATOR,<span class="m_br w_br"></span>
          everyone can create a masterpiece!
        </p>
      </div>
    `;
  }
}

export const makeLineReviewListMainHtml = (reviews, review_total, query) => legacyHtml`
  <div class="line_review_list">
    <div class="review_banners">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide bf">
            <div class="visual">${lineReviewVisualText()}</div>
          </div>
          <div class="swiper-slide bt21">
            <div class="visual">${lineReviewVisualText()}</div>
          </div>
          <div class="swiper-slide bs">
            <div class="visual">${lineReviewVisualText()}</div>
          </div>
          <div class="swiper-slide truz">
            <div class="visual">${lineReviewVisualText()}</div>
          </div>
        </div>
      </div>
    </div>
    ${makeReviewListMainHtml(reviews, review_total, query)}
  </div>
`;
