import { html, map, sel, strMap } from 'fxjs/es';
import { MShopCoreConstantS } from '../../../../MShop/Core/S/Constant/module/MShopCoreConstantS.js';
import { MShopCoreTmplS } from '../../../../MShop/Core/S/Tmpl/module/MShopCoreTmplS.js';

export const menuList = (cate_lists, seller_type_filters) => [
  { name: T('mshop::Menu::Home'), link: '/' },
  {
    name: T('mshop::Menu::Creator'),
    link: '/@/sellers',
    category: map(
      ({ id, name }) => ({ name, link: `/@/sellers?st_id=${id}&drawer=true` }),
      seller_type_filters,
    ),
  },
  { name: T('mshop::Menu::Contents'), link: '/@/todays' },
  {
    name: T('mshop::Menu::Product'),
    link: '/@/products',
    category: map(({ id, name }) => ({ name, link: `/@/product/list/all?cate_list_id=${id}` }), cate_lists),
  },
  { name: T('mshop::Live'), link: '/@/live' },
  { name: T('mshop::Community'), link: '/@/communities' },
];

export const plusMenuList = (crew) => [
  { name: ET('layout::drawer::menu::home'), link: `/` },
  // TODO: 지금은 NBOX 라서 뺀것, 나중에 추상화하기
  // { name: T('mshop::Menu::Contents'), link: '/@/todays' },
  { name: ET('layout::drawer::menu::product'), link: `/@/products` },
  // TODO: 지금은 NBOX 라서 뺀것, 나중에 추상화하기
  // {
  //   name: MShopUtilS.getSellerWording(crew),
  //   link: '/@/sellers',
  // },
  { name: ET('layout::drawer::menu::review'), link: '/@/communities' },
];

// const drawerSellerMenuList = ()=> [
//   { name: T('mshop::Menu::Product'), link: `/${domain_name}` },
//   { name: T('mshop::Community'), link: `/${domain_name}/community` },
//   { name: T('mshop::Menu::Order Status'), link: `/@/orders` },
//   { name: 'FAQ', link: `/${domain_name}/help` },
// ];

export const menuGroups = strMap(
  ({ name, link, category = [] }) => html`
    <div class="drawer-category__group">
      <a href="/${T.lang}${link}" class="drawer-category__group-anchor">${name}</a>
      ${category.length > 0
        ? html`
            <ul class="drawer-category__list">
              ${strMap(
                ({ name, link }) => html` <li class="drawer-category__item">
                  <a href="/${T.lang}${link}" class="drawer-category__anchor">${name}</a>
                </li>`,
                category,
              )}
            </ul>
          `
        : ''}
    </div>
  `,
);

export const tmpl = ({ user, is_mobile }) => {
  // TODO: nbox 아닐 때에는 user 없을 수도 있음 - 없는 경우 디자인 개발 필요
  if (!user) return '';
  const default_profile_image = html`<img
    src="${sel('profile_image', user) || MShopCoreConstantS.default_images.profile}"
    class="avatar__image"
    alt="${user?.name}"
  />`;

  return html`
    <div class="drawer">
      <div class="drawer__inner">
        <div class="drawer__header">
          <button type="button" class="drawer__close">
            ${MShopCoreTmplS.closeIcon({ color: 'black' })}
            <span class="drawer__blind-text">${T('mshop::Close')}</span>
          </button>
        </div>
        <div class="drawer__body">
          <div class="drawer__iscroll">
            <div class="drawer__iscroll-list">
              <div class="drawer-login">
                <div class="avatar avatar--login">${default_profile_image}</div>
                <div class="drawer-login__box">
                  <div class="drawer-login__text-box">
                    <span class="drawer-login__text">
                      ${T('mshop::welcome text', { name: `<b>${user?.name}</b>` })}
                    </span>
                  </div>
                  <a href="/${T.lang}/@/mypage" class="drawer-login__link drawer-login__link--round">
                    <span class="drawer-login__link-text">${T('mshop::Mypage')}</span>
                  </a>
                </div>
              </div>
              <div class="drawer-category">${menuGroups(plusMenuList())}</div>
            </div>
          </div>
          <div class="drawer-banner">
            ${user?._.store.id
              ? `<a href='/${T.lang}/${user._.store.domain_name}/settings' class='drawer-banner__button'>${T(
                  'mshop::Go to Seller Studio',
                  {
                    emoji: is_mobile ? ' ⭐️' : '',
                  },
                )}</a>`
              : `<a href='/${T.lang}/@/hello' class='drawer-banner__button drawer-banner__button--apply'>
                ${T('mshop::Go to Apply Seller', { emoji: is_mobile ? ' 😃️' : '' })}
              </a>`}
          </div>
        </div>
      </div>
    </div>
    <div class="creator-mask"></div>
  `;
};
