import { saveAs } from 'file-saver';
import { $closest, $data, $delegate, $find, $setText, $trigger } from 'fxdom/es';
import { delay, go } from 'fxjs/es';
import { BpOptionTmplS } from '../../S/Tmpl/module/BpOptionTmplS.js';
import { changeNotVirtualSelect, changeVirtualSelect } from './bpoption.js';
import { VectorEditorF } from '../../../VectorEditor/F/Function/module/VectorEditorF.js';
import { SVGEditorProductDetailF } from '../../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BaseProductS } from '../../../BaseProduct/S/Function/module/BaseProductS.js';
import { setIScroll } from '../../../Maker/F/Marpplizer/fs.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { BpOptionUploadTypeF } from '../../UploadType/F/Function/module/BpOptionUploadTypeF.js';
import { alertProEditorCsError } from '../../../VectorEditor/F/Function/pro_editor.error.js';
import { SVGEditorUtilF } from '../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { renderMpMakerProductPrice } from '../../../Maker/F/Product/product_tmpl.js';

function dataURLtoBlob(canvas) {
  const dataURL = canvas.toDataURL('image/png', 1);
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  blob.lastModifiedDate = new Date();
  blob.name = 'printable_file.png';
  return blob;
}

function svgElToBlob(svg_el) {
  const svgString = new XMLSerializer().serializeToString(svg_el);

  // SVG 문자열을 Blob 객체로 변환
  const blob = new Blob([svgString], { type: 'image/svg+xml' });

  // Blob 객체를 파일 객체로 변환 (선택적)
  blob.lastModifiedDate = new Date();
  blob.name = 'cutting_line.svg';

  // 변환된 Blob 객체를 사용하거나 전송 등의 작업을 수행
  return blob;
}
export const event = (tab_el) => {
  return go(
    tab_el,
    $delegate('click', '.maker-upload-type-mobile', () => {
      BpOptionUploadTypeF.openMobilePopup();
    }),
    $delegate('click', '#marpplizer .pro-editor .single-upload-file', ({ currentTarget: ct }) => {
      const file = $find('input[type="file"]', ct);
      if (file == null) throw new Error(`Not exist file element`);
      file.click();
    }),
    $delegate('click', '#marpplizer .maker-upload-type-template__download-template button', async (e) => {
      try {
        $.don_loader_start();
        const base_product = box.sel('maker->product_color->_->base_product');
        const { endpoint_url, file_extension } = await BaseProductS.getProEditorTemplateUrl({
          lang: T.lang,
          base_product_id: base_product.id,
        });
        saveAs(endpoint_url, `${base_product[`name${G._en}`]}-TEMPLATE.${file_extension}`);
        await delay(500, () => {});
        $.don_loader_end();
      } catch (err) {
        await alertProEditorCsError({ title: TT('pro_editor::alert::title::download') });
        $.don_loader_end();
        console.error(err);
      }
    }),
    $delegate('click', '#marpplizer .maker-upload-type-template__upload-file button', (e) => {
      go(
        e.currentTarget,
        $closest('.maker-upload-type-template__upload-file'),
        $find('input[type="file"]'),
        (el) => $trigger('click', el),
      );
    }),
    $delegate(
      'change',
      '#marpplizer .maker-upload-type-template__upload-file input[type="file"]',
      async ({ currentTarget: ct }) => {
        let loaderDone = null;
        try {
          loaderDone = SVGEditorUtilF.percentLoader({
            message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
            time: 6 * 1000,
            clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
          });
          const file = ct.files[0];
          const original_svg_file_name = file.name;

          if (file) {
            const maker_type = $closest('#maker_frame', ct).dataset?.maker_type;

            const options = (() => {
              if (maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
                const acryl_stand_size_option_el = go(
                  ct,
                  $closest('#marpplizer'),
                  $find('.bp_option_group[data-dev_name="아크릴_스탠드_사이즈"]'),
                );
                if (acryl_stand_size_option_el) {
                  const size_select_el = $find('select', acryl_stand_size_option_el);
                  const selected_option = $data(size_select_el.options[size_select_el.selectedIndex]);
                  return selected_option._.maker_meta.value.free_meta;
                }
              }
            })();
            const result = await VectorEditorF.pro.processSvgUploadFile({ file, maker_type, options });

            const { printable_image_canvas, key_item_coord_ratio, cut_svg_el, toPrintableImageCanvas } =
              result;

            await SVGEditorProductDetailF.directFileUploadSvgProcess({
              cutting_line_svg_file: svgElToBlob(cut_svg_el),
              original_svg_file: file,
              key_item_coord_ratio,
              original_svg_file_name,
              printable_png_file: dataURLtoBlob(printable_image_canvas),
              thumbnail_png_file: dataURLtoBlob(await toPrintableImageCanvas(800)),
            });
            go(
              ct,
              $closest('.maker-upload-type-template__upload-file'),
              $find('.maker-upload-type-template__file-name'),
              $setText(original_svg_file_name),
            );
          }
        } catch (err) {
          console.log('err', err);

          if (loaderDone) {
            await loaderDone(true).catch(() => {});
            loaderDone = null;
          }
          await VectorEditorF.alertProEditorError(err);
        } finally {
          $.don_loader_end();
          if (loaderDone) {
            await loaderDone().catch(() => {});
            loaderDone = null;
          }
          ct.value = null;
        }
      },
    ),
    $delegate('click', '#marpplizer .bp_option_group .select_box .option:not(.selected)', async (e) => {
      const bp_option_group_el = $closest('.bp_option_group')(e.currentTarget);
      const bp_option_groups_el = $closest('.bp_option_groups')(e.currentTarget);
      if (
        bp_option_groups_el.dataset.is_designed === 'true' &&
        bp_option_group_el.dataset.is_lockable === 'true'
      )
        return;
      $.selectable(e);
      const value = e.currentTarget.dataset.id;
      await go(bp_option_group_el, $find('select'), async (select_el) => {
        select_el.value = value;
        try {
          $.don_loader_start();
          if (bp_option_group_el.dataset.is_virtual === 'false') {
            if (G.mp.maker.active()) G.mp.maker.unselect_all();
            await changeNotVirtualSelect(select_el);
          } else {
            await changeVirtualSelect(bp_option_groups_el);
          }
        } catch (e) {
          console.error(e);
          $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
          bp_option_groups_el.outerHTML = BpOptionTmplS.bpAllOptions({
            product: box().maker.product_color,
          });
        } finally {
          $.don_loader_end();
        }
      });
      const activity_el = $closest('.activity', bp_option_group_el);
      if (activity_el) {
        if (!UtilF.isLegacyMobile()) setIScroll(activity_el);
      }
    }),
    $delegate('change', '.bp_option_group[data-is_virtual="false"] select', async (e) => {
      const bp_option_groups_el = go(e.currentTarget, $closest('.bp_option_groups'));
      try {
        $.don_loader_start();
        const select_el = e.currentTarget;
        if (G.mp.maker.active()) G.mp.maker.unselect_all();
        await changeNotVirtualSelect(select_el);
        renderMpMakerProductPrice();
      } catch (e) {
        console.error(e);
        $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
        bp_option_groups_el.outerHTML = BpOptionTmplS.bpAllOptions({
          product: box().maker.product_color,
        });
      } finally {
        $.don_loader_end();
      }
    }),
    $delegate('change', '.bp_option_group[data-is_virtual="true"] select', async (e) => {
      const bp_option_groups_el = go(e.currentTarget, $closest('.bp_option_groups'));
      try {
        $.don_loader_start();
        await changeVirtualSelect(bp_option_groups_el);
      } catch (e) {
        $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
        bp_option_groups_el.outerHTML = BpOptionTmplS.bpAllOptions({
          product: box().maker.product_color,
        });
      } finally {
        $.don_loader_end();
      }
    }),
  );
};
