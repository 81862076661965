import { $findAll, $remove } from 'fxdom/es';
import { each, go, html, sel, strMap } from 'fxjs/es';
import * as L from 'fxjs/es/Lazy/index.js';
import { AdScriptFbF } from '../../../AdScript/Fb/F/Function/module/AdScriptFbF.js';
import { AdScriptGaF } from '../../../AdScript/Ga/F/Function/module/AdScriptGaF.js';
import { AdScriptKakaoF } from '../../../AdScript/Kakao/F/Function/module/AdScriptKakaoF.js';
import { LinePrivacyTmplS } from '../../../Line/Privacy/S/Tmpl/module/LinePrivacyTmplS.js';
import { LineTerms2MuiF } from '../../../Line/Terms2/F/Mui/module/LineTerms2MuiF.js';
import { LineTerms2TmplS } from '../../../Line/Terms2/S/Tmpl/module/LineTerms2TmplS.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { OMPAuthF } from '../../../OMP/Auth/F/Function/module/OMPAuthF.js';
import { PrivacyMainMuiF } from '../../../Privacy/Main/F/Mui/module/PrivacyMainMuiF.js';
import { PrivacyPopUpTmplS } from '../../../Privacy/PopUp/S/Tmpl/module/PrivacyPopUpTmplS.js';
import { TermsConditionsMuiF } from '../../../Terms/Conditions/F/Mui/module/TermsConditionsMuiF.js';
import { TermsConditionsTmplS } from '../../../Terms/Conditions/S/Tmpl/module/TermsConditionsTmplS.js';
import { isMpApp } from '../../../Util/F/fs.js';
import { is_ios } from '../../../Util/F/Function/ua.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UserFindIdMuiF } from '../../FindId/F/Mui/module/UserFindIdMuiF.js';
import { UserResetPasswordMuiF } from '../../ResetPassword/F/Mui/module/UserResetPasswordMuiF.js';
import { checkPassword } from '../S/fs.js';
import { afterSignIn, closeFrameAfterSignIn } from './fs.js';

const fbLoginRemove = (tab_el) => {
  if (isMpApp() && is_ios()) go(tab_el, $findAll('.or, .fb_submit'), each($remove));
};

export function frameOpenSignUpIn(href, prev_frame_show = false) {
  if (!box.sel('is_user')) {
    if (G.collabo_type === '') {
      return OMPAuthF.openLogin();
    } else {
      return new Promise(function (rs) {
        $.frame.open({
          is_modal: G.is_pc_size(),
          frame_name: 'mp.login',
          prev_frame_show,
          closing: function (X, data) {
            if (!href || !data) {
              $.remove_class($1('body'), 'body_scroll');
              $.remove_class($1('html'), 'login_mode');
            } else {
              return new Promise(function (rs) {
                window.location.href = href;
                setTimeout(rs, 1000);
              });
            }
          },
          closed: function (X, data) {
            rs(data);
          },
        });
      });
    }
  }
}

export const defnMpLoginSignUpTab = () => ({
  title: T('Sign up'),
  tab_name: 'mp_login_sign_up_tab',
  template: () => legacyHtml`
    <div class="wrap">
      <div class="login_input">
        <div class="name control">
          <input type="text" name="name" placeholder="${T('login::Your Name')}" value="" />
        </div>
        <div class="email control">
          <input type="email" name="email" placeholder="${T('login::Email_placeholder')}" value="" />
        </div>
        <div class="pw control">
          <input
            type="password"
            name="password"
            placeholder="${T('login::비밀번호 10 ~ 64자')}"
            value=""
          />
        </div>
      </div>
      <div class="agree_email">
        ${(() => {
          if (T.lang == 'en') {
            return legacyHtml`
              <label>
                <span class="check_box"></span>
                <input type="checkbox" name="check_agree" />
                <span>You can add your products to your cart just by entering your email address.</span>&nbsp;
                <span>By signing up, you agree to</span>&nbsp;
                <span>
                  <a href="/privacy" class="go_privacy" target="_blank">Privacy Policy</a>&nbsp;
                  <a href="/terms" class="go_terms" target="_blank">Terms & Conditions.</a>
                </span>
              </label>
            `;
          } else if (T.lang == 'jp') {
            return legacyHtml`
              <div>
                <label>
                  <span class="check_box"></span>
                  <input type="checkbox" name="check_agree" />
                  <span>${T('signup::이용약관 동의')}</span>&nbsp;
                  <span>
                    <a href="/terms" class="go_terms" target="_blank">${T('signup::보기')}</a>
                  </span>
                </label>
                <label>
                  <span class="check_box"></span>
                  <input type="checkbox" name="check_privacy" />
                  <span>${T('signup::개인정보수집 이용 동의')}</span>&nbsp;
                  <span>
                    <a href="/privacy" class="go_privacy" target="_blank">${T('signup::보기')}</a>
                  </span>
                </label>
                <label>
                  <span class="check_box"></span>
                  <input type="checkbox" name="check_age14" />
                  <span>${T('signup::만 14세 이상입니다.')}</span>
                </label>
              </div>
            `;
          }

          return html`
            <div>
              <label>
                <span class="check_box"></span>
                <input type="checkbox" name="check_agree" />
                <span>${T('signup::이용약관 동의')}</span>&nbsp;
                <span>
                  <a href="/terms" class="go_terms" target="_blank">${T('signup::보기')}</a>
                </span>
              </label>
              <label>
                <span class="check_box"></span>
                <input type="checkbox" name="check_privacy" />
                <span>${T('signup::개인정보수집 이용 동의')}</span>&nbsp;
                <span>
                  <a href="/privacy" class="go_privacy" target="_blank">${T('signup::보기')}</a>
                </span>
              </label>
              <label>
                <span class="check_box"></span>
                <input type="checkbox" name="check_age14" />
                <span>${T('signup::만 14세 이상입니다.')}</span>
              </label>
            </div>
            <label>
              <span class="check_box"></span>
              <input type="checkbox" name="agree_email" />
              <span>${T('signup::Email News')}</span>&nbsp;
              ${G.collabo_type !== 'line'
                ? html`<span>
                    <a href="#" class="go_marketing" target="_blank">${T('signup::보기')}</a>
                  </span>`
                : ''}
            </label>
          `;
        })()}
      </div>
      <div class="submit">
        <button type="button" class="btn_sign_up">${T('Sign up')}</button>
      </div>
      <div class="or">OR</div>
      <div class="fb_submit">
        <button type="button" class="btn_fb_login">${T('Sign up Facebook')}</button>
      </div>
    </div>
  `,
  appended: __(
    $.on('focusout', 'input[name="email"]', function (e) {
      $.val(e.currentTarget, G.to_lower($.val(e.currentTarget)));
    }),
    $.on('keyup', 'input[name="password"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.$delegateTarget, '.btn_sign_up'), 'click');
    }),
    $.on('click', '.go_terms', function (e) {
      e.preventDefault();

      if (G.collabo_type == 'line') {
        MuiF.openFrame(LineTerms2MuiF.frame, (frame, page, [tab]) => {
          frame.el_class = 'terms_modal_style terms_style';
          frame.hide_frame_button_position = 'RIGHT';
          frame.hide_frame_button_type = 'X';
          frame.is_modal = G.is_pc_size();
          tab.template = () =>
            T.lang != 'kr' ? LineTerms2TmplS.en_terms_220307() : LineTerms2TmplS.kr_terms_220307();
        });
      } else {
        MuiF.openFrame(TermsConditionsMuiF.frame, (frame, page, [tab]) => {
          frame.header_height = 0;
          frame.el_class = 'terms_modal_style terms_style';
          frame.hide_frame_button_position = 'RIGHT';
          frame.hide_frame_button_type = 'X';
          frame.is_modal = G.is_pc_size();
          tab.template = () => TermsConditionsTmplS.termsHtml({ lang: T.lang });
        });
      }
    }),
    $.on('click', '.go_privacy', function (e) {
      e.preventDefault();

      if (G.collabo_type == 'line') {
        MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
          frame.el_class = 'terms_modal_style terms_style';
          frame.hide_frame_button_type = 'X';
          frame.is_modal = G.is_pc_size();
          tab.template = () => {
            if (T.lang != 'kr') return LinePrivacyTmplS.en_privacy_220307();
            return LinePrivacyTmplS.kr_privacy_login();
          };
        });
      } else {
        MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
          frame.header_height = 0;
          frame.el_class = 'terms_modal_style terms_style';
          frame.hide_frame_button_position = 'RIGHT';
          frame.hide_frame_button_type = 'X';
          frame.is_modal = G.is_pc_size();
          tab.template = () => PrivacyPopUpTmplS.login();
        });
      }
    }),
    $.on('click', '.go_marketing', function (e) {
      e.preventDefault();

      MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
        frame.header_height = 0;
        frame.el_class = 'terms_modal_style terms_style';
        frame.hide_frame_button_position = 'RIGHT';
        frame.hide_frame_button_type = 'X';
        frame.is_modal = G.is_pc_size();
        frame.title = T('signup::Email News');
        tab.template = () => PrivacyPopUpTmplS.marketing();
      });
    }),
    $.on2('click', '.btn_sign_up', function (e) {
      const input_name = $.find1(e.$delegateTarget, 'input[name="name"]');
      const input_email = $.find1(e.$delegateTarget, 'input[name="email"]');
      const input_pw = $.find1(e.$delegateTarget, 'input[name="password"]');
      const input_agree_email = $.find1(e.$delegateTarget, 'input[name="agree_email"]');

      if (UtilS.is_empty($.val(input_name))) {
        _p.go(T('Name is empty'), $.alert, function () {
          input_name.focus();
        });
        return;
      }

      if (UtilS.is_special_str($.val(input_name))) {
        _p.go(T('my_info::Only Numbers and Text'), $.alert, function () {
          input_name.focus();
        });
        return;
      }

      if (UtilS.is_empty($.val(input_email))) {
        _p.go(T('Email is empty'), $.alert, function () {
          input_email.focus();
        });
        return;
      }
      if (!UtilS.is_valid_email($.val(input_email))) {
        _p.go(T('This email address is not available.'), $.alert, function () {
          input_email.focus();
        });
        return;
      }

      const { msg, result } = checkPassword($.val(input_pw), $.val(input_email));

      if (!result) {
        _p.go(msg, $.alert, function () {
          input_pw.focus();
        });
        return;
      }

      if (!$.find1(e.delegateTarget, 'input[name="check_agree"]:checked'))
        return $.alert(T('login::check_agree'));

      if (
        (T.lang == 'kr' || T.lang == 'jp') &&
        !$.find1(e.delegateTarget, 'input[name="check_privacy"]:checked')
      )
        return $.alert(T('signup::개인정보수집 이용에 동의해 주세요.'));

      if (
        (T.lang == 'kr' || T.lang == 'jp') &&
        !$.find1(e.delegateTarget, 'input[name="check_age14"]:checked')
      )
        return $.alert(T('signup::만14세 이상 가입이 가능합니다.'));

      return _p.go(
        $.post('/@api/mp/sign_up', {
          name: $.val(input_name),
          email: G.to_lower($.val(input_email)),
          password: $.val(input_pw),
          agree_email: input_agree_email ? input_agree_email.checked : false,
        }),
        function (data) {
          if (!data.result) return $.alert(data.msg || T('Sign up failed'));

          AdScriptGaF.signUpMpCreator();
          AdScriptFbF.signUp();
          AdScriptKakaoF.signUp();

          afterSignIn(data.user);

          if ($1('html.product.maker_page')) return $.frame.close({ is_session: data.result });

          return _p.go(
            void 0,
            function () {
              return $.frame.close({ is_session: data.result });
            },
            function () {
              $.frame.open(
                {
                  frame_name: 'mp.email_verify_frame',
                  closed: function () {
                    // 로그인 페이지 일 때
                    if ($1('html.login_page')) window.location.href = '/';
                  },
                },
                {
                  page_name: 'mp.email_verify_page',
                  tabs: [
                    {
                      tab_name: 'mp.email_verify_tab',
                      data_func: function () {
                        return data.user;
                      },
                    },
                  ],
                },
              );
            },
          );
        },
      );
    }),
    $.on('change', 'input[name="gender"]', function (e) {
      const current_label = $.closest(e.currentTarget, 'label');
      e.currentTarget.checked = true;
      $.add_class(current_label, 'on');
      $.remove_class($.siblings(current_label, 'label'), 'on');
    }),
    $.on('change', 'input[type="checkbox"]', function (e) {
      const current_label = $.closest(e.currentTarget, 'label');
      if (e.currentTarget.checked) $.add_class(current_label, 'on');
      else $.remove_class(current_label, 'on');
    }),
  ),
  showing: fbLoginRemove,
  showed: function (tab) {
    if (G.is_pc_size()) $.find1(tab, 'input[name="name"]').focus();
  },
});
export const defnMpLoginSignInTab = () => ({
  title: T('Sign in'),
  tab_name: 'mp_login_tab',
  template: () => legacyHtml`
    <div class="wrap">
      <div class="login_input">
        <div class="email control">
          <input type="email" name="email" placeholder="${T('login::Email_placeholder')}" value="" />
        </div>
        <div class="pw control">
          <input type="password" name="password" placeholder="${T('login::Password')}" value="" />
        </div>
      </div>
      <div class="submit">
        <button type="button" class="btn_login">${T('Sign in')}</button>
      </div>
      <div class="or">OR</div>
      <div class="fb_submit">
        <button type="button" class="btn_fb_login">${T('Sign in Facebook')}</button>
      </div>
      ${
        T.lang === 'kr'
          ? html`<div class="forgot">
              <span class="find_id">아이디 찾기</span>|<span class="reset_password">비밀번호 재설정</span>
            </div>`
          : html`<div class="forgot_password">
              <span>${T('login::Forgot Password?')}</span>
            </div>`
      }
    </div>
  `,
  appended: __(
    $.on('focusout', 'input[name="email"]', function (e) {
      $.val(e.currentTarget, G.to_lower($.val(e.currentTarget)));
    }),
    $.on('keydown', 'input[name="password"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.$delegateTarget, '.btn_login'), 'click');
    }),
    $.on2('click', '.btn_login', function (e) {
      const input_email = $.find1(e.$delegateTarget, 'input[name="email"]');
      const input_pw = $.find1(e.$delegateTarget, 'input[name="password"]');

      if (UtilS.is_empty($.val(input_email))) {
        _p.go(T('Email is empty'), $.alert, function () {
          input_email.focus();
        });
        return;
      }
      if (UtilS.is_empty($.val(input_pw))) {
        _p.go(T('Password is empty'), $.alert, function () {
          input_pw.focus();
        });
        return;
      }

      if (!UtilS.is_valid_email($.val(input_email))) {
        _p.go(T('This email address is not available.'), $.alert, function () {
          input_pw.focus();
        });
        return;
      }

      return _p.go(
        $.post('/@api/mp/login', {
          email: G.to_lower($.val(input_email)),
          password: $.val(input_pw),
        }),
        _p(closeFrameAfterSignIn, _p, input_email),
      );
    }),
    $.on2('click', '.forgot_password', function (e) {
      return $.frame.open({
        frame_name: 'mp.id_pw_find_frame',
        page_name: 'mp.id_pw_find_page',
        closed: function (X, user) {
          if (_p.v(user, 'email')) {
            $.val($.find1(e.delegateTarget, 'input[name="email"]'), user.email);
            $.find1(e.delegateTarget, 'input[name="password"]').focus();
          } else {
            $.find1(e.delegateTarget, 'input[name="email"]').focus();
          }
        },
      });

      // return $.frame.open({
      //   frame_name: 'mp.reset_password_frame',
      //   page_name: 'mp.reset_password_page'
      // });
    }),
    $.on2('click', '.forgot .find_id', async (e) => {
      await MuiF.openFrame(UserFindIdMuiF.frame, (frame, page, [tab]) => {
        frame.always_remove = true;
        frame.is_modal = G.is_pc_size();
        page.hide_frame_button_type = 'X';
        page.title = '아이디 찾기';
        frame.closed = (_, user) => {
          if (user?.email) {
            $.val($.find1(e.delegateTarget, 'input[name="email"]'), user.email);
            $.find1(e.delegateTarget, 'input[name="password"]').focus();
          } else {
            $.find1(e.delegateTarget, 'input[name="email"]').focus();
          }
        };
      });
    }),
    $.on2('click', '.forgot .reset_password', async (e) => {
      await MuiF.openFrame(UserResetPasswordMuiF.frame, (frame, page, [tab]) => {
        frame.always_remove = true;
        frame.is_modal = G.is_pc_size();
        page.hide_frame_button_type = 'X';
        page.title = '비밀번호 재설정';
        frame.closed = () => {
          $.find1(e.delegateTarget, 'input[name="email"]').focus();
        };
      });
    }),
  ),
  showing: fbLoginRemove,
  showed: function (tab) {
    if (G.is_pc_size()) $.find1(tab, 'input[name="email"]').focus();
  },
});

export const defnResetPasswordTab = () => ({
  title: 'Reset your password',
  tab_name: 'mp.reset_password_tab',
  template: _p.t$(
    '\
    .reset_pw_area\
      h2 Reset your password\
      p.txt1 Enter your email address and we will send you a link to reset your password.\
      .controls\
        .email.control\
          input[type="email" name="email" placeholder="Email" value=""]\
      .submit\
        button[type="button"].btn_reset_pw Reset password\
  ',
  ),
  appended: __(
    $.on('keyup', 'input[name="email"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.$delegateTarget, '.btn_reset_pw'), 'click');
    }),
    $.on2('click', '.btn_reset_pw', function (e) {
      const input_email = $.find1(e.$delegateTarget, 'input[name="email"]');

      if (util.validation.is_input_empty($.val(input_email))) {
        _p.go(T('Email is empty'), $.alert, function () {
          input_email.focus();
        });
        return;
      }
      if (!util.validation.is_valid_email($.val(input_email))) {
        _p.go(T('This email address is not available.'), $.alert, function () {
          input_email.focus();
        });
        return;
      }

      const lo = G.$location();
      const url = box.sel('store_url')
        ? box.sel('store_url')
        : sel('query.url', lo)
        ? sel('query.url', lo)
        : null;
      return _p.go(
        $.post('/@api/mp/reset_password', {
          email: G.to_lower($.val($.find1(e.$delegateTarget, 'input[name="email"]'))),
          url,
        }),
        function (data) {
          if (!data.result) return $.alert(T('Reset password failed'));
          return _p.go(T('Check your email for a link'), $.alert, function () {
            return $.frame.close();
          });
        },
      );
    }),
  ),
  showed: function (tab) {
    if (G.is_pc_size()) $.find1(tab, 'input[name="email"]').focus();
  },
});

export const defnIdPasswordFindTab = () => ({
  tab_name: 'mp.id_pw_find_tab',
  template: _p.t$(
    '\
    .id_pw_find_area\
      .reset_pw_area\
        h2 {{T("login::Reset your password")}}\
        p.txt1[lang="{{T.lang}}"] {{T("login::Enter your email address and we will send you a link to reset your password.")}}\
        .controls\
          .email.control\
            input[type="email" name="email" placeholder="{{T("login::Email_placeholder")}}" value=""]\
        .submit\
          button[type="button"].btn_reset_pw {{T("login::Reset password")}}\
      .id_pw_area\
        h2 휴대전화번호로 아이디 찾기\
        .controls\
          .name.control\
            input[type="text" name="name" placeholder="이름" value=""]\
          .mobile.control\
            input[type="text" name="mobile" placeholder="ex) 01012345678" value=""]\
        .submit\
          button[type="button"].btn_cert_code 인증번호 요청하기\
  ',
  ),
  appended: __(
    $.on('keyup', '.reset_pw_area input[name="email"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.$delegateTarget, '.btn_reset_pw'), 'click');
    }),
    $.on2('click', '.reset_pw_area .btn_reset_pw', function (e) {
      const input_email = $.find1(e.$delegateTarget, '.reset_pw_area input[name="email"]');

      if (util.validation.is_input_empty($.val(input_email))) {
        _p.go(T('Email is empty'), $.alert, function () {
          input_email.focus();
        });
        return;
      }
      if (!util.validation.is_valid_email($.val(input_email))) {
        _p.go(T('This email address is not available.'), $.alert, function () {
          input_email.focus();
        });
        return;
      }

      const lo = G.$location();
      const url = box.sel('store_url')
        ? box.sel('store_url')
        : sel('query.url', lo)
        ? sel('query.url', lo)
        : null;
      return _p.go(
        $.post('/@api/mp/reset_password', {
          email: G.to_lower($.val($.find1(e.$delegateTarget, '.reset_pw_area input[name="email"]'))),
          url,
        }),
        function (data) {
          if (!data.result) return $.alert(T('Reset password failed'));
          return _p.go(T('Check your email for a link'), $.alert, function () {
            return $.frame.close({
              email: G.to_lower($.val($.find1(e.$delegateTarget, '.reset_pw_area input[name="email"]'))),
            });
          });
        },
      );
    }),
    $.on('keyup', '.id_pw_area input[name="mobile"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.$delegateTarget, '.btn_cert_code'), 'click');
    }),
    $.on2('click', '.id_pw_area .btn_cert_code', function (e) {
      const name_el = $.find1(e.delegateTarget, '.id_pw_area input[name="name"]');
      const mobile_el = $.find1(e.delegateTarget, '.id_pw_area input[name="mobile"]');

      if (util.validation.is_input_empty($.val(name_el)))
        return _p.go('이름을 입력해 주세요.', $.alert, function () {
          name_el.focus();
        });

      if (util.validation.is_input_empty($.val(mobile_el)))
        return _p.go('번호를 입력해 주세요.', $.alert, function () {
          mobile_el.focus();
        });

      if (!util.check_phone_number(util.only_number($.val(mobile_el))))
        return _p.go('번호가 잘못되었습니다. 다시 입력해 주세요.', $.alert, function () {
          mobile_el.focus();
        });

      return _p.go(
        $.post('/@api/mp/cert/request', {
          name: $.val(name_el),
          mobile: util.only_number($.val(mobile_el)),
        }),
        function (data) {
          if (!data.result) return $.alert(data.msg);

          $.frame.add_page({
            page_name: 'mp.phone_cert_page',
            tabs: [
              {
                tab_name: 'mp.phone_cert_tab',
                data_func: function () {
                  return _p.mr($.val(name_el), util.only_number($.val(mobile_el)));
                },
              },
            ],
          });
        },
      );
    }),
  ),
  showed: function (tab) {
    if (G.is_pc_size()) $.find1(tab, '.reset_pw_area input[name="email"]').focus();
  },
});

export const defnPhoneCertificationTab = () => ({
  tab_name: 'mp.phone_cert_tab',
  template: __(
    _p.t(
      'name, mobile',
      '\
      .phone_cert_area\
        h2 인증번호 확인\
        p.txt1\
          |인증번호를 전송하였습니다.<br>\
          |휴대폰 문자 메시지를 확인해 주세요.\
        .controls\
          .code.control\
            input[type="text" name="code" value="" placeholder="인증번호 6자리"]\
            input[type="hidden" name="name" value="{{name}}"]\
            input[type="hidden" name="mobile" value="{{mobile}}"]\
        .submit\
          button[type="button"].btn_confirm 인증하기\
          button[type="button"].btn_resend 인증번호 재전송\
    ',
    ),
  ),
  appended: __(
    $.on('keyup', 'input[name="code"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.delegateTarget, '.btn_confirm'), 'click');
    }),
    $.on2('click', '.btn_confirm', function (e) {
      const code = $.val($.find1(e.delegateTarget, 'input[name="code"]'));

      if (!UtilS.is_number(code)) return $.alert('숫자만 입력해 주세요.');
      if (code.length != 6) return $.alert('인증번호 6자리 입력해 주세요.');

      return _p.go(
        $.post('/@api/mp/phone_certification', {
          code,
          name: $.val($.find1(e.delegateTarget, 'input[name="name"]')),
          mobile: $.val($.find1(e.delegateTarget, 'input[name="mobile"]')),
        }),
        function (data) {
          if (!data.result) return $.alert('인증 실패. 다시 시도해 주세요.');

          $.frame.add_page({
            page_name: 'mp.phone_cert_complete_page',
            tabs: [
              {
                tab_name: 'mp.phone_cert_complete_tab',
                data_func: function () {
                  return data.users;
                },
              },
            ],
          });
        },
      );
    }),
    $.on2('click', '.btn_resend', function (e) {
      return _p.go(
        $.post('/@api/mp/cert/request', {
          name: $.val($.find1(e.delegateTarget, 'input[name="name"]')),
          mobile: $.val($.find1(e.delegateTarget, 'input[name="mobile"]')),
        }),
        function (data) {
          if (!data.result) return $.alert(data.msg);
          _p.go('인증번호가 재발송 되었습니다.', $.alert, function () {
            $.find1(e.delegateTarget, 'input[name="code"]').focus();
          });
        },
      );
    }),
  ),
  showed: function (tab) {
    if (G.is_pc_size()) $.find1(tab, 'input[name="code"]').focus();
  },
});

export const defnPhoneCertificationCompleteTab = () => ({
  tab_name: 'mp.phone_cert_complete_tab',
  template: (users) => legacyHtml`
    <div class="phone_cert_complete_area">
      <h2>인증 완료</h2>
      <p class="txt1">검색된 회원님의 아이디 입니다.</p>
      <div class="users">
        <ul>
          ${strMap(([i, user]) => {
            return legacyHtml`
              <li>
                <label>
                  ${
                    user.email
                      ? legacyHtml`
                        <input type="radio" name="user" value="${user.email}" ${i == 0 ? ' checked' : ''} />
                      `
                      : ''
                  }
                  <span
                    >${
                      user.social_id ? `${user.email || '이메일 없음'}(페이스북 가입 계정)` : user.email
                    }</span
                  >
                </label>
              </li>
            `;
          }, L.ipp(users))}
        </ul>
      </div>
      <div class="buttons">
        <button type="button" class="btn_confirm">로그인</button>
        <button type="button" class="btn_reset_pw">비밀번호 변경하기</button>
      </div>
    </div>
  `,
  appended: __(
    $.on('click', '.btn_confirm', function (e) {
      const user_email = $.val($.find1(e.delegateTarget, 'input[name="user"]:checked'));
      $.frame.close({ email: user_email });
    }),
    $.on2('click', '.btn_reset_pw', function (e) {
      const user_email = $.val($.find1(e.delegateTarget, 'input[name="user"]:checked'));

      if (!user_email) return $.alert('아이디를 선택해 주세요.');

      const lo = G.$location();
      const url = box.sel('store_url')
        ? box.sel('store_url')
        : sel('query.url', lo)
        ? sel('query.url', lo)
        : null;
      return _p.go($.post('/@api/mp/reset_password', { email: user_email, url }), function (data) {
        if (!data.result) return $.alert(T('Reset password failed'));
        return _p.go(T('Check your email for a link'), $.alert, function () {
          return $.frame.close({ email: user_email });
        });
      });
    }),
  ),
});
// 사용안함
export const defnCertificationResetPasswordTab = () => ({
  tab_name: 'mp.cert_reset_password_tab',
  template: _p.t(
    'email, code',
    '\
    .phone_cert_complete_area\
      h2 비밀번호 변경\
      .controls\
        .control.email\
          label {{email}}\
          input[type="hidden" name="email" value="{{email}}"]\
          input[type="hidden" name="code" value="{{code}}"]\
        .control.password\
          input[type="password" name="password" value="" placeholder="비밀번호를 입력해 주세요."]\
      .buttons\
        button[type="button"].btn_reset_pw 비밀번호 변경하기\
  ',
  ),
  appended: __(
    $.on('keyup', 'input[name="password"]', function (e) {
      if (e.keyCode != 13) return;
      $.trigger($.find1(e.delegateTarget, '.btn_reset_pw'), 'click');
    }),
    $.on2('click', '.btn_reset_pw', function (e) {
      const pw_el = $.find1(e.delegateTarget, 'input[name="password"]');
      const email = $.val($.find1(e.delegateTarget, 'input[name="email"]'))?.trim();

      const { msg, result } = checkPassword($.val(pw_el), email);
      if (!result) {
        _p.go(msg, $.alert, function () {
          pw_el.focus();
        });
        return;
      }

      return _p.go(
        $.post('/@api/mp/cert_reset_password', {
          email,
          code: $.val($.find1(e.delegateTarget, 'input[name="code"]')),
          password: $.val(pw_el),
        }),
        function (data) {
          if (!data.result) return $.alert('비밀번호 변경 실패.<br>다시 시도해 주세요.');

          _p.go('비밀번호 변경 완료', $.alert, function () {
            $.frame.close({ email: data.user.email });
          });
        },
      );
    }),
  ),
});

export const defnEmailVerifyTab = () => ({
  tab_name: 'mp.email_verify_tab',
  data_func: (user) => user,
  template: (user) => {
    if (T.lang == 'kr') {
      return _p.t(
        'user',
        '\
        .email_verify_cont\
          .email {{user.email || ""}}\
          p.txt1\
            |인증 이메일을 보냈습니다. 이메일을 확인하고,<br>\
            |인증하기 버튼을 클릭하시면 인증이 완료됩니다.\
          p.txt2\
            sp 인증 이메일을 받지 못하셨나요? \
          .btn_resend_email_verify\
            button[type="button"] 인증 이메일 다시 보내기\
      ',
      )(user);
    } else if (T.lang == 'jp') {
      return _p.t(
        'user',
        '\
        .email_verify_cont\
          .email {{user.email || ""}}\
          p.txt1\
            |認証メールを送信しました。メールをご確認ください。<br>\
            |「メールアドレス認証」ボタンをクリックすると認証が完了します。\
          p.txt2\
            span 認証メールが届きませんでしたか？\
          .btn_resend_email_verify\
            button[type="button"] 認証メールをもう一度送る\
      ',
      )(user);
    } else {
      if (G.collabo_type != 'line') {
        return _p.t(
          'user',
          '\
          .email_verify_cont\
            .email {{user.email || ""}}\
            p.txt1\
              |To verify your email address,<br>\
              |click on the verification link in the email that you receive from Marpple.\
            p.txt2\
              div You may resend email by clicking on the link below.\
            .btn_resend_email_verify\
              button[type="button"] Resend verification email\
        ',
        )(user);
      } else {
        return _p.t(
          'user',
          '\
          .email_verify_cont\
            .email {{user.email || ""}}\
            p.txt1 To verify your email address, click on the verification link on the email that you have received from LINE FRIENDS Creator.\
            p.txt2 You may resend the email by clicking on the link below.\
            .btn_resend_email_verify\
              button[type="button"] Resend verification email\
        ',
        )(user);
      }
    }
  },
  appended: __(
    $.on('click', '.btn_resend_email_verify button', function () {
      const lo = G.$location();
      const url = box.sel('store_url')
        ? box.sel('store_url')
        : sel('query.url', lo)
        ? sel('query.url', lo)
        : null;
      _p.go($.post('/@api/mp/resend_verification', { url }), function (data) {
        if (data.result) {
          $.alert(T('login::Check your email.'));
        } else {
          $.alert(T('login::failed please retry'));
        }
      });
    }),
  ),
});
