import { compact, each, extend, go } from 'fxjs/es';
import { getFcanvasFlattenCvObjs } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { getBpfOfFcanvas, getCurrentBpcInMaker } from '../../../../../Maker/F/getSth.js';
import { NewMakerPropertyBpfConstantS } from '../../S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { makeCanvasTextured } from '../../../../../Maker/F/cylinder.js';
import { makeCanvasByUrl } from '../../../../../Canvas/S/util.js';
import { getImageUrlByZoom } from '../../../../../Maker/F/util.js';
import { NewMakerPropertyBpfS } from '../../S/Function/module/NewMakerPropertyBpfS.js';

export const bpcColorCode2Render = {
  isTarget: (fcanvas) => {
    const bpf = getBpfOfFcanvas(fcanvas);
    return bpf?.preview?.[NewMakerPropertyBpfConstantS.BPC_COLOR_CODE2_RENDER];
  },
  getColorCode2: () => {
    const bpc = getCurrentBpcInMaker();
    return bpc?.color_code2;
  },
  renderCvPreviewByShadeMaterial: async (shade_material, zoom) => {
    if (!shade_material) return;
    const { design_url, color_code, original_color_code } = shade_material;
    if (original_color_code === color_code) return;
    const design_canvas = await makeCanvasTextured(
      await makeCanvasByUrl(getImageUrlByZoom(design_url, zoom)),
      color_code,
    );

    if (zoom > 0.5) {
      return (
        await NewMakerPropertyBpfS.bpcColorCode2Render.getShadeCanvas({
          canvas: design_canvas,
        })
      ).toDataURL();
    } else {
      return design_canvas.toDataURL();
    }
  },
  makeShadeMaterialData: ({ color_code, shade_data_url, design_data_url }) => {
    return {
      color_code,
      shade_data_url,
      design_data_url,
    };
  },
  uploadShadeMaterialData: async (cv_preview) => {
    const { url: design_url } = await $.uploadFileToUrl(
      cv_preview._data.shade_material.design_data_url,
      'cv_preview',
    );
    cv_preview._data.shade_material.design_data_url = null;
    cv_preview._data.shade_material.design_url = design_url;
    // const { url: shade_url } = await $.uploadFileToUrl(
    //   cv_preview._data.shade_material.shade_data_url,
    //   'cv_preview',
    // );
    // cv_preview._data.shade_material.shade_data_url = null;
    // cv_preview._data.shade_material.shade_url = shade_url;
  },
  setColorCode2: function (cv_obj, bpc) {
    const _datas = go(
      [cv_obj._data?.cv_text_image_attrs?._data, cv_obj._data?.cv_image_attrs?._data, cv_obj._data],
      compact,
    );
    go(
      _datas,
      each((_data) => {
        if (_data.cv_type === 'cv_text_image') {
          extend(_data, {
            only_flex: false,
            press_color_id: `bpc_${bpc.id}`,
            press_color_code: bpc.color_code2,
            press_color_name: bpc.name,
            press_color_name_en: bpc.name_en,
            press_color_name_jp: bpc.name_jp,
          });
        }
        _data.color_code2 = bpc.color_code2;
      }),
    );
  },
  applyColor: function (cv_obj) {
    const color_code2 = cv_obj._data?.color_code2;
    if (!color_code2) return;
    const cv_type = cv_obj._data.cv_type;

    if (['cv_image', 'cv_text_image', 'cv_pattern', 'cv_text_image_pattern'].includes(cv_type)) {
      const color_filter = new fabric.Image.filters.Tint({
        color: color_code2,
        opacity: 1.0,
      });
      cv_obj.filters = [color_filter];
    }
    if (cv_obj.applyFilters && cv_obj.filters?.length) {
      return new Promise((resolve) => {
        cv_obj.applyFilters((cv_obj) => {
          cv_obj.filters = [];
          resolve();
        });
      });
    }
  },
  applyAll: async function (fcanvass, bpc) {
    await go(
      fcanvass,
      each(async (fcanvas) => {
        await go(
          getFcanvasFlattenCvObjs(fcanvas),
          each(async (cv_obj) => {
            NewMakerPropertyBpfS.bpcColorCode2Render.setColorCode2(cv_obj, bpc);
            await bpcColorCode2Render.applyColor(cv_obj);
          }),
        );
        fcanvas.renderAll();
      }),
    );
  },
};
