import { $delegate, $find, $qs, $setAttr, $setText, $toggleClass } from 'fxdom/es';
import { go, tap, each } from 'fxjs/es';
import { pushLoginStack } from '../../../../../Creator/Login/F/fs.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppStoreF } from '../../../Store/F/Function/module/MShopAppStoreF.js';

const changeHeart$ = (target) => {
  const { already_like } = target.dataset;
  $toggleClass('app-store-like__heart--on', $find('.app-store-like__heart', target));
  target.dataset.already_like = already_like != 'true';
};

const setChangeHeart$ =
  (already) =>
  ({ store_id }) => {
    const like_el = $qs(`.app-store-like__btn[data-already_like="${already}"][data-store_id="${store_id}"]`);
    if (like_el) changeHeart$(like_el);
  };

const replaceHeart = async (is_persisted) => {
  if (window.box.sel('is_user->id') && window.box.sel('is_user->type') != 'TEMP' && is_persisted)
    return go(await MShopAppStoreF.getMyHeartList(true), ([activated_likes, deactivated_likes]) => {
      each(setChangeHeart$(false), activated_likes);
      each(setChangeHeart$(true), deactivated_likes);
    });
};

const appListener = async function (e) {
  try {
    const { is_focused } = JSON.parse(e.data);
    if (is_focused) await replaceHeart(true);
  } catch (err) {}
};

const resetLikeBackForward = () => {
  if (!MShopUtilF.isApp()) return UtilF.initBackForward(replaceHeart);
  window.addEventListener('message', appListener);
  document.addEventListener('message', appListener);
};

export const updateLikeHeartAndCount = async (tab_el$) =>
  go(
    tab_el$,
    tap(resetLikeBackForward),
    $delegate('click', '.app-store-like__btn', async (e) => {
      if (!window.box.sel('is_user->id') || window.box.sel('is_user->type') == 'TEMP')
        return (
          (await $.confirm(T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?'))) &&
          (MShopUtilF.isApp()
            ? pushLoginStack()
            : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`))
        );
      const { already_like, store_id } = e.currentTarget.dataset;
      changeHeart$(e.currentTarget);
      try {
        const like_count = await MShopAppStoreF.updateLike(already_like === 'true', store_id);
        go(
          e.currentTarget,
          $setAttr({ 'data-already_like': already_like !== 'true' }),
          $toggleClass('active'),
          $find('.count'),
          $setText(like_count),
        );
      } catch (error) {
        changeHeart$(e.currentTarget);
      }
    }),
  );
