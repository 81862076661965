import { sel } from 'fxjs/es';
import { AlertTmplS } from '../../../Alert/S/Tmpl/module/AlertTmplS.js';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { UserJwtF } from '../../../User/Jwt/F/Function/module/UserJwtF.js';

export async function closeFrameAfterSignIn(data, input_email, seller_code) {
  if (!data.result)
    return _p.go($.alert(AlertTmplS.alertTmpl(data)), function () {
      input_email && input_email.focus();
    });

  if (data.user_dormant_url) {
    if (MShopUtilF.isCreatorApp()) {
      MShopUtilF.postMessage({
        screen: {
          type: 'empty',
          uri: `/${T.lang}${data.user_dormant_url}`,
        },
      });
      return;
    }

    return (window.location.href = data.user_dormant_url);
  }

  const lo = G.$location();
  const redirect_uri = lo.query.url ? location.search.replace('?url=', '') : '/';

  if (MShopUtilF.isApp()) {
    // 셀러 코드 (셀러 신청 이메일 마플샵 시작 링크)
    if (seller_code && data.redirect_url) {
      // 셀러 코드 있는 로그인 페이지로 이동
      return UserJwtF.loginMessage(data.user, {
        name: 'SellerStudioScreen',
        params: { uri: data.redirect_url },
      });
    } else if (seller_code && sel('store.domain_name', data)) {
      return UserJwtF.loginMessage(data.user, {
        name: 'SellerStudioScreen',
        params: { uri: `/${T.lang}/${sel('store.domain_name', data)}/settings` },
      });
    }

    if (redirect_uri.indexOf('/checkout') > -1) {
      return UserJwtF.loginMessage(data.user, {
        name: 'EmptyScreen',
        params: { uri: redirect_uri },
      });
    }

    return UserJwtF.loginMessage(data.user);
  }

  if (seller_code && data.redirect_url) {
    return (window.location.href = data.redirect_url);
  } else if (seller_code) {
    return (window.location.href = sel('store.domain_name', data)
      ? `/${T.lang}/${sel('store.domain_name', data)}/settings`
      : '/');
  }

  if (sel('store.domain_name', data))
    return (window.location.href = sel('store.domain_name', data)
      ? `/${T.lang}/${sel('store.domain_name', data)}`
      : '/');

  if (!$1('html.login_page')) return $.frame.close({ is_session: data.result });

  window.location.replace(redirect_uri);
}

export function pushLoginStack(is_join) {
  MShopUtilF.postMessage({
    navigate: {
      name: 'EmptyScreen',
      params: { uri: `/${T.lang}/login${is_join ? '?is_join=true' : ''}` },
    },
  });
}
