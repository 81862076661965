export default {
  home: {
    home_1_0: '1個から作られます！',
    home_1_1: '団体グッズもリーズナブルな価格で作ってみてください！',
    home_1_2: '団体グッズもリーズナブルな価格で作ってみてください！',
    home_2_1: 'リピートが多い商品を集めてみました',
    home_2_2: 'もっと見る',
    home_3_1: 'マープルラーのリアルタイムレビュー',
    home_4_1: '{{count}}個の新しい商品が到着しました！',
    home_4_2: '最近たくさんカスタムしています',
    home_5_1: 'マープルが厳選したハイクオリティブランド',
    home_6_1: '最近人気のカスタム, あなたもできます!',
    home_7_1: '{{cate_list_name}} もっと見る',
  },

  keyword: {
    kwd_new: '{{month}}月 新商品',
    kwd_best: 'マープルベスト',
    kwd_1_1: '団体グッズ',
    kwd_1_2: '1個から作成',
    kwd_1_3: '企業キット',
    kwd_1_4: 'ファングッズ',
    kwd_1_5: '販促商品',
    kwd_1_6: 'ESG グッズ',
    kwd_1_7: 'アクリルグッズ',
  },

  navigation: {
    navigation_01: '円~',
    navigation_02: '全商品',
    navigation_03: ' カテゴリー',
  },
  pro_editor: {
    alert: {
      title: {
        file_size: 'ファイルのサイズをご確認ください。',
        file_save: '保存方法をご確認ください。',
        template_use: '指定されたテンプレートを活用してください。',
        layer: 'レイヤーをご確認ください。',
        layer_stand: 'stand レイヤーをご確認ください。',
        layer_ring: 'ring レイヤーをご確認ください。',
        layer_cut: 'cut レイヤーをご確認ください。',
        layer_cut_not_single: 'カットラインパスをご確認ください。',
        layer_cut_not_path_type: 'カットラインパスをご確認ください。',
        layer_cut_not_closed: 'カットラインパスをご確認ください。',
        art_board: 'アートボードをご確認ください。',
        download: 'ファイルのダウンロード中にエラーが発生しました。',
        unknown: '未知のエラーが発生しました。<br>カスタマーセンターまでお問い合わせください。',
        dev: 'ファイルの分析中エラーが発生しました。<br>カスタマーセンターまでお問い合わせください。',
      },
      text: {
        file_size: ' 以下の SVG ファイルのみアップロードできます。',
        file_save: 'ファイル保存時 SVG Optionsで<br>Responsiveのチェックを解除してください。',
        template_use:
          'テンプレートをダウンロード後、図案を製作してアップロードしてください。<br>ガイドに従っていないSVGファイルはアップロードできません。',
        layer:
          'テンプレートファイルのレイヤーを追加、削除したり<br>レイヤー名を任意に変更した場合、ファイルはアップロードされません。',
        layer_stand:
          '提供される四角形パスを追加、削除される場合や<br>他の要素を追加される場合、ファイルがアップロードされません。',
        layer_ring:
          '提供される リングパスを追加、削除、変形される場合や<br>他の要素を追加される場合、ファイルがアップロードされません。',
        layer_cut:
          'カットラインパスは途切れず1本の線に繋ぐ必要があり<br>カットラインパスを製作しない場合、ファイルがアップロードされません。',
        layer_cut_not_single:
          'カットラインパスは1つだけ入れてください。<br>カットラインパスがないか、<br>2つ以上ある場合はファイルがアップロードされません。',
        layer_cut_not_path_type:
          'カットラインパスを Compound Path に変更してください。<br>Illustrator で、ナイフエッジ パスを右クリックし<br>→ Make Compound Pathを選択してください。',
        layer_cut_not_closed: 'カットラインパスを一つの<br>途切れない線につなげてください。',
        art_board:
          '提供されるアートボードのサイズは変更できず<br>全てのデザインがアートボード内に収まるようにしてください。',
      },
    },
  },

  review: {
    review_01: 'マープルのリアルタイムレビュー',
    review_02: 'フォトレビューを見る',
    review_03: '全商品',
    review_04: 'もっと見る',
    review_05: 'レビューをもっと見る ({{count}})',
    detail: {
      detail_01: 'レビュー詳細',
    },
    write: {
      write_01: 'レビュー作成',
      write_02: 'レビュー作成完了',
      write_03: '商品レビューを作成してください。',
      write_04:
        'マープルでのカスタム経験、作られた商品に対するレビューをお願いします。素敵なレビューを作成していただければポイントを差し上げます！',
      write_05: 'SNS共有',
      write_06:
        'SNSに <span style="color: #FF6B00"> #마플 #마플커스텀 #내가만든커스텀</span> ハッシュタグと一緒にフォトレビューを投稿していただければ、選定後<span style="color: #FF6B00; padding: 0 4px;">300P</span>追加のポイントを貯められます！<br />ハッシュタグがない場合や誤ったリンク、非公開アカウントはポイントをを貯めることができません。',
      write_07: 'SNSに共有されたリンクを入力してください。',
      write_08: '作成したレビューは、マープル広報コンテンツとして活用される場合があります。(必須)',
      write_09: '商品はいかがでしたか？ 星で評価をしてください。',
      write_10: 'レビューを作成していただきありがとうございます。',
      write_11: '（最低5文字以上作成）',
      write_04_01:
        'マープルでのカスタム経験、作られた商品に対するレビューをお願いします。素敵なレビューを作成していただければポイントを差し上げます！',
      write_06_01:
        'SNSに <span style="color: #FF6B00">#마플 #마플커스텀 #내가만든커스텀</span><br> をハッシュタグと一緒にフォトレビューを投稿していただければ、選定後<br><span style="color: #FF6B00; padding-right: 4px;">300P</span>追加のポイントを貯められます！<br><p style="margin:0; padding-top: 4px;">ハッシュタグがない場合や誤ったリンク、非公開アカウントは<br>ポイントをを貯めることができません。</p>',
      write_08_01: '作成したレビューは、マープル広報コンテンツとして<br>活用される場合があります。(必須)',
      write_12: 'レビューを作成すると、「配送完了」に変更されます。',
      write_13: 'No. {{no}}',
    },
    review_06: '今ホットなレビュー',
    review_07: '全体レビューを見る',
    review_08: '今レビューを書いてポイントをもらってください 🎉',
  },
  cell: {
    review: {
      read_more: 'もっと見る',
      category_more: '{{name}} もっと見る',
      size_info: '{{size}} 購入',
    },
    product: {
      prod_1_1: '評点',
      prod_1_2: 'レビュー',
      prod_1_3: '1個から注文可能！',
      prod_1_4: '{{count}}장 마다 추가로 {{price}}원씩 할인',
      prod_1_5: '{{n}}個',
      prod_1_6: '{{n}}個以上',
      prod_1_7: '{{n}}%割引',
      prod_1_8: '定価',
      prod_1_9: '{{n}}個以上製作可能',
      prod_1_10: '別途お問い合わせ',
    },
  },
  search: {
    search_01: '2,000個以上のカスタム商品を簡単に探してみてください。',
    search_02: 'おすすめ検索キーワード',
    search_03: 'すべてのカスタムをマープルで',
    search_04:
      '<span style="color: #ff6b00">{{keyword}}</span>について<span style="color: #ff6b00">{{count}}個</span>の{{- br}}検索結果があります。',
    search_07: '最近の検索キーワード',
    search_09: '検索キーワードが正確か確認してください。{{- br}}特定の単語ではなく商品名を入力してください。',
    search_10: '最近の検索キーワードの履歴がありません。',
    search_11: '統合',
    search_12: '商品',
    search_13: 'コンテンツ',
    search_14: 'レビュー',
    search_15: 'その他製品',
    search_17: 'マープルのリアルタイムレビュー',
    search_16: 'カスタムノウハウ',
    search_18: '商品がありません。',
    search_19: 'コンテンツがありません。',
    search_20: 'レビューがありません。',
  },
  common: {
    show_all: '全体を見る',
    remove_all: '全てを削除',
    currency: '{{price}}円',
    quantity: '{{quantity}}個',
    view_more: 'もっと見る',
    delete: '削除',
    review_count: 'レビュー {{count}}',
    show_all_name: '{{name}} 全体を見る',
  },
  omp_layout: {
    left: {
      left_01: '商品を作る',
      left_02: '新商品／ベスト',
      left_03: '全商品',
      left_04: '企業グッズ相談',
      left_04_mo: '企業グッズ',
      left_05: 'リアルタイムレビュー',
      left_06: 'カスタムノウハウ',
      sub_01: '会員登録',
      sub_02: 'ログイン',
      sub_03: '1：1お問い合わせ',
      sub_04: 'カスタマーセンター',
      sub_05: '初めてのご利用ですか？',
      sub_06: 'マイページ',
      sub_07: 'ログアウト',
      left_07: 'リアルタイムレビュー',
      all_product: '全商品',
      sub_08: '',
      left_08: '団体販促/ノベルティ',
      left_08_01: '団体販促',
    },
    bottom_bar: {
      txt_01: 'ホーム',
      txt_02: '検索',
      txt_03: '新商品／ベスト',
      txt_04: 'レビュー',
      txt_05: 'マイページ',
    },
    mp_title: 'マープル カスタム {{cate_list_name}}',
    title: 'マープル',
    sub_menu_all: 'すべての製品',
    sub_menu_all_mo: 'すべて',
    temp_cate_list_name: 'デジタル/テク',
  },
  auth: {
    login: {
      login_01: 'ログイン',
      login_02: 'メールアドレス',
      login_03: 'パスワード',
      login_04: 'ID検索',
      login_05: 'パスワード再設定',
      login_01_01: 'フェイスブックでログイン',
    },
    signup: {
      signup_01: '会員登録',
      signup_02: '名前',
      signup_03: 'メールアドレス',
      signup_04: 'パスワード (10~64文字)',
      signup_05: '利用規約同意',
      signup_06: '個人情報収集及び利用同意',
      signup_07: '満14歳以上です',
      signup_08: 'メールマーケティング受信同意(選択)',
      signup_09: '見る',
      signup_10: '',
      signup_01_01: 'フェイスブックではじめる',
    },
    reset_password: {
      reset_password_01: 'パスワード再設定',
      reset_password_02: 'メールアドレス',
      reset_password_03: 'メールアドレスを入力してください。',
      reset_password_04: 'パスワード再設定メール送信',
      reset_password_05: 'パスワード変更メール送信',
      reset_password_06:
        '入力されたメールアドレスにパスワード変更案内メールを送信しました。<br>案内メールが数分以内に表示されない場合は、スパムフォルダをご確認ください。',
      reset_password_07: '',
      reset_password_08: 'パスワード',
      reset_password_10: '10文字以上 / 英数字、特殊文字を含む',
      reset_password_11: 'パスワード確認',
      reset_password_12: 'パスワードを再入力してください。',
    },
    find_id: {
      find_id_02: '携帯電話認証',
      find_id_03: '名前',
      find_id_04: '名前を入力してください。',
      find_id_05: '連絡先',
      find_id_06: '連絡先を入力してください。',
      find_id_07: '認証番号リクエスト',
      find_id_08: '認証番号6桁を入力してください。',
      find_id_01: 'ID検索',
      find_id_09: '認証番号の再送信',
      find_id_10: 'ID検索結果',
      find_id_11: '会員様の情報と一致するIDリストです。',
      find_id_12: 'メールアドレスなし',
      find_id_13: 'フェイスブックアカウント',
    },
    email_verify: {
      email_verify_01:
        '認証メールを送りました。<br>メールを確認し、認証するボタンをクリックすると認証が完了します。',
      email_verify_02: '認証メールを受け取っていませんか？',
      email_verify_03: '認証メールを再送信',
      email_verify_04: 'メール認証を行う',
      email_verify_05: 'メールをご確認ください。',
      email_verify_06: '数分以内に表示されない場合は、<br>スパムフォルダを確認してください。',
      email_verify_07: 'ようこそ！メール認証済みです。',
      email_verify_08: 'メールが認証されていません。',
    },
    need_verify: {
      need_verify_01: '認証ボタンをクリックすると認証が完了します。',
      need_verify_02: 'メール認証が必要です。',
      need_verify_03: 'メールアドレスの変更',
    },
    change_email: {
      change_email_01: 'メールアドレスを変更する',
      change_email_02: '現在入力されたメールアドレス',
      change_email_03: '新しいメールアドレスを入力してください',
      change_email_04: '既存のメールアドレスを入力してください',
      change_email_05: 'メールアドレス変更',
      change_email_06: '変更されました。',
    },
  },
  error: {
    common: {
      error_01: 'エラーが発生しました。',
    },
    auth: {
      error_01: '10文字以上入力してください。 / 英語、数字、特殊文字使用可能',
      error_03: '同じ文字を過度に連続して使用することはできません。',
      error_02: 'セキュリティのため、連続した文字は使用できません。',
      error_04: '英文、数字、特殊文字を組み合わせて設定してください。',
      error_05: 'メールの形式を確認してください。',
      error_06: '名前を入力してください。',
      error_07: '利用規約に同意してください。',
      error_08: '個人情報収集の利用に同意してください。',
      error_09: '満14歳以上登録が可能です。',
      error_06_01: '名前には特殊文字を含めることはできません。',
      error_10: 'パスワードにIDを含めることはできません。',
      error_11: '退会済みのIDです。',
      error_12: '登録済みのIDです。',
      error_13: '会員登録に失敗しました。',
      error_14:
        'メールまたはパスワードを<br>間違って入力しました。<br>入力された内容を<br>もう一度ご確認ください。',
      error_15: 'パスワードの再設定不可',
      error_16: 'パスワード再設定メールを送信できません。<br/>メールアドレスをご確認ください。',
      error_17: 'パスワード再設定メールの送信に失敗',
      error_18:
        'パスワード再設定メールを1分以内に<br/>再送信できません。<br/>しばらくしてからもう一度お試しください。',
      error_19: 'コードが生成されていません。',
      error_20: 'メール送信エラー',
      error_21: 'メールが送信されませんでした。<br/>しばらくしてからもう一度お試しください。',
      error_22: 'パスワードをご入力ください。',
      error_23: 'パスワードが一致しません。',
      error_25: 'パスワード欄が作成されていません。',
      error_24: 'パスワードの確認欄が作成されていません。',
      error_26: '現在のパスワードと同じパスワードは<br/>使用できません。',
      error_15_01: '認証コードが有効ではありません。',
      error_15_02: '入力された情報と一致するアカウントがありません。',
      error_27: 'パスワード再設定完了',
      error_27_01: 'パスワードが変更されました。',
      error_28: '認証番号の送信不可',
      error_28_01: '認証番号を送信できません。<br/>連絡先をご確認ください。',
      error_29: '認証番号6桁を入力してください。',
      error_30: '携帯番号形式ではありません。',
      error_31: 'IDが見つかりません。',
      error_32: '認証番号の再送信不可',
      error_33: '転送に失敗しました。',
      error_33_01: 'しばらくしてからもう一度お試しください。',
      error_34: '認証失敗',
      error_34_01: '認証番号が一致しません。',
      error_35: 'フェイスブックのログインローディングが遅いです。 しばらくしてからもう一度お試しください。',
      error_36: 'メールを選択してください。',
    },
    code: {
      code_401: 'ログインが必要です。',
      code_400: '間違ったリクエストです。',
    },
    review: {
      write_01: 'アップロード可能な画像ファイルは、JPEGまたはPNG、GIFファイルです。',
      write_02: '{{max}}枚以下でアップロードしてください。',
      write_03: 'ファイルの容量が大きすぎます。<br>{{mb}}MB以下でアップロードしてください。',
      write_04: '画像をアップロードしてください。',
      write_05: '最大2,000文字以内で作成してください。',
      write_06: 'レビュー活用規約に同意してください。',
      write_07: 'SNSのアドレスが間違っています。確認してください。',
      write_08: '購入履歴を確認できません。',
      write_09: 'データが有効ではありません。',
    },
    order: {
      order_01: '購入履歴を確認できません。',
    },
  },
  product: {
    list: {
      list_1_1: 'ブランド',
      list_2_1: 'カラー',
      list_3_1: 'サイズ',
      list_4_1: 'おすすめ順',
      list_5_1: '価格が低い順',
      list_6_1: '価格が高い順',
      list_7_1: 'フィルター検索',
      list_8_1: '{{- checked_cate_list_name}} カスタムしてみてください',
      list_9_1: '全商品',
      list_10_1: 'マープル{{name}}1位',
      list_11_1: '累積販売量9,999+',
      list_11_2: '1~2度印刷',
      list_12_1: '{{price}}円から',
      list_13_1: '{{price}}円',
      list_14_1: '確認',
      list_15_1: 'カテゴリー選択',
      list_16_1: 'フィルター',
      list_17_1: 'フィルターに適した商品がありません。',
      list_18_1: 'ご希望のフィルターを選択してください。',
      list_19_1: '商品を見る',
      list_20_1: '初期化',
    },
    new_best: {
      new_best_1_1: 'この商品をカスタムする',
      new_best_2_1: '新しいカスタムをご体験ください。',
      new_best_3_1: 'このアイテムもカスタム可能です。',
      new_best_4_1: '最近一番たくさんカスタムしてます。',
      new_best_5_1: 'カスタムの定番、ベストアイテム',
      new_best_6_1: '新商品／ベスト',
      new_best_7_1: 'ベスト',
    },
  },
  my_page: {
    layout: {
      layout_01: 'マイページ',
    },
    order: {
      order_01: '購買履歴',
      order_02: '支払い確認',
      order_03: 'ご注文を確認しました。<br>お支払い確認後、次のステップに進みます。',
      order_04: '支払い完了',
      order_05: '入金が確認されました。',
      order_06: '製作準備中',
      order_07: 'ご注文いただいたカスタム仕様を確認し、<br>制作を準備しています。',
      order_08: '製作中',
      order_09: 'ご注文いただいたカスタム商品を<br>制作しております。',
      order_10: '配送準備中',
      order_11: '商品の配送の手続きをしております。',
      order_12: '配送中',
      order_13: '商品が発送され<br>お客様へ配送中です。',
      order_14: '配送完了',
      order_15: '配送が完了しました。<br>配送完了日を含めて7日以内に<br>交換/返品の受け付けが可能です。',
      order_16: '1ヶ月の注文履歴',
      order_17: '注文日 / 注文番号',
      order_18: '購入商品情報',
      order_19: '金額',
      order_20: 'ステータス',
      order_21: '購入',
      order_22: '最近1ヶ月以内に購入履歴がありません。',
      order_01_01: '注文取り消し',
      order_01_02: '注文キャンセル要請',
      order_23: 'ご注文の商品がありません。',
      detail_01: 'Step {{step}}',
      order_24: '支払い確認中です。',
      order_25: '製作準備中です。',
      order_26: '製作中です。',
      order_27: '商品をご用意しております。',
      order_28: '配送中です。',
      order_29: '配送が完了しました。',
      order_30: '注文番号{{order_id}}',
      detail_02: '',
      detail: {
        detail_01: 'ステップ {{step}}',
        detail_02: '支払い方法の変更',
        detail_03: '注文をキャンセルする',
        detail_04: '追加支払い',
        detail_05: '注文商品情報',
        detail_07: '購入商品情報',
        detail_06: 'サイズ',
        detail_08: 'カラー',
        detail_09: '数量',
        detail_10: '金額',
        detail_11: 'No.{{up_c_id}}',
        detail_12: '印刷:{{print_face}}',
        detail_13: '注文情報エクセルでダウンロードする',
        detail_14: '取引明細書印刷',
        detail_15: '製作要請事項',
        detail_16: 'お届け先情報',
        detail_17: '支払い金額',
        detail_18: '支払い情報',
        detail_19: '特別な要請事項がありません。',
        detail_20: '支払い金額',
        detail_21: '送料',
        detail_21_01: '遠方地域の場合は別途運賃がかかります',
        detail_22: 'クーポン',
        detail_23: 'ポイント',
        detail_24: '追加金額',
        detail_25: '追加送料',
        detail_26: '支払い金額',
        detail_27: '注文キャンセル金額',
        detail_28: 'まとめ送料',
        detail_29: '数量',
        detail_25_01: '追加配送割引',
        detail_30: '入金確認中',
        detail_31: '支払い方法',
        detail_32: '承認時間',
        detail_33: '支払い金額',
        detail_34: '支払い予定金額',
        detail_35: '証明',
        detail_36: '支払い金額',
        detail_37: '未決済額',
        detail_38: '払い戻しポイント',
        detail_39: '払い戻し金額',
        detail_24_01: '追加割引',
        detail_40: '宅配便の受け取り',
        detail_41: 'クイック配送',
        detail_42: 'まとめ配送',
        detail_43: '訪問受領',
        detail_44: '注文キャンセルの要請が失敗しました。',
        detail_45: 'お客様の注文キャンセルの要請が完了しました。<br>確認後、キャンセル処理をいたします。',
        detail_46: '注文情報が変更され、<br>追加の支払いが必要です。<br>今すぐお支払いしますか？',
        detail_12_01: '印刷',
        detail_47: '購入履歴詳細',
      },
      order_31: '再購入する',
      order_32: 'レビューを書く',
      order_29_01: '注文キャンセル',
      order_29_02: '注文キャンセル確認中',
      order_33: '注文番号',
      order_34: '',
    },
    wish: {
      wish_01: 'お気に入り商品',
      wish_02: '後で購入する商品',
      wish_03: 'お気に入り商品',
      wish_04: '後で購入する商品がありません。',
      wish_05: '後で購入する商品を {{- br}}ご希望の時間に簡単に探してみてください。',
      wish_06: 'お気に入り商品',
      wish_07: 'お気に入り商品がありません。',
      wish_08: '自分の好みに合う商品を選んでみて、 {{- br}}自分だけのカスタム体験をお楽しみください。',
      wish_09: 'このような商品はいかがですか？',
      wish_10: 'すべてカートに入れる',
      wish_11: 'カートに入れる',
    },
    point: {
      point_01: 'ポイント',
      point_02: '<span style="color: #FF6B00">{{user_point}}</span> ポイントを保有されています。',
      point_03: 'ポイント貯め方',
      point_04: '購入確定後、購入金額の1%が積み立てられます。',
      point_05: 'レビュー作成時にポイントが最大300P支給されます。',
      point_06: 'SNS共有時に追加で500Pが支給されます。',
      point_07: 'ポイント政策',
      point_08: '最小使用金額は制限なく1円単位から使用できます。',
      point_09: '詳細は{{-FAQ}で確認できます。',
      point_10: 'ポイントの積み立て及び使用',
      point_11: '日付',
      point_12: '積み立ておよび使用履歴',
      point_13: 'ポイントの積み立てや使用履歴がありません。',
    },
    my_review: {
      my_review_01: 'レビュー',
      my_review_02: '作成可能なレビュー',
      my_review_03: '作成したレビュー',
      my_review_04: '作成可能なレビューがありません。',
      my_review_05: 'レビューは書かれていない。',
    },
    my_info: {
      my_info_01: '個人情報',
      my_info_02: '基本会員情報',
      my_info_03: 'メールアドレス',
      my_info_04: 'メールアドレスの変更',
      my_info_05: 'パスワード',
      my_info_06: 'パスワード変更のメールを受け取る',
      my_info_07: 'ポイント',
      my_info_08: 'メールのお知らせを受信する',
      my_info_09: 'メールマーケティング受信同意(選択)',
      my_info_10: 'はい。',
      my_info_11: '受けません。',
      my_info_12: '追加会員情報',
      my_info_13: 'ニックネーム',
      my_info_14: 'ニックネームを入力してください。',
      my_info_15: '生年月日',
      my_info_16: 'YYYYMMDD',
      my_info_17: '名前',
      my_info_18: '携帯電話',
      my_info_19: '- なしで数字だけ入力してください。',
      my_info_20: '性別',
      my_info_21: '男',
      my_info_22: '女',
      my_info_23: '保存する',
      my_info_24: '会員退会',
      my_info_25: '名前を入力してください。',
      my_info_26: 'ユーザー情報がありません。 ログイン後、再度ご利用ください。',
      my_info_27: '変更されました。',
      my_info_28: '退会できませんでした。 もう一度お試しください。',
      my_info_29: '本当に退会しますか？',
    },
    landing: {
      landing_01: '<span>{{name}}様、</span><span>お会いできて嬉しいです👋</span>',
      landing_02: '個人情報の変更',
      landing_03: '購買履歴',
      landing_04: '{{count}} 件',
      landing_05: 'ポイント',
      landing_06: '{{point}} P',
      landing_07: 'レビュー',
      landing_08: '{{count}} / <span style="color: #858585">{{all_count}}</span> 個',
      landing_09: 'お気に入り商品',
      landing_10: '{{count}} 個',
      landing_11: 'このアイテムもカスタム可能です。',
      landing_12: '1：1お問い合わせ',
      landing_13: '個人情報',
      landing_14: 'カスタマーセンター',
      landing_15: 'ログアウト',
      landing_16: 'MYページ',
      landing_17: 'お客様サービス',
      landing_18: '',
    },
  },
  cart: {
    cart_01: 'カート',
    cart_02: '注文書の作成',
    cart_03: '注文完了',
    cart_04: '日付 / 商品番号',
    cart_05: '購入商品情報',
    cart_06: 'サイズ / 数量',
    cart_07: '金額',
    cart_08: '編集',
    cart_09: 'No. {{no}}',
    cart_10: 'なし',
    cart_11: '他のカラー追加',
    cart_12: '数量',
    cart_13: '支払い金額',
    cart_14: '送料',
    cart_15: '支払い金額',
    cart_16: '注文書の作成',
    cart_17: 'サイズと数量を選択していない商品があります。',
    option: {
      option_01: '数量を選択してください。',
    },
    cart_18: 'カートに商品がありません。',
    cart_19: '空いているカートを埋めてください！',
    cart_20: 'オプション変更',
    cart_21: '後で購入する',
    cart_22: '削除',
    cart_23: '品切れ',
    cart_24: '販売終了',
    cart_25: '商品が在庫切れです',
    cart_26: '選択したオプションまたは商品が在庫切れです。',
    cart_27: '最低注文数量が必要です。<br>適切に調節してください。',
  },
  maker: {
    warning: {
      image_size: '画像サイズが調整されましたので、ご確認ください。',
    },
    dosu: {
      confirm1_content:
        'この商品は{{dosu_count}}度まで印刷が可能です。<br>このデザインで印刷をご希望の場合、フルカラー商品に移動してください。',
      confirm1_title: 'あっ！印刷度数を超えました。 😂',
      confirm1_ok: 'フルカラー商品を作る',
      confirm1_cancel: 'エディターに戻る',
      confirm1_content_mobile:
        'この商品は{{dosu_count}}度まで印刷が可能です。<br>このデザインで印刷をご希望の場合、フルカラー商品に移動してください。',
    },
    image: {
      title: '画像から抽出したカラー',
    },
  },
  biz: {
    dosu: {
      warning_00: '印刷可能なカラーに自動変換しました。',
      warning_01: '基本デフォルトカラーに自動変換しました。',
    },
    opt: {
      title: {
        qty: '数量',
        ref_file: '参考ファイル',
        req_memo: '要望事項',
        print_color: '印刷色',
        tippy_content: '注文数量が多いほど</br> 割引が大きくなります！',
        error_message:
          '現在の印刷カラーの制限が超過しています。1面あたり{{dosu_ctn}}度以内に編集してください。',
        more_dosu: '{{dosu_ctn}}度 (印刷カラー超過)',
      },
      ref_file: {
        placeholder: '商品製作に参考ファイルがある場合、添付してください。',
        limit: 'ファイル形式 ：ai および pdfファイル(10MB以下)',
      },
      req_memo: {
        placeholder: '製作時に要望事項がある場合、入力してください。',
      },
      ea: '個',
      order_unit: 'から注文可能 / 注文単位',
      group_discount: '団体割引ガイド',
      dosu_guide: 'お客様のデザインに使用されたカラーを分析し、印刷可能なカラーを抽出いたします。',
      file_error: 'ファイルダウンロード中エラーが発生しました。',
      file_size_error: 'ファイルの大きさが制限より大きいです。',
      file_ext_error: '可能なファイル拡張子ではありません。',
      order_qty: '注文数量',
      unit_price: '単価',
    },
    biz_00: '企業グッズもマープルで！',
    biz_01: '企業グッズ、\nマープルで簡単に解決ください',
    biz_02:
      '<strong>デザイン試案確認からサンプル製作まで —</strong>\n効率的な制作過程と便利な配送サービスを\n合理的な価格で経験してください。',
    biz_03: '企業グッズ相談する',
    biz_04: 'マープルは多様な好みと必要に合わせた企業グッズを提供します。',
    biz_05: 'ブランドまたは企業のアイデンティティを盛り込んだ差別化されたグッズ',
    biz_06: '役職員のためのウェルカムキット',
    biz_07: 'オン/オフラインマーケティング及び広報のためのグッズ',
    biz_08: 'マープルならではの便利で優れたカスタムサービスをご体験ください。',
    biz_09: 'サンプル製作可能',
    biz_10: 'マープルはサンプル1つも製作できます。特別な構成品が必要でもOK！',
    biz_11: 'デザイン試案確認',
    biz_12:
      'マープルサイトで商品に直接デザインしたりイメージを適用して試案の確認をリアルタイムで行うことができます。',
    biz_13: '少量製作可能',
    biz_14: '少ない数量も高い品質で製作いたします。構成品が多くても少なくとも全部OK！',
    biz_15: '便利な配送',
    biz_16: '国内、海外配送から個別指定配送まで全て可能です。',
    biz_17: '簡単かつ迅速な注文方法で企業グッズを受け取ってみてください！',
    biz_18: 'マープル担当マネージャーを通じてディテールな見積もり相談を行います。',
    biz_19: '製作、検収、梱包、配送までマープルにお任せください。簡単で手軽な企業グッズが出来上がります。',
    biz_20: 'マープルは大企業、スタートアップ、学校、放送局、エンター、ゲーム会社と共にします。',
    biz_21: 'どんな企業のグッズを作ればいいか悩んでいますか？',
    biz_22:
      'ウェルカムキット·プロモーショングッズ·ブランドグッズなど目的に合った企業グッズを集めました。おすすめのグッズを直接ご確認いただき、お手軽にすぐに製作してみてください！',
    biz_23: 'おすすめ企業グッズ',
    biz_24: 'お問い合わせする{{email}}',
    biz_25: 'よくある質問\nここに集めてみました。',
    biz_26: '企業グッズ相談する',
    biz_27: 'どんな企業グッズを<br/>作るべきかお悩みですか？',
    biz_28: 'どんな企業グッズを<br/>作るべきかお悩みですか？',
    biz_29: '企業グッズ相談',
    biz_30: '企業グッズ相談を申し込む',
    faq: {
      faq_q_1: 'グッズとキットの違いはなんですか？',
      faq_a_1:
        'グッズは主に単一アイテム/個別商品を意味し、販促用、ファンアイテム、記念品、ブランド広報用品などで製作します。<br/>キットは複数の構成品が一緒にパッケージングされたセットを意味し、一緒に使いやすかったり、特定の目的に合うように企画および構成・製作されます。',
      faq_q_2: '基本キットの構成を変えたり、構成品の追加ができますか？',
      faq_q_2_mo: '基本キットの構成を変えたり、<br/>構成品の追加ができますか？',
      faq_a_2:
        '可能です。キットのタイプを選択した後、企業グッズ相談するを通してお問い合わせ頂ければ担当マネージャーが割り当てられ 担当マネージャーが割り当てられ、要請された内容を基に相談させていただきます。',
      faq_q_3: '最低注文数量はありますか？',
      faq_a_3:
        'キット製作の場合、50個以上注文可能です。 製品やパッケージの仕様によって最低注文数量が変動することがあります。<br/>最小注文数量未満の注文を希望する場合、木型費、印刷費過多請求など様々な問題がある場合がありますので、担当マネージャーと詳細な相談を行ってください。',
      faq_q_4: 'パッケージも変更できますか？',
      faq_a_4:
        '可能です。構成品が確定した後、パッケージの種類とデザイン、大きさなどを多彩に選択できます。 パッケージの内容も担当マネージャーが詳細にご相談いたします。',
      faq_q_5: 'キットの見積もりをいただきたいです。',
      faq_a_5:
        '具体的な見積もりの確認は、担当マネージャーに相談して確認してください。 注文数量、後加工など別途ご要請によりお見積りが変わる可能性がございます。<br/>別途要請可能範囲はボックス変更/追加、カスタムデザイン、內紙、ブランドカード追加、自社製品合包装、サンプル製作などがあります。',
      faq_q_6: '製作期間はどのくらいかかりますか？',
      faq_a_6:
        'キットの仕様や数量によって異なり、おすすめキットで製作する場合、製作確定後通常2週間以内に製作可能です。<br/>構成品や仕様が変更される場合、さらに1週間~2週間かかることがあります。<br/>カスタムキットの場合、構成品やパッキング仕様によって製作期間が異なり、ご相談の際にご希望の受け取り日程を教えていただければ、できるだけ合わせて進行いたします。',
      faq_a_6_mo:
        'キットの仕様や数量によって異なり、おすすめキットで製作する場合、製作確定後通常2週間以内に製作可能です。<br/>構成品や仕様が変更される場合、さらに1週間~2週間かかることがあります。<br/>カスタムキットの場合、構成品やパッキング仕様によって製作期間が異なり、ご相談の際にご希望の受け取り日程を教えていただければ、できるだけ合わせて進行いたします。',
      faq_q_7: 'キットもサンプル製作が可能ですか？',
      faq_a_7:
        '構成品がマープルサイト商品の場合、量産前にサンプル製作が可能です。<br/>試案が確定してからサンプルの受け取りまで平均1週間かかります。<br/>しかし、一部のカスタム製作グッズの場合、製作の特性上、サンプル製作が難しいことがあります。<br/>サンプル製作が難しいグッズは事前にご案内いたします。<br/>パッケージの場合、サンプル製作は難しい場合があり、製作サンプルや印刷サンプルでご確認いただく方法もございます。',
      faq_q_8: '支払い方法について知りたいです。',
      faq_a_8:
        'お支払いは主に振込(税金計算書発行)で行われます。<br/>入金/決済確認後に製作が行われるのが原則ですが、別途決済/購買システムを通じて決済が必要な場合は担当マネージャーにお知らせください。<br/>機関によって支出可能な日付が固定された場合などの例外的な場合は、決済日を協議して税金計算書発行の下で先制作/後払い決済で進行できるようにサポートしています。<br/>振込時に注文者名と入金者名が異なる場合は確認できませんので、必ず注文者名で入金してください。',
    },
    kit: {
      kit_header_mo: '価格別企業グッズを<br/>おすすめします',
      kit_header_pc: '価格別企業グッズを<br/>おすすめします',
      kit_img_alt_1: '1,000円代 セット',
      kit_title_mo_1: '1,000円代<br/>セット',
      kit_title_pc_1: '1,000円代<br/>セット',
      kit_summary_1: 'プロモーショングッズにおすすめ！',
      kit_desc_mo_1: '様々な目的と場所で使用でき、広報に良いアイテム構成をご紹介します。',
      kit_desc_pc_1: '様々な目的と場所で使用でき、広報に良いアイテム構成をご紹介します。',
      kit_point: '特徴',
      kit_list_mo_1:
        '<li>販促, プロモーショングッズにおすすめ</li><li>オフライン行事(カンファレンス、セミナーなど) グッズにおすすめ</li><li>実用性·コスパ·携帯性 すべて充足</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_list_pc_1:
        '<li>販促, プロモーショングッズにおすすめ</li><li>オフライン行事(カンファレンス、セミナーなど) グッズにおすすめ</li><li>実用性·コスパ·携帯性 すべて充足</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_img_alt_2: '2,000円代 セット',
      kit_title_mo_2: '2,000円代<br/>セット',
      kit_title_pc_2: '2,000円代<br/>セット',
      kit_summary_2: 'ASAP! 最も早い製作が可能な構成。',
      kit_desc_mo_2: '早い日時で製作を希望される方におすすめします。',
      kit_desc_pc_2: '早い日時で製作を希望される方におすすめします。',
      kit_list_mo_2:
        '<li>新入社員のためのウェルカムキットにおすすめ</li><li>社内イベント用·部活·団体·企業行事グッズにおすすめ</li><li>アパレルと文具の適切な組み合わせ</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_list_pc_2:
        '<li>新入社員のためのウェルカムキットにおすすめ</li><li>社内イベント用·部活·団体·企業行事グッズにおすすめ</li><li>アパレルと文具の適切な組み合わせ</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_img_alt_3: '3,000円代 セット',
      kit_title_mo_3: '3,000円代<br/>セット',
      kit_title_pc_3: '3,000円代<br/>セット',
      kit_summary_3: 'デスク必須アイテムセット。',
      kit_desc_mo_3: 'オフィスでよく使う実用的なアイテムでセンス良く使い業務効率をアップさせてください。\n',
      kit_desc_pc_3: 'オフィスでよく使う実用的なアイテムでセンス良く使い業務効率をアップさせてください。\n',
      kit_list_mo_3:
        '<li>ブランドアイデンティティが込められたブランドグッズとしておすすめ</li><li>ファンアイテム、企業PR用におすすめ</li><li>リーズナブルな価格で楽しめる多様な構成</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_list_pc_3:
        '<li>ブランドアイデンティティが込められたブランドグッズとしておすすめ</li><li>ファンアイテム、企業PR用におすすめ</li><li>リーズナブルな価格で楽しめる多様な構成</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_img_alt_4: '6,000円代 セット',
      kit_title_mo_4: '6,000円代<br/>セット',
      kit_title_pc_4: '6,000円代<br/>セット',
      kit_summary_4: '大切な顧客のためのギフト。',
      kit_desc_mo_4: '持続的に使用できるアイテムでブランド認知度と顧客満足度を高めるのに効果的です。',
      kit_desc_pc_4: '持続的に使用できるアイテムでブランド認知度と顧客満足度を高めるのに効果的です。',
      kit_list_mo_4:
        '<li>VIP顧客のためのギフトグッズにおすすめ</li><li>ブランドアイデンティティが込められたグッズとしておすすめ</li><li>実用性·持続性·高級さが込められた丁寧なセット</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_list_pc_4:
        '<li>VIP顧客のためのギフトグッズにおすすめ</li><li>ブランドアイデンティティが込められたグッズとしておすすめ</li><li>実用性·持続性·高級さが込められた丁寧なセット</li><li>価格は100個基準です。(以下製作時、別途お問い合わせ)</li>',
      kit_img_alt_5: '自由構成セット',
      kit_title_mo_5: '自由構成セット',
      kit_title_pc_5: '自由構成セット',
      kit_summary_5: 'グッズ+パッケージすべて自由に組み合わせ',
      kit_desc_mo_5: 'オフィスでよく使う実用的なアイテムでセンス良く使い業務効率をアップさせてください。',
      kit_desc_pc_5: 'オフィスでよく使う実用的なアイテムでセンス良く使い業務効率をアップさせてください。',
      kit_list_mo_5:
        '<li>グッズとパッケージング方式すべて自由に選択可能です。</li><li>ご希望品目+パッケージング方式をご記入いただき、相談申込書を作成していただくか</li><li><a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a> までお問い合わせいただければ、ご相談いただけます。</li>',
      kit_list_pc_5:
        '<li>グッズとパッケージング方式すべて自由に選択可能です。</li><li>ご希望品目+パッケージング方式をご記入いただき、相談申込書を作成していただくか</li><li><a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a> までお問い合わせいただければ、ご相談いただけます。</li>',
    },
    kit_form: {
      kit_form_title_mo_1: 'お問い合わせ前に<br/>必ず<br/>お読みください！',
      kit_form_title_pc_1: 'お問い合わせ前に<br/>必ず<br/>お読みください！',
      kit_form_desc_mo_1:
        '<p>こんにちは、マープルB2Bサービスを担当しているビーズチームです。<br/>マープルは、優れたクオリティの団体注文カスタムおよび企業キットサービスを<br/>リーズナブルな価格で提供しております。<br/></p><p>下記の相談申込書を通じて、担当マネージャーの迅速かつ正確な<br/>グッズのおすすめや制作相談を受けることができます。<br/>その他のお問い合わせは、ビズチームメール&nbsp;<a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a> までお問い合わせください。</p>',
      kit_form_desc_pc_1:
        '<p>こんにちは、マープルB2Bサービスを担当しているビーズチームです。<br/>マープルは、優れたクオリティの団体注文カスタムおよび企業キットサービスを<br/>リーズナブルな価格で提供しております。<br/></p><p>下記の相談申込書を通じて、担当マネージャーの迅速かつ正確な<br/>グッズのおすすめや制作相談を受けることができます。<br/>その他のお問い合わせは、ビズチームメール&nbsp;<a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a> までお問い合わせください。</p>',
      kit_form_view_more: '詳細を見る',
      kit_form_info_title: '申請手続きのご案内',
      kit_form_info_title_1: '1. 相談受付',
      kit_form_info_desc_mo_1:
        "<p>ご入力いただいたメールアドレスに返信メールが送信されます。(メールアドレスを正確に入力してください)\n相談申し込み時には、機密事項に該当しない '公開可能な内容' のみ記入していただきますようお願いいたします。\n相談内容および関連資料は、相談の審査および制作の目的でのみ使用されます。</p>",
      kit_form_info_desc_pc_1:
        "<ul><li>ご入力いただいたメールアドレスに返信メールが送信されます。(メールアドレスを正確に入力してください)</li><li>相談申し込み時には、機密事項に該当しない '公開可能な内容' のみ記入していただきますようお願いいたします。</li><li>相談内容および関連資料は、相談の審査および制作の目的でのみ使用されます。</li></ul>",
      kit_form_info_title_2: '2. 申請書の審査',
      kit_form_info_desc_mo_2:
        '<p>お客様の相談申し込みは営業日を基準3〜5日以内に処理されます。\n担当者が詳細な内容を審査する過程で追加の資料が必要となったり、再問い合わせになることがあります。\n(重要な事案の場合、少し時間がかかることがありますので、ご理解の程よろしくお願い申し上げます。)</p>',
      kit_form_info_desc_pc_2:
        '<ul><li>お客様の相談申し込みは営業日を基準3〜5日以内に処理されます。</li><li>担当者が詳細な内容を審査する過程で追加の資料が必要となったり、再問い合わせになることがあります。</li><li>(重要な事案の場合、少し時間がかかることがありますので、ご理解の程よろしくお願い申し上げます。)</li></ul>',
      kit_form_info_title_3: '3. 相談終了',
      kit_form_info_desc_mo_3:
        '<p>担当者からの資料要求または再問い合わせ後、30日以内にお客様からの返信がない場合、\n該当の相談申し込みは終了処理されます。</p>',
      kit_form_info_desc_pc_3:
        '<ul><li>担当者からの資料要求または再問い合わせ後、30日以内にお客様からの返信がない場合、</li><li>該当の相談申し込みは終了処理されます。</li></ul>',
      kit_form_title_2: '相談の種類を<br/>選んでください',
      kit_form_select_title_1: '団体販促/ノベルティ',
      kit_form_select_desc_1: '別途のパッケージなしにグッズ単品だけを作りたいです。',
      kit_form_select_title_2: 'パッケージ / キット',
      kit_form_select_desc_2:
        'ボックス、ティンケースなどの包装用アイテムだけを製作したり、<br/>複数の構成品を一緒に包装するキットを製作したいです。',
      kit_form_title_3: '相談申込内容を<br/>記入してください',
      kit_form_required: '必須項目です',
      kit_form_label_1: 'タイトル',
      kit_form_placeholder_1: 'タイトルは[会社名]などご自由に記入してください。',
      kit_form_placeholder_mo_1: '<p>タイトルは[会社名]などご自由に記入してください。</p>',
      kit_form_label_2: '希望制作数量',
      kit_form_placeholder_mo_2:
        '<p>単品の場合、商品名と一緒に各商品のオプション(カラー、サイズ)別数量を記入してください。<br/>キットの場合はセット基準に記入してください。</p>',
      kit_form_placeholder_pc_2:
        '単品の場合、商品名と一緒に各商品のオプション(カラー、サイズ)別数量を記入してください。 キットの場合はセット基準に記入してください。',
      kit_form_label_3: '納期日',
      kit_form_placeholder_mo_3:
        '<p>ご希望の納品スケジュールを選択してください。<br/>(商品ごとに制作スケジュールは異なりますが、注文から製作完了まで平均2週間以上かかることをご参考ください。）</p>',
      kit_form_placeholder_pc_3:
        'ご希望の納品スケジュールを選択してください。(商品ごとに制作スケジュールは異なりますが、注文から製作完了まで平均2週間以上かかることをご参考ください。）',
      kit_form_placeholder_data_3:
        'ご希望の納品スケジュールを選択してください。(商品ごとに制作スケジュールは異なりますが、注文から製作完了まで平均2週間以上かかることをご参考ください。）',
      kit_form_label_4: 'グッズ受け取り場所',
      kit_form_placeholder_mo_4:
        '<p>おおよその住所を教えていただければ、<br/>送料も含めてご案内いただけます。</p>',
      kit_form_placeholder_pc_4: 'おおよその住所を教えていただければ、送料も含めてご案内いただけます。',
      kit_form_label_5: '製作目的',
      kit_form_placeholder_5: '広報およびプレゼント用、販売用などの制作目的や状況をご記入ください。',
      kit_form_placeholder_mo_5:
        '<p>広報およびプレゼント用、販売用などの制作目的や状況をご記入ください。</p>',
      kit_form_label_6: '予算',
      kit_form_placeholder_6: 'ご希望の制作単価や決まった予算がある場合は、ご記入ください。',
      kit_form_placeholder_mo_6: '<p>ご希望の制作単価や決まった予算がある場合は、ご記入ください。</p>',
      kit_form_label_7: '詳細内容',
      kit_form_placeholder_mo_7:
        '担当マネージャーが知るべき追加情報がある場合やマーフルサイトにない商品の場合、参考URLを記入してください。&#10;画像などのファイル形式は下記の添付ファイルに添付してください。',
      kit_form_placeholder_pc_7:
        '担当マネージャーが知るべき追加情報がある場合やマーフルサイトにない商品の場合、参考URLを記入してください。画像などのファイル形式は下記の添付ファイルに添付してください。',

      kit_form_title_4: '参考ファイルを添付してください요',
      kit_form_label_8: '添付ファイル',
      kit_form_desc_mo_2:
        'pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt など 1個のファイル  (100MB以下の容量) ',
      kit_form_desc_pc_2:
        '添付ファイル 形式 : pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt など 1個のファイル  (100MB以下の容量) ',
      kit_form_title_5: '会社の紹介を<br/>してください',
      kit_form_label_9: '企業形態',
      kit_form_select_title_3: '一般企業',
      kit_form_select_title_4: '学校 / 公共機関',
      kit_form_select_title_5: 'その他',
      kit_form_label_10: '企業名',
      kit_form_placeholder_10: '会社または機関名を入力してください。',
      kit_form_label_11: '担当者',
      kit_form_placeholder_11: '担当者のお名前を入力してください。',
      kit_form_label_12: '担当者メールアドレス',
      kit_form_placeholder_12: '返信を受け取るための正確なメールアドレスを入力してください。',
      kit_form_placeholder_mo_12: '<p>返信を受け取るための正確なメールアドレスを入力してください。</p>',
      kit_form_label_13: '担当者 連絡先',
      kit_form_placeholder_13: '連絡が取れる電話番号を正確に入力してください。',
      kit_form_placeholder_mo_13: '<p>連絡が取れる電話番号を正確に入力してください</p>',
      kit_form_title_6: '個人情報収集<br/>および利用案内',
      kit_form_label_14: '個人情報および収集利用同意',
      kit_form_desc_14:
        '(株)マープルコーポレーションは企業および個人の相談を希望する方々を対象に、以下のように個人情報を収集および利用します。',
      kit_form_desc_14_2:
        '<li>個人情報の収集目的 : 顧客相談、お問い合わせの転送</li><li>個人情報の収集項目 : 氏名、電話番号、メールアドレス</li><li>個人情報の保有および利用期間 : 個人情報の収集および利用目的が達成された後、該当情報を例外なく廃棄します。</li><li>個人情報および収集利用に同意しない場合、相談はできません。</li>',
      kit_form_label_15: '申し込み完了',
      kit_form_label_16: '完了',
      kit_form_message_1: '削除しますか？',
      kit_form_message_2: 'アップロード中...',
      kit_form_message_3: 'ファイルのアップロードに失敗しました',
      kit_form_message_4: 'エラーが発生しました。',
      kit_form_validation_message_1: 'メールの形式を確認してください。',
      kit_form_validation_message_2: '連絡先をご確認ください。',
      kit_form_validation_message_3: '相談の種類を選んでください',
      kit_form_validation_message_4: 'タイトルは[会社名]などご自由に記入してください。',
      kit_form_validation_message_5:
        '単品の場合、商品名と一緒に各商品のオプション(カラー、サイズ)別数量を記入してください。 キットの場合はセット基準に記入してください。',
      kit_form_validation_message_6:
        'ご希望の納品スケジュールを選択してください。(商品ごとに制作スケジュールは異なりますが、注文から製作完了まで平均2週間以上かかることをご参考ください。）',
      kit_form_validation_message_7: '広報およびプレゼント用、販売用などの制作目的や状況をご記入ください。',
      kit_form_validation_message_8:
        '担当マネージャーが知るべき追加情報がある場合やマーフルサイトにない商品の場合、参考URLを記入してください。画像などのファイル形式は下記の添付ファイルに添付してください。',
      kit_form_validation_message_9: '企業形態の種類を選んでください',
      kit_form_validation_message_10: '会社または機関名を入力してください。',
      kit_form_validation_message_11: '返信を受け取るための正確なメールアドレスを入力してください。',
      kit_form_validation_message_12: '連絡が取れる電話番号を正確に入力してください。',
      kit_form_validation_message_13: '個人情報収集の利用に同意してください',
    },
    kit_form_result: {
      kit_form_result_title: '企業グッズ相談申し込み完了',
      kit_form_result_desc:
        '相談申し込みが正常に受け付けられました。<br/>進行状況を確認し、営業日基準に3〜5日以内に\n<br/>入力いただいたメールアドレスと連絡先を通じてご連絡させていただきます。',
      kit_form_result_complete: '完了',
    },
    notice: {
      title: {
        price: '商品価格',
        consulting: '注文相談',
        payment: '決済方法',
        delivery: '配送案内',
        req_consulting: '相談申請',
      },
      price: '<b>すべての価格には付加価値税（VAT）</b> が含まれています。',
      consulting:
        'カスタムオーダーやパッケージ、印刷オプションなどのオリジナル商品に関するご相談は別途行っています。',
      payment: '注文製作商品は前払いを優先し、請求書の発行及びサイトでのカード支払いが可能です。',
      delivery:
        '個別配送、倉庫保管、クイック出荷、海外配送など、配送方法に応じて追加料金が発生する場合があります。',
      delivery2: '詳細な送料については別途ご相談ください。。',
      req1: '1:1 お問い合わせ',
    },
  },
  group_biz_product: {
    gbp_01: '団体商品タイプ',
    gbp_01_mo: '団体タイプ',
    gbp_02: '個別商品価格',
    gbp_02_mo: '個別価格',
    gbp_03: '注文数量',
    gbp_04: '{{price}}以上',
    gbp_05: '団体販促/ノベルティ商品もっと見る',
    gbp_06: '企業グッズ相談する',
    gbp_07: 'どんな団体商品が必要ですか?',
    gbp_08: 'ポップアップストアからミュージアムまで<br/>あなたのブランドで販売してください',
    gbp_09: '団体注文で人気が多いです',
    gbp_10: 'ポートフォリオ',
    faq: {
      faq_q_1: 'グッズとキットの違いはなんですか？',
      faq_a_1:
        'グッズは主に単一アイテム/個別商品を意味し、販促用、ファンアイテム、記念品、ブランド広報用品などで製作します。<br/>キットは複数の構成品が一緒にパッケージングされたセットを意味し、一緒に使いやすかったり、特定の目的に合うように企画および構成・製作されます。',
      faq_q_2: 'マープルサイトにないグッズも製作できますか？',
      faq_a_2:
        '可能です。ご希望のグッズのデザインと仕様を提供していただければ、担当マネージャーと相談し製作の可否をご確認いただけます。',
      faq_q_3: 'グッズの見積もりをいただきたいです。',
      faq_a_3:
        'マープルの全てのグッズはオーダーメイドなので、グッズの種類や数量、製作仕様によって費用が異なります。<br/>マープル製作商品の場合、マープルサイト(www.marpple.com)でご希望の商品1つの製作価格をすぐにご確認いただけます。<br/>衣類の場合、表·裏·袖などのプリント位置と大きさ、数量、印刷方式(プリンティング、刺繍)によって製作単価が決定されます。<br/>したがって、製作試案を必ず提供していただかないと正確な見積もりが確認できません。<br/>カスタムキットや別途オーダーメイドグッズの場合、担当マネージャーにお問い合わせいただければ、相談後見積もりを提供いたします。',
      faq_q_4: '製作過程について詳しく知りたいです。',
      faq_a_4: `お問い合わせする : ページ上段の「企業グッズ相談」を通じてお問い合わせください。
制作相談 : 担当マネージャーが割り当てられ、要請した内容を基にグッズのおすすめ及び制作相談を提供します。
見積り : 相談後、1次見積書をメールで送信いたします。 以後、試案および仕様が確定したら最終確定見積書を発送いたします。<ul><li>マープルサイトから商品に直接イメージを適用して試案確認がすぐ可能です。
デザイン完了後、ページで確認した合成画像とともにデザイン原本ファイル(高画質PNG、AIまたはPSDファイル)をお渡しください。</li><li>その他カスタム商品 : 別途デザインガイドに合わせてデザインを行い、デザイン原本ファイル(高画質PNG、AIまたはPSDファイル)をお渡しください。</li></ul><p>生産および配送 : すべての商品は担当マネージャーの几帳面な検収を経て、高クオリティで生産され安全に包装して配送いたします。</p>`,
      faq_q_5: 'デザインの依頼もできますか？？',
      faq_a_5: '製作試案のイメージサイズ調整、背景除去、補正などのイメージ最適化作業を提供します。',
      faq_q_6: 'サンプル製作は可能ですか？',
      faq_a_6:
        'マープルはサンプル製作が可能です。 しかし、一部のカスタム製作グッズの場合、製作特性上サンプル製作が難しい場合があります。<br/>サンプル製作が難しいグッズは事前にご案内いたします。サンプル費は本製作時に1回支援され、本製作不発時に別途請求されます。<br/>サンプルに関する詳細については、担当マネージャーにご相談ください。',
      faq_q_7: '製作期間はどのくらいかかりますか？',
      faq_a_7:
        'グッズごとに製作期間が異なる場合があり、最終製作承認後営業日基準(週末/祝日を除く)7日ほどかかります。<br/>大量注文の場合、営業日基準2週間以上かかる場合があります。<br/>サンプル製作の可否および製作内容によっては、さらに期間がかかることがあります。<br/>マネージャーにお問い合わせいただければ、より詳細な日程をご案内いたします。',
      faq_q_8: '支払い方法について知りたいです。',
      faq_a_8:
        'お支払いは主に振込(税金計算書発行)またはカード決済で行われます。<br/>入金/決済確認後に製作が行われるのが原則ですが、別途決済/購買システムを通じて決済が必要な場合は担当マネージャーにお知らせください。<br/>機関によって支出可能な日付が固定された場合などの例外的な場合は、決済日を協議して税金計算書発行の下で先制作/後払い決済で進行できるようにサポートしています。<br/>振込時に注文者名と入金者名が異なる場合は確認できませんので、必ず注文者名で入金してください。',
      faq_q_9: '配送はどのくらいかかりますか？',
      faq_a_9:
        'CJ大韓通運宅配会社に出庫後、営業日基準で1~2日以内に受領できます。 ただし、離島地域および一部地域の場合、もう少し期間がかかる場合があります。<br/>製作完了したグッズは訪問受領、宅配、クイックなどで受け取ることができます。<br/>海外配送、離島地域、航空、クイック配送、個別配送などの配送方法の変更は、担当マネージャーに必ずご相談ください。',
      faq_q_10: 'オーダーメイドの商品を交換/返品できますか？',
      faq_a_10:
        'マープルのオーダーメイドグッズは、お客様のご注文に応じて個別制作される商品で、再販売ができませんので、心変わりによる交換/返品はできません。<br/>商品に問題がある場合、受領後7日以内にbiz@marpple.com まで商品の不良に対する証明とともに不良受付をしていただければ、商品回収及び検収後アフターサービスを提供いたします。<br/>ご注文いただいた商品以外の商品への交換/返品はお断りしております。<br/>同一商品、同一サイズ、同一カラーでの再製作及びアフターサービスのみ可能であり、その際発生する送料はマープルが負担します。',
    },
  },
  about: {
    about_00: 'マープルは初めてですか？',
    about_01: 'こんにちは🎨 カスタムグッズ製作専門プラットフォームマープルです。',
    about_02:
      '<strong>思い出と友情、家族と職場、個性と意味、名前と誕生日まで —</strong>\nマープルであなただけの、世界にたった一つの商品を作ってみてください。\n15年間積んできたグッズ製作ノウハウと便利なオンラインデザインソリューションで\n最高のカスタムグッズをお作りします。',
    about_03: 'カスタムする',
    about_04: '3分で完成できる🔫\nマープルだけのカスタム方法をお伝えします。',
    about_05: '商品を選択する',
    about_06: 'デザインをアップロードする(無料デザイン提供)/カスタムグッズ作り',
    about_07: '注文してお支払いすれば終わり！',
    about_08: 'マープルの製作工程を\n一目で見る👀',
    about_09: 'マープルで作るカスタム、\nこのように作ってみてください！ 🧚',
    about_10: '世界にたった一つの私だけの商品、\nこんな風にも作られます！ 💞',
    about_11: '自分のイメージでカスタムする',
    about_12:
      '自分で作ったロゴや写真を入れてカスタムしてみてください！\nこの世にたった一つの自分だけのグッズカスタム！',
    about_13: 'カスタムする',
    about_14: 'テキストを入れてカスタムする',
    about_15:
      'フォントを選択して、好きなフレーズを入れることができます！学校や団体名、大切な家族、自分の好きなように！',
    about_16: '無料装飾デザインでカスタムする',
    about_17: 'デザインが難しいですか？ 数千種類に及ぶマープル無料デザインツールで素早く簡単にカスタム完成！',
    about_18: '様々な方法でカスタムする',
    about_19:
      'シルクスクリーン、デジタルプリンティング、刺繍、パッチ、蛍光印刷、多様に自分のカスタムの個性を生かしてみてください！',
    about_20: 'マープルではたった一枚から団体注文まで\n全て製作できます。',
    about_21: '団体注文',
    about_22:
      '1つから注文製作が可能なマープルでサンプルを先に確認した後、高品質·高クオリティのカスタム製品を作ってみてください。',
    about_23: 'クオリティが心配ですか？\nマープルのプレミアムクオリティを感じてみてください。',
    about_24: '高いクオリティ',
    about_25:
      'マープルは韓国で直接生産する高いクオリティの無地商品とプリントサービスを提供しています。\nデジタルプリンティング、シルクスクリーンなど多様な製作方式と設備を備えており、多彩なカスタムが可能で、\n様々なデザインとディテールを完璧にお届けします。',
    about_26:
      'マープルは9万個の顧客レビューで\r🔍 検証済み\n\rオンラインカスタムグッズ\r製作専門プラットフォームです。',
    about_27: 'マープルのリアルタイムレビュー',
    about_28: 'マープルは大企業、スタートアップ、学校、放送局、エンター、\nゲーム会社と共にします。',
  },
  custom: {
    custom_01: '今日のカスタム',
    custom_02: '最近人気のカスタム、あなたにもできます！',
    custom_03: 'カスタムTips',
    custom_04: 'カスタム？もう簡単です！',
    custom_05: '\n' + '団体商品ガイド',
    custom_06: 'ビジネスのコツをお届けします！',
    custom_07: '全体',
    custom_08: '全体タブヘッダーフレーズをリクエストする。',
  },
  event: {
    event_01: 'イベント全体を見る',
  },
  quality: {
    title: 'ハイクオリティ',
    qual_01: '信じて任せられるマープル、100％満足保障',
    qual_02:
      'マープルでのお客様の経験はとても大切なので私たちは最高クオリティの商品を製作し、その品質を維持するために絶えず努力しております。',
    qual_03: '2,000個以上の自分だけの商品、そして無駄のない生産',
    qual_04:
      'マープルでは世界に一つしかない自分だけの商品を作ることができます。大企業やメガブランドが提供する画一的なデザインから脱し、他人とは違う自分だけのアイテムを探しているなら、マープルがその答えです。',
    qual_05:
      '工場で生産された後、販売されず捨てられたり廃棄される服があまりにも多いです。マープルの全面印刷方式は生地に先に印刷してから裁断及び縫製過程を経るため資源の無駄を減らし、常に環境に配慮した心で商品製作を行っております。',
    qual_06: '最先端織物印刷技術',
    qual_07: 'デジタル平板プリンティング',
    qual_08:
      'コンピューターで作業されたイメージをDTG方式で生地に直接印刷するプリンティング方式で、リアルな表現が必要な写真、複雑なデザイン、または多様なカラーの表現が必要な時に最適化された印刷技法です。',
    qual_09: 'カラー数の限界なし',
    qual_10: 'ディテールなイメージとカラーを実現',
    qual_11: '初期セットアップ費用がかからないため、プリンティングオーダーメイドに最適化',
    qual_12: 'シルクスクリーン',
    qual_13:
      '団体Tシャツに適した印刷方式で同じデザイン、同じ大きさの大量作業に適した方式です。 A2サイズまで作業が可能で、一度に8色まで作業ができます。',
    qual_14: '速い生産速度',
    qual_15: '同一デザインの大量生産に適合',
    qual_16: '洗濯に最も強力で印刷クオリティが長持ちする',
    qual_17: 'フラックス',
    qual_18:
      'フラックス印刷は、一つや二つのカラーを印刷する際、イニシャルや背番号など単純なロゴ作業をしたり、少量の個別作業が必要な時に良い作業方式です。 1つの数量から作業が可能だというメリットがあります。',
    qual_19: '印刷面にTシャツ生地の色が透けず高級感あり',
    qual_20: '印刷可能部分及び面積に制約なし',
    qual_21: '驚くべき洗濯堅牢度',
    qual_22: '昇華転写',
    qual_23:
      '転写紙に印刷後、出力物を生地に高温圧着する方式です。 リアルな表現が必要な写真、イラストなどのイメージを印刷するのに適しており、衣類、スパンデックス、マグカップ、エコバッグなど様々な印刷が可能です。',
    qual_24: '印刷可能部分及び面積に制約なし',
    qual_25: '印刷素材の制約なし(生地、マグ、プラスチック素材、革など)',
    qual_26: '印刷された部分を触ってみた時、異物感が全くない',
    qual_27: '全面印刷',
    qual_28:
      '生地に先に印刷してから裁断と縫製を経て商品を製作する方式です。マープルは生地印刷に必要な超大型プリンターとロールプレスを備えており、最高品質の結果を保証します。',
    qual_29: '印刷可能部分及び面積に制約なし',
    qual_30: '印刷された部分を触ってみた時、異物感が全くない',
    qual_31: 'ピクセルが大きいフォーマットの印刷に適している',
    qual_32: '刺繍印刷',
    qual_33:
      '帽子、ポロシャツ、デニムシャツ、ウィンドブレーカー、パーカーなど様々な衣類に最高クオリティの刺繍を提供します。',
    qual_34: 'たった1つの注文でも刺繍可能',
    qual_35: 'デザインを衣類に直接糸で安着させる方法で高級感あり',
    qual_36: 'メリット',
    qual_37: 'マープルが厳選したハイクオリティブランド',
    qual_38:
      'マープルが厳選したブランドは高級生地、鮮やかな色の捺染、しっかりとした縫製を経て耐久性が高く快適な着心地の商品を提供しています。\nマープルは印刷に適した生地を様々な印刷方式でテストし、最高のクオリティを提供するために努力しています。',
    qual_39:
      'マープルが厳選したブランドは高級生地、\n鮮やかな色の捺染、しっかりとした縫製を経て\n耐久性が高く快適な着心地の商品を提供しています。',
  },
  group_order: {
    go_01: '団体注文',
    go_02: '10万件のレビューが証明する団体Tシャツの人気を体験してみてください',
    go_03:
      '企業、集まり、同好会、学校など様々な団体Tシャツをマープルで経験してみてください。マープルでは誰でも簡単に素早く作ることができます。',
    go_04: '団体Tシャツを作る',
    go_05: 'マープルならではの便利で強力な団体注文サービスを経験してみてください',
    go_06: '丈夫なクオリティ',
    go_07: 'マープル団体Tシャツは優秀なクオリティのブランド無地商品で製作し耐久性に優れています。',
    go_08: '簡単で早い注文手続き',
    go_09: 'マープルではデザインから見積もりまでリアルタイムで確認して注文が可能です。',
    go_10: '団結すればするほど大きくなる割引',
    go_11: 'マープルでは5枚から割引が始まります。注文数量によって割引特典もより大きくなります！',
    go_12: '驚きで嬉しいマープル団体注文サービス',
    go_13: 'マープルでは5枚から割引が始まります。注文数量によって割引特典もより大きくなります！',
    go_14: '団体Tシャツ',
    go_15: '団体Tシャツカスタム商品を集めてみました',
    go_16: 'ブランド団体Tシャツ',
    go_17: 'ブランドTシャツの商品を集めてみました',
    go_18: '団体スマホケース',
    go_19: '最新機種まで全て集めてみました',
    go_20: '団体ユニフォーム',
    go_21: '団体機能性衣類を集めてみました',
    go_22: '団体エコバッグ',
    go_23: 'エコバッグを全て集めてみました',
    go_24: '団体小物',
    go_25: '色んな団体アイテムを集めてみました',
    go_26: '団体注文する',
    go_27: '今すぐ団体注文の割引価格をご確認ください！',
    go_28: '電話でのお問い合わせ 1566-9437',
    go_29: 'お問い合わせ受付時間 平日10:00-18:00',
  },
  omp_receipt: {
    txt1: '注文完了',
  },
  omp_title: {
    kit: '企業グッズ相談',
    home: '自分だけのグッズ製作マープル',
    new_best: 'マープル新商品',
    search: 'マープル全商品',
    reviews: 'マープルレビュー',
    group: 'マープルグループの注文',
    my_page: 'マープルマイページ',
    order: 'マープル オーダーリスト',
    wish: 'マープル蒸し商品',
    point: 'マープルポイント',
    my_review: 'マープルのレビュー',
    my_review_list: 'マープルのレビュー',
    my_info: 'マープル 私の情報',
    high_quality: 'マープル高品質',
    event: 'マープルイベント',
    login: 'マープルログイン',
    help: 'マープルヘルプ',
    know_how: 'マープルのノウハウ',
    apparel: 'マープルカスタムアパレル',
    sports: 'マープルカスタムスポーツ',
    electronics: 'マープルカスタムスマホケース／テック',
    accessories: 'マープルカスタムファッション雑貨',
    home_deco: 'マープルカスタムファッション雑貨',
    sticker: 'マープルカスタムステッカー／紙類',
    frames: 'マープルカスタム額縁',
    stationery: 'マープルカスタム文具／オフィス',
    kids_pet: 'マープルカスタムキッズ／ペット',
    new: 'マープル新商品',
    best: 'マープルベスト',
    review: 'マープルレビュー',
    about: 'マープルは初めてのご利用ですか？',
    biz: 'マープル企業グッズ',
    etc: '自分だけのグッズ製作マープル',
    sign_up: 'マープル会員登録',
    cart: 'マープルカート',
    order_detail: 'マープル注文詳細',
    search_result: '{{keyword}}マープル検索',
    group_biz: 'マープル団体販促/ノベルティ',
    goods: 'マープルグッズ',
    Cushion: 'マープルファブリック',
    kids: 'マープルキッズ',
    pet: 'マープルペット',
    digital: 'マープルDigital/Tech',
  },
  omp_og: {
    title: {
      apparel: 'アパレル | Made by you, Made with Marpple',
      home: 'Made by you, Made with Marpple',
      sports: 'スポーツ | Made by you, Made with Marpple',
      electronics: 'スマホアクセ | Made by you, Made with Marpple',
      accessories: 'アクセサリー | Made by you, Made with Marpple',
      home_deco: 'リビング | Made by you, Made with Marpple',
      sticker: 'ステッカー/紙類 | Made by you, Made with Marpple',
      frames: 'フォトパネル | Made by you, Made with Marpple',
      stationery: '文具 | Made by you, Made with Marpple',
      kids_pet: 'キッズ＆ペット | 自分だけのカスタム、マープル',
      new: '新商品 | マープルで新商品を楽しむ',
      best: `ベスト | マープルでベスト商品を楽しむ`,
      review: 'レビュー',
      about: 'マープルとは',
      biz: '企業グッズ / キット',
      etc: 'Made by you, Made with Marpple',
      group: '団体注文、マープルで簡単に！',
      group_biz: '団体販促',
      goods: 'グッズ | Made by you, Made with Marpple',
      Cushion: 'ファブリック | Made by you, Made with Marpple',
      kids: 'キッズ | Made by you, Made with Marpple',
      pet: 'ペット | Made by you, Made with Marpple',
      digital: 'Digital/Tech | Made by you, Made with Marpple',
    },
    description: {
      home: '送料無料',
      apparel: 'オリジナルTシャツ、送料無料',
      sports: 'オリジナルスポーツアイテム、送料無料',
      electronics: 'オリジナルスマホアクセ、送料無料',
      accessories: 'オリジナルアクセサリー、送料無料',
      home_deco: 'オリジナルリビングアイテム、送料無料',
      sticker: 'オリジナルステッカー、送料無料',
      frames: 'オリジナルフォトパネル、送料無料',
      stationery: 'オリジナル文具、送料無料',
      kids_pet: '我が家のワンちゃんのためのカスタム、マープルで！',
      new: 'Made by you, Made with Marpple',
      best: `Made by you, Made with Marpple`,
      review: 'Made by you, Made with Marpple',
      about: 'Made by you, Made with Marpple',
      biz: 'Made by you, Made with Marpple',
      etc: '送料無料',
      group: 'マープルだけの簡単で強力な団体注文サービス！団結すればするほど大きくなる割引！',
      group_biz: 'Made by you, Made with Marpple',
      goods: 'オリジナルグッズ、送料無料',
      Cushion: 'オリジナルファブリックアイテム、送料無料',
      kids: 'オリジナルキッズアイテム、送料無料',
      pet: 'オリジナルペット用品、送料無料',
      digital: 'Custom Digital/Tech Items, Free Shipping',
    },
  },

  omp_withdrawal: {
    title: '会員退会',
    txt1: 'お客様、Marpple ・ MarppleShopを本当に退会されますか？😢',
    txt2: '会員退会前に以下の事項を確認してください。',
    ol_1: '一般会員の場合、会員退会後に再登録は可能ですが、購入されたコンテンツ/注文履歴/保有ポイントなどの会員情報はすべて削除されます。',
    ol_2: `MarppleShopのクリエイターの場合、<strong>進行中の取引 '0件'、残余収益金額 '¥0'であり、</strong>販売商品の配送出荷/ CS/収益精算がすべて完了された後、退会が可能です。退会後には、クリエイターショップと商品販売/精算履歴などの会員情報がすべて削除され、復旧できません。詳細については、クリエイタースタジオでご確認ください。`,
    ol_3: '退会されてもすでにご購入いただいた商品は正常な配送処理が行われ、お客様の個人情報は会員退会後も商品の返品やアフターサービスのため「電子商取引等における消費者保護に関する法律」に基づくMarpple ・ MarppleShopの顧客情報保護ポリシーに従って管理されます。',
    ol_4: '退会手続きが難しい場合、カスタマーセンター(<a href="tel:1566-5496">1566-5496</a>または<a href="mailto:help@marpple.com">help@marpple.com</a>)にお問い合わせいただければご案内させていただきます。',
    info_txt1: '進行中の取引',
    info_txt3: '残余収益金額',
    info_txt5: '保有ポイント',
    info_txt2: '{{count}}件',
    info_txt4: 'KRW {{amount}}',
    info_txt6: '{{point}}P',
    txt3: `<strong>進行中の取引'0件'、残余収益金額「¥0」</strong>でなければ脱退ができません。`,
    txt4: '詳細はクリエイタースタジオでご確認ください。進行が難しい場合は、カスタマーセンターにお問い合わせください。',
    btn1: '退会する',
    txt5: '今までMarppleとMarppleShopをご利用いただきありがとうございました。',
    txt6: 'より良いサービスでお客様にまたお会いできるようにスタッフ一同努めてまいります。',
    confirm_txt1: '会員脱退が完了しました。',
    confirm_txt2:
      'これまでMarppleをご利用いただきありがとうございます。<br />より良いサービスでお客様を再度お会いできるよう努力いたします。',
    not_confirm_txt1: '会員退会を完了できません。',
    not_confirm_txt2:
      '進行中の取引「0件」、残余収益金額「0ウォン」でなければ会員脱退が可能です。詳細はクリエイタースタジオでご確認お願いし、進行が難しい場合は、カスタマーセンターにお問い合わせいただくとご案内いたします。',
    not_confirm_txt3: '平日 10:00~18:00',
  },
};
