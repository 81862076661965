import { go, sel, tap } from 'fxjs/es';
import { AdScriptFbF } from '../../../AdScript/Fb/F/Function/module/AdScriptFbF.js';
import { AdScriptGaF } from '../../../AdScript/Ga/F/Function/module/AdScriptGaF.js';
import { AdScriptKakaoF } from '../../../AdScript/Kakao/F/Function/module/AdScriptKakaoF.js';
import { resetUserCounts } from '../../../MPay/Cart/F/fs.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { getIsUser } from '../S/fs.js';
import { frameOpenSignUpIn } from './tab.js';

export const isLoginCheck = function () {
  return !!box.sel('is_user');
};

export const beforeLoginCheck = (prev_frame_show) => {
  return go(isLoginCheck(), function (res) {
    return (
      res ||
      go(frameOpenSignUpIn(null, prev_frame_show), function (res) {
        return res && res.is_session;
      })
    );
  });
};

export function afterSignIn(user) {
  if (!user) return;
  resetUserCounts();
  if (
    box.sel('is_user') &&
    !_p.find(_p.keys(box.sel('is_user')), function (key) {
      if (key == '_') return false;
      return box.sel('is_user')[key] != user[key];
    })
  )
    return;
  box.set('is_user', getIsUser(user));
  setTimeout(function () {
    $.attr($1('html'), {
      is_session: true,
      mp_worker_policy: !!user?._?.policies?.mp_worker_policy,
      line_worker_policy: !!user?._?.policies?.line_worker_policy,
      master_admin: !!user?._?.policies?.master_admin,
      is_mobile_cert: user.is_mobile_cert,
      is_verify: user.type != 'NEED_VERIFY',
    });
    $.html($1('#ham .login_user_email > span'), user.email);
    if (G.is_pc_size()) return G.my_menu_layer && G.my_menu_layer(box.sel('is_user'));
  }, 500);
}

export function closeFrameAfterSignIn(data, input_email) {
  if (!data.result)
    return _p.go($.alert(data.msg), function () {
      input_email && input_email.focus();
    });

  if (data.user_dormant_url) {
    return (window.location.href = data.user_dormant_url);
  }

  afterSignIn(data.user);
  if (!$1('html.login_page')) return $.frame.close({ is_session: data.result });
  const lo = G.$location();
  window.location.replace(lo.query.url ? location.search.replace('?url=', '') : '/');
}

const fbAppLogin = (rs) =>
  function (e) {
    try {
      const data = JSON.parse(e.data);
      if (data.type !== 'fb') return rs();
      return onMessage(rs)(data);
    } catch (err) {}
  };

export function makeFbLogin(e) {
  // return $.alert(T('Facebook Error'));
  $.don_loader_start();
  return go(
    new Promise(function (rs) {
      if (sel('currentTarget', e)) $.removeClass(e.currentTarget, '__don_on2_ing__');
      if (!window.ReactNativeWebView) return FB.login(onMessage(rs), { scope: 'email' });
      // if (!window.is_android)
      //   return $.alert(
      //     '어플리케이션 페이스북 로그인 점검입니다.<br>브라우저를 통해 이용해주시면 감사하겠습니다.<br>https://marpple.com'
      //   ).then(() => $.don_loader_end());
      if (UtilF.is_android()) {
        return $.alert(T('mp_app::android_fb_login'));
      }
      window.addEventListener('message', fbAppLogin(rs));
      document.addEventListener('message', fbAppLogin(rs));
      $.don_loader_end();
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          fb_uri: `${T.lang}/fb_login_native`,
        }),
      );
    }),
    () => $.don_loader_end(),
  );
}

const onMessage = (rs) => (data) => {
  const fb_login_data = _p.pick(data.authResponse, ['accessToken']);
  if (!fb_login_data.accessToken) return rs();
  go(
    $.post('/@api/mp/fb/login', {
      accessToken: fb_login_data.accessToken,
    }),
    tap((data) => {
      if (data?.msg == 'Success') {
        AdScriptGaF.signUpMpCreator();
        AdScriptFbF.signUp();
        AdScriptKakaoF.signUp();
      }
    }),
    closeFrameAfterSignIn,
    rs,
  );
};

G.afterSignIn = afterSignIn;
