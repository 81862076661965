import { each, go, defaults, sel, pipe, noop, tap, extend, identity } from 'fxjs/es';
import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { makeReviewItemHtml, makeReviewItemHtml2 } from '../S/tmpl.js';
import { REVIEW_LIST_LIMIT } from '../S/constant.js';

export const makeReviewListCommentMoreBtn = pipe(
  $.find('.comment_wrap'),
  each(function (don_comment_wrap) {
    if ($.height(don_comment_wrap) >= don_comment_wrap.scrollHeight) return;
    $.addClass($.next(don_comment_wrap, '.more'), 'show');
  }),
);

export const getReviewListByBox = async (don_review_list) =>
  $.get(
    '/@api/review/list',
    defaults(
      {
        limit: REVIEW_LIST_LIMIT,
        is_all: !$.attr(don_review_list, 'photo_review') || void 0,
      },
      window.box.sel('review_list->query'),
    ),
  );

export const changeReviewListInfi = async (don_review_list, scroll, _review_list) => {
  try {
    const review_list = _review_list || (await getReviewListByBox(don_review_list));
    window.box.extend2('review_list', review_list);
    const total_count = sel('review_total.count', review_list) || 0;

    if (!review_list) {
      const debug_error = new Error('changeReviewListInfi Error');
      ConfigSentryF.debug(debug_error, {
        extra: {
          review_list,
          box: window.box,
        },
      });
    }

    $.attr($.closest(don_review_list, '.reviews_wrap'), {
      total_count,
      review_len: review_list.reviews.length,
    });
    $.html($1('.review_total_count'), UtilS.commify(total_count));
    go(review_list.reviews, (rs) =>
      (sel('change', $.closest(don_review_list, '.don_tab').tab_opt.infi) || noop)(
        rs,
        !$.attr(don_review_list, 'photo_review') ? 1 : G.is_mobile_size() ? 2 : 3,
      ),
    );
    $.html(
      $('.reviews_wrap #not_found_results'),
      T(
        (review_list.reviews.length || total_count) > 0
          ? 'review::No Photo review.'
          : 'review::No reviews have been written.',
      ),
    );
    if (scroll == 'no') return;
    $.scroll_top2(window, $.offset($1('.mp_review_list')).top, 500);
  } catch (e) {
    e.name = 'changeReviewListInfi Error';
    throw e;
  }
};

export const makeReviewListInfi =
  (appended, opt_func = () => ({})) =>
  (don_tab) => {
    const el_review_list = $.find1(don_tab, '.reviews_wrap .review_list');
    try {
      return $.frame.infi(
        el_review_list,
        defaults(opt_func(don_tab), {
          limit: REVIEW_LIST_LIMIT,
          col: !$.attr(el_review_list, 'photo_review') ? 1 : G.is_mobile_size() ? 2 : 3,
          off: true,
          template: (review) =>
            ($.attr(el_review_list, 'photo_review') ? makeReviewItemHtml : makeReviewItemHtml2)(review),
          appended: pipe(tap(makeReviewListCommentMoreBtn), appended || identity),
          data_func: function (offset, limit) {
            return go(
              $.get(
                '/@api/review/list',
                extend(
                  {
                    is_list: true,
                    offset,
                    limit,
                  },
                  window.box.sel('review_list->query'),
                  {
                    is_all:
                      !$.attr($.find1(don_tab, '.mp_review_list .review_list'), 'photo_review') || void 0,
                  },
                ),
              ),
              tap((reviews) => window.box.push.apply(null, ['review_list->reviews'].concat(reviews))),
            );
          },
        }),
      );
    } catch (e) {}
  };
