export function isStringIncludedToArray(str, array) {
  const lowerCaseSubstring = str.toLowerCase();

  return array.some((item) => lowerCaseSubstring.includes(item.toLowerCase()));
}

// @description 문자열을 chunkSize 단위로 쪼개서 원하는 문자열을 삽입해서 돌려주는 함수
export function insertHyphens({ input_str, chunk_size, insert_str = '-' }) {
  const regexPattern = new RegExp(`.{1,${chunk_size}}`, 'g');
  const parts = input_str.match(regexPattern);

  if (!parts) {
    return input_str;
  }

  return parts.join(insert_str);
}

// @description 문자열의 첫번째와 마지막 문자를 추출
export function getFirstAndLastCharacter({ str }) {
  if (str == null || str.length === 0 || typeof str !== 'string') {
    return { first: null, last: null };
  }

  return {
    first: str[0],
    last: str[str.length - 1],
  };
}

export function generateRandomNumberStringWithoutZero({ length }) {
  let result = '';
  const characters = '123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

// @description string 이 한국어로만 구성되어 있는지 확인
export function isKorean({ input_str }) {
  const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
  return koreanRegex.test(input_str);
}

// @description 문자열에서 숫자만 추출해서 반환
export function extractNumbersFromString(inputString) {
  return inputString.replace(/\D+/g, '');
}

// @description 문자열이 숫자로만 이루어져 있는지 체크
export function isNumericString(inputString) {
  const numberPattern = /^[-+]?(\d+(\.\d*)?|\.\d+)$/;
  return numberPattern.test(inputString);
}

export function isString(val) {
  return typeof val === 'string';
}

export function isEmNil(str) {
  return str == null || str === '';
}

export function isNotEmpty(str) {
  return !isEmNil(str);
}

export function isValidHttpUrlStr(str) {
  return /^(http|https):\/\/[^ "]+$/.test(str);
}

export function removeSpaces(str) {
  return str.replace(/\s/g, '');
}

export function sanitizeInputEnglishOnly(input_string) {
  const is_valid = /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/.test(input_string);

  if (!is_valid) {
    return input_string.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+/g, '');
  }
  return input_string;
}

export function formatPhoneNumber(phoneNumber) {
  // 전화번호에서 숫자만 추출
  const numbersOnly = phoneNumber.replace(/\D/g, '');

  // 전화번호 길이에 따라 형식 결정
  if (numbersOnly.length === 11) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 7)}-${numbersOnly.slice(7, 11)}`;
  } else if (numbersOnly.length === 10) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 6)}-${numbersOnly.slice(6, 10)}`;
  } else if (numbersOnly.length === 8) {
    return `${numbersOnly.slice(0, 4)}-${numbersOnly.slice(4, 8)}`;
  } else {
    // 다른 길이의 전화번호는 그대로 반환
    return phoneNumber;
  }
}

export function formatWaybillNumber(waybillNumber) {
  if (waybillNumber.toString().length === 12) {
    return waybillNumber.toString().replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    // 12자리가 아닌 경우 그대로 반환
    return waybillNumber;
  }
}

export function emptyStringToNullAndTrim(str) {
  if (typeof str !== 'string') return str;

  return str === '' ? null : str.trim();
}
