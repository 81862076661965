import { $attr, $closest, $delegate, $find, $parent, $qs, $setAttr, $setText, $trigger } from 'fxdom/es';
import { defaults, find, go, map, pluck, sel, sort } from 'fxjs/es';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';
import { UtilObjS } from '../../../Util/Object/S/Function/module/UtilObjS.js';
import { getIsBizProduct } from '../../S/product.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { group_discount_guide_frame } from './group_discount_range.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import { calcMakerPrice, getProductColorQuantityPrice } from '../../../ProductColorPrice/S/fs.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { BpDiscountSetS } from '../../../BpDiscountSet/S/Function/module/BpDiscountSetS.js';
import { BpOptionS } from '../../../BpOption/S/Function/module/BpOptionS.js';

const BOX_PF2_KEY = `maker->product_color->product_faces2`;
const BOX_PF2_BIZ_KEY = `${BOX_PF2_KEY}->${BpOptionConstantS.BIZ_PF2_KEY}`;

const boxBizValuesUpsert = (biz_option_values) => {
  if (box.sel(BOX_PF2_BIZ_KEY)) {
    box.extend2(BOX_PF2_BIZ_KEY, biz_option_values);
  } else {
    box.extend2(BOX_PF2_KEY, {
      [BpOptionConstantS.BIZ_PF2_KEY]: biz_option_values,
    });
  }
};

export const bizOptionMenus = {
  init: () => {
    const { is_pure_biz_product } = getIsBizProduct(box().maker.product_color);
    if (!is_pure_biz_product) return;
    const bp_discount_ranges = box.sel(
      'maker->product_color->_->base_product->_->bp_discount_set->_->bp_discount_ranges',
    );
    if (!bp_discount_ranges?.length) return;
    const quantity = go(
      bp_discount_ranges,
      find(sel('is_default')),
      (bp_discount_range) => bp_discount_range?.start || bp_discount_ranges[0].start,
    );
    if (!box.sel(BOX_PF2_KEY)?.biz?.quantity) boxBizValuesUpsert({ quantity });
  },
  upsert: {
    toBoxAtPf2: (biz_option_values) => {
      biz_option_values = biz_option_values ?? bizOptionMenus.get.bizOptionValuesFromDOM();
      boxBizValuesUpsert(biz_option_values);
    },
  },
  get: {
    el: (key) => {
      if (!Object.values(BpOptionConstantS.BIZ_OPTION_MENU_KEY).includes(key)) {
        throw new Error(`Not exist biz option menu key : ${key}`);
      }

      const { is_biz_product } = getIsBizProduct(box().maker.product_color);
      if (is_biz_product === false) {
        throw new Error(`Not biz product`);
      }

      return $qs(`.${BpOptionConstantS.BIZ_OPTION_MENU_TITLE}.${key}`);
    },
    bizOptionValuesFromBox: () => {
      const biz_option_values = box.sel(BOX_PF2_BIZ_KEY);

      return UtilObjS.isNotEmpty(biz_option_values)
        ? biz_option_values
        : {
            [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL]: '',
            [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME]: '',
            [BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO]: '',
            [BpOptionConstantS.BIZ_PF2_DATA_KEYS.QTY]: undefined,
            [BpOptionConstantS.BIZ_PF2_DATA_KEYS.MOQ]: undefined,
          };
    },
    bizOptionValuesFromDOM: () => {
      const biz_option_values = {
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.QTY]: undefined,
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.MOQ]: undefined,
      };

      // 비즈 상품 주문 수량 가져오기
      const qty_option_el = bizOptionMenus.get.el(BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY);

      if (qty_option_el) {
        const input_el = $find('input[type="number"]', qty_option_el);

        if (input_el) {
          const moq = $attr('min', input_el);
          biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.QTY] = parseInt(input_el.value);
          biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.MOQ] = parseInt(moq);
        }
      }

      // 파일 참조 url 가져오기
      const file_option_el = bizOptionMenus.get.el(BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF);
      if (file_option_el) {
        const file_download_el = $find('button[name="download"]', file_option_el);
        if (file_download_el) {
          const file_reference_url = $attr('url', file_download_el);
          const file_reference_name = $attr('filename', file_download_el);
          if (!UtilStringS.isEmNil(file_reference_url)) {
            biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL] = file_reference_url;
            biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME] = file_reference_name;
          }
        }
      }

      // 제작 요청 사항 메모 가져오기
      const request_memo_option_el = bizOptionMenus.get.el(BpOptionConstantS.BIZ_OPTION_MENU_KEY.REQ_MEMO);

      if (request_memo_option_el) {
        const input_el = $find('input[type="text"]', request_memo_option_el);
        if (input_el) {
          const memo_str = input_el.value;
          if (!UtilStringS.isEmNil(memo_str)) {
            biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO] = memo_str;
          }
        }
      }

      return biz_option_values;
    },
  },
};

export const bizOptionMenuEvents = (tab_el) =>
  go(
    /******** 수량 Section */
    tab_el,
    $delegate(
      'click',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY} .input_wrap button`,
      ({ currentTarget: ct }) => {
        const action = ct.name;
        const input_el = go(ct, $parent, $find('input[type="number"]'));
        if (input_el == null) return;
        const qty_prev = Number(input_el.value);
        const step = Number($attr('step', input_el));
        const target_val = go(
          qty_prev + step * (action === 'plus' ? 1 : action === 'minus' ? -1 : 0),
          (quantity) => {
            const bp_discount_ranges = box.sel(
              'maker->product_color->_->base_product->_->bp_discount_set->_->bp_discount_ranges',
            );
            if (bp_discount_ranges) {
              const { quantity: qty } = BpDiscountSetS.makePossibleQuantity(
                { quantity, is_plus: action === 'plus' },
                bp_discount_ranges,
              );
              return qty;
            } else {
              return quantity;
            }
          },
        );

        bizQtyControlHandler({ input_el, target_val });
      },
    ),
    $delegate(
      'change',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY} .input_wrap input[type="number"]`,
      ({ currentTarget: ct }) => {
        const quantity = go(Number(ct.value), (quantity) => {
          const bp_discount_ranges = box.sel(
            'maker->product_color->_->base_product->_->bp_discount_set->_->bp_discount_ranges',
          );
          if (bp_discount_ranges) {
            const { quantity: qty } = BpDiscountSetS.makePossibleQuantity(
              { quantity, is_plus: true },
              bp_discount_ranges,
            );
            return qty;
          } else {
            return quantity;
          }
        });
        bizQtyControlHandler({ input_el: ct, target_val: quantity });
      },
    ),
    $delegate(
      'click',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY} button.group_discount_guide`,
      async ({ currentTarget: ct }) => {
        await MuiF.openFrame(group_discount_guide_frame(), (frame, page, [tab]) => {
          // const price_ranges = $data(ct);
          const product_color = box().maker.product_color;
          const price_ranges = go(
            BpOptionS.getBpDiscountRanges(product_color),
            pluck('start'),
            sort,
            map((start) => {
              const price = getProductColorQuantityPrice(product_color, start);
              return {
                qty: start,
                price: price.discounted_one,
              };
            }),
          );
          /*TODO jip [{qty, price}]*/
          tab.makeData = () => price_ranges;
          /* 동적 사이즈 계산
            1. 모바일
              - 세로 사이즈 계산해서 동적으로 max-height 80% 위치로 frame 열어 주기
              - content 사이즈에 맞춰서 frame height 결정
              - 부족한 경우에는 inner scroll 을 적용
            2. PC
              - 가로 사이즈 계산해서 동적으로 frame 사이즈 변경

          * */
          if (OMPCoreUtilF.isMobile()) {
            // const row_counts = price_ranges.length + 1; // header
            // const ROW_HEIGHT = 46; //px
            // const FRAME_HEADER_H = 64;
            // const MARGIN_B = 20;
            //
            // const contents_height = ROW_HEIGHT * row_counts + FRAME_HEADER_H;
            //
            // const vh =
            //   window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            // const max_slide_up_h = vh * 0.8;
            //
            // const table_allowed_h = max_slide_up_h - FRAME_HEADER_H;
            //
            // if (contents_height < max_slide_up_h) {
            //   frame.height = contents_height + MARGIN_B;
            // } else {
            //   tab.table_style = {
            //     maxHeight: `${table_allowed_h}px`,
            //     overflowY: 'scroll',
            //   };
            //
            //   frame.height = max_slide_up_h + MARGIN_B;
            // }
          } else {
            const lang = go(ct, $closest('html'), $attr('lang'));
            const min_width = lang === 'en' || lang === 'jp' ? 772 : 620;
            const data_counts = price_ranges.length;

            const DATA_W = 70;
            const HEADER_W = 120;
            const MARGIN_W = 24;
            const total_width = HEADER_W + DATA_W * data_counts;
            const width = total_width < min_width ? min_width : total_width > 984 ? 984 : total_width;

            tab.frame_style = {
              width: `${width + MARGIN_W * 2}px`,
              body_width: `${width}px`,
            };
          }
        });
      },
    ),

    /******** 참고 파일 Section */
    $delegate(
      'click',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF} button[name="upload"]`,
      ({ currentTarget: ct }) => {
        const file_input_el = go(ct, $parent, $find('input[type="file"]'));
        if (file_input_el == null) return;

        file_input_el.click();
      },
    ),
    $delegate(
      'click',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF} button[name="download"]`,
      ({ currentTarget: ct }) => {
        const url = $attr('url', ct);
        const filename = $attr('filename', ct);
        if (url === '' || url == null) return;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const a = document.createElement('a');
            const blob_url = window.URL.createObjectURL(blob);
            a.href = blob_url;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blob_url);
            document.body.removeChild(a);
          })
          .catch((error) => {
            console.error(TT('biz::opt::file_error'), error);
          });
      },
    ),
    $delegate(
      'change',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF} input[type="file"]`,
      async ({ currentTarget: ct }) => {
        // 싱글 파일 업로드만 허용
        const upload_file = ct.files[0];
        if (upload_file != null) {
          // 파일 validation - 확장자 (ai | pdf) , 용량 (10MB)
          const { is_allowed_size, is_allowed_extension } = fileUploadValidation({ upload_file });

          if (is_allowed_size && is_allowed_size) {
            try {
              $.don_loader_start();
              const upload_data = await $.upload(ct);

              if (upload_data) {
                const { original_name, url } = upload_data;
                const filename_el = go(ct, $parent, $find('button[name="download"]'));

                $setAttr({ filename: original_name, url }, filename_el);
                $setText(original_name, filename_el);

                bizOptionMenus.upsert.toBoxAtPf2();
              }
            } catch (err) {
              $.don_loader_end();
              console.error(err);
              $.alert('파일 업로드 중에 문제가 발생하였습니다.');
            } finally {
              $.don_loader_end();
            }
          } else {
            if (!is_allowed_size) {
              $.alert(TT('biz::opt::file_size_error'));
              return;
            }
            if (!is_allowed_extension) {
              $.alert(TT('biz::opt::file_ext_error'));
            }
          }
        }
      },
    ),

    /******** 제작 요청 사항 메모 Section */
    $delegate(
      'focusout',
      `.section.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.REQ_MEMO} input[type="text"]`,
      ({ currentTarget: ct }) => {
        const request_memo = ct.value;
        if (UtilStringS.isEmNil(request_memo)) return;

        bizOptionMenus.upsert.toBoxAtPf2();
      },
    ),
  );

export function handleBizQuantityControl({ pf2, current_qty, cb }) {
  const is_biz = UtilObjS.isNotEmpty(pf2?.biz);

  let target_value;

  if (is_biz) {
    const moq = pf2.biz[BpOptionConstantS.BIZ_PF2_DATA_KEYS.MOQ];
    target_value = Math.max(moq, current_qty);

    const biz_option_quantity_input_el = $qs(
      `.biz-option.${BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY} input[type='number']`,
    );
    if (biz_option_quantity_input_el) {
      biz_option_quantity_input_el.value = target_value;
      $trigger('change', biz_option_quantity_input_el);
    }
  } else {
    target_value = current_qty;
  }

  cb && cb(target_value);

  return target_value;
}

function bizQtyControlHandler({ input_el, target_val }) {
  if (input_el == null) throw new Error('Not exist quantity control input el');

  if (!Number.isInteger(target_val)) throw new Error(`Not a number : ${target_val}`);

  const min_limit = Number($attr('min', input_el));
  const max_limit = Number($attr('max', input_el));
  const step = Number($attr('step', input_el));

  const target_temp = Math.max(Math.min(target_val, max_limit), min_limit);
  const remainder = (target_temp - min_limit) % step;

  const target_value = target_temp - remainder;

  input_el.value = target_value;

  bizOptionMenus.upsert.toBoxAtPf2();

  if (UtilF.isLegacyMobile()) {
    const price_el = go(input_el, $closest('.detail_wrapper'), $find('.price_info .original_price'));
    if (price_el) {
      const pc = box.sel('maker->product_color');
      $setText(calcMakerPrice(pc), price_el);
    }
  } else {
    const price_el = go(input_el, $closest('.product_editor'), $find('.product_price_wrapper .price'));
    if (price_el) {
      const pc = box.sel('maker->product_color');
      $setText(`${calcMakerPrice(pc)}${G.is_df ? '원 | $' + PriceS.pricify(pc.price_en) : ''}`, price_el);
    }
  }
}

// @description 파일 용량제한, 확장자제한 검사
function fileUploadValidation({ upload_file, allowed_condition = {} }) {
  allowed_condition = defaults(allowed_condition, { extensions: ['ai', 'pdf', 'eps'], mega_bytes: 10 });

  const { extensions, mega_bytes } = allowed_condition;

  const res = {
    is_allowed_extension: false,
    is_allowed_size: false,
  };

  if (upload_file != null && upload_file instanceof File) {
    const { name, size } = upload_file;

    const file_extension = name.split('.').pop().toLowerCase();
    res.is_allowed_extension = extensions.includes(file_extension);

    const file_size_in_mega_bytes = size / (1024 * 1024);
    res.is_allowed_size = file_size_in_mega_bytes <= mega_bytes;
  }

  return res;
}
