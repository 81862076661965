import { isNil } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { beforeLoginCheck } from '../../../../../../User/Login/F/fs.js';

export const handleRightCancelButtonClick = async () => {
  const is_close = await $.confirm(
    T('modules::VectorEditor::Sticker::message::디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?'),
  );
  if (!is_close) {
    return;
  }
  MuiF.closeFrame(null);
};

export const handleRightDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_grid_sticker_editor;
    if (isNil(editor)) {
      return;
    }

    if (!editor.getIsAllGridExisting()) {
      await $.alert(T('modules::VectorEditor::Sticker::FreeGrid::반칸이 있습니다. 모든 빈칸을 채워주세요.'));
      return;
    }

    const is_login = await beforeLoginCheck(true);
    if (!is_login) {
      return;
    }

    try {
      const result = (await tab_el.__mp_postProcess?.(editor)) ?? null;
      MuiF.closeFrame(result);
    } catch (error) {
      console.error(error);
      $.alert(
        error?.__mp_alert_message ??
          T('modules::VectorEditor::Sticker::message::죄송합니다. 다시 시도해 주세요.'),
      );
    }
  };
