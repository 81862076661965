import { filter, go, html, keys, last, map, strMap, zipWithIndexL } from 'fxjs/es';
import { MShopCoreTmplS } from './module/MShopCoreTmplS.js';
import { MShopCoreConstantS } from '../Constant/module/MShopCoreConstantS.js';
import {
  MPS_MAIL_ORDER_SALES,
  NBOX_MAIL_ORDER_SALES,
} from '../../../../Creator/Address/S/Constant/address.js';
import { MShopUtilS } from '../../../Util/S/Function/module/MShopUtilS.js';
import { ComponentsDrawerS } from '../../../../Components/Drawer/S/Function/module/ComponentsDrawerS.js';
import { MShopExtraAuthCrewConstantS } from '../../../ExtraAuth/Crew/S/Constant/module/MShopExtraAuthCrewConstantS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

/**
 *
 * @param {object?} [plus_crew]
 * @param {string | number} [plus_crew.id]
 * @param {string} [plus_crew.ig_url]
 * @param {string} [plus_crew.fb_url]
 * @param {string} [plus_crew.yt_url]
 * @param {string} [plus_crew.bg_url]
 * @param {string} [plus_crew.tw_url]
 * @return {string}
 */

export const createDefaultFooter = (plus_crew) => {
  const component = 'mshop-default-footer';

  const is_nbox = UtilS.isNbox(plus_crew);

  const texts = {
    footer1: ET('layout::footer1'),
    footer2: ET('layout::footer2'),
    footer3: ET('layout::footer3'),
    footer4: ET('layout::footer4'),
    footer5: ET('layout::footer5'),
    footer6: ET('layout::footer6'),
    footer7: ET('layout::footer7'),
    footer8: ET('layout::footer8'),
    footer9: ET('layout::footer9'),
    address: ET('layout::footer::address'),
    address_info: ET('layout::footer::address_info'),
    apply_seller: ET('layout::footer::apply_seller'),
    blog: ET('layout::footer::blog'),
    ceo: ET('layout::footer::ceo'),
    ceo_name: ET('layout::footer::ceo_name'),
    company_name: ET('layout::footer::company_name'),
    company_registration_info: ET('layout::footer::company_registration_info'),
    company_registration_number: ET('layout::footer::company_registration_number'),
    customer_center: ET('layout::footer::customer_center'),
    customer_center_number: MShopUtilS.getCustomerServiceInfo(plus_crew?.id).tel,
    customer_center_info: ET('layout::footer::customer_center_info'),
    email: ET('layout::footer::email'),
    email_info: MShopUtilS.getCustomerServiceInfo(plus_crew?.id).email,
    instagram: ET('layout::footer::instagram'),
    marpple_corp: ET('layout::footer::marpple_corp'),
    privacy_policy: ET('layout::footer::privacy_policy'),
    report_center: ET('layout::footer::report_center'),
    terms_and_conditions: ET('layout::footer::terms_and_conditions'),
    twitter: ET('layout::footer::twitter'),
    youtube: ET('layout::footer::youtube'),
  };

  const nbox_texts = {
    footer1: ET('layout::footer::nbox::footer1'),
    footer9: ET('layout::footer::nbox::footer9'),
    ceo_name: ET('layout::footer::nbox::ceo_name'),
    address: ET('layout::footer::nbox::address'),
    phone: ET('layout::footer::nbox::phone'),
    phone_number: ET('layout::footer::nbox::phone_number', MShopUtilS.getCustomerServiceInfo(plus_crew?.id)),
    email_info: MShopUtilS.getCustomerServiceInfo(plus_crew?.id).email,
    company_registration_number: ET('layout::footer::nbox::company_registration_number'),
  };

  const makeSeparator = (size) => {
    return html`<span class="${component}__vertical-separator ${component}__vertical-separator--${size}">
    </span>`;
  };

  const separator_lg = makeSeparator('lg');
  const separator_md = makeSeparator('md');

  const sns_icons = {
    ig: MShopCoreTmplS.instagramIcon(),
    yt: MShopCoreTmplS.youtubeIcon(),
    tw: MShopCoreTmplS.twitterIcon(),
    bg: MShopCoreTmplS.blogIcon(),
    fb: MShopCoreTmplS.facebookIcon(),
  };

  const sns_html = go(
    keys(sns_icons),
    map((sns_short) => [sns_short, plus_crew?.[sns_short + '_url']]),
    filter(last),
    strMap(
      ([sns_short, url]) =>
        html`<a href="${url}" class="${component}__sns">
          <div class="${component}__sns-icon">${sns_icons[sns_short]}</div>
        </a>`,
    ),
  );

  const company_detail = is_nbox
    ? `${texts.ceo} : ${nbox_texts.ceo_name} ${separator_md} ${nbox_texts.address} ${separator_md}
    ${nbox_texts.phone} : ${nbox_texts.phone_number} ${separator_md} ${texts.email} :
    ${nbox_texts.email_info} ${separator_md} ${texts.company_registration_info} :
    ${nbox_texts.company_registration_number}
    ${ET.lang === 'kr' ? `${separator_md}통신판매업신고번호 : ${NBOX_MAIL_ORDER_SALES}` : ''}`
    : `${texts.ceo} : ${texts.ceo_name} ${separator_md} ${texts.customer_center}
            :${texts.customer_center_number} ${texts.customer_center_info} ${separator_md}
            ${texts.company_registration_info} : ${texts.company_registration_number}
            ${ET.lang === 'kr' ? `${separator_md}통신판매업신고번호 : ${MPS_MAIL_ORDER_SALES}` : ''}
            ${separator_md} ${texts.address} : ${texts.address_info} ${separator_md} ${texts.email}:
            ${texts.email_info} ${ET.lang === 'kr' ? `${separator_md} 개인정보보호책임자 : 유인동` : ''}`;

  const lang_options = [
    { name: '한국어', value: 'kr' },
    { name: 'English', value: 'en' },
    { name: '日本語', value: 'jp' },
  ];

  const selected_lang_option = lang_options.find((option) => option.value == ET.lang);

  return html`
    <div class="${component}">
      <div class="${component}__upper">
        <div class="${component}__main-links">
          <a href="/@/help?type=nbox_mall_customer_faq_kr&hg_id=491" class="${component}__main-link"
            >${texts.footer2}</a
          >
          ${separator_lg}
          ${is_nbox
            ? ''
            : `<span class="${component}__main-link">${texts.footer3}</span>
          ${separator_lg}
          <span class="${component}__main-link">${texts.footer4}</span>
          ${separator_lg}
          <span class="${component}__main-link">${texts.footer5}</span>
          ${separator_lg}`}
          <a
            href="${is_nbox ? '/@/privacy-nbox' : '/@/privacy'}"
            target="_blank"
            class="${component}__main-link ${is_nbox ? 'bold' : ''}"
            >${texts.footer6}</a
          >
          ${separator_lg}
          <a href="${is_nbox ? '/@/terms-nbox' : '/@/terms'}" target="_blank" class="${component}__main-link"
            >${texts.footer7}</a
          >
        </div>
        <div class="${component}__langs-pc">
          <span class="${component}__langs-pc-icon">${MShopCoreTmplS.earthIcon()}</span>
          ${MShopCoreTmplS.dropdown({
            selected_option: selected_lang_option,
            options: lang_options,
            klass: `${component}__dropdown`,
          })}
        </div>
      </div>
      <div class="${component}__hr"></div>
      <div class="${component}__bottom">
        <div class="${component}__description">
          <div class="${component}__company" data-always-open="${is_nbox}">
            ${is_nbox ? nbox_texts.footer1 : texts.footer1}
            <span class="${component}__company-icon-wrapper" data-show="${!is_nbox}">
              ${MShopCoreTmplS.dropDownIcon({
                klass: `${component}__company-icon`,
                color: MShopCoreConstantS.colors.gray_500,
              })}
            </span>
          </div>
          <!--    nbox 일때만 오픈해놓다가, 접었다 펼치는 기능 사라지면 플러스나 마플샵 쪽이랑 통일하기      -->
          <div class="${component}__company-detail" ${is_nbox ? 'data-always-open="true"' : ''}>
            ${company_detail}
          </div>
          <p class="${component}__description-text ${component}__description-text--1" data-show="${!is_nbox}">
            ${texts.footer8}
          </p>
          <p class="${component}__description-text ${component}__description-text--2">
            ${is_nbox ? nbox_texts.footer9 : texts.footer9}
          </p>
        </div>
        <div class="${component}__langs-mobile">
          <a class="${component}__lang" href="/kr" data-lang="kr">한국어</a>
          ${separator_lg}
          <a class="${component}__lang" href="/en" data-lang="en">English</a>
          ${separator_lg}
          <a class="${component}__lang" href="/jp" data-lang="jp">日本語</a>
        </div>
        <div class="${component}__sns-list" data-show="${!is_nbox}">${sns_html}</div>
      </div>
    </div>
  `;
};

/**
 *
 * @param {string} [search_placeholder]
 * @param {number} [active_menu_idx]
 * @param {number} cart_count
 * @param {any} plus_crew
 * @param {any} user
 * @param {'white' | 'transparent'} [theme]
 * @param {{name: string, url: string}[]} menus
 * @param {string} [title]
 * @param {string} [bg_color] 배경 색상 커스텀 필요한 경우 사용
 * @param {'black' | 'white'} [item_color] 요소들의 색상 (텍스트, 아이콘)
 * @param {'fixed' | 'relative'} [position] fixed 일지 가만히 있을 지
 * @param {string} [left_header] override 용 left_header
 * @param {string} [right_header] override 용 right_header
 * @param {boolean} [has_arrow] 뒤로가기 화살표 표시 여부, default left header 와 arrow 둘 중 하나만 표시
 * @param {boolean} [use_headroom] 헤드룸 적용할 지 여부
 * @param {boolean} is_app
 * @param {boolean} is_mobile
 * @param {boolean} [is_layered]
 * @return {string}
 */
export const createDefaultHeader = ({
  search_placeholder = '',
  active_menu_idx,
  cart_count,
  plus_crew,
  user,
  theme = 'white',
  menus,
  title = '',
  bg_color,
  item_color = 'black',
  position = 'fixed',
  left_header,
  right_header,
  has_arrow = false,
  use_headroom = false,
  is_app,
  is_mobile,
  is_layered = false,
}) => {
  const component = 'mshop-default-header';

  const is_nbox = plus_crew?.id === MShopExtraAuthCrewConstantS.NBOX_ID;

  const { brand_color, menu_color } = MShopUtilS.getCrewBrandColors(plus_crew, is_app);

  const default_black_logo_src_attr = (() => {
    if (is_nbox) {
      return is_mobile
        ? `src="//s3.marpple.co/files/u_2283830/2023/10/original/e65202e23ac22fb008e8de79861aadffe3d5e28d1.png" alt="NBOX"`
        : `src="//s3.marpple.co/files/u_2283830/2023/10/original/4e57af8d0f6ecb3f33b9a180525084afc465e3662.png" alt="NBOX"`;
    }

    return is_mobile
      ? `src="//s3.marpple.co/files/u_2283830/2023/9/original/8308161571b07315d04c87c240ff34145ac66d5c6.png" alt="MARPPLE"`
      : `src="//s3.marpple.co/files/u_2283830/2023/9/original/702655990fda68b891013683a95a168bbf712f617.png" alt="MARPPLE"`;
  })();

  const default_white_logo_src_attr = (() => {
    if (is_nbox) {
      return is_mobile
        ? `src="//s3.marpple.co/files/u_2283830/2023/10/original/c19856e2db77c5e3bda4a63e9735db5c556f4bd92.png" alt="NBOX"`
        : `src="//s3.marpple.co/files/u_2283830/2023/10/original/e06700002cb0ecc64362ecb6596d2886a9f707f81.png" alt="NBOX"`;
    }

    return is_mobile
      ? `src="//s3.marpple.co/files/u_2283830/2023/9/original/5a882c455c41ab13f00114859567497e6c89ff091.png" alt="MARPPLE"`
      : `src="//s3.marpple.co/files/u_2283830/2023/9/original/16d5d8794bed1958c3c03002363e96e8a3862ed18.png" alt="MARPPLE"`;
  })();

  const default_left_header = html`<div class="${component}__logo-box">
    <a href="/${T.lang}">
      <img ${default_black_logo_src_attr} class="${component}__logo ${component}__logo--black" />
      <img ${default_white_logo_src_attr} class="${component}__logo ${component}__logo--white" />
    </a>
  </div>`;

  const default_right_header = html`<!-- 일단은 아직 플러스 쪽은 검색창 없음
              <div class="${component}__search">
                <input class="${component}__search-input" type="text" placeholder="${search_placeholder}" />
                <div class="${component}__search-icon">${MShopCoreTmplS.searchIcon()}</div>
              </div>
            -->
    <a href="/${ET.lang}/@/mypage" data-show="${!is_mobile}" class="${component}__mypage">
      ${MShopCoreTmplS.mypageIcon()}
    </a>
    <a class="${component}__shopping-bag" href="/${ET.lang}/@/cart">
      ${MShopCoreTmplS.shoppingBagIcon(cart_count, brand_color, menu_color)}
    </a>
    <div data-show="${is_mobile}" class="${component}__menu-icon">${MShopCoreTmplS.menuIcon()}</div>`;

  return html`
    <!-- fixed 일 경우 공간 확보를 위해서 존재 -->
    <div
      class="${component}"
      data-theme="${theme}"
      data-position="${position}"
      data-layering="${is_layered}"
      data-headroom="${use_headroom}"
      data-item_color="${item_color}"
    >
      <!-- position fixed 여도 공간 확보를 위해서 relative 로 영역 잡아두기 -->
      <div
        class="${component}__wrapper"
        ${MShopUtilS.returnIf(!!bg_color, `style="background-color: ${bg_color}"`)}
      >
        <div class="${component}__inner">
          <div class="${component}__left">
            ${MShopUtilS.returnIf(
              has_arrow,
              `<span data-show="${is_mobile}" class="${component}__arrow-icon mshop-history-back">${MShopCoreTmplS.arrowIcon()}</span>`,
            )}
            ${left_header == undefined ? default_left_header : left_header}
          </div>
          <div class="${component}__center">
            <span data-show="${is_mobile}" class="${component}__title">${title}</span>
            <div data-show="${!is_mobile}" class="${component}__menus">
              ${go(
                menus,
                zipWithIndexL,
                strMap(
                  ([idx, menu]) =>
                    html`<a
                      class="${component}__menu"
                      data-active="${active_menu_idx == idx}"
                      href="${menu.url}"
                    >
                      ${menu?.name}
                    </a>`,
                ),
              )}
            </div>
          </div>
          <div class="${component}__right">
            ${right_header == undefined ? default_right_header : right_header}
          </div>
        </div>
      </div>
    </div>
    ${ComponentsDrawerS.tmpl({
      user,
      is_mobile,
    })}
  `;
};
