import { $qs } from 'fxdom/es';
import { ComponentsBottomTabBarF } from '../../../../modules/Components/BottomTabBar/F/Function/module/ComponentsBottomTabBarF.js';
import { ComponentsFooterF } from '../../../../modules/Components/Footer/F/Function/module/ComponentsFooterF.js';
import { ComponentsHeaderF } from '../../../../modules/Components/Header/F/Function/module/ComponentsHeaderF.js';
import { ComponentsSlideSelectorF } from '../../../../modules/Components/SlideSelector/F/Function/module/ComponentsSlideSelectorF.js';

ComponentsHeaderF.init();
ComponentsSlideSelectorF.init();

if ($qs('.bottom-tab-bar')) {
  ComponentsBottomTabBarF.init();
}
