import { each, find, go, last, pipe, reject, tap } from 'fxjs/es';
import { marpplizer_home } from './home.js';
import { marpplizer_cv_text_image } from './cv_text_image.js';
import { $appendTo, $attr, $delegate, $el, $find, $qs, $qsa, $remove, $setAttr } from 'fxdom/es';
import { marpplizerCvOldTextImage } from './cv_old_text.js';
import { marpplizerImage } from './cv_image.js';
import { makeOpacityFunctionEvent } from '../opacity.js';
import { setIScroll } from './fs.js';
import { isShadeStyle } from '../CvPreview/Shade/render.js';
import { getCurrentFcanvas } from '../Fcanvas/cv_object.js';
import { NewMakerRangeSliderF } from '../../../NewMaker/RangeSlider/F/Function/module/NewMakerRangeSliderF.js';
import { NewMakerCvObjectCvBackgroundF } from '../../../NewMaker/CvObject/CvBackground/F/Function/module/NewMakerCvObjectCvBackgroundF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import tippy from 'tippy.js';

let is_first = true;

function hide(el, to_right) {
  return anime({
    targets: [el],
    translateX: to_right ? '20%' : '-20%',
    opacity: 0,
    duration: 200,
    easing: 'easeInOutQuart',
  }).finished;
}
function show(el) {
  return anime({
    targets: [el],
    translateX: 0,
    opacity: 1,
    duration: 200,
    easing: 'easeInOutQuart',
  }).finished;
}

let queue = [];

export function startMarpplizer() {
  is_first = true;
  queue = [];
}

function getRoute(cv_type) {
  if (cv_type === 'cv_image' || cv_type === 'cv_pattern') return 'image';
  else if (cv_type === 'cv_text') return 'text';
  else if (cv_type === 'cv_text_image' || cv_type === 'cv_text_image_pattern') return 'text';
  else if (cv_type === 'cv_old_text') return 'old_text';
  else if (cv_type === 'cv_background') return 'cv_background';
  else return 'home';
}

export async function marpplizerProxy(cv_active) {
  if (UtilF.isLegacyMobile()) return;
  const cv_type =
    (isShadeStyle(getCurrentFcanvas()) && cv_active && cv_active._data.cv_type === 'cv_image') ||
    cv_active?._data?.is_embro
      ? 'home'
      : cv_active && cv_active._data.cv_type;
  const route = getRoute(cv_type);
  try {
    $.don_loader_start();
    await openMarpplizer(route, cv_active || { cid: _p.uniqueId('c') });
    queue.shift();
  } catch (e) {
    console.error(e);
    if (e.message === 'no_active' || e.message === 'wrong_data') {
      /*
       * TODO jip 언젠가......
       * */
    }
    queue.shift();
  } finally {
    $.don_loader_end();
  }
}

const event = $delegate('click', '.marpplizer_back_btn', async function () {
  G.mp.maker.unselect_all();
});

function destroyTippy() {
  const marpplizer_el = $qs('#marpplizer');
  if (!marpplizer_el) return;
  if (marpplizer_el.__tippies?.length) {
    go(
      marpplizer_el.__tippies,
      each((_tippy) => _tippy.destroy()),
    );
    marpplizer_el.__tippies = [];
  }
}
function activeTippy(marpplizer_contents_wrapper_el, tooltips) {
  const marpplizer_el = $qs('#marpplizer');
  if (!marpplizer_el) return;
  marpplizer_el.__tippies = [];
  go(
    tooltips,
    each((tooltip) => {
      const target_el = go(marpplizer_contents_wrapper_el, $find(tooltip.target_unique_class));
      if (!target_el) return;
      marpplizer_el.__tippies.push(
        tippy(target_el, {
          content: tooltip.content,
          allowHTML: true,
          theme: 'maker-marpplizer',
          showOnCreate: true,
          placement: 'top',
          maxWidth: 'none',
          appendTo: marpplizer_contents_wrapper_el,
          trigger: 'manual',
          hideOnClick: false,
        }),
      );
    }),
  );
}

const afterAppended = (activity_attr) =>
  pipe(tap(activity_attr.event), NewMakerRangeSliderF.start, setIScroll, event, makeOpacityFunctionEvent);

async function showHideAsync(activity_attr, last_queue) {
  const marpple_home = $qs('#marpplizer .marpplizer_wrapper >.home');
  const activitys = $qs('#marpplizer .marpplizer_wrapper >.activitys');
  if (activity_attr.app === 'base1') {
    if ($attr('app', $1('#marpplizer')) === 'base1') return;
    $setAttr({ app: last_queue.activity_attr.app }, $qs('#marpplizer'));
    await Promise.all([show(marpple_home), hide(activitys, true)]);
  } else {
    if ($attr('app', $1('#marpplizer')) === 'base2') return;
    $setAttr({ app: last_queue.activity_attr.app }, $qs('#marpplizer'));
    await Promise.all([show(activitys), hide(marpple_home)]);
  }
}

async function render(activity_attr) {
  destroyTippy();
  if (activity_attr.app === 'base1') {
    const marpple_home = $qs('#marpplizer .marpplizer_wrapper >.home');
    await go(
      undefined,
      () => {
        if (is_first) {
          is_first = false;
          return go($el(activity_attr.html), $appendTo(marpple_home), afterAppended(activity_attr));
        } else {
          return go(activity_attr.render(marpple_home), setIScroll);
        }
      },
      (el) => {
        if (activity_attr.tooltips?.length)
          activeTippy(go(el, $find('.marpplizer_contents_wrapper')), activity_attr.tooltips);
      },
    );
    return $find('.activity', marpple_home);
  } else {
    return go(
      $el(activity_attr.html),
      $appendTo($qs('#marpplizer .marpplizer_wrapper >.activitys')),
      afterAppended(activity_attr),
    );
  }
}
const openMarpplizer = function (route, cv_active) {
  const cid = cv_active.cid;
  if (find((v) => v.cid === cid, queue)) throw new Error('same_cid');
  queue.push({ route, cid: cv_active.cid });
  return go(
    undefined,
    () => {
      if (route === 'home') return marpplizer_home();
      if (route === 'text') return marpplizer_cv_text_image();
      if (route === 'old_text') return marpplizerCvOldTextImage();
      if (route === 'image') return marpplizerImage();
      if (route === 'cv_background') return NewMakerCvObjectCvBackgroundF.marpplizerCvBackground();
    },
    tap((activity_attr) => {
      find((v) => v.cid === cid, queue).activity_attr = activity_attr;
    }),
    render,
    tap((el) => {
      find((v) => v.cid === cid, queue).el = el;
    }),
    async () => {
      const last_queue = last(queue);
      if (last_queue.cid !== cid) return;
      await showHideAsync(last_queue.activity_attr, last_queue);
    },
    function () {
      const last_queue = last(queue);
      if (last_queue.cid !== cid) return;
      const { activity_attr } = last_queue;
      const activity_els = $qsa('#marpplizer .marpplizer_wrapper >.activitys .activity');
      if (activity_attr.app === 'base1') {
        go(activity_els, each($remove));
      } else {
        if (activity_els.length > 1)
          go(
            activity_els,
            reject((el) => {
              return el === last_queue.el;
            }),
            each($remove),
          );
      }
    },
  );
};
