import { makeProductDetailMakerCanvasSize } from '../../../../Maker/F/product_detail_maker.js';
import { go, html, range, strMap, tap } from 'fxjs/es';
import { $qs, $setAttr, $setHTML } from 'fxdom/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const swiperInit = function () {
  if (!UtilF.isLegacyMobile()) return;
  const target = $1('.canvas_container.selected');
  const width = $.width(target);
  const prev_all = $.prev_all(target, '.canvas_container');
  const next_all = $.next_all(target, '.canvas_container');
  return _p.go(
    _p.mr(
      _p.go(
        G.mp.maker.get_bpcfs_length(),
        _p.range,
        _p.each(function (i) {
          const { translateX, translateY, scale } = makeProductDetailMakerCanvasSize(
            box.sel('maker->canvass')[i].size_info,
          );
          return anime({
            targets: [$('.canvas_zoom_wrapper')[i]],
            translateY,
            translateX,
            scale,
            duration: 0,
          }).finished;
        }),
      ),
      anime({
        targets: target,
        translateX: 0,
        scale: 1,
        duration: 0,
      }).finished,
      prev_all &&
        anime({
          targets: prev_all,
          translateX: -width,
          scale: 1,
          duration: 0,
        }).finished,
      next_all &&
        anime({
          targets: next_all,
          translateX: width,
          scale: 1,
          duration: 0,
        }).finished,
    ),
    function () {
      dotInit();
      dot_move();
    },
  );
};

function dotInit() {
  const dots_el = $qs('.fake_mp_maker .dots');
  go(
    G.mp.maker.get_bpcfs_length(),
    tap(function (length) {
      $setAttr({ faces_length: length })(dots_el);
    }),
    range,
    strMap(() => html` <div class="dot"></div> `),
    (str) => $setHTML(str)(dots_el),
  );
}
function dot_move() {
  const idx = G.mp.maker.editing_canvas_idx() || 0;
  const dot_els = $('.fake_mp_maker .dot');
  const left = idx;
  const right = dot_els.length - idx - 1;

  $.css($1('.fake_mp_maker .left'), {
    display: idx === 0 ? 'none' : 'block',
  });
  $.css($1('.fake_mp_maker .right'), {
    display: idx === dot_els.length - 1 ? 'none' : 'block',
  });
  $.remove_class(dot_els, 'selected _s _m');
  $.add_class(dot_els[idx], 'selected');
  if (left >= 3) {
    $.add_class(dot_els[0], '_s');
    $.add_class(dot_els[1], '_m');
  } else if (left == 2) {
    $.add_class(dot_els[0], '_m');
  }
  if (right >= 3) {
    $.add_class(dot_els[dot_els.length - 1], '_s');
    $.add_class(dot_els[dot_els.length - 2], '_m');
  } else if (right == 2) {
    $.add_class(dot_els[dot_els.length - 1], '_m');
  }
}

export const makerMobileSwiper = (el) => {
  let on_animation = false;
  let prev_x = null;
  let prev_y = null;
  const ratio = 1 / 10;
  let left, center, right;
  let moving_started = null;
  function real_canvas(target) {
    const face_length = G.mp.maker.get_bpcfs_length();
    const idx = $.attr(target, 'idx');
    return target && $.has_class(target, 'canvas_container') && idx < face_length && target;
  }

  function move_translateX(target, value) {
    const value2 = Math.abs(value) - Math.abs(parseFloat(anime.getValue(target, 'translateX')));
    const duration = 400;
    const value_ratio = duration / $.width(target);
    return anime({
      targets: [target],
      translateX: value,
      duration: Math.abs(value2) * value_ratio,
      easing: 'easeInOutQuad',
    }).finished.catch(function (e) {
      console.error(e);
    });
  }

  function canvas_container_swipe(center_translateX, default_width) {
    on_animation = true;
    const width = $.width(center);
    function _default_translate() {
      return _p.mr(
        left && move_translateX(left, -width),
        move_translateX(center, 0),
        right && move_translateX(right, width),
      );
    }
    return _p.go(
      ___,
      function () {
        if (center_translateX <= -default_width) {
          if (!right) return _default_translate();
          G.mp.maker.editing_canvas($.attr(right, 'idx'));
          $.selectable2(right);
          return _p.mr(
            left && move_translateX(left, -width),
            move_translateX(center, -width),
            move_translateX(right, 0),
          );
        } else if (center_translateX >= default_width) {
          if (!left) return _default_translate();
          G.mp.maker.editing_canvas($.attr(left, 'idx'));
          $.selectable2(left);
          return _p.mr(
            move_translateX(left, 0),
            move_translateX(center, width),
            right && move_translateX(right, width),
          );
        } else {
          return _default_translate();
        }
      },
      function () {
        dot_move();
        on_animation = false;
        prev_x = null;
        prev_y = null;
        moving_started = null;
      },
    );
  }

  return _p.go(
    el,
    $.on('click', '.fake_mp_maker .left', function (e) {
      if (on_animation) return;
      center = $1('.canvas_container.selected');
      left = real_canvas($.prev(center));
      right = real_canvas($.next(center));
      canvas_container_swipe($.width(center), $.width(center));
    }),
    $.on('click', '.fake_mp_maker .right', function (e) {
      if (on_animation) return;
      center = $1('.canvas_container.selected');
      left = real_canvas($.prev(center));
      right = real_canvas($.next(center));
      canvas_container_swipe(-$.width(center), $.width(center));
    }),
    $.on('touchstart', '.fake_mp_maker .wrapper', function (e) {
      if (G.mp.maker.get_bpcfs_length() == 1) return;
      if (e.touches === undefined) return;
      if (on_animation) return;
      center = $1('.canvas_container.selected');
      left = real_canvas($.prev(center));
      right = real_canvas($.next(center));
    }),
    $.on('touchmove', '.fake_mp_maker .wrapper', function (e) {
      if (G.mp.maker.get_bpcfs_length() == 1) return;
      if (e.touches === undefined) return;
      if (on_animation) return;
      const page_x = e.touches[0].pageX;
      const page_y = e.touches[0].pageY;
      if (prev_x === null) {
        prev_x = page_x;
        prev_y = page_y;
        return;
      }
      const value_x = page_x - prev_x;
      const value_y = page_y - prev_y;
      if (moving_started === null) {
        if (Math.abs(value_y) > Math.abs(value_x)) {
          moving_started = false;
        } else {
          moving_started = true;
        }
      }
      prev_x = page_x;
      prev_y = page_y;
      if (!moving_started) return;
      else e.preventDefault();

      function f(value) {
        const center_translateX = parseFloat(anime.getValue(center, 'translateX'));
        const left_translateX = left && parseFloat(anime.getValue(left, 'translateX'));
        const right_translateX = right && parseFloat(anime.getValue(right, 'translateX'));
        $.css(left, {
          transform: `translateX(${value + left_translateX}px)`,
        });
        $.css(center, {
          transform: `translateX(${value + center_translateX}px)`,
        });
        $.css(right, {
          transform: `translateX(${value + right_translateX}px)`,
        });
      }
      f(value_x);
    }),
    $.on('touchend', '.fake_mp_maker .wrapper', function (e) {
      if (G.mp.maker.get_bpcfs_length() == 1) return;
      if (on_animation) return;
      const center_translateX = parseFloat(anime.getValue(center, 'translateX'));
      const width = $.width(center);
      const default_width = width * ratio;
      canvas_container_swipe(center_translateX, default_width);
    }),
  );
};
